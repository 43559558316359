import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: auto;
  padding: 10px;

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-top: 0;
  font-family: "Metropolis", sans-serif;
`;

export const ExtraHelpButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;

  top: 8px;
  right: 8px;

  border: 1px solid white;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #007;
  }
`;

export const ExtraHelpButtonText = styled.div`
  position: relative;
  top: 1px;
  font-family: "Metropolis", sans-serif;
  font-size: 16px;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 13px;
  line-height: 1.2;
  margin-top: 6px;
  margin-bottom: 16px;
`;

export const Explanation = styled.div`
  color: #fff;
  font-size: 13px;
  margin-bottom: 6px;
  text-align: center;
  line-height: 1.2;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 278px;
  border-radius: 4px;
  outline: 2px solid white;
  outline-offset: -1px;
  margin-top: 10px;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const NumberInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 24px;
  background-color: #fff;
  color: #000;
`;

export const NumberButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 22px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  user-select: none;
`;

export const PlusPlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 22px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  border-radius: 0px 4px 4px 0;
  user-select: none;
`;

export const MinusMinusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 25px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  border-radius: 4px 0 0 4px;
  user-select: none;
`;

export const Number = styled.span`
  font-size: 18px;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 16px;
    width: 60px;
    text-align: center;
  }
`;

export const TraitPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .dropdown btn-selector nolink {
    color: #fff;
  }

  ${(props) =>
    props.hasTertiaryTraitPicker
      ? css`
          position: relative;
          top: 0px;
          margin-top: -4px;
          margin-bottom: 4px;
          height: 120px;
          justify-content: space-between;
        `
      : props.hasSecondaryTraitPicker &&
        css`
          position: relative;
          top: 2px;
          margin-top: -10px;
          margin-bottom: 4px;
          height: 80px;
          justify-content: space-between;
        `}
`;

export const ForbiddenRarity = styled.h3`
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  font-family: "Metropolis", sans-serif;
  color: #ff0;
`;

export const NameInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
`;

export const NameInput = styled.input`
  width: 100% !important;
  height: 30px !important;
  margin-top: 4px;

  border-radius: 4px !important;

  background-color: #06063b !important;

  /*
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.04em;
  */

  font-size: 1.5rem !important;
  color: #eee !important;
  outline: none;
  text-align: center;

  &::placeholder {
    font-size: 1.5rem !important;
    position: relative;
    top: 0px;
  }
`;

export const ImageUrlInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
`;

export const ImageUrlInput = styled.input`
  width: 100% !important;
  height: 30px !important;

  border-radius: 4px !important;

  /*
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  */

  font-size: 1rem !important;
  color: #eee !important;
  margin-top: 4px;
  outline: none;
  text-align: center;

  background-color: #06063b !important;

  &::placeholder {
    font-size: 1.2rem !important;
    position: relative;
    top: 2px;
  }
`;

export const ImageUrlLoaderButton = styled.div`
  width: 40px;
  height: 30px;

  margin-top: 4px;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  border: none;
  background-color: #fff;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 30px;
    filter: sepia(0%) saturate(7500%) hue-rotate(341deg) brightness(109%) contrast(101%);
  }

  &:hover {
    img {
      filter: invert(41%) sepia(19%) saturate(730%) hue-rotate(76deg) brightness(97%) contrast(87%);
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #222;
      cursor: default;

      &:hover {
        background-color: #222;
      }
    `}
`;

export const SquadPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 6px;

  .dropdown btn-selector nolink {
    color: #fff;
  }

  ${(props) =>
    props.hasSecondaryTraitPicker &&
    css`
      position: relative;
      top: 2px;
      margin-top: -10px;
      margin-bottom: 4px;
      height: 80px;
      justify-content: space-between;
    `}
`;
