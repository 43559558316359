import BigNumber from "bignumber.js";

import PlayerInfoModel from "./PlayerInfoModel";
import SquadModel from "./SquadModel";
import PlayerBonusModel from "./PlayerBonusModel";
import NftModel from "../models/NftModel";
import SquadMultipliersModel from "../models/SquadMultipliersModel";

import { idToQuest } from "../config/resources/02-quests";

/*
player = {
      address: string,
      info: PlayerInfoModel,
      squads: SquadModel[],
      nfts: NftModel[],
      masteries: number[],
      ingameBalances: number[],
      metamaskBalances: [string, string],
      stashed: string,
      connection: {
        initialBlockNumber: number,
        initialTimestamp: number,
      },
    };
*/

/*
  playerData = {
    [0] IBkPlayer.Player memory player,
    [1] IBkSquad.Squad[] memory squads,
    [2] IBkChar.BkChar[] memory nfts,
    [3] InGameBalances memory ingameBalances,
    [4] uint256 stasherBalance,
    [5] IBkPlayerBonus.Player memory playerBonus,
    [6] uint256[] memory masteries,
    [7] uint256 bctMetamaskBalance,
    [8] uint256 stabletokenBalance
  }
*/

class PlayerModel {
  constructor(address, playerData, initialBlockNumber, initialTimestamp, isMerchant = false) {
    BigNumber.config({ EXPONENTIAL_AT: 60 });
    this.setData(address, playerData, initialBlockNumber, initialTimestamp, isMerchant);
  }

  setData(address, playerData, initialBlockNumber, initialTimestamp, isMerchant) {
    const playerInfo = new PlayerInfoModel(playerData[0]);
    try {
      playerInfo.playerBonus = new PlayerBonusModel(playerData[5]);
    } catch (e) {
      console.warn("PlayerBonusModel error", e);
      playerInfo.playerBonus = {
        stash: 0,
        mythicMints: 0,
        item: 0,
        special: 0,
        fortuneLevel: 0,
        total: 0,
      };
    }

    if (playerInfo?.registeredAt === 0 || !playerInfo) {
      this.address = address;
      this.info = {};
      this.squads = [];
      this.nfts = [];
      this.masteries = [];
      this.ingameBalances = [];
      this.metamaskBalances = [];
      this.stashed = "0";
      this.connection = {};
      return;
    }

    const playerSquads = playerData[1].map((squad) => new SquadModel(squad));
    let playerNfts = [];
    let sortedNfts = [];
    if (playerData[2]?.length > 0) {
      console.log("PLAYER RAW NFTS:", playerData[2]);
      playerNfts = playerData[2].map((nft) => new NftModel(nft));
      console.log("PLAYER NFTS:", playerNfts);

      // set squadNumber, treat names and imageUrls, and remove zeroed traits
      for (let i = 0; i < playerNfts.length; i++) {
        // remove zeroes from the traits array
        playerNfts[i].traits = playerNfts[i].traits.filter((trait) => trait !== 0);

        // remove any \" from the name and image
        playerNfts[i].name = playerNfts[i]?.name?.replace(/\"/g, "");
        playerNfts[i].imageUrl = playerNfts[i]?.imageUrl?.replace(/\"/g, "");

        // set the squad number
        if (playerSquads.length > 0) {
          if (playerNfts[i].squadId !== 0) {
            playerNfts[i].set(
              "squadNumber",
              playerInfo.squads.findIndex((squadId) => squadId === playerNfts[i].squadId) + 1
            );
          }
        }
      }

      sortedNfts = playerNfts.sort((a, b) => {
        // Rule 2: NFTs with the highest farmPerBlock should come first
        const farmPerBlockDiff = new BigNumber(b.farmPerBlock).minus(a.farmPerBlock);
        if (!farmPerBlockDiff.isZero()) return farmPerBlockDiff.toNumber();

        // Rule 3: NFTs with the highest type_ should come first
        if (a.type_ !== b.type_) return b.type_ - a.type_;

        // Rule 4: NFTs with the highest rank should come first
        if (a.rank !== b.rank) return b.rank - a.rank;

        // Rule 5: NFTs with the highest agility should come first
        if (a.agility !== b.agility) return b.agility - a.agility;

        // Rule 6: NFTs with the highest baseFarm should come first
        const baseFarmDiff = new BigNumber(b.baseFarm).minus(a.baseFarm);
        if (!baseFarmDiff.isZero()) return baseFarmDiff.toNumber();

        // Rule 7: NFTs with the lowest nftId should come first
        return a.nftId - b.nftId;
      });
    }

    for (let i = 0; i < playerSquads.length; i++) {
      // Set the squad ID
      if (!playerSquads[i]) continue;

      playerSquads[i].id = playerInfo.squads[i];

      // Create the NFTs array if it doesn't exist
      if (!playerSquads[i].nfts) {
        playerSquads[i].nfts = [];
      }

      // Add the NFTs to the squad
      for (let j = 0; j < playerSquads[i].nftIds.length; j++) {
        const nftId = playerSquads[i].nftIds[j];
        const nft = sortedNfts.find((nft) => nft.id === nftId);
        if (nft && nft.squadId === playerSquads[i].id) {
          nft.set("questId", playerSquads[i].currentQuest);
          playerSquads[i].nfts.push(nft);
        }
      }
    }

    // now sum the rarity of all nfts in this squad and set it in the property "raritySum"
    for (let i = 0; i < playerSquads.length; i++) {
      if (!playerSquads[i]) continue;

      // If the squad has 0 as its only collection, let's remove it from the list
      if (playerSquads[i].collections.length === 1 && playerSquads[i].collections[0] === 0) {
        playerSquads[i].collections = [];
      }

      // Rarity Sum
      if (playerSquads[i].nfts.length === 0) {
        playerSquads[i].raritySum = 0;
      } else {
        playerSquads[i].raritySum = playerSquads[i].nfts.reduce((acc, nft) => {
          if (typeof nft.rarity === "number") {
            return acc + nft.rarity;
          } else if (typeof nft.rarity === "string") {
            return acc + parseInt(nft.rarity);
          } else {
            console.error("Invalid rarity:", nft.rarity);
            return acc;
          }
        }, 0);

        playerSquads[i].multipliers = new SquadMultipliersModel(
          playerSquads[i],
          playerInfo.playerBonus.total,
          playerInfo.playerBonus.fortuneLevel,
          playerSquads[i].squadBonus,
          playerData[6]
        );

        /*
        // now, reduce 5% of the farmPerBlock for each squad above the fifth
        if (i > 3) {
          const differenceUp = (100 / (100 + playerSquads[i].squadBonus)) * 100;
          const factor = 100 - differenceUp;
          const adjustedFarmPerBlock = new BigNumber(playerSquads[i].farmPerBlock).times(
            (100 - factor) / 100
          );
          playerSquads[i].farmPerBlock = adjustedFarmPerBlock.toString();
        }
        */
      }
    }

    for (let i = 0; i < playerSquads.length; i++) {
      if (!playerSquads[i]) continue;

      if (playerSquads[i].currentQuest !== 0) {
        playerSquads[i].quest = idToQuest(playerSquads[i].currentQuest);
        // count how many resources are greater than 0
        playerSquads[i].quest.numberOfResources = playerSquads[i].quest.resourceMultipliers.filter(
          (resource) => {
            const key = Object.keys(resource)[0];
            return resource[key] > 0;
          }
        ).length;
      }

      playerSquads[i].squadNumber = i + 1;

      playerSquads[i].calculateSpecials();
    }

    const ingBal = playerData[3].map((balance) => balance.toString());
    ingBal[2] = ingBal[2].split(",");

    const nftIds = [];
    for (let i = 0; i < sortedNfts.length; i++) {
      nftIds.push(sortedNfts[i].id);
    }

    this.address = address;
    this.info = playerInfo;
    this.squads = playerSquads;
    this.nfts = sortedNfts;
    this.nftIds = nftIds;
    this.masteries = playerData[6];
    this.ingameBalances = ingBal;
    this.metamaskBalances = [playerData[7], playerData[8]];
    this.stashed = playerData[4].toString();
    this.connection = {
      initialBlockNumber: initialBlockNumber,
      initialTimestamp: initialTimestamp,
    };

    this.isMerchant = isMerchant;
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }

  setDeep(propertyName, subPropName, value) {
    this[propertyName][subPropName] = value;
  }

  hasEnoughKozi(koziInWei) {
    if (!this.koziBalance) return false;
    return this.koziBalance.gte(koziInWei);
  }
}

export default PlayerModel;
