import React from "react";

import {
  OuterContainer,
  InnerContainer,
  TopLineContainer,
  Wrapper,
  CostTextContainer,
  CostText,
} from "./styles";

import QuestInfo from "./quest-info";
import QuestBonus from "./quest-bonus";

import { idToQuest } from "../../../config/resources/02-quests";

const Quest = ({ questId }) => {
  const quest = idToQuest(questId);

  return (
    quest && (
      <Wrapper>
        <OuterContainer>
          <TopLineContainer style={{ width: "600px" }}>
            <InnerContainer>
              <QuestInfo quest={quest}></QuestInfo>
              <QuestBonus quest={quest}></QuestBonus>
            </InnerContainer>
          </TopLineContainer>
        </OuterContainer>
        {quest.costInResources.length > 0 && (
          <CostTextContainer>
            <CostText>
              Cost to enter this quest: {quest.costInResources[21] / 100} Dragon Scale
              {quest.costInResources[21] > 100 ? "s" : ""}
            </CostText>
          </CostTextContainer>
        )}
      </Wrapper>
    )
  );
};

export default Quest;
