import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 28px;
  border-radius: 6px;
  overflow: hidden;
  cursor: default;
  background: ${(props) => props.background};

  @media (max-width: 450px) {
    height: 20px;
    min-height: 10px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  background: ${(props) => props.background};

  img {
    width: 100%;
    object-fit: contain;
  }

  h6 {
    font-size: 16px;
  }

  @media (max-width: 685px) {
    h6 {
      font-size: 14px;
    }
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;

export const TextContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  height: 100%;

  h6 {
    position: relative;
    top: 1px;
    left: 1px;
    font-size: ${(props) => (props.fontSize === "small" ? "12px" : "14px")};
  }

  h4 {
    position: relative;
    top: 1.5px;
    left: ${(props) => (props.isMultiplier ? "0px" : "2px")};
    font-size: ${(props) => (props.isMultiplier ? "16px" : "10px")};
  }

  @media (max-width: 685px) {
    h6 {
      font-size: ${(props) => (props.fontSize === "small" ? "10px" : "12px")};
    }
    h4 {
      font-size: ${(props) => (props.fontSize === "small" ? "5px" : "8px")};
    }
  }

  @media (max-width: 450px) {
    h6 {
      font-size: ${(props) => (props.fontSize === "small" ? "7px" : "9px")};
      left: ${(props) => (props.isMultiplier ? "0px" : "1px")};
    }

    h4 {
      font-size: ${(props) => (props.fontSize === "small" ? "5px" : "6px")};
    }
  }
`;

export const EbctContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  font-size: 14px;
`;
