import { Container, ImageContainer, TextContainer } from "./styles";
import { resourceToImagePath } from "../../../../config/resources/09-resources-images";

const ResourceContainerSpiralAuctions = ({ resource, quantity, background, hasEnough = true }) => {
  const resourcePath = `../${resourceToImagePath(Number(resource))}`;
  const smallFont = quantity > 9999;
  if (quantity % 0 !== 0 && quantity > 100) {
    quantity = Math.ceil(quantity);
  }

  return (
    <Container background={background} missing={!hasEnough}>
      <ImageContainer background={background} missing={!hasEnough}>
        <img src={resourcePath} alt="resource" />
      </ImageContainer>
      <TextContainer smallFont={smallFont} missing={!hasEnough}>
        <h6>{quantity}</h6>
      </TextContainer>
    </Container>
  );
};

export default ResourceContainerSpiralAuctions;
