//import img1 from "../images/slider/slide_1.png";
import img1 from "../images/slider/kingdom-chain-512xt.png";
// import img2 from "../images/slider/slide_1.png";
import imgbg1 from "../images/slider/bg_slide_1.png";
// import imgbg2 from "../images/slider/bg_slide_1.png";

const heroSliderData = [
  {
    title_1: "We're Pioneers",
    title_2: "And This Is Our Home:",
    title_3: "The Kingdom Chain",
    description: "Made by the community, for the community.",
    img: img1,
    imgbg: imgbg1,
    class: "left",
  },
];

export default heroSliderData;
