import BigNumber from "bignumber.js";
import questList from "./02-quests.json";

import QuestModel from "../../models/QuestModel";

const DEFAULT_FARMING_SCALE = 1;

export function idToQuest(id) {
  const quest = questList.find((quest) => quest.id === id);

  if (quest) {
    return new QuestModel(quest);
  } else {
    return 0;
  }
}

export function canEnter(
  questId,
  synergyBonus,
  traitsInSquad,
  collectionsInSquad,
  resourcesInBalance,
  dragonsInSquad,
  maxedOutNftsInSquad
) {
  const quest = idToQuest(questId);
  if (synergyBonus < quest.minSynergyMultiplier) return false;
  if (quest.traits.length > 0) {
    if (!quest.traits.every((trait) => traitsInSquad.includes(trait))) return false;
  }
  if (quest.collections.length > 0) {
    if (!quest.collections.every((collection) => collectionsInSquad.includes(collection)))
      return false;
  }

  if (quest.anyCollection && collectionsInSquad.length === 0) return false;

  if (quest.costInResources.length > 0) {
    for (let i = 0; i < quest.costInResources.length; i++) {
      if (quest.costInResources[i] > resourcesInBalance[i]) return false;
    }
  }

  if (quest.requiredDreamBeasts.length > 0) {
    if (quest.requiredDreamBeasts[0] > dragonsInSquad) return false;
  }

  if (quest.requiredMaxedOutNfts > 0) {
    if (quest.requiredMaxedOutNfts > maxedOutNftsInSquad) return false;
  }

  return true;
}

export function getAvailableQuests(
  synergyBonus,
  traitsInSquad,
  collectionsInSquad,
  resourcesInBalance,
  dragonsInSquad,
  maxedOutNftsInSquad
) {
  const availableQuests = questList.filter((quest) =>
    canEnter(
      quest.id,
      synergyBonus,
      traitsInSquad,
      collectionsInSquad,
      resourcesInBalance,
      dragonsInSquad,
      maxedOutNftsInSquad
    )
  );
  return availableQuests;
}

export function getAllUniqueQuests() {
  const availableQuests = questList.filter((quest) => quest.id < 1000 || quest.id % 1000 === 0);
  return availableQuests;
}

/**
 * Store this once and use it as much as you need to feed the calculateQuestRewards function
 * @param {QuestRewardsCalculator} questRewardsCalculatorContract the contract that calculates the rewards
 * @param {SquadModel} squad the squad
 * @param {number} raritySum the sum of the rarities of all nfts in the squad
 * @param {string} userAddress the address of the user
 * @returns an array of exactly 5 numbers [bct,eBct,loot,Nuts,Evo]
 */
export async function getSquadMultipliers(
  questRewardsCalculatorContract,
  squad,
  raritySum,
  userAddress
) {
  const durationInBlocks = 8640; // get one day

  const multipliers = await questRewardsCalculatorContract.getBaseMultipliers({
    squadType: squad.type_,
    numberOfNtfs: squad.nftIds.length,
    raritySum: raritySum,
    synergyBonus: squad.synergyBonus,
    traits: squad.traits.length,
    collections: squad.collections.length,
    durationInBlocks,
    account: userAddress,
  });

  return multipliers; // an array of exactly 5 numbers [bct,eBct,loot,Nuts,Evo]
}

export function calculateQuestRewardsV2(questId, squad, currentBlockNumber, scale) {
  BigNumber.config({ DECIMAL_PLACES: 18 });

  if (currentBlockNumber < squad.questStartedAt) {
    const result = {
      bct: 0,
      eBct: 0,
      resources: [],
    };

    return result;
  }

  // find out how many elasped blocks
  const elapsedBlocks = currentBlockNumber - squad.questStartedAt;

  const quest = idToQuest(questId);

  if (!scale) {
    console.warn(`FARMING SCALE NOT FOUND! USING DEFAULT SCALE OF ${DEFAULT_FARMING_SCALE}`);
  }
  scale = scale ? (scale / 100) * elapsedBlocks : (DEFAULT_FARMING_SCALE / 100) * elapsedBlocks;

  let totalBctFarmed = new BigNumber(0);
  let totalEtherealFarmed = new BigNumber(0);

  if (quest.bctPercentage > 0) {
    totalBctFarmed = new BigNumber(squad.farmPerBlock).multipliedBy(quest.bctPercentage).div(100);
    totalBctFarmed = totalBctFarmed
      .multipliedBy(squad.multipliers.BCT - squad.squadBonus)
      .div(100)
      .multipliedBy(scale);
  }

  if (quest.etherealPercentage > 0) {
    totalEtherealFarmed = new BigNumber(squad.farmPerBlock)
      .multipliedBy(quest.etherealPercentage)
      .div(100);
    totalEtherealFarmed = totalEtherealFarmed
      .multipliedBy(squad.multipliers.eBCT - squad.squadBonus)
      .div(100)
      .multipliedBy(scale);
  }

  // zero-fill an array of 19 elements
  const resourceCentsFarmed = {};
  for (let i = 0; i < 21; i++) {
    resourceCentsFarmed[i] = 0;
  }

  for (let i = 0; i < squad.nfts.length; i++) {
    // get the NFT's rarity and multiply it
    const nftData = squad.nfts[i];

    // Skills: BL 1
    resourceCentsFarmed[nftData.type_ - 1] += new BigNumber(squad.multipliers.BL)
      .multipliedBy(quest.resourceMultipliers[0])
      .multipliedBy(nftData.rarity)
      .multipliedBy(nftData.skills[0])
      .multipliedBy(elapsedBlocks)
      .toNumber();

    // Skills: AL 1
    if (nftData.type_ === 15) {
      // Cacodemons farm Cow Boxes at a normal rate (so, x50)
      resourceCentsFarmed[nftData.type_ + 4] += new BigNumber(squad.multipliers.AL)
        .multipliedBy(quest.resourceMultipliers[1])
        .multipliedBy(nftData.rarity)
        .multipliedBy(nftData.skills[1])
        .multipliedBy(elapsedBlocks)
        .multipliedBy(50)
        .toNumber();
    } else {
      resourceCentsFarmed[nftData.type_ + 4] += new BigNumber(squad.multipliers.AL)
        .multipliedBy(quest.resourceMultipliers[1])
        .multipliedBy(nftData.rarity)
        .multipliedBy(nftData.skills[1])
        .multipliedBy(elapsedBlocks)
        .toNumber();
    }

    // Skills: BL 2
    if (nftData.type_ >= 6) {
      resourceCentsFarmed[nftData.type_] += new BigNumber(squad.multipliers.BL)
        .multipliedBy(quest.resourceMultipliers[0])
        .multipliedBy(nftData.rarity)
        .multipliedBy(nftData.skills[4] || 0)
        .multipliedBy(elapsedBlocks)
        .toNumber();
    }

    // Skills: AL 2
    if (nftData.type_ >= 11) {
      if (nftData.type_ === 14 || nftData.type_ === 15) {
        // Kaijus and Cacodemons farm Cow and Elephant Boxes at a normal rate (so, x50)
        resourceCentsFarmed[nftData.type_ + 5] += new BigNumber(squad.multipliers.AL)
          .multipliedBy(quest.resourceMultipliers[1])
          .multipliedBy(nftData.rarity)
          .multipliedBy(nftData.skills[5] || 0)
          .multipliedBy(elapsedBlocks)
          .multipliedBy(50)
          .toNumber();
      } else {
        resourceCentsFarmed[nftData.type_ + 5] += new BigNumber(squad.multipliers.AL)
          .multipliedBy(quest.resourceMultipliers[1])
          .multipliedBy(nftData.rarity)
          .multipliedBy(nftData.skills[5] || 0)
          .multipliedBy(elapsedBlocks)
          .toNumber();
      }
    }

    // Skill: 'Nuts'
    resourceCentsFarmed[10] += new BigNumber(squad.multipliers.Nuts)
      .multipliedBy(quest.resourceMultipliers[2])
      .multipliedBy(nftData.rarity)
      .multipliedBy(nftData.skills[2])
      .multipliedBy(elapsedBlocks)
      .toNumber();

    // Skill: Crafting
    for (let j = 3; j < 11; j++) {
      // Trait Rerolls, Evo Stones, Collection Gems and others
      resourceCentsFarmed[j + 8] += new BigNumber(squad.multipliers.Evo)
        .multipliedBy(quest.resourceMultipliers[j])
        .multipliedBy(nftData.rarity)
        .multipliedBy(nftData.skills[3])
        .multipliedBy(elapsedBlocks)
        .toNumber();
    }

    // Skill: Crafting Cow and Elephant Boxes
    if (quest.resourceMultipliers.length >= 12) {
      resourceCentsFarmed[19] += new BigNumber(squad.multipliers.Evo)
        .multipliedBy(quest.resourceMultipliers[11])
        .multipliedBy(nftData.rarity)
        .multipliedBy(nftData.skills[3])
        .multipliedBy(elapsedBlocks)
        .toNumber();
    }
    if (quest.resourceMultipliers.length >= 13) {
      resourceCentsFarmed[20] += new BigNumber(squad.multipliers.Evo)
        .multipliedBy(quest.resourceMultipliers[12])
        .multipliedBy(nftData.rarity)
        .multipliedBy(nftData.skills[3])
        .multipliedBy(elapsedBlocks)
        .toNumber();
    }
  }

  // now, normalize dividing it by a day * 100 = 864000
  for (let i = 0; i < 21; i++) {
    resourceCentsFarmed[i] = BigNumber(resourceCentsFarmed[i] || 0)
      .div(864000)
      .toNumber();
  }

  for (let i = 0; i < 21; i++) {
    if (i === 19 || i === 20) {
      // let's divide by 50
      resourceCentsFarmed[i] = BigNumber(resourceCentsFarmed[i] || 0)
        .div(50)
        .toNumber();
    }

    resourceCentsFarmed[i] = Math.floor(resourceCentsFarmed[i] * 10000) / 1000000;
  }

  const resourcesArray = Object.values(resourceCentsFarmed);
  const result = {
    bct: totalBctFarmed.toString(),
    eBct: totalEtherealFarmed.toString(),
    resources: resourcesArray,
  };

  return result;
}
