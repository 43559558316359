export function resourceToImagePath(resource) {
  if (resource < 90 || (resource > 200 && resource < 300) || resource === 999) {
    return `assets/images/resources/resource-${resource}.png`;
  }

  switch (resource) {
    case 1118:
      return "assets/images/resources/resource-11-to-18.png";
    case 99:
      return "assets/images/resources/resource-BCT-md.png";
    case "BCT":
      return "assets/images/resources/resource-BCT-md.png";
    case 98:
      return "assets/images/resources/resource-eBCT-md.png";
    case "eBCT":
      return "assets/images/resources/resource-eBCT-md.png";
    case 101:
      return "assets/images/resources/resource-101.png";
    case 102:
      return "assets/images/resources/resource-USDC.png";
    case "USDC":
      return "assets/images/resources/resource-USDC.png";

    default:
      return "";
  }
}
