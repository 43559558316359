import React, { createContext, useContext, useCallback } from "react";
import { BigNumber } from "bignumber.js";

import config from "../config/config";
import { useWeb3Context } from "./Web3Context";
import { ENV } from "../utils/environment";

export const BctPoolContext = createContext({});

export const useBctPoolContext = () => {
  return useContext(BctPoolContext);
};

export const BctPoolProvider = ({ children }) => {
  const { isReady, execFunction, consult } = useWeb3Context();

  const getAllowanceObject = (tokenContractName, addressToAllow, allowedContractName) => {
    return {
      tokenContractName,
      addressToAllow,
      allowedContractName,
      allowedAssetDescription: `${tokenContractName} in your wallet`,
      successMessage: `Allowance given to ${allowedContractName}`,
    };
  };

  const buyGameTokens = async (howMany) => {
    if (!isReady) return;

    await execFunction({
      contractName: "CommunityPool",
      functionName: "buyGameTokens",
      functionParams: [howMany],
      allowance: getAllowanceObject(
        "Stabletoken",
        config[ENV].CommunityPool.address,
        "Community Pool"
      ),
      successMessage: "You have successfully bought BCT. It's already in your wallet!",
      errorList: [
        {
          reason: "cooldown",
          message: "Cooldown: There is a 10-minute cooldown between operations.",
        },
      ],
    });
  };

  const buyGameTokensAtPrice = async (howMany, price) => {
    if (!isReady) return;

    await execFunction({
      contractName: "CommunityPool",
      functionName: "buyGameTokensAtPrice",
      functionParams: [howMany, price, 10], // 10 here equals 1%
      allowance: getAllowanceObject(
        "Stabletoken",
        config[ENV].CommunityPool.address,
        "Community Pool"
      ),
      successMessage: "You have successfully bought BCT. It's already in your wallet!",
      errorList: [
        {
          reason: "cooldown",
          message: "Cooldown: There is a 10-minute cooldown between operations.",
        },
      ],
    });
  };

  const sellGameTokens = async (howMany) => {
    if (!isReady) return;

    await execFunction({
      contractName: "CommunityPool",
      functionName: "sellGameTokens",
      functionParams: [howMany],
      allowance: getAllowanceObject("BCT", config[ENV].CommunityPool.address, "Community Pool"),
      successMessage:
        "You have successfully sold BCT. The corresponding USDC is already in your wallet!",
      errorList: [
        {
          reason: "cooldown",
          message: "Cooldown: There is a 10-minute cooldown between operations.",
        },
      ],
    });
  };

  const sellGameTokensAtPrice = async (howMany, price) => {
    if (!isReady) return;

    await execFunction({
      contractName: "CommunityPool",
      functionName: "sellGameTokensAtPrice",
      functionParams: [howMany, price, 10], // 10 here equals 1%
      allowance: getAllowanceObject("BCT", config[ENV].CommunityPool.address, "Community Pool"),
      successMessage:
        "You have successfully sold BCT. The corresponding USDC is already in your wallet!",
      errorList: [
        {
          reason: "cooldown",
          message: "Cooldown: There is a 10-minute cooldown between operations.",
        },
      ],
    });
  };

  const getPrice = async () => {
    if (!isReady) return;

    const result = await consult({
      contractName: "CommunityPool",
      functionName: "getPrice",
    });

    return BigNumber(result).div(1e18).toFixed(5);
  };

  const quoteBuyGameTokens = async (howMany) => {
    if (!isReady) return;

    const result = await consult({
      contractName: "CommunityPool",
      functionName: "quoteBuyGameTokens",
      functionParams: [howMany],
    });

    return BigNumber(result).div(1e18).toFixed(2);
  };

  return (
    <BctPoolContext.Provider
      value={{
        quoteBuyGameTokens,
        buyGameTokens,
        sellGameTokens,
        getPrice,
        buyGameTokensAtPrice,
        sellGameTokensAtPrice,
      }}
    >
      {children}
    </BctPoolContext.Provider>
  );
};
