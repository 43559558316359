function nameToNumber(name) {
  switch (name) {
    case "Mouse":
      return 1;
    case "Cat":
      return 2;
    case "Cow":
      return 3;
    case "Elephant":
      return 4;
    case "Ape":
      return 5;
    case "Dragon":
      return 6;
    case "Griffin":
      return 7;
    case "Satyr":
      return 8;
    case "Dinosaur":
      return 9;
    case "Cyborg":
      return 10;
    case "Hydra":
      return 11;
    case "Chimera":
      return 12;
    case "Ent":
      return 13;
    case "Kaiju":
      return 14;
    case "Cacodemon":
      return 15;
    default:
      return 0;
  }
}

function numberToName(number) {
  switch (number) {
    case 1:
      return "Mouse";
    case 2:
      return "Cat";
    case 3:
      return "Cow";
    case 4:
      return "Elephant";
    case 5:
      return "Ape";
    case 6:
      return "Dragon";
    case 7:
      return "Griffin";
    case 8:
      return "Satyr";
    case 9:
      return "Dinosaur";
    case 10:
      return "Cyborg";
    case 11:
      return "Hydra";
    case 12:
      return "Chimera";
    case 13:
      return "Ent";
    case 14:
      return "Kaiju";
    case 15:
      return "Cacodemon";
    default:
      return "Unknown";
  }
}

export { nameToNumber, numberToName };
