import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const OuterShell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  max-width: 100%;
  margin-top: 60px;
  margin-bottom: 100px;
  flex-wrap: wrap;
`;

export const Wheel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  min-height: 600px;
  padding: 20px;
  border-radius: 0px 0px 4px 4px;
  flex-wrap: wrap;
  outline: 3px solid white;
  max-width: 90%;
`;

export const SacrificeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: ${(props) => (props.disabled ? "0px" : "100%")};
  opacity: ${(props) => (props.disabled ? "0" : "1")};
  margin-bottom: ${(props) => (props.disabled ? "-40px" : "10px")};
  outline: 3px solid white;
  padding: 20px;
  border-radius: 4px;
  background: ${(props) => (props.disabled ? "#555" : "")};
  position: relative;
  top: ${(props) => (props.disabled ? "-4000px" : "0px")};

  max-width: 90%;

  @media (max-width: 625px) {
    flex-wrap: wrap;
  }

  /* transition: all 0.3s ease-in-out; */
`;

export const CardCradle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;

  min-width: 20%;

  @media (max-width: 625px) {
    padding: 10px;
  }

  transition: all 0.2s ease-in-out;
`;

export const StatsBox = styled.div`
  display: flex;
  align-items: center;
  width: 600px;
  min-height: 40px;
  margin-bottom: 3px;
  outline: 3px solid white;
  padding: 10px 26px;
  border-radius: 4px 4px 0px 0px;
  background: #112;

  max-width: 90%;
`;

export const StatLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  width: 34px;
  text-align: center;
`;

export const Lucks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  width: 215px;
  text-align: center;
  margin-left: 10px;
  position: relative;
  top: 1px;
  overflow-wrap: anywhere;
`;

export const AdvancedOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  left: 28px;

  @media (max-width: 625px) {
    left: 20px;
  }
`;

export const ClearButton = styled.div`
  font-size: 12px;
  color: #fff;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.isOn ? "#fa3" : "#113")};
  outline: ${(props) => (props.isOn ? "1px solid yellow" : "1px solid white")};
  border-radius: 4px;
  padding: 5px 3px;
  margin-left: 4px;
  user-select: none;

  ${(props) =>
    !props.disabled
      ? `
  &:hover {
    opacity: 1;
    outline: ${(props) => (props.isOn ? "1px solid white" : "1px solid yellow")};
  }
  &:active {
    background-color: #fff;
  }`
      : ""}
`;

export const TicketsButton = styled.div`
  font-size: 12px;
  color: #fff;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.isOn ? "#fa3" : "#113")};
  outline: ${(props) => (props.isOn ? "1px solid yellow" : "1px solid white")};
  border-radius: 4px;
  padding: 5px 3px;
  margin-left: 4px;
  user-select: none;
  min-width: 36px;
  text-align: center;

  ${(props) =>
    !props.disabled
      ? `
  &:hover {
    opacity: 1;
    outline: ${(props) => (props.isOn ? "1px solid white" : "1px solid yellow")};
  }

  &:active {
    background-color: #fff;
  }`
      : ""}
`;

export const DoubleButton = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isOn ? "#0f0" : "#fff")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.isOn ? "#000" : "#113")};
  outline: ${(props) => (props.isOn ? "1px solid yellow" : "1px solid white")};
  border-radius: 4px;
  padding: 5px 3px;
  margin-left: 4px;
  user-select: none;
  font-weight: bold;
  width: 164px;
  text-align: center;

  @media (max-width: 625px) {
    display: ${(props) => (props.ignoreDisplayNone ? "flex" : "none")};
  }

  ${(props) =>
    !props.disabled
      ? `
  &:hover {
    outline: ${(props) => (props.isOn ? "1px solid white" : "1px solid yellow")};
  }

  &:active {
    background-color: #fff;
  }`
      : ""}
`;

export const SmallDoubleButton = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isOn ? "#0f0" : "#fff")};
  cursor: pointer;
  background-color: ${(props) => (props.isOn ? "#000" : "#113")};
  outline: ${(props) => (props.isOn ? "1px solid yellow" : "1px solid white")};
  border-radius: 4px;
  padding: 5px 3px;
  margin-left: 4px;
  user-select: none;
  font-weight: bold;
  text-align: center;

  @media (min-width: 625px) {
    display: ${(props) => (props.ignoreDisplayNone ? "flex" : "none")};
  }

  @media (max-width: 625px) {
    display: ${(props) => (props.ignoreDisplayNone ? "none" : "flex")};
  }

  :hover {
    outline: ${(props) => (props.isOn ? "1px solid white" : "1px solid yellow")};
  }

  :active {
    background-color: #fff;
  }
`;

export const BigSpinButton = styled.div`
  font-size: 24px;
  color: #fff;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: ${(props) =>
    props.disabled ? "#555" : "linear-gradient(0deg, rgba(38, 74, 40, 1), rgba(108, 200, 104, 1))"};
  outline: 3px solid white;
  border-radius: 4px;
  padding: 15px 3px;
  margin: 14px;
  user-select: none;
  font-weight: bold;
  width: 100%;
  max-width: 600px;
  text-align: center;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333,
    0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333;

  @media (max-width: 625px) {
    width: 90%;
  }

  :hover {
    opacity: ${(props) => (props.disabled ? "1" : "0.9")};
  }

  :active {
    background: #fff;
  }
`;
