import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/header/Header";
import UpgradesBlock from "../components/upgrades/UpgradesV2";

import { usePlayerContext } from "../contexts/PlayerContext";

const UpgradeNft = () => {
  const { id } = useParams(); // use the hook to get the id
  const { player } = usePlayerContext();
  const [nft, setNft] = useState(null); // set the initial state to null

  useEffect(() => {
    if (!player) return;

    const tempNft = player?.nfts?.find((_nft) => _nft.id === Number(id));
    if (tempNft) {
      console.log("UpgradeNft :: nft", tempNft);
      setNft(tempNft);
    } else {
      console.log("UpgradeNft :: nft not found");
    }
  }, [id, player]);

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Upgrades</h1>
              </div>
              <div>
                <h5 className="sub-title help-center" style={{ marginBottom: "0px" }}>
                  Upgrade your beast to make it stronger and more valuable.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UpgradesBlock nft={nft} />
    </div>
  );
};

export default UpgradeNft;
