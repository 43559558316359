import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import Header from "../components/header/Header";
import CountUp from "react-countup";
import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useBctPoolContext } from "../contexts/BctPoolContext";
import {
  quoteBuyGameTokens,
  quoteStableToGameTokens,
  priceFromReserves,
  quoteSellGameTokens,
} from "../utils/pool";
import DexChart from "../components/BctPool/chart/dexChart";
import SpreadModal from "../components/BctPool/spreadModal";
import spreadImg from "../assets/images/pool-spread-tiny.png";
import { getSpread } from "../components/BctPool/spread";
import { stasherLevelByBalance } from "../utils/constants";

const DECIMAL_PLACES = 6;
const ORIGINAL_PRICE = 0.01142;
BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_UP });

const DexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 90%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const TradingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  padding: 20px;
  margin: 0px auto;
  border-radius: 8px;
  outline: 3px solid #fff;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

const TradingButton = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background: ${(props) =>
    props.sell
      ? "linear-gradient(145deg, #d9534f, #c9302c)"
      : "linear-gradient(145deg, #45a049, #4caf58)"};
  border: none;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  &:hover {
    background: ${(props) =>
      props.sell
        ? "linear-gradient(145deg, #c74343, #df6360)"
        : "linear-gradient(145deg, #3c8f40, #51c665)"};
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const TradingLabel = styled.label`
  color: #fff;
  margin-bottom: 6px;
  font-size: 14px;
`;

const TradingInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 6px;
  border: 1px solid #4caf58;
  background-color: #1c1b20;
  color: #fff;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  max-width: 90%;
  margin-top: 20px; // Reduced this value
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  padding: 20px;
  gap: 6px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BctPool = () => {
  BigNumber.config({ DECIMAL_PLACES: 18 });
  const { blockchainState, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE, watchBct } = useWeb3Context();
  const { player } = usePlayerContext();
  const { buyGameTokensAtPrice, sellGameTokensAtPrice } = useBctPoolContext();
  const [showCountUp, setShowCountUp] = useState(false);
  const [formattedBusdAmount, setFormattedBusdAmount] = useState("0");
  const [formattedBctAmount, setFormattedBctAmount] = useState("0");
  const [isModalOpen, setModalOpen] = useState(false);
  const [formattedSellBusdAmount, setFormattedSellBusdAmount] = useState("0");
  const [formattedSellBctAmount, setFormattedSellBctAmount] = useState("0");

  const isValidBlockchainState = blockchainState && blockchainState?.poolReserves?.length === 2;

  const price = isValidBlockchainState
    ? priceFromReserves(blockchainState.poolReserves[0], blockchainState.poolReserves[1])
    : 0;

  const priceChange = (price) => {
    return Math.round((parseFloat(price) / ORIGINAL_PRICE - 1) * 10000) / 100;
  };

  const priceChangeClass = priceChange(price) < 0 ? "red" : "";

  const handleBusdAmountChange = (event) => {
    if (!blockchainState) return;

    setShowCountUp(false);
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedBusdAmount(value);

    // Perform calculation to update BCT amount
    const calculatedBctAmount = quoteStableToGameTokens(
      BigNumber(value).times(1e18),
      blockchainState.poolReserves[0],
      blockchainState.poolReserves[1]
    );
    const bctAmount = calculatedBctAmount.div(1e18);

    if (bctAmount.isNaN() || bctAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN") {
      setFormattedBctAmount("0");
      return;
    }

    setFormattedBctAmount(bctAmount.toFixed(DECIMAL_PLACES), BigNumber.ROUND_DOWN);
  };

  const handleBctAmountChange = (event) => {
    if (!blockchainState) return;

    setShowCountUp(false);
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedBctAmount(value);

    // Perform calculation to update USDC amount
    const calculatedBusdAmount = quoteBuyGameTokens(
      BigNumber(value).times(1e18),
      blockchainState.poolReserves[0],
      blockchainState.poolReserves[1]
    );
    const busdAmount = calculatedBusdAmount.div(1e18);

    if (busdAmount.isNaN() || busdAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN") {
      setFormattedBusdAmount("0");
      return;
    }

    setFormattedBusdAmount(busdAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP));
  };

  const handleSellBctAmountChange = (event) => {
    if (!blockchainState) return;
    if (!player) return;

    setShowCountUp(false);
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedSellBctAmount(value);

    // Perform calculation to update USDC amount
    const calculatedBusdAmount = quoteSellGameTokens(
      BigNumber(value).times(1e18),
      blockchainState.poolReserves[0],
      blockchainState.poolReserves[1],
      getSpread(stasherLevelByBalance(parseFloat(BigNumber(player.stashed).div(1e18).toFixed(2)))),
      stasherLevelByBalance(parseFloat(BigNumber(player.stashed).div(1e18).toFixed(2)))
    );
    const busdAmount = calculatedBusdAmount.div(1e18);

    if (busdAmount.isNaN() || busdAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN") {
      setFormattedSellBusdAmount("0");
      return;
    }

    setFormattedSellBusdAmount(busdAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_DOWN));
  };

  const isBuyButtonDisabled = false;
  const handleBuyButtonClick = async () => {
    await buyGameTokensAtPrice(
      BigNumber(formattedBctAmount).times(1e18).toFixed(0, BigNumber.ROUND_DOWN),
      priceFromReserves(blockchainState.poolReserves[0], blockchainState.poolReserves[1]).toFixed(0)
    );
  };

  const isSellButtonDisabled = false;
  const handleSellButtonClick = async () => {
    await sellGameTokensAtPrice(
      BigNumber(formattedSellBctAmount).times(1e18).toFixed(0, BigNumber.ROUND_DOWN),
      priceFromReserves(blockchainState.poolReserves[0], blockchainState.poolReserves[1]).toFixed(0)
    );
  };

  const handleModalToggle = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <div>
      <style>
        {`
        .green {
          color: #2f4;
        }
        .red {
          color: red;
        }
        .fade-in {
          opacity: 0;
          transition: opacity 0.5s ease-in;
        }
        .fade-in.show {
          opacity: 1;
        }
      `}
      </style>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🦍</h1>
                    <h1 className="heading text-center">BCT</h1>
                    <h2
                      className={`price text-center ${
                        price ? "green" : ""
                      } ${priceChangeClass} fade-in ${blockchainState?.poolReserves ? "show" : ""}`}
                    >
                      {!blockchainState?.poolReserves ? (
                        "Loading..."
                      ) : (
                        <>
                          $
                          {showCountUp ? (
                            <CountUp
                              start={0}
                              end={price.div(1e18) || 0}
                              duration={3}
                              decimals={5}
                            />
                          ) : (
                            price.div(1e18).toFixed(5, BigNumber.ROUND_DOWN)
                          )}
                          {" ("}
                          {priceChange(price.div(1e18)) > 0 ? "+" : ""}
                          <CountUp
                            start={0}
                            end={priceChange(price.div(1e18)) || 0}
                            duration={3}
                            decimals={2}
                          />
                          %)
                        </>
                      )}
                    </h2>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      Welcome to the heart of our Beast Kingdom Community Pool. This is your go-to
                      hub to monitor the price, purchase, and sell Beast Community Tokens.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      {`The counter above displays the percentage change from the initial price: $${ORIGINAL_PRICE} on May 1st, 2023.`}
                    </h5>
                    <h5 className="sub-title help-center">
                      We have a{"  "}
                      <Link
                        to="#"
                        style={{ textDecorationLine: "underline" }}
                        onClick={handleModalToggle}
                      >
                        dynamic spread
                      </Link>{" "}
                      that decreases according to your Stasher level and the number of months passed
                      since October 6th, 2023 (UTC).
                    </h5>
                    {player ? (
                      <>
                        <h5 className="sub-title help-center">
                          {`The best possible spread right now is ${getSpread(
                            10
                          )}% for Stashers level 10.`}
                        </h5>
                        <h5 className="sub-title help-center mg-bt-32" style={{ color: "yellow" }}>
                          {`Your current spread is ${getSpread(
                            stasherLevelByBalance(
                              parseFloat(BigNumber(player.stashed).div(1e18).toFixed(2))
                            )
                          )}%.`}
                        </h5>
                      </>
                    ) : (
                      <h5 className="sub-title help-center mg-bt-32">Connect your Metamask</h5>
                    )}
                    <h5 className="sub-title help-center">
                      Please note: Minor decimal imprecisions may occur.
                    </h5>
                    <SpreadModal
                      isOpen={isModalOpen}
                      closeModal={handleModalToggle}
                      tableSrc={spreadImg}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <DexWrapper>
            <DexChart />
          </DexWrapper>
          <BoxesWrapper>
            <TradingBox>
              <TradingLabel htmlFor="bctInputSell">Send BCT</TradingLabel>
              <TradingInput
                type="text"
                id="bctInputSell"
                value={formattedSellBctAmount}
                onChange={handleSellBctAmountChange}
                onFocus={(event) => event.target.select()}
              />
              <TradingLabel htmlFor="busdInputBuy">Get USDC</TradingLabel>
              <TradingInput
                type="text"
                id="busdInputSell"
                value={formattedSellBusdAmount}
                //onChange={handleSellBusdAmountChange}
                //onFocus={(event) => event.target.select()}
                readOnly
              />
              <TradingButton sell disabled={isSellButtonDisabled} onClick={handleSellButtonClick}>
                Sell BCT
              </TradingButton>
            </TradingBox>
            <TradingBox>
              <TradingLabel htmlFor="busdInputBuy">Send USDC</TradingLabel>
              <TradingInput
                type="text"
                id="busdInputBuy"
                value={formattedBusdAmount}
                onChange={handleBusdAmountChange}
                onFocus={(event) => event.target.select()}
              />
              <TradingLabel htmlFor="bctInputBuy">Get BCT</TradingLabel>
              <TradingInput
                type="text"
                id="bctInputBuy"
                value={formattedBctAmount}
                onChange={handleBctAmountChange}
                onFocus={(event) => event.target.select()}
              />
              <TradingButton disabled={isBuyButtonDisabled} onClick={handleBuyButtonClick}>
                Buy BCT
              </TradingButton>
            </TradingBox>
          </BoxesWrapper>
          <h5 className="sub-title help-center mg-bt-32 ">
            <Link to="#" style={{ textDecorationLine: "underline" }} onClick={watchBct}>
              Add BCT to Metamask
            </Link>
          </h5>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default BctPool;
