import BigNumber from "bignumber.js";

import React, { useEffect, useState } from "react";

import Messenger from "../../utils/Messenger";
import { useWeb3Context } from "../../contexts/Web3Context";
import { usePlayerContext } from "../../contexts/PlayerContext";

import {
  GlobalContainer,
  Container,
  StashUnstashContainer,
  Title,
  Description,
  Button,
  ButtonContainer,
} from "./styles";
import DepositButton from "../../components/deposit-button/DepositButton";

import { stasherLevelByBalance } from "../../utils/constants";
import { currentBlock, blocksToHours } from "../../utils/block";

const StashScreen = () => {
  const { isReady, consult } = useWeb3Context();
  const { player, stash, unstash } = usePlayerContext();

  const [inputValue, setInputValue] = useState("1000");
  const [isLoading, setIsLoading] = useState(false);
  const [stasherLevel, setStasherLevel] = useState(0);
  const [remainingHoursToUnstash, setRemainingHoursToUnstash] = useState(0);

  useEffect(() => {
    if (!player || !isReady) return;

    setStasherLevel(
      stasherLevelByBalance(parseFloat(BigNumber(player.stashed).div(1e18).toString()))
    );

    // let's fetch the stashedAt property from the contract and calculate the next withdraw date
    (async () => {
      let _currentBlock = currentBlock(
        player.connection.initialBlockNumber,
        player.connection.initialTimestamp
      );
      _currentBlock = BigNumber(_currentBlock);

      // @SOL returns (_balance, _level, _stashedAt, _unstashedAt, _canUnstashAt)
      const stashData = await consult({
        contractName: "Stasher",
        functionName: "getDataFor",
        functionParams: [player.address],
      });
      const data = {
        balance: BigNumber(stashData[0]),
        level: BigNumber(stashData[1]).toNumber(),
        stashedAt: BigNumber(stashData[2]).toNumber(),
        unstashedAt: BigNumber(stashData[3]).toNumber(),
        canUnstashAt: BigNumber(stashData[4]).toNumber(),
      };

      console.log("Stash Data: ", data);
      console.log("Current Block:", _currentBlock.toString());

      // if the player has never stashed, stashedAt will be 0
      if (data.canUnstashAt < _currentBlock) {
        setRemainingHoursToUnstash(0);
        return;
      } else {
        // now let's calculate how many hours until we can unstash
        const _remainingHoursToUnstash = (data.canUnstashAt - _currentBlock) / 6 / 60;
        setRemainingHoursToUnstash(_remainingHoursToUnstash);
      }
    })();
  }, [player]);

  const getRemainingTimeToUnstash = () => {
    // we'll convert from hours to days if the remaining time is more than 24 hours
    // we'll always round up to the next integer
    //const remainingHours = 0; // * @dev for testing
    const remainingHours = Math.ceil(remainingHoursToUnstash);
    const remainingDays = Math.floor(remainingHours / 24);
    const remainingHoursInDay = Math.ceil(remainingHours % 24);
    const endingLabel = ". When the time comes, a button will appear here.";
    if (remainingHours < 24) {
      if (remainingDays === 0 && remainingHoursInDay === 0) {
        return `...actually, right now!`;
      } else {
        return `${Math.ceil(remainingHours)} ${
          Math.ceil(remainingHours) === 1 ? "hour" : "hours"
        }${endingLabel}`;
      }
    } else {
      let hoursLabel;
      if (remainingDays === 1) {
        if (remainingHoursInDay > 0) {
          hoursLabel = ` and ${remainingHoursInDay} ${
            remainingHoursInDay === 1 ? "hour" : "hours"
          }`;
        } else {
          hoursLabel = "";
        }

        return `${remainingDays} day${hoursLabel}${endingLabel}`;
      } else {
        if (remainingHoursInDay > 0) {
          hoursLabel = ` and ${remainingHoursInDay} ${
            remainingHoursInDay === 1 ? "hour" : "hours"
          }`;
        } else {
          hoursLabel = "";
        }
        return `${remainingDays} days${hoursLabel}${endingLabel}`;
      }
    }
  };

  const handleStash = async (event) => {
    event.preventDefault(); // prevent the default form submission

    setIsLoading(true);

    if (
      inputValue === "" ||
      inputValue.indexOf(".") !== -1 ||
      inputValue.indexOf("-") !== -1 ||
      inputValue.indexOf("+") !== -1 ||
      inputValue.indexOf("e") !== -1 ||
      !inputValue ||
      inputValue === 0 ||
      isNaN(inputValue)
    ) {
      console.log(`The amount ${inputValue} is not valid.`);
      Messenger.error(`The amount ${inputValue} is not valid.`);

      setIsLoading(false);
      return;
    }

    console.log(`Stashing ${inputValue}`);

    await stash(BigNumber(inputValue).times(1e18).toString());
    setIsLoading(false);
  };

  const handleUnstash = async () => {
    console.log(`Unstashing...`);

    if (BigNumber(player.stashed).toFixed(0) === "0") {
      Messenger.error("You don't have any BCT stashed.");
      return;
    }

    await unstash();
  };

  return (
    <GlobalContainer>
      {player && (
        <Container>
          <StashUnstashContainer>
            <Title>Stash</Title>
            <Description>
              You currently have {BigNumber(player.stashed).div(1e18).toFixed(2)} BCT stashed.
            </Description>
            <Description>Your current Stasher Level is {stasherLevel} out of 10.</Description>
            {stasherLevel === 10 ? (
              <Description>You are at the maximum level.</Description>
            ) : (
              <>
                <Description>
                  Stash up to{" "}
                  {BigNumber("10000")
                    .times(1e18)
                    .minus(BigNumber(player.stashed))
                    .div(1e18)
                    .toFixed(2)}{" "}
                  and earn benefits.
                </Description>
                <form
                  action="#"
                  className="help-center-form for-small-button"
                  style={{ marginTop: "12px", marginBottom: "12px" }}
                >
                  <input
                    type="number"
                    min="0"
                    step="1"
                    value={inputValue}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/; // RegEx pattern for numbers and backspace
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setInputValue(e.target.value);
                      }
                    }}
                    pattern="^[0-9]"
                    id="centralizedInput"
                  />
                  <DepositButton
                    className="blue"
                    disabled={isLoading}
                    isLoading={isLoading}
                    onClick={(event) => handleStash(event)}
                  />
                </form>
              </>
            )}
          </StashUnstashContainer>
          <StashUnstashContainer>
            <Title>Unstash</Title>
            {stasherLevel < 1 ? (
              <Description>You don't have any BCT stashed.</Description>
            ) : (
              <>
                <Description>
                  You will be able to unstash{" "}
                  {Math.min(parseFloat(BigNumber(player.stashed).div(1e18).toFixed(2)), 2000)} BCT
                  in {getRemainingTimeToUnstash()}
                </Description>
                {getRemainingTimeToUnstash() === "...actually, right now!" && (
                  <ButtonContainer onClick={handleUnstash}>
                    <Button>Unstash</Button>
                  </ButtonContainer>
                )}
              </>
            )}
          </StashUnstashContainer>
        </Container>
      )}
    </GlobalContainer>
  );
};

export default StashScreen;
