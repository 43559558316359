import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 760px;
  height: auto;
  background-color: #111;
  border-radius: 10px;
  flex-wrap: wrap;
`;

export const BalanceGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const BalanceGroup = styled.div`
  display: flex;
  align-items: stretch;
  width: 350px;
  height: auto;
  overflow: hidden;
  padding: 10px;
  gap: 2px;

  outline: 3px solid #000;
  border-radius: 6px;

  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.7) 20%,
      rgba(0, 0, 0, 0.7) 80%,
      rgba(0, 0, 0, 0.87)
    ),
    linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),
    linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),
    linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;

  margin-right: 20px;
  margin-left: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));

  animation: ${fadeIn} 1s ease-in-out;

  @media (max-width: 450px) {
    width: 280px;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
`;

export const PlayerNotFound = styled.div`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;

  // centralize it inside the container:
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // make it fill the container:
  width: 330px;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

export const Subtitle = styled.h1`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #ddd;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TransferToInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

export const TransferToInput = styled.input`
  width: 294px !important;
  height: 40px !important;

  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  font-size: 1.06rem !important;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #eee !important;
  margin-bottom: 12px;
  outline: none;

  &::placeholder {
    font-size: 1.5rem !important;
    position:relative;
    top: 2px;
  }
`;

export const TransferToLoaderButton = styled.div`
  width: 60px;
  height: 40px;

  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  border: none;
  background-color: #080;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(341deg) brightness(109%)
      contrast(101%);
  }

  &:hover {
    background-color: #070;
  }
`;
