import BigNumber from "bignumber.js";

export function getElapsedBlocks(startedAt, currentBlock) {
  const elapsedBlocks = currentBlock - startedAt;
  return elapsedBlocks;
}

export function getElapsedSeconds(startedAt, currentBlock) {
  return getElapsedBlocks(startedAt, currentBlock) * 10;
}

export function getRemainingTime(startedAt, currentBlock) {
  // A dutch auction takes 3 hours to finish (that's 1200 * 10 blocks, or 10800 seconds)

  const inSeconds = getElapsedSeconds(startedAt, currentBlock);

  if (inSeconds >= 1080) {
    // auction is over
    return "Minimum Price Reached";
  }

  // we want the remaining time, not the elapsed:
  const remainingSeconds = 1080 - inSeconds;

  const hours = `0${Math.floor((remainingSeconds % 86400) / 3600)}`.slice(-2);
  const minutes = `0${Math.floor((remainingSeconds % 3600) / 60)}`.slice(-2);
  const seconds = `0${Math.floor(remainingSeconds % 60)}`.slice(-2);
  const time = `${hours}:${minutes}:${seconds}`;

  // now get the percentage that represents:
  let percentage = getProgressBarPercentage(startedAt, currentBlock);
  percentage = Math.ceil(percentage * 100) / 100;
  // add any needed zeroes to the right:
  percentage = BigNumber(percentage).times(75).div(100).toFixed(2);

  return `${time}`;
}

export function getProgressBarPercentage(startedAt, currentBlock) {
  // 100% is going to be 6 hours (7200 blocks); anything higher than that will be considered 100%
  const elapsedBlocks = getElapsedBlocks(startedAt, currentBlock);
  const percentage = Math.min(elapsedBlocks / 36, 100);
  return percentage;
}

export function getRemainingTimeSpiral(startedAt, currentBlock) {
  if (!currentBlock) {
    return "Log in to see remaining time";
  }

  // A spiral auction takes 24 hours to finish (that's 8640 blocks, or 8640 * 10 seconds)
  const inSeconds = getElapsedSeconds(startedAt, currentBlock);

  if (inSeconds >= 8640 * 10) {
    // auction is over
    return "Auction Closed";
  }

  // we want the remaining time, not the elapsed:
  const remainingSeconds = 8640 * 10 - inSeconds;

  const hours = `0${Math.floor((remainingSeconds % 86400) / 3600)}`.slice(-2);
  const minutes = `0${Math.floor((remainingSeconds % 3600) / 60)}`.slice(-2);
  const seconds = `0${Math.floor(remainingSeconds % 60)}`.slice(-2);
  const time = `${hours}:${minutes}:${seconds}`;

  return `${time}`;
}

export function getProgressBarPercentageSpiral(startedAt, currentBlock) {
  // 100% is going to be 24 hours (8640 blocks); anything higher than that will be considered 100%
  const elapsedBlocks = getElapsedBlocks(startedAt, currentBlock);
  const percentage = Math.min((elapsedBlocks / 8640) * 100, 100);
  return percentage;
}
