import React, { useState } from "react";
import BigNumber from "bignumber.js";
import { format } from "../../../utils/math";
import Contracts from "../../../utils/Contracts";

import {
  Container,
  AccountDetails,
  TitleBox,
  Title,
  TopRightButtons,
  AdvancedButton,
  RobotButton,
  DetailItem,
  NotFound,
  PaginationContainer,
  PaginationButton,
  PaginationText,
  QueryButton,
  ExecuteButton,
} from "./styles";

import ItemValue from "../item-value/ItemValue";
import ItemInput from "../item-input/ItemInput";
import { capitalize } from "../../../utils/capitalize";

import { useWeb3Context } from "../../../contexts/Web3Context";

const TX_PER_PAGE = 25;

const Token = ({
  isRefreshingTxns = false,
  isFetching = false,
  address = "",
  token = null,
  searchCallback = null,
  changePageCallback = null,
}) => {
  const { consult, execFunction, isReady } = useWeb3Context();
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isContractView, setIsContractView] = useState(false);
  const [methodInputs, setMethodInputs] = useState({});
  const [methodResults, setMethodResults] = useState({});

  BigNumber.config({ DECIMAL_PLACES: 18 });
  const totalPages = Math.ceil(token.txTotal / TX_PER_PAGE);

  const toggleAdvanced = () => {
    setIsAdvanced(!isAdvanced);
  };

  const toggleContractView = () => {
    setIsAdvanced(false);
    setIsContractView(!isContractView);
  };

  const handleInputChange = (methodName, paramIndex, value) => {
    setMethodInputs((prevInputs) => ({
      ...prevInputs,
      [methodName]: {
        ...prevInputs[methodName],
        [paramIndex]: value,
      },
    }));
  };

  const handlePrevious = () => {
    const _page = currentPage > 1 ? currentPage - 1 : 1;
    setCurrentPage(_page);

    if (changePageCallback) {
      changePageCallback(_page);
    }
  };

  const handleNext = () => {
    const _page = currentPage < totalPages ? currentPage + 1 : totalPages;
    setCurrentPage(_page);

    if (changePageCallback) {
      changePageCallback(_page);
    }
  };

  const handleLast = () => {
    setCurrentPage(totalPages);

    if (changePageCallback) {
      changePageCallback(totalPages);
    }
  };

  const handleFirst = () => {
    setCurrentPage(1);

    if (changePageCallback) {
      changePageCallback(1);
    }
  };

  const executeMethod = async (methodName, isExecution = false) => {
    // Clear the previous result for this method
    setMethodResults((prevResults) => ({
      ...prevResults,
      [methodName]: undefined,
    }));

    let params = methodInputs[methodName] || [];
    console.log(`executeMethod ${methodName} with params:`, params);

    let koziAmount = 0;
    // if the params object has a property called "koziValue", it's the amount of KOZI to send;
    // we will save it and remove it from the params object
    if (params.koziValue) {
      koziAmount = params.koziValue;
      delete params.koziValue;
    }

    // params come always as strings, but we need to transform them to the correct type
    // before sending them to the contract; if they are strings of arrays, we need to
    // parse them as JSON so they become arrays again
    params = Object.values(params).map((param) => {
      try {
        return JSON.parse(param);
      } catch (e) {
        return param;
      }
    });

    // now convert all numbers to strings
    params = params.map((param) => {
      if (typeof param === "number") {
        return BigNumber(param).toFixed(0);
      }
      return param;
    });

    if (isExecution) {
      const hash = await execFunction({
        contractName: Contracts.addressToKey(token.address),
        functionName: methodName,
        functionParams: params,
        successMessage: "Transaction executed",
        koziAmount,
      });

      setMethodResults((prevResults) => ({
        ...prevResults,
        [methodName]: !hash.startsWith("0x")
          ? `Error: this transaction will certainly fail. Reason: "${hash}"`
          : `Function executed: ${hash}`,
      }));
    } else {
      const result = await consult({
        contractName: Contracts.addressToKey(token.address),
        functionName: methodName,
        functionParams: Object.values(params),
      });

      setMethodResults((prevResults) => ({
        ...prevResults,
        [methodName]: result,
      }));
    }
  };

  return (
    <Container>
      {token.address ? (
        <AccountDetails>
          <TitleBox>
            <Title>Token Details</Title>
            {window.innerWidth > 580 && (
              <TopRightButtons>
                {token.isContract && (
                  <RobotButton onClick={toggleContractView} isContractView={isContractView}>
                    {isContractView ? "🤖" : "🧑"}
                  </RobotButton>
                )}
                {!isContractView && (
                  <AdvancedButton onClick={toggleAdvanced} isAdvanced={isAdvanced}>
                    {isAdvanced ? "Advanced" : "Standard"}
                  </AdvancedButton>
                )}
              </TopRightButtons>
            )}
          </TitleBox>

          <DetailItem>
            <strong>Name:</strong> {<ItemValue value={token.name} />}
          </DetailItem>

          <DetailItem>
            <strong>Symbol:</strong> {<ItemValue value={token.symbol} />}
          </DetailItem>

          <DetailItem>
            <strong>Decimals:</strong> {<ItemValue value={token.decimals} />}
          </DetailItem>

          <DetailItem>
            <strong>Total Supply:</strong>{" "}
            {<ItemValue value={token.totalSupply} toLocaleString={true} />}
          </DetailItem>

          <DetailItem>
            <strong>Address:</strong> {<ItemValue value={token.address} showPlainAddress={true} />}
          </DetailItem>

          {isAdvanced && token?.txList?.length && (
            <>
              {token.txList.map((tx, index) => (
                <div
                  key={index + tx.hash}
                  style={{
                    marginTop: "20px",
                    outline: "1px solid white",
                    padding: "4px 10px 1px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#000010",
                  }}
                >
                  <DetailItem>
                    <strong>Action:</strong>{" "}
                    <ItemValue onClick={searchCallback} isAction={true} value={tx.action} />
                  </DetailItem>
                  <DetailItem>
                    <strong>From:</strong> <ItemValue onClick={searchCallback} value={tx.from} />
                  </DetailItem>
                  {tx.action !== "Contract Deployment" && (
                    <DetailItem>
                      <strong>To:</strong> <ItemValue onClick={searchCallback} value={tx.to} />
                    </DetailItem>
                  )}
                  {tx.action === "KOZI Transfer" && (
                    <DetailItem>
                      <strong>Amount:</strong>{" "}
                      <ItemValue value={BigNumber(tx.value).dividedBy(1e18).toString()} />
                    </DetailItem>
                  )}
                  <DetailItem>
                    <strong>Block:</strong>{" "}
                    {
                      <ItemValue
                        onClick={searchCallback}
                        value={parseInt(tx.blockNumber)}
                        isBlockNumber={true}
                      />
                    }
                  </DetailItem>
                  <DetailItem>
                    <strong style={tx.status === 1 ? null : { color: "#F44" }}>
                      {tx.status === 1 ? `Mined:` : `Failed:`}
                    </strong>{" "}
                    {<ItemValue onClick={searchCallback} value={tx.age} />}
                  </DetailItem>
                  <DetailItem>
                    <strong>Hash:</strong>{" "}
                    <ItemValue onClick={searchCallback} value={tx.hash} isTxHash={true} />
                  </DetailItem>
                </div>
              ))}
            </>
          )}
          {isAdvanced && token?.txTotal > TX_PER_PAGE && (
            <PaginationContainer>
              <PaginationButton
                onClick={handleFirst}
                disabled={currentPage === 1 || isRefreshingTxns}
                style={{ marginRight: "-10px", fontSize: "12px" }}
              >
                First
              </PaginationButton>
              <PaginationButton
                onClick={handlePrevious}
                disabled={currentPage === 1 || isRefreshingTxns}
              >
                ←
              </PaginationButton>
              <PaginationText>{`${currentPage} / ${totalPages}`}</PaginationText>
              <PaginationButton
                onClick={handleNext}
                disabled={currentPage === totalPages || isRefreshingTxns}
              >
                →
              </PaginationButton>
              <PaginationButton
                onClick={handleLast}
                disabled={currentPage === totalPages || isRefreshingTxns}
                style={{ marginLeft: "-10px", fontSize: "12px" }}
              >
                Last
              </PaginationButton>
            </PaginationContainer>
          )}
          {isAdvanced && (!token?.txList || token?.txList?.length === 0) && (
            <DetailItem style={{ color: "yellow" }}>
              <NotFound>{`The transaction list is empty.`}</NotFound>
            </DetailItem>
          )}

          {isContractView && token.isContract && token?.viewMethods?.length > 0 && (
            <>
              <div style={{ marginTop: "20px" }}>
                <h3>{"Direct Interaction"}</h3>
              </div>
              {token.viewMethods.map((method, index) => (
                <div
                  key={index + method.name}
                  style={{
                    marginTop: "20px",
                    outline: "1px solid white",
                    padding: "4px 10px 1px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#000010",
                  }}
                >
                  <DetailItem>
                    <strong>{`${index + 1}. ${capitalize(method.name)}`}</strong>
                    <QueryButton onClick={() => executeMethod(method.name)}>Query</QueryButton>
                  </DetailItem>
                  {method?.inputs?.length > 0 &&
                    method.inputs.map((input, _index) => (
                      <DetailItem key={`Input-${index}-${_index}`}>
                        <ItemInput
                          placeholder={`${input?.name} (${String(input?.type)})`}
                          onChange={(e) => handleInputChange(method.name, _index, e.target.value)}
                        />
                      </DetailItem>
                    ))}
                  {methodResults[method.name] !== null &&
                    methodResults[method.name] !== undefined && (
                      <DetailItem>
                        <strong
                          style={
                            String(methodResults[method.name]).indexOf("Error:") !== -1
                              ? { color: "#F43" }
                              : { color: "#3F3" }
                          }
                        >
                          Result:
                        </strong>
                        <ItemValue
                          value={String(methodResults[method.name])}
                          ignorePrettyWeiFormat={true}
                        />
                      </DetailItem>
                    )}
                </div>
              ))}
              {token.changeMethods.map((method, index) => (
                <div
                  key={token.viewMethods.length + index + method.name}
                  style={{
                    marginTop: "20px",
                    outline: "1px solid white",
                    padding: "4px 10px 1px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#000010",
                  }}
                >
                  <DetailItem>
                    <strong>{`${token.viewMethods.length + index + 1}. ${capitalize(
                      method.name
                    )}`}</strong>
                    {method.stateMutability === "payable" && (
                      <span
                        style={{ position: "relative", left: "4px", color: "yellow" }}
                      >{`(payable)`}</span>
                    )}
                    <ExecuteButton onClick={() => executeMethod(method.name, true)}>
                      Execute
                    </ExecuteButton>
                  </DetailItem>
                  {method.stateMutability === "payable" && (
                    <DetailItem>
                      <ItemInput
                        placeholder={`KOZI to send (uint256)`}
                        onChange={(e) =>
                          handleInputChange(
                            method.name,
                            0,
                            e.target.value,
                            method.stateMutability,
                            true // isKoziAmount
                          )
                        }
                      />
                    </DetailItem>
                  )}
                  {method?.inputs?.length > 0 &&
                    method.inputs.map((input, _index) => (
                      <DetailItem key={`Input-${index}-${_index}`}>
                        <ItemInput
                          placeholder={`${input?.name} (${String(input?.type)})`}
                          onChange={(e) => handleInputChange(method.name, _index, e.target.value)}
                        />
                      </DetailItem>
                    ))}
                  {methodResults[method.name] !== null &&
                    methodResults[method.name] !== undefined && (
                      <DetailItem>
                        <strong
                          style={
                            String(methodResults[method.name]).indexOf("Error:") !== -1
                              ? { color: "#F43" }
                              : { color: "#3F3" }
                          }
                        >
                          Result:
                        </strong>
                        <ItemValue
                          value={String(methodResults[method.name])}
                          ignorePrettyWeiFormat={true}
                        />
                      </DetailItem>
                    )}
                </div>
              ))}
            </>
          )}
        </AccountDetails>
      ) : !isFetching ? (
        address && <NotFound>{`Address ${address} not found.`}</NotFound>
      ) : (
        address && <NotFound>{`Loading...`}</NotFound>
      )}
    </Container>
  );
};

export default Token;
