import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import shape1 from "../../assets/images/backgroup-secsion/bg-gradient1.png";
import shape2 from "../../assets/images/backgroup-secsion/bg-gradient2.png";
import shape3 from "../../assets/images/backgroup-secsion/bg-gradient3.png";
import imgbg from "../../assets/images/backgroup-secsion/img_bg_page_title.jpg";

import {
  MobileContainer,
  MobileTitle,
  MobileButtons,
  TitleStyle,
  SubtitleStyle,
  ThirdTitleStyle,
  SubHeadingStyle,
} from "./styles";

const Slider = (props) => {
  const data = props.data;
  return (
    <div className="mainslider">
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: false }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className={item.class}>
            <SliderItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  data: PropTypes.array.isRequired,
  control: PropTypes.bool,
  auto: PropTypes.bool,
  timeOut: PropTypes.number,
};

const SliderItem = (props) => (
  <>
    <div className="flat-title-page" style={{ backgroundImage: `url(${imgbg})`, border: "none" }}>
      <img className="bgr-gradient gradient1" src={shape1} alt="bct" />
      <img className="bgr-gradient gradient2" src={shape2} alt="bct" />
      <img className="bgr-gradient gradient3" src={shape3} alt="bct" />
      <div className="shape item-w-16"></div>
      <div className="shape item-w-22"></div>
      <div className="shape item-w-32"></div>
      <div className="shape item-w-48"></div>
      <div className="shape style2 item-w-51"></div>
      <div className="shape style2 item-w-51 position2"></div>
      <div className="shape item-w-68"></div>
      <div className="overlay"></div>
      <div className="swiper-container mainslider home">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="slider-item">
              <div className="themesflat-container ">
                <div className="wrap-heading flat-slider flex">
                  <div className="content">
                    <TitleStyle>{props.item.title_1}</TitleStyle>
                    <SubtitleStyle>
                      <span className="tf-text s1">{props.item.title_2}</span>
                    </SubtitleStyle>
                    <ThirdTitleStyle>{props.item.title_3}</ThirdTitleStyle>
                    <SubHeadingStyle>{props.item.description}</SubHeadingStyle>
                    <div className="flat-bt-slider flex style2">
                      <MobileContainer>
                        <Link
                          to="/whitepaper"
                          className="sc-button style-1"
                          style={{ margin: "5px" }}
                        >
                          <span>📃 Whitepaper</span>
                        </Link>
                        <Link
                          to="/travelsong"
                          className="sc-button style-1"
                          style={{ margin: "5px" }}
                        >
                          <span>🎼 Block Explorer</span>
                        </Link>
                        <Link
                          to="/add-kingdom-chain"
                          className="sc-button style-1"
                          style={{ margin: "5px" }}
                        >
                          <span>🦊 Add to Metamask</span>
                        </Link>
                      </MobileContainer>
                    </div>
                  </div>
                  <div className="image">
                    <img src={props.item.img} alt="Kingdom Chain" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="tf-help-center tf-section">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-12">
            <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
            <h5 className="sub-title help-center mg-bt-32 "></h5>
          </div>
        </div>
      </div>
    </section>
  </>
);
export default Slider;
