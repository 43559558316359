import BigNumber from "bignumber.js";
import ContractModel from "./ContractModel.js";

class TokenModel extends ContractModel {
  constructor(address) {
    super(address);

    this.name = "";
    this.symbol = "";
    this.decimals = 18;
    this.totalSupply = "0";
  }

  async setTokenData(data) {
    console.log("Setting token data:", data);

    this.name = data[0];
    this.symbol = data[1];
    this.decimals = parseInt(data[2]);
    this.totalSupply = BigNumber(data[3]).toString();

    return this; // return updated instance
  }
}

export default TokenModel;
