import BigNumber from "bignumber.js";

export function priceFromReserves(gameTokenReserve, stabletokenReserve) {
  return quoteBuyGameTokens(BigNumber(1e18), gameTokenReserve, stabletokenReserve);
}

export function quoteBuyGameTokens(gameTokensToBuy, gameTokenReserve, stabletokenReserve) {
  const costInStabletokens = BigNumber(stabletokenReserve.toString())
    .times(gameTokensToBuy)
    .dividedBy(BigNumber(gameTokenReserve.toString()).minus(gameTokensToBuy))
    .plus(1);

  return costInStabletokens;
}

export function quoteStableToGameTokens(stabletokens, gameTokenReserve, stabletokenReserve) {
  const gameTokens = BigNumber(gameTokenReserve.toString())
    .times(stabletokens)
    .dividedBy(BigNumber(stabletokenReserve.toString()).plus(stabletokens));

  return gameTokens;
}

export function quoteSellGameTokens(
  gameTokensToSell,
  gameTokenReserve,
  stabletokenReserve,
  originalFeePct
) {
  let feePct = parseInt(BigNumber(originalFeePct));
  const finalGameTokensToSell = BigNumber(gameTokensToSell).minus(
    BigNumber(gameTokensToSell).times(feePct).dividedBy(100)
  );

  const stabletokensOut = BigNumber(stabletokenReserve)
    .times(finalGameTokensToSell)
    .dividedBy(BigNumber(gameTokenReserve).plus(finalGameTokensToSell));

  return stabletokensOut;
}

export function _bctToBusd(bctAmount, gameTokenReserve, stabletokenReserve) {
  return quoteBuyGameTokens(bctAmount, gameTokenReserve, stabletokenReserve);
}

export function _bctToEbct(bctAmount) {
  // eBCT is always 30% greater than BCT
  return BigNumber(bctAmount).times(1.3);
}

export function _busdToBct(busdAmount, gameTokenReserve, stabletokenReserve) {
  return quoteStableToGameTokens(busdAmount, gameTokenReserve, stabletokenReserve);
}

export function _busdToEbct(busdAmount, gameTokenReserve, stabletokenReserve) {
  return _bctToEbct(_busdToBct(busdAmount, gameTokenReserve, stabletokenReserve));
}

export function _ebctToBct(ebctAmount) {
  return BigNumber(ebctAmount).dividedBy(1.3);
}

export function _ebctToBusd(ebctAmount, gameTokenReserve, stabletokenReserve) {
  return _bctToBusd(_ebctToBct(ebctAmount), gameTokenReserve, stabletokenReserve);
}
