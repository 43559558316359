import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header.jsx";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

import FurAndFortuneScreen from "../components/fur-and-fortune-screen/FurAndFortuneScreen";

import { useWeb3Context } from "../contexts/Web3Context";
import BigNumber from "bignumber.js";

const FurAndFortune = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const { isReady, consult } = useWeb3Context();
  const [jackpot, setJackpot] = useState("?");
  const [collapsed, setCollapsed] = useState(false);

  const getJackpot = async () => {
    const totalPot = await consult({
      contractName: "KingdomFunds",
      functionName: "balanceOf",
      functionParams: [3], // fund #3 is the Fur & Fortune jackpot
    });
    const _jackpot = BigNumber(totalPot)
      .times(25)
      .div(100)
      .div(1e18)
      .toFixed(2, BigNumber.ROUND_DOWN);

    setJackpot(_jackpot);
  };

  useEffect(() => {
    if (isReady) {
      getJackpot();
    }
  }, [isReady]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Header />

      {!IS_PAUSED ? (
        <div>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🍀</h1>
                    <h1 className="heading text-center">Fur & Fortune</h1>
                  </div>
                  {!collapsed ? (
                    <div>
                      <h5 className="sub-title help-center mg-bt-32 ">
                        Where Kingdom Nobles expand their prosperity
                      </h5>
                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Spend one Lucky Ticket (🎫) to test your fortune and win prizes.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        There are 25 prize categories, and each category has 6 unique prizes. For
                        each category, you have a 67% chance of winning the small prize, a 26.4%
                        chance of winning either a medium or high prize, and a 6.6% chance of
                        winning the very high prize. Each time you play Fur & Fortune, you can win a
                        prize from one of the 25 available categories.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Spend extra Lucky Tickets to win in more categories.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        Every extra Lucky Ticket you spend guarantees a prize from an additional
                        category. For example, spending the maximum of 5 extra Lucky Tickets will
                        ensure you receive prizes from 6 categories instead of just 1.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Sacrifice beasts to gain Luck (🍀) and dominate the game.
                      </h5>
                      <h5 className="sub-title help-center small">
                        You can sacrifice up to one beast of each species. Regardless of species,
                        you gain 1🍀 if it's a common beast, 2🍀 if it's rare, 3🍀 if it's epic, 4🍀
                        if it's legendary, and 5🍀 if it's a mythic beast. Then, you may add Luck to
                        any category, or disable a category altogether by spending 2🍀.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        Adding Luck to a category means removing its small prize. When you do that,
                        your chances of getting a medium or high prize in that category increase to
                        80%, and your chance of getting the very high prize increases to 20% in that
                        category.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Spend one Prestige Trophy (🏆) to increase your luck in all categories.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        Instead of sacrificing beasts to improve your chances in each category, you
                        can spend a single Prestige Trophy to gain 25🍀. With that, you can add Luck
                        to ALL categories.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Spend KOZI (🧿) directly to double all prizes.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        In addition, spending KOZI directly allows you to double the prizes across
                        all categories.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Don't faint while playing!
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        You will execute exactly 3 transactions each time you play Fur & Fortune.
                        The whole game takes approximately one minute to unfold. Altough you can
                        take your time to press buttons, when you start a game, you MUST go all the
                        way to the end in no more than 30 minutes, or you'll automatically forfeit
                        your prizes.
                      </h5>
                    </div>
                  ) : (
                    <h5 className="sub-title help-center small" style={{ marginBottom: "-20px" }}>
                      <Link
                        to="#"
                        onClick={toggleCollapse}
                        style={{ fontSize: "12px", textDecoration: "underline" }}
                      >
                        Rules of the Game
                      </Link>
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </section>

          <FurAndFortuneScreen jackpot={jackpot} />
        </div>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default FurAndFortune;
