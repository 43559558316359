import React, { createContext, useEffect, useState, useContext, useCallback } from "react";
import { toast } from "react-toastify";

// Contexts:
import { useWeb3Context } from "./Web3Context";
import { usePlayerContext } from "./PlayerContext";

export const KingdomQuestsContext = createContext({});

export const useKingdomQuestsContext = () => {
  return useContext(KingdomQuestsContext);
};

export const KingdomQuestsProvider = ({ children }) => {
  // Get execFunction from the Web3Context
  const { execFunction } = useWeb3Context();

  // Get the player data from the PlayerContext
  const { player, isRegistered } = usePlayerContext();

  const requireSquadNotOnQuest = (squadId) => {
    const isSquadOnQuest = player.squads.find((squad) => squad.id === squadId).quest?.id > 0;
    if (isSquadOnQuest) {
      toast.error(`Squad #${squadId} is on a quest. Please finish it first.`, {
        type: toast.TYPE.WARNING,
        autoClose: 5000,
      });
      return false;
    }
    return true;
  };

  // Add an NFT to a squad
  const addToSquad = async (nftId, squadId) => {
    if (!player) return;
    if (!isRegistered) return;
    if (!requireSquadNotOnQuest(squadId)) return;

    console.log(`Adding nft ${nftId} to squad ${squadId} using account ${player.address}`);
    await execFunction({
      contractName: "Farm",
      functionName: "addToSquad",
      functionParams: [[nftId], squadId],
      successMessage: `Beast ${nftId} added to squad.`,
      errorList: [
        {
          reason: "3",
          message: `Beast #${nftId} is on a quest. Please finish it first.`,
        },
      ],
      navigateOnSuccess: "/kingdom-quests",
    });
  };

  // Remove an NFT from a squad
  const removeFromSquad = async (nftId, squadId) => {
    if (!player) return;
    if (!isRegistered) return;
    if (!requireSquadNotOnQuest(squadId)) return;

    console.log(`Removing nft ${nftId} from squad ${squadId} using account ${player.address}`);
    await execFunction({
      contractName: "Farm",
      functionName: "removeFromSquad",
      functionParams: [[nftId], squadId],
      successMessage: `Beast ${nftId} removed from squad.`,
      errorList: [
        {
          reason: "3",
          message: `Beast #${nftId} is on a quest. Please finish it first.`,
        },
      ],
      navigateOnSuccess: "/kingdom-quests",
    });
  };

  // Start a Quest
  const startQuest = async (squadId, questId) => {
    if (!player) return;
    if (!isRegistered) return;
    if (!requireSquadNotOnQuest(squadId)) return;

    console.log(`Starting quest ${questId} with squad ${squadId} using account ${player.address}`);

    await execFunction({
      contractName: "QuestManager",
      functionName: "startQuest",
      functionParams: [squadId, questId],
      successMessage: `Quest ${questId} started!`,
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
        {
          reason: "missingResources",
          message: `Not enough balance to start the quest.`,
        },
        {
          reason: "4",
          message: `Only Stashers Level 9 and above can spend Dragon Scales to start a quest.`,
        },
      ],
      navigateOnSuccess: "/kingdom-quests",
    });
  };

  // Start a Quest
  const finishQuest = async (squadId) => {
    if (!player) return;
    if (!isRegistered) return;

    console.log(`Finishing quest with squad ${squadId} using account ${player.address}`);

    await execFunction({
      contractName: "QuestManager",
      functionName: "finishQuest",
      functionParams: [squadId],
      successMessage: `Quest finished! Now that you're loaded, check your balance and upgrade your NFTs!`,
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: "/kingdom-quests",
    });
  };

  return (
    <KingdomQuestsContext.Provider
      value={{
        useKingdomQuestsContext,
        isRegistered,
        addToSquad,
        removeFromSquad,
        startQuest,
        finishQuest,
      }}
    >
      {children}
    </KingdomQuestsContext.Provider>
  );
};
