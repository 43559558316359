import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  bottom: 5px;
  left: 2px;
`;

export const Input = styled.input`
  border-radius: 4px;
  height: 30px;
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 0 10px;
  font-size: 12px !important;
  background: rgba(0, 20, 30) !important;
  transition: all 0.3s ease-in-out;

  // remove active border
  &:focus {
    outline: none;
  }
`;
