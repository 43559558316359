import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: none !important;

  background: ${(props) => (props.isConnected ? "#114111" : "#2f40ed")};

  :hover {
    background: ${(props) => (props.isConnected ? "#040" : "#2f80ed")};
    border: ${(props) => (props.isConnected ? "1px solid #040" : "none")};
  }

  span {
    color: #fff;

    :hover {
      color: #fff;
    }
  }

  transition: all 0.5s ease-in-out;
`;
