import React, { useState } from "react";

import {
  OuterContainer,
  InnerContainer,
  TopLineContainer,
  NoNftsContainer,
  Wrapper,
} from "./styles";

import SquadBonus from "./squad-bonus";
import SquadInfo from "./squad-info";
import SquadAction from "./squad-action";

import Quest from "./quest";
import { usePlayerContext } from "../../contexts/PlayerContext";

import QuestSelectorModal from "../quest-selector-modal-v2";

const SquadHeader = ({ squad }) => {
  const { player, isRegistered } = usePlayerContext();

  const [selectedQuest, setSelectedQuest] = useState(squad.currentQuest || squad.quest);
  const [showQuestSelector, setShowQuestSelector] = useState(false);

  const showSelectQuestModal = () => {
    setShowQuestSelector(true);
  };

  if (!isRegistered || !player || player?.info?.registeredAt === 0) {
    return null;
  }

  return (
    <Wrapper>
      {squad?.nfts.length ? (
        <OuterContainer>
          {showQuestSelector && (
            <QuestSelectorModal
              setSelectedQuest={setSelectedQuest}
              setShowQuestSelector={setShowQuestSelector}
              showQuestSelector={showQuestSelector}
              squad={squad}
              player={player}
            />
          )}
          <TopLineContainer>
            <SquadAction
              squad={squad}
              openQuestModalCallback={showSelectQuestModal}
              className="clickable"
            ></SquadAction>
            <InnerContainer>
              <SquadInfo squad={squad}></SquadInfo>
              <SquadBonus squad={squad}></SquadBonus>
            </InnerContainer>
          </TopLineContainer>
        </OuterContainer>
      ) : null}
      <Quest squad={squad} questId={selectedQuest} onForceOpenQuestSelection={showSelectQuestModal}/>

      {squad?.nfts.length === 0 && (
        <NoNftsContainer>{`This squad is empty. Add beasts into it and start farming to collect resources. Then, either use those resources to improve your beasts and farm even more, or sell them to other players.`}</NoNftsContainer>
      )}
    </Wrapper>
  );
};

export default SquadHeader;
