import styled from "styled-components";
import SynergyOverlay from "../../../assets/images/squads-card/SynergyOverlay.png";

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const FarmAndSynergyContainer = styled.div`
  display: flex;
  justify-content: space-center;
  align-items: center;
  width: 180px;
  height: 100%;
  cursor: default;

  @media (max-width: 685px) {
    height: 30px;
    width: 250px;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }

  @media (max-width: 450px) {
    width: 180px;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
`;

export const GradientContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: ${(props) =>
    props.isFarmPerBlock
      ? "linear-gradient(0deg, rgba(0, 65, 20, 1), rgba(0, 120, 0, 1))"
      : props.isSynergy
      ? "linear-gradient(0deg, rgba(0, 65, 20, 1), rgba(0, 120, 0, 1))"
      : "linear-gradient(0deg, rgba(15, 52, 72, 1), rgba(15, 32, 62, 1))"};

  h4 {
    font-size: 17px;
  }

  @media (max-width: 450px) {
    h4 {
      font-size: 14px;
    }

    h6 {
      font-size: 11px;
    }
  }
`;

export const CollectionAndTraitContainer = styled.div`
  display: flex;
  justify-content: space-center;
  align-items: center;
  width: 176px;
  height: 100%;
  margin: 0 3px;
  cursor: default;

  @media (max-width: 685px) {
    height: 30px;
    width: 124px;
    margin: 0 0px;
  }

  @media (max-width: 450px) {
    width: 108px;
    margin: 0;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
`;

export const GradientContainerSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: linear-gradient(0deg, rgba(15, 52, 72, 1), rgba(15, 32, 52, 1));

  @media (max-width: 450px) {
    h4 {
      font-size: 14px;
    }

    h6 {
      font-size: 11px;
    }
  }
`;

export const SquadNumberWrapper = styled.div`
  padding: 1px;
  width: 100%;
  height: 100%;
`;

export const TraitImage = styled.img`
  width: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  height: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  position: relative;
`;

export const TraitGlow = styled.div`
  position: relative;
  top: 3px;
  right: 1px;
  display: flex;
  background: ${(props) => (props.isGlowing ? "#ffe176" : "transparent")};
  box-shadow: ${(props) =>
    props.isGlowing ? "0px 0px 3px 1px #ffe176" : "0px 0px 3px 1px transparent"};
  width: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  height: ${(props) => (props.scale ? `${26 * props.scale}px` : `26px`)};
  border-radius: 50%;
  margin-left: ${(props) => (props.scale ? `${3 * props.scale}px` : `3px`)};
  margin-bottom: ${(props) => (props.scale ? `${6 * props.scale}px` : `6px`)};

  &::before {
    content: ${(props) => (props.isGlowing ? '""' : "none")};
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.8;
    box-shadow: inset 0px 0px 3px 2px #ffe176;
    z-index: 2;
  }

  &::after {
    content: ${(props) => (props.isGlowing ? '""' : "none")};
    position: absolute;
    background: url(${SynergyOverlay}) no-repeat center;
    background-size: contain;
    top: -3px;
    left: -3px;
    opacity: 0.4;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    z-index: 3;
    animation: rotate 40s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  img {
    position: relative;
    z-index: 1;
  }

  @media (max-width: 685px) {
    width: ${(props) => (props.scale ? `${20 * props.scale}px` : `20px`)};
    height: ${(props) => (props.scale ? `${18 * props.scale}px` : `18px`)};
    top: 2px;
    right: 2px;

    &::before {
      top: 2px;
      left: 0px;
    }

    &::after {
      top: -1px;
      left: -1px;
    }

    img {
      position: relative;
      top: -2.5px;
    }
  }

  @media (max-width: 450px) {
    width: ${(props) => (props.scale ? `${18 * props.scale}px` : `18px`)};
    height: ${(props) => (props.scale ? `${16 * props.scale}px` : `16px`)};
    top: 2px;
    right: 2px;

    &::before {
      top: 1px;
      left: 0px;
    }

    &::after {
      top: -1px;
      left: -1px;
    }

    img {
      position: relative;
      top: -4px;
    }
  }
`;
