import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import { Link } from "react-router-dom";

import WithdrawScreen from "../components/withdraw-screen/WithdrawScreen";
import Contracts from "../utils/Contracts";

import { useWeb3Context } from "../contexts/Web3Context";
import { fromWei } from "../utils/wei";

const Withdraw = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE, consult, isReady } = useWeb3Context();
  const [farmBalance, setFarmBalance] = useState(
    "Unable to fetch the Farm's balance. Please check it on Travel Song."
  );

  useEffect(() => {
    if (!isReady) return;

    const farmAddress = Contracts.keyToAddress("Farm");

    const fetchFarmBalance = async () => {
      const _balance = await consult({
        contractName: "BCT",
        functionName: "balanceOf",
        functionParams: [farmAddress],
      });

      console.log("Farm balance: ", _balance);

      setFarmBalance(parseFloat(fromWei(_balance)).toLocaleString());
    };

    fetchFarmBalance();
  }, [isReady, consult]);

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🤑</h1>
                    <h1 className="heading text-center">Withdraw</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      You can withdraw BCT from the game to your Metamask with a minimum interval of
                      15 days between withdrawals.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Whenever you withdraw BCT, 15% of it returns to farm to be farmed by other
                      players (and yourself).
                    </h5>
                    <h5
                      className="sub-title help-center"
                      style={{ color: "yellow", fontWeight: "bold" }}
                    >
                      ENDGAME MECHANICS: READ CAREFULLY
                    </h5>
                    <h5 className="sub-title help-center">
                      If you attempt to withdraw more than what is available in the FARM contract,
                      the Community Pool will be used to cover the full amount. In such cases, the
                      Pool will automatically calculate the corresponding amount of USDC and
                      transfer it to the Commonwealth Fund to maintain the token's price while you
                      withdraw. This means that if you withdraw too much, you'll dilute the token's
                      liquidity, which will, in turn, increase the volatility of BCT.
                    </h5>
                    {farmBalance && (
                      <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                        Current Farm's balance: {farmBalance} BCT
                      </h5>
                    )}
                    {!farmBalance && (
                      <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                        Check the current Farm's balance on Travel Song.
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <WithdrawScreen />
          </section>
        </>
      ) : (
        <div className="col-12">
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </div>
      )}
    </div>
  );
};

export default Withdraw;
