import React, { useState } from "react";
import BigNumber from "bignumber.js";
import { capitalize } from "../../../utils/capitalize";
import { format } from "../../../utils/math";
import { shortHash } from "../../../utils/tx";

import {
  Container,
  BlockDetails,
  TitleBox,
  Title,
  AdvancedButton,
  DetailItem,
  NotFound,
} from "./styles";

import ItemValue from "../item-value/ItemValue";

const Block = ({
  isFetching = false,
  blockNumber = "",
  block = null,
  searchCallback = null,
  isLatestBlock = false,
  latestTxnList = [],
}) => {
  const [isAdvanced, setIsAdvanced] = useState(false);

  const toggleAdvanced = () => {
    setIsAdvanced(!isAdvanced);
  };

  return (
    <Container>
      {block?.number ? (
        <BlockDetails>
          <TitleBox>
            <Title>{isLatestBlock ? "Latest Block" : "Block Details"}</Title>
            {window.innerWidth > 580 && (
              <AdvancedButton onClick={toggleAdvanced} isAdvanced={isAdvanced}>
                {isAdvanced ? "Advanced" : "Standard"}
              </AdvancedButton>
            )}
          </TitleBox>

          <DetailItem>
            <strong>Block Number:</strong>{" "}
            {<ItemValue onClick={searchCallback} value={parseInt(block.number)} />}
          </DetailItem>
          <DetailItem>
            <strong>Transaction Count:</strong>{" "}
            {<ItemValue onClick={searchCallback} value={block.transactionCount()} />}
          </DetailItem>
          <DetailItem>
            <strong>Timestamp:</strong>{" "}
            {
              <ItemValue
                onClick={searchCallback}
                value={new Date(parseInt(block.timestamp) * 1000).toUTCString()}
              />
            }
          </DetailItem>

          {isAdvanced && (
            <>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Gas Limit:</strong>{" "}
                <ItemValue
                  onClick={searchCallback}
                  value={format(BigNumber(block.gasLimit).toString())}
                />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Gas Used:</strong>{" "}
                <ItemValue
                  onClick={searchCallback}
                  value={format(BigNumber(block.gasUsed).toString())}
                />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Block Hash:</strong>{" "}
                <ItemValue onClick={searchCallback} value={block.hash} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Mix Hash:</strong>{" "}
                <ItemValue onClick={searchCallback} value={block.mixHash} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Parent Hash:</strong>{" "}
                <ItemValue onClick={searchCallback} value={block.parentHash} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Transactions Root:</strong>{" "}
                <ItemValue onClick={searchCallback} value={block.transactionsRoot} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>State Root:</strong>{" "}
                <ItemValue onClick={searchCallback} value={block.stateRoot} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Receipts Root:</strong>{" "}
                <ItemValue onClick={searchCallback} value={block.receiptsRoot} />
              </DetailItem>
            </>
          )}

          {block.txSummary().map((tx, index) => (
            <div
              key={index + tx.hash}
              style={{
                marginTop: "20px",
                outline: "1px solid white",
                padding: "4px 10px 1px 10px",
                borderRadius: "4px",
                backgroundColor: "#000010",
              }}
            >
              <DetailItem>
                <strong>Action:</strong> <ItemValue isAction={true} value={tx.action} />
              </DetailItem>
              <DetailItem>
                <strong>From:</strong> <ItemValue onClick={searchCallback} value={tx.from} />
              </DetailItem>
              {tx.to !== "Contract Deployment" && (
                <DetailItem>
                  <strong>To:</strong> <ItemValue onClick={searchCallback} value={tx.to} />
                </DetailItem>
              )}
              <DetailItem>
                <strong>Hash:</strong>{" "}
                <ItemValue onClick={searchCallback} value={tx.hash} isTxHash={true} />
              </DetailItem>
            </div>
          ))}

          {isLatestBlock && latestTxnList.length > 0 && (
            <>
              <Title style={{ marginTop: "40px" }}>Latest Transactions in the Kingdom</Title>
              {latestTxnList.map((tx, index) => (
                <div
                  key={index + tx.hash}
                  style={{
                    marginTop: "20px",
                    outline: "1px solid white",
                    padding: "4px 10px 1px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#000010",
                  }}
                >
                  <DetailItem>
                    <strong>Action:</strong>{" "}
                    <ItemValue onClick={searchCallback} isAction={true} value={tx.action} />
                  </DetailItem>
                  <DetailItem>
                    <strong>From:</strong> <ItemValue onClick={searchCallback} value={tx.from} />
                  </DetailItem>
                  {tx.action !== "Contract Deployment" && (
                    <DetailItem>
                      <strong>To:</strong> <ItemValue onClick={searchCallback} value={tx.to} />
                    </DetailItem>
                  )}
                  {tx.action === "KOZI Transfer" && (
                    <DetailItem>
                      <strong>Amount:</strong>{" "}
                      <ItemValue value={BigNumber(tx.value).dividedBy(1e18).toString()} />
                    </DetailItem>
                  )}
                  <DetailItem>
                    <strong>Block:</strong>{" "}
                    {
                      <ItemValue
                        onClick={searchCallback}
                        value={parseInt(tx.blockNumber)}
                        isBlockNumber={true}
                      />
                    }
                  </DetailItem>

                  <DetailItem>
                    <strong>Hash:</strong>{" "}
                    <ItemValue onClick={searchCallback} value={tx.hash} isTxHash={true} />
                  </DetailItem>
                </div>
              ))}
            </>
          )}
        </BlockDetails>
      ) : !isFetching ? (
        blockNumber && <NotFound>{`Block #${blockNumber} not found.`}</NotFound>
      ) : (
        blockNumber && <NotFound>{`Loading...`}</NotFound>
      )}
    </Container>
  );
};

export default Block;
