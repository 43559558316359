class ChainModel {
  constructor(chainData) {
    this.setData(chainData);
  }

  setData(chainData) {
    this.name = chainData?.name;
    this.shortName = chainData?.shortName;
    this.chainId = chainData?.chainId;
    this.nativeCurrency = chainData?.nativeCurrency;
    this.rpc = chainData?.rpc;
    this.indexer = chainData?.indexer;
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }
}

export default ChainModel;
