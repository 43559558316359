import styled from "styled-components";
import BagCommon from "../../assets/images/squads-card/cradle-type-rarity-1.png";
import BagRare from "../../assets/images/squads-card/cradle-type-rarity-2.png";
import BagEpic from "../../assets/images/squads-card/cradle-type-rarity-3.png";
import BagLegendary from "../../assets/images/squads-card/cradle-type-rarity-4.png";
import BagMythic from "../../assets/images/squads-card/cradle-type-rarity-5.png";

import Mouse from "../../assets/images/squads-card/type-1.png";
import Cat from "../../assets/images/squads-card/type-2.png";
import Cow from "../../assets/images/squads-card/type-3.png";
import Elephant from "../../assets/images/squads-card/type-4.png";
import Ape from "../../assets/images/squads-card/type-5.png";
import Dragon from "../../assets/images/squads-card/type-6.png";
import Griffin from "../../assets/images/squads-card/type-7.png";
import Satyr from "../../assets/images/squads-card/type-8.png";
import Dino from "../../assets/images/squads-card/type-9.png";
import Cyborg from "../../assets/images/squads-card/type-10.png";
import Hydra from "../../assets/images/squads-card/type-11.png";
import Chimera from "../../assets/images/squads-card/type-12.png";
import Ent from "../../assets/images/squads-card/type-13.png";
import Kaiju from "../../assets/images/squads-card/type-14.png";
import Cacodemon from "../../assets/images/squads-card/type-15.png";

const Container = styled.div`
  position: relative;
  top: ${(props) => (props.scale ? `${-4 * props.scale}px` : `-4px`)};
  left: ${(props) => (props.scale ? `${-3 * props.scale}px` : `-3px`)};
  width: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => (props.scale ? `${3 * props.scale}px` : `3px`)};

  .bag {
    position: absolute;
    width: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  }

  .type {
    position: relative;
    width: 80%;
  }
`;

export const getBag = (rarity) => {
  switch (rarity) {
    case 1:
      return BagCommon;
    case 2:
      return BagRare;
    case 3:
      return BagEpic;
    case 4:
      return BagLegendary;
    case 5:
      return BagMythic;
    default:
      return "Unknown";
  }
};

const getType = (type) => {
  switch (type) {
    case 1:
      return Mouse;
    case 2:
      return Cat;
    case 3:
      return Cow;
    case 4:
      return Elephant;
    case 5:
      return Ape;
    case 6:
      return Dragon;
    case 7:
      return Griffin;
    case 8:
      return Satyr;
    case 9:
      return Dino;
    case 10:
      return Cyborg;
    case 11:
      return Hydra;
    case 12:
      return Chimera;
    case 13:
      return Ent;
    case 14:
      return Kaiju;
    case 15:
      return Cacodemon;
    default:
      return "Unknown";
  }
};

const CardType = ({ type, rarity, scale }) => (
  <Container scale={scale}>
    <img className="bag" src={getBag(rarity)} scale={scale} />
    <img className="type" src={getType(type)} scale={scale} />
  </Container>
);

export default CardType;
