export function squadIdToSquad(squadId, player) {
  if (!player) return null;

  return player.squads.find((squad) => squad.id === squadId);
}

export function squadIdToSquadNumber(squadId, player) {
  if (!player) return null;

  return player.squads.findIndex((squad) => squad.id === squadId) + 1;
}

// This considers the cumulative 5% bonus to each squad above the 4th, plus
// the 30% BCT farming bonus for pure squads (type_ !== 6),
// the 40% eBCT farming bonus for pure squads (type_ !== 6),
// and the 100% Loot farming bonus for pure squads (type_ !== 6).
export function getSpecialBonuses(squadNumber, player) {
  if (!player || !squadNumber) return null;

  let bctBonus = 0;
  let ebctBonus = 0;
  let lootBonus = 0;

  if (squadNumber > 4) {
    bctBonus += 5 * (squadNumber - 4);
    ebctBonus += 5 * (squadNumber - 4);
  }

  if (squadNumber % 2 === 0) {
    bctBonus += 20;
    ebctBonus += 30;
    lootBonus += 100;
  }

  return {
    bctBonus,
    ebctBonus,
    lootBonus,
  };
}

export function squadNumberToSquadType(squadNumber) {
  if (squadNumber % 2 !== 0) {
    // it's a mixed squad
    return 6;
  } else {
    if (squadNumber === 10 || squadNumber === 16 || squadNumber === 20) {
      return 2;
    } else {
      // it's a Mouse squad
      return 1;
    }
  }
}
