import React from "react";
import { Container, Title } from "./styles";

import MintButton from "../mint-button/MintButton";

import { hasEnoughBct, hasEnoughResources } from "../../../utils/purchase";
import { usePlayerContext } from "../../../contexts/PlayerContext";
import { useWeb3Context } from "../../../contexts/Web3Context";
import { nameToNumber } from "../../../config/resources/06-type_.js";

import { getMintPrices } from "../../../config/resources/12-upgrades";

const BeastMinter = ({ type, balance, onMint }) => {
  const { player } = usePlayerContext();
  const { blockchainState } = useWeb3Context();

  const prices = {};
  prices["1"] = {};
  prices["2"] = {};
  prices["3"] = {};
  prices["4"] = {};
  for (let i = 1; i <= 4; i++) {
    prices[i]["1"] = getMintPrices(i, 1, blockchainState?.scales?.upgradesScale);
    prices[i]["3"] = getMintPrices(i, 3, blockchainState?.scales?.upgradesScale);
    prices[i]["9"] = getMintPrices(i, 9, blockchainState?.scales?.upgradesScale);
    prices[i]["27"] = getMintPrices(i, 27, blockchainState?.scales?.upgradesScale);
    prices[i]["81"] = getMintPrices(i, 81, blockchainState?.scales?.upgradesScale);
  }

  return (
    player && (
      <Container>
        <Title>
          {type} Boxes: {balance}
        </Title>
        <MintButton
          onClick={() => onMint(1)}
          disabled={balance < 1}
          type={type}
          rarity={1}
          player={player}
          eBctPrice={prices[nameToNumber(type)][1].bctPrice}
          resourcePrices={prices[nameToNumber(type)][1].resourceCents}
          resourceIndexes={prices[nameToNumber(type)][1].resourceIndexes}
        />
        <MintButton
          onClick={() => onMint(3)}
          disabled={
            balance < 3 ||
            !hasEnoughBct(player, prices[nameToNumber(type)][3].bctPrice) ||
            !hasEnoughResources(
              player,
              prices[nameToNumber(type)][3].resourceCents,
              prices[nameToNumber(type)][3].resourceIndexes
            )
          }
          type={type}
          rarity={2}
          player={player}
          eBctPrice={prices[nameToNumber(type)][3].bctPrice}
          resourcePrices={prices[nameToNumber(type)][3].resourceCents}
          resourceIndexes={prices[nameToNumber(type)][3].resourceIndexes}
        />
        <MintButton
          onClick={() => onMint(9)}
          disabled={
            balance < 9 ||
            !hasEnoughBct(player, prices[nameToNumber(type)][9].bctPrice) ||
            !hasEnoughResources(
              player,
              prices[nameToNumber(type)][9].resourceCents,
              prices[nameToNumber(type)][9].resourceIndexes
            )
          }
          type={type}
          rarity={3}
          player={player}
          eBctPrice={prices[nameToNumber(type)][9].bctPrice}
          resourcePrices={prices[nameToNumber(type)][9].resourceCents}
          resourceIndexes={prices[nameToNumber(type)][9].resourceIndexes}
        />
        <MintButton
          onClick={() => onMint(27)}
          disabled={
            balance < 27 ||
            !hasEnoughBct(player, prices[nameToNumber(type)][27].bctPrice) ||
            !hasEnoughResources(
              player,
              prices[nameToNumber(type)][27].resourceCents,
              prices[nameToNumber(type)][27].resourceIndexes
            )
          }
          type={type}
          rarity={4}
          player={player}
          eBctPrice={prices[nameToNumber(type)][27].bctPrice}
          resourcePrices={prices[nameToNumber(type)][27].resourceCents}
          resourceIndexes={prices[nameToNumber(type)][27].resourceIndexes}
        />
        <MintButton
          onClick={() => onMint(81)}
          disabled={
            balance < 81 ||
            !hasEnoughBct(player, prices[nameToNumber(type)][81].bctPrice) ||
            !hasEnoughResources(
              player,
              prices[nameToNumber(type)][81].resourceCents,
              prices[nameToNumber(type)][81].resourceIndexes
            )
          }
          type={type}
          rarity={5}
          player={player}
          eBctPrice={prices[nameToNumber(type)][81].bctPrice}
          resourcePrices={prices[nameToNumber(type)][81].resourceCents}
          resourceIndexes={prices[nameToNumber(type)][81].resourceIndexes}
        />
      </Container>
    )
  );
};

export default BeastMinter;
