import { Container, BorderContainer, DescriptionContainer, NameContainer } from "./styles";

import ResourceContainerSpiralAuctions from "./resource-container";
import { bonusToBackground } from "../../../utils/constants";

// check if the logged in player is the seller of this order
// if it is, and there have been no bids so far, then we'll show a cancel button
// Check if 24 hours have passed since the latest bid.
// If yes, and the logged player is the privateFor of this order,
// then we'll show a CLAIM button
// If yes, and the logged player is the seller of this order,
// and there have been no bids so far, then we'll show a CANCEL button
// If no, then we'll show a BID button

const BonusBlock = ({
  ebct = 0,
  feathers = 0,
  horns = 0,
  skulls = 0,
  isWinner = false,
  isTimeOver = false,
}) => {
  const titleText = () => {
    if (isWinner) {
      return "Your Bonus";
    } else if (isTimeOver) {
      return "Winner Bonus";
    } else {
      return "Current Winner Bonus";
    }
  };

  const descriptionText = () => {
    return (
      <>
        <ResourceContainerSpiralAuctions
          resource={98}
          quantity={ebct}
          background={bonusToBackground["98"]}
        />
        <ResourceContainerSpiralAuctions
          resource={22}
          quantity={feathers}
          background={bonusToBackground["22"]}
        />
        <ResourceContainerSpiralAuctions
          resource={23}
          quantity={horns}
          background={bonusToBackground["23"]}
        />
        <ResourceContainerSpiralAuctions
          resource={24}
          quantity={skulls}
          background={bonusToBackground["24"]}
        />
      </>
    );
  };

  return (
    <Container ebct={ebct} isWinner={isWinner}>
      <BorderContainer>
        <NameContainer ebct={ebct} isWinner={isWinner} isTimeOver={isTimeOver}>
          {titleText()}
        </NameContainer>
        <DescriptionContainer>{descriptionText()}</DescriptionContainer>
      </BorderContainer>
    </Container>
  );
};

export default BonusBlock;
