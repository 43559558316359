import styled from "styled-components";

const Text = styled.p`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  color: #fff !important;
  letter-spacing: -0.06em;
  background: -webkit-linear-gradient(#fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  line-height: 1.2;
  font-size: ${(props) =>
    props.scale
      ? props.smallfont
        ? `${12 * props.scale}px`
        : `${14 * props.scale}px`
      : props.smallfont
      ? `12px`
      : `14px`};
  margin-bottom: ${(props) => (props.scale ? `${4.5 * props.scale}px` : `4.5px`)};
  top: ${(props) =>
    props.scale
      ? props.isSecondSkillNumber && props.scale <= 0.5
        ? `${-0.5 * props.scale + 0.5}px`
        : `${-0.5 * props.scale}px`
      : `-0.5px`};
  left: ${(props) => (props.scale ? `${-0.5 * props.scale}px` : `-0.5px`)};

  position: relative;

  @media (max-width: 450px) {
    font-size: ${(props) =>
      props.scale ? (props.lockSize ? `${14 * props.scale}px` : `${11 * props.scale}px`) : `11px`};

    top: ${(props) =>
      props.scale
        ? props.isSecondSkillNumber
          ? props.lockSize
            ? `${0 * props.scale}px`
            : `${-3 * props.scale}px`
          : props.isThirdSkillNumber
          ? `${-3 * props.scale - 0.5}px`
          : `${0 * props.scale - 0.5}px`
        : props.isThirdSkillNumber
        ? `-4px`
        : props.isSecondSkillNumber
        ? `-3px`
        : `-1.5px`};

    left: ${(props) =>
      props.scale
        ? props.lockSize
          ? `${-1.5 * props.scale}px`
          : `${-2.5 * props.scale}px`
        : `-2px`};
  }
`;

const SkillNumber = ({ children, scale, lockSize, isSecondSkillNumber, isThirdSkillNumber }) => {
  return (
    <Text
      scale={scale}
      isSecondSkillNumber={isSecondSkillNumber}
      isThirdSkillNumber={isThirdSkillNumber}
      lockSize={lockSize}
    >
      {children}
    </Text>
  );
};

export default SkillNumber;
