import React from "react";
import Header from "../components/header/Header";

import MintScreen from "../components/mint-screen/MintScreen";

import { useWeb3Context } from "../contexts/Web3Context";

const Mint = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🐣</h1>
                    <h1 className="heading text-center">Mint</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center">
                      Here you can mint Beasts from boxes that you farmed or bought. The more boxes
                      of the same type, the rarer the beast that comes out of them.
                    </h5>
                    <h5 className="sub-title help-center">
                      <p className="miniP">
                        After minting a beast, please allow up to 60 seconds for it to show up in
                        your inventory. Reload your inventory if it doesn't refresh automatically
                        for any reason.
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <MintScreen />
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Mint;
