import BigNumber from "bignumber.js";

class CollectionModel {
  constructor(collectionData, nfts, isExternal = false) {
    this.setData(collectionData, nfts, isExternal);
  }

  setData(collectionData, nfts, isExternal) {
    this.id = new BigNumber(collectionData.id).toNumber(); // an integer from 0 to infinity. This is the global ID of this NFT. @notice: NFT 0 DOES EXIST AND IS VALID.
    this.name = String(collectionData.name); // When the name is en ampty string (""), we'll render the rarity + type instead: "Common Cat", "Legendary Mouse", etc.
    this.creator = collectionData.creator; // the blockchain address of the ORIGINAL owner (not necessarily the current owner)
    //this.rarity = new BigNumber(nfts[0].rarity).toNumber(); // from 4 to 6: Legendary, Mythic, Titanic
    this.nftIds = collectionData.nftIds.map((nftId) => new BigNumber(nftId).toNumber()); // A list containig integers
    this.nfts = nfts;
    this.isExternal = isExternal;
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }
}

export default CollectionModel;
