import React from "react";
import Header from "../components/header/Header";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

const Discord = () => {
  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🎭</h1>
                    <h1 className="heading text-center">Discord Community</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      Beast Kingdom is integrated with Discord
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      After associating your Discord account, you will receive direct notifications
                      whenever you sell or buy on our P2P Marketplace. You can also join the
                      community to participate in our Universal Basic Income experiment, learn how
                      to play the game, and get involved in the development of the ecosystem.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32" style={{ color: "yellow" }}>
                      Account association is an important step to take advantage of all the benefits
                      of the Kingdom.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      On Discord, you'll find guides, videos, the latest important news, several
                      channels for monitoring prices and activity in our systems, a space for
                      questions and answers, a voting channel, and more.
                    </h5>

                    <h5 className="sub-title help-center mg-bt-32">
                      1.{" "}
                      <a
                        href="https://discord.gg/beast-kingdom-893977842113712128"
                        style={{ textDecorationLine: "underline" }}
                      >
                        Proceed to our Discord server
                      </a>
                      , find the #verify-address channel and type{" "}
                      <span
                        style={{
                          color: "yellow",
                          fontWeight: "bold",
                          backgroundColor: "#000",
                          padding: "4px",
                          borderRadius: "6px",
                        }}
                      >
                        !verify
                      </span>{" "}
                      there.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      2. Magnus, our bot, will send you a DM. Reply with{" "}
                      <span
                        style={{
                          color: "yellow",
                          fontWeight: "bold",
                          backgroundColor: "#000",
                          padding: "4px",
                          borderRadius: "6px",
                        }}
                      >
                        !verify 0x...
                      </span>{" "}
                      replacing "0x..." with your actual blockchain address.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      3. Magnus will then provide a link back to a special page on this website.
                      There, you'll need to sign a message with Metamask (it's free).
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      4. Once that's done, Magnus will confirm your address verification via direct
                      message. That's it!
                    </h5>
                    <h5 className="sub-title help-center">
                      <a
                        href="https://discord.gg/beast-kingdom-893977842113712128"
                        style={{ textDecorationLine: "underline", margin: "4px" }}
                      >
                        Join us and become one with the Kingdom!
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
                  <h5 className="sub-title help-center mg-bt-32 "></h5>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Discord;
