import React, { useEffect, useState } from "react";
import Messenger from "../../utils/Messenger";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";

import { usePlayerContext } from "../../contexts/PlayerContext";
import { useWeb3Context } from "../../contexts/Web3Context";

import { bonusToBackground } from "../../utils/constants";
import { indexToBackground } from "../../utils/constants";

import SendIcon from "../../assets/images/icon/sendMoney.png";

import {
  GlobalContainer,
  BalanceGroup,
  PlayerNotFound,
  Title,
  Subtitle,
  BalanceGroupContainer,
  TransferToInput,
  TransferToInputContainer,
  TransferToLoaderButton,
} from "./styles";
import BalanceContainer from "../balance-container";

const BALANCE_ORDER = [
  //0,   // BCT
  //1,   // eBCT
  0, 5, 1, 6, 2, 7, 3, 8, 4, 9, 11, 14, 12, 15, 13, 16, 19, 17, 20, 18, 10, 21, 22, 23, 24, 25,
];

const BalancesScreen = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });

  const { player, loading, isRegistered, transfer } = usePlayerContext();
  const { isAddress } = useWeb3Context();

  const [isTransferActive, setIsTransferActive] = useState(true);

  const [bct, setBct] = useState(0);
  const [eBct, setEBct] = useState(0);
  const [to, setTo] = useState("");
  const [resources, setResources] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0,
    16: 0,
    17: 0,
    18: 0,
    19: 0,
    20: 0,
    21: 0,
    22: 0,
    23: 0,
    24: 0,
    25: 0,
  });

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }

    if (!isRegistered && !loading) {
      console.log("Balances :: Player not found (yet?)");
      return;
    }
  }, [player, loading, isRegistered]);

  if (!player) return null;

  const ingameBalances = player.ingameBalances;
  const stashed = player.stashed;

  if (!ingameBalances) {
    console.log("Balances :: Player not found");
    return null;
  }

  const balanceValues = {
    0: parseFloat(ingameBalances[0]) / 100,
    1: parseFloat(ingameBalances[1]) / 100,
    ...ingameBalances[2]?.reduce((acc, balance, index) => {
      acc[index] = parseFloat(balance) / 100;
      return acc;
    }, {}),
  };

  // now, inject the stasher balance
  balanceValues["101"] = BigNumber(stashed).div(1e18).toFixed(2);

  const handleTransfer = async () => {
    if (!isAddress(to)) {
      toast.error("Invalid destination address", {
        type: toast.TYPE.WARNING,
        autoClose: 5000,
      });

      return;
    }

    // these are supposed to be multiplied by 1e18 before sending to the contract
    const _bct = BigNumber(bct).times(1e18).toFixed(0);
    const _eBct = BigNumber(eBct).times(1e18).toFixed(0);

    // now convert the resources object to array:
    const _resources = Object.values(resources).map((value) => Math.round(parseFloat(value) * 100));

    await transfer(to, _bct, _eBct, _resources);
  };

  const onResourceChange = (index, value) => {
    const newResources = { ...resources };
    newResources[index] = value !== "" ? (parseFloat(value) > 0 ? value : 0) : 0;
    setResources(newResources);
  };

  return (
    <GlobalContainer>
      <BalanceGroupContainer>
        <Title>In-game Wallet</Title>
        <Subtitle>Resources can be used or sold to other players</Subtitle>
        <BalanceGroup>
          {isRegistered ? (
            <>
              <BalanceContainer
                key={`ingame-99`}
                resource={99}
                quantity={BigNumber(ingameBalances[0]).div(1e18).toFixed(2)}
                background={bonusToBackground["BCT"]}
              />
              <BalanceContainer
                key={`ingame-98`}
                resource={98}
                quantity={BigNumber(ingameBalances[1]).div(1e18).toFixed(2)}
                background={bonusToBackground["eBCT"]}
              />
              {BALANCE_ORDER.map((index) => (
                <BalanceContainer
                  key={`ingame-${index}`}
                  resource={index}
                  quantity={balanceValues[index]}
                  background={indexToBackground[index]}
                />
              ))}
            </>
          ) : (
            <PlayerNotFound>
              <p>Player not found</p>
            </PlayerNotFound>
          )}
        </BalanceGroup>
      </BalanceGroupContainer>

      {isTransferActive && (
        <BalanceGroupContainer>
          <Title>Transfer To</Title>
          <TransferToInputContainer>
            <TransferToInput
              type="text"
              placeholder="Paste the destination address here"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
            <TransferToLoaderButton onClick={handleTransfer}>
              <img src={SendIcon}></img>
            </TransferToLoaderButton>
          </TransferToInputContainer>
          <BalanceGroup>
            {isRegistered ? (
              <>
                <BalanceContainer
                  id="bct"
                  key={`ingame-99`}
                  resource={99}
                  quantity={0}
                  background={bonusToBackground["BCT"]}
                  isEditable={true}
                  disabled={BigNumber(ingameBalances[0]).lte(0)}
                  value={bct}
                  onChange={(value) => setBct(value)}
                />
                <BalanceContainer
                  id="eBct"
                  key={`ingame-98`}
                  resource={98}
                  quantity={0}
                  background={bonusToBackground["eBCT"]}
                  isEditable={true}
                  value={eBct}
                  onChange={(value) => setEBct(value)}
                />
                {BALANCE_ORDER.map((index) => (
                  <BalanceContainer
                    id={`transfer-${index}`}
                    key={`ingame-${index}`}
                    resource={index}
                    quantity={0}
                    background={indexToBackground[index]}
                    isEditable={true}
                    disabled={index === 101 || balanceValues[index] === 0}
                    value={resources[index]}
                    onChange={(value) => onResourceChange(index, value)}
                  />
                ))}
              </>
            ) : (
              <PlayerNotFound>
                <p>Player not found</p>
              </PlayerNotFound>
            )}
          </BalanceGroup>
        </BalanceGroupContainer>
      )}
    </GlobalContainer>
  );
};

export default BalancesScreen;
