import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 24px;
  font-family: "Metropolis", sans-serif;
`;

export const Subtitle = styled.div`
  font-size: 12px;
  font-family: "Metropolis", sans-serif;
  margin-top: 10px;
  color: #aaa;
`;

export const ListContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 215px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  outline: 2px solid white;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 17px;
`;

export const CollectionNamesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  margin: ${(props) => (props.isTheLastOne ? "10px 10px 15px 10px" : "10px 10px 0px 10px")};
  width: 94%;
  cursor: pointer;

  outline: ${(props) =>
    props.isExternal ? "1px solid cyan" : props.isComplete ? "1px solid white" : "1px solid white"};

  color: ${(props) => (props.isExternal ? "white" : props.isComplete ? "white" : "white")};

  background-color: ${(props) =>
    props.isSelected
      ? props.isComplete
        ? props.isPromo
          ? "#707"
          : "rgba(0, 85, 0, 1)"
        : "rgba(0, 155, 200, 0.5)"
      : props.isComplete
      ? props.isPromo
        ? "#505"
        : "green"
      : "transparent"};

  &:hover {
    background-color: ${(props) =>
      props.isComplete ? (props.isPromo ? "#707" : "rgba(0, 85, 0, 1)") : "rgba(0, 155, 200, 0.5)"};
  }
`;

export const CollectionName = styled.div`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  padding: 5px;
`;

export const CollectionLength = styled.div`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  padding: 5px;
`;
