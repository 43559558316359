import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/header/Header";

import { shortAccount } from "../utils/account";

import { usePlayerContext } from "../contexts/PlayerContext";

const YellowHeading = styled.h5`
  color: #f9d71c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const GreenHeading = styled.h5`
  color: #00ff00;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`;

const Button = styled.div`
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px 20px;
  text-align: center;
  width: 10%;
  min-width: 180px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &:active {
    background-color: #000;
    color: #fff;
  }
`;

// the user is at a URL like so:
// https://beastkingdom.io/verify/${discordId}/${address}
const LinkAddress = () => {
  const { player, proveIdentity } = usePlayerContext();
  const [didValidate, setDidValidate] = useState(false);

  // let's get the data from the url
  const { discordId, address } = useParams();

  const verify = async () => {
    const success = await proveIdentity(discordId);

    if (success) {
      setDidValidate(true);
    }
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            {!didValidate && (
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">{`Link Account`}</h1>
                </div>
                <div>
                  <h5 className="sub-title help-center mg-bt-32 ">{`Let's link your address to your Discord account.`}</h5>
                  <h5 className="sub-title help-center mg-bt-32">{`You may link as many addresses as you like, with the first address you link being considered your "main address". The Stasher level of your main address determines your access level and voting power on Discord.`}</h5>
                  {player && player.address.toLowerCase() === address.toLowerCase() && (
                    <>
                      <GreenHeading className="sub-title help-center ">{`Address: ${shortAccount(
                        address
                      )}`}</GreenHeading>
                      <h5 className="sub-title help-center mg-bt-32">
                        When you click the button bellow, Metamask will open for you to sign a
                        message, proving the ownership of the accounts. Don't worry, it's free.
                      </h5>
                      <ButtonContainer onClick={verify}>
                        <Button>Link Account</Button>
                      </ButtonContainer>
                    </>
                  )}
                  {player && player.address.toLowerCase() !== address.toLowerCase() && (
                    <YellowHeading className="sub-title help-center ">{`Please select the correct address directly on your Metamask: ${shortAccount(
                      address
                    )}`}</YellowHeading>
                  )}
                  {!player && (
                    <>
                      <YellowHeading className="sub-title help-center ">
                        {`Please connect your Metamask to proceed. If you are using a mobile phone, copy this URL and paste it into your Metamask App's browser:`}
                      </YellowHeading>
                      <h6
                        style={{
                          textAlign: "center",
                          position: "relative",
                          top: "10px",
                          fontSize: "12px",
                          color: "yellow",
                        }}
                      >{`${window.location.href}`}</h6>
                    </>
                  )}
                </div>
              </div>
            )}
            {didValidate && (
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">{`Account Linked`}</h1>
                </div>
                <div>
                  <h5 className="sub-title help-center mg-bt-32 ">{`✅ That's it, you're all set!`}</h5>
                  <h5 className="sub-title help-center mg-bt-32">{`Mighty Mentor Magnus sent you a direct message on Discord with your current Stasher Level.`}</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="tf-help-center tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
              <h5 className="sub-title help-center mg-bt-32 "></h5>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LinkAddress;
