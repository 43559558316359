import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 78px;
  min-height: 20px;
  height: 24px;
  border-radius: 6px;
  overflow: hidden;
  background: ${(props) => props.background};
  margin: 6px;

  outline: ${(props) => (props.missing ? "1px solid #f22" : "none")};
  outline-offset: -1px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: 100%;
  background: ${(props) => props.background};

  img {
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;

export const TextContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  height: 100%;
  position: relative;
  left: 1px;
  top: 1px;

  h6 {
    font-size: ${(props) => (props.smallFont ? "12px" : "12px")};
    color: ${(props) => (props.missing ? "#f44" : "#fff")};

    text-shadow: ${(props) =>
      props.missing
        ? "-0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333, 0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333"
        : "none"};
  }

  @media (max-width: 685px) {
    h6 {
      font-size: 14px;
    }
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;
