import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
`;

export const Grid = styled.div`
  display: ${(props) => (props.orderCount > 1 ? "grid" : "flex")};
  grid-template-columns: repeat(auto-fill, minmax(210px, 4fr));
  width: 95%;
  justify-items: center;
  margin-top: 20px;
  margin-bottom: 80px;

  @media (max-width: 685px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 4fr));
    width: 100%;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 4fr));
    width: 350px;
  }

  @media (max-width: 315px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 4fr));
    width: 400px;
  }
`;

export const NoOrdersMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  color: #ddd;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 800px;

  animation: ${fadeIn} 2s ease-in;
`;

export const LoadingMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  margin-bottom: 600px;
`;

export const BigLoadingMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #ddd;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-left: 10px;

  position: relative;
  top: -11px;
`;

export const SmallLoadingMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
  text-align: center;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  top: -80px;
  flex-wrap: wrap;
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: #0f2034;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    `
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  `}

  &:hover {
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  }
`;
