import React, { useState, useEffect } from "react";

import styled, { keyframes } from "styled-components";

import SunRays from "../../assets/images/squads-card/sunrays.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const flash = keyframes`
  from, 50%, to {
    opacity: 0.5;
    scale: 2;
  }

  40%, 90% {
    opacity: 0;
    scale: 0.6;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 4px;

  max-height: ${(props) => (props.scale ? props.scale * 200 + "px" : "200px")};
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }

  animation: ${fadeIn} 1s ease-in-out;

  ${(props) => props.isDead && "filter: grayscale(100%) brightness(30%);"}
`;

const SunRaysOverlay = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1%;
  left: -1%;
  scale: 1.4;
  z-index: 1;

  animation: ${flash} 20s infinite, ${spin} 10s linear infinite;

  filter: saturate(2) brightness(0.8);
`;

export const getDefaultImage = (key) => {
  switch (key) {
    case "1-1":
      return "https://i.postimg.cc/9FQ7LrqS/1-1.png";
    case "1-2":
      return "https://i.postimg.cc/L4Nqg20V/1-2.png";
    case "1-3":
      return "https://i.postimg.cc/s2Yxjrh7/1-3.png";
    case "1-4":
      return "https://i.postimg.cc/4dw3cQw3/1-4.png";
    case "1-5":
      //return "https://i.postimg.cc/bNSzYPXr/1-5.png";
      return "https://i.postimg.cc/kXjh2tX8/mouse-small.gif";
    case "2-1":
      return "https://i.postimg.cc/SNnKX89y/2-1.png";
    case "2-2":
      return "https://i.postimg.cc/qv84nGvd/2-2.png";
    case "2-3":
      return "https://i.postimg.cc/J0GRkVGT/2-3.png";
    case "2-4":
      return "https://i.postimg.cc/59CvH6CW/2-4.png";
    case "2-5":
      //return "https://i.postimg.cc/PxcmgDv2/2-5.png";
      return "https://i.postimg.cc/XYmH8S5Y/cat-small.gif";
    case "3-1":
      return "https://i.postimg.cc/SjbNF1xD/3-1.png";
    case "3-2":
      return "https://i.postimg.cc/T2zVQr6P/3-2.png";
    case "3-3":
      return "https://i.postimg.cc/QtzNGc4q/3-3.png";
    case "3-4":
      return "https://i.postimg.cc/4dy4SW5d/3-4.png";
    case "3-5":
      //return "https://i.postimg.cc/ZqWn1MPz/3-5.png";
      return "https://i.postimg.cc/Xv521HKc/cow-small.gif";
    case "4-1":
      return "https://i.postimg.cc/GpSR13LX/4-1.jpg";
    case "4-2":
      return "https://i.postimg.cc/zBjfWY8W/4-2.png";
    case "4-3":
      return "https://i.postimg.cc/bJzyqVkv/4-3.png";
    case "4-4":
      return "https://i.postimg.cc/sDtP79Zd/4-4-3.png";
    case "4-5":
      //return "https://i.postimg.cc/hGng0ZC0/4-5.png";
      return "https://i.postimg.cc/2y0TLPmm/elephant-small.gif";
    case "5-1":
      return "https://i.postimg.cc/mDXsBLTd/5-1.png";
    case "5-2":
      return "https://i.postimg.cc/yNTKphtC/5-2.png";
    case "5-3":
      return "https://i.postimg.cc/ZnWhqGRJ/5-3.png";
    case "5-4":
      return "https://i.postimg.cc/TwL628hf/5-4.png";
    case "5-5":
      //return "https://i.postimg.cc/PfDjBkBM/5-5.png";
      return "https://i.postimg.cc/7Zft0YfR/ape2-small.gif";
    case "6-5":
      return "https://i.postimg.cc/jdPWPjjq/dragon3.gif";
    case "7-5":
      return "https://i.postimg.cc/K8qT3wjc/griffin-small.gif";
    case "8-5":
      return "https://i.postimg.cc/L86fX2H0/satyr-small.gif";
    case "9-5":
      return "https://i.postimg.cc/wB1mX2RX/dino-small.gif";
    case "10-5":
      return "https://i.postimg.cc/pd3nqjKg/cyborg-small.gif";
    case "11-5":
      return "https://i.postimg.cc/prVdP9Qj/hydra1.gif";
    case "12-5":
      return "https://i.postimg.cc/9XGtvz61/chimera-anim.gif";
    case "13-5":
      return "https://i.postimg.cc/ydHdrpkv/ent1.gif";
    case "14-5":
      return "https://i.postimg.cc/bJ9JX0n8/kaiju1.gif";
    case "15-5":
      return "https://i.postimg.cc/QC6dCXSy/cacodemon1.gif";
    default:
      return "https://i.postimg.cc/xd3zTWv1/Unknown.png";
  }
};

const CardImage = ({ nft, scale }) => {
  const [actualCardImage, setActualCardImage] = useState(null);

  useEffect(() => {
    const getCardImageUrl = async () => {
      const url = await setCardImage(nft.imageUrl);

      if (url) {
        setActualCardImage(url);
      } else {
        setActualCardImage(getDefaultImage(`${nft.type_}-${nft.rarity}`));
      }
    };

    getCardImageUrl();
  });

  const setCardImage = async (url) => {
    if (!url) return;
    url = url.trim();
    // now remove any double-quotes we may find:
    url = url.replace(/"/g, "");

    if (url === "" || url === '""') return;
    if (!url.startsWith("https://")) return;

    if (nft.rarity < 5) {
      if (!url.endsWith(".png") && !url.endsWith(".jpg")) return;
    } else {
      if (!url.endsWith(".png") && !url.endsWith(".jpg") && !url.endsWith(".gif")) return;
    }

    return url;
  };

  return (
    <Container isDead={nft?.isDead} scale={scale}>
      {nft.species >= 11 && <SunRaysOverlay src={SunRays} alt="sunrays" />}
      {actualCardImage && <img src={actualCardImage} alt={nft.name} />}
    </Container>
  );
};

export default CardImage;
