import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import Header from "../components/header/Header";

import MasteriesScreen from "../components/masteries-screen/MasteriesScreen";

import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";

import { stasherLevelByBalance } from "../utils/constants";

const Masteries = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();
  const { player } = usePlayerContext();
  const [stasherLevel, setStasherLevel] = useState(0);

  useEffect(() => {
    if (!player) return;

    setStasherLevel(
      stasherLevelByBalance(parseFloat(BigNumber(player.stashed).div(1e18).toString()))
    );
  }, [player]);

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🥋</h1>
                    <h1 className="heading text-center">Masteries</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      Masteries offer global farming bonuses that apply to all your squads. Each
                      Mastery Level grants you a 1% bonus to the farming rate for that Mastery.
                    </h5>
                    <h5 className="sub-title help-center">
                      The perks below are valid as long as you remain a Stasher Level 10. If your
                      level goes below 10, you'll lose them, but all you need to do is reach level
                      10 again to get them back.
                      <p className="miniP">
                        Example of Mastery bonus: if you possess 4 Mastery levels in BCT, your
                        collective farming bonus for BCT rises to 14%: a base of 10% for Stasher
                        Level 10, plus an additional 1% for each Mastery Level.
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <MasteriesScreen />
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Masteries;
