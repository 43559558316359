import React, { useEffect, useState } from "react";
import Messenger from "../../../utils/Messenger";
import BigNumber from "bignumber.js";
import Card from "../../card/Card";
import ResourceContainer from "../resource-container";
import ResourceNft from "../resource-nft/ResourceNft";
import { zeroAddress } from "../../../utils/address";
import {
  Container,
  CardContainer,
  PricesContainer,
  MiddleContainer,
  InputWrapper,
  NumberInput,
  NumberButton,
  PlusPlusButton,
  MinusMinusButton,
  Number,
  ResourceNameContainer,
  PricesTitle,
  OriginalPriceContainer,
  CreateOrderButtonContainer,
  CreateOrderButton,
  CancelContainer,
  CancelButton,
} from "./styles.js";
import { indexToBackground, indexToDescription } from "../../../utils/constants";
import BinIcon from "../../../assets/images/icon/trash-bin.png";
import { indexToVerticalBackground } from "../../../utils/constants";
import { currentBlock } from "../../../utils/block";
import { isMaxedOut } from "../../../config/resources/08-squad-cards";

import { indexToName } from "../../../config/resources/04-resources-full-list";
import { Tooltip } from "react-tooltip";

import { useWeb3Context } from "../../../contexts/Web3Context";
import { usePlayerContext } from "../../../contexts/PlayerContext";
import { useMarketplaceContext } from "../../../contexts/MarketplaceContext";

import OrderProgressBar from "../order-progress-bar";

const DECIMALS = 2;
const EBCT_PCT = 130;

const OrderCard = ({
  orderData = null,
  moreLikeThisCallback = null,
  moreFromThisSellerCallback = null,
  isMerchant = false,
}) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_UP });

  const { player } = usePlayerContext();
  const { isReady, bctToEbct, bctToBusd, busdToBct } = useWeb3Context();
  const { createOrder, executeOrder, cancelOrder, mustHaveEnough } = useMarketplaceContext();
  const [currentBlockNumber, setCurrentBlockNumber] = useState(0);
  const [qtd, setQtd] = useState(1);
  const [previewOrderData, setPreviewOrderData] = useState(orderData.isPreview ? orderData : null);
  const [previewLastEditedPriceType, setPreviewLastEditedPriceType] = useState(null);
  const [acceptEbct, setAcceptEbct] = useState(orderData.allowEbct);
  const [acceptBct, setAcceptBct] = useState(!orderData.isStabletokenOnly);
  const [isEditingEbctPrice, setIsEditingEbctPrice] = useState(false);
  const [isEditingBctPrice, setIsEditingBctPrice] = useState(false);
  const [isEditingBusdPrice, setIsEditingBusdPrice] = useState(false);
  const [isPoolFuel, setIsPoolFuel] = useState(false);

  const [dutchPrices, setDutchPrices] = useState({
    ebct: BigNumber(0),
    bct: BigNumber(0),
    busd: BigNumber(0),
  });

  const [prices, setPrices] = useState({
    busd: orderData.isPreview ? BigNumber(0) : BigNumber(39916801),
    bct: orderData.isPreview ? BigNumber(0) : BigNumber(39916801),
    ebct: orderData.isPreview ? BigNumber(0) : BigNumber(39916801),
  });

  useEffect(() => {
    if (!orderData) return;

    //console.log("OrderCard :: orderData:", orderData);

    const fetchPrices = async () => {
      const _prices = await convertPrices();
      setPrices(_prices);
    };

    fetchPrices();
  }, [orderData]);

  const convertPrices = async (forceEbctPrice = null) => {
    if (!orderData || !isReady) return;

    const data = previewOrderData ? previewOrderData : orderData;

    const _busd =
      data.normalizedPriceInBusd && BigNumber(data.normalizedPriceInBusd).gt(0)
        ? BigNumber(data.normalizedPriceInBusd)
        : data.isPriceInBusd
        ? BigNumber(data.priceInWei)
        : await bctToBusd(data.priceInWei);

    const _bct =
      data.normalizedPriceInBct && BigNumber(data.normalizedPriceInBct).gt(0)
        ? BigNumber(data.normalizedPriceInBct)
        : !data.isStabletokenOnly
        ? data.isPriceInBusd
          ? await busdToBct(data.priceInWei)
          : BigNumber(data.priceInWei)
        : BigNumber(0);

    const _ebct = data.allowEbct
      ? forceEbctPrice
        ? forceEbctPrice
        : await bctToEbct(_bct)
      : BigNumber(0);

    if (isNaN(_busd)) {
      console.error("OrderCard :: convertPrices :: _busd is NaN", _busd);
    }

    const _prices = {
      busd: _busd,
      usdc: _busd,
      bct: _bct,
      ebct: _ebct,
    };

    return _prices;
  };

  useEffect(() => {
    if (!isReady) return;
    if (!orderData) return;
    if (orderData.isPreview) return;
    if (!orderData.isDutchAuction) return;

    calculateDutchPrices();

    const interval = setInterval(calculateDutchPrices, 3000);

    return () => clearInterval(interval);
  }, [currentBlockNumber]);

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }
  }, [player]);

  const calculateDutchPrices = () => {
    if (!player) return;

    const _currentBlockNumber = currentBlock(
      player.connection.initialBlockNumber,
      player.connection.initialTimestamp
    );
    setCurrentBlockNumber(_currentBlockNumber);

    let multiplier;
    if (_currentBlockNumber >= orderData.createdAt + 3600) {
      // price = price;
      multiplier = 1;
    } else {
      const startMultiplier = 4;
      const blocksPassed = _currentBlockNumber - orderData.createdAt;
      const maxMultiplierReduction = startMultiplier - 1;
      const multiplierPerBlock = maxMultiplierReduction / 3600;
      multiplier = startMultiplier - multiplierPerBlock * blocksPassed;
    }

    // now multiply all prices by the multiplier
    const _prices = {
      busd: prices.busd.times(multiplier),
      usdc: prices.busd.times(multiplier),
      bct: prices.bct.times(multiplier),
      ebct: prices.ebct.times(multiplier),
    };
    setDutchPrices(_prices);
  };

  const handleIncrease = () => {
    if (orderData.isPreview) {
      if (qtd < orderData.userBalance) {
        setQtd((prevQtd) => prevQtd + 1);
        //updatePrice(qtd + 1);
      }
    } else {
      if (qtd < orderData.quantity) {
        setQtd((prevQtd) => prevQtd + 1);
        //updatePrice(qtd + 1);
      }
    }
  };

  const handleIncreasePlus = () => {
    if (orderData.isPreview) {
      if (qtd + 10 < orderData.userBalance) {
        setQtd((prevQtd) => prevQtd + 10);
        //updatePrice(qtd + 10);
      } else {
        setQtd(orderData.userBalance);
        //updatePrice(orderData.quantity);
      }
    } else {
      if (qtd + 10 < orderData.quantity) {
        setQtd((prevQtd) => prevQtd + 10);
        //updatePrice(qtd + 10);
      } else {
        setQtd(orderData.quantity);
        //updatePrice(orderData.quantity);
      }
    }
  };

  const handleDecrease = () => {
    if (qtd > 1) {
      setQtd((prevQtd) => prevQtd - 1);
      //updatePrice(qtd - 1);
    }
  };

  const handleDecreasePlus = () => {
    if (qtd - 10 > 1) {
      setQtd((prevQtd) => prevQtd - 10);
      //updatePrice(qtd - 10);
    } else {
      setQtd(1);
      //updatePrice(1);
    }
  };

  const handlePriceEdited = (newPrice, resource) => {
    if (!orderData.isPreview) return;

    setPreviewLastEditedPriceType(resource);

    // this is a preview order, so we need to update the other prices;
    // if resource is 98 (eBCT), then we need to update the BCT and USDC prices
    let forceEbctPrice = null;
    if (resource === 98) {
      // we set the price in BCT of the order:
      let newBctPrice = BigNumber(newPrice).times(100).div(EBCT_PCT);
      newBctPrice = newBctPrice.times(1e18).toFixed(0);
      previewOrderData.priceInWei = newBctPrice;
      previewOrderData.isPriceInBusd = false;
      previewOrderData.allowEbct = true;
      //forceEbctPrice = BigNumber(newPrice).times(1e18).toFixed(0);
    } else if (resource === 99) {
      // we set the price in BCT of the order:
      const newBctPrice = BigNumber(newPrice).times(1e18).toFixed(0);
      previewOrderData.priceInWei = newBctPrice;
      previewOrderData.isPriceInBusd = false;
    } else if (resource === 102) {
      const newBusdPrice = BigNumber(newPrice).times(1e18).toFixed(0);
      previewOrderData.priceInWei = newBusdPrice;
      previewOrderData.isPriceInBusd = true;
    }

    const calc = async () => {
      const _prices = await convertPrices();
      setPrices(_prices);
    };

    calc();
  };

  const getDescription = (assetId) => {
    const nobodySellingMsg =
      "No one is selling this resource right now. If you have some, it may be a great opportunity to sell.";

    // if the price in USDC is 0, then nobody is selling this
    if (BigNumber(prices.busd).eq(0) && !orderData.isPreview) {
      return nobodySellingMsg;
    }

    return indexToDescription[assetId];
  };

  const isItMaxedOut = isMaxedOut(orderData?.nft);

  const isOrderDisabled =
    (orderData.isPreview && orderData.userBalance < 1) ||
    (orderData.isPreview && prices.busd.eq(0));

  const originalPrice = orderData?.isPriceInBusd
    ? BigNumber(prices.busd).div(1e18).times(4).toFixed(2)
    : BigNumber(prices.bct).div(1e18).times(4).toFixed(2);

  // refresh when the variable prices changes:
  useEffect(() => {
    if (!orderData) return;

    // do nothing
  }, [prices, previewOrderData, orderData.assetId]);

  // now we need to handle the accepted state of each price type (eBCT and BCT, since USDC is always accepted)
  const onToggleAccepted = (resource) => {
    console.log("onToggleAccepted", resource);
    if (resource === 98) {
      if (!acceptEbct) {
        setIsPoolFuel(false);
      }
      if (!acceptBct) setAcceptBct(true);
      setAcceptEbct(!acceptEbct);

      console.log("-> Set accept eBCT to:", !acceptEbct);
    } else if (resource === 99) {
      if (!acceptBct) {
        setIsPoolFuel(false);
      }
      if (acceptBct) setAcceptEbct(false);
      setAcceptBct(!acceptBct);
      setPreviewLastEditedPriceType(102);

      console.log("-> Set accept BCT to:", !acceptBct);
    } else if (resource === "FUEL") {
      if (!isPoolFuel) {
        setAcceptEbct(false);
        setAcceptBct(false);
      }

      setIsPoolFuel(!isPoolFuel);
    }
  };

  const handleIsEditing = (isIt, resource) => {
    if (resource === 98) {
      setIsEditingEbctPrice(isIt);
    } else if (resource === 99) {
      setIsEditingBctPrice(isIt);
    } else if (resource === 102) {
      setIsEditingBusdPrice(isIt);
    }
  };

  const isEditing = isEditingEbctPrice || isEditingBctPrice || isEditingBusdPrice;

  const handleBuy = async (currency = "eBCT") => {
    let _qtd;
    if (orderData.isDutchAuction) {
      _qtd = orderData.quantity;
    } else {
      _qtd = qtd;
    }

    console.log(
      `User action: buy ${_qtd} units of ${
        orderData.isNft ? `NFT #${orderData.assetId}` : indexToName(orderData.assetId)
      } with ${currency} | order #${orderData.id}`
    );

    // first, let's make sure the current player is not the seller of the order
    if (player.address === orderData.seller) {
      Messenger.warn("You can't buy your own order.");
      return;
    }

    // now, let's guarantee the player has enough balance using the hasEnough function
    // if the player doesn't have enough, the function will show a warning message and return false
    // if the player has enough, the function will return true
    console.log("CURRENCY:", currency);
    const hasEnough = mustHaveEnough(prices[currency.toLowerCase()], currency);
    if (!hasEnough) return;

    // we have orderData and currency to organize into an execute request
    // const executeOrder = async (orderId, quantity, payWithBusd, payWithEbct)

    // if currency is eBCT, we will mark payWithBusd false and payWithEbct true
    // if currency is BCT, we will mark payWithBusd false and payWithEbct false
    // if currency is USDC, we will mark payWithBusd true and payWithEbct false
    let _payWithBusd = false;
    let _payWithEbct = false;
    if (currency === "USDC" || currency === "BUSD") _payWithBusd = true;
    else if (currency === "eBCT") _payWithEbct = true;

    // now we can execute the order
    await executeOrder(orderData.id, _qtd, _payWithBusd, _payWithEbct);
  };

  const handleCreateOrder = async ({ dutchAuction = false }) => {
    // we have previewOrderData to organize into an execute request
    // const createOrder = async (asset, price, players, isDutchAuction, isPoolFuel)
    // Example:
    // { isNft: false, id: 0, quantity: 100 },
    // { isNominalInStabletoken: false, isStabletokenOnly: false, amount: toWei("1"), allowEbct: false, secondaryReceiverPct: 0 },
    // { seller: alice.address, secondaryReceiver: zeroAddress, privateFor: zeroAddress },
    // false,
    // false

    if (!player) return;
    if (!orderData.isPreview) return;

    const asset = {
      isNft: previewOrderData.isNft,
      id: previewOrderData.assetId,
      quantity: qtd,
    };

    const price = {
      isNominalInStabletoken: previewOrderData.isPriceInBusd,
      isStabletokenOnly: !acceptBct,
      amount: previewOrderData.priceInWei,
      allowEbct: acceptEbct,
      secondaryReceiverPct: previewOrderData.secondaryReceiverPct || 0,
    };

    const players = {
      seller: player.address,
      secondaryReceiver: previewOrderData.secondaryReceiver || zeroAddress,
      privateFor: dutchAuction ? player.address : previewOrderData.privateFor || zeroAddress,
    };

    const isDutchAuction = dutchAuction;

    // now we can execute the order
    await createOrder(asset, price, players, isDutchAuction, isPoolFuel);
  };

  const handleCreateDutchOrder = async () => {
    await handleCreateOrder({ dutchAuction: true });
  };

  const handleCancelOrder = async () => {
    if (!player) return;
    if (orderData.seller !== player.address) return;

    // now we can cancel the order
    await cancelOrder(orderData.id);
  };

  const handleMoreLikeThis = () => {
    if (moreLikeThisCallback) moreLikeThisCallback(orderData.assetId);
  };

  const handleMoreFromThisSeller = () => {
    if (moreFromThisSellerCallback) moreFromThisSellerCallback(orderData.seller);
  };

  const isMyOrders = window.location.pathname.includes("/my-orders");

  return (
    orderData.assetId !== undefined && (
      <Container disable={orderData.isPreview && orderData.userBalance < 1}>
        {orderData.isDutchAuction && (
          <>
            <OrderProgressBar
              startsAt={orderData.createdAt}
              endsAt={parseInt(orderData.createdAt) + 3600}
              currentBlockNumber={currentBlockNumber}
              startingPrice={`${parseFloat(originalPrice).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })} ${orderData.isPriceInBusd ? "USDC" : "BCT"}`}
            />
          </>
        )}
        {orderData.isNft ? (
          <CardContainer>
            {isMyOrders && (
              <CancelContainer>
                <CancelButton disable={false} onClick={handleCancelOrder}>
                  <img src={BinIcon} alt="cancel" />
                </CancelButton>
              </CancelContainer>
            )}
            <Card
              nft={orderData.nft}
              squadType={6}
              scale={isItMaxedOut ? 0.98 : 1}
              reasonToSelect={orderData.isNft && orderData.assetId === 39916801 ? "sell" : "none"}
              clickToSell={orderData.isNft && orderData.isPreview ? true : false}
              clickToSelectNftFromInventory={orderData.isNft && orderData.isPreview ? true : false}
              supressInteraction={orderData.isNft && orderData.isPreview ? false : true}
              lockSize={true}
            />
          </CardContainer>
        ) : (
          <ResourceNameContainer>
            <ResourceNft
              resource={orderData.assetId}
              name={indexToName(orderData.assetId)}
              quantity={orderData.quantity}
              background={
                (orderData.isPreview && orderData.userBalance > 0) ||
                (!orderData.isPreview &&
                  orderData.seller !== "0x0000000000000000000000000000000000000000")
                  ? indexToVerticalBackground[orderData.assetId]
                  : indexToVerticalBackground["none"]
              }
              description={getDescription(orderData.assetId)}
              seller={orderData.seller}
              userBalance={orderData.userBalance}
              isPreview={orderData.isPreview}
              disable={isOrderDisabled}
              cancelOrderCallback={handleCancelOrder}
              moreFromThisSellerCallback={handleMoreFromThisSeller}
              moreLikeThisCallback={handleMoreLikeThis}
            />
            <MiddleContainer>
              {prices.busd && (
                <InputWrapper>
                  <MinusMinusButton
                    onClick={orderData.isDutchAuction ? null : handleDecreasePlus}
                    disable={orderData.isDutchAuction}
                  >
                    {"--"}
                  </MinusMinusButton>
                  <NumberButton
                    onClick={orderData.isDutchAuction ? null : handleDecrease}
                    disable={orderData.isDutchAuction}
                  >
                    -
                  </NumberButton>
                  <NumberInput>
                    <Number>
                      {orderData.isPreview
                        ? orderData.userBalance > 0
                          ? `${qtd} / ${orderData.userBalance}`
                          : "0"
                        : prices.busd.eq(0)
                        ? "0"
                        : orderData.isDutchAuction
                        ? `${orderData.quantity} / ${orderData.quantity}`
                        : `${qtd} / ${orderData.quantity}`}
                    </Number>
                  </NumberInput>
                  <NumberButton
                    onClick={orderData.isDutchAuction ? null : handleIncrease}
                    disable={orderData.isDutchAuction}
                  >
                    +
                  </NumberButton>
                  <PlusPlusButton
                    onClick={orderData.isDutchAuction ? null : handleIncreasePlus}
                    disable={orderData.isDutchAuction}
                  >
                    {"++"}
                  </PlusPlusButton>
                </InputWrapper>
              )}
            </MiddleContainer>
          </ResourceNameContainer>
        )}

        <PricesContainer
          isNft={orderData.isNft}
          isMaxedOut={isItMaxedOut}
          isPoolFuel={orderData.isPoolFuel}
        >
          <PricesTitle isPoolFuel={orderData.isPoolFuel}>
            {orderData.isPreview
              ? orderData.isNft
                ? `Set the price:`
                : isMerchant
                ? `UNITARY for normal, FULL for auction`
                : `Set the UNITARY price:`
              : orderData.isPoolFuel
              ? `🐉 50x more Dragon Scales 🐉`
              : `Buy now for one of these prices:`}
          </PricesTitle>
          <a data-tooltip-id={orderData.id + "98"} data-tooltip-content={`Pay with eBCT`}>
            <ResourceContainer
              resource={98}
              quantity={
                orderData.isDutchAuction
                  ? BigNumber(dutchPrices.ebct).times(qtd).div(1e18).toFixed(DECIMALS)
                  : orderData.isPreview
                  ? BigNumber(prices.ebct).div(1e18).dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
                  : BigNumber(prices.ebct)
                      .times(qtd)
                      .div(1e18)
                      .dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
              }
              background={
                acceptEbct && !orderData.isStabletokenOnly
                  ? indexToVerticalBackground[98]
                  : indexToVerticalBackground["none"]
              }
              hasEnough={true}
              useRecurrentCountUp={orderData.isDutchAuction}
              countUpSeconds={orderData.isDutchAuction ? 3 : 0}
              isPreview={orderData.isPreview}
              onIsEditing={handleIsEditing}
              onEdit={handlePriceEdited}
              forceEditValue={
                orderData.isPreview
                  ? BigNumber(prices.ebct).div(1e18).dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
                  : null
              }
              previewDivider={orderData.isPreview ? qtd : 1}
              isBasePrice={previewLastEditedPriceType === 98}
              isAccepted={
                acceptEbct &&
                !orderData.isStabletokenOnly &&
                orderData.seller !== "0x0000000000000000000000000000000000000000"
              }
              disable={
                !acceptEbct ||
                orderData.isStabletokenOnly ||
                orderData.seller === "0x0000000000000000000000000000000000000000"
              }
              onToggleAccepted={onToggleAccepted}
              buyCallback={handleBuy}
              useAnimatedCounter={orderData.isDutchAuction && !orderData.isPreview}
            />
          </a>
          {!orderData.isPreview && (
            <Tooltip
              id={orderData.id + "98"}
              style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
            />
          )}
          <a data-tooltip-id={orderData.id + "99"} data-tooltip-content={`Pay with BCT`}>
            <ResourceContainer
              resource={99}
              quantity={
                orderData.isDutchAuction
                  ? BigNumber(dutchPrices.bct).times(qtd).div(1e18).toFixed(DECIMALS)
                  : orderData.isPreview
                  ? BigNumber(prices.bct).div(1e18).dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
                  : BigNumber(prices.bct)
                      .times(qtd)
                      .div(1e18)
                      .dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
              }
              background={
                acceptBct ? indexToVerticalBackground[99] : indexToVerticalBackground["none"]
              }
              hasEnough={true}
              useRecurrentCountUp={orderData.isDutchAuction}
              countUpSeconds={orderData.isDutchAuction ? 3 : 0}
              isPreview={orderData.isPreview}
              onEdit={handlePriceEdited}
              onIsEditing={handleIsEditing}
              forceEditValue={
                orderData.isPreview
                  ? BigNumber(prices.bct).div(1e18).dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
                  : null
              }
              previewDivider={orderData.isPreview ? qtd : 1}
              isBasePrice={previewLastEditedPriceType === 99 || previewLastEditedPriceType === 98}
              isAccepted={
                acceptBct && orderData.seller !== "0x0000000000000000000000000000000000000000"
              }
              onToggleAccepted={onToggleAccepted}
              buyCallback={handleBuy}
              useAnimatedCounter={orderData.isDutchAuction && !orderData.isPreview}
            />
          </a>
          {!orderData.isPreview && (
            <Tooltip
              id={orderData.id + "99"}
              style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
            />
          )}
          <a data-tooltip-id={orderData.id + "102"} data-tooltip-content={`Pay with USDC`}>
            <ResourceContainer
              resource={102}
              quantity={
                orderData.isDutchAuction
                  ? BigNumber(dutchPrices.busd).times(qtd).div(1e18).toFixed(DECIMALS)
                  : orderData.isPreview
                  ? BigNumber(prices.busd).div(1e18).dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
                  : BigNumber(prices.busd)
                      .times(qtd)
                      .div(1e18)
                      .dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
              }
              background={indexToVerticalBackground[103]}
              hasEnough={true}
              useRecurrentCountUp={orderData.isDutchAuction}
              countUpSeconds={orderData.isDutchAuction ? 3 : 0}
              isLastOne={!orderData.isPreview}
              isPreview={orderData.isPreview}
              onEdit={handlePriceEdited}
              onIsEditing={handleIsEditing}
              bonus={!orderData.isNft && orderData.assetId === 21 ? false : true}
              forceEditValue={
                orderData.isPreview
                  ? BigNumber(prices.busd).div(1e18).dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
                  : null
              }
              previewDivider={orderData.isPreview ? 1 : 1}
              isBasePrice={previewLastEditedPriceType === 102}
              isAccepted={orderData.seller !== "0x0000000000000000000000000000000000000000"}
              buyCallback={handleBuy}
              useAnimatedCounter={orderData.isDutchAuction && !orderData.isPreview}
              isPoolFuel={orderData.isPoolFuel || isPoolFuel}
            />
          </a>
          {!orderData.isPreview && (
            <Tooltip
              id={orderData.id + "102"}
              style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
            />
          )}
          {orderData.isPreview && (
            <>
              <a
                data-tooltip-id={orderData.id + "FUEL"}
                data-tooltip-content={`Instead of USDC, you get 50% of the price in Dragon Scales.`}
              >
                <ResourceContainer
                  resource={"FUEL"}
                  quantity={"Pool Fuel"}
                  background={indexToVerticalBackground["FUEL"]}
                  hasEnough={true}
                  useRecurrentCountUp={false}
                  countUpSeconds={0}
                  isLastOne={true}
                  isPreview={orderData.isPreview}
                  previewDivider={orderData.isPreview ? 1 : 1}
                  isBasePrice={false}
                  isAccepted={
                    isPoolFuel && orderData.seller !== "0x0000000000000000000000000000000000000000"
                  }
                  useAnimatedCounter={false}
                  onToggleAccepted={onToggleAccepted}
                />
              </a>
              <Tooltip
                id={orderData.id + "FUEL"}
                style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
              />
            </>
          )}
        </PricesContainer>
        {orderData.isPreview && (
          <>
            <CreateOrderButtonContainer>
              <CreateOrderButton
                onClick={
                  isOrderDisabled ||
                  isEditing ||
                  BigNumber(prices.busd).div(1e18).dp(DECIMALS, BigNumber.ROUND_HALF_DOWN).eq(0) ||
                  (orderData.isNft && orderData.assetId === 39916801) ||
                  (orderData.isNft && orderData.assetId === 0)
                    ? null
                    : handleCreateOrder
                }
                disable={
                  isOrderDisabled ||
                  isEditing ||
                  BigNumber(prices.busd).div(1e18).dp(DECIMALS, BigNumber.ROUND_HALF_DOWN).eq(0) ||
                  (orderData.isNft && orderData.assetId === 39916801) ||
                  (orderData.isNft && orderData.assetId === 0)
                }
              >
                Create Sell Order
              </CreateOrderButton>
            </CreateOrderButtonContainer>
            {isMerchant && (
              <CreateOrderButtonContainer style={{ marginTop: "2px" }}>
                <CreateOrderButton
                  onClick={
                    isOrderDisabled ||
                    isEditing ||
                    BigNumber(prices.busd)
                      .div(1e18)
                      .dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
                      .eq(0) ||
                    (orderData.isNft && orderData.assetId === 39916801) ||
                    (orderData.isNft && orderData.assetId === 0)
                      ? null
                      : handleCreateDutchOrder
                  }
                  disable={
                    isOrderDisabled ||
                    isEditing ||
                    BigNumber(prices.busd)
                      .div(1e18)
                      .dp(DECIMALS, BigNumber.ROUND_HALF_DOWN)
                      .eq(0) ||
                    (orderData.isNft && orderData.assetId === 39916801) ||
                    (orderData.isNft && orderData.assetId === 0)
                  }
                >
                  Create Dutch Auction
                </CreateOrderButton>
              </CreateOrderButtonContainer>
            )}
          </>
        )}
      </Container>
    )
  );
};

export default OrderCard;
