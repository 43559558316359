import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "../components/header/Header";
import BigNumber from "bignumber.js";

import { shortAccount } from "../utils/account";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useWeb3Context } from "../contexts/Web3Context";

import Service from "../components/claim/service";

const YellowHeading = styled.h5`
  color: #f9d71c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const GreenHeading = styled.h5`
  color: #00ff00;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`;

const Button = styled.div`
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px 20px;
  text-align: center;
  width: 10%;
  min-width: 180px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &:active {
    background-color: #000;
    color: #fff;
  }
`;

const SUBTITLES = [
  "Cow-abunga! 🐮🤘",
  "Moo-tastic! 🐮🤘",
  "Holy Cow! 🐄😲",
  "Milking It! 🐄🥛",
  "No Bull, Just Rewards 🐄🎁",
  "Herd You Like Rewards 🐄🎉",
  "Moo-sic to My Ears 🐮🎶",
  "Mice to See You! 🐭👀",
  "The Early Mouse Gets the Cheese! 🐭🧀",
  "Purr-fect Claim! 🐱💖",
  "Claw-some! 🐱🐾",
  "Paw-sitively Thrilling! 🐱🎢",
  "Tusk-tacular! 🐘🌟",
  "Ele-fantastic! 🐘🎉",
  "Ape-solutely Amazing! 🦍👍",
  "Swinging Success! 🦍🌳",
  "Scale the Heights! 🐉⛰",
  "Wing-tastic Claim! 🐉🦋",
];

const FINISHERS = [
  "Ready to milk the next challenge? 🐮💪",
  "Time to beef up your game! 🥩",
  "Moo-ve on to the next quest! 🐮🚀",
  "Moo-chas gracias for playing! 🐮🙏",
  "Moo-ving on up, aren't we? 🐄🔝",
  "Time to cow-quer the world! 🐮🌍",
  "Moo-tivate yourself for what's ahead! 🐮💪",
  "The pasture's always greener with rewards! 🌾💎",
  "Cheese the day! 🧀🌞",
  "Tiny paws, big rewards! 🐭🎁",
  "Ready to scratch the surface of your next adventure? 🐱",
  "Don't paws, keep playing! 🐾🎮",
  "On the prowl for more? 🐱🌌",
  "Nine lives, endless rewards! 🐱",
  "Fur-tunately, more to come! 🐱🍀",
  "Never forget, more rewards await! 🐘🔮",
  "Ready to trunk-roll to victory? 🐘🏆",
  "Ready to monkey around in the next level? 🦍🎮",
  "Going bananas for more? 🍌🎉",
  "Ready to scale the next quest? 🐉",
  "Sky's the limit! 🐉🌌",
  "Hoard your way to the top! 🐉💎",
  "Soar like a dragon, claim like a king! 🐉👑",
];

// the user is at this URL:
// https://beastkingdom.io/claim
const Claim = () => {
  const { isReady, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();
  const { player, claim } = usePlayerContext();
  const [outstandingClaim, setOutstandingClaim] = useState(null);
  const [didClaim, setDidClaim] = useState(false);

  const loadClaim = async () => {
    if (!isReady) return;
    if (!player) return;

    const _outstandingClaim = await Service.getOutstandingClaim(player.address);

    console.log(">>> outstandingClaim", _outstandingClaim);

    if (_outstandingClaim && _outstandingClaim?.claimData?.playerAddress === player.address) {
      setOutstandingClaim(_outstandingClaim);
    } else {
      setOutstandingClaim(false);
    }
  };

  const doClaim = async () => {
    if (!isReady) return;
    if (!player) return;
    if (!outstandingClaim) return;
    if (didClaim) return;

    const _didClaim = await claim(outstandingClaim);
    if (_didClaim) {
      setDidClaim(true);
    }
  };

  const hasResourcesInClaim = () => {
    if (!outstandingClaim) return false;
    const { claimData } = outstandingClaim;
    if (!claimData) return false;
    const { resources } = claimData;
    if (!resources) return false;
    if (resources.length === 0) return false;

    return true;
  };

  const randomSubtitle = () => {
    return SUBTITLES[Math.floor(Math.random() * SUBTITLES.length)];
  };

  const randomFinisher = () => {
    return FINISHERS[Math.floor(Math.random() * FINISHERS.length)];
  };

  // we want to load the claim data when the page loads
  // and when the player changes
  useEffect(() => {
    loadClaim();
  }, [player]);

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          {!IS_PAUSED ? (
            <div className="row">
              {!didClaim && (
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">Claim Rewards</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      This is where you claim all your Roaring Rewards.
                    </h5>
                    {player && outstandingClaim && (
                      <>
                        <GreenHeading className="sub-title help-center ">{`Account ${shortAccount(
                          player.address
                        )} has a Roaring Reward to claim!`}</GreenHeading>
                        <h5 className="sub-title help-center mg-bt-32">
                          {`You're about to claim a Roaring Reward: ${BigNumber(
                            outstandingClaim.claimData.eBct
                          )
                            .div(1e18)
                            .toFixed(2)} eBCT`}
                        </h5>
                        {hasResourcesInClaim() && (
                          <>
                            <h5 className="sub-title help-center mg-bt-32">
                              {`On top of that, your reward contains ${
                                outstandingClaim.claimData.resources[0] / 100
                              } of each one of the following:`}
                            </h5>
                            <h6
                              className="sub-title help-center mg-bt-32"
                              style={{
                                textAlign: "center",
                                fontSize: "13px",
                                lineHeight: "1.5",
                                position: "relative",
                                top: "-26px",
                                fontWeight: "bold",
                              }}
                            >
                              Super Cheese, Shiny Fish, Groovy Grass, Happy Roots, Bang Bananas,
                              Exotic Cheese, Cat Milk, Magic Mushrooms, Power Mangoes, Ape Tools,
                              Golden Nuts, Gene Therapy, Mouse Box, Cat Box, Mouse DNA, and Cat DNA.
                            </h6>
                          </>
                        )}
                        <ButtonContainer onClick={doClaim}>
                          <Button>Claim</Button>
                        </ButtonContainer>
                      </>
                    )}
                    {player && !outstandingClaim && (
                      <YellowHeading className="sub-title help-center ">{`There are no pending rewards for account ${shortAccount(
                        player.address
                      )}.`}</YellowHeading>
                    )}
                    {!player && (
                      <>
                        <YellowHeading className="sub-title help-center ">
                          {`Please connect your Metamask to proceed. If you are using a mobile phone, copy the following URL and paste it into your Metamask App's browser.`}
                        </YellowHeading>
                        <h6
                          style={{
                            textAlign: "center",
                            position: "relative",
                            top: "10px",
                            fontSize: "12px",
                            color: "yellow",
                          }}
                        >{`${window.location.href}`}</h6>
                      </>
                    )}
                  </div>
                </div>
              )}
              {didClaim && (
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">{`Roaring Reward Claimed!`}</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 " style={{ fontWeight: "bold" }}>
                      {randomSubtitle()}
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">{`You've got yourself a Roaring Reward and Mighty Mentor Magnus sent you a direct message on Discord with more details. Please reload the website and check you balances!`}</h5>
                    <h5 className="sub-title help-center mg-bt-32 ">{randomFinisher()}</h5>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">{PAUSED_TITLE}</h2>
              <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
            </div>
          )}
        </div>
      </section>
      <section className="tf-help-center tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
              <h5 className="sub-title help-center mg-bt-32 "></h5>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Claim;
