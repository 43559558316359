import { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

import { ProgressContainer, CustomProgressBar, DurationText } from "./styles";
import { getQuestProgressBarPercentage, getQuestElapsedTime } from "../../../../../utils/quests";
import { currentBlock } from "../../../../../utils/block";

import { usePlayerContext } from "../../../../../contexts/PlayerContext";

const QuestProgressBar = ({ duration, endsAt, startsAt, currentBlockNumber }) => {
  const { player } = usePlayerContext();

  const [isFirstRender, setIsFirstRender] = useState(true);

  // The progress in seconds
  const [formattedCurrentProgress, setFormattedCurrentProgress] = useState(0);

  // The progress % up to 1 day
  const [currentBarProgress, setCurrentBarProgress] = useState(0);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }

    const timer = setInterval(() => {
      const block = currentBlock(
        player.connection.initialBlockNumber,
        player.connection.initialTimestamp
      );

      const elapsed = getQuestElapsedTime(startsAt, block);
      const barProgress = getQuestProgressBarPercentage(startsAt, block);

      setFormattedCurrentProgress(elapsed);
      setCurrentBarProgress(barProgress);
    }, 1000);

    // Clean up on component unmount
    return () => clearInterval(timer);
  }, [isFirstRender, currentBlockNumber, startsAt]);

  return (
    duration &&
    endsAt &&
    currentBlockNumber > 0 && (
      <ProgressContainer>
        <DurationText>
          <a
            data-tooltip-id="bar-progress"
            data-tooltip-content={`Elapsed time since this squad embarked on this Quest`}
            data-tooltip-place="top"
          >
            {formattedCurrentProgress === 0 || formattedCurrentProgress === undefined
              ? "Loading progress..."
              : formattedCurrentProgress}
          </a>
          <Tooltip id="bar-progress" style={{ fontSize: "12px", fontWeight: 600 }} />
        </DurationText>
        <CustomProgressBar now={isFirstRender ? 0 : currentBarProgress} />
      </ProgressContainer>
    )
  );
};

export default QuestProgressBar;
