import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header.jsx";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

import GravedanceScreen from "../components/gravedance-screen/GravedanceScreen.jsx";

import { useWeb3Context } from "../contexts/Web3Context.jsx";
import BigNumber from "bignumber.js";

const Gravedance = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const { isReady, consult } = useWeb3Context();
  const [jackpot, setJackpot] = useState("?");
  const [collapsed, setCollapsed] = useState(false);

  const getJackpot = async () => {
    const totalPot = await consult({
      contractName: "KingdomFunds",
      functionName: "balanceOf",
      functionParams: [3], // fund #3 is the Fur & Fortune jackpot
    });
    const _jackpot = BigNumber(totalPot)
      .times(25)
      .div(100)
      .div(1e18)
      .toFixed(2, BigNumber.ROUND_DOWN);

    setJackpot(_jackpot);
  };

  useEffect(() => {
    if (isReady) {
      getJackpot();
    }
  }, [isReady]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Header />

      {!IS_PAUSED ? (
        <div>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🧟‍♂️</h1>
                    <h1 className="heading text-center">Gravedance</h1>
                  </div>
                  {!collapsed ? (
                    <div>
                      <h5 className="sub-title help-center mg-bt-32 ">
                        Revive the dead. Come back with a bang.
                      </h5>
                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Spend Kozi (🧿) to revive dead beasts.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        Revived beasts keep all their stats, and may even gain something in the
                        process.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Dig some graves and steal the best beasts from other players.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        The dead belong to no one. Find the best dead beasts and revive them to make
                        them your own. How can you find them? With the persistence and sheer luck of
                        a grave robber.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Spend Lucky Tickets (🎫) to test your fortune and embrace the unknown.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        When you revive a beast, there is a chance that it comes back as a strong
                        Vampire, an elusive Ghost, or a brain-hungry Undead. Lucky Tickets increase
                        the chances of a successful modification.{" "}
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Vampires, Ghosts, and Undeads are stronger than regular beasts.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        <p style={{ fontSize: "1em" }}>
                          💠 <span style={{ color: "yellow" }}>Vampires</span> come back to life
                          with a <span style={{ color: "yellow" }}>40% Base Farm Boost</span>.
                        </p>
                        <p style={{ fontSize: "1em" }}>
                          The trait "Vampire" replaces the 1st trait of the returning beast.
                        </p>

                        <p style={{ fontSize: "1em" }}>
                          💠 <span style={{ color: "yellow" }}>Ghosts</span> become beasts of the{" "}
                          <span style={{ color: "yellow" }}>next species</span> and same rarity; the
                          Base Farm is unchanged.
                        </p>
                        <p style={{ fontSize: "1em" }}>
                          The trait "Ghost" replaces the 2nd trait of the returning beast.
                        </p>

                        <p style={{ fontSize: "1em" }}>
                          💠 <span style={{ color: "yellow" }}>Undeads</span> come back with{" "}
                          <span style={{ color: "yellow" }}>+5 levels to all skills</span>, even if
                          that's above their maximum.
                        </p>
                        <p style={{ fontSize: "1em" }}>
                          The trait "Undead" replaces the 3rd trait of the returning beast, or adds
                          the 3rd trait to it.
                        </p>
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Spend extra Kozi (🧿) to triple your chances.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        Add more Kozi into the mix to{" "}
                        <span style={{ fontWeight: "bold" }}>triple</span> the chances of a
                        successful modification of your choice. Each time you triple your chances,
                        the total cost increases by 50%.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Reviving upgraded beasts is more than worth it.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        The cost for reviving a beast is calculated based on its species and rarity,
                        and then multiplied by 2 to the power of revivals already done on the beast.
                        This means Ranks, Agility, Traits, Skills, Base Farm, Evolutions, Base Farm
                        Boosts, and Collections, are all ignored when calculating the cost, but they
                        are kept when reviving the beast. Use this to your advantage.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Integrating revived beasts adds up all revivals.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        After playing Gravedance, you might want to integrate resurrected beasts to
                        reach new heights. However, beware: revivals are cumulative across all
                        beasts involved in an integration. This means that if you integrate a beast
                        that has been revived three times with two other beasts that have been
                        revived once each, the resulting beast will be marked with five revivals. If
                        you use Mangoes instead, the resulting beast will be marked with twice the
                        number of revivals it already has, plus the number of revivals of the
                        sacrificed beast.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Don't faint while playing!
                      </h5>
                      <h5 className="sub-title help-center small">
                        You will execute exactly 2 transactions each time you play Gravedance. The
                        whole game takes less than 1 minute to unfold. Altough you can take your
                        time to press buttons, once started, you must complete the game within 30
                        minutes. Failure to do so results in the game being marked as abandoned; any
                        resources spent will be lost.
                      </h5>
                    </div>
                  ) : (
                    <h5 className="sub-title help-center small" style={{ marginBottom: "-20px" }}>
                      <Link
                        to="#"
                        onClick={toggleCollapse}
                        style={{ fontSize: "12px", textDecoration: "underline" }}
                      >
                        Rules of the Game
                      </Link>
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </section>

          <GravedanceScreen />
        </div>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Gravedance;
