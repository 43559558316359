import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  margin: 6px;
  border-radius: 4px;
`;

export const Name = styled.div`
  font-size: 20px;
  color: #fff;
  padding: 10px;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  margin-top: 8px;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 44px;
  margin-top: 5px;
`;

export const Line2 = styled.div`
  font-size: 14px;
  color: #fff;
  padding: 2px;
  font-weight: bold;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333,
    0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333;
`;

export const CentralLine = styled.div`
  font-size: 24px;
  color: #fff;
  padding: 8px;
  font-weight: bold;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333,
    0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333;
`;

export const CentralLineConfiguring = styled.div`
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333,
    0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333;
`;

export const MiddleLine = styled.div`
  font-size: 10px;
  color: #ccc;
  padding: 2px;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333,
    0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333;
`;

export const ResultLine = styled.div`
  font-size: 24px;
  padding: 8px;
  font-weight: bold;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333,
    0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const DestroyButton = styled.div`
  font-size: 12px;
  color: #fff;
  margin: 4px;
  padding: 6px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: ${(props) =>
    props.disabled
      ? "linear-gradient(0deg, #aaa, #ccc)"
      : "linear-gradient(0deg, rgba(20, 50, 0,1), rgba(0, 200, 0,1))"};
  border-radius: 6px;
  font-weight: bold;
  width: 90px;
  text-align: center;

  ${(props) =>
    !props.disabled
      ? ` &:hover {
        background: #fff;
        color: #111;
      }
    
      &:active {
        background: #600;
      }
      `
      : ""}
`;

export const FloatingBadge = styled.div`
  position: absolute;
  top: 3px;
  right: ${(props) => (props.right ? props.right : "3px")};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;

export const EbctContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 48px;
  font-size: 20px;
  position: relative;
  top: 2px;
`;
