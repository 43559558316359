import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import Messenger from "../../utils/Messenger";
import { useParams, useNavigate } from "react-router-dom";
import { zeroAddress } from "../../utils/address";
import { shortAccount } from "../../utils/account";
import {
  Container,
  CategoriesContainer,
  CategoryButtonsContainer,
  CategoryButton,
  CategoryDescriptionContainer,
  CategoryMiniDescription,
  PreferredCurrencyButton,
  PreferredCurrencyTitle,
  PreferredCurrencyButtonsContainer,
  OuterLoadingContainer,
  LoadingContainer,
  LoadingLabel,
  BeastFiltersContainer,
  BeastFiltersTitle,
  BeastFiltersDropdownsContainer,
  MerchantContainer,
  MerchantTitle,
  EbctContainer,
} from "./styles.js";
import auctionIcon from "../../assets/images/auction.png";
import buyBeastsIcon from "../../assets/images/buyBeasts.png";
import buyResourcesIcon from "../../assets/images/buyResources.png";
import sellBeastsIcon from "../../assets/images/sellBeasts.png";
import sellResourcesIcon from "../../assets/images/sellResources.png";
import mySellOrdersIcon from "../../assets/images/mySellOrders.png";
import eBCTIcon from "../../assets/images/resources/resource-eBCT-md.png";
import BCTIcon from "../../assets/images/resources/resource-BCT-md.png";
import USDCIcon from "../../assets/images/resources/resource-USDC.png";
import DragonScalesIcon from "../../assets/images/resources/resource-21.png";

import SpeciesSelectDropdown from "./species-select-dropdown/SpeciesSelectDropdown";
import RaritySelectDropdown from "./rarity-select-dropdown/RaritySelectDropdown";
import TraitSelectDropdown from ".//trait-select-dropdown/TraitSelectDropdown";
import SortSelectDropdown from "./sort-select-dropdown/SortSelectDropdown";

import MarketplaceGrid from "./marketplace-grid/MarketplaceGrid";
import { DUMMY_RESOURCE_LIST, DUMMY_BEAST_ORDER } from "./dummy-orders/dummySellOrders";
import { FAKE_DUTCH_LIST } from "./dummy-orders/dummyOrders"; // TODO! Remove this
import { CATEGORIES } from "./categories";

import { indexToVerticalBackground } from "../../utils/constants";
import Service from "./service";

import { usePlayerContext } from "../../contexts/PlayerContext";
import { useWeb3Context } from "../../contexts/Web3Context";

const IS_BUY_RESOURCES_ACTIVE = true;
const IS_BUY_BEASTS_ACTIVE = true;
const IS_DUTCH_ACUTIONS_ACTIVE = false;
const IS_SELL_RESOURCES_ACTIVE = true;
const IS_SELL_BEASTS_ACTIVE = true;
const IS_MY_ORDERS_ACTIVE = true;

const MarketplaceScreen = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_UP });

  const { code, id } = useParams();
  const navigate = useNavigate();
  const { isReady } = useWeb3Context();
  const { player } = usePlayerContext();

  const [preferredCurrency, setPreferredCurrency] = useState("eBCT");
  const [beastSearchType_, setBeastSearchType_] = useState(null);
  const [beastSearchRarity, setBeastSearchRarity] = useState(null);
  const [beastSearchTrait, setBeastSearchTrait] = useState(null);
  const [beastSortBy, setBeastSortBy] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(
    CATEGORIES.find((category) => category.code === code) || CATEGORIES[2]
  );
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [forceBeastId, setForceBeastId] = useState(null);

  const handleCategorySelection = (categoryId, _extraParam) => {
    const category = CATEGORIES.find((category) => category.id === categoryId);
    if (categoryId === selectedCategory.id && orders.length > 0) return;

    // in more-like-this, the _extraParam can be 0 signifying Super Cheese!
    const _id = _extraParam === 0 ? 0 : id === 0 ? 0 : _extraParam || null;

    if (_id !== null) {
      if (category.useBeastFilters) {
        resetBeastSearchParams();
      }

      if (category.forceId) {
        setForceBeastId(_extraParam);
      } else {
        setForceBeastId(null);
      }

      if (category.useTwoPathBits) {
        navigate(`/marketplace/${category.code}/${_id}`, { replace: true });
      } else {
        navigate(`/marketplace/${category.code}`, { replace: true });
      }
    } else {
      if (category.forceId) {
        setForceBeastId(_extraParam);
      } else {
        setForceBeastId(null);
      }

      navigate(`/marketplace/${category.code}`, { replace: true });
    }

    setSelectedCategory(category);

    if (category.id === 7 || category.id === 9) {
      fetchOrders({ selectedCategoryId: category.id, _extraParam: _id });
    }
  };

  const resetBeastSearchParams = () => {
    setBeastSearchType_(null);
    setBeastSearchRarity(null);
    setBeastSearchTrait(null);
    setBeastSortBy(null);
  };

  const fetchOrders = async ({
    selectedCategoryId,
    forceCurrency,
    _extraParam,
    forceSpecies,
    forceRarity,
    forceTrait,
    forceSort,
  }) => {
    if (!isReady) return;
    setIsLoading(true);

    console.log(
      "fetchOrders(",
      {
        selectedCategoryId,
        forceCurrency,
        _extraParam,
        forceSpecies,
        forceRarity,
        forceTrait,
        forceSort,
      },
      ")"
    );

    let _orders = [];
    try {
      if (selectedCategoryId === 1) {
        // Prosperity Wars
        //_orders = await Service.getDutchAuctions();
        _orders = FAKE_DUTCH_LIST;
      } else if (selectedCategoryId === 2) {
        // Buy Beasts
        _orders = await Service.getBeastOrders({
          type_: forceSpecies === 0 ? null : forceSpecies ? forceSpecies : beastSearchType_,
          rarity: forceRarity === 0 ? null : forceRarity ? forceRarity : beastSearchRarity,
          trait:
            forceTrait === 0 || forceTrait === 999
              ? null
              : forceTrait
              ? forceTrait
              : beastSearchTrait,
          sortPreference: forceSort ? forceSort : beastSortBy,
        });
      } else if (selectedCategoryId === 3) {
        // Buy Resources
        _orders = await Service.getBestResourceOrders({
          currency: forceCurrency ? forceCurrency : preferredCurrency,
        });
      } else if (selectedCategoryId === 4) {
        // Sell Beasts
        _orders = DUMMY_BEAST_ORDER;
      } else if (selectedCategoryId === 5) {
        // Sell Resources
        _orders = enrichDummyResourceList(DUMMY_RESOURCE_LIST);
      } else if (selectedCategoryId === 6) {
        // My Orders
        _orders = await Service.getOrdersBySeller({ seller: player.address });
      } else if (selectedCategoryId === 7) {
        // Orders Of
        _orders = await Service.getOrdersBySeller({ seller: _extraParam });
      } else if (selectedCategoryId === 8) {
        // Orders By Resource
        _orders = await Service.getOrdersByResourceId({
          id: _extraParam,
          currency: forceCurrency ? forceCurrency : preferredCurrency,
        });
      } else if (selectedCategoryId === 9) {
        // Specific Order
        _orders = await Service.getOrdersById({
          orderIds: [_extraParam],
        });
      }

      if (selectedCategoryId !== 2) {
        resetBeastSearchParams();
      }

      if (selectedCategoryId === 3) {
        _orders = mountBestResourceOrders(_orders);
      }

      if (JSON.stringify(_orders) !== JSON.stringify(orders)) {
        console.log("👇REFRESHING ORDERS👇");
        console.log("PreviousOrders:", orders.length, " | NewOrders:", _orders.length);
        setOrders(_orders);
        console.log("👆ORDERS REFRESHED👆");
      } else {
        console.log(">>>>>>>>>>> Order refresh aborted: nothing changed.");
      }
    } catch (e) {
      // maybe this player is not even registered yet
      console.error("error fetching orders", e);
    }

    setIsLoading(false);
  };

  const mountBestResourceOrders = (fetchedBestOrders) => {
    // we will show all 25 resources on screen. If there is no order with that assetId, we will show a dummy order with ID 0 for that resource
    const bestOrders = [];
    for (let i = 0; i < 26; i++) {
      const order = fetchedBestOrders.find((order) => order.assetId === i);
      if (order) {
        bestOrders.push(order);
      } else {
        bestOrders.push({
          id: 0,
          createdAt: 1000,
          assetId: i,
          quantity: 0,
          seller: zeroAddress,
          privateFor: zeroAddress,
          priceInWei: "0",
          allowEbct: true,
          isNft: false,
          isPriceInBusd: true,
          isStabletokenOnly: false,
          isDutchAuction: false,
        });
      }
    }

    return bestOrders;
  };

  const enrichDummyResourceList = (orderList) => {
    // let's add info with regard to the balance of the user
    if (!player) return;

    const enrichedOrders = orderList.map((order) => {
      const assetId = order.assetId;
      const userBalance = Math.floor(player.ingameBalances[2][assetId] / 100);

      return {
        ...order,
        userBalance,
        isPreview: true,
      };
    });

    // now, remove the orders that have a balance of 0
    const filteredOrders = enrichedOrders.filter((order) => order.userBalance > 0);
    return filteredOrders;
  };

  // Initial Effect for setting up with URL parameters
  useEffect(() => {
    if (!isReady || !player) {
      return;
    }

    Messenger.cancelDelay();
    const category = CATEGORIES.find((category) => category.code === code);

    if (!category) return;

    handleCategorySelection(category.id, id);
  }, [code, id, player, selectedCategory, isReady]);

  // Separate Effect for regular refreshing
  useEffect(() => {
    const fetchOrdersBasedOnState = () => {
      fetchOrders({
        selectedCategoryId: selectedCategory.id,
        _extraParam: id,
        forceCurrency: preferredCurrency,
        forceSpecies: beastSearchType_,
        forceRarity: beastSearchRarity,
        forceTrait: beastSearchTrait,
        forceSort: beastSortBy,
      });
    };

    // Initial fetch
    fetchOrdersBasedOnState();
  }, [selectedCategory, preferredCurrency, beastSearchType_, beastSearchRarity, beastSearchTrait]);

  const onPreferredCurrencyChange = (newCurrency) => {
    setPreferredCurrency(newCurrency);
  };

  const handleMoreFromThisSeller = (seller) => {
    handleCategorySelection(7, seller);
    navigate(`/marketplace/orders-of/${seller}`, { replace: true });
  };

  const handleMoreLikeThis = (assetId) => {
    handleCategorySelection(8, assetId);
  };

  const getCurrencyFilterMiniDescription = () => {
    if (preferredCurrency === "eBCT") {
      return "Showing orders that accept eBCT, BCT, and USDC";
    } else if (preferredCurrency === "BCT") {
      return "Showing orders that accept BCT and USDC";
    } else if (preferredCurrency === "USDC") {
      return "Showing orders that accept USDC only";
    } else if (preferredCurrency === "FUEL") {
      return "Showing Pool Fuel orders (50x more Dragon Scales)";
    }
  };

  const handleSpeciesSelect = (species) => {
    setBeastSearchType_(species);
  };

  const handleRaritySelect = (rarity) => {
    setBeastSearchRarity(rarity);
  };

  const handleTraitSelect = (trait) => {
    setBeastSearchTrait(trait);
  };

  const handleSelectSort = (sort) => {
    setBeastSortBy(sort);
    fetchOrders({ selectedCategoryId: 2, forceSort: sort });
  };

  return (
    <>
      <section className="flat-title-page inner" style={{ paddingBottom: "0px" }}>
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">🎪</h1>
                <h1 className="heading text-center">P2P Marketplace</h1>
              </div>
              <div>
                <h5 className="sub-title help-center mg-bt-32 ">
                  Where you buy and sell your way to the top
                </h5>
              </div>
              <CategoriesContainer>
                <CategoryButtonsContainer>
                  {IS_BUY_RESOURCES_ACTIVE && (
                    <CategoryButton
                      onClick={() => handleCategorySelection(3)}
                      selected={selectedCategory.name === "Buy Resources"}
                    >
                      <img src={buyResourcesIcon} />
                      Buy Resources
                    </CategoryButton>
                  )}

                  {IS_BUY_BEASTS_ACTIVE && (
                    <CategoryButton
                      onClick={() => handleCategorySelection(2)}
                      selected={selectedCategory.name === "Buy Beasts"}
                    >
                      <img src={buyBeastsIcon} />
                      Buy Beasts
                    </CategoryButton>
                  )}

                  {IS_DUTCH_ACUTIONS_ACTIVE && (
                    <CategoryButton
                      onClick={() => handleCategorySelection(1)}
                      selected={selectedCategory.name === "Prosperity Wars"}
                    >
                      <img src={auctionIcon} />
                      Prosperity Wars
                    </CategoryButton>
                  )}

                  {IS_SELL_RESOURCES_ACTIVE && (
                    <CategoryButton
                      onClick={() => handleCategorySelection(5)}
                      selected={selectedCategory.name === "Sell Resources"}
                    >
                      <img src={sellResourcesIcon} />
                      Sell Resources
                    </CategoryButton>
                  )}

                  {IS_SELL_BEASTS_ACTIVE && (
                    <CategoryButton
                      onClick={() => handleCategorySelection(4)}
                      selected={selectedCategory.name === "Sell Beasts"}
                    >
                      <img src={sellBeastsIcon} />
                      Sell Beasts
                    </CategoryButton>
                  )}

                  {IS_MY_ORDERS_ACTIVE && (
                    <CategoryButton
                      onClick={() => handleCategorySelection(6)}
                      selected={selectedCategory.name === "My Active Orders"}
                    >
                      <img src={mySellOrdersIcon} />
                      My Orders
                    </CategoryButton>
                  )}
                </CategoryButtonsContainer>
              </CategoriesContainer>
              <CategoryDescriptionContainer>
                <h5 className="sub-title help-center mg-bt-32 ">{selectedCategory.description}</h5>
              </CategoryDescriptionContainer>
              {selectedCategory.name === "Buy Resources" && (
                <CategoriesContainer style={{ flexDirection: "column" }}>
                  <PreferredCurrencyTitle>
                    Explore these filters to find different orders:
                  </PreferredCurrencyTitle>
                  <PreferredCurrencyButtonsContainer>
                    <PreferredCurrencyButton
                      onClick={() => onPreferredCurrencyChange("eBCT")}
                      selected={preferredCurrency === "eBCT"}
                      selectedBg={indexToVerticalBackground[98]}
                    >
                      <EbctContainer>⚡</EbctContainer>
                      eBCT
                    </PreferredCurrencyButton>
                    <PreferredCurrencyButton
                      onClick={() => onPreferredCurrencyChange("BCT")}
                      selected={preferredCurrency === "BCT"}
                      selectedBg={indexToVerticalBackground[99]}
                    >
                      <img src={BCTIcon} />
                      BCT
                    </PreferredCurrencyButton>
                    <PreferredCurrencyButton
                      onClick={() => onPreferredCurrencyChange("USDC")}
                      selected={preferredCurrency === "USDC"}
                      selectedBg={indexToVerticalBackground[103]}
                    >
                      <img src={USDCIcon} />
                      USDC
                    </PreferredCurrencyButton>
                    <PreferredCurrencyButton
                      onClick={() => onPreferredCurrencyChange("FUEL")}
                      selected={preferredCurrency === "FUEL"}
                      selectedBg={indexToVerticalBackground["FUEL"]}
                    >
                      <EbctContainer>🐉</EbctContainer>
                      Fuel
                    </PreferredCurrencyButton>
                  </PreferredCurrencyButtonsContainer>
                  <CategoryMiniDescription>
                    {getCurrencyFilterMiniDescription()}
                  </CategoryMiniDescription>
                </CategoriesContainer>
              )}
              {selectedCategory.name === "Buy Beasts" && (
                <BeastFiltersContainer>
                  <BeastFiltersTitle>
                    Explore these filters to find different Beasts:
                  </BeastFiltersTitle>
                  <BeastFiltersDropdownsContainer>
                    <SpeciesSelectDropdown
                      speciesSelectCallback={handleSpeciesSelect}
                      speciesList={[
                        { id: 0, name: "Any Species" },
                        { id: 1, name: "Mouse" },
                        { id: 2, name: "Cat" },
                        { id: 3, name: "Cow" },
                        { id: 4, name: "Elephant" },
                        { id: 5, name: "Ape" },
                        { id: 6, name: "Dragon" },
                        { id: 7, name: "Griffin" },
                        { id: 8, name: "Satyr" },
                        { id: 9, name: "Dinosaur" },
                        { id: 10, name: "Cyborg" },
                        { id: 11, name: "Hydra" },
                        { id: 12, name: "Chimera" },
                        { id: 13, name: "Ent" },
                        { id: 14, name: "Kaiju" },
                        { id: 15, name: "Cacodemon" },
                      ]}
                      selected={beastSearchType_}
                      setSelected={setBeastSearchType_}
                    />
                    <RaritySelectDropdown
                      raritySelectCallback={handleRaritySelect}
                      rarityList={[
                        { id: 0, name: "Any Rarity" },
                        { id: 1, name: "Common" },
                        { id: 2, name: "Rare" },
                        { id: 3, name: "Epic" },
                        { id: 4, name: "Legendary" },
                        { id: 5, name: "Mythic" },
                      ]}
                      selected={beastSearchRarity}
                      setSelected={setBeastSearchRarity}
                    />
                    <TraitSelectDropdown
                      traitSelectCallback={handleTraitSelect}
                      injectTraitZero={true}
                      selected={beastSearchTrait}
                      setSelected={setBeastSearchTrait}
                    />
                    <SortSelectDropdown
                      selectCallback={handleSelectSort}
                      optionList={[
                        { id: 1, name: "Highest BCT Farm" },
                        { id: 2, name: "Highest Resource Farm" },
                        { id: 3, name: "Newest" },
                        { id: 4, name: "Oldest" },
                        { id: 5, name: "Pool Fuel first" },
                        { id: 6, name: "eBCT first" },
                      ]}
                      defaultText="Sort By"
                    />
                  </BeastFiltersDropdownsContainer>
                </BeastFiltersContainer>
              )}
              {selectedCategory.name === "Sell Resources" && player?.isMerchant && (
                <MerchantContainer>
                  <MerchantTitle style={{ margin: "8px" }}>
                    As an Official Merchant ({shortAccount(player?.address)}), you may create Dutch
                    Auctions.
                  </MerchantTitle>
                  <MerchantTitle style={{ color: "yellow", fontSize: "14px" }}>
                    In a Dutch Auction, YOU DEFINE THE MINIMUM PRICE and the system multiplies that
                    by 4.
                  </MerchantTitle>
                  <MerchantTitle style={{ color: "yellow", fontSize: "14px", marginTop: "-16px" }}>
                    The price then decreases gradually over 3 hours, reaching the price you defined
                    as the minimum.
                  </MerchantTitle>
                  <MerchantTitle style={{ marginBottom: "10px" }}>
                    Prosperity Wars cannot be partially filled; It's all or nothing.
                  </MerchantTitle>
                  <MerchantTitle style={{ color: "yellow", fontSize: "14px" }}>
                    Please set the FULL BUNDLE PRICE INSTEAD OF THE UNITARY PRICE when creating
                    Prosperity Wars.
                  </MerchantTitle>
                  <MerchantTitle style={{ marginBottom: "10px" }}>
                    Prosperity Wars cannot be cancelled before they reach minimum price.
                  </MerchantTitle>
                  <MerchantTitle style={{ color: "yellow", fontSize: "14px" }}>
                    Be sure to double check the price before creating a Dutch Auction.
                  </MerchantTitle>
                </MerchantContainer>
              )}
              {selectedCategory.name === "Sell Beasts" && player?.isMerchant && (
                <MerchantContainer>
                  <MerchantTitle style={{ marginBottom: "18px" }}>
                    As an Official Merchant ({shortAccount(player?.address)}), you may create Dutch
                    Auctions.
                  </MerchantTitle>
                  <MerchantTitle style={{ color: "yellow", fontSize: "14px", marginTop: "-10px" }}>
                    In a Dutch Auction, YOU DEFINE THE MINIMUM PRICE and the system multiplies that
                    by 4.
                  </MerchantTitle>
                  <MerchantTitle style={{ color: "yellow", fontSize: "14px", marginTop: "-16px" }}>
                    The price then decreases gradually over 3 hours, reaching the price you defined
                    as the minimum.
                  </MerchantTitle>
                  <MerchantTitle style={{ marginBottom: "10px" }}>
                    Prosperity Wars cannot be cancelled before they reach minimum price.
                  </MerchantTitle>
                  <MerchantTitle style={{ color: "yellow", fontSize: "14px" }}>
                    Be sure to double check the price before creating a Dutch Auction.
                  </MerchantTitle>
                </MerchantContainer>
              )}
            </div>
          </div>
        </div>
      </section>
      <Container>
        {isLoading ? (
          <OuterLoadingContainer>
            <LoadingContainer>
              <LoadingLabel>Loading...</LoadingLabel>
            </LoadingContainer>
          </OuterLoadingContainer>
        ) : (
          <MarketplaceGrid
            orders={orders}
            forceBeastId={forceBeastId ? parseInt(forceBeastId) : null}
            moreFromThisSellerCallback={handleMoreFromThisSeller}
            moreLikeThisCallback={handleMoreLikeThis}
            isMerchant={player && player?.isMerchant}
            isMyOrders={
              selectedCategory.id === 6 || selectedCategory.id === 7 || selectedCategory.id === 9
            }
          />
        )}
      </Container>
    </>
  );
};

export default MarketplaceScreen;
