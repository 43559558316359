import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: auto;
  border-radius: 10px;
  flex-wrap: wrap;
  margin-top: 40px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 720px;
  font-size: 18px;
  gap: 20px;
  margin-bottom: 100px;

  @media (max-width: 730px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const StashUnstashContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 370px;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
  outline: 3px solid white;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  @media (max-width: 400px) {
    width: 90%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
  line-height: 1.2;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`;

export const Button = styled.div`
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px 20px;
  text-align: center;
  width: 10%;
  min-width: 180px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &:active {
    background-color: #000;
    color: #fff;
  }
`;
