import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  height: 62px;
  margin: ${(props) => (props.margin ? props.margin : "34px 4px 0")};
  border-radius: 6px;
  cursor: ${(props) => (props.isActive ? "default" : "pointer")};
  outline: ${(props) => (props.isAtMax ? "2px solid white" : "2px solid white")};
  z-index: 2;
`;

export const ContainerBg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 60px;
  height: 62px;
  border-radius: 6px;

  background: ${(props) =>
    props.isActive
      ? props.background
      : `linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1))`};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
  translate: 0px -2px;

  img {
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;

export const TextContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  display: flex;
  align-items: center;

  justify-content: center;
  position: relative;
  bottom: ${(props) => (props.isInfinite ? "4px" : "5px")};
  line-height: ${(props) => (props.isInfinite ? "10px" : "1em")};

  h6 {
    font-size: ${(props) => (props.isInfinite ? "28px" : "14px")};
    text-shadow: 1px 1px black;
  }

  @media (max-width: 450px) {
    h6 {
      font-size: ${(props) => (props.isInfinite ? "24px" : "12px")};
    }
  }
`;
