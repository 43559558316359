import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { ProgressBar } from "react-bootstrap";
import {
  getProgressBarPercentageSpiral,
  getRemainingTimeSpiral,
} from "../../../utils/dutchAuctions";
import { currentBlock } from "../../../utils/block";

import { usePlayerContext } from "../../../contexts/PlayerContext";

const shimmerAnimation = keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

const glow1 = keyframes`
    from {
      filter: brightness(1.15) contrast(1.25);
    }
    to {
      filter: brightness(1);
    }
  }
`;

const glow2 = keyframes`
    from {
      filter: brightness(1.2) contrast(1.25) saturate(1.25);
    }
    to {
      filter: brightness(1);
    }
  }
`;

const glow3 = keyframes`
    from {
      filter: brightness(1.25) contrast(1.25) saturate(1.25) hue-rotate(10deg);
    }
    to {
      filter: brightness(1);
    }
  }
`;

const glow4 = keyframes`
    from {
      filter: brightness(1.25) contrast(1.25) saturate(1.25) hue-rotate(30deg);
    }
    to {
      filter: brightness(1);
    }
  }
`;

const glow5 = keyframes`
    from {
      filter: brightness(1.25) contrast(1.25) saturate(1.25) hue-rotate(100deg);
    }
    to {
      filter: brightness(1);
    }
  }
`;

const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 204px;
  height: 25px;
  margin-bottom: 8px;
  border-radius: 4px;
`;

const CustomProgressBar = styled(ProgressBar)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background-color: #2f2c33;

  animation: ${(props) => {
      if (props.now < 66) {
        return glow1;
      } else if (props.now < 80) {
        return glow2;
      } else if (props.now < 90) {
        return glow3;
      } else if (props.now < 95) {
        return glow4;
      } else if (props.now < 100) {
        return glow5;
      }
    }}
    3s infinite alternate;

  .progress-bar {
    background: linear-gradient(-90deg, #f72, #fa4);
    border-radius: 6px;
    height: 95%;
    transition: width 3s ease-in-out;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      border-radius: 4px;
      width: 100%;
      height: 100%;

      background-image: ${(props) =>
        props.now < 100
          ? "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))"
          : ""};

      background-position: -100% 0;
      background-size: 10% 100%;

      animation: ${(props) => (props.now < 100 ? shimmerAnimation : "")} 25s linear infinite;
    }
  }
`;

const DurationText = styled.div`
  position: absolute;
  bottom: 6px;
  font-size: 14px;
  color: #ffffff;
  text-shadow: ${"-0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333, 0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333"};
  font-weight: 800;
  z-index: 1;
  user-select: none;
`;

const OrderProgressBar = ({ startsAt, currentBlockNumber }) => {
  const { player } = usePlayerContext();

  const [isFirstRender, setIsFirstRender] = useState(true);

  // The progress in seconds
  const [formattedCurrentProgress, setFormattedCurrentProgress] = useState(0);

  // The progress % up to 6 hours (1 day / 4)
  const [currentBarProgress, setCurrentBarProgress] = useState(0);

  useEffect(() => {
    if (!player) return;

    if (isFirstRender) {
      setIsFirstRender(false);
    }

    const timer = setInterval(() => {
      const block = currentBlock(
        player.connection.initialBlockNumber,
        player.connection.initialTimestamp
      );

      const remaining = getRemainingTimeSpiral(startsAt, block);
      const barProgress = getProgressBarPercentageSpiral(startsAt, block);

      setFormattedCurrentProgress(remaining);
      setCurrentBarProgress(barProgress);
    }, 1000);

    // Clean up on component unmount
    return () => clearInterval(timer);
  }, [isFirstRender, , startsAt]);

  return (
    <ProgressContainer>
      <DurationText>
        {formattedCurrentProgress === 0 || formattedCurrentProgress === undefined
          ? "Loading progress..."
          : formattedCurrentProgress}
      </DurationText>
      <CustomProgressBar now={currentBarProgress} />
    </ProgressContainer>
  );
};

export default OrderProgressBar;
