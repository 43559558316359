import React, { useEffect, useState } from "react";
import Messenger from "../utils/Messenger";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import BigNumber from "bignumber.js";

import PurchaseModal from "../components/purchase-modal/PurchaseModal";

import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useUpgradesContext } from "../contexts/UpgradesContext";
import SquadsGrid from "../components/squads/SquadsGrid";

import { getExtraSlotPrices, getExtraSquadPrices } from "../config/resources/12-upgrades";
import { getSpecialBonuses, squadNumberToSquadType } from "../utils/squads";
import { numberToName } from "../config/resources/06-type_";

import { stasherLevelByBalance } from "../utils/constants";

const KingdomQuests = () => {
  const navigate = useNavigate();

  // Get the user's address and provider from the Web3Context
  const { signer, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE, blockchainState } = useWeb3Context();

  // Get the Migrate Nfts Context
  const { player, loading, isRegistered } = usePlayerContext();

  // Get upgrade functions for the upgrade modal
  const { buyExtraSlot, buyExtraSquad } = useUpgradesContext();

  const [isExtraSlotModalOpen, setIsExtraSlotModalOpen] = useState({
    isOpen: false,
    priceInResources: { bctPrice: 0 },
  });
  const [isExtraSquadModalOpen, setIsExtraSquadModalOpen] = useState({
    isOpen: false,
    priceInResources: { bctPrice: 0 },
  });
  const [squadId, setSquadId] = useState(0);

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }

    if (player) {
      console.log("Kingdom Quests :: Active Player is", player.address);
    } else {
      if (!loading) {
        console.log("Kingdom Quests :: Awaiting player data...");
      }
    }

    // on unmount, let's close the modal:
    return () => {
      setIsExtraSlotModalOpen({ isOpen: false, priceInResources: { bctPrice: 0 } });
      setIsExtraSquadModalOpen({ isOpen: false, priceInResources: { bctPrice: 0 } });
    };
  }, [player]);

  function stasherLevel() {
    if (!player) return 0;

    const playerStasherLevel = stasherLevelByBalance(
      parseFloat(BigNumber(player.stashed).div(1e18).toString())
    );

    return playerStasherLevel;
  }

  const handleOpenModal = (mySquadId) => {
    setSquadId(mySquadId);
    const costInResources = getCostInResources(mySquadId);
    setIsExtraSlotModalOpen({ isOpen: true, costInResources });
  };

  const handleCloseModal = () => {
    setIsExtraSlotModalOpen({ isOpen: false, costInResources: { bctCost: 0 } });
  };

  const handleOpenExtraSquadModal = () => {
    if (!player) return;

    const costInResources = getExtraSquadCostInResources(player);
    setIsExtraSquadModalOpen({ isOpen: true, costInResources });
  };

  const handleCloseExtraSquadModal = () => {
    setIsExtraSquadModalOpen({ isOpen: false, costInResources: { bctCost: 0 } });
  };

  const getCostInResources = (squadId) => {
    if (!player) return 0;

    return getExtraSlotPrices(squadId, player, blockchainState?.scales?.upgradesScale);
  };

  const getExtraSquadCostInResources = () => {
    if (!player) return 0;

    return getExtraSquadPrices(player, blockchainState?.scales?.upgradesScale);
  };

  const buyExtraSlotWithResources = () => {
    if (!player) return;

    buyExtraSlot(squadId, false);
  };

  const buyExtraSlotWithKozi = (_stablePrice) => {
    if (!player) return;

    buyExtraSlot(squadId, true, _stablePrice);
  };

  const buyExtraSquadWithResources = () => {
    if (!player) return;

    const _stasherLevel = stasherLevel();
    if (player.squads.length > _stasherLevel && _stasherLevel < 8) {
      Messenger.warn("Stasher level too low.");
      return;
    }

    buyExtraSquad(false);
  };

  const buyExtraSquadWithKozi = (_stablePrice) => {
    if (!player) return;

    const _stasherLevel = stasherLevel();
    if (player.squads.length > _stasherLevel && _stasherLevel < 8) {
      Messenger.warn("Stasher level too low.");
      return;
    }

    buyExtraSquad(true, _stablePrice);
  };

  const getExtraSquadDescription = () => {
    if (!player) return "";
    if (!player?.squads) return "";

    const squadNumber = player.squads.length + 1;
    const specialBonuses = getSpecialBonuses(squadNumber, player);

    let description = `Your ${squadNumber}th squad will be unlocked. It has special bonuses: `;

    description += `+${specialBonuses.bctBonus}% BCT farming bonus`;

    if (specialBonuses.lootBonus > 0) {
      description += `, +${specialBonuses.ebctBonus}% eBCT farming bonus, and +${specialBonuses.lootBonus}% Basic and Advanced Loot bonus!`;
      const type_ = squadNumberToSquadType(squadNumber);

      description += ` This squad can only be used by ${numberToName(
        type_
      )}s or Dream Beasts (Dragon and above).`;
    } else {
      description += ` and +${specialBonuses.ebctBonus}% eBCT farming bonus!`;
    }

    return description;
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container low-margin">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🐾</h1>
                    <h1 className="heading text-center">Kingdom Quests</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center">
                      <p>
                        Assemble your teams, add them to squads, and send them on quests to earn
                        resources and improve your NFTs.
                      </p>
                      <p className="miniP">
                        You may finish quests at any time after 24 hours. While there is no maximum
                        duration, you have to finish a quest to collect whatever you farmed there.
                      </p>

                      <p className="miniP">
                        <a
                          href="#!"
                          onClick={handleOpenExtraSquadModal}
                          style={{ textDecorationLine: "underline" }}
                        >
                          Unlock an extra squad
                        </a>
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section" style={{ paddingTop: "30px" }}>
            {!IS_PAUSED ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {player && signer && isRegistered && !loading && (
                  <SquadsGrid onExtraSlotClick={handleOpenModal} />
                )}
              </div>
            ) : (
              <div className="col-12">
                <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">{PAUSED_TITLE}</h2>
                <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
              </div>
            )}
          </section>
          <PurchaseModal
            title="Unlock a new beast slot"
            description="It enhances your synergy capabilities like nothing else in the game."
            costInResources={getCostInResources(squadId)}
            onPurchaseWithResources={buyExtraSlotWithResources}
            onPurchaseWithKozi={buyExtraSlotWithKozi}
            player={player}
            isOpen={isExtraSlotModalOpen.isOpen} // Pass the state to modal
            onClose={handleCloseModal} // Pass the handler to modal
          />
          {player && player?.squads && (
            <PurchaseModal
              title="Unlock an extra squad"
              description={getExtraSquadDescription(squadId)}
              costInResources={getExtraSquadCostInResources(squadId)}
              onPurchaseWithResources={buyExtraSquadWithResources}
              onPurchaseWithKozi={buyExtraSquadWithKozi}
              player={player}
              isOpen={isExtraSquadModalOpen.isOpen} // Pass the state to modal
              onClose={handleCloseExtraSquadModal} // Pass the handler to modal
            />
          )}
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default KingdomQuests;
