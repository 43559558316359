import BigNumber from "bignumber.js";

export function priceFromReserves(koziReserve, stabletokenReserve) {
  return quoteBuyKozi(BigNumber(1e18), koziReserve, stabletokenReserve);
}

export function quoteBuyKozi(koziToBuy, koziReserve, stabletokenReserve) {
  const costInStabletokens = BigNumber(stabletokenReserve.toString())
    .times(koziToBuy)
    .dividedBy(BigNumber(koziReserve.toString()).minus(koziToBuy))
    .plus(1);

  return costInStabletokens;
}

export function quoteStableToKozi(stabletokens, koziReserve, stabletokenReserve) {
  const kozi = BigNumber(koziReserve.toString())
    .times(stabletokens)
    .dividedBy(BigNumber(stabletokenReserve.toString()).plus(stabletokens));

  return kozi;
}

export function quoteSellKozi(koziToSell, koziReserve, stabletokenReserve, targetPrice) {
  let stabletokensOut = BigNumber(
    BigNumber(stabletokenReserve)
      .times(koziToSell)
      .dividedBy(BigNumber(koziReserve).plus(koziToSell))
      .toFixed(0)
  );

  const currentPrice = priceFromReserves(koziReserve, stabletokenReserve);
  stabletokensOut = _applySpread(
    koziToSell,
    stabletokensOut,
    currentPrice,
    targetPrice,
    koziReserve,
    stabletokenReserve
  );

  return stabletokensOut;
}

function _applySpread(
  koziToSell,
  stabletokensOut,
  currentPrice,
  targetPrice,
  koziReserve,
  stabletokenReserve
) {
  const simulatedPriceAfterSell = simulateNewPriceAfterSell(
    koziToSell,
    stabletokensOut,
    BigNumber(currentPrice).gt(BigNumber(targetPrice)),
    koziReserve,
    stabletokenReserve
  );

  let _spread;
  // if the simulatedPriceAfterSell is above the targetPrice, the spread is half the percentual difference between the target and the current, up to 99%;
  if (BigNumber(simulatedPriceAfterSell).gt(BigNumber(targetPrice))) {
    _spread = BigNumber(BigNumber(simulatedPriceAfterSell))
      .minus(targetPrice)
      .times(100)
      .dividedBy(targetPrice)
      .dividedBy(2);
  } else {
    // else, it's 2x the percentual difference between the target and the current, up to 99%;
    _spread = BigNumber(targetPrice)
      .minus(BigNumber(simulatedPriceAfterSell))
      .times(100)
      .dividedBy(targetPrice)
      .times(2);
  }
  if (_spread.gt(99)) {
    _spread = BigNumber(99);
  }

  _spread = Math.abs(Math.floor(_spread.toNumber()));

  const result = BigNumber(stabletokensOut)
    .times(100 - _spread)
    .dividedBy(100)
    .dp(0, BigNumber.ROUND_DOWN);

  return result;
}

export function simulateNewPriceAfterSell(
  koziIn,
  stabletokensOut,
  isAboveTargetPrice,
  koziReserve,
  stabletokenReserve
) {
  const postKoziReserve = isAboveTargetPrice
    ? BigNumber(koziReserve).plus(koziIn)
    : BigNumber(koziReserve);
  const postStableReserve = BigNumber(stabletokenReserve).minus(stabletokensOut);

  const priceAfter = BigNumber(postStableReserve)
    .times(1e18)
    .dividedBy(BigNumber(postKoziReserve).minus(1e18))
    .plus(1);

  return priceAfter;
}

export function _koziToUsdc(koziAmount, koziReserve, stabletokenReserve) {
  return quoteBuyKozi(koziAmount, koziReserve, stabletokenReserve);
}

export function _usdcToKozi(usdcAmount, koziReserve, stabletokenReserve) {
  return quoteStableToKozi(usdcAmount, koziReserve, stabletokenReserve);
}
