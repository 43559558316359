import React, { useEffect, useState } from "react";
import Messenger from "../../utils/Messenger";
import BigNumber from "bignumber.js";
import { usePlayerContext } from "../../contexts/PlayerContext";
import { useWeb3Context } from "../../contexts/Web3Context";
import { indexToBackground } from "../../utils/constants";

// import the Crafting Powder icon (it's resource 26)
import CraftingPowderIcon from "../../assets/images/resources/resource-26.png";
import DrascaIcon from "../../assets/images/resources/resource-21.png";
import ApeToolsIcon from "../../assets/images/resources/resource-9.png";

import FORGE_WEIGHTS from "./forgeWeights";
import MELT_ORDER from "./meltOrder";
import CRAFT_ORDER from "./craftOrder";
import DEFAULT_RESOURCE_LIST from "./defaultResourceList";

import {
  OuterShell,
  PageSubtitle,
  GlobalContainer,
  BalanceGroup,
  PlayerNotFound,
  Title,
  Subtitle,
  BalanceGroupContainer,
  InputWrapper,
  NumberInput,
  NumberButton,
  PlusPlusButton,
  MinusMinusButton,
  Number,
  ActionButton,
} from "./styles";
import BalanceContainer from "./balance-container";

const TheForgeScreen = () => {
  const { consult } = useWeb3Context();
  const { player, loading, isRegistered, meltResources, craftResources } = usePlayerContext();

  const [craftingPowderBalance, setCraftingPowderBalance] = useState(0);

  const [resourcesToCraft, setResourcesToCraft] = useState(DEFAULT_RESOURCE_LIST);
  const [powderToCraft, setPowderToCraft] = useState(0);
  const [apeToolsUsedToCraft, setApeToolsUsedToCraft] = useState(0);
  const [hasEnoughApeToolsToCraft, setHasEnoughApeToolsToCraft] = useState(true);

  const [resourcesToMelt, setResourcesToMelt] = useState(DEFAULT_RESOURCE_LIST);
  const [powderToMelt, setPowderToMelt] = useState(0);
  const [apeToolsUsedToMelt, setApeToolsUsedToMelt] = useState(0);
  const [hasEnoughApeToolsToMelt, setHasEnoughApeToolsToMelt] = useState(true);

  const [canMelt, setCanMelt] = useState(false);

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }

    if (!isRegistered && !loading) {
      console.log("Balances :: Player not found (yet?)");
      return;
    }

    const _setCurrentBalance = async () => {
      const _result = await consult({
        contractName: "Forge",
        functionName: "balanceOf",
        functionParams: [player.address],
      });
      setCraftingPowderBalance(parseInt(_result));
    };

    _setCurrentBalance();
  }, [player, loading, isRegistered]);

  if (!player) return null;

  const ingameBalances = player.ingameBalances;

  if (!ingameBalances) {
    console.log("Balances :: Player not found");
    return null;
  }

  const balanceValues = {
    ...ingameBalances[2]?.reduce((acc, balance, index) => {
      acc[index] = (parseFloat(balance) / 100).toFixed(0);
      return acc;
    }, {}),
  };

  const onCraftResourceChange = (index, value) => {
    const newResources = { ...resourcesToCraft };
    newResources[index] = value !== "" ? (parseInt(value) > 0 ? value : 0) : 0;
    setResourcesToCraft(newResources);
    const _powder = resourcesToPowder(newResources);
    _powder.craft = Math.ceil((_powder.craft * (100 - apeToolsUsedToCraft * 5)) / 100);
    setPowderToCraft(_powder);
  };

  const onApeToolsToCraftChange = (newApeToolsValue) => {
    const _powder = resourcesToPowder(resourcesToCraft);
    _powder.craft = Math.ceil((_powder.craft * (100 - newApeToolsValue * 5)) / 100);
    setPowderToCraft(_powder);

    if (newApeToolsValue <= player.ingameBalances[2][9]) {
      // has enough
      setHasEnoughApeToolsToCraft(true);
    } else {
      // doesn't have enough
      setHasEnoughApeToolsToCraft(false);
    }
  };

  const onApeToolsToMeltChange = (newApeToolsValue) => {
    const _powder = resourcesToPowder(resourcesToMelt);
    _powder.melt = Math.ceil((_powder.melt * (100 + newApeToolsValue * 5)) / 100);
    setPowderToMelt(_powder);

    if (newApeToolsValue <= player.ingameBalances[2][9]) {
      // has enough
      setHasEnoughApeToolsToMelt(true);
    } else {
      // doesn't have enough
      setHasEnoughApeToolsToMelt(false);
    }
  };

  const onMeltResourceChange = (index, value) => {
    const newResources = { ...resourcesToMelt };
    newResources[index] = value !== "" ? (parseInt(value) > 0 ? value : 0) : 0;
    setResourcesToMelt(newResources);
    const _powder = resourcesToPowder(newResources);
    _powder.melt = Math.ceil((_powder.melt * (100 + apeToolsUsedToMelt * 5)) / 100);
    setPowderToMelt(_powder);

    // for each resource, check all balances and see if there's enough to melt
    let _canMelt = true;
    for (let i = 0; i < 19; i++) {
      if (parseInt(newResources[index]) > parseInt(player.ingameBalances[2][index]) / 100) {
        _canMelt = false;
        break;
      }
    }

    setCanMelt(_canMelt);
  };

  function resourcesToPowder(_resources) {
    let powder = 0;
    let drasca = 0;
    Object.keys(_resources).forEach((index) => {
      const amount = _resources[index];
      const weight = index === "98" ? 10 : FORGE_WEIGHTS[index];
      powder += amount * weight;

      if (index === "25") {
        drasca += parseFloat(amount * 4);
      } else if (index > 20 && index !== "98") {
        drasca += parseFloat(amount);
      }
    });

    return {
      craft: powder,
      melt: powder / 5,
      drascaCost: drasca.toFixed(0, BigNumber.ROUND_DOWN),
    };
  }

  const handleIncreaseToCraft = () => {
    if (apeToolsUsedToCraft < 10) {
      setApeToolsUsedToCraft((prev) => prev + 1);
      onApeToolsToCraftChange(apeToolsUsedToCraft + 1);
    }
  };

  const handleIncreaseToCraftPlus = () => {
    if (apeToolsUsedToCraft + 10 <= 10) {
      setApeToolsUsedToCraft((prev) => prev + 10);
      onApeToolsToCraftChange(apeToolsUsedToCraft + 10);
    } else {
      setApeToolsUsedToCraft(10); // max
      onApeToolsToCraftChange(10);
    }
  };

  const handleDecreaseToCraft = () => {
    if (apeToolsUsedToCraft > 0) {
      setApeToolsUsedToCraft((prev) => prev - 1);
      onApeToolsToCraftChange(apeToolsUsedToCraft - 1);
    }
  };

  const handleDecreaseToCraftPlus = () => {
    if (apeToolsUsedToCraft - 10 > 0) {
      setApeToolsUsedToCraft((prev) => prev - 10);
      onApeToolsToCraftChange(apeToolsUsedToCraft - 10);
    } else {
      setApeToolsUsedToCraft(0);
      onApeToolsToCraftChange(0);
    }
  };

  ////////////
  const handleIncreaseToMelt = () => {
    if (apeToolsUsedToMelt < 10) {
      setApeToolsUsedToMelt((prev) => prev + 1);
      onApeToolsToMeltChange(apeToolsUsedToMelt + 1);
    }
  };

  const handleIncreaseToMeltPlus = () => {
    if (apeToolsUsedToMelt + 10 <= 10) {
      setApeToolsUsedToMelt((prev) => prev + 10);
      onApeToolsToMeltChange(apeToolsUsedToMelt + 10);
    } else {
      setApeToolsUsedToMelt(10); // max
      onApeToolsToMeltChange(10);
    }
  };

  const handleDecreaseToMelt = () => {
    if (apeToolsUsedToMelt > 0) {
      setApeToolsUsedToMelt((prev) => prev - 1);
      onApeToolsToMeltChange(apeToolsUsedToMelt - 1);
    }
  };

  const handleDecreaseToMeltPlus = () => {
    if (apeToolsUsedToMelt - 10 > 0) {
      setApeToolsUsedToMelt((prev) => prev - 10);
      onApeToolsToMeltChange(apeToolsUsedToMelt - 10);
    } else {
      setApeToolsUsedToMelt(0);
      onApeToolsToMeltChange(0);
    }
  };
  /////////////

  const handleMelt = async () => {
    // async function meltResources(resourceIndexes, amountsInUnits, apeToolsUsed)
    const _resourceIndexesToMelt = Object.keys(resourcesToMelt).map((index) => parseInt(index));
    const _amountsInUnitsToMelt = Object.values(resourcesToMelt).map((amount) => parseInt(amount));

    // now remove from both lists all resources that are 0
    const finalIndexList = [];
    const finalAmountList = [];
    for (let i = 0; i < _resourceIndexesToMelt.length; i++) {
      const index = _resourceIndexesToMelt[i];
      const value = _amountsInUnitsToMelt[i];

      if (value === 0) {
        continue;
      } else {
        finalIndexList.push(index);
        finalAmountList.push(value);
      }
    }

    const _result = await meltResources(finalIndexList, finalAmountList, apeToolsUsedToMelt);
  };

  const handleCraft = async () => {
    // async function craftResources(resourceIndexes, amountsInUnits, apeToolsUsed)
    const _resourceIndexesToCraft = Object.keys(resourcesToCraft).map((index) => parseInt(index));
    const _amountsInUnitsToCraft = Object.values(resourcesToCraft).map((amount) =>
      parseInt(amount)
    );

    // now remove from both lists all resources that are 0
    const finalIndexList = [];
    const finalAmountList = [];
    for (let i = 0; i < _resourceIndexesToCraft.length; i++) {
      const index = _resourceIndexesToCraft[i];
      const value = _amountsInUnitsToCraft[i];

      if (value === 0) {
        continue;
      } else {
        finalIndexList.push(index);
        finalAmountList.push(value);
      }
    }

    // now remove from both lists resource 98, if it's there
    const index98 = finalIndexList.indexOf(98);
    const eBctToCraft = finalAmountList[index98] || 0;
    if (index98 !== -1) {
      finalIndexList.splice(index98, 1);
      finalAmountList.splice(index98, 1);
    }

    console.log(finalIndexList);
    console.log(finalAmountList);

    const _result = await craftResources(
      finalIndexList,
      finalAmountList,
      apeToolsUsedToCraft,
      eBctToCraft
    );
  };

  return (
    <OuterShell>
      <PageSubtitle>
        You currently have {craftingPowderBalance.toFixed(0)}{" "}
        <img
          src={CraftingPowderIcon}
          alt="Crafting Powder"
          style={{ width: "30px", position: "relative", bottom: "3px" }}
        />{" "}
      </PageSubtitle>
      <GlobalContainer>
        <BalanceGroupContainer>
          <Title>Melt Resources</Title>

          <BalanceGroup>
            {isRegistered ? (
              <>
                {MELT_ORDER.map(
                  (index) =>
                    index !== 9 &&
                    index !== 19 &&
                    index !== 20 &&
                    index !== 21 &&
                    index !== 22 &&
                    index !== 23 &&
                    index !== 24 &&
                    index !== 25 && (
                      <BalanceContainer
                        key={`ingame-${index}`}
                        resource={index}
                        quantity={0}
                        background={
                          parseInt(resourcesToMelt[index]) >
                          parseInt(player.ingameBalances[2][index]) / 100
                            ? "red"
                            : indexToBackground[index]
                        }
                        isEditable={true}
                        value={resourcesToMelt[index]}
                        onChange={(value) => onMeltResourceChange(index, value)}
                      />
                    )
                )}
              </>
            ) : (
              <PlayerNotFound>
                <p>Player not found</p>
              </PlayerNotFound>
            )}
          </BalanceGroup>

          <InputWrapper>
            <MinusMinusButton onClick={handleDecreaseToMeltPlus}>{"--"}</MinusMinusButton>
            <NumberButton onClick={handleDecreaseToMelt}>-</NumberButton>
            <NumberInput>
              <Number style={hasEnoughApeToolsToMelt ? {} : { color: "#900" }}>
                {apeToolsUsedToMelt}{" "}
                <img
                  src={ApeToolsIcon}
                  alt="Dragon Scales"
                  style={{
                    width: "21px",
                    position: "relative",
                    bottom: "1.5px",
                    left: "-2px",
                    filter: hasEnoughApeToolsToMelt
                      ? "brightness(0) saturate(100%)"
                      : "brightness(0) saturate(100%) invert(10%) sepia(52%) saturate(7464%) hue-rotate(1deg) brightness(100%) contrast(117%)",
                  }}
                />
              </Number>
            </NumberInput>
            <NumberButton onClick={handleIncreaseToMelt}>+</NumberButton>
            <PlusPlusButton onClick={handleIncreaseToMeltPlus}>{"++"}</PlusPlusButton>
          </InputWrapper>

          <ActionButton disabled={!canMelt} onClick={handleMelt}>
            Melt now: receive {powderToMelt?.melt || "0"}{" "}
            <img
              src={CraftingPowderIcon}
              alt="Crafting Powder"
              style={{ width: "30px", position: "relative", bottom: "3px" }}
            />
            {powderToMelt?.drascaCost > 0 && (
              <>
                {" "}
                and {powderToMelt?.drascaCost || "0"}{" "}
                <img
                  src={DrascaIcon}
                  alt="Dragon Scales"
                  style={{ width: "30px", position: "relative", bottom: "3px" }}
                />
              </>
            )}
          </ActionButton>
        </BalanceGroupContainer>

        <BalanceGroupContainer>
          <Title>Craft Resources</Title>

          <BalanceGroup>
            {isRegistered ? (
              <>
                {CRAFT_ORDER.map(
                  (index) =>
                    index !== 19 &&
                    index !== 20 &&
                    index !== 21 && (
                      <BalanceContainer
                        id={`transfer-${index}`}
                        key={`ingame-${index}`}
                        resource={index}
                        quantity={0}
                        background={indexToBackground[index]}
                        isEditable={true}
                        value={resourcesToCraft[index]}
                        onChange={(value) => onCraftResourceChange(index, value)}
                      />
                    )
                )}
              </>
            ) : (
              <PlayerNotFound>
                <p>Player not found</p>
              </PlayerNotFound>
            )}
          </BalanceGroup>

          <InputWrapper>
            <MinusMinusButton onClick={handleDecreaseToCraftPlus}>{"--"}</MinusMinusButton>
            <NumberButton onClick={handleDecreaseToCraft}>-</NumberButton>
            <NumberInput>
              <Number style={hasEnoughApeToolsToCraft ? {} : { color: "#900" }}>
                {apeToolsUsedToCraft}{" "}
                <img
                  src={ApeToolsIcon}
                  alt="Dragon Scales"
                  style={{
                    width: "21px",
                    position: "relative",
                    bottom: "1.5px",
                    left: "-2px",
                    filter: hasEnoughApeToolsToCraft
                      ? "brightness(0) saturate(100%)"
                      : "brightness(0) saturate(100%) invert(10%) sepia(52%) saturate(7464%) hue-rotate(1deg) brightness(100%) contrast(117%)",
                  }}
                />
              </Number>
            </NumberInput>
            <NumberButton onClick={handleIncreaseToCraft}>+</NumberButton>
            <PlusPlusButton onClick={handleIncreaseToCraftPlus}>{"++"}</PlusPlusButton>
          </InputWrapper>

          {player && player?.ingameBalances && player?.ingameBalances[2] && (
            <ActionButton
              disabled={
                craftingPowderBalance < powderToCraft?.craft ||
                player?.ingameBalances[2][21] < 100 * powderToCraft?.drascaCost ||
                powderToCraft?.craft === 0 ||
                !powderToCraft?.craft
              }
              style={
                (craftingPowderBalance >= powderToCraft?.craft &&
                  player.ingameBalances[2][21] >= 100 * powderToCraft?.drascaCost) ||
                !powderToCraft?.craft
                  ? { color: "#fff" }
                  : {
                      color: "#f77",
                      textShadow:
                        "-0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333, 0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333",
                    }
              }
              onClick={handleCraft}
            >
              Craft now: spend {powderToCraft?.craft || "0"}{" "}
              <img
                src={CraftingPowderIcon}
                alt="Crafting Powder"
                style={{ width: "30px", position: "relative", bottom: "3px" }}
              />
              {powderToCraft?.drascaCost > 0 && (
                <>
                  {" "}
                  and {powderToCraft?.drascaCost || "0"}{" "}
                  <img
                    src={DrascaIcon}
                    alt="Dragon Scales"
                    style={{ width: "30px", position: "relative", bottom: "3px" }}
                  />
                </>
              )}
            </ActionButton>
          )}
        </BalanceGroupContainer>
      </GlobalContainer>
    </OuterShell>
  );
};

export default TheForgeScreen;
