import BigNumber from "bignumber.js"; // TODO: use ethers BigNumber for precision and performance

BigNumber.config({ DECIMAL_PLACES: 0, ROUNDING_MODE: BigNumber.ROUND_DOWN });

/**
 * All functions return { bctPrice, resourceCents, resourceIndexes }
 * bctPrice is in wei, resourceCents is in cents
 * resourceIndexes is an array of indexes of resources that are required to pay for the upgrade
 */

const RANK_UP_PRICE = 2;
const AGILITY_UP_PRICE = 5;
const EXTRA_SQUAD_PRICE = 5000;
const SKILL_LEVEL_PRICE = 125;
const NUTS_UP_PRICE = 2;
const TRAIT_REROLL_PRICE = 1;
const ADD_TRAIT_PRICE = 100;
const REASSEMBLE_PRICE = 750;
const DISSOLVE_PRICE = 64;
const INTEGRATION_PRICE = 25;
const TRANSFORMATION_PRICE = 25;
const EVOLUTION_PRICE = 100;
const EXTRACTION_PRICE = 5000;
const MINT_PRICE = 25;

export function getPerksArray({ prices, update, buyFunc }) {
  return [
    {
      id: 0,
      title: "Rank",
      description: "The higher the rank of a beast, the more BCT and eBCT it farms.",
      description2: "",
      maxLevel: 99,
      mythicMaxLevel: 99,
      titanicMaxLevel: 99,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.rank",
      previewLevelReference: "rankUpLevels",
      getPriceInResourcesFunction: prices.rank,
      updateFunction: update.rank,
      buyFunction: buyFunc.resources.rank,
      buyWithBusdFunction: buyFunc.busd.rank,
    },
    {
      id: 1,
      title: "Agility",
      description: "It improves how much each rank increases farming.",
      description2: "",
      maxLevel: 5,
      mythicMaxLevel: 5,
      titanicMaxLevel: 5,
      sacrificeSlots: 0,
      hasInputText: false,
      extraHelpContent: {
        title: "Agility Levels",
        description1:
          "Agility only works in conjunction with Ranks. If your beast is Rank zero, increasing its Agility won't do anything.",
      },
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.agility || 0",
      previewLevelReference: "agilityLevels",
      getPriceInResourcesFunction: prices.agility,
      updateFunction: update.agility,
      buyFunction: buyFunc.resources.agility,
      buyWithBusdFunction: buyFunc.busd.agility,
    },
    {
      id: 2,
      title: "Base Farm Boost",
      description: "Each Base Farm Boost level increases this beast's base farm by 1%.",
      description2: "",
      maxLevel: 50,
      mythicMaxLevel: 50,
      titanicMaxLevel: 50,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.nuts || 0",
      previewLevelReference: "nutsLevels",
      getPriceInResourcesFunction: prices.baseFarm,
      updateFunction: update.baseFarm,
      buyFunction: buyFunc.resources.baseFarm,
      buyWithBusdFunction: buyFunc.busd.baseFarm,
    },
    {
      id: 3,
      title: "Basic Loot Skill",
      description:
        "Increase how much Basic Loot this beast farms. The rarer the beast, the higher it goes.",
      description2: "",
      maxLevel: 5,
      mythicMaxLevel: 7,
      titanicMaxLevel: 10,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.skills[0] || 0",
      previewLevelReference: "skill1Levels",
      getPriceInResourcesFunction: prices.skill1,
      updateFunction: update.skill1,
      buyFunction: buyFunc.resources.skill1,
      buyWithBusdFunction: buyFunc.busd.skill1,
    },
    {
      id: 4,
      title: "Advanced Loot Skill",
      description:
        "Increase how much Advanced Loot this beast farms. The rarer the beast, the higher it goes.",
      description2: "",
      maxLevel: 5,
      mythicMaxLevel: 7,
      titanicMaxLevel: 10,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.skills[1] || 0",
      previewLevelReference: "skill2Levels",
      getPriceInResourcesFunction: prices.skill2,
      updateFunction: update.skill2,
      buyFunction: buyFunc.resources.skill2,
      buyWithBusdFunction: buyFunc.busd.skill2,
    },
    {
      id: 5,
      title: "Golden Nuts Skill",
      description:
        "Increase how many Golden Nuts this beast farms. The rarer the beast, the higher it goes.",
      description2: "",
      maxLevel: 5,
      mythicMaxLevel: 7,
      titanicMaxLevel: 10,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.skills[2] || 0",
      previewLevelReference: "skill3Levels",
      getPriceInResourcesFunction: prices.skill3,
      updateFunction: update.skill3,
      buyFunction: buyFunc.resources.skill3,
      buyWithBusdFunction: buyFunc.busd.skill3,
    },
    {
      id: 6,
      title: "Evolution Items Skill",
      description:
        "Increase how many Evolution Items this beast farms. The rarer the beast, the higher it goes.",
      description2: "",
      maxLevel: 5,
      mythicMaxLevel: 7,
      titanicMaxLevel: 10,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.skills[3] || 0",
      previewLevelReference: "skill4Levels",
      getPriceInResourcesFunction: prices.skill4,
      updateFunction: update.skill4,
      buyFunction: buyFunc.resources.skill4,
      buyWithBusdFunction: buyFunc.busd.skill4,
    },
    {
      id: 7,
      title: "Evolution",
      description:
        "Change this beast's name and appearance; increase its farming power; choose one trait and roll the other. Doesn't work on common beasts.",
      description2:
        "The first time it evolves, this beast gains a 25% increase in Base Farm. From the second evolution onwards, it gains a 2% increase in Base Farm.",
      maxLevel: 11,
      mythicMaxLevel: 11,
      titanicMaxLevel: 11,
      sacrificeSlots: 0,
      hasInputText: true,
      hasTraitPicker: true,
      forbiddenRarities: [1],
      injectTraitZero: true,
      isReady: true,
      extraHelpContent: {
        title: "How to evolve",
        description1:
          "Evolving a beast means many things: it changes its name and appearance, it increases its farming power, it allows you to choose one trait among the 50 available traits and roll the other. Everything is optional, except for the base farm boost.",
        description2:
          "To create a nice image for your evolved beast, it's strongly recommended that you try and use AI image generators.",
        description3:
          "After you create your image, you can upload it for free to %link% and paste the link here. Images from 200x200 pixels up will work - they should be square and must be in .png format. The maximum size is 2Mb per image. You can use gifs for mythics!",
        link1: null,
        link2: null,
        link3: "https://postimages.org/",
      },
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.evos || 0",
      previewLevelReference: "nft?.evos + 1",
      getPriceInResourcesFunction: prices.evolution,
      updateFunction: nonReadyUpgradeFunction,
      buyFunction: buyFunc.resources.evolution,
      buyWithBusdFunction: buyFunc.busd.evolution,
    },
    {
      id: 8,
      title: "Integrate",
      description:
        "Sacrifice (destroy) two other beasts of the same species and rarity to make this one gain one rarity level. All attributes will be averaged out between the three beasts (rounded down), then the Base Farm will be increased in 70%.",
      description2:
        "You may sacrifice one other beast instead of two if you add 3 Power Mangoes into the mix.",
      maxLevel: 0,
      mythicMaxLevel: 0,
      titanicMaxLevel: 0,
      sacrificeSlots: 2,
      hasInputText: false,
      forbiddenRarities: [5, 6],
      injectTraitZero: false,
      isReady: true,
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "0",
      previewLevelReference: "nonReadyLevels",
      getPriceInResourcesFunction: prices.integration,
      updateFunction: update.integration,
      buyFunction: buyFunc.resources.integration,
      buyWithBusdFunction: buyFunc.busd.integration,
    },
    {
      id: 9,
      title: "Transform",
      description:
        "Sacrifice (destroy) two other legendary beasts of the same species to transform this one into a common beast of the next species. All attributes will be averaged out between the three beasts (rounded down), then the Base Farm will be increased in 70%.",
      description2:
        "You may sacrifice only 1 other beast instead of two if you add 3 Power Mangoes into the mix.",
      maxLevel: 0,
      mythicMaxLevel: 0,
      titanicMaxLevel: 0,
      sacrificeSlots: 2,
      hasInputText: false,
      forbiddenRarities: [1, 2, 3, 5, 6],
      injectTraitZero: false,
      isReady: true,
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "0",
      previewLevelReference: "nonReadyLevels",
      getPriceInResourcesFunction: prices.transformation,
      updateFunction: update.transformation,
      buyFunction: buyFunc.resources.transformation,
      buyWithBusdFunction: buyFunc.busd.transformation,
    },
    {
      id: 10,
      title: "Trait Reroll",
      description:
        "Choose any one of the 50 available traits for this beast and randomnly reroll the others.",
      description2: "The trait you choose is guaranteed. The others are random.",
      maxLevel: 0,
      mythicMaxLevel: 0,
      titanicMaxLevel: 0,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "0",
      previewLevelReference: "nonReadyLevels",
      hasTraitPicker: true,
      getPriceInResourcesFunction: prices.reroll,
      updateFunction: update.reroll,
      buyFunction: buyFunc.resources.reroll,
      buyWithBusdFunction: buyFunc.busd.reroll,
    },
    {
      id: 11,
      title: "Add Trait",
      description: "Add a third trait of your choice to any beast that has exactly two traits.",
      description2: "You can choose any trait you like from the list of available traits.",
      maxLevel: 3,
      mythicMaxLevel: 3,
      titanicMaxLevel: 3,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft.traits.length",
      previewLevelReference: "nft.traits.length + 1",
      hasTraitPicker: true,
      getPriceInResourcesFunction: prices.addTrait,
      updateFunction: update.addTrait,
      buyFunction: buyFunc.resources.addTrait,
      buyWithBusdFunction: buyFunc.busd.addTrait,
    },
    {
      id: 12,
      title: "Extract",
      description:
        "Extract one of this beast's traits and add it to one of your Squads. This beast dies in the process.",
      description2:
        "The Squad itself will have a trait that synergizes with whoever is in it. Extracting a trait will destroy this beast in the process.",
      maxLevel: 0,
      mythicMaxLevel: 0,
      titanicMaxLevel: 0,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      hasTraitPicker: true,
      useNftTraitsInTraitPicker: true,
      hasSquadPicker: true,
      currentLevelReference: "0",
      previewLevelReference: "nonReadyLevels",
      getPriceInResourcesFunction: prices.extraction,
      updateFunction: update.extraction,
      buyFunction: buyFunc.resources.extraction,
      buyWithBusdFunction: buyFunc.busd.extraction,
      useExtendedTraitList: true,
    },
    {
      id: 13,
      title: "Reassemble",
      description:
        "Choose two traits for this beast. If it has 3 traits, the third will be rerolled.",
      description2: "",
      maxLevel: 0,
      mythicMaxLevel: 0,
      titanicMaxLevel: 0,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "0",
      previewLevelReference: "nonReadyLevels",
      hasTraitPicker: true,
      hasSecondaryTraitPicker: true,
      getPriceInResourcesFunction: prices.reassemble,
      updateFunction: update.reassemble,
      buyFunction: buyFunc.resources.reassemble,
      buyWithBusdFunction: buyFunc.busd.reassemble,
    },
    {
      id: 14,
      title: "Mythic Basic Loot",
      description:
        "This beast has a second Basic Loot Skill! Whenever it would farm Basic Loot, it farms what boths skills combined would farm.",
      description2: "",
      maxLevel: 5,
      mythicMaxLevel: 7,
      titanicMaxLevel: 10,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [1, 2, 3, 4],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.skills[4] || 0",
      previewLevelReference: "skill5Levels",
      getPriceInResourcesFunction: prices.skill5,
      updateFunction: update.skill5,
      buyFunction: buyFunc.resources.skill5,
      buyWithBusdFunction: buyFunc.busd.skill5,
    },
    {
      id: 15,
      title: "Titanic Advanced Loot",
      description:
        "This beast has a second Advanced Loot Skill! Whenever it would farm Advanced Loot, it farms what boths skills combined would farm.",
      description2: "",
      maxLevel: 5,
      mythicMaxLevel: 7,
      titanicMaxLevel: 10,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "nft?.skills[5] || 0",
      previewLevelReference: "skill6Levels",
      getPriceInResourcesFunction: prices.skill6,
      updateFunction: update.skill6,
      buyFunction: buyFunc.resources.skill6,
      buyWithBusdFunction: buyFunc.busd.skill6,
    },
    {
      id: 16,
      title: "Deify",
      description:
        "Deify a mythic beast into something else entirely. It will be fully reborn and will lose everything it has: name, image, rank, agility, traits, and all skills will be reset. Requires Stasher Level 9 or 10.",
      description2:
        "The resulting Dream Beast will be a mythic beast with immense farming power, an extra farming skill, and a higher maximum skill level in each skill.",
      maxLevel: 0,
      maxType: 10,
      sacrificeSlots: 0,
      isReady: true,
      forbiddenRarities: [1, 2, 3, 4],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      omitLevelPicker: true,
      currentLevelReference: "0",
      previewLevelReference: "0",
      getPriceInResourcesFunction: prices.deification,
      buyFunction: buyFunc.resources.deification,
      buyWithBusdFunction: buyFunc.busd.deification,
    },
    {
      id: 17,
      title: "Transmute",
      description:
        "Choose the three traits of any beast that already has 3 traits. You may choose one of the special traits, but they cost extra Dragons Scales.",
      description2: "",
      maxLevel: 0,
      mythicMaxLevel: 0,
      titanicMaxLevel: 0,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      mustHave3Traits: true,
      useExtendedTraitList: true,
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "0",
      previewLevelReference: "nonReadyLevels",
      hasTraitPicker: true,
      hasSecondaryTraitPicker: true,
      hasTertiaryTraitPicker: true,
      getPriceInResourcesFunction: prices.transmute,
      updateFunction: update.transmute,
      buyFunction: buyFunc.resources.transmute,
      buyWithBusdFunction: buyFunc.busd.transmute,
    },
    {
      id: 18,
      title: "Dissolve",
      description:
        "Dissolve up to 6 beasts into Dna and / or beast boxes. All attributes are ignored, except for each beast's species and rarity.",
      description2: "",
      maxLevel: 0,
      mythicMaxLevel: 0,
      titanicMaxLevel: 0,
      sacrificeSlots: 0,
      hasInputText: false,
      injectTraitZero: false,
      isReady: true,
      forbiddenRarities: [],
      useNftTraitsInTraitPicker: false,
      hasSquadPicker: false,
      currentLevelReference: "0",
      previewLevelReference: "nonReadyLevels",
      hasTraitPicker: false,
      hasSecondaryTraitPicker: false,
      hasTertiaryTraitPicker: false,
      getPriceInResourcesFunction: prices.dissolution,
      updateFunction: update.dissolution,
      buyFunction: buyFunc.resources.dissolution,
      buyWithBusdFunction: buyFunc.busd.dissolution,
    },
  ];
}

const nonReadyUpgradeFunction = () => {}; // do nothing

function scaleToDivider(upgradesScale) {
  switch (upgradesScale) {
    case 100: // it started here, and it divided prices by 2
      return 2;
    case 50:
      return 4;
    case 25:
      return 8;
    case 12:
      return 16;
    case 6:
      return 32;
    case 3:
      return 64;
    case 1:
      return 128;
    default:
      return 128; // the default is a scale of 0.50% for upgrades (50 in our decimal-less notation), representing the 5th halving
  }
}

/* SOL:
  uint256 bctPrice = config.rankUpPrice * (2 ** type_ - 1) * (2 ** rarity - 1);
  return (bctPrice * 1E18, bctPrice);
*/
export function getRankUpPrices(nft, levels, scale) {
  const type_ = nft.type_ > 5 ? 5 : nft.type_;
  const bctPrice = RANK_UP_PRICE * (2 ** type_ - 1) * (2 ** nft.rarity - 1) * levels;

  return {
    bctPrice: BigNumber(bctPrice).times(1e18).times(scale).div(100).div(2),
    resourceCents: [bctPrice],
    resourceIndexes: [nft.type_ - 1], // Basic Loot of that type
  };
}

/* SOL:
  uint256 baseBctPrice = config.agilityUpPrice * (2 ** type_ - 1) * (2 ** rarity - 1);

  uint256 bctPrice;
  for (uint i = currentAgility; i < currentAgility + levels; i++) {
    bctPrice += baseBctPrice * ((i + 1) ** 2);
  }

  return (bctPrice * 1E18, (bctPrice * 10) / 25);
*/
export function getAgilityUpPrices(nft, levels, scale) {
  const type_ = nft.type_ > 5 ? 5 : nft.type_;
  const baseBctPrice = AGILITY_UP_PRICE * (2 ** type_ - 1) * (2 ** nft.rarity - 1);
  let bctPrice = BigNumber(0);
  for (let i = nft.agility; i < nft.agility + levels; i++) {
    bctPrice = bctPrice.plus(baseBctPrice * (i + 1) ** 2);
  }

  return {
    bctPrice: bctPrice.times(1e18).times(scale).div(100).div(2),
    resourceCents: [(bctPrice * 10) / 25],
    resourceIndexes: [nft.type_ - 1], // Basic Loot of that type
  };
}

/* SOL:
uint256 priceInBct = 125 * rarity * skillNumber * levels;

return (priceInBct * 1E18, priceInBct / 5);
*/
export function getSkillLevelPrices(nft, skillNumber, levels, scale) {
  let priceInBct = SKILL_LEVEL_PRICE * nft.rarity * skillNumber * levels;

  const resourceCents = [priceInBct / 5];

  const resourceIndexes = [];
  if (skillNumber === 1) {
    resourceIndexes.push(nft.type_ - 1); // Basic Loot of that type
  } else if (skillNumber === 2) {
    resourceIndexes.push(nft.type_ + 4); // Advanced Loot of that type
  } else if (skillNumber === 3) {
    resourceCents.push([priceInBct / 5], [priceInBct / 5]);
    resourceIndexes.push(nft.type_ - 1, nft.type_ + 4); // Basic and Advanced Loot of that type
  } else if (skillNumber === 4) {
    resourceIndexes.push(10); // Golden Nuts
  } else if (skillNumber === 5) {
    resourceIndexes.push(nft.type_); // Basic Loot + 1
  } else {
    resourceIndexes.push(nft.type_ + 5); // Advanced Loot + 1
  }

  if (nft.species === 14 && skillNumber === 6) {
    priceInBct *= 10;
  } else if (nft.species === 15 && (skillNumber === 6 || skillNumber === 2)) {
    priceInBct *= 10;
  }

  const result = {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents,
    resourceIndexes,
  };

  return result;
}

// 2 * nft.type_ * nft.rarity * 1e18 * howManyNuts
export function getNutsUpPrices(nft, howManyNuts, scale) {
  const priceInBct = NUTS_UP_PRICE * nft.type_ * nft.rarity * howManyNuts;

  const resourceCents = [howManyNuts * 100];
  if (nft.type_ >= 6 && nft.type_ <= 10) {
    resourceCents[0] = resourceCents[0] * 5;
  } else if (nft.type_ > 10) {
    resourceCents[0] = resourceCents[0] * 10;
  }

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents,
    resourceIndexes: [10], // Golden Nuts
  };
}

export function getExtraSlotPrices(squadId, player, scale) {
  if (!player || !squadId) return { bctPrice: BigNumber(0), resourceCents: 0, resourceIndexes: [] };

  // first, get the index of the squad inside the player.squads object:
  const squad = player.squads.find((s) => s.id === squadId);
  const squadNumber = squad.squadNumber;

  // now, calculate the squadBonus, which is 5% per squad after the 4th
  const squadBonus = getSquadBonusBySquadNumber(squadNumber);

  // now, get the current size of the squad:
  const currentSize = squad.size;

  // Now, use the same logic as in the contract to calculate the price:
  let priceInBct = 0;
  let priceInCheese = 0;

  if (currentSize === 3) {
    priceInBct = 10000;
    priceInCheese = 11;
  } else if (currentSize === 4) {
    priceInBct = 15000;
    priceInCheese = 22;
  } else if (currentSize === 5) {
    priceInBct = 25000;
    priceInCheese = 44;
  }

  priceInBct = BigNumber(priceInBct);
  priceInBct = priceInBct.times(100 + squadBonus).div(100);
  priceInCheese = (priceInCheese * (100 + squadBonus)) / 100;

  return {
    bctPrice: priceInBct.times(1e18).times(scale).div(100).div(2),
    resourceCents: [priceInCheese * 100],
    resourceIndexes: [6], // Cat Milk
  };
}

function getSquadBonusBySquadNumber(squadNumber) {
  if (squadNumber < 5) return 0;

  return 5 * (squadNumber - 4);
}

export function getExtraSquadPrices(player, scale) {
  if (!player) return { bctPrice: BigNumber(0), resourceCents: 0, resourceIndexes: [] };

  const currentSquads = player.squads.length;

  const priceInBct = BigNumber(currentSquads - 3).times(EXTRA_SQUAD_PRICE);
  const priceInCheese = currentSquads <= 3 ? 0 : (currentSquads - 4) * 12;

  return {
    bctPrice: priceInBct.times(1e18).times(scale).div(100).div(2),
    resourceCents: [priceInCheese * 100],
    resourceIndexes: [5], // Exotic Cheese
  };
}

export function getTraitRerollPrices(nft, scale) {
  const priceInBct = TRAIT_REROLL_PRICE * nft.type_ * nft.rarity;

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [100], // always 1 Gene Therapy
    resourceIndexes: [11], // Gene Therapy
  };
}

export function getReassemblePrices(nft, scale) {
  const priceInBct = REASSEMBLE_PRICE * nft.type_ * nft.rarity;

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [5000], // always 50 Gene Therapy
    resourceIndexes: [11], // Gene Therapy
  };
}

export function getTransmutePrices(nft, specialTraitsCount, scale) {
  const priceInBct = REASSEMBLE_PRICE * 2 * nft.type_ * nft.rarity;

  let dnaIndex = [nft.type_ + 13]; // DNA/boxes/dream resources of the type
  if (dnaIndex > 25) dnaIndex = 25;

  let drascaCents = 0;
  if (specialTraitsCount > 0) {
    drascaCents = (specialTraitsCount * 3) ** 2 * 100;
  }

  const result = {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [2500, 100, 2500, 10000, 100], // always 25 Golden Nuts, 100 Gene Therapy, 25 Mushrooms, 1 DNA, and 1 Ape Tool
    resourceIndexes: [7, 9, 10, 11, dnaIndex], // Gene Therapy, Mushrooms and DNA/boxes/dream resources of the type, Ape Tool
  };

  if (drascaCents > 0) {
    result.resourceCents.push(drascaCents);
    result.resourceIndexes.push(21); // Drasca
  }

  return result;
}

export function getDissolutionPrices(howMany, scale) {
  const priceInBct = DISSOLVE_PRICE * howMany;

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).div(scaleToDivider(scale)),
    resourceCents: [100, 300], // always 3 Golden Nuts, and 1 Ape Tools
    resourceIndexes: [9, 10], // Golden Nuts and Ape Tools
  };
}

export function getAddTraitPrices(nft, scale) {
  const priceInBct = ADD_TRAIT_PRICE * nft.type_ * nft.rarity;

  let dnaIndex = [nft.type_ + 13]; // DNA of the type
  if (dnaIndex > 25) dnaIndex = 25;

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [2500, 100], // always 25 MagicMushrooms and 1 DNA
    resourceIndexes: [7, dnaIndex], // Mushrooms + DNA
  };
}

export function getIntegrationPrices(nft, scale) {
  const priceInBct = INTEGRATION_PRICE * nft.type_ * nft.rarity;

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [nft.rarity * 25],
    resourceIndexes: [nft.type_ + 4], // Advanced Loot of that type
  };
}

export function getTransformationPrices(nft, scale) {
  const priceInBct = TRANSFORMATION_PRICE * nft.type_ * nft.rarity;

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [100, 100],
    resourceIndexes: [11, nft.type_ + 14], // Gene Therapy and DNA ABOVE the type
  };
}

export function getEvolutionPrices(nft, scale) {
  const priceInBct = EVOLUTION_PRICE * nft.type_ * nft.rarity;

  let dnaIndex = [nft.type_ + 13]; // DNA of the type
  if (dnaIndex > 25) dnaIndex = 25;

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [100, 100],
    resourceIndexes: [11, dnaIndex], // Gene Therapy and DNA of the type
  };
}

export function getExtractionPrices(nft, scale) {
  let priceInBct = EXTRACTION_PRICE - nft.rarity * 1000;
  if (priceInBct < 0) priceInBct = 0;

  let mangoes = 2600 - 5 * nft.rarity * 100;
  if (mangoes < 0) mangoes = 0;

  return {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [mangoes, 100], // always costs 1 Ape Tool too
    resourceIndexes: [8, 9], // Power Mangoes, Ape Tools
  };
}

export function getDeificationPrices(nft, scale) {
  let priceInBct =
    nft.type_ <= 8 ? 20000 + 10000 * (nft.type_ - 1) : nft.type_ === 9 ? 150000 : 200000;

  priceInBct = BigNumber(priceInBct).times(1e18).times(scale).div(50);
  const dnas = [nft.type_ <= 5 ? 2500 : nft.type_ <= 8 ? 5000 : 25000];
  const dnaIndex = [nft.type_ <= 5 ? nft.type_ + 13 : nft.type_ + 8]; // DNA of the type
  const dreamResources = [nft.type_ <= 5 ? 5000 : nft.type_ <= 8 ? 10000 : 25000];
  const dreamResourceIndex = [nft.type_ <= 5 ? nft.type_ + 20 : nft.type_ + 15]; // Advanced Loot of the type

  return {
    bctPrice: priceInBct,
    resourceCents: [100, dnas, dreamResources], // ape tools, dna, dream res
    resourceIndexes: [9, dnaIndex, dreamResourceIndex],
  };
}

export function getMintPrices(type, boxCount, scale) {
  let bctPrice, resourceCents, resourceIndexes;

  if (type <= 2) {
    resourceIndexes = [type + 4, type + 11]; // Advanced Loot of that type, Box of that type
  } else {
    resourceIndexes = [type + 4, type + 16]; // Advanced Loot of that type, Box of that type
  }

  if (boxCount === 1) {
    bctPrice = 0;
    resourceCents = [0, 100];
  } else if (boxCount === 3) {
    bctPrice = MINT_PRICE * type * 1;
    resourceCents = [MINT_PRICE * 1, 300];
  } else if (boxCount === 9) {
    // First, 9 commons become 3 rares
    bctPrice = MINT_PRICE * type * 1 * 3;
    resourceCents = [MINT_PRICE * 3, 900];

    // Then, 3 rares become 1 epic
    bctPrice += MINT_PRICE * type * 2;
    resourceCents[0] += MINT_PRICE * 2;
  } else if (boxCount === 27) {
    // First, 27 commons become 9 rares
    bctPrice = MINT_PRICE * type * 1 * 9;
    resourceCents = [MINT_PRICE * 9, 2700];

    // Then, 9 rares become 3 epics
    bctPrice += MINT_PRICE * type * 2 * 3;
    resourceCents[0] += MINT_PRICE * 6;

    // Then, 3 epics become 1 legendary
    bctPrice += MINT_PRICE * type * 3;
    resourceCents[0] += MINT_PRICE * 3;
  } else if (boxCount === 81) {
    // First, 81 commons become 27 rares
    bctPrice = MINT_PRICE * type * 1 * 27;
    resourceCents = [MINT_PRICE * 27, 8100];

    // Then, 27 rares become 9 epics
    bctPrice += MINT_PRICE * type * 2 * 9;
    resourceCents[0] += MINT_PRICE * 18;

    // Then, 9 epics become 3 legendaries
    bctPrice += MINT_PRICE * type * 3 * 3;
    resourceCents[0] += MINT_PRICE * 9;

    // Then, 3 legendaries become 1 mythic
    bctPrice += MINT_PRICE * type * 4;
    resourceCents[0] += MINT_PRICE * 4;
  }

  return {
    bctPrice: BigNumber(bctPrice).times(1e18).times(scale).div(100).div(2),
    resourceCents,
    resourceIndexes,
  };
}

export function getLegendaryCollectionPrices(type_, scale) {
  const priceInBct = 2 ** (type_ - 1) * 10000;
  const priceInDna = type_ <= 2 ? 5000 : 12000; // cost in DNA

  const result = {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [priceInDna],
    resourceIndexes: [type_ + 13], // DNA of that type
  };

  if (type_ > 2) {
    result.resourceCents.push(2000);
    result.resourceIndexes.push(type_ + 20); // 23 for cow, 24 for elephant
  }

  return result;
}

export function getMythicCollectionPrices(type_, scale) {
  const priceInBct = 2 ** type_ * 20000;
  const priceInDna = type_ <= 2 ? 15000 : 36000;

  const result = {
    bctPrice: BigNumber(priceInBct).times(1e18).times(scale).div(100).div(2),
    resourceCents: [priceInDna, 4000],
    resourceIndexes: [type_ + 13, type_ + 20], // DNA of that type + 21 for mouse, 22 for cat, 23 for cow, 24 for elephant
  };

  return result;
}

export function getCollectionPrices(type_, rarity, scale) {
  if (rarity === 5) {
    return getMythicCollectionPrices(type_, scale);
  } else {
    return getLegendaryCollectionPrices(type_, scale);
  }
}

const typeToTrait = {
  6: 44, // All Dragons are born Greedy
  7: 49, // All Griffins are born Fancy
  8: 5, // All Satyrs are born Magical
  9: 19, // All Dinosaurs are born Imposing
  10: 31, // All Cyborgs are born Clever
  11: 51, // All Hydras are born Pyromaniac
  12: 0, // All Chimeras are born Pyromaniac + Runesmith + Healer
  13: 53, // All Ents are born Healers
  14: 52, // All Kaijus are born Runesmiths
  15: 54, // All Cacodemons are born Sacavenger
};
const typeToBaseFarm = {
  6: BigNumber(100000).times(1e9).toString(),
  7: BigNumber(125000).times(1e9).toString(),
  8: BigNumber(150000).times(1e9).toString(),
  9: BigNumber(175000).times(1e9).toString(),
  10: BigNumber(500000).times(1e9).toString(),
  11: BigNumber(600000).times(1e9).toString(),
  12: BigNumber(700000).times(1e9).toString(),
  13: BigNumber(800000).times(1e9).toString(),
  14: BigNumber(900000).times(1e9).toString(),
  15: BigNumber(1000000).times(1e9).toString(),
};
export function getDeifiedNftPropsFrom(nft) {
  const newType = nft.type_ <= 10 ? nft.type_ + 5 : 0;
  const newTrait = typeToTrait[newType];
  const newBaseFarm = typeToBaseFarm[newType];

  const overwriteProps = {
    agility: 0,
    baseFarm: newBaseFarm,
    collection: nft.collection,
    evos: 0,
    farmPerBlock: newBaseFarm,
    imageUrl: "",
    name: "",
    nuts: 0,
    rank: 0,
    rarity: 5,
    skills: [1, 1, 0, 0, 0, 0],
    traits: [newTrait, newTrait, newTrait],
    type_: newType,
    species: newType,
  };

  if (newType === 12) {
    overwriteProps.traits = [51, 52, 53];
  }

  return overwriteProps;
}
