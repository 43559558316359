import styled from "styled-components";

export const TraitImage = styled.img`
  width: ${(props) => (props.scale ? `${23 * props.scale}px` : `23px`)};
  height: ${(props) => (props.scale ? `${23 * props.scale}px` : `23px`)};
  position: relative;
  left: ${(props) => (props.scale ? `${-5 * props.scale}px` : `-5px`)};
  top: ${(props) => (props.scale ? `${2 * props.scale}px` : `2px`)};
  margin-right: -8px;
`;
