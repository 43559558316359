import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";

import { useWeb3Context } from "../../contexts/Web3Context";

import {
  ButtonContainer,
  BuyButton,
  BuyWithBusdButton,
  BuyWithKoziButton,
  PurchaseCostContainer,
} from "./PurchaseButtons";

import ResourceContainerModal from "../resource-container-modal";
import { indexToBackground } from "../../utils/constants";
import { bonusToBackground } from "../../utils/constants";

import { hasEnoughBct, hasEnoughBusd, hasEnoughResources } from "../../utils/purchase";

import { ModalOverlay, ModalContent, Title, Description } from "./styles";

const PurchaseModal = ({
  title,
  description,
  costInResources,
  onPurchaseWithResources,
  onPurchaseWithKozi,
  player,
  isOpen,
  onClose,
}) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_CEIL });
  const { isReady, bctToBusd, stableToTargetKozi } = useWeb3Context();

  const showModal = isOpen;

  const [busdPrice, setBusdPrice] = useState(BigNumber(0));

  useEffect(() => {
    if (!isReady) return;
    if (!costInResources) return;
    if (!player) return;

    const getBusdPrice = async () => {
      const price = await bctToBusd(costInResources.bctPrice);
      setBusdPrice(BigNumber(price).div(1e18).toFixed(2));
    };

    getBusdPrice();
  }, [costInResources, player, onPurchaseWithKozi]);

  const handleCloseModal = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Stop the event from propagating up to ModalOverlay
  };

  const handleBuyWithResources = () => {
    onPurchaseWithResources();
  };

  const handleBuyWithKozi = async () => {
    const _stablePrice = await bctToBusd(costInResources.bctPrice);
    onPurchaseWithKozi(_stablePrice);
  };

  if (!costInResources) return null;

  if (isNaN(costInResources.bctPrice) || costInResources.bctPrice < 0) {
    // send player to the home page
    window.location.href = "/";
  }

  return (
    <>
      {showModal && (
        <ModalOverlay onClick={handleCloseModal}>
          <ModalContent onClick={handleModalContentClick}>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <ButtonContainer>
              <BuyButton
                onClick={handleBuyWithResources}
                disabled={
                  !hasEnoughBct(player, costInResources.bctPrice) ||
                  !hasEnoughResources(
                    player,
                    costInResources.resourceCents,
                    costInResources.resourceIndexes
                  )
                }
              >
                Pay with resources
                <PurchaseCostContainer>
                  <ResourceContainerModal
                    resource={98}
                    quantity={costInResources.bctPrice.div(1e18).toNumber()}
                    background={bonusToBackground["eBCT"]}
                    hasEnough={hasEnoughBct(player, costInResources.bctPrice)}
                  />
                  {costInResources.resourceIndexes.map((resourceIndex, mapIndex) => (
                    <ResourceContainerModal
                      key={`resource-${resourceIndex}`}
                      resource={resourceIndex}
                      quantity={parseFloat(costInResources.resourceCents) / 100}
                      background={indexToBackground[resourceIndex]}
                      hasEnough={hasEnoughResources(
                        player,
                        [costInResources.resourceCents[mapIndex]],
                        [costInResources.resourceIndexes[mapIndex]]
                      )}
                    />
                  ))}
                </PurchaseCostContainer>
              </BuyButton>

              <BuyWithKoziButton
                disabled={
                  busdPrice === 0 ||
                  busdPrice === "0.00" ||
                  !player.hasEnoughKozi(stableToTargetKozi(BigNumber(busdPrice).times(1e18)))
                }
                onClick={handleBuyWithKozi}
                hasEnough={player.hasEnoughKozi(
                  stableToTargetKozi(BigNumber(busdPrice).times(1e18))
                )}
              >
                <h1>
                  Pay with <span style={{ color: "yellow" }}>KOZI</span>
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#ddc",
                    textDecoration: "line-through",
                    marginBottom: "4px",
                  }}
                >
                  {"- "}
                  {busdPrice || 0} USDC{" -"}
                </p>
                <p>
                  {busdPrice === "0.00" ||
                  busdPrice === "0.01" ||
                  busdPrice === NaN ||
                  busdPrice === "NaN"
                    ? "—"
                    : stableToTargetKozi(BigNumber(busdPrice).times(1e18)).toFixed(
                        4,
                        BigNumber.ROUND_CEIL
                      ) + " 🧿"}
                </p>
              </BuyWithKoziButton>
            </ButtonContainer>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default PurchaseModal;
