import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 20px;
`;

export const Title = styled.h1`
  font-size: 16px;
  padding: 10px;
  text-align: center;
`;
