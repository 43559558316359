import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const SpreadTable = styled.img`
  cursor: pointer; // Encourage users to click to close
  width: 100%;
  max-width: 1024px;
`;

const SpreadModal = ({ isOpen, closeModal, tableSrc }) => {
  if (!isOpen) return null;

  return (
    <Overlay onClick={closeModal}>
      <SpreadTable src={tableSrc} onClick={closeModal} />
    </Overlay>
  );
};

export default SpreadModal;
