import { useWeb3Context } from "../../contexts/Web3Context";
import { Button } from "./styles";

import { shortAccount } from "../../utils/account";

export const ConnectWalletButton = () => {
  const { signer, connectWallet } = useWeb3Context();

  const defaultOnClick = async () => {
    if (!signer) {
      connectWallet();
    }
  };

  return (
    <Button onClick={defaultOnClick} isConnected={signer}>
      <span> {shortAccount(signer?.address) || "Connect Wallet"}</span>
    </Button>
  );
};
