import styled from "styled-components";
import RankNumber from "./RankNumber";

import BagCommon from "../../assets/images/squads-card/cradle-agility-rarity-1.png";
import BagRare from "../../assets/images/squads-card/cradle-agility-rarity-2.png";
import BagEpic from "../../assets/images/squads-card/cradle-agility-rarity-3.png";
import BagLegendary from "../../assets/images/squads-card/cradle-agility-rarity-4.png";
import BagMythic from "../../assets/images/squads-card/cradle-agility-rarity-5.png";

const Container = styled.div`
  position: relative;
  left: ${(props) => (props.scale ? `${-4 * props.scale}px` : `-4px`)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.scale ? `${30 * props.scale}px` : `30px`)};

  .bag {
    position: absolute;
    width: ${(props) => (props.scale ? `${30 * props.scale}px` : `30px`)};
  }

  p {
    line-height: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  }
`;

export const getBag = (rarity) => {
  switch (rarity) {
    case 1:
      return BagCommon;
    case 2:
      return BagRare;
    case 3:
      return BagEpic;
    case 4:
      return BagLegendary;
    case 5:
      return BagMythic;
    default:
      return "Unknown";
  }
};

const CardShield = ({ rarity, agility, scale }) => (
  <Container scale={scale}>
    <img className="bag" src={getBag(rarity)} />
    <RankNumber scale={scale}>{agility}</RankNumber>
  </Container>
);

export default CardShield;
