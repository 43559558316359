/**
 * This is the model for the PlayerBonus object.
 * To get the correct data, you need to call the PlayerBonus contract and get the data from there.
 * The function is playerBonus.detailedBonusOf(address) and it returns a BonusData struct, that you pass to the constructor of this class.
 */
import BigNumber from 'bignumber.js';

class PlayerBonusModel {
  constructor(data) {
    this.setData(data);
  }

  setData(data) {
    this.stash = new BigNumber(data.stash).toNumber(); // Stasher Bonus, from 0 to 10
    this.mythicMints = new BigNumber(data.mythicMints).toNumber(); // Bonus from 1 to 200 for having minted Mythics
    this.item = new BigNumber(data.item).toNumber(); // bonus from 0 to infinity. TBD.
    this.special = new BigNumber(data.special).toNumber(); // bonus from 0 to infinity. TBD.
    this.fortuneLevel = new BigNumber(data.fortuneLevel).toNumber(); // level from 0 to 5. Multiplies everything farmed by this number.
    this.total = new BigNumber(data.total).toNumber(); // total bonus from 0 to infinity. It's the sum of all other bonuses, excluding the GorgonLevel.
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }
}

export default PlayerBonusModel;
