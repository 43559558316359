/**
 * This is the model for a Player.
 * To get the correct data, you need to call the FARM contract and get the data from there.
 * The function is farm.getPlayer(id) and it returns a PlayerData struct, that you pass to the constructor of this class.
 */
import BigNumber from "bignumber.js";
class PlayerInfoModel {
  constructor(playerData) {
    try {
      BigNumber.config({ EXPONENTIAL_AT: 60 });
      this.setData(playerData);
    } catch (e) {
      console.error("Error in PlayerInfoModel constructor", e);
    }
  }

  setData(playerData) {
    this.bctToClaim = new BigNumber(playerData.bctToClaim).toString(); // This is ingame BCT
    this.etherealBct = new BigNumber(playerData.etherealBct).toString(); // this is eBCT: cannot be withdrawn, nor transferred, only spent within the game
    this.lastWithdrawalBlock = new BigNumber(playerData.lastWithdrawalBlock).toNumber(); // when did the player last withdraw BCT
    this.registeredAt = new BigNumber(playerData.registeredAt).toNumber(); // when did the player register
    //this.mentor = playerData.mentor; // who is the player's Mentor. This is any valid blockcahin address. If the address is 0x0, the player has no Mentor.
    //this.mentorLevel = new BigNumber(playerData.mentorLevel).toNumber(); // the Mentor level of this player. This is an integer from 0 to 10. 0 means the player is not even registered, as the default here is 1.
    //this.merchantLevel = new BigNumber(playerData.merchantLevel).toNumber(); // the Merchant level of this player. This is an integer from 0 to 10. 0 means the player is not a merchant.
    this.squads =
      playerData.squads.length > 0
        ? playerData.squads.map((id) => new BigNumber(id).toNumber())
        : []; // a list of integers that are the squadIds of each squad this player has.
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }
}

export default PlayerInfoModel;
