import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1));

  animation: ${fadeIn} 1s ease-in-out;
`;

export const QuestNameAndSynergyContainer = styled.div`
  display: flex;
  justify-content: space-center;
  align-items: center;
  width: 240px;
  height: 100%;
  margin-left: 3px;
  cursor: default;

  @media (max-width: 685px) {
    height: 28px;
    width: 100%;
  }

  @media (max-width: 450px) {
    width: 260px;
    margin: 0;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }

  animation: ${fadeIn} 1s ease-in-out;
`;

export const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 660px;
  height: 60px;
  overflow: hidden;
  flex-grow: 1;

  @media (max-width: 685px) {
    width: 440px;
    height: 60px; /* depending on the number of resources */
  }

  @media (max-width: 450px) {
    width: 320px;
    height: 60px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  overflow: hidden;

  @media (max-width: 685px) {
    flex-direction: column;
    height: auto;
  }
`;

export const QuestActionContainer = styled.div`
  height: 100%;
  width: 70px;
  padding: 1px;
  overflow: hidden;
  h2,
  h6 {
    color: rgba(0, 255, 247, 0.8);
  }

  @media (max-width: 450px) {
    height: auto;
  }
`;

export const QuestDetailsContainer = styled.div`
  width: 380px;
  height: 100%;
  margin: 0 3px;
  overflow: hidden;
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  justify-items: center;

  @media (max-width: 685px) {
    height: 28px;
    width: 100%;
    margin: 0;
  }

  @media (max-width: 450px) {
    width: 262px;
    margin: 0;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
`;

export const QuestDetailWrapper = styled.div`
  padding: 1px;
  width: 100%;
  height: 100%;
`;

export const QuestBonusWrapper = styled.div`
  padding: 1px;
  width: 100%;
  height: 100%;

  @media (max-width: 450px) {
    height: 24px;
  }
`;

export const StartButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 70%;
    height: 70%;
    fill: rgba(0, 255, 71, 0.8);
  }

  @media (max-width: 450px) {
    height: 49px;
  }
`;

export const QuestRewardsContainer = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;

  @media (max-width: 685px) {
    width: 100%;
    height: 30px;
  }

  @media (max-width: 450px) {
    width: 100%;
    height: 22px;
  }
`;

export const ResourcesRewardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 2fr));
  justify-items: center;

  @media (max-width: 685px) {
    grid-template-columns: repeat(auto-fill, minmax(16%, 3fr));
    flex-grow: 1;
    flex-direction: column;
  }
`;

export const OnQuestContainer = styled.div`
  padding: 5px;
  border-radius: 6px;
  img {
    width: 70%;
  }
`;

export const CollectContainer = styled.div`
  padding: 5px;
  background: linear-gradient(180deg, rgb(0 120 20), rgb(11 79 6));
  border-radius: 6px;
  img {
    width: 76%;
  }

  @media (max-width: 685px) {
    height: 56px;
  }

  @media (max-width: 450px) {
    height: 49px;
  }
`;

export const QuestDetailLeft = styled.div`
  display: flex;
  position: relative;
  justify-content: center;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (max-width: 450px) {
    h4 {
      position: relative;
      ${(props) =>
        props.numberOfTraits >= 3 &&
        `
        right: 19px;
      `}
    }
  }
`;

export const QuestDetailRight = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 450px) {
    justify-content: left;
    max-width: 20px;
    position: relative;
    right: 20px;
  }
`;

export const Image = styled.img`
  width: 25px;
  height: 25px;
  margin-top: 3px;
`;

export const GradientContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: linear-gradient(0deg, rgba(15, 52, 72, 1), rgba(15, 32, 62, 1));

  @media (max-width: 450px) {
    h4 {
      font-size: 14px;
    }

    h6 {
      font-size: 11px;
    }
  }
`;

export const CostTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1));
  border-radius: 0px 0px 4px 4px;
  position:relative;
  top: -10px;
`;

export const CostText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  color: yellow;
`;
