import React from "react";
import { Container, Input } from "./styles";

const ItemInput = ({ placeholder = "", type_ = "String", onChange = null }) => {
  const [value, setValue] = React.useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <Container>
      <Input placeholder={placeholder} type={type_} value={value} onChange={handleChange} />
    </Container>
  );
};

export default ItemInput;
