import {
  Container,
  TopContainer,
  BottomContainer,
  TitleContainer,
  DescriptionContainer,
  ImageContainer,
  NameContainer,
  SellerContainer,
  MoreLikeThisContainer,
  MoreLikeThisButton,
  Seller,
  SellerPositioner,
  CancelContainer,
  CancelButton,
} from "./styles";
import { resourceToImagePath } from "../../../config/resources/09-resources-images";
import CategoryIcon from "../../../assets/images/icon/Category.png";
import BinIcon from "../../../assets/images/icon/trash-bin.png";

import CheeseImage from "../../../assets/cheese.png";

import { shortAccount } from "../../../utils/account";

const ResourceNft = ({
  resource,
  quantity,
  background,
  name,
  description,
  seller,
  userBalance,
  isPreview,
  disable = false,
  cancelOrderCallback = null,
  moreFromThisSellerCallback = null,
  moreLikeThisCallback = null,
}) => {
  const resourcePath = `/${resourceToImagePath(Number(resource))}`;

  //if the url path includes "/my-orders", then enable the "Cancel" button and disable the "More Like This" button
  const isMyOrders = window.location.pathname.includes("/my-orders");
  const showMoreLikeThisButton =
    !isMyOrders &&
    !isPreview &&
    !window.location.pathname.includes("/more-like-this") &&
    !window.location.pathname.includes("/orders-of/");

  const handleCancelOrder = () => {
    if (cancelOrderCallback) cancelOrderCallback();
  };

  const handleMoreLikeThis = () => {
    if (moreLikeThisCallback) moreLikeThisCallback();
  };

  const handleMoreFromThisSeller = () => {
    if (moreFromThisSellerCallback) moreFromThisSellerCallback();
  };

  return (
    <Container background={background} bgImg={CheeseImage}>
      <TitleContainer>
        <TopContainer>
          {isMyOrders && (
            <CancelContainer>
              <CancelButton background={background} disable={false} onClick={handleCancelOrder}>
                <img src={BinIcon} alt="cancel" />
              </CancelButton>
            </CancelContainer>
          )}
          {showMoreLikeThisButton && (
            <MoreLikeThisContainer>
              <MoreLikeThisButton
                background={background}
                disable={disable}
                onClick={handleMoreLikeThis}
              >
                <img src={CategoryIcon} alt="category" />
              </MoreLikeThisButton>
            </MoreLikeThisContainer>
          )}
          <ImageContainer background={background}>
            <img src={resourcePath} alt="resource" />
          </ImageContainer>
        </TopContainer>
        <BottomContainer>
          <NameContainer>{name}</NameContainer>
          <DescriptionContainer>{description}</DescriptionContainer>
        </BottomContainer>
        <SellerPositioner>
          <SellerContainer
            background={background}
            disable={disable}
            onClick={!isPreview ? handleMoreFromThisSeller : null}
          >
            <Seller background={background} disable={disable}>
              {isPreview
                ? `You have ${userBalance}. Set how many to sell:`
                : `Seller: ${shortAccount(seller)}`}
            </Seller>
          </SellerContainer>
        </SellerPositioner>
      </TitleContainer>
    </Container>
  );
};

export default ResourceNft;
