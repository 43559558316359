import React, { useEffect } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import Card from "../card/Card";

const subtitle = "BEAST KINGDOM - The Community Game";
const title1 = "Collect, Create,";
const title2 = "Enhance and Sell";
const title3 = "In Beast Kingdom";
const description = "Farm, craft, and trade your way to the top of the food chain";

const LeftTitleContainer = styled.div`
  position: absolute;
  top: ${(props) => props.windowHeight / 2 - 200}px;
  left: 100px;
  z-index: 1;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const SliderStyle2 = ({ beasts }) => {
  // function to get the window height
  const getHeight = () => {
    const height = window.innerHeight;
    return height;
  };

  const getWidth = () => {
    const width = window.innerWidth;
    return width;
  };

  return (
    <section className="flat-title-page home5" style={{ height: getHeight() }}>
      <div className="overlay"></div>
      {beasts && (
        <div className="themesflat-container">
          <div className="wrap-heading flat-slider d-flex align-items-center">
            <LeftTitleContainer windowHeight={getHeight()}>
              <div className="content">
                <h2 className="heading">{title1}</h2>
                <h1 className="heading mb-style">
                  <span className="tf-text s1">{title2}</span>
                </h1>
                <h1 className="heading">{title3}</h1>
                <p className="sub-heading">{description}</p>
              </div>
            </LeftTitleContainer>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={8}
              loop
              autoplay={{
                delay: 3,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={6000}
              style={{ margin: 12, padding: 12, position: "relative", left: "870px" }}
            >
              {beasts &&
                beasts.length > 0 &&
                beasts.slice(0, 8).map((beast, index) => {
                  return (
                    <SwiperSlide key={`${index}.${beast.id}`}>
                      <Card
                        nft={beast}
                        squadType={6}
                        reasonToSelect="none"
                        suppressTooltips={true}
                        supressInteraction={true}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={8}
              loop
              autoplay={{
                delay: 3,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={7000}
              style={{ margin: 12, padding: 12, position: "relative", left: "870px" }}
            >
              {beasts &&
                beasts.length > 0 &&
                beasts.slice(8, 16).map((beast, index) => {
                  return (
                    <SwiperSlide key={`${index}.${beast.id}`}>
                      <Card
                        nft={beast}
                        squadType={6}
                        reasonToSelect="none"
                        suppressTooltips={true}
                        supressInteraction={true}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={8}
              loop
              autoplay={{
                delay: 3,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={6500}
              style={{ margin: 12, padding: 12, position: "relative", left: "870px" }}
            >
              {beasts &&
                beasts.length > 0 &&
                beasts.slice(16, 24).map((beast, index) => {
                  return (
                    <SwiperSlide key={`${index}.${beast.id}`}>
                      <Card
                        nft={beast}
                        squadType={6}
                        reasonToSelect="none"
                        suppressTooltips={true}
                        supressInteraction={true}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={8}
              loop
              autoplay={{
                delay: 3,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={8000}
              className="end"
              style={{ margin: 12, padding: 12, position: "relative", left: "870px" }}
            >
              {beasts &&
                beasts.length > 0 &&
                beasts.slice(24, 32).map((beast, index) => {
                  return (
                    <SwiperSlide key={`${index}.${beast.id}`}>
                      <Card
                        nft={beast}
                        squadType={6}
                        reasonToSelect="none"
                        suppressTooltips={true}
                        supressInteraction={true}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={8}
              loop
              autoplay={{
                delay: 3,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={10000}
              className="end"
              style={{ margin: 12, padding: 12, position: "relative", left: "870px" }}
            >
              {beasts &&
                beasts.length > 0 &&
                beasts.slice(32, 40).map((beast, index) => {
                  return (
                    <SwiperSlide key={`${index}.${beast.id}`}>
                      <Card
                        nft={beast}
                        squadType={6}
                        reasonToSelect="none"
                        suppressTooltips={true}
                        supressInteraction={true}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      )}
    </section>
  );
};

export default SliderStyle2;
