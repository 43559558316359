import Contracts from "./Contracts";

export const isBlock = (input) => {
  // if the input contains only numbers from 0 to 9, it's a block number
  // else, it's a transaction hash
  return /^\d+$/.test(input);
};

export const isAddress = (input) => {
  return input.length === 42 && input.startsWith("0x");
};

export const isToken = (input) => {
  // a token must be an address, and must pass the Contracts.isKnownToken function
  return isAddress(input) && Contracts.isKnownToken(input);
};

export const travelSongTypeOf = (input) => {
  if (isToken(input)) {
    return "token";
  } else if (isAddress(input)) {
    return "account";
  } else if (isBlock(input)) {
    return "block";
  } else {
    return "transaction";
  }
};
