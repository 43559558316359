import { ethers } from "ethers";

export const shaMessage = (message) => {
  return ethers.keccak256(ethers.toUtf8Bytes(message));
};

export const getSignerAddress = (messageHash, signature) => {
  const recoveredAddress = ethers.verifyMessage(messageHash, signature);
  return recoveredAddress;
};

export const isValidSignature = (claimedAddress, claimedDiscordId, signature) => {
  const messageHash = shaMessage(`${claimedAddress}${claimedDiscordId}`);
  const signer = getSignerAddress(messageHash, signature);
  return signer.toLowerCase() === claimedAddress.toLowerCase();
};
