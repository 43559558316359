import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import ReactFlipCard from "reactjs-flip-card";
import { resourceToImagePath } from "../../../config/resources/09-resources-images";
import { format } from "../../../utils/math";
import IntervalManager from "../../../utils/IntervalManager";
import Debouncer from "../../../utils/Debouncer";

import {
  Container,
  Name,
  Image,
  Line2,
  MiddleLine,
  CentralLine,
  CentralLineConfiguring,
  ResultLine,
  DestroyButton,
  FloatingBadge,
  ButtonsContainer,
  EbctContainer,
} from "./styles";
import BigNumber from "bignumber.js";

const Item = ({
  config,
  forceValue = null,
  xPos = undefined,
  yPos = undefined,
  isDoubling = false,
  isDisabled = false,
  isDead = false,
  isOptimized = false,
  onAddLuck = null,
  onDisableCategory = null,
  spinStep = 0, // 1 is the first call to metakask, 2 is the spin, 3 is the category selection, 4 is the result
  selected = true,
  selectedCategories = [],
}) => {
  const [forceFlip, setForceFlip] = useState(undefined);
  const [lastRandomHighPrize, setLastRandomHighPrize] = useState("?");
  const [lastRandomLowPrize, setLastRandomLowPrize] = useState("?");
  const [nextDirection, setNextDirection] = useState(null);
  const [isFinallyDead, setIsFinallyDead] = useState(false);
  const [isDying, setIsDying] = useState(null);
  const [dieTime, setDieTime] = useState(10000);
  const [isWinning, setIsWinning] = useState(false);
  const [isFinallyVictor, setIsFinallyVictor] = useState(false);
  const [winningValue, setWinningValue] = useState(null);

  let rewards = [
    config.lowItemValue,
    config.lowItemValue,
    config.lowItemValue,
    config.lowItemValue,
    config.lowItemValue,
    config.lowItemValue,
    config.lowItemValue,
    config.lowItemValue,
    config.lowItemValue,
    config.lowItemValue,
    ...config.highItems,
  ];
  if (isDoubling) {
    rewards = rewards.map((item) => BigNumber(item).times(2).toString());
  }

  let flipTrigger = spinStep <= 0 ? "onHover" : "disabled";
  const prefix = config.prefix ? config.prefix : "";

  let lowestValue = isDoubling
    ? BigNumber(config.lowItemValue).times(2).toString()
    : config.lowItemValue;

  lowestValue = config?.formatWithKs ? format(lowestValue, 1) : lowestValue;

  let highestValue = isDoubling
    ? BigNumber(config.highItems[config.highItems.length - 1])
        .times(2)
        .toString()
    : config.highItems[config.highItems.length - 1];

  highestValue = config?.formatWithKs ? format(highestValue, 1) : highestValue;

  let secondLowestValue = isDoubling
    ? BigNumber(config.highItems[0]).times(2).toString()
    : config.highItems[0];

  secondLowestValue = config?.formatWithKs ? format(secondLowestValue, 1) : secondLowestValue;

  if (spinStep >= 2 && spinStep <= 7 && !isDisabled) {
    IntervalManager.stop("flip-item-" + config.resource);
    IntervalManager.start(
      "flip-item-" + config.resource,
      toggleFlip,
      Math.round(Math.random() * 2000 + 500)
    );
  }

  function win() {
    if (isWinning || isFinallyVictor) return;

    setIsWinning(true);
    IntervalManager.stop("flip-item-" + config.resource);

    if (forceValue && forceValue >= 11) {
      setForceFlip(true);
    } else {
      setForceFlip(false);
    }

    Debouncer.debounce(`win-${config.resource}`, finallyWin, 1000);
  }

  function finallyWin() {
    console.warn(
      `WIN! ${config.name}: ${rewards[forceValue - 1]} | Positional Result: ${forceValue}/15`
    );
    // now is the time to set the value visually??
    if (forceValue && forceValue >= 11) {
      setForceFlip(false);
    } else {
      setForceFlip(true);
    }
    setIsFinallyVictor(true);

    const _winningValue = rewards[forceValue - 1];

    setTimeout(setWinningValue, 200, _winningValue);
  }

  function die() {
    if (isDying || isFinallyDead) return;
    setIsDying(true);

    IntervalManager.stop("flip-item-" + config.resource);
    setForceFlip(true);
    setTimeout(finallyDie, 1000);
  }

  function finallyDie() {
    setForceFlip(false);
    setIsFinallyDead(true);
  }

  function toggleFlip() {
    if (isFinallyDead || isDying || isDisabled || isWinning || isFinallyVictor) return;
    if (isDead) {
      Debouncer.debounce(`die-${config.resource}`, die, Math.round(Math.random() * dieTime + 500));
      setDieTime(dieTime / 2);
    }
    if (spinStep >= 6) {
      Debouncer.debounce(`win-${config.resource}`, win, Math.round(Math.random() * 500 + 500));
    }

    if (!nextDirection) {
      setNextDirection(getRandomDirection());
    }

    setForceFlip(!forceFlip);

    if (!forceFlip) {
      setTimeout(randomHighPrize, 500);
    } else {
      setTimeout(randomLowPrize, 500);
    }
  }

  function randomHighPrize() {
    let result = config.highItems[Math.floor(Math.random() * config.highItems.length)];
    if (isDoubling) {
      result = BigNumber(result).times(2).toString();
    }
    setLastRandomHighPrize(result);
  }
  function randomLowPrize() {
    let result = config.highItems[Math.floor(Math.random() * config.highItems.length)];
    if (isDoubling) {
      result = BigNumber(result).times(2).toString();
    }
    setLastRandomLowPrize(result);
  }

  function getRandomDirection() {
    const rand = Math.floor(Math.random() * 100);
    if (rand % 2 === 0) {
      return "horizontal";
    } else {
      return "vertical";
    }
  }

  return (
    <>
      <Container xPos={xPos} yPos={yPos} selected={selected}>
        <ReactFlipCard
          flipTrigger={flipTrigger}
          flipByProp={spinStep > 1 ? forceFlip : undefined}
          direction={nextDirection || "horizontal"}
          frontStyle={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100px",
            height: "100px",
            background: isDisabled || isFinallyDead ? "#999" : `${config.background}`,
            borderRadius: "4px",
            color: isDisabled || isFinallyDead ? "#999" : "#fff",
            fontSize: "24px",
            opacity: isFinallyDead || (isDisabled && spinStep >= 2) ? "0.05" : "1",
            outline: winningValue ? "5px solid #5f2" : "none",
            filter:
              isDisabled || isFinallyDead ? "none" : "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5))",
            transition: "all 0.5s ease",
          }}
          frontComponent={
            <>
              {isOptimized && <FloatingBadge>🍀</FloatingBadge>}
              {config.resource !== "🎫" && config.resource !== 98 && config.resource !== "eBct" ? (
                <Image>
                  <img src={resourceToImagePath(config.resource)} />{" "}
                </Image>
              ) : config.resource === 98 || config.resource === "eBct" ? (
                <EbctContainer background={null}>⚡</EbctContainer>
              ) : (
                <Name>{config.resource}</Name>
              )}
              {winningValue ? (
                <>
                  <ResultLine isDisabled={isDisabled}>
                    {prefix}
                    {winningValue}
                  </ResultLine>
                </>
              ) : spinStep <= 1 ? (
                <>
                  <Line2>
                    <span style={{ fontSize: "10px", color: "#eee" }}>From: </span> {prefix}
                    {isOptimized ? secondLowestValue : lowestValue}
                  </Line2>
                  <Line2>
                    {" "}
                    <span style={{ fontSize: "10px", color: "#eee" }}>Up to: </span> {prefix}
                    {highestValue}
                  </Line2>
                </>
              ) : (
                <>
                  <ResultLine>
                    {prefix}
                    {lastRandomHighPrize}
                  </ResultLine>
                </>
              )}
            </>
          }
          backStyle={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100px",
            height: "100px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "24px",
            background: isOptimized
              ? config.background
              : spinStep > 1
              ? "linear-gradient(0deg, #000, #333)"
              : "linear-gradient(0deg, #000, #111)",
            outline: winningValue ? "2px solid #5f2" : spinStep > 1 ? "none" : "2px solid white",
            filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5))",
          }}
          backComponent={
            <>
              {spinStep === 0 ? (
                <ButtonsContainer>
                  <DestroyButton onClick={isDisabled ? null : onAddLuck} disabled={isDisabled}>
                    {isOptimized ? `Reset` : `Add Luck 🍀`}
                  </DestroyButton>
                  <DestroyButton
                    onClick={isOptimized ? null : onDisableCategory}
                    disabled={isOptimized}
                  >
                    {isDisabled ? `Enable` : `Disable 🍀🍀`}
                  </DestroyButton>
                </ButtonsContainer>
              ) : (
                <>
                  {isOptimized && <FloatingBadge>🍀</FloatingBadge>}
                  {config.resource !== "🎫" &&
                  config.resource !== 98 &&
                  config.resource !== "eBct" ? (
                    <Image>
                      <img src={resourceToImagePath(config.resource)} />{" "}
                    </Image>
                  ) : config.resource === 98 || config.resource === "eBct" ? (
                    <EbctContainer background={null}>⚡</EbctContainer>
                  ) : (
                    <Name>{config.resource}</Name>
                  )}
                  {isOptimized ? (
                    <>
                      <ResultLine>
                        {prefix}
                        {lastRandomLowPrize}
                      </ResultLine>
                    </>
                  ) : (
                    <CentralLine>
                      {prefix}
                      {lowestValue}
                    </CentralLine>
                  )}
                </>
              )}
            </>
          }
        />
      </Container>
    </>
  );
};

export default Item;
