import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import Header from "../components/header/Header";
import styled, { keyframes } from "styled-components";

import { useWeb3Context } from "../contexts/Web3Context";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  margin: auto;
  gap: 6px;
  font-family: "Metropolis", sans-serif;
  font-weight: 200;
  letter-spacing: -0.03em;
`;

const CountdownContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 308px;
  font-family: "Metropolis";
  font-weight: 600;
  font-size: 60px;
  position: relative;
`;

const Halvings = () => {
  const { isReady, blockchainState } = useWeb3Context();

  const [halvingPlural, setHalvingPlural] = useState("s");
  const [halvingsLeftPlural, setHalvingsLeftPlural] = useState("s");
  const [currentFarmBalanceFormatted, setCurrentFarmBalanceFormatted] = useState("0");
  const [nextHalvingThresholdFormatted, setNextHalvingThresholdFormatted] = useState("0");
  const [leftToHalveFormatted, setLeftToHalveFormatted] = useState("0");

  const [prevLeftToHalve, setPrevLeftToHalve] = useState(
    blockchainState?.halvingsData?.totalAmountToBurn
  );

  useEffect(() => {
    if (!isReady) return;

    if (!blockchainState?.halvingsData) {
      console.log("!halvingsData");
      return;
    } else {
      setHalvingPlural(blockchainState?.halvingsData.halvingsHad > 1 ? "s" : "");
      setHalvingsLeftPlural(blockchainState?.halvingsData.halvingsLeft > 1 ? "s" : "");
      setCurrentFarmBalanceFormatted(
        parseInt(
          blockchainState?.halvingsData.currentFarmBalance.div(1e18).toFixed(0)
        ).toLocaleString()
      );
      setNextHalvingThresholdFormatted(
        parseInt(
          blockchainState?.halvingsData.nextHalvingThreshold.div(1e18).toFixed(0)
        ).toLocaleString()
      );
      setLeftToHalveFormatted(
        parseInt(blockchainState?.halvingsData.leftToHalve.div(1e18).toFixed(0)).toLocaleString()
      );
    }
  }, [isReady, blockchainState]);

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Halvings</h1>
              </div>
              <div className="mg-bt-12">
                <h5 className="sub-title help-center">
                  The emission of BCT and eBCT follows a schedule of halvings. Whenever the Farm's
                  balance reaches a certain threshold, the farming scale is halved for everyone. All
                  game item prices will be halved along with the emission of BCT/eBCT in the
                  following halvings: 3rd, 5th, 7th and 9th.
                </h5>
              </div>
              <div className="mg-bt-12">
                <h5 className="sub-title help-center">
                  {`All 9 halvings have already happened. There will be no more halvings.`}
                </h5>
              </div>

              <div className="mg-bt-12">
                <h5 className="sub-title help-center">{`The current scale is 0.01.`}</h5>
              </div>

              {blockchainState?.halvingsData && (
                <>
                  <h5 className="sub-title help-center">
                    {`The Farm's balance is ${currentFarmBalanceFormatted} BCT.`}
                  </h5>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Halvings;
