import { ENV } from "../utils/environment";
import config from "../config/config";
import { ethers } from "ethers";

const DATA = config[ENV];
/*
  `DATA` is a json with as long list of names, addresses, and abi arrays. The structrure is:
  Forge: {
    address: "0x67840C5D545eb16108B2e38E4741698be162c72e",
    name: "Forge",
    abi: BkForgeJson.abi,
  },
  HighUpgrades: {
    address: "0xB37CaaA542583C2b0Ab8f171F094c3Ad522d8963",
    name: "High Upgrades",
    abi: BkHighUpgradesJson.abi,
  },
}
*/
class Contracts {
  constructor() {
    // we'll adjust the DATA so that all addresses are in lower case,
    // and make it an array of objects:
    const _contracts = [];
    Object.keys(DATA).forEach((key) => {
      if (DATA[key].address) {
        _contracts.push({
          ...DATA[key],
          address: DATA[key].address.toLowerCase(),
          key,
        });
      }
    });
    this.contracts = _contracts;
  }

  _configOf(address) {
    if (!address) return null;

    const _searchAddress = address?.toLowerCase();
    const conf = this.contracts.find((contract) => contract.address === _searchAddress);

    return conf ? conf : null;
  }

  addressToName(address) {
    const conf = this._configOf(address);
    return conf ? conf.name : null;
  }

  addressToKey(address) {
    const conf = this._configOf(address);
    return conf ? conf.key : null;
  }

  addressToAbi(address) {
    const conf = this._configOf(address);
    return conf ? conf.abi : null;
  }

  addressToConfig(address) {
    if (typeof address !== "string") return null;

    const conf = this._configOf(address);
    return conf ? conf : null;
  }

  addressToEthersContract(address, signerOrProvider) {
    if (!address) return null;
    if (!signerOrProvider) signerOrProvider = ethers.getDefaultProvider();

    const abi = this.addressToAbi(address);
    const contract = new ethers.Contract(address, abi, signerOrProvider);

    return contract;
  }

  _configOfByKey(key) {
    if (!key) return null;

    const conf = this.contracts.find((contract) => contract.key === key);
    return conf ? conf : null;
  }

  keyToName(key) {
    const conf = this._configOfByKey(key);
    return conf ? conf.name : null;
  }

  keyToAbi(key) {
    const conf = this._configOfByKey(key);
    return conf ? conf.abi : null;
  }

  keyToAddress(key) {
    const conf = this._configOfByKey(key);
    return conf ? conf.address : null;
  }

  keyToConfig(key) {
    const conf = this._configOfByKey(key);
    return conf ? conf : null;
  }

  keyToEthersContract(key, signerOrProvider) {
    if (!key) return null;
    if (!signerOrProvider) {
      signerOrProvider = new ethers.BrowserProvider(window.ethereum);
    }

    const abi = this.keyToAbi(key);
    const address = this.keyToAddress(key);

    const contract = new ethers.Contract(address, abi, signerOrProvider);

    return contract;
  }

  isKnownContract(address) {
    const conf = this._configOf(address);
    return conf ? true : false;
  }

  isKnownToken(address) {
    const conf = this._configOf(address);

    if (!conf) return false;
    if (!conf.key) return false;

    return (conf && conf.key === "BCT") || conf.key === "Stabletoken" ? true : false;
  }

  getNativeERC20TokenContract(address, signerOrProvider) {
    if (!signerOrProvider) {
      signerOrProvider = new ethers.BrowserProvider(window.ethereum);
    }

    const abi = this.keyToAbi("BCT");
    const contract = new ethers.Contract(address, abi, signerOrProvider);

    return contract;
  }
}

const contracts = new Contracts();
export default contracts;
