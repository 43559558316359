import React from "react";
import { Container, ImageContainer, TextContainer, EbctContainer } from "./styles";
import { resourceToImagePath } from "../../../config/resources/09-resources-images";
import { Tooltip } from "react-tooltip";

import { indexToTooltip } from "../../../utils/constants";

export const indexToTooltipForSquads = {
  10: "Golden Nuts Farming Rate: affected by the number of Collection NFTs in your squad",
  1118: "Evolution Items Farming Rate: affected by stashing",
  98: "eBCT Farming Rate: affected by the number of NFTs in your squad",
  99: "BCT Farming Rate: affected by the rarity of NFTs in your squad",
  BL: "Basic Loot Farming Rate: affected by the the number of traits in your squad and gets 10% of the Synergy Bonus",
  AL: "Advanced Loot Farming Rate: affected by the the number of traits in your squad and gets 10% of the Synergy Bonus",
};

const ResourceContainer = ({
  resource,
  quantity,
  background,
  alreadyInPct = false,
  useBalancesTooltips = false,
}) => {
  const isTextResource = typeof resource === "string" && (resource === "BL" || resource === "AL");

  const finalIndexToTooltip = useBalancesTooltips ? indexToTooltip : indexToTooltipForSquads;

  let fontSize = "normal";
  if (!alreadyInPct) {
    quantity = Math.round(quantity * 100);

    if (quantity >= 1000) {
      fontSize = "small";
    }
  }

  return (
    <>
      <a
        data-tooltip-id={resource}
        data-tooltip-content={finalIndexToTooltip[resource]}
        data-tooltip-place="top"
      >
        <Container background={background}>
          <ImageContainer background={background}>
            {isTextResource ? (
              <h6>{resource}</h6>
            ) : resource === 98 || resource === "eBct" ? (
              <EbctContainer background={background}>⚡</EbctContainer>
            ) : (
              <img src={resourceToImagePath(Number(resource))} alt="resource" />
            )}
          </ImageContainer>
          <TextContainer fontSize={fontSize}>
            <h6>{`${quantity}`}</h6>
            <h4>%</h4>
          </TextContainer>
        </Container>
      </a>
      <Tooltip id={resource} style={{ fontSize: "12px", fontWeight: 600, zIndex: 4 }} />
    </>
  );
};

export default ResourceContainer;
