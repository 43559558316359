import { ethers } from "ethers";
import Rpc from "../rpc/Rpc.js";
import Contracts from "../utils/Contracts.js";
import AccountModel from "./AccountModel.js";

class ContractModel extends AccountModel {
  constructor(address) {
    super(address);

    this.isContract = true;
    this.abi = Contracts.addressToAbi(address);
    this.viewMethods = [];
    this.changeMethods = [];

    this.parseAbi(this.abi);
  }

  parseAbi(abi) {
    if (!abi) return;

    // First, we separate the view methods from the change methods
    this.viewMethods = abi.filter((method) => {
      return (
        (method.stateMutability === "view" || method.stateMutability === "pure") &&
        method.name !== "DEFAULT_ADMIN_ROLE"
      );
    });
    this.changeMethods = abi.filter((method) => {
      return (
        method.stateMutability !== "view" &&
        method.stateMutability !== "pure" &&
        method.type !== "event" &&
        method.name !== "initialize" &&
        method.name !== "__Administrable_init" &&
        method.name !== "__AdminUpBridgeERC20_init" &&
        method.name !== "__AdminUpgradeableERC721_init" &&
        method.name !== "_resetBlocklist" &&
        method.name !== "setRResult"
      );
    });
  }

  setEthersContract(ethersContract) {
    this.ethersContract = ethersContract;
  }
}

export default ContractModel;
