import React from "react";
import styled from "styled-components";
import TradeIcon from "../../assets/images/icon/trade.png";
import "../../assets/styles/loader.css";

// create a styled button:
const StyledButton = styled.button`
  width: 200px;
`;

const DepositButton = ({ onClick, isLoading, className, disabled }) => {
  return (
    <StyledButton onClick={onClick} className={className} disabled={disabled || isLoading}>
      {isLoading ? <div className="loader"></div> : <img src={TradeIcon} alt="Magnifying Glass" />}
    </StyledButton>
  );
};

export default DepositButton;
