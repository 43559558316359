import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";

import { useParams, useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import Card from "../components/card/Card";

import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useUpgradesContext } from "../contexts/UpgradesContext";

import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

import { numberToName as type_ } from "../config/resources/06-type_";
import { numberToName as rarity } from "../config/resources/05-rarity";
import { indexToName as resource } from "../config/resources/04-resources-full-list";
import { numberToName as trait } from "../config/resources/01-traits";

import { format } from "../utils/math";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 400px;
  margin-top: 10px;
`;

const CardContainer = styled.div``;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const InputIdField = styled.input`
  width: 370px !important;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 0 10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.3) !important;
  transition: all 0.3s ease-in-out;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 94px;
  height: 40px;
  background: #0f2034;
  margin: 6px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;

  ${(props) =>
    props.isSelected &&
    `
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  `}

  &:hover {
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  }
`;

const Beast = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { fetchBeasts } = usePlayerContext();
  const { consult } = useWeb3Context();
  const { getCollectionNames } = useUpgradesContext();

  const [inputId, setInputId] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [nft, setNft] = useState(null);
  const [collectionName, setCollectionName] = useState("");
  const [reloadTimeout, setReloadTimeout] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  function getValidTraits(traitIds) {
    // return only ids that are not 0:
    return traitIds.filter((traitId) => traitId !== 0);
  }

  function getTraitNames(traitIds) {
    const list = getValidTraits(traitIds).map((traitId) => trait(traitId, true));
    return list.join(", ");
  }

  function formatDecimal(number) {
    if (number.toString().length === 3) {
      return number.toString() + "0";
    } else {
      return number.toString();
    }
  }

  function loadId(_id) {
    // Check if the beast is already loaded
    if ((isLoaded && inputId === _id) || nft?.id === _id) return;

    setInputId(_id);

    clearTimeout(reloadTimeout);
    setNft(null);
    setCollectionName("");
    const finalId = _id || "-1";
    const _timeout = setTimeout(navigate, 400, `/beast/${finalId}`, { replace: true });
    setReloadTimeout(_timeout);
  }

  function handleIdChange(event) {
    loadId(event.target.value);
  }

  function handleNext() {
    loadId(parseInt(id) + 1);
  }

  function handlePrevious() {
    loadId(parseInt(id) - 1);
  }

  useEffect(() => {
    if (!id || !consult || !fetchBeasts || isFetching) return;

    const fetch = async (id) => {
      setIsFetching(true);

      const tempBeast = await fetchBeasts([id]);

      if (tempBeast) {
        setNft(tempBeast[0]);

        getCollectionNames([parseInt(BigNumber(tempBeast[0].collection).toString())]).then(
          (names) => {
            setCollectionName(names[0]);
            setIsFetching(false);
            setIsLoaded(false);
          }
        );
        console.log(`Beast :: nft #${id} found: ${JSON.stringify(tempBeast)}`);
      } else {
        setIsFetching(false);
      }
    };

    if (id !== "-1" && !isLoaded && nft?.id !== id) {
      fetch(id);
    }
  }, [id, consult, fetchBeasts]);

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🦖</h1>
                    {nft ? (
                      <h1 className="heading text-center">{`${
                        nft.type_ > 0 ? `Beast #${nft.id}` : "Not found"
                      }`}</h1>
                    ) : (
                      <h1 className="heading text-center">
                        {id === "-1" ? "All Kingdom Beasts" : `Loading...`}
                      </h1>
                    )}
                  </div>
                  <InputContainer>
                    <InputIdField
                      type="text"
                      value={inputId}
                      onChange={handleIdChange}
                      placeholder={inputId || "Enter a beast's blockchain ID"}
                      style={{ textAlign: "center" }}
                    />
                  </InputContainer>
                  <div>
                    {nft && nft?.type_ > 0 && (
                      <h5 className="sub-title help-center">
                        <p>
                          {`It's a ${nft.collection !== 0 ? "Collection" : ""} ${rarity(
                            nft.rarity
                          )} ${type_(nft.type_)} at Rank ${nft.rank} and Agility ${nft.agility}.`}
                        </p>
                        {collectionName && (
                          <p>{`It belongs to the ${collectionName} Collection (#${nft.collection}).`}</p>
                        )}
                        <p>{`It has ${getValidTraits(nft.traits).length} traits${
                          getValidTraits(nft.traits).length > 0
                            ? `: ${getTraitNames(nft.traits)}.`
                            : "."
                        }`}</p>
                        <p>{`The Base Farm is ${format(
                          BigNumber(nft.baseFarm).div(1e9).toFixed(0)
                        )}; Farm Per Block is ${format(
                          BigNumber(nft.farmPerBlock).div(1e9).toFixed(0),
                          3
                        )} Gwei of BCT.`}</p>
                        <p>{`Basic Loot Skill at level ${nft.skills[0]}: farms ${formatDecimal(
                          (nft.skills[0] * nft.rarity) / 100
                        )} ${resource(nft.type_ - 1)} per day.`}</p>
                        {nft.type_ >= 6 && (
                          <p>{`Mythic Basic Loot at level ${nft.skills[4]}: farms ${formatDecimal(
                            (nft.skills[4] * nft.rarity) / 100
                          )} ${resource(nft.type_)} per day.`}</p>
                        )}
                        <p>{`Advanced Loot Skill at level ${nft.skills[1]}: farms ${formatDecimal(
                          (nft.skills[1] * nft.rarity) / 100
                        )} ${resource(nft.type_ + 4)} per day.`}</p>
                        {nft.type_ >= 10 && (
                          <p>{`Titanic Advanced Loot at level ${
                            nft.skills[0]
                          }: farms ${formatDecimal((nft.skills[0] * nft.rarity) / 100)} ${resource(
                            nft.type_ + 5
                          )} per day.`}</p>
                        )}
                        <p>{`Golden Nuts Skill at level ${nft.skills[2]}: farms ${formatDecimal(
                          (nft.skills[2] * nft.rarity) / 100
                        )} ${resource(10)} per day.`}</p>
                        <p>{`Evolution Items Skill at level ${nft.skills[3]}: farms ${formatDecimal(
                          (nft.skills[3] * nft.rarity) / 100
                        )} Evolution Items per day.`}</p>

                        <p>{`It evolved ${nft.evos} time${
                          nft.evos === 1 ? "" : "s"
                        } and had its Base Farm boosted ${nft.nuts} time${
                          nft.nuts === 1 ? "" : "s"
                        }.`}</p>
                      </h5>
                    )}
                    {nft && nft?.type_ === 0 && (
                      <h5 className="sub-title help-center">{`There is no Beast with blockhain ID #${id}`}</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Container>
            <CardContainer>
              {nft && nft?.type_ > 0 && <Card nft={nft} squadType={6} squadSynergies={[]} />}
            </CardContainer>
            {nft && nft?.type_ > 0 && (
              <PaginationContainer>
                {nft?.type_ > 0 && nft?.id !== 0 && (
                  <PaginationButton onClick={handlePrevious}>{`⬅ #${
                    parseInt(nft.id) - 1
                  }`}</PaginationButton>
                )}
                <PaginationButton onClick={handleNext}>{`#${
                  parseInt(nft.id) + 1
                } ➡`}</PaginationButton>
              </PaginationContainer>
            )}
          </Container>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Beast;
