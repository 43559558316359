import React, { useState, useEffect } from "react";

import { getAvailableQuests } from "../../config/resources/02-quests";
import {
  GlobalContainer,
  Container,
  Title,
  Subtitle,
  Wrapper,
  Separator,
  Envelope,
  PaginationButton,
  PaginationContainer,
} from "./styles";
import Quest from "./quest";

import SeparatorImg from "../../assets/images/icon/separator.png";

const QuestSelectorModal = ({
  setSelectedQuest,
  setShowQuestSelector,
  showQuestSelector,
  squad,
  player,
}) => {
  const availableQuests = getAvailableQuests(
    squad.synergyBonus,
    [...squad.traits, squad.squadTrait],
    squad.collections,
    player.ingameBalances[2],
    squad.dragons,
    squad.beastsOnFire
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [totalPages, setTotalPages] = useState(Math.ceil(availableQuests.length / itemsPerPage));

  const questsToShow = availableQuests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const modalBodyRef = React.useRef(null);

  useEffect(() => {
    setTotalPages(Math.ceil(availableQuests.length / itemsPerPage));
  }, [availableQuests]);

  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  const chooseQuest = (questId) => {
    setSelectedQuest(questId);
    setShowQuestSelector(false);
  };

  const scrollToTop = () => {
    modalBodyRef.current.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
    let buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <PaginationButton
          key={i}
          onClick={() => setCurrentPage(i)}
          disabled={currentPage === i}
          isSelected={currentPage === i}
          style={i === 1 ? { marginLeft: "0px" } : null}
        >
          {i}
        </PaginationButton>
      );
    }

    return totalPages > 1 && <PaginationContainer>{buttons.slice(0, 10)}</PaginationContainer>;
  };

  return (
    squad &&
    questsToShow.length > 0 && (
      <GlobalContainer
        show={showQuestSelector}
        onClick={() => setShowQuestSelector(false)}
        //onHide={() => setShowQuestSelector(false)}
      >
        <Container onClick={handleContainerClick} ref={modalBodyRef}>
          <Title>Quests</Title>
          <Subtitle>
            There are 168 unique quests to choose from, but you can only see the ones available to
            your squad. The higher the synergy bonus of a squad, the more quests show up here. You
            can also unlock new quests for this squad by having collection beasts, beasts on fire
            and dragons in it.
          </Subtitle>

          {questsToShow.map((quest, index) => (
            <Envelope key={quest.id}>
              <Wrapper>
                <Quest squad={squad} questId={quest.id} onClick={() => chooseQuest(quest.id)} />
              </Wrapper>
              {index < questsToShow.length - 1 ? (
                <Separator src={SeparatorImg} alt="separator" />
              ) : null}
            </Envelope>
          ))}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      </GlobalContainer>
    )
  );
};

export default QuestSelectorModal;
