import {
  SERVICE_URL,
  GET_ORDERS_ENDPOINT,
  GET_ORDERS_BY_RESOURCE_ID_ENDPOINT,
  GET_BEST_RESOURCE_ORDERS_ENDPOINT,
  GET_ORDERS_BY_SELLER_ENDPOINT,
  GET_BEAST_ORDERS_ENDPOINT,
  GET_DUTCH_ACUTIONS_ENDPOINT,
} from "../../../utils/constants";

import axios from "axios";

import OrderModel from "../../../models/OrderModel";

class Service {
  constructor() {
    this.baseUrl = SERVICE_URL;
    this.getOrdersUrl = `${SERVICE_URL}${GET_ORDERS_ENDPOINT}`;
    this.getOrdersByResourceIdUrl = `${SERVICE_URL}${GET_ORDERS_BY_RESOURCE_ID_ENDPOINT}`;
    this.getBestResourceOrdersUrl = `${SERVICE_URL}${GET_BEST_RESOURCE_ORDERS_ENDPOINT}`;
    this.getOrdersBySellerUrl = `${SERVICE_URL}${GET_ORDERS_BY_SELLER_ENDPOINT}`;
    this.getBeastOrdersUrl = `${SERVICE_URL}${GET_BEAST_ORDERS_ENDPOINT}`;
    this.getDutchAuctionsUrl = `${SERVICE_URL}${GET_DUTCH_ACUTIONS_ENDPOINT}`;
  }

  async getOrders(ids) {
    console.log("SERVICE::getOrders | ids: ", ids);

    const result = await axios.post(this.getOrdersUrl, {
      orderIds: ids,
    });

    if (!result.data.success) throw new Error("Service Error on getOrders: !result.success");

    if (result.data.data.length > 0) {
      return result.data.data.map((orderData) => new OrderModel(orderData));
    } else {
      return [];
    }
  }

  async getOrdersByResourceId({ id = 0, currency = "eBCT", limit = 25 }) {
    console.log(
      "SERVICE::getOrdersByResourceId | id: ",
      id,
      " | currency: ",
      currency,
      " | limit: ",
      limit
    );

    const result = await axios.post(this.getOrdersByResourceIdUrl, {
      resourceId: id,
      coinToSortBy: currency,
      limit,
    });

    if (!result.data.success)
      throw new Error("Service Error on getOrdersByResourceId: !result.success");

    if (result.data.data.length > 0) {
      return result.data.data.map((orderData) => new OrderModel(orderData));
    } else {
      return [];
    }
  }

  async getBestResourceOrders({ currency = "eBCT", forceRefresh = false }) {
    console.log("SERVICE::getBestResourceOrders | currency: ", currency);

    const result = await axios.post(this.getBestResourceOrdersUrl, {
      coinToSortBy: currency,
      forceRefresh,
    });

    if (!result.data.success) {
      console.log("SERVICE::getBestResourceOrders | result: ", result);
      throw new Error("Service Error on getBestResourceOrders: !result.success");
    }

    if (result.data.data.length > 0) {
      return result.data.data.map((orderData) => new OrderModel(orderData));
    } else {
      return [];
    }
  }

  async getOrdersBySeller({ seller, limit = 250 }) {
    console.log("SERVICE::getOrdersBySeller | seller: ", seller, " | limit: ", limit);

    const result = await axios.post(this.getOrdersBySellerUrl, {
      seller,
      limit,
    });

    if (!result.data.success)
      throw new Error("Service Error on getOrdersBySeller: !result.success");

    if (result.data.data.length > 0) {
      return result.data.data.map((orderData) => new OrderModel(orderData));
    } else {
      return [];
    }
  }

  async getBeastOrders({ type_, rarity, trait, sortPreference }) {
    if (type_ === 0) type_ = null;
    if (rarity === 0) rarity = null;
    if (trait === 0 || trait === 999) trait = null;
    if (sortPreference === 0) sortPreference = null;

    console.log(
      "SERVICE::getBeastOrders | type: ",
      type_,
      " | rarity: ",
      rarity,
      " | trait: ",
      trait,
      " | sortPreference: ",
      sortPreference
    );

    const query = {};
    if (type_) query.type_ = type_;
    if (rarity) query.rarity = rarity;
    if (trait) query.trait = trait;
    if (sortPreference) query.sortPreference = sortPreference;

    /*
    if (!type_ && !rarity && !trait) {
      query.limit = 25;
    }
    */

    const result = await axios.post(this.getBeastOrdersUrl, query);

    if (!result.data.success) {
      console.log("SERVICE::getBeastOrders | result: ", result);
      throw new Error("Service Error on getBeastOrders: !result.success");
    }

    if (result.data.data.length > 0) {
      return result.data.data.map((orderData) => new OrderModel(orderData));
    } else {
      return [];
    }
  }

  async getOrdersById({ orderIds, limit = 250 }) {
    console.log("SERVICE::getOrdersById | orderIds: ", orderIds, " | limit: ", limit);

    const result = await axios.post(this.getOrdersUrl, {
      orderIds,
      limit,
    });

    if (!result.data.success) throw new Error("Service Error on getOrdersById: !result.success");

    if (result.data.data.length > 0) {
      return result.data.data.map((orderData) => new OrderModel(orderData));
    } else {
      return [];
    }
  }

  async getDutchAuctions() {
    console.log("SERVICE::getDutchAuctions");

    const result = await axios.post(this.getDutchAuctionsUrl, {});

    if (!result.data.success) throw new Error("Service Error on getDutchAuctions: !result.success");

    if (result.data.data.length > 0) {
      return result.data.data.map((orderData) => new OrderModel(orderData));
    } else {
      return [];
    }
  }
}

const service = new Service();
export default service;
