import { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import BigNumber from "bignumber.js";
import { format } from "../../utils/math";

import cat from "../../assets/images/squads/plus-cat.png";
import mouse from "../../assets/images/squads/plus-mouse.png";
import mix from "../../assets/images/squads/plus-mixed.png";
import padlock from "../../assets/images/squads/padlock.png";
import SunRays from "../../assets/images/squads-card/sunrays.png";

import { getDefaultImage } from "../card/CardImage";
import CardAttributes from "../card/CardAttributes";
import CardTraits from "../card/CardTraits";
import CardSkillsRight from "../card/CardSkillsRight";
import CardSkillsLeft from "./CardSkillsLeftForSquads";
import CardProgressBar from "../card/CardProgressBar";
import silverStar from "../../assets/images/squads-card/silver-star.png";
import goldenStar from "../../assets/images/squads-card/golden-star.png";

import { isMaxedOut } from "../../config/resources/08-squad-cards";

import { getCustomBorder } from "../../config/resources/08-squad-cards";
import { numberToName as getRarity } from "../../config/resources/05-rarity";
import { numberToName as getType } from "../../config/resources/06-type_";
import { useNavigate } from "react-router-dom";
import { useKingdomQuestsContext } from "../../contexts/KingdomQuestsContext";

import promoStyle1 from "../../assets/images/squads-card/promo-overlay-1.png";
import promoStyle2 from "../../assets/images/squads-card/promo-overlay-4.png";
import promoStyle3 from "../../assets/images/squads-card/promo-overlay-5.png";
import promoStyle4 from "../../assets/images/squads-card/promo-overlay-6.png";
import promoStyle5 from "../../assets/images/squads-card/promo-overlay-7.png";
import promoStyle6 from "../../assets/images/squads-card/promo-overlay-8.png";
import { isPromoNft, getPromoInfo } from "../card/specialNfts";
import revivedOverlay from "../../assets/images/squads-card/revived.png";

const flash = keyframes`
  from, 50%, to {
    opacity: 0.5;
    scale: 2;
  }

  40%, 90% {
    opacity: 0;
    scale: 0.6;
  }
`;

const colorCycle = keyframes`
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const SunRaysOverlay = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1%;
  left: -1%;
  scale: 1.4;
  z-index: 1;

  animation: ${flash} 20s infinite, ${spin} 10s linear infinite;

  filter: saturate(2) brightness(0.8);
`;

const GridItem = styled.div`
  background-color: ${(props) => props.backgroundColor};
  background-image: ${(props) =>
    props.backgroundImage ? `url('${props.backgroundImage}')` : "none"};
  background-size: 200px;
  outline: ${(props) => (props.borderImage ? "none" : `3px solid ${props.borderColor}`)};
  border-image: ${(props) =>
    props.hasCustomBorder ? `url('${props.borderImage}') 0 fill` : "none"};
  border-image-outset: 3px;
  width: 200px;
  height: 246px;
  border-radius: 4px;
  cursor: ${(props) => (props.isClickable ? "pointer" : "auto")};
  z-index: 2;

  &:hover {
    outline: ${(props) =>
      !props.backgroundImage ? "2px solid white" : props.isRemoving ? "3px solid red" : "none"};
  }

  &:hover .nft-remove__unique {
    display: block;
  }

  @media (max-width: 450px) {
    width: 150px;
    height: 184px;
    background-size: 150px;
  }
`;

const ImageEmptyModel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0.25;

  img {
    height: 120px;
    position: relative;
    top: -10px;
    filter: invert(49%) sepia(32%) saturate(3257%) hue-rotate(217deg) brightness(92%) contrast(87%);
  }

  .no-nft-text {
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    width: 100%;
    position: absolute;
    bottom: 10px;
  }
`;

const NftId = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  position: relative;
  font-size: 10px;
  font-weight: bold;
  color: ${(props) => (props.nftId === "NEW" ? `#ff0` : props.nftId === 201 ? `#0ff` : `#fff`)};
  height: 20%;
  text-align: center;
  right: 1px;

  @media (max-width: 450px) {
    font-size: 8px;
  }
`;

const NftName = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  font-size: 12px;
  color: ${(props) =>
    props.nftId === "NEW" ? `#ff0` : props.isPromo ? `#0ff` : props.isAwakened ? `#f7f` : `#fff`};
  height: 40%;
  text-align: center;
  top: 4px;

  @media (max-width: 450px) {
    font-size: 9.5px;
    top: 3.5px;
  }
`;

const NutsAndEvoContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 18%;
  top: 31px;
  right: 1px;
  font-size: 13.5px;
  color: #ffffff;

  .goldenStar {
    position: "relative";
    z-index: 99;
    top: -3px;
  }

  @media (max-width: 450px) {
    top: 23px;
  }
`;

const FarmContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.04em;
  position: relative;
  display: flex;
  height: 40%;
  flexdirection: row;
  justifycontent: "center";
  top: 2px;

  @media (max-width: 450px) {
    top: 1px;
  }
`;

const BaseFarm = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  width: 50%;
  text-align: center;
  top: 5px;

  @media (max-width: 450px) {
    font-size: 11px;
  }
`;

const FarmPerBlock = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: ${({ isDifferent, isMaxedOut }) =>
    isMaxedOut ? "#f9f" : isDifferent ? "#00ff00" : "#ffffff"};
  width: 50%;
  text-align: center;
  top: 5px;

  text-shadow: ${(props) =>
    props.isMaxedOut
      ? "-0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333, 0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333"
      : "none"};

  @media (max-width: 450px) {
    font-size: 11px;
  }
`;

const InfluencerOverlay = styled.img`
  position: absolute;
  z-index: 3;
  transform: translate(-7px, -11px);
  width: ${(props) => (props.scale ? `${214 * props.scale}px` : `214px`)};
  height: ${(props) => (props.scale ? `${267 * props.scale}px` : `271px`)};
  pointer-events: none;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1));

  ${(props) =>
    props.cycleColors &&
    css`
      animation: ${colorCycle} 10s linear infinite;
    `}

  ${(props) =>
    !props.lockSize &&
    `
  @media (max-width: 450px) {
    margin-top: ${String(props.scale ? `${-1 * props.scale}px` : `-1px`)};
    width: ${String(props.scale ? `${164 * props.scale}px` : `164px`)};
    height: ${String(props.scale ? `${204 * props.scale}px` : `204px`)};
  }`}
`;

const colorize = keyframes`
  0% {
    filter: invert(10%) sepia(9%) saturate(5000%) hue-rotate(280deg) brightness(104%) contrast(104%);
  }
  20% {
    filter: invert(0) sepia(0) saturate(0) hue-rotate(120deg) brightness(1) contrast(1);
  }
  80% {
    filter: invert(0) sepia(0) saturate(0) hue-rotate(120deg) brightness(1) contrast(1);
  }
  100% {
    filter: invert(10%) sepia(9%) saturate(5000%) hue-rotate(280deg) brightness(104%) contrast(104%);
  }
`;

const RevivedOverlayContainer = styled.div`
  position: absolute;
  zindex: 99;
  width: ${(props) => (props.scale ? `${18 * props.scale}px` : `18px`)};
  height: ${(props) => (props.scale ? `${18 * props.scale}px` : `18px`)};
  transform: ${(props) =>
    props.scale
      ? `translate(${90 * props.scale}px, ${-11 * props.scale}px)`
      : `translate(90px, -11px)`};

  ${(props) =>
    !props.lockSize &&
    `
      @media (max-width: 450px) {
        width: ${String(props.scale ? `${18 * props.scale}px` : `18px`)};
        height: ${String(props.scale ? `${18 * props.scale}px` : `18px`)};
        transform: translate(66px, -14px);
        display: flex;
      }
    `}

  animation: ${colorize} 10s linear infinite;

  background-color: black;
  border-radius: 50%;

  z-index: 101;
`;

const SquadNftGridItem = ({
  nft,
  unlocked,
  squadId,
  synergies,
  collections,
  squadType = 6,
  onExtraSlotClick,
  isRemovingFromSquad = false,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [actualCardImage, setActualCardImage] = useState(null);
  const [hasCustomBorder, setHasCustomBorder] = useState(false);
  const [needPadding, setNeedPadding] = useState(false);
  const [borderImage, setBorderImage] = useState("");
  const [borderColor, setBorderColor] = useState("");
  const [backGroundColor, setBackGroundColor] = useState("");
  const navigate = useNavigate();
  const { removeFromSquad } = useKingdomQuestsContext();

  useEffect(() => {
    if (nft && collections) {
      setIsLoading(false);
      const [hasBorder, hasPadding, hasImage] = getCustomBorder(
        nft,
        collections.includes(nft.collection)
      );
      setHasCustomBorder(hasBorder);
      setNeedPadding(hasPadding);
      setBorderImage(hasImage);
      setBackGroundColor("#25235500");

      const getCardImageUrl = async () => {
        const url = await setCardImage(nft.imageUrl);

        if (url) {
          setActualCardImage(url);
        } else {
          setActualCardImage(getDefaultImage(`${nft.type_}-${nft.rarity}`));
        }
      };

      getCardImageUrl();
    } else {
      setIsLoading(true);
      setBackGroundColor(unlocked ? "#252355" : "#0f2034");
      setBorderColor(unlocked ? "#7471DC" : "#7471aC");
      setHasCustomBorder(true);
    }
  }, [nft, unlocked]);

  const isItMaxedOut = isMaxedOut(nft);

  const setCardImage = async (url) => {
    if (!url) return;
    url = url.trim();

    if (url === "" || url === '""') return;
    if (!url.startsWith("https://")) return;

    if (nft.rarity < 5) {
      if (!url.endsWith(".png") && !url.endsWith(".jpg")) return;
    } else {
      if (!url.endsWith(".png") && !url.endsWith(".jpg") && !url.endsWith(".gif")) return;
    }

    return url;
  };

  const addNftToSquad = (squadId) => {
    if (unlocked) {
      navigate("/beasts", { state: { squadId } });
    }
  };

  const removeNftFromSquad = async () => {
    await removeFromSquad(nft?.id, squadId);
  };

  const onAddExtraSlot = (squadId) => {
    if (onExtraSlotClick) {
      onExtraSlotClick(squadId);
    }
  };

  const isAwakened = () => {
    if (!nft?.attributes) return false;
    if (nft?.attributes.length <= 6) return false;

    return nft?.attributes[6] > 0;
  };

  const isFarmPerBlockDifferent =
    new BigNumber(nft?.baseFarm).div(1e9).toFixed(0) !==
    new BigNumber(nft?.farmPerBlock).div(1e9).toFixed(0);

  const renderStars = () => {
    if (!nft.evos || nft?.evos === 0) {
      return null; // Render nothing if evos is 0
    } else if (nft.evos <= 5) {
      return (
        // Render silver stars for evos 1 to 5
        <>
          {Array.from({ length: nft.evos }).map((_, index) => (
            <img
              key={index}
              src={silverStar}
              alt="Silver Star"
              style={{ position: "relative", zIndex: 3, top: "-2px" }}
            />
          ))}
        </>
      );
    } else {
      return (
        // Render golden stars for evos 6 to 11
        <>
          {Array.from({ length: nft.evos - 5 }).map((_, index) => (
            <img
              key={index}
              src={goldenStar}
              alt="Golden Star"
              style={{
                position: "relative",
                zIndex: 3,
                top: "-2px",
              }}
            />
          ))}
        </>
      );
    }
  };

  const promoFrame = (nftId) => {
    const frameId = getPromoInfo(nftId)?.frameId;

    switch (frameId) {
      case 1:
        return <InfluencerOverlay scale={1} src={promoStyle1} />;

      case 2:
        return <InfluencerOverlay scale={1} src={promoStyle2} />;

      case 3:
        return <InfluencerOverlay scale={1} src={promoStyle3} />;

      case 4:
        return <InfluencerOverlay scale={1} src={promoStyle4} />;

      case 5:
        return <InfluencerOverlay scale={1} src={promoStyle5} cycleColors={true} />;

      case 6:
        return <InfluencerOverlay scale={1} src={promoStyle6} />;

      default:
        return null;
    }
  };

  const isRevivedBeast = () => {
    if (!nft) return false;
    if (!nft?.attributes) return false;
    if (nft?.attributes?.length <= 6) return false;
    if (nft?.attributes[6] <= 0) return false;

    return true;
  };

  return (
    <>
      <GridItem
        backgroundColor={backGroundColor}
        borderColor={borderColor}
        hasCustomBorder={hasCustomBorder}
        borderImage={borderImage}
        backgroundImage={actualCardImage}
        hasPadding={needPadding}
        isClickable={
          (onExtraSlotClick !== null && !nft?.id) || (isRemovingFromSquad && nft?.id > 0)
        }
        onClick={isRemovingFromSquad && nft?.id > 0 ? removeNftFromSquad : null}
        isRemoving={isRemovingFromSquad}
      >
        {isRevivedBeast() && (
          <RevivedOverlayContainer>
            <img src={revivedOverlay} />
          </RevivedOverlayContainer>
        )}
        {isPromoNft(nft?.id) && promoFrame(nft?.id)}

        <div
          style={{
            position: "relative",
            height: `${nft ? "50%" : "100%"}`,
            zIndex: `${nft ? "-1" : "0"}`,
          }}
        >
          {nft && <CardAttributes nft={nft} />}
          {nft && <CardTraits traits={nft.traits} synergies={synergies} />}
          {nft ? (
            <></>
          ) : (
            <ImageEmptyModel
              onClick={unlocked ? () => addNftToSquad(squadId) : () => onAddExtraSlot(squadId)}
            >
              <img
                src={!unlocked ? padlock : squadType === 1 ? mouse : squadType === 2 ? cat : mix}
                alt="empty-img"
              />
              <div className="no-nft-text">
                <p>{unlocked ? "TAP TO ADD" : "TAP TO UNLOCK"}</p>
              </div>
            </ImageEmptyModel>
          )}
        </div>
        {nft && (
          <div style={{ position: "relative", height: "50%" }}>
            <div style={{ position: "relative", height: "50%", display: "flex" }}>
              <CardSkillsLeft nft={nft} skillsLength={nft.spcies >= 6 ? 3 : 2} />
              <CardSkillsRight nft={nft} />
            </div>
            <div style={{ position: "relative", height: "50%" }}>
              <NftId nftId={nft?.id}>{nft.id}</NftId>
              <NftName nftId={nft?.id} isAwakened={isAwakened()} isPromo={isPromoNft(nft?.id)}>
                {!nft.name || nft.name.trim() === '""'
                  ? `${getRarity(nft.rarity)} ${getType(nft.type_)}`
                  : nft.name}
              </NftName>
              <NutsAndEvoContainer>
                {nft.nuts !== undefined && (
                  <>
                    <CardProgressBar progress={nft.nuts * 2} />
                  </>
                )}
                {renderStars()}
              </NutsAndEvoContainer>
              <FarmContainer>
                <BaseFarm>{format(new BigNumber(nft.baseFarm).div(1e9).toFixed(0), 3)}</BaseFarm>
                <FarmPerBlock isDifferent={isFarmPerBlockDifferent} isMaxedOut={isItMaxedOut}>
                  {format(new BigNumber(nft.farmPerBlock).div(1e9).toFixed(0), 3)}
                </FarmPerBlock>
              </FarmContainer>
            </div>
          </div>
        )}
      </GridItem>
    </>
  );
};

//{format(new BigNumber(_nft.baseFarm).div(1e9).toFixed(0), 3)}
export default SquadNftGridItem;
