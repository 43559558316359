import {
  Container,
  TopContainer,
  BottomContainer,
  TitleContainer,
  DescriptionContainer,
  ImageContainer,
  NameContainer,
  CancelContainer,
  CancelButton,
} from "./styles";
import { resourceToImagePath } from "../../../config/resources/09-resources-images";
import BinIcon from "../../../assets/images/icon/trash-bin.png";

// check if the logged in player is the seller of this order
// if it is, and there have been no bids so far, then we'll show a cancel button
// Check if 24 hours have passed since the latest bid.
// If yes, and the logged player is the privateFor of this order,
// then we'll show a CLAIM button
// If yes, and the logged player is the seller of this order,
// and there have been no bids so far, then we'll show a CANCEL button
// If no, then we'll show a BID button

const ResourceNft = ({
  resource,
  quantity,
  background,
  name,
  description,
  cancelOrderCallback = null,
  showCancelButton = true,
}) => {
  const resourcePath = `/${resourceToImagePath(Number(resource))}`;

  const handleCancelOrder = () => {
    if (cancelOrderCallback) cancelOrderCallback();
  };

  return (
    <Container background={background}>
      <TitleContainer>
        <TopContainer>
          {showCancelButton && (
            <CancelContainer>
              <CancelButton background={background} disable={false} onClick={handleCancelOrder}>
                <img src={BinIcon} alt="cancel" />
              </CancelButton>
            </CancelContainer>
          )}
          <ImageContainer background={background}>
            <img src={resourcePath} alt="resource" />
          </ImageContainer>
        </TopContainer>
        <BottomContainer>
          <NameContainer>
            {quantity} {name}
          </NameContainer>
          <DescriptionContainer>{description}</DescriptionContainer>
        </BottomContainer>
      </TitleContainer>
    </Container>
  );
};

export default ResourceNft;
