import { ethers } from "ethers";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/header/Header";
import BigNumber from "bignumber.js";
import Messenger from "../utils/Messenger";

import Contracts from "../utils/Contracts";
import { quoteBuyKozi, quoteStableToKozi, priceFromReserves } from "../utils/koziPool";

import { useWeb3Context } from "../contexts/Web3Context";

import IntervalManager from "../utils/IntervalManager.js";

const DECIMAL_PLACES = 6;
BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_UP });

const TradingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  padding: 20px;
  margin: 4px;
  border-radius: 8px;
  outline: 3px solid #fff;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

const TradingButton = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background: ${(props) =>
    props.disabled
      ? "linear-gradient(145deg, #ccc, #bbb)"
      : "linear-gradient(145deg, #45a049, #4caf58)"};
  border: none;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background 0.3s;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  &:hover {
    background: ${(props) =>
      props.sell
        ? "linear-gradient(145deg, #c74343, #df6360)"
        : "linear-gradient(145deg, #3c8f40, #51c665)"};
  }
`;

const TradingLabel = styled.label`
  color: #fff;
  margin-bottom: 6px;
  font-size: 14px;
`;

const TradingInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 6px;
  border: 1px solid #4caf58;
  background-color: #1c1b20;
  color: #fff;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  max-width: 90%;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  padding: 20px;
  gap: 6px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const KingdomBridge = () => {
  BigNumber.config({ DECIMAL_PLACES: 18 });
  const { isReady, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE, switchToBnbChain, getChainId, signer } =
    useWeb3Context();
  const [chainId, setChainId] = useState(0);
  const [formattedStabletokenAmount, setFormattedStabletokenAmount] = useState("0");
  const [formattedKoziAmount, setFormattedKoziAmount] = useState("0");
  const [formattedStableToStableAmount, setFormattedStableToStableAmount] = useState("0");

  const kingdomChainProvider = new ethers.JsonRpcProvider(
    "https://kingdomchain.observer/rpc",
    39916801
  );

  const [poolState, setPoolState] = useState({
    price: 0,
    reserves: [0, 0],
    targetPrice: 0,
    timestamp: 0,
  });

  const updatePoolState = async () => {
    if (!isReady) return;

    // let's check if we have the pool state in the local storage
    const _localPoolState = JSON.parse(localStorage.getItem("poolState"));
    if (_localPoolState) {
      const _now = Date.now();
      const _diff = _now - _localPoolState.timestamp;
      if (_diff < 199999) {
        console.log("Pool State is fresh. Using local storage.");
        setPoolState(_localPoolState);
        return;
      }
    }

    try {
      const koziPoolAddress = Contracts.keyToAddress("KoziPool");
      const koziPoolAbi = Contracts.keyToAbi("KoziPool");
      const provider = kingdomChainProvider;
      const koziPoolContract = new ethers.Contract(koziPoolAddress, koziPoolAbi, provider);

      const _poolState = await koziPoolContract.getPricesAndReserves();
      const _state = {
        price: BigNumber(_poolState[0]).toString(),
        targetPrice: BigNumber(_poolState[1]).toString(),
        reserves: [BigNumber(_poolState[2]).toString(), BigNumber(_poolState[3]).toString()],
      };
      setPoolState(_state);
      console.log("Kozi Pool State: ", _state);

      // save it in the local storage
      localStorage.setItem("poolState", JSON.stringify({ ..._state, timestamp: Date.now() }));
      console.log("Pool State saved in local storage");

      cid();
    } catch (e) {
      //console.error(e);
      //Messenger.error(`Failed to update pool state: ${e.message}`);
    }
  };

  useEffect(() => {
    updatePoolState();
    cid();
    IntervalManager.start("BridgeUpdatePoolState", updatePoolState, 20000);
  }, [isReady]);

  const handleStabletokenAmountChange = (event) => {
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedStabletokenAmount(value);

    // Perform calculation to update KOZI amount
    const calculatedKoziAmount = quoteStableToKozi(
      BigNumber(value).times(1e18),
      poolState.reserves[0],
      poolState.reserves[1]
    );
    const koziAmount = calculatedKoziAmount.div(1e18);

    if (koziAmount.isNaN() || koziAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN") {
      setFormattedKoziAmount("0");
      return;
    }

    setFormattedKoziAmount(koziAmount.toFixed(DECIMAL_PLACES), BigNumber.ROUND_DOWN);
  };

  const handleKoziAmountChange = (event) => {
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedKoziAmount(value);

    // Perform calculation to update USDC amount
    const calculatedStabletokenAmount = quoteBuyKozi(
      BigNumber(value).times(1e18),
      poolState.reserves[0],
      poolState.reserves[1]
    );
    const stabletokenAmount = calculatedStabletokenAmount.div(1e18);

    if (
      stabletokenAmount.isNaN() ||
      stabletokenAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN"
    ) {
      setFormattedStabletokenAmount("0");
      return;
    }

    if (stabletokenAmount.lt(0)) {
      setFormattedStabletokenAmount("Infinity");
    } else {
      setFormattedStabletokenAmount(stabletokenAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP));
    }
  };

  const handleStableToStableAmountChange = (event) => {
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedStableToStableAmount(value);
  };

  const getAllowance = async (amount) => {
    const stabletokenAbi = Contracts.keyToAbi("Stabletoken");
    const stabletokenAddress = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
    const stabletokenContract = new ethers.Contract(stabletokenAddress, stabletokenAbi, signer);
    const bnbBridgeAddress = Contracts.keyToAddress("BnbBridge");

    const finalAmount = BigNumber(amount).times(1e18).toFixed(0, BigNumber.ROUND_UP);

    if (finalAmount === "0") {
      Messenger.error("Please enter a valid amount.");
      return false;
    }

    const allowance = new BigNumber(
      await stabletokenContract.allowance(signer.address, bnbBridgeAddress)
    );

    if (allowance.lt(BigNumber(finalAmount))) {
      let tx;
      try {
        tx = await stabletokenContract.approve(bnbBridgeAddress, "999999999000000000000000000");
      } catch (e) {
        console.error(e);
        if (e.message.indexOf("User denied transaction signature") !== -1) {
          Messenger.error(`Transaction cancelled by user.`);
        } else {
          Messenger.error(`Failed to give allowance: ${e.message}`);
        }
        return false;
      }

      Messenger.info("Allowance transaction sent. Please wait for it to be mined.", 20000);
      // Wait for the transaction to be mined
      const receipt = await tx.wait();

      if (receipt.status !== 1) {
        console.log(`> Allowance tx failed.`);
        console.log(`> Receipt: ${JSON.stringify(receipt, null, 2)}`);
        Messenger.error(`Error. Failed do give allowance.`);
        return false;
      } else {
        Messenger.success(`Allowance completed. Now, sign the transaction to bridge USDC.`);
        return true;
      }
    } else {
      console.log(`> Allowance already given.`);
      return true;
    }
  };

  const handleConvertToKoziClick = async () => {
    if (!chainId || chainId === 39916801) return;

    const bnbBridgeAbi = Contracts.keyToAbi("BnbBridge");
    const bnbBridgeAddress = Contracts.keyToAddress("BnbBridge");
    const bnbBridge = new ethers.Contract(bnbBridgeAddress, bnbBridgeAbi, signer);

    const hasAllowance = await getAllowance(formattedStabletokenAmount);
    if (!hasAllowance) return;

    const finalAmount = BigNumber(formattedStabletokenAmount)
      .times(1e18)
      .toFixed(0, BigNumber.ROUND_UP);

    let bridgeTx;
    try {
      bridgeTx = await bnbBridge.depositStabletokens(finalAmount, true);
    } catch (e) {
      console.error(e);
      if (e.message.indexOf("User denied transaction signature") !== -1) {
        Messenger.error(`Transaction cancelled by user.`);
      } else {
        if (e.message.indexOf("BEP20: transfer amount exceeds balance") !== -1) {
          Messenger.error(`Failed to bridge USDC: insufficient USDC balance on BNB Chain.`);
        } else {
          Messenger.error(`Failed to bridge USDC: ${e.message}`);
        }
      }
      return;
    }

    Messenger.info("Transaction sent to the blockchain. Please wait for it to be mined.", 20000);
    // Wait for the transaction to be mined
    await bridgeTx.wait();

    console.log(`Bridging process initiated.`);
    Messenger.success(
      "Bridging process initiated. It may take a few minutes to complete. Check your Discord for updates.",
      15000
    );
  };

  const handleBridgeUsdcClick = async () => {
    if (!chainId || chainId === 39916801) return;

    const bnbBridgeAbi = Contracts.keyToAbi("BnbBridge");
    const bnbBridgeAddress = Contracts.keyToAddress("BnbBridge");
    const bnbBridge = new ethers.Contract(bnbBridgeAddress, bnbBridgeAbi, signer);

    const hasAllowance = await getAllowance(formattedStableToStableAmount);
    if (!hasAllowance) return;

    const finalAmount = BigNumber(formattedStableToStableAmount)
      .times(1e18)
      .toFixed(0, BigNumber.ROUND_UP);

    let bridgeTx;
    try {
      bridgeTx = await bnbBridge.depositStabletokens(finalAmount, false);
    } catch (e) {
      console.error(e);
      if (e.message.indexOf("User denied transaction signature") !== -1) {
        Messenger.error(`Transaction cancelled by user.`);
      } else {
        if (e.message.indexOf("BEP20: transfer amount exceeds balance") !== -1) {
          Messenger.error(`Failed to bridge USDC: insufficient USDC balance on BNB Chain.`);
        } else {
          Messenger.error(`Failed to bridge USDC: ${e.message}`);
        }
      }
      return;
    }

    Messenger.info("Transaction sent to the blockchain. Please wait for it to be mined.", 20000);
    // Wait for the transaction to be mined
    await bridgeTx.wait();

    console.log(`Bridging process initiated.`);
    Messenger.success(
      "Bridging process initiated. It may take a few minutes to complete. Check your Discord for updates.",
      15000
    );
  };

  async function connectToBnbChain() {
    const { provider, signer } = await switchToBnbChain();

    if (provider && signer) {
      return { provider, signer };
    }
  }

  const cid = async () => {
    const chainId = await getChainId();
    setChainId(parseInt(chainId));
    return parseInt(chainId);
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🔗</h1>
                    <h1 className="heading text-center">Kingdom Bridge</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      Securely move your assets from BNB Chain to the Kingdom Chain
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      {chainId === 39916801 ? (
                        <>
                          <span style={{ color: "yellow" }}>
                            Let's connect your Metamask to BNB Chain instead of the Kingdom Chain.
                          </span>{" "}
                          You can manually switch networks on your Metamask, or just{" "}
                          <Link
                            to="#"
                            className="btn-selector nolink"
                            style={{ textDecorationLine: "underline" }}
                            onClick={connectToBnbChain}
                          >
                            CLICK HERE!
                          </Link>
                        </>
                      ) : chainId === 56 ? (
                        <>
                          <span style={{ color: "yellow" }}>
                            You are currently on the BNB Chain ✅
                          </span>
                          <p>Go ahead and send some USDC to the Kingdom Chain.</p>
                          <p>You can convert it to Kozi or just bridge it as USDC.</p>
                        </>
                      ) : (
                        <p>
                          Loading... If this takes too long, make sure your wallet is connected.
                        </p>
                      )}
                    </h5>
                    <h5 className="sub-title help-center small">
                      The Kingdom Bridge is integrated with Discord. While account association is
                      not mandatory, verified players on Discord receive notifications with all the
                      details when they bridge USDC to the Kingdom Chain. If you're not verified
                      yet, consider joining our{" "}
                      <Link
                        to="/discord"
                        className="btn-selector nolink"
                        style={{ textDecorationLine: "underline" }}
                      >
                        Discord server.
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container" style={{ top: "-40px" }}>
              <div className="row">
                <BoxesWrapper>
                  <TradingBox>
                    <TradingLabel htmlFor="stabletokenInputBuy">
                      Send USDC from BNB Chain
                    </TradingLabel>
                    <TradingInput
                      type="text"
                      id="stabletokenInputBuy"
                      value={formattedStabletokenAmount}
                      onChange={handleStabletokenAmountChange}
                      onFocus={(event) => event.target.select()}
                    />
                    <TradingLabel htmlFor="koziInputBuy">
                      Get <span style={{ color: "yellow" }}>KOZI</span> on Kingdom Chain
                    </TradingLabel>
                    <TradingInput
                      type="text"
                      id="koziInputBuy"
                      value={formattedKoziAmount}
                      onChange={handleKoziAmountChange}
                      onFocus={(event) => event.target.select()}
                    />
                    <TradingButton
                      onClick={handleConvertToKoziClick}
                      disabled={
                        !chainId ||
                        chainId === 39916801 ||
                        formattedStabletokenAmount === "Infinity"
                      }
                    >
                      Convert To KOZI
                    </TradingButton>
                  </TradingBox>
                  <TradingBox>
                    <TradingLabel htmlFor="stabletokenInputBuy">
                      Send USDC from BNB Chain
                    </TradingLabel>
                    <TradingInput
                      type="text"
                      id="stabletokenInputBuy"
                      value={formattedStableToStableAmount}
                      onChange={handleStableToStableAmountChange}
                      onFocus={(event) => event.target.select()}
                    />
                    <TradingLabel htmlFor="koziInputBuy">Get USDC on Kingdom Chain</TradingLabel>
                    <TradingInput
                      type="text"
                      id="koziInputBuy"
                      value={formattedStableToStableAmount}
                      onChange={handleStableToStableAmountChange}
                      onFocus={(event) => event.target.select()}
                    />
                    <TradingButton
                      onClick={handleBridgeUsdcClick}
                      disabled={!chainId || chainId === 39916801}
                    >
                      Send USDC
                    </TradingButton>
                  </TradingBox>
                </BoxesWrapper>
              </div>
              <p
                className="small"
                style={{ width: "100%", textAlign: "center", marginTop: "-40px" }}
              >
                Powered by BscScan APIs
              </p>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default KingdomBridge;
