import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: "center";
  width: 60px;
  height: 60px;
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  background: linear-gradient(0deg, rgba(15, 52, 72, 1), rgba(15, 32, 52, 1));

  &:hover {
    outline: ${(props) => (props.disabled ? "none" : "2px solid rgba(255,255,255,1)")};
    background: ${(props) =>
      props.disabled
        ? "linear-gradient(0deg, rgba(15, 52, 72, 1), rgba(15, 32, 52, 1))"
        : "linear-gradient(180deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1))"};
  }

  &:active {
    background: linear-gradient(180deg, rgba(45, 45, 45, 1), rgba(56, 56, 56, 1));
  }

  &:focus {
    outline: none;
  }
`;

export const StartButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;
  cursor: pointer;

  img {
    width: 70%;
    filter: invert(60%) sepia(84%) saturate(4950%) hue-rotate(91deg) brightness(94%) contrast(108%);
  }

  @media (max-width: 450px) {
    height: 49px;
  }
`;

export const OnQuestContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 6px;

  height: 60px;
  cursor: default;

  img {
    width: 70%;
  }

  @media (max-width: 450px) {
    height: 49px;
  }
`;

export const CollectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  cursor: pointer;

  border-radius: 4px;
  img {
    width: 66%;
    filter: brightness(0) saturate(100%) invert(73%) sepia(93%) saturate(765%) hue-rotate(9deg)
      brightness(97%) contrast(90%);
  }

  @media (max-width: 685px) {
    height: 56px;
  }

  @media (max-width: 450px) {
    height: 49px;
  }
`;
