import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState, useEffect } from "react";
import { Data } from "./mockData";
import LineChart from "./LineChart/lineChart";
import BigNumber from "bignumber.js";
import { convertToHumanDate } from "../../../utils/dates";
import Service from "./service";

import { Container, TimeframeButtonsContainer, TimeframeButton } from "./styles";

Chart.register(CategoryScale);

const DexChart = () => {
  const [timeFrame, setTimeFrame] = useState("oneMonth");
  const [chartSubtitle, setChartSubtitle] = useState("One Week Price Chart");
  const [chartData, setChartData] = useState({
    labels: Data.map((data) => convertToHumanDate(data.createdAt)),
    datasets: [
      {
        label: "Price",
        data: Data.map((data) => parseFloat(BigNumber(data.price).div(1e18).toFixed(18))),
        backgroundColor: ["#bfb"],
        borderColor: "white",
        borderWidth: 1,
      },
    ],
  });

  const loadChartData = async (_timeFrame) => {
    const chartData = await Service.getChart(_timeFrame);
    console.log("DexChart::loadChartData | chartData: ", chartData);

    setChartData({
      labels: chartData.labels.map((label) => convertToHumanDate(label)),
      datasets: [
        {
          label: "Price",
          fill: true,
          data: chartData.data.map((data) => parseFloat(BigNumber(data).div(1e18).toFixed(18))),
          backgroundColor: ["#bfb"],
          borderColor: "#5f5",
          borderWidth: 1,
          hoverBorderWidth: 2,
          radius: 1.5,
        },
      ],
      movements: chartData.movements,
    });

    setChartSubtitle(getSubtitle(_timeFrame));
  };

  const getSubtitle = (_timeFrame) => {
    switch (_timeFrame) {
      case "oneDay":
        return "One Day Price Chart";
      case "oneWeek":
        return "One Week Price Chart";
      case "oneMonth":
        return "One Month Price Chart";
      case "allTime":
        return "All Time Price Chart";
      default:
        return "One Week Price Chart";
    }
  };

  const handleTimeframeButtonClick = async (_timeFrame) => {
    setTimeFrame(_timeFrame);
    setChartSubtitle(getSubtitle(_timeFrame));
    loadChartData(_timeFrame);
  }

  // when this page loads, we want to load the chart data for the default time frame
  useEffect(() => {
    loadChartData(timeFrame);
  }, []);

  return (
    <Container>
      <LineChart chartData={chartData} chartSubtitle={chartSubtitle} />
      <TimeframeButtonsContainer>
        <TimeframeButton
          isNegative={chartData?.movements ? chartData.movements["1 day"]?.isNegative : false}
          selected={timeFrame === "oneDay"}
          onClick={() => handleTimeframeButtonClick("oneDay")}
        >
          {chartData?.movements
            ? `1 Day: ${chartData.movements["1 day"].percentageString}`
            : `1 Day`}
        </TimeframeButton>
        <TimeframeButton
          isNegative={chartData?.movements ? chartData.movements["1 week"]?.isNegative : false}
          selected={timeFrame === "oneWeek"}
          onClick={() => handleTimeframeButtonClick("oneWeek")}
        >
          {chartData?.movements
            ? `1 Week: ${chartData.movements["1 week"].percentageString}`
            : `1 Week`}
        </TimeframeButton>
        <TimeframeButton
          isNegative={chartData?.movements ? chartData.movements["1 month"]?.isNegative : false}
          selected={timeFrame === "oneMonth"}
          onClick={() => handleTimeframeButtonClick("oneMonth")}
        >
          {chartData?.movements
            ? `1 Month: ${chartData.movements["1 month"].percentageString}`
            : `1 Month`}
        </TimeframeButton>
        <TimeframeButton
          isNegative={chartData?.movements ? chartData.movements["All time"]?.isNegative : false}
          selected={timeFrame === "allTime"}
          onClick={() => handleTimeframeButtonClick("allTime")}
        >
          {chartData?.movements
            ? `All time: ${chartData.movements["All time"].percentageString}`
            : `All time`}
        </TimeframeButton>
      </TimeframeButtonsContainer>
    </Container>
  );
};

export default DexChart;
