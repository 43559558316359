import styled, { keyframes } from "styled-components";
import CountUp from "react-countup";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1));

  border-radius: 4px;
  padding: 5px;

  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const RewardsOuterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1));
  margin-top: 5px;
  cursor: default;

  border-radius: 4px;
  padding: 5px;

  animation: ${fadeIn} 1s ease-in-out;
`;

export const QuestDetailWrapper = styled.div`
  padding: 0px;
  width: 100%;
  height: 38%;
`;

export const QuestBonusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
  top: 2px;
  min-height: 28px;

  @media (max-width: 450px) {
    top: 0px;
  }
`;

export const RewardsContainer = styled.div`
  width: 100%;
  height: 50%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 2px;
  & > * {
    height: 100%;
  }
`;

export const StyledCountUp = styled(CountUp)`
  font-size: 1.68rem;
  position: relative;
  left: 12px;
  top: 1px;

  @media (max-width: 450px) {
    font-size: 1.6rem;
  }
`;
