import Adventurer from "../../assets/svg/nft-traits/adventurer.svg";
import Artistic from "../../assets/svg/nft-traits/artistic.svg";
import Authoritarian from "../../assets/svg/nft-traits/authoritarian.svg";
import Brave from "../../assets/svg/nft-traits/brave.svg";
import Cleptomaniac from "../../assets/svg/nft-traits/cleptomaniac.svg";
import Clever from "../../assets/svg/nft-traits/clever.svg";
import Clumsy from "../../assets/svg/nft-traits/clumsy.svg";
import Commanding from "../../assets/svg/nft-traits/commanding.svg";
import Cooker from "../../assets/svg/nft-traits/cooker.svg";
import Cultural from "../../assets/svg/nft-traits/cultural.svg";
import Dancer from "../../assets/svg/nft-traits/dancer.svg";
import Driver from "../../assets/svg/nft-traits/driver.svg";
import Drunk from "../../assets/svg/nft-traits/drunk.svg";
import Faithful from "../../assets/svg/nft-traits/faithful.svg";
import Fanatical from "../../assets/svg/nft-traits/fanatical.svg";
import Fancy from "../../assets/svg/nft-traits/fancy.svg";
import Fatigued from "../../assets/svg/nft-traits/fatigued.svg";
import Foodie from "../../assets/svg/nft-traits/foodie.svg";
import Forgetful from "../../assets/svg/nft-traits/forgetful.svg";
import Friendly from "../../assets/svg/nft-traits/friendly.svg";
import Greedy from "../../assets/svg/nft-traits/greedy.svg";
import Heroic from "../../assets/svg/nft-traits/heroic.svg";
import Historical from "../../assets/svg/nft-traits/historical.svg";
import Imposing from "../../assets/svg/nft-traits/imposing.svg";
import Impulsive from "../../assets/svg/nft-traits/impulsive.svg";
import Insomniac from "../../assets/svg/nft-traits/insomniac.svg";
import Joyful from "../../assets/svg/nft-traits/joyful.svg";
import Lawful from "../../assets/svg/nft-traits/lawful.svg";
import Magical from "../../assets/svg/nft-traits/magical.svg";
import Manipulative from "../../assets/svg/nft-traits/manipulative.svg";
import Musical from "../../assets/svg/nft-traits/musical.svg";
import Nimble from "../../assets/svg/nft-traits/nimble.svg";
import Outdoorsy from "../../assets/svg/nft-traits/outdoorsy.svg";
import Scholar from "../../assets/svg/nft-traits/scholar.svg";
import Server from "../../assets/svg/nft-traits/server.svg";
import Shady from "../../assets/svg/nft-traits/shady.svg";
import ShortTempered from "../../assets/svg/nft-traits/short-tempered.svg";
import Slow from "../../assets/svg/nft-traits/slow.svg";
import Smelly from "../../assets/svg/nft-traits/smelly.svg";
import Smug from "../../assets/svg/nft-traits/smug.svg";
import Sporty from "../../assets/svg/nft-traits/sporty.svg";
import Star from "../../assets/svg/nft-traits/star.svg";
import Storyteller from "../../assets/svg/nft-traits/storyteller.svg";
import Stressed from "../../assets/svg/nft-traits/stressed.svg";
import Stubborn from "../../assets/svg/nft-traits/stubborn.svg";
import Unfaithful from "../../assets/svg/nft-traits/unfaithful.svg";
import Uniformed from "../../assets/svg/nft-traits/uniformed.svg";
import Unreliable from "../../assets/svg/nft-traits/unreliable.svg";
import Weirdo from "../../assets/svg/nft-traits/weirdo.svg";
import Workaholic from "../../assets/svg/nft-traits/workaholic.svg";
import Pyromaniac from "../../assets/svg/nft-traits/Pyromaniac.png";
import Runesmith from "../../assets/svg/nft-traits/Runesmith.png";
import Healer from "../../assets/svg/nft-traits/Healer.png";
import Scavenger from "../../assets/svg/nft-traits/Scavenger.png";
import Undead from "../../assets/svg/nft-traits/Undead.png";
import Ghost from "../../assets/svg/nft-traits/Ghost.png";
import Vampire from "../../assets/svg/nft-traits/Vampire.png";
import Zero from "../../assets/svg/nft-traits/zero.svg";
import Random from "../../assets/svg/nft-traits/random.svg";

import { numberToName } from "./01-traits";

export function getSvgTraitById(id) {
  if (id === 0) return Zero;
  if (id === 999) return Random;
  if (id === 998) return Random;

  const trait = numberToName(id, true);
  switch (trait) {
    case "Adventurer":
      return Adventurer;
    case "Artistic":
      return Artistic;
    case "Authoritarian":
      return Authoritarian;
    case "Brave":
      return Brave;
    case "Cleptomaniac":
      return Cleptomaniac;
    case "Clever":
      return Clever;
    case "Clumsy":
      return Clumsy;
    case "Commanding":
      return Commanding;
    case "Cooker":
      return Cooker;
    case "Cultural":
      return Cultural;
    case "Dancer":
      return Dancer;
    case "Driver":
      return Driver;
    case "Drunk":
      return Drunk;
    case "Faithful":
      return Faithful;
    case "Fanatical":
      return Fanatical;
    case "Fancy":
      return Fancy;
    case "Fatigued":
      return Fatigued;
    case "Foodie":
      return Foodie;
    case "Forgetful":
      return Forgetful;
    case "Friendly":
      return Friendly;
    case "Greedy":
      return Greedy;
    case "Heroic":
      return Heroic;
    case "Historical":
      return Historical;
    case "Imposing":
      return Imposing;
    case "Impulsive":
      return Impulsive;
    case "Insomniac":
      return Insomniac;
    case "Joyful":
      return Joyful;
    case "Lawful":
      return Lawful;
    case "Magical":
      return Magical;
    case "Manipulative":
      return Manipulative;
    case "Musical":
      return Musical;
    case "Nimble":
      return Nimble;
    case "Outdoorsy":
      return Outdoorsy;
    case "Scholar":
      return Scholar;
    case "Server":
      return Server;
    case "Shady":
      return Shady;
    case "Short Tempered":
      return ShortTempered;
    case "Slow":
      return Slow;
    case "Smelly":
      return Smelly;
    case "Smug":
      return Smug;
    case "Sporty":
      return Sporty;
    case "Star":
      return Star;
    case "Storyteller":
      return Storyteller;
    case "Stressed":
      return Stressed;
    case "Stubborn":
      return Stubborn;
    case "Unfaithful":
      return Unfaithful;
    case "Uniformed":
      return Uniformed;
    case "Unreliable":
      return Unreliable;
    case "Weirdo":
      return Weirdo;
    case "Workaholic":
      return Workaholic;
    case "Pyromaniac":
      return Pyromaniac;
    case "Runesmith":
      return Runesmith;
    case "Healer":
      return Healer;
    case "Scavenger":
      return Scavenger;
    case "Undead":
      return Undead;
    case "Ghost":
      return Ghost;
    case "Vampire":
      return Vampire;
    case "Random":
      return Random;
    default:
      return Zero;
  }
}
