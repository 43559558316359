export const bonusToBackground = {
  BCT: "linear-gradient(90deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  99: "linear-gradient(90deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  eBCT: "linear-gradient(90deg, rgba(37, 58, 75,1), rgba(80, 125, 160,1))",
  98: "linear-gradient(90deg, rgba(37, 58, 75,1), rgba(80, 125, 160,1))",
  BasicLoot: "linear-gradient(90deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  BL: "linear-gradient(90deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  Loot: "linear-gradient(90deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  AL: "linear-gradient(90deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  Nuts: "linear-gradient(90deg, rgba(117, 89, 21,1), rgba(156, 130, 75,1))",
  10: "linear-gradient(90deg, rgba(117, 89, 21,1), rgba(156, 130, 75,1))",
  Evo: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  1118: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  11: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  12: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  13: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  14: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  15: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  16: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  17: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  18: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  19: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  20: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  21: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  22: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  23: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  24: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  25: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  DreamResources: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  none: "linear-gradient(90deg, rgba(150, 150, 150,1), rgba(120, 120, 120,1))",
  USDC: "linear-gradient(90deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  102: "linear-gradient(90deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  999: "linear-gradient(90deg, #8085c2, #471069);",
};

export const indexToBackground = {
  0: "linear-gradient(90deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  1: "linear-gradient(90deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  2: "linear-gradient(90deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  3: "linear-gradient(90deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  4: "linear-gradient(90deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  5: "linear-gradient(90deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  6: "linear-gradient(90deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  7: "linear-gradient(90deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  8: "linear-gradient(90deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  9: "linear-gradient(90deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  10: "linear-gradient(90deg, rgba(117, 89, 21,1), rgba(156, 130, 75,1))",
  11: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  12: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  13: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  14: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  15: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  16: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  17: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  18: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  19: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  20: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  21: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  22: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  23: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  24: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  25: "linear-gradient(90deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  1118: "linear-gradient(90deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  98: "linear-gradient(90deg, rgba(37, 58, 75,1), rgba(80, 125, 160,1))",
  99: "linear-gradient(90deg, rgba(150, 150, 150,1), rgba(120, 120, 120,1))",
  100: "transparent",
  101: "linear-gradient(90deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  none: "linear-gradient(90deg, rgba(150, 150, 150,1), rgba(120, 120, 120,1))",
  999: "linear-gradient(90deg, #8085c2, #471069);",
};

export const indexToBackgroundInverted = {
  0: "linear-gradient(270deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  1: "linear-gradient(270deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  2: "linear-gradient(270deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  3: "linear-gradient(270deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  4: "linear-gradient(270deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  5: "linear-gradient(270deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  6: "linear-gradient(270deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  7: "linear-gradient(270deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  8: "linear-gradient(270deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  9: "linear-gradient(270deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  10: "linear-gradient(270deg, rgba(117, 89, 21,1), rgba(156, 130, 75,1))",
  11: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  12: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  13: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  14: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  15: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  16: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  17: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  18: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  19: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  20: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  21: "linear-gradient(270deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  22: "linear-gradient(270deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  23: "linear-gradient(270deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  24: "linear-gradient(270deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  25: "linear-gradient(270deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  1118: "linear-gradient(270deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  98: "linear-gradient(270deg, rgba(37, 58, 75,1), rgba(80, 125, 160,1))",
  99: "linear-gradient(270deg, rgba(150, 150, 150,1), rgba(120, 120, 120,1))",
  100: "transparent",
  101: "linear-gradient(270deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  999: "linear-gradient(270deg, #8085c2, #471069);",
};

export const indexToVerticalBackground = {
  0: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  1: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  2: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  3: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  4: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  5: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  6: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  7: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  8: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  9: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  10: "linear-gradient(0deg, rgba(117, 89, 21,1), rgba(156, 130, 75,1))",
  11: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  12: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  13: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  14: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  15: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  16: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  17: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  18: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  19: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  20: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  21: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  22: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  23: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  24: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  25: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  1118: "linear-gradient(0deg, rgba(0, 40, 100,1), rgba(0, 0, 80,1))",
  98: "linear-gradient(0deg, rgba(37, 58, 75,1), rgba(80, 125, 160,1))",
  99: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  100: "transparent",
  101: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  102: "linear-gradient(0deg, rgba(58, 74, 40,1), rgba(128, 161, 94,1))",
  103: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  201: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  202: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  203: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  204: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  205: "linear-gradient(0deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1))",
  206: "linear-gradient(0deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1))",
  207: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  208: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  209: "linear-gradient(0deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1))",
  210: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  214: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  215: "linear-gradient(0deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1))",
  216: "linear-gradient(0deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1))",
  217: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  220: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  221: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  none: "linear-gradient(0deg, rgba(150, 150, 150,1), rgba(120, 120, 120,1))",
  FUEL: "linear-gradient(0deg, rgba(38, 4, 60,1), rgba(108, 61, 104,1))",
  999: "linear-gradient(0deg, #471069, #8085c2);",
};

export const indexToVerticalBackgroundInverted = {
  0: "linear-gradient(180deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  1: "linear-gradient(180deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  2: "linear-gradient(180deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  3: "linear-gradient(180deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  4: "linear-gradient(180deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1))",
  5: "linear-gradient(180deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  6: "linear-gradient(180deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  7: "linear-gradient(180deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  8: "linear-gradient(180deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  9: "linear-gradient(180deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
  10: "linear-gradient(180deg, rgba(117, 89, 21,1), rgba(156, 130, 75,1))",
  11: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  12: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  13: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  14: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  15: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  16: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  17: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  18: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  19: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  20: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  21: "linear-gradient(180deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  22: "linear-gradient(180deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  23: "linear-gradient(180deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  24: "linear-gradient(180deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  25: "linear-gradient(180deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
  1118: "linear-gradient(180deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1))",
  99: "linear-gradient(180deg, rgba(150, 150, 150,1), rgba(120, 120, 120,1))",
  100: "transparent",
  101: "linear-gradient(180deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  201: "linear-gradient(180deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  202: "linear-gradient(180deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  203: "linear-gradient(180deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  204: "linear-gradient(180deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1))",
  205: "linear-gradient(180deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1))",
  206: "linear-gradient(180deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1))",
  207: "linear-gradient(180deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  208: "linear-gradient(180deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  209: "linear-gradient(180deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1))",
  210: "linear-gradient(180deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  214: "linear-gradient(180deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
  999: "linear-gradient(180deg, #8085c2, #471069);",
};

export const indexToVerticalBackground3 = {
  0: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1),rgba(140, 120, 40,1))",
  1: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1),rgba(140, 120, 40,1))",
  2: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1),rgba(140, 120, 40,1))",
  3: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1),rgba(140, 120, 40,1))",
  4: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1),rgba(140, 120, 40,1))",
  5: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1),rgba(157, 92, 16,1))",
  6: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1),rgba(157, 92, 16,1))",
  7: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1),rgba(157, 92, 16,1))",
  8: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1),rgba(157, 92, 16,1))",
  9: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1),rgba(157, 92, 16,1))",
  10: "linear-gradient(0deg, rgba(117, 89, 21,1), rgba(156, 130, 75,1),rgba(117, 89, 21,1))",
  11: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  12: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  13: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  14: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  15: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  16: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  17: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  18: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  19: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  20: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  21: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1),rgba(60, 20, 60,1))",
  22: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1),rgba(60, 20, 60,1))",
  23: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1),rgba(60, 20, 60,1))",
  24: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1),rgba(60, 20, 60,1))",
  25: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1),rgba(60, 20, 60,1))",
  1118: "linear-gradient(0deg, rgba(30, 30, 40,1), rgba(80, 80, 90,1),rgba(30, 30, 40,1))",
  98: "linear-gradient(0deg, rgba(37, 58, 75,1), rgba(80, 125, 160,1),rgba(37, 58, 75,1))",
  99: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1),rgba(48, 74, 50,1))",
  100: "transparent",
  101: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1),rgba(0, 100, 100,1))",
  102: "linear-gradient(0deg, rgba(58, 74, 40,1), rgba(128, 161, 94,1),rgba(58, 74, 40,1))",
  103: "linear-gradient(0deg, rgba(140, 120, 40,1), rgba(188, 164, 40,1),rgba(140, 120, 40,1))",
  201: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1),rgba(0, 100, 100,1))",
  202: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1),rgba(0, 100, 100,1))",
  203: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1),rgba(0, 100, 100,1))",
  204: "linear-gradient(0deg, rgba(0, 100, 100,1), rgba(0, 150, 150,1),rgba(0, 100, 100,1))",
  205: "linear-gradient(0deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1),rgba(48, 4, 50,1))",
  206: "linear-gradient(0deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1),rgba(48, 4, 50,1))",
  207: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1),rgba(48, 74, 50,1))",
  208: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1),rgba(48, 74, 50,1))",
  209: "linear-gradient(0deg, rgba(48, 4, 50,1), rgba(108, 61, 104,1),rgba(48, 4, 50,1))",
  210: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1),rgba(48, 74, 50,1))",
  214: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1),rgba(48, 74, 50,1))",
  999: "linear-gradient(0deg, #8085c2, #471069);",
};

export const indexToTooltip = {
  0: "Super Cheese: improves Rank and Agility of mice",
  1: "Shiny Fish: improves Rank and Agility of cats",
  2: "Groovy Grass: improves Rank and Agility of cows",
  3: "Happy Roots: improves Rank and Agility of elephants",
  4: "Bang Bananas: improves Rank and Agility of apes",
  5: "Exotic Cheese: buys extra squads",
  6: "Cat Milk: buys extra beast slots in squads you own",
  7: "Magic Mushrooms: adds extra traits to your beasts",
  8: "Power Mangoes: replaces one beast during an integration",
  9: "Ape Tools: greatly improves your crafting efficiency",
  10: "Golden Nuts: teaches your beasts how to farm Evolution Items",
  11: "Gene Therapy: evolves your beasts and rerolls their traits",
  12: "Mouse Box: there is a mouse inside",
  13: "Cat Box: there is a cat inside",
  14: "Mouse DNA: evolves your mice and creates collections",
  15: "Cat DNA: evolves your cats and creates collections",
  16: "Cow DNA: evolves your cows and creates collections",
  17: "Elephant DNA: evolves elephants and creates collections",
  18: "Ape DNA: evolves your apes, great for melting",
  19: "Cow Box: there is a cow inside",
  20: "Elephant Box: there is an elephant inside",
  21: "Dragon Scales: transforms your mice into dragons",
  22: "Griffin Feathers: transforms your cats into griffins",
  23: "Satyr Horns: transforms your cows into satyrs",
  24: "Dinosaur Skulls: transforms your elephants into dinosaurs",
  25: "AI Chip: lets you master all aspects of farming",
  1118: "Evolution Items: used to evolve and generate new beasts",
  98: "eBCT: pays for in-game products and services",
  eBCT: "eBCT: it's BCT but can only be spent in the game",
  99: "Beast Community Token (BCT): can be exchanged for USDC",
  BCT: "Beast Community Token: can be exchanged for USDC",
  101: "Stashed BCT: locked for 180 days giving you global bonuses",
  BL: "Basic Loot: they improve your beasts' Rank and Agility",
  AL: "Advanced Loot: they have special uses and are very valuable",
  999: "Kozi is the Gas token of the Kingdom Chain",
};

export const indexToDescription = {
  0: "Farmed by mice as Basic Loot. Use Super Cheese to improve Rank and Agility of mice.",
  1: "Farmed by cats as Basic Loot. Use Shiny Fish to improve Rank and Agility of cats.",
  2: "Farmed by cows as Basic Loot. Use Groovy Grass to improve Rank and Agility of cows.",
  3: "Farmed by elephants as Basic Loot. Use Happy Roots to improve Rank and Agility of elephants.",
  4: "Farmed by apes as Basic Loot. Use Bang Bananas to improve Rank and Agility of apes.",
  5: "Farmed by mice as Advanced Loot. Use Exotic Cheese to acquire powerful extra squads.",
  6: "Farmed by cats as Advanced Loot. Use Cat Milk to buy extra beast slots in squads you own.",
  7: "Farmed by cows as Advanced Loot. Use Magic Mushrooms to add extra traits to your beasts.",
  8: "Farmed by elephants as Advanced Loot. Use Power Mangoes to optimize integrations.",
  9: "Farmed by apes as Advanced Loot. Use Ape Tools to gain fantastic temporary bonuses.",
  10: "Farmed by all. Use Golden Nuts to teach your beasts how to farm Gene Therapy, Boxes and DNA.",
  11: "Farmed by all. Use Gene Therapy to evolve your beasts and reroll their traits.",
  12: "Farmed by all. Mint common, rare, epic, legendary and mythic mice from these boxes.",
  13: "Farmed by all. Mint common, rare, epic, legendary and mythic cats from these boxes.",
  14: "Farmed by all. Use Mouse DNA to evolve your mice.",
  15: "Farmed by all. Use Cat DNA to evolve your mice and cats.",
  16: "Farmed by all. Use Cow DNA to evolve your cats and cows.",
  17: "Farmed by all. Use Elephant DNA to evolve your cows and elephants.",
  18: "Farmed by all. Use Ape DNA to evolve your elephants and apes.",
  19: "Mint common, rare, epic, legendary and mythic cows from these boxes.",
  20: "Mint common, rare, epic, legendary and mythic elephants from these boxes.",
  21: "Use them to enter special quests, create collections, and to transform mice into dragons.",
  22: "Use them to create collections and to transform cats into griffins.",
  23: "Use them to create collections and to transform cows into satyrs.",
  24: "Use them to create collections and to transform elephants into dinosaurs.",
  25: "Use them to master all aspects of farming.",
  1118: "Evolution Items: used to evolve your beasts",
  98: "eBCT: it's BCT but can only be spent in the game",
  eBCT: "eBCT: it's BCT but can only be spent in the game",
  99: "Beast Community Token (BCT): can be exchanged for USDC",
  BCT: "Beast Community Token: can be exchanged for USDC",
  101: "Stashed BCT: locked for 180 days giving you global bonuses",
  BL: "Basic Loot: they improve your beasts' Rank and Agility",
  AL: "Advanced Loot: they have special uses and are very valuable",
};

export const MktplaceDragonBonus = 100; // for every X busd cents, both buyer and seller gain 0.01 Dragon Scales

// "Service" here is Magnus via AWS API Gateway:
export const SERVICE_URL = "https://fw6gclbtpd.execute-api.us-east-2.amazonaws.com";
//export const SERVICE_URL = "http://localhost:3001";
export const GET_ORDERS_ENDPOINT = "/getOrders";
export const GET_ORDERS_BY_RESOURCE_ID_ENDPOINT = "/getOrdersByResourceId";
export const GET_BEST_RESOURCE_ORDERS_ENDPOINT = "/getBestResourceOrders";
export const GET_ORDERS_BY_SELLER_ENDPOINT = "/getOrdersBySeller";
export const GET_BEAST_ORDERS_ENDPOINT = "/getBeastOrdersWithProps";
export const GET_DUTCH_ACUTIONS_ENDPOINT = "/getDutchAuctions";
export const GET_OUTSTANDING_CLAIM_ENDPOINT = "/getOutstandingClaimOf";
export const GET_CHART_ENDPOINT = "/getChart";
export const GET_KOZI_CHART_ENDPOINT = "/getKoziChart";
export const GET_SCALES_ENDPOINT = "/getScales";

export const MentorLevels = {
  0: { level: 0, stashedBct: 0, depositBonus: 0 },
  1: { level: 1, stashedBct: 50, depositBonus: 0.5 },
  2: { level: 2, stashedBct: 100, depositBonus: 1 },
  3: { level: 3, stashedBct: 150, depositBonus: 1.5 },
  4: { level: 4, stashedBct: 200, depositBonus: 2 },
  5: { level: 5, stashedBct: 250, depositBonus: 2.5 },
  6: { level: 6, stashedBct: 500, depositBonus: 3 },
  7: { level: 7, stashedBct: 1000, depositBonus: 3.5 },
  8: { level: 8, stashedBct: 2500, depositBonus: 4 },
  9: { level: 9, stashedBct: 5000, depositBonus: 4.5 },
  10: { level: 10, stashedBct: 10000, depositBonus: 5 },
};

export const StasherLevels = {
  0: { level: 0, stashedBct: 0, depositBonus: 0 },
  1: { level: 1, stashedBct: 50, depositBonus: 2 },
  2: { level: 2, stashedBct: 100, depositBonus: 4 },
  3: { level: 3, stashedBct: 150, depositBonus: 6 },
  4: { level: 4, stashedBct: 200, depositBonus: 8 },
  5: { level: 5, stashedBct: 250, depositBonus: 10 },
  6: { level: 6, stashedBct: 500, depositBonus: 12 },
  7: { level: 7, stashedBct: 1000, depositBonus: 14 },
  8: { level: 8, stashedBct: 2500, depositBonus: 16 },
  9: { level: 9, stashedBct: 5000, depositBonus: 18 },
  10: { level: 10, stashedBct: 10000, depositBonus: 20 },
};

export const stasherLevelByBalance = (balance) => {
  if (balance < 50) return 0;
  if (balance < 100) return 1;
  if (balance < 150) return 2;
  if (balance < 200) return 3;
  if (balance < 250) return 4;
  if (balance < 500) return 5;
  if (balance < 1000) return 6;
  if (balance < 2500) return 7;
  if (balance < 5000) return 8;
  if (balance < 10000) return 9;
  return 10;
};

export const GLOBAL_KOZI_DISCOUNT_PCT = 50; // % discount for purchases made with KOZI
