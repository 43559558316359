import axios from "axios";
import TransactionModel from "../models/TransactionModel";
import ChainModel from "../models/ChainModel";
import BlockModel from "../models/BlockModel";
import AccountModel from "../models/AccountModel";

const USE_LOCAL_INDEXER = false;
const INDEXER_URL =
  window.location.href.indexOf("localhost") !== -1 && USE_LOCAL_INDEXER
    ? "http://localhost:8544/rpc"
    : "https://kingdomchain.observer/travelsong/rpc";

class Rpc {
  constructor() {
    const chainModel = new ChainModel({
      name: "Kingdom Chain",
      shortName: "Kingdom",
      chainId: 39916801,
      nativeCurrency: {
        name: "Kozi",
        symbol: "KOZI",
        decimals: 18,
      },
      rpc: "https://kingdomchain.observer/rpc",
      indexer: INDEXER_URL,
    });
    this.setChain(chainModel);
  }

  setChain(chainModel) {
    this.chain = chainModel;
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }

  async fetchFullTx(txHash) {
    // we'll make two calls, one for the pre-mine data, another for the receipt data
    let txData, receiptData;

    try {
      txData = await this.fetchTx(txHash);
    } catch (e) {
      console.log("Rpc Error::fetchTx | e: ", e);
      return null;
    }

    if (!txData) return null;

    try {
      receiptData = await this.fetchTxReceipt(txHash);
    } catch (e) {
      console.log("Rpc Error::fetchTxReceipt | e: ", e);
      return null;
    }

    // we'll return a well-structured TransactionModel
    const txn = new TransactionModel(txData, receiptData);
    return txn;
  }

  async fetchTx(txHash) {
    const method = "eth_getTransactionByHash";

    const rpcObject = {
      method,
      params: [txHash],
      id: 1,
      jsonrpc: "2.0",
    };

    const rpcResponse = await axios.post(this.chain.rpc, rpcObject);
    const txData = rpcResponse.data.result;

    return txData;
  }

  async fetchTxReceipt(txHash) {
    const method = "eth_getTransactionReceipt";

    const rpcObject = {
      method,
      params: [txHash],
      id: 1,
      jsonrpc: "2.0",
    };

    const rpcResponse = await axios.post(this.chain.rpc, rpcObject);
    const receiptData = rpcResponse.data.result;

    return receiptData;
  }

  async fetchBlock(blockNumber) {
    const method = "eth_getBlockByNumber";

    const rpcObject = {
      method,
      params: [blockNumber, true],
      id: 1,
      jsonrpc: "2.0",
    };

    const rpcResponse = await axios.post(this.chain.rpc, rpcObject);
    const blockData = rpcResponse.data.result;

    const block = new BlockModel(blockData);
    return block;
  }

  async fetchLatestBlock() {
    const method = "eth_getBlockByNumber";

    const rpcObject = {
      method,
      params: ["latest", false],
      id: 1,
      jsonrpc: "2.0",
    };

    const rpcResponse = await axios.post(this.chain.rpc, rpcObject);
    const blockData = rpcResponse.data.result;

    console.log("RECEIVED BLOCK DATA:", blockData);

    const block = new BlockModel(blockData);
    return block;
  }

  // Kozi is the network token, as ETH is to Ethereum
  async fetchKoziBalanceOf(address) {
    const method = "eth_getBalance";

    const rpcObject = {
      method,
      params: [address, "latest"],
      id: 1,
      jsonrpc: "2.0",
    };

    const rpcResponse = await axios.post(this.chain.rpc, rpcObject);
    const balance = rpcResponse.data.result;

    return balance;
  }

  async fetchTxListOf(address, page = 1, includeEvents = false) {
    try {
      const result = await axios.post(this.chain.indexer, {
        method: "txsByAddress",
        params: { address, page, includeEvents },
      });

      if (!result?.data?.success)
        throw new Error("Service Error on fetchTranslactionListOf: !result.success");

      if (result?.data?.txList) {
        return {
          list: result.data.txList,
          total: result.data?.totalCount,
          currentBlockHeight: result.data?.currentBlockHeight,
        };
      } else {
        throw new Error("Service Error on fetchTranslactionListOf: cannot get data");
      }
    } catch (e) {
      console.log("Rpc Error::fetchTxListOf | e: ", e);
      return [];
    }
  }

  async fetchLatestSummary() {
    try {
      const result = await axios.post(this.chain.indexer, {
        method: "latestSummary",
        params: {
          limit: 10,
        },
      });

      if (!result?.data?.success)
        throw new Error("Service Error on fetchLatestBlockAndTransactions: !result.success");

      if (result?.data?.txList) {
        return {
          block: new BlockModel(result.data.block),
          txList: result.data.txList.map((tx) => {
            return {
              ...tx,
              action: new AccountModel().parseAction(tx),
            };
          }),
        };
      } else {
        throw new Error("Service Error on fetchTranslactionListOf: cannot get data");
      }
    } catch (e) {
      console.log("Rpc Error::fetchLatestBlockAndTransactions | e: ", e);
      return [];
    }
  }

  async fetchKingdomNobles() {
    try {
      const result = await axios.post(this.chain.indexer, {
        method: "topAddresses",
        params: { limit: 25 },
      });

      if (!result?.data?.success)
        throw new Error("Service Error on fetchKingdomNobles: !result.success");

      if (result?.data?.addresses) {
        return {
          nobles: result.data.addresses,
        };
      } else {
        throw new Error("Service Error on fetchKingdomNobles: cannot get data");
      }
    } catch (e) {
      console.log("Rpc Error::fetchKingdomNobles | e: ", e);
      return [];
    }
  }
}

const rpc = new Rpc();
export default rpc;
