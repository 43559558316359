import styled from "styled-components";
import CardShield from "./CardShield";
import CardType from "./CardType";
import CardAgility from "./CardAgility";

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: ${(props) => (props.scale ? `${10 * props.scale}px` : `10px`)};
  left: ${(props) => (props.scale ? `${6 * props.scale}px` : `6px`)};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CardAttributes = ({ nft, scale }) => {
  return (
    <Container scale={scale}>
      <CardType type={nft.type_} rarity={nft.rarity} scale={scale} />
      <CardShield rarity={nft.rarity} rank={nft.rank} scale={scale} />
      <CardAgility rarity={nft.rarity} agility={nft.agility} scale={scale} />
    </Container>
  );
};

export default CardAttributes;
