import BigNumber from "bignumber.js";

export const toWei = (amount, rounding = BigNumber.ROUND_DOWN) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  return BigNumber(amount).times(1e18).toFixed(0, rounding);
};

export const fromWei = (amount, decimals = 2, rounding = BigNumber.ROUND_DOWN) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  return BigNumber(amount).div(1e18).toFixed(decimals, rounding);
};
