import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Container, TraitItem, TraitImage } from "./styles";
import { getSvgTraitById } from "../../../config/resources/07-traits-to-svg";
import { allTraits } from "../../../config/resources/01-traits.js";

const ORIGINAL_TRAIT_LIST = allTraits(true).sort((a, b) => a.name.localeCompare(b.name));
let TRAIT_LIST = [...ORIGINAL_TRAIT_LIST];

const TraitSelectDropdown = ({
  traitSelectCallback = null,
  injectTraitZero = false,
  traitPickerForceTraits = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [selectedTraitName, setSelectedTraitName] = useState("Trait");

  const setup = () => {
    if (traitPickerForceTraits.length > 0) {
      // transform TRAIT_LIST to only include the traits we want
      TRAIT_LIST = TRAIT_LIST.filter((trait) => traitPickerForceTraits.includes(trait.id));
    } else {
      if (TRAIT_LIST.length < ORIGINAL_TRAIT_LIST.length) {
        TRAIT_LIST = ORIGINAL_TRAIT_LIST;
      }

      if (injectTraitZero) {
        if (TRAIT_LIST[0].id === 999) return;
        // Inject trait zero
        TRAIT_LIST = [
          {
            id: 999,
            name: "Any Trait",
          },
          ...TRAIT_LIST,
        ];
      } else {
        // see if we need to remove trait zero
        if (TRAIT_LIST[0].id !== 999) return;
        TRAIT_LIST = TRAIT_LIST.slice(1);
      }
    }
  };
  setup();

  const onTraitSelect = (trait) => {
    setSelectedTrait(trait.id);
    setSelectedTraitName(trait.name);
    setIsOpen(false);

    if (traitSelectCallback) {
      traitSelectCallback(trait.id);
    }
  };

  const dropDownForceStyle = {
    minWidth: "280px",
  };

  return (
    <Container itemCount={TRAIT_LIST.length}>
      <div className="dropdown" style={dropDownForceStyle}>
        <Link
          to="#"
          className="btn-selector nolink"
          onClick={() => setIsOpen(!isOpen)}
          style={{ padding: "5px" }}
        >
          {selectedTrait !== null && <TraitImage src={getSvgTraitById(selectedTrait)} />}{" "}
          {selectedTraitName}
        </Link>
        {isOpen && (
          <ul style={{ overflowY: "auto" }}>
            {TRAIT_LIST.map((trait, index) => (
              <li key={index}>
                <TraitItem onClick={() => onTraitSelect(trait)}>
                  <TraitImage src={getSvgTraitById(trait.id)} /> {trait.name}
                </TraitItem>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Container>
  );
};

export default TraitSelectDropdown;
