import BigNumber from "bignumber.js";
import React from "react";
import { Tooltip } from "react-tooltip";
import { ethers } from "ethers";
import Contracts from "../../../utils/Contracts";
import { indexToName } from "../../../config/resources/04-resources-full-list";
import { numberToName } from "../../../config/resources/01-traits";

import { Container, Text, Badge } from "./styles";
import { indexOf, startsWith } from "lodash";
const fromWei = (input) => {
  // if there is a "." in the input, we'll assume it's already in ETH
  if (input.toString().includes(".")) {
    return input;
  }

  const formatted = BigNumber(input).toString();

  // if the last characters are ".0", we'll remove them
  if (formatted.substring(formatted.length - 2, formatted.length) === ".0") {
    return formatted.substring(0, formatted.length - 2);
  }
};

const ItemValue = ({
  value,
  isFrom = false,
  isTo = false,
  isAction = false,
  isTxHash = false,
  isBlockNumber = false,
  onClick = null,
  showPlainAddress = false,
  toLocaleString = false,
  ignorePrettyWeiFormat = false,
  isPrice = false,
  forceContainerMarginTop = false,
  isResourceArray = false,
  isTraitNumber = false,
  isEbctPlusResourceArray = false,
}) => {
  const contractConfig = Contracts.addressToConfig(value);

  const isSmartContract = contractConfig
    ? contractConfig.address === "0x0000000000000000000000000000000000000000"
      ? false
      : true
    : false;

  // check if there are only numbers in the value
  const isNumberInWei = value
    ? value.length >= 10 && !isNaN(value) && !startsWith(value, "0x") && indexOf(value, ".") === -1
    : false;

  const isZeroAddress = contractConfig?.name === "Zero Address";

  let append = null;
  if (isZeroAddress) {
    if (isFrom) {
      append = " (MINT)";
    } else if (isTo) {
      append = " (BURN)";
    }
  }

  function getIsAddress() {
    try {
      ethers.getAddress(value);
      return true;
    } catch (e) {
      return false;
    }
  }

  function handleClick() {
    if (onClick) {
      onClick({ value, isFrom, isTo, isAction, isTxHash });
    } else {
      console.log(`ItemValue::User clicked ${value}, but no onClick function was provided`);
    }
  }

  function isClickable() {
    // must be an address or a transaction hash
    return getIsAddress() || isTxHash;
  }

  function prettifiedAddress() {
    // we'll return an HTML where the first 4 after the 0x and last 4 characters are in BOLD
    const firstFour = value.substring(2, 7);
    const lastFour = value.substring(value.length - 5, value.length);
    const middle = value.substring(7, value.length - 5);
    return (
      <div>
        <span>
          <span style={{ color: "#aaa" }}>0x</span>
          <strong style={{ color: "white" }}>{firstFour}</strong>
          <span style={{ color: "#aaa" }}>{middle}</span>
          <strong style={{ color: "white" }}>{lastFour}</strong>
        </span>
      </div>
    );
  }

  function prettifiedEtherValue(value) {
    if (isNumberInWei) {
      let _value = ethers.formatEther(value);
      return _value;
    } else {
      return value;
    }
  }

  function parseResourceArray() {
    let valueArray = value.split(",");
    valueArray = valueArray.map((v) => parseInt(v));
    let resources = [];
    let currentlyAdded = 0;

    // if all values are 0, we'll return an HTML saying "None"
    if (
      valueArray.length === 0 ||
      valueArray.every((v) => v === 0) ||
      valueArray.every((v) => isNaN(v))
    ) {
      return <span style={{ color: "#FFF" }}>None</span>;
    }

    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[i] === 0) continue;

      resources.push(
        <div key={i} style={{ marginTop: `${currentlyAdded === 0 ? "0" : "4"}px` }}>
          <span style={{ color: "#FFF" }}>
            {valueArray[i] / 100} {indexToName(i)}
          </span>
        </div>
      );

      currentlyAdded++;
    }
    return resources;
  }

  // here, the very first value is eBct, and the rest are resources
  // we want to return an array of divs with both eBct and resources
  function parseEbctAndResourceArray() {
    let valueArray = value.split(",");
    let ebct = valueArray[0];
    let resources = valueArray.slice(1);
    let resourcesArray = [];
    let currentlyAdded = 0;

    if (ebct === 0 && resources.every((v) => v === 0)) {
      return <span style={{ color: "#FFF" }}>None</span>;
    }

    resourcesArray.push(
      <div key={0} style={{ marginTop: `${currentlyAdded === 0 ? "0" : "4"}px` }}>
        <span style={{ color: "#FFF" }}>
          {ethers.formatEther(ebct)} eBCT
        </span>
      </div>
    );

    currentlyAdded++;

    for (let i = 0; i < resources.length; i++) {
      if (resources[i] === 0 || resources[i] === "0") continue;

      resourcesArray.push(
        <div key={i + 1} style={{ marginTop: `${currentlyAdded === 0 ? "0" : "4"}px` }}>
          <span style={{ color: "#FFF" }}>
            {resources[i] / 100} {indexToName(i)}
          </span>
        </div>
      );

      currentlyAdded++;
    }

    return resourcesArray;
  }

  function parseTraitNumber() {
    if (parseInt(value) === 0) {
      return <span style={{ color: "#FFF" }}>None</span>;
    } else if (parseInt(value) === 999) {
      return <span style={{ color: "#FFF" }}>Random</span>;
    } else {
      return (
        <span style={{ color: "#FFF" }}>
          {numberToName(parseInt(value), true)} ({value})
        </span>
      );
    }
  }

  return (
    <Container forceContainerMarginTop={forceContainerMarginTop}>
      {(contractConfig || isAction || isTxHash) && !showPlainAddress ? (
        <Badge
          isZeroAddress={isZeroAddress}
          isAction={isAction}
          isTxHash={isTxHash}
          onClick={isClickable() ? handleClick : null}
        >
          {contractConfig?.name || value}
          {append}{" "}
        </Badge>
      ) : getIsAddress() ? (
        <Badge isAddress={true} onClick={isClickable() ? handleClick : null}>
          {prettifiedAddress()}
        </Badge>
      ) : (
        <Text>
          {isResourceArray ? (
            parseResourceArray()
          ) : isTraitNumber ? (
            parseTraitNumber()
          ) : isNumberInWei && !ignorePrettyWeiFormat ? (
            <span style={{ color: "#FFF" }}>{`${
              toLocaleString
                ? parseFloat(prettifiedEtherValue(value)).toLocaleString("en-US", {
                    minimumFractionDigits: isPrice ? 18 : 18,
                    maximumFractionDigits: isPrice ? 18 : 18,
                  })
                : prettifiedEtherValue(value)
            }`}</span>
          ) : isEbctPlusResourceArray ? (
            parseEbctAndResourceArray()
          ) : (
            value
          )}
        </Text>
      )}
    </Container>
  );
};

export default ItemValue;
