import { useEffect, useState } from "react";
import Messenger from "../../utils/Messenger";
import BigNumber from "bignumber.js";
import { Tooltip } from "react-tooltip";
import { format } from "../../utils/math";
import { toWei } from "../../utils/wei";

import CardImage from "./CardImage";
import CardAttributes from "./CardAttributes";
import CardTraits from "./CardTraits";
import CardSkillsRight from "./CardSkillsRight";
import CardSkillsLeft from "./CardSkillsLeft";
import CardProgressBar from "./CardProgressBar";
import silverStar from "../../assets/images/squads-card/silver-star.png";
import goldenStar from "../../assets/images/squads-card/golden-star.png";
import deadStamp from "../../assets/images/squads-card/dead-stamp.png";
import fireOverlayImage from "../../assets/images/squads-card/fire-anim-transparent.gif";

import { useWeb3Context } from "../../contexts/Web3Context";

import { getDefaultImage } from "./CardImage";
import { getCustomBorder } from "../../config/resources/08-squad-cards";
import { realQuestId } from "../../utils/quests";
import plusCat from "../../assets/images/squads/plus-cat.png";
import plusMouse from "../../assets/images/squads/plus-mouse.png";
import plusMix from "../../assets/images/squads/plus-mixed.png";
import padlock from "../../assets/images/squads/padlock.png";
import sacrifice from "../../assets/images/squads/sacrifice.png";
import sell from "../../assets/images/squads/sell.png";

import questionMark from "../../assets/images/squads/question-mark.png";
import { resourceToImagePath } from "../../config/resources/09-resources-images";
import { indexToName } from "../../config/resources/04-resources-full-list";
import { indexToVerticalBackground } from "../../utils/constants";
import { isMaxedOut } from "../../config/resources/08-squad-cards";

import { numberToName as getRarity } from "../../config/resources/05-rarity";
import { numberToName as getType } from "../../config/resources/06-type_";
import { numberToName as getTrait } from "../../config/resources/01-traits";

import { useLocation, useNavigate } from "react-router-dom";
import { useKingdomQuestsContext } from "../../contexts/KingdomQuestsContext";
import { useUpgradesContext } from "../../contexts/UpgradesContext";

import promoStyle1 from "../../assets/images/squads-card/promo-overlay-1.png";
import promoStyle2 from "../../assets/images/squads-card/promo-overlay-4.png";
import promoStyle3 from "../../assets/images/squads-card/promo-overlay-5.png";
import promoStyle4 from "../../assets/images/squads-card/promo-overlay-6.png";
import promoStyle5 from "../../assets/images/squads-card/promo-overlay-7.png";
import promoStyle6 from "../../assets/images/squads-card/promo-overlay-8.png";
import { isPromoNft, getPromoInfo } from "./specialNfts";
import { isPromoCollection } from "./specialCollections";

import revivedOverlay from "../../assets/images/squads-card/revived.png";

import {
  GridItem,
  ClickableOverlay,
  ImageEmptyModel,
  NftId,
  QuestTag,
  SquadTag,
  NftName,
  NutsAndEvoContainer,
  FarmContainer,
  BaseFarm,
  FarmPerBlock,
  Star,
  BottomDataContainer,
  DeadStamp,
  FireOverlay,
  CollectionOverlay,
  CollectionGlow,
  RevivedOverlayContainer,
} from "./styles";

import { InfluencerOverlay } from "./promoFramesStyles";

const Card = ({
  nft = null,
  squadSynergies = [],
  collections = [],
  slotUnlocked = false,
  squadType = 6,
  scale = 1,
  extraOutline = false,
  extraOutlineColor = "#ffffff",
  clickToSelectNftFromInventory = false,
  clickToResetFreeSelection = false,
  clickToSell = false,
  reasonToSelect = "sacrifice",
  suppressTooltips = false,
  supressInteraction = false,
  lockSize = false,
}) => {
  const { stableToTargetKozi } = useWeb3Context();
  const [_nft, setNft] = useState(nft);
  const [_scale, setScale] = useState(scale);
  const [_extraOutline, setExtraOutline] = useState(extraOutline);
  const [_extraOutlineColor, setExtraOutlineColor] = useState(extraOutlineColor);

  const [paddingTop, setPaddingTop] = useState("40px");
  const [hasCustomBorder, setHasCustomBorder] = useState(false);
  const [needPadding, setNeedPadding] = useState(false);
  const [borderImage, setBorderImage] = useState("");
  // const [borderColor, setBorderColor] = useState('')
  const [backGroundColor, setBackGroundColor] = useState("");
  const [isClickable, setIsClickable] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { addToSquad } = useKingdomQuestsContext();
  const { setCurrentlySelectedPerkIndex } = useUpgradesContext();

  const isItMaxedOut = isMaxedOut(_nft);

  useEffect(() => {
    setNft(nft);
  }, [nft]);

  useEffect(() => {
    if (!_nft) return;

    setBorderAndBackground();

    if (
      (location.pathname.indexOf(`/beast/`) === -1 &&
        location.pathname.indexOf(`/collections`) === -1 &&
        location.pathname.indexOf(`/home`) === -1 &&
        !supressInteraction) ||
      clickToSelectNftFromInventory
    ) {
      setIsClickable(true);
    } else {
      setIsClickable(false);
    }
  }, [nft, isItMaxedOut]);

  const setBorderAndBackground = () => {
    if (!nft) return;

    const [hasBorder, hasPadding, hasImage] = getCustomBorder(
      nft,
      collections.includes(nft?.collection)
    );
    setHasCustomBorder(hasBorder);
    setNeedPadding(hasPadding);
    setBorderImage(hasImage);
    setBackGroundColor("#25235500");
  };

  useEffect(() => {
    const updatePaddingTop = () => {
      if (window.innerWidth < 450) {
        setPaddingTop("14px");
      } else {
        setPaddingTop("40px");
      }
    };

    window.addEventListener("resize", updatePaddingTop);

    // Ensure the padding top is correct when the component mounts
    updatePaddingTop();

    // Clean up listener when the component unmounts
    return () => window.removeEventListener("resize", updatePaddingTop);
  }, []);

  const handleMouseOver = () => {
    //setScale(0.99);

    if (!isClickable && !clickToSelectNftFromInventory) return;

    if (extraOutline) {
      //setExtraOutlineColor("#00ff00");
    } else {
      setExtraOutline(true);
    }
  };

  const handleMouseOut = () => {
    //setScale(scale);

    if (!isClickable && !clickToSelectNftFromInventory && !clickToResetFreeSelection) return;

    if (extraOutline) {
      //setExtraOutlineColor(extraOutlineColor);
    } else {
      setExtraOutline(false);
    }
  };

  const selectNft = async () => {
    if (!isClickable && !clickToSelectNftFromInventory && !clickToResetFreeSelection) return;

    if (clickToSell) {
      setCurrentlySelectedPerkIndex(null);
      navigate("/beasts", {
        state: {
          selectedNfts: [],
          forbiddenNftIds: [],
          requiredNftFeatures: [],
          pickToSell: true,
        },
      });

      return;
    }

    if (clickToResetFreeSelection) {
      // we're probably in the Upgrades page, trying to swap the main selected NFT
      // we need to clear the currently selected perk index, as this is a new NFT to upgrade
      // we also need to clear the selected NFTs array, as we're starting from scratch
      // the we go to the beasts page for the user to select anew
      setCurrentlySelectedPerkIndex(null);
      navigate("/beasts", {
        state: {
          selectedNfts: [],
          forbiddenNftIds: [],
          requiredNftFeatures: [],
        },
      });

      return;
    }

    if (!clickToSelectNftFromInventory) {
      if (location.state?.hasOwnProperty("squadId")) {
        // we are ON THE INVENTORY coming from the squads page;
        // We are trying to add an NFT to a squad
        selectNftFromInventoryAndAddToSquad();
      } else {
        if (location.state?.hasOwnProperty("pickAnyNft")) {
          // We're ON THE INVENTORY coming from an unknown context
          // That would like us to pick an NFT and return to it
          selectAndAddToSelectedNfts();
        } else if (location.state?.hasOwnProperty("pickToSell")) {
          // We're on the inventory looking for a beast to sell
          selectNftFromInventoryAndSell();
        } else {
          // We're ON THE INVENTORY coming from free navigation
          // Clicking an NFT here will take us to the upgrades page
          // we'll first clear the currently selected perk index, as this is a new NFT to upgrade
          setCurrentlySelectedPerkIndex(null);
          selectNftFromInventoryAndUpgrade();
        }
      }
    } else {
      if (location.state?.pickAnyNft) {
        // We're ON THE INVENTORY coming from an unknown context
        // That would like us to pick an NFT and return to it
        selectAndAddToSelectedNfts();
      } else {
        // this is a free slot; we may be anywhere, doing whatever,
        // just want to pick an NFT for whatever context, let's go to the inventory

        // if there is already an NFT here, we have to remove it from the selectedNfts array
        // otherwise we'll end up with duplicates
        const selectedNfts = location.state?.selectedNfts || [];
        const index = selectedNfts.findIndex((nft) => (nft ? nft.id === _nft?.id : -1));
        if (index > -1) {
          selectedNfts[index] = null;
        }

        // now also remove from the forbiddenNftIds array
        const forbiddenNftIds = location.state?.forbiddenNftIds || [];
        const index2 = forbiddenNftIds.findIndex((id) => id === _nft?.id);
        if (index2 > -1) {
          forbiddenNftIds.splice(index2, 1);
        }

        let requiredNftFeatures = location.state?.requiredNftFeatures || {};
        const isSpeciesSac =
          reasonToSelect === "sacrifice-mouse" ||
          reasonToSelect === "sacrifice-cat" ||
          reasonToSelect === "sacrifice-cow" ||
          reasonToSelect === "sacrifice-elephant" ||
          reasonToSelect === "sacrifice-ape";

        if (isSpeciesSac) {
          requiredNftFeatures.notOnQuest = true;
        }

        switch (reasonToSelect) {
          case "sacrifice-mouse":
            requiredNftFeatures.type_ = 1;
            break;
          case "sacrifice-cat":
            requiredNftFeatures.type_ = 2;
            break;
          case "sacrifice-cow":
            requiredNftFeatures.type_ = 3;
            break;
          case "sacrifice-elephant":
            requiredNftFeatures.type_ = 4;
            break;
          case "sacrifice-ape":
            requiredNftFeatures.type_ = 5;
            break;
        }

        const newState = {
          pickAnyNft: true,
          previousPath: location.pathname,
          selectedNfts,
          forbiddenNftIds,
          requiredNftFeatures,
        };

        if (isSpeciesSac) {
          newState.injectPowerMangoes = false;
          newState.injectStablekozi = stableToTargetKozi(toWei("15")).times(1e18).toString();
        } else {
          newState.injectPowerMangoes = true;
          delete newState.injectStablekozi;
        }

        navigate("/beasts", {
          state: newState,
        });
      }
    }
  };

  const selectNftFromInventoryAndAddToSquad = async () => {
    setIsClickable(false); // we need to wait for the transaction to be mined before we can click again
    await addToSquad(_nft?.id, location.state.squadId);
  };

  const selectNftFromInventoryAndUpgrade = async () => {
    const forbiddenNftIds = location.state?.forbiddenNftIds || [];
    forbiddenNftIds.push(_nft?.id);

    // also add other forbidden rules (must be of the same type and rarity, and can't be on quest)
    const requiredNftFeatures = {
      type_: _nft?.type_,
      rarity: _nft?.rarity,
      notOnQuest: true,
    };

    const newState = {
      ...location.state,
      forbiddenNftIds,
      requiredNftFeatures,
    };

    navigate(`/upgrade-nft/${_nft?.id}`, {
      state: newState,
    });
  };

  const selectNftFromInventoryAndSell = async () => {
    if (_nft?.questId > 0) {
      Messenger.warn("You can't sell a beast that is on a quest.");
      return;
    } else if (_nft?.squadNumber > 0) {
      Messenger.warn("You can't sell a beast that is in a squad.");
      return;
    }

    console.log(`Selecting beast ${_nft?.id} to sell...`);
    navigate(`/marketplace/sell-beasts/${_nft?.id}`);
  };

  // we'll need to pass the selected NFTs to the upgrades page
  // inside an array that may or may not already exist in the path
  const selectAndAddToSelectedNfts = () => {
    const selectedNfts = location.state?.selectedNfts || [];

    // replace the first null value with the selected NFT
    // this happens when we remove an NFT from the selectedNfts array
    const index = selectedNfts.findIndex((nft) => nft === null);
    if (index > -1) {
      selectedNfts[index] = _nft;
    } else {
      selectedNfts.push(_nft);
    }

    location.state.pickAnyNft = false; // we're done picking NFTs

    const forbiddenNftIds = location.state?.forbiddenNftIds || [];
    forbiddenNftIds.push(_nft?.id);

    const requiredNftFeatures = location.state?.requiredNftFeatures || {};

    // go back to the previous page, but with the selected NFTs in the state
    navigate(location.state?.previousPath || -1, {
      state: { ...location.state, selectedNfts, forbiddenNftIds, requiredNftFeatures },
    });
  };

  const attributesTootlip = () => {
    if (!_nft || _nft?.isActuallyAResource) return null;

    let text = `${getRarity(_nft?.rarity)} ${getType(_nft?.type_)}: Rank ${_nft?.rank}, Agility ${
      _nft?.agility
    }`;

    if (_nft?.traits?.length > 0 && _nft?.traits[0] !== "0") {
      text += " (";
    }

    for (let i = 0; i < _nft?.traits?.length; i++) {
      const trait = _nft?.traits[i];
      if (trait !== "0" && trait !== 0) {
        text += `${getTrait(trait, true)}`;
        if (i < _nft?.traits.length - 1) {
          text += ", ";
        }
      }
    }

    if (_nft?.traits?.length > 0 && _nft?.traits[0] !== "0") {
      text += ")";
    }

    return text;
  };

  const isFarmPerBlockDifferent =
    new BigNumber(_nft?.baseFarm).div(1e9).toFixed(0) !==
    new BigNumber(_nft?.farmPerBlock).div(1e9).toFixed(0);

  let imageUrl = "";
  if (_nft) {
    imageUrl =
      _nft?.imageUrl && _nft?.imageUrl.trim() !== '""'
        ? _nft?.imageUrl
        : getDefaultImage(`${_nft?.type_}-${_nft?.rarity}`);
  }

  const renderStars = () => {
    if (!_nft?.evos || _nft?.evos === 0) {
      return null; // Render nothing if evos is 0
    } else if (_nft?.evos <= 5) {
      return (
        // Render silver stars for evos 1 to 5
        <>
          {Array.from({ length: _nft?.evos }).map((_, index) => (
            <Star key={index} src={silverStar} scale={_scale} lockSize={lockSize} style={{}} />
          ))}
        </>
      );
    } else {
      return (
        // Render golden stars for evos 6 to 11
        <>
          {Array.from({ length: _nft?.evos - 5 }).map((_, index) => (
            <Star key={index} src={goldenStar} scale={_scale} lockSize={lockSize} />
          ))}
        </>
      );
    }
  };

  const getEmptyCardSourceImg = () => {
    if (_nft?.isActuallyAResource) {
      return `../${resourceToImagePath(_nft?.resourceIndex)}`;
    }

    if (reasonToSelect === "squadSlot") {
      return !slotUnlocked
        ? padlock
        : squadType === 1
        ? plusMouse
        : squadType === 2
        ? plusCat
        : plusMix;
    } else if (
      reasonToSelect === "sacrifice" ||
      reasonToSelect === "sacrifice-mouse" ||
      reasonToSelect === "sacrifice-cat" ||
      reasonToSelect === "sacrifice-cow" ||
      reasonToSelect === "sacrifice-elephant" ||
      reasonToSelect === "sacrifice-ape"
    ) {
      return sacrifice;
    } else if (reasonToSelect === "sell") {
      return sell;
    } else if (reasonToSelect === "none") {
      return questionMark;
    }

    return sacrifice;
  };

  const getEmptyCardText = () => {
    if (_nft?.isActuallyAResource) {
      return `${_nft?.resourceQuantity} ${indexToName(_nft?.resourceIndex)}`;
    }

    if (reasonToSelect === "squadSlot") {
      return slotUnlocked ? "TAP TO ADD" : "TAP TO UNLOCK";
    } else if (reasonToSelect === "sacrifice" || reasonToSelect === "sell") {
      return "PICK A BEAST";
    } else if (reasonToSelect === "none") {
      return "EMPTY SLOT";
    } else if (reasonToSelect === "sacrifice-mouse") {
      return "PICK A MOUSE";
    } else if (reasonToSelect === "sacrifice-cat") {
      return "PICK A CAT";
    } else if (reasonToSelect === "sacrifice-cow") {
      return "PICK A COW";
    } else if (reasonToSelect === "sacrifice-elephant") {
      return "PICK AN ELEPHANT";
    } else if (reasonToSelect === "sacrifice-ape") {
      return "PICK AN APE";
    } else if (reasonToSelect === "findDeadBeast") {
      return "";
    }

    return "TAP TO SELECT";
  };

  const getEmptyCardBackground = () => {
    if (_nft?.isActuallyAResource) {
      return indexToVerticalBackground[_nft?.resourceIndex];
    }

    if (reasonToSelect === "squadSlot") {
      return slotUnlocked ? "#000000" : "#000000";
    } else if (reasonToSelect === "sacrifice") {
      return "#000000";
    } else if (reasonToSelect === "sell") {
      return "#111";
    }

    return "#000000";
  };

  const promoFrame = (nftId) => {
    const frameId = getPromoInfo(nftId)?.frameId;

    switch (frameId) {
      case 1:
        return <InfluencerOverlay scale={scale} src={promoStyle1} lockSize={true} />;

      case 2:
        return <InfluencerOverlay scale={scale} src={promoStyle2} lockSize={true} />;

      case 3:
        return <InfluencerOverlay scale={scale} src={promoStyle3} lockSize={true} />;

      case 4:
        return <InfluencerOverlay scale={scale} src={promoStyle4} lockSize={true} />;

      case 5:
        return (
          <InfluencerOverlay scale={scale} src={promoStyle5} cycleColors={true} lockSize={true} />
        );

      case 6:
        return <InfluencerOverlay scale={scale} src={promoStyle6} lockSize={true} />;

      default:
        return null;
    }
  };

  const isRevivedBeast = () => {
    if (!_nft) return false;
    if (!_nft?.attributes) return false;
    if (_nft?.attributes?.length <= 6) return false;
    if (_nft?.attributes[6] <= 0) return false;

    return true;
  };

  const isAwakened = () => {
    if (!_nft?.attributes) return false;
    if (_nft?.attributes.length <= 6) return false;

    return _nft?.attributes[6] > 0;
  };

  return (
    <GridItem
      backgroundColor={backGroundColor}
      // borderColor={borderColor}
      hasCustomBorder={hasCustomBorder}
      borderImage={borderImage}
      backgroundImage={imageUrl}
      hasPadding={needPadding}
      isClickable={isClickable}
      onClick={() => selectNft()}
      scale={_scale}
      lockSize={lockSize}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      extraOutline={_extraOutline}
      extraOutlineColor={_extraOutlineColor}
      isDead={_nft?.isDead}
      nftId={nft?.id}
    >
      {isPromoNft(nft?.id) && promoFrame(nft?.id)}
      {clickToSelectNftFromInventory && (
        <ClickableOverlay
          scale={scale}
          onClick={isClickable ? () => selectNft() : null}
          lockSize={lockSize}
        ></ClickableOverlay>
      )}
      {isItMaxedOut && (
        <FireOverlay
          scale={scale}
          src={fireOverlayImage}
          isMaxedOut={isItMaxedOut}
          lockSize={lockSize}
        />
      )}
      {isRevivedBeast() && (
        <>
          <a
            data-tooltip-id="revived"
            data-tooltip-content={`This beast came back to life  ${
              _nft.attributes[6] > 1 ? `${_nft.attributes[6]} times` : "once"
            }`}
            data-tooltip-place="top"
          >
            <RevivedOverlayContainer scale={scale} lockSize={lockSize}>
              <img src={revivedOverlay} />
            </RevivedOverlayContainer>
          </a>
          {!suppressTooltips && (
            <Tooltip
              id="revived"
              style={{ fontSize: "12px", zIndex: 9999999999, fontWeight: 600 }}
            />
          )}
        </>
      )}
      <a data-tooltip-id="cardAttributes" data-tooltip-content={attributesTootlip()}>
        <div
          style={{
            position: "relative",
            height: `${_nft && !_nft?.isActuallyAResource ? "50%" : "100%"}`,
            zIndex: `${_nft && !_nft?.isActuallyAResource ? "-1" : "0"}`,
          }}
        >
          {_nft && !_nft?.isActuallyAResource && (
            <CardAttributes nft={_nft} scale={_scale} lockSize={lockSize} />
          )}
          {_nft && !_nft?.isActuallyAResource && (
            <CardTraits
              traits={_nft?.traits}
              synergies={squadSynergies}
              scale={_scale}
              lockSize={lockSize}
            />
          )}
          {_nft && !_nft?.isActuallyAResource ? (
            <>
              {_nft?.questId > 0 ? (
                <QuestTag scale={_scale} lockSize={lockSize}>
                  On Quest {realQuestId(_nft?.questId)}
                </QuestTag>
              ) : null}
              {_nft?.squadNumber > 0 ? (
                <SquadTag
                  scale={_scale}
                  lockSize={lockSize}
                  color="#bbb"
                  isOnQuest={_nft?.questId > 0}
                >
                  In Squad {_nft?.squadNumber}
                </SquadTag>
              ) : null}
              <CardImage nft={_nft} scale={_scale} lockSize={lockSize} />
            </>
          ) : (
            <ImageEmptyModel
              scale={_scale}
              lockSize={lockSize}
              background={getEmptyCardBackground()}
            >
              <img src={getEmptyCardSourceImg()} />
              <div className="nft-dummy">
                <p>{getEmptyCardText()}</p>
              </div>
            </ImageEmptyModel>
          )}
          {nft?.isDead && <DeadStamp src={deadStamp} scale={_scale} lockSize={lockSize} />}
        </div>
      </a>
      {!suppressTooltips && (
        <Tooltip
          id="cardAttributes"
          style={{ fontSize: "12px", zIndex: 9999999999, fontWeight: 600 }}
        />
      )}
      {_nft && !_nft?.isActuallyAResource && (
        <BottomDataContainer scale={_scale} lockSize={lockSize}>
          <div
            style={{
              position: "relative",
              height: "50%",
              display: "flex",
              cursor: "default",
            }}
          >
            <CardSkillsLeft nft={_nft} scale={_scale} lockSize={lockSize} />
            <CardSkillsRight nft={_nft} scale={_scale} lockSize={lockSize} />
          </div>
          <div style={{ position: "relative", height: "50%", cursor: "default" }}>
            <NftId scale={_scale} lockSize={lockSize} nftId={_nft?.id}>
              {_nft?.id}
            </NftId>
            <a
              data-tooltip-id="nutsAndEvo"
              data-tooltip-content={`${_nft?.nuts} Base Farm Boosts and ${_nft?.evos} Evolutions`}
            >
              <NftName
                style={{ cursor: "default" }}
                scale={_scale}
                lockSize={lockSize}
                nftId={_nft?.id}
                isAwakened={isAwakened()}
                isPromo={isPromoNft(nft?.id)}
              >
                {!_nft?.name || _nft?.name.trim() === '""'
                  ? `${getRarity(_nft?.rarity)} ${getType(_nft?.type_)}`
                  : _nft?.name.replace(/"/g, "")}
              </NftName>
              <NutsAndEvoContainer scale={_scale} lockSize={lockSize}>
                {_nft?.nuts !== undefined && (
                  <>
                    <CardProgressBar progress={_nft?.nuts * 2} scale={_scale} lockSize={lockSize} />
                  </>
                )}
                {renderStars()}
              </NutsAndEvoContainer>
            </a>
            {!suppressTooltips && (
              <Tooltip
                id="nutsAndEvo"
                style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
              />
            )}

            <FarmContainer scale={_scale} lockSize={lockSize}>
              <a data-tooltip-id="baseFarm" data-tooltip-content={`Base Farm (Gwei of BCT)`}>
                <BaseFarm scale={_scale} lockSize={lockSize}>
                  {format(new BigNumber(_nft?.baseFarm).div(1e9).toFixed(0), 3)}
                </BaseFarm>
              </a>
              {!suppressTooltips && (
                <Tooltip
                  id="baseFarm"
                  style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
                />
              )}

              {nft?.collection > 0 && (
                <CollectionOverlay scale={_scale} lockSize={lockSize}>
                  <a
                    data-tooltip-id="collection"
                    data-tooltip-content={
                      isPromoCollection(_nft?.collection)
                        ? `Promotional Collection #${_nft?.collection}`
                        : `Collection #${_nft?.collection}`
                    }
                  >
                    <CollectionGlow scale={_scale}></CollectionGlow>
                  </a>
                  {!suppressTooltips && (
                    <Tooltip
                      id="collection"
                      style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
                    />
                  )}
                </CollectionOverlay>
              )}

              <a
                data-tooltip-id="farmPerBlock"
                data-tooltip-content={`Farm Per Block: Base Farm + Ranks and Agility Boosts`}
              >
                <FarmPerBlock
                  isDifferent={isFarmPerBlockDifferent}
                  scale={_scale}
                  lockSize={lockSize}
                  isMaxedOut={isItMaxedOut}
                >
                  {format(new BigNumber(_nft?.farmPerBlock).div(1e9).toFixed(0), 3)}
                </FarmPerBlock>
              </a>
              {!suppressTooltips && (
                <Tooltip
                  id="farmPerBlock"
                  style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }}
                />
              )}
            </FarmContainer>
          </div>
        </BottomDataContainer>
      )}
    </GridItem>
  );
};

export default Card;
