class TimerManager {
  constructor() {
    // Binding all methods
    Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach((method) => {
      this[method] = this[method].bind(this);
    });

    this.timeouts = {};
  }

  _start(timerName, callbackFunction, timeoutTime, ...args) {
    this._stop(timerName);
    this.timeouts[timerName] = setTimeout(callbackFunction, timeoutTime, ...args);
  }

  _stop(timerName) {
    if (this.timeouts[timerName]) {
      clearTimeout(this.timeouts[timerName]);
      this.timeouts[timerName] = null;
    }
  }

  start(timerName, callbackFunction, timeoutTime, ...args) {
    this._start(timerName, callbackFunction, timeoutTime);
  }

  stop(timerName) {
    this._stop(timerName);
  }

  stopAll() {
    Object.keys(this.timeouts).forEach((timerName) => {
      this._stop(timerName);
    });
  }
}

const timerManager = new TimerManager();
export default timerManager;
