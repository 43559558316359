import styled from "styled-components";

export const QuestRewardsContainer = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;

  @media (max-width: 685px) {
    width: 100%;
    height: 30px;
  }

  @media (max-width: 450px) {
    height: 100%;
  }
`;

export const QuestBonusWrapper = styled.div`
  padding: 1px;
  width: 100%;
  height: 100%;

  @media (max-width: 450px) {
    height: 24px;
  }
`;

export const ResourcesRewardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 2fr));

  @media (max-width: 685px) {
    grid-template-columns: repeat(auto-fill, minmax(16%, 3fr));
    flex-grow: 1;
    flex-direction: column;
  }
`;
