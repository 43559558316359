// let's recreate this bidimensional array:
/*
stablekoziCostPerSpeciesAndRarity[1][1] = 2 ether;
    stablekoziCostPerSpeciesAndRarity[1][2] = 4 ether;
    stablekoziCostPerSpeciesAndRarity[1][3] = 8 ether;
    stablekoziCostPerSpeciesAndRarity[1][4] = 16 ether;
    stablekoziCostPerSpeciesAndRarity[1][5] = 32 ether;

    stablekoziCostPerSpeciesAndRarity[2][1] = 3 ether;
    stablekoziCostPerSpeciesAndRarity[2][2] = 6 ether;
    stablekoziCostPerSpeciesAndRarity[2][3] = 12 ether;
    stablekoziCostPerSpeciesAndRarity[2][4] = 24 ether;
    stablekoziCostPerSpeciesAndRarity[2][5] = 48 ether;

    stablekoziCostPerSpeciesAndRarity[3][1] = 6 ether;
    stablekoziCostPerSpeciesAndRarity[3][2] = 12 ether;
    stablekoziCostPerSpeciesAndRarity[3][3] = 24 ether;
    stablekoziCostPerSpeciesAndRarity[3][4] = 48 ether;
    stablekoziCostPerSpeciesAndRarity[3][5] = 96 ether;

    stablekoziCostPerSpeciesAndRarity[4][1] = 10 ether;
    stablekoziCostPerSpeciesAndRarity[4][2] = 20 ether;
    stablekoziCostPerSpeciesAndRarity[4][3] = 40 ether;
    stablekoziCostPerSpeciesAndRarity[4][4] = 80 ether;
    stablekoziCostPerSpeciesAndRarity[4][5] = 120 ether;

    stablekoziCostPerSpeciesAndRarity[5][1] = 10 ether;
    stablekoziCostPerSpeciesAndRarity[5][2] = 20 ether;
    stablekoziCostPerSpeciesAndRarity[5][3] = 40 ether;
    stablekoziCostPerSpeciesAndRarity[5][4] = 80 ether;
    stablekoziCostPerSpeciesAndRarity[5][5] = 120 ether;

    stablekoziCostPerSpeciesAndRarity[6][5] = 120 ether;
    stablekoziCostPerSpeciesAndRarity[7][5] = 130 ether;
    stablekoziCostPerSpeciesAndRarity[8][5] = 140 ether;
    stablekoziCostPerSpeciesAndRarity[9][5] = 150 ether;
    stablekoziCostPerSpeciesAndRarity[10][5] = 160 ether;

    stablekoziCostPerSpeciesAndRarity[11][5] = 170 ether;
    stablekoziCostPerSpeciesAndRarity[12][5] = 180 ether;
    stablekoziCostPerSpeciesAndRarity[13][5] = 200 ether;
    stablekoziCostPerSpeciesAndRarity[14][5] = 250 ether;
    stablekoziCostPerSpeciesAndRarity[15][5] = 300 ether;
*/

// Os menores precisam ser mais caros, pra valer a pena reviver bichos fortes, e não os fraquinhos,
// senão eles viram só veículo pras traits.
export const COST = [
  [0, 0, 0, 0, 0, 0],
  [0, 12, 16, 20, 24, 26],
  [0, 14, 18, 22, 26, 48],
  [0, 20, 24, 32, 48, 96],
  [0, 32, 40, 48, 56, 100],
  [0, 32, 40, 48, 56, 100],
  [0, 0, 0, 0, 0, 120],
  [0, 0, 0, 0, 0, 130],
  [0, 0, 0, 0, 0, 140],
  [0, 0, 0, 0, 0, 150],
  [0, 0, 0, 0, 0, 160],
  [0, 0, 0, 0, 0, 200],
  [0, 0, 0, 0, 0, 250],
  [0, 0, 0, 0, 0, 300],
  [0, 0, 0, 0, 0, 400],
  [0, 0, 0, 0, 0, 500],
];
