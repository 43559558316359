import SynergyOverlay from "../../assets/images/squads-card/SynergyOverlay.png";
import { getSvgTraitById } from "../../config/resources/07-traits-to-svg";

import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: ${(props) => (props.scale ? `${4.5 * props.scale}px` : `4.5px`)};
  right: ${(props) => (props.scale ? `${4 * props.scale}px` : `4px`)};
  display: flex;
  flex-direction: column;
  justify-content: center;

  .image {
    z-index: 1 !important;
  }
  svg {
    width: ${(props) => (props.scale ? `${30 * props.scale}px` : `30px`)};
    height: ${(props) => (props.scale ? `${30 * props.scale}px` : `30px`)};
  }
`;

export const Glow = styled.div`
  position: relative;
  display: flex;
  background: ${(props) => (props.isGlowing ? "#ffe176" : "transparent")};
  box-shadow: ${(props) =>
    props.isGlowing ? "0px 0px 3px 1px #ffe176" : "0px 0px 3px 1px transparent"};
  width: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  height: ${(props) => (props.scale ? `${26 * props.scale}px` : `26px`)};
  border-radius: 50%;
  margin-left: ${(props) => (props.scale ? `${3 * props.scale}px` : `3px`)};
  margin-bottom: ${(props) => (props.scale ? `${6 * props.scale}px` : `6px`)};

  &::before {
    content: ${(props) => (props.isGlowing ? '""' : "none")};
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.8;
    box-shadow: inset 0px 0px 3px 2px #ffe176;
    z-index: 2;
  }

  &::after {
    content: ${(props) => (props.isGlowing ? '""' : "none")};
    position: absolute;
    background: url(${SynergyOverlay}) no-repeat center;
    background-size: contain;
    top: -3px;
    left: -3px;
    opacity: 0.4;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    z-index: 3;
    animation: rotate 40s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  img {
    position: relative;
    z-index: 1;
  }
`;

export const Image = styled.img`
  width: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  height: ${(props) => (props.scale ? `${28 * props.scale}px` : `28px`)};
  margin-bottom: ${(props) => (props.scale ? `${4 * props.scale}px` : `4px`)};
`;

const CardTraits = ({ traits, synergies, scale }) => {
  if (traits?.length > 0) {
    return (
      <Container scale={scale}>
        {traits.map((trait, index) =>
          trait !== 0 ? (
            <Glow key={index} isGlowing={synergies.includes(trait)} scale={scale}>
              <Image src={getSvgTraitById(trait)} scale={scale} />
            </Glow>
          ) : null
        )}
      </Container>
    );
  } else {
    return null;
  }
};

export default CardTraits;
