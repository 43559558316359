import React from "react";

import {
  Container,
  IdleContainer,
  OnQuestContainer,
  ActionButton,
  ClickableOverlay,
} from "./styles";

import { realQuestId } from "../../../utils/quests";

const SquadAction = ({ squad, openQuestModalCallback = null }) => {
  const isIdle = squad.questStartedAt === 0;

  const showSelectQuestModal = () => {
    if (openQuestModalCallback) {
      openQuestModalCallback();
    }
  };

  return (
    <Container>
      <ActionButton style={{ cursor: "default" }} disabled={!isIdle}>
        {isIdle ? (
          <IdleContainer onClick={showSelectQuestModal}>
            <h6>?</h6>
          </IdleContainer>
        ) : (
          <OnQuestContainer>
            <h2 style={{ fontSize: "30px" }}>{realQuestId(squad.currentQuest)}</h2>
            <h6 style={{ fontSize: "10px" }}>On Quest</h6>
          </OnQuestContainer>
        )}
      </ActionButton>
    </Container>
  );
};

export default SquadAction;
