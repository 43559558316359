import BigNumber from "bignumber.js";
import Rpc from "../rpc/Rpc.js";
import Contracts from "../utils/Contracts.js";
import { ethers } from "ethers";

import { parseAge } from "../utils/age.js";

class AccountModel {
  constructor(address) {
    this.address = address;
    this.balances = {
      KOZI: "0",
      BCT: "0",
      USDC: "0",
    };
  }

  async fetchKoziBalance() {
    // let's first fetch the Kozi Balance and convert it to a decimal string:
    const koziBalance = await Rpc.fetchKoziBalanceOf(this.address);
    this.balances.KOZI = ethers.formatEther(BigNumber(koziBalance).toString());

    return this.balances.KOZI;
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }

  setTokenBalances(tokenBalances) {
    const normalizedTokenBalances = Object.entries(tokenBalances).reduce(
      (acc, [token, balance]) => {
        acc[token] = ethers.formatUnits(balance, 18);
        return acc;
      },
      {}
    );

    // merge token balances into this.balances
    this.balances = {
      ...this.balances,
      ...normalizedTokenBalances,
    };
  }

  async setTxList(page = 1, includeEvents = false) {
    const { list, total, currentBlockHeight } = await Rpc.fetchTxListOf(
      this.address,
      page,
      includeEvents
    );

    console.log("TxList Fetched", { list, total, currentBlockHeight, page });

    this.txList = list;
    this.txTotal = total;

    // add the Action and the Age to each transaction
    if (list?.length) {
      this.txList = this.txList.map((tx) => {
        return {
          ...tx,
          action: this.parseAction(tx),
          age: parseAge(tx.blockNumber, currentBlockHeight),
        };
      });
    }
  }

  parseAction(txReceipt) {
    if (!txReceipt.to) return "Contract Deployment";

    // action is the name of the called function
    const abi = Contracts.addressToAbi(ethers.getAddress(txReceipt.to));

    if (!abi) {
      if (BigNumber(txReceipt.value).toFixed() !== "0") return "KOZI Transfer";

      if (txReceipt.input.startsWith("0x12257c90")) {
        return "Kingdom Reward";
      }

      return "Unknown";
    }

    const contract = new ethers.Contract(txReceipt.to, abi);
    const parsedFunction = contract.interface.parseTransaction({ data: txReceipt.input });

    try {
      const action = parsedFunction.name.replace(/([A-Z])/g, " $1").trim();
      const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1);
      return capitalizedAction;
    } catch (e) {
      return "Unknown";
    }
  }
}

export default AccountModel;
