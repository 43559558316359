import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 685px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const SquadNumberWrapper = styled.div`
  padding: 1px;
  width: 100%;
  height: 100%;
`;

export const BonusContainer = styled.div`
  width: 230px;

  @media (max-width: 685px) {
    width: 380px;
    height: 30px;
  }

  @media (max-width: 450px) {
    width: 300px;
    height: 100%;
  }
`;

export const ResourcesRewardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 2fr));

  @media (max-width: 685px) {
    grid-template-columns: repeat(auto-fill, minmax(16%, 3fr));
    flex-grow: 1;
    flex-direction: column;
  }
`;

export const ResourceBonusWrapper = styled.div`
  padding: 1px;
  width: 100%;
  height: 100%;
`;