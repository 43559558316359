import React, { useEffect, useState } from "react";
import TraitSelectDropdown from "../../upgrades/trait-select-dropdown/TraitSelectDropdown";
import SpeciesSelectDropdown from "../species-select-dropdown/SpeciesSelectDropdown";
import RaritySelectDropdown from "../rarity-select-dropdown/RaritySelectDropdown";
import { hasEnoughBct, hasEnoughBusd, hasEnoughResources } from "../../../utils/purchase";
import { toast } from "react-toastify";

import {
  ButtonContainer,
  BuyButton,
  BuyWithBusdButton,
  BuyWithKoziButton,
  PurchaseCostContainer,
} from "../../upgrades/buttons/PurchaseButtons";

import {
  GlobalContainer,
  Container,
  TitleContainer,
  Title,
  Description,
  TraitPickerContainer,
  ForbiddenRarity,
  NameInputContainer,
  NameInput,
  CustomizeButtonContainer,
  CustomizeButton,
  ExtraHelpButton,
  ExtraHelpButtonText,
  SpeciesPickerContainer,
  RarityPickerContainer,
  CompleteContainer,
  CompleteTitleContainer,
  CompleteDescription,
  ExternalContainer,
  ExternalTitleContainer,
  ExternalDescription,
} from "./styles";

import "../../../assets/styles/loader.css";

import BigNumber from "bignumber.js";
import ResourceContainerUpgrades from "../../upgrades/resource-container";
import { indexToBackground } from "../../../utils/constants";
import { bonusToBackground } from "../../../utils/constants";

import { useWeb3Context } from "../../../contexts/Web3Context";

const ActionBox = ({
  title = "Create Collection",
  description = "Choose the name of your new collection. Then, choose the species, the rarity, and the shared trait of this group of beasts. Finally, upload the images of your beasts and set their names. Customizing names and images is optional.",
  description2 = "Expanding a collection doesn't change its name, but you may choose a new species, a new rarity, and a new trait for these five new beasts. You can also customize their names and images, but this is optional.",
  eBctPrice,
  resourcePrices,
  resourceIndexes,
  busdPrice = 0,
  buyFunction = null,
  buyWithBusdFunction = null,
  player = null,
  traitSelectCallback = null,
  speciesSelectCallback = null,
  raritySelectCallback = null,
  customizationModalOpenCallback = null,
  inputNameDefaultValue = "",
  inputNameCallback = null,
  isForbidden = false,
  isBlocked = false,
  onExtraHelpButtonClick = null,
  isComplete = false,
  isExternal = false,
  isExpansion = false,
  isFull = false,
  forceName = null,
}) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_CEIL });
  const { stableToTargetKozi } = useWeb3Context();

  const [selectedTrait, setSelectedTrait] = useState(0);
  const [currentNameInputValue, setCurrentNameInputValue] = useState(inputNameDefaultValue);

  busdPrice = BigNumber(busdPrice).div(1e18).toFixed(2);

  useEffect(() => {
    setCurrentNameInputValue(inputNameDefaultValue);
  }, []);

  function handleBuyFunction() {
    if (!buyFunction) {
      console.log(`Can't find the buy function`);
      return;
    }

    buyFunction();
  }

  function handleBuyWithBusdFunction() {
    if (!buyWithBusdFunction) {
      console.log(`Can't find the pay with USDC function`);
      return;
    }

    buyWithBusdFunction();
  }

  function onTraitSelectCallback(traitId) {
    setSelectedTrait(traitId);
    traitSelectCallback(traitId);
  }

  function handleNameChange(newName) {
    setCurrentNameInputValue(newName);
    inputNameCallback(newName);
  }

  function handleExtraHelpButtonClick() {
    if (onExtraHelpButtonClick) {
      onExtraHelpButtonClick();
    }
  }

  function onSpeciesSelectCallback(speciesId) {
    speciesSelectCallback(speciesId);
  }

  function onRaritySelectCallback(rarityId) {
    raritySelectCallback(rarityId);
  }

  function onCustomizeModalOpen() {
    customizationModalOpenCallback();
  }

  return (
    <GlobalContainer>
      {isExternal && !isComplete && (
        <ExternalContainer>
          <ExternalTitleContainer>
            <Title>Collect them all!</Title>
            <ExternalDescription>
              This collection was not minted by you. In order to get more NFTs from it, you should
              reach out to the player who minted it. Look for them on Discord, they will be happy to
              negotiate with you!
            </ExternalDescription>
          </ExternalTitleContainer>
        </ExternalContainer>
      )}
      {(isComplete || isFull) && !isExternal && (
        <CompleteContainer>
          <CompleteTitleContainer>
            <Title>This collection is complete</Title>
            <CompleteDescription>Congratulations! 🤩</CompleteDescription>
          </CompleteTitleContainer>
        </CompleteContainer>
      )}
      {!isExternal && !isComplete && !isExpansion && !isFull && (
        <Container>
          <TitleContainer>
            <Title>{title}</Title>
            {onExtraHelpButtonClick && (
              <ExtraHelpButton onClick={handleExtraHelpButtonClick}>
                <ExtraHelpButtonText>?</ExtraHelpButtonText>
              </ExtraHelpButton>
            )}
          </TitleContainer>
          <Description>{description}</Description>
          <NameInputContainer>
            <NameInput
              type="text"
              placeholder={
                currentNameInputValue !== "" ? currentNameInputValue : "Name your collection"
              }
              onChange={(e) => handleNameChange(e.target.value)}
            ></NameInput>
          </NameInputContainer>
          <SpeciesPickerContainer>
            <SpeciesSelectDropdown
              speciesSelectCallback={onSpeciesSelectCallback}
              speciesList={[
                { id: 1, name: "Mouse" },
                { id: 2, name: "Cat" },
                { id: 3, name: "Cow" },
                { id: 4, name: "Elephant" },
              ]}
            />
          </SpeciesPickerContainer>
          <RarityPickerContainer>
            <RaritySelectDropdown
              raritySelectCallback={onRaritySelectCallback}
              rarityList={[
                { id: 4, name: "Legendary" },
                { id: 5, name: "Mythic" },
              ]}
            />
          </RarityPickerContainer>
          <TraitPickerContainer>
            <TraitSelectDropdown
              traitSelectCallback={onTraitSelectCallback}
              injectTraitZero={false}
            />
          </TraitPickerContainer>
          <CustomizeButtonContainer onClick={onCustomizeModalOpen}>
            <CustomizeButton>Customize</CustomizeButton>
          </CustomizeButtonContainer>
          {eBctPrice && !isForbidden && (
            <ButtonContainer>
              <BuyButton
                onClick={handleBuyFunction}
                disabled={
                  selectedTrait === 0 ||
                  !hasEnoughBct(player, eBctPrice) ||
                  !hasEnoughResources(player, resourcePrices, resourceIndexes) ||
                  isForbidden ||
                  isBlocked
                }
              >
                <p>Pay with resources</p>
                <PurchaseCostContainer>
                  <ResourceContainerUpgrades
                    resource={98}
                    quantity={eBctPrice.div(1e18).toNumber()}
                    background={bonusToBackground["eBCT"]}
                    hasEnough={hasEnoughBct(player, eBctPrice)}
                  />
                  {resourceIndexes.map((resourceIndex, mapIndex) => (
                    <ResourceContainerUpgrades
                      key={`resource-${resourceIndex}`}
                      resource={resourceIndex}
                      quantity={resourcePrices[mapIndex] / 100}
                      background={indexToBackground[resourceIndex]}
                      hasEnough={hasEnoughResources(
                        player,
                        [resourcePrices[mapIndex]],
                        [resourceIndexes[mapIndex]]
                      )}
                    />
                  ))}
                </PurchaseCostContainer>
              </BuyButton>
              <BuyWithKoziButton
                disabled={
                  selectedTrait === 0 ||
                  busdPrice === 0 ||
                  busdPrice === "0.00" ||
                  busdPrice === "0.01" ||
                  busdPrice === "—" ||
                  !hasEnoughBusd(player, busdPrice) ||
                  isForbidden ||
                  isBlocked
                }
                onClick={handleBuyWithBusdFunction}
                hasEnough={player.hasEnoughKozi(
                  stableToTargetKozi(BigNumber(busdPrice).times(1e18))
                )}
              >
                <h1>
                  Pay with <span style={{ color: "yellow" }}>KOZI</span>
                </h1>
                {busdPrice !== "0.00" && busdPrice !== "—" && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#ddc",
                      textDecoration: "line-through",
                      marginBottom: "4px",
                    }}
                  >
                    {"- "}
                    {busdPrice} USDC{" -"}
                  </p>
                )}

                <p>
                  {stableToTargetKozi(BigNumber(busdPrice).times(1e18)).toFixed(
                    4,
                    BigNumber.ROUND_CEIL
                  ) + " 🧿"}
                </p>
              </BuyWithKoziButton>
            </ButtonContainer>
          )}
          {isForbidden && (
            <ForbiddenRarity>This doesn't work with beasts of this rarity level.</ForbiddenRarity>
          )}
        </Container>
      )}
      {!isExternal && !isComplete && isExpansion && !isFull && (
        /* forceName bit without spaces */
        <Container key={forceName.replace(/\s/g, "")}>
          <TitleContainer>
            <Title>Expand Collection</Title>
            {onExtraHelpButtonClick && (
              <ExtraHelpButton onClick={handleExtraHelpButtonClick}>
                <ExtraHelpButtonText>?</ExtraHelpButtonText>
              </ExtraHelpButton>
            )}
          </TitleContainer>
          <Description>{description2}</Description>
          <NameInputContainer>
            <NameInput
              type="text"
              placeholder={
                forceName
                  ? forceName
                  : currentNameInputValue !== ""
                  ? currentNameInputValue
                  : "Name your collection"
              }
              onChange={(e) => handleNameChange(e.target.value)}
              readOnly={forceName ? true : false}
            ></NameInput>
          </NameInputContainer>
          <SpeciesPickerContainer>
            <SpeciesSelectDropdown
              speciesSelectCallback={onSpeciesSelectCallback}
              speciesList={[
                { id: 1, name: "Mouse" },
                { id: 2, name: "Cat" },
                { id: 3, name: "Cow" },
                { id: 4, name: "Elephant" },
              ]}
            />
          </SpeciesPickerContainer>
          <RarityPickerContainer>
            <RaritySelectDropdown
              raritySelectCallback={onRaritySelectCallback}
              rarityList={[
                { id: 4, name: "Legendary" },
                { id: 5, name: "Mythic" },
              ]}
            />
          </RarityPickerContainer>
          <TraitPickerContainer>
            <TraitSelectDropdown
              traitSelectCallback={onTraitSelectCallback}
              injectTraitZero={false}
            />
          </TraitPickerContainer>
          <CustomizeButtonContainer onClick={onCustomizeModalOpen}>
            <CustomizeButton>Customize</CustomizeButton>
          </CustomizeButtonContainer>
          {eBctPrice && !isForbidden && (
            <ButtonContainer>
              <BuyButton
                onClick={handleBuyFunction}
                disabled={
                  selectedTrait === 0 ||
                  !hasEnoughBct(player, eBctPrice) ||
                  !hasEnoughResources(player, resourcePrices, resourceIndexes) ||
                  isForbidden ||
                  isBlocked
                }
              >
                <p>Pay with resources</p>
                <PurchaseCostContainer>
                  <ResourceContainerUpgrades
                    resource={98}
                    quantity={eBctPrice.div(1e18).toNumber()}
                    background={bonusToBackground["eBCT"]}
                    hasEnough={hasEnoughBct(player, eBctPrice)}
                  />
                  {resourceIndexes.map((resourceIndex, mapIndex) => (
                    <ResourceContainerUpgrades
                      key={`resource-${resourceIndex}`}
                      resource={resourceIndex}
                      quantity={resourcePrices[mapIndex] / 100}
                      background={indexToBackground[resourceIndex]}
                      hasEnough={hasEnoughResources(
                        player,
                        [resourcePrices[mapIndex]],
                        [resourceIndexes[mapIndex]]
                      )}
                    />
                  ))}
                </PurchaseCostContainer>
              </BuyButton>
              <BuyWithKoziButton
                disabled={
                  selectedTrait === 0 ||
                  busdPrice === 0 ||
                  busdPrice === "0.00" ||
                  busdPrice === "0.01" ||
                  busdPrice === "—" ||
                  !player.hasEnoughKozi(stableToTargetKozi(BigNumber(busdPrice).times(1e18))) ||
                  isForbidden ||
                  isBlocked
                }
                onClick={handleBuyWithBusdFunction}
                hasEnough={player.hasEnoughKozi(
                  stableToTargetKozi(BigNumber(busdPrice).times(1e18))
                )}
              >
                <h1>
                  Pay with <span style={{ color: "yellow" }}>KOZI</span>
                </h1>
                {busdPrice !== "0.00" && busdPrice !== "—" && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#ddc",
                      textDecoration: "line-through",
                      marginBottom: "4px",
                    }}
                  >
                    {"- "}
                    {busdPrice} USDC{" -"}
                  </p>
                )}
                <p>
                  {stableToTargetKozi(BigNumber(busdPrice).times(1e18)).toFixed(
                    4,
                    BigNumber.ROUND_CEIL
                  ) + " 🧿"}
                </p>
              </BuyWithKoziButton>
            </ButtonContainer>
          )}
          {isForbidden && (
            <ForbiddenRarity>This doesn't work with beasts of this rarity level.</ForbiddenRarity>
          )}
        </Container>
      )}
    </GlobalContainer>
  );
};

export default ActionBox;
