import React from "react";
import Header from "../components/header/Header";

import MarketplaceScreen from "../components/marketplace-screen/MarketplaceScreen";

import { useWeb3Context } from "../contexts/Web3Context";

const Marketplace = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <MarketplaceScreen></MarketplaceScreen>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Marketplace;
