import BigNumber from "bignumber.js";
import React from "react";
import { Container, Content, Title } from "./styles";

import Transaction from "./transaction/Transaction";
import Block from "./block/Block";
import Account from "./account/Account";
import Token from "./token/Token";

const TravelSongScreen = ({
  isRefreshingTxns = false,
  isFetching = false,
  toSearch = "",
  result = null,
  resultType = null,
  searchCallback = null,
  isLatestBlock = false,
  txnList = [],
  changePageCallback = null,
}) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const viewByResultType = (_resultType) => {
    switch (_resultType) {
      case "token":
        return (
          <Token
            isRefreshingTxns={isRefreshingTxns}
            isFetching={isFetching}
            address={toSearch}
            token={result}
            searchCallback={searchCallback}
            changePageCallback={changePageCallback}
          />
        );
      case "account":
        return (
          <Account
            isRefreshingTxns={isRefreshingTxns}
            isFetching={isFetching}
            address={toSearch}
            account={result}
            searchCallback={searchCallback}
            changePageCallback={changePageCallback}
          />
        );
      case "block":
        return (
          <Block
            searchCallback={searchCallback}
            isFetching={isFetching}
            blockNumber={toSearch}
            block={result}
            isLatestBlock={isLatestBlock}
            latestTxnList={txnList}
          />
        );
      case "transaction":
        return (
          <Transaction
            searchCallback={searchCallback}
            isFetching={isFetching}
            hash={toSearch}
            txn={result}
          />
        );
      default:
        break;
    }
  };

  return (
    <Container>
      <Content>
        {toSearch ? (
          viewByResultType(resultType)
        ) : (
          <Title>
            {toSearch ? `` : "Search the Kingdom Chain by transaction hash or block number"}
          </Title>
        )}
      </Content>
    </Container>
  );
};

export default TravelSongScreen;
