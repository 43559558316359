import { ENV, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment";
import Config from "../config/config";
import Messenger from "../utils/Messenger";
import Contracts from "../utils/Contracts";
import { GLOBAL_KOZI_DISCOUNT_PCT } from "../utils/constants";

import { useState, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";

import BigNumber from "bignumber.js";

import { HALVING_THRESHOLDS } from "../config/resources/14-halvingsThresholds";

import TimerManager from "../utils/TimerManager";

import {
  _ebctToBct,
  _ebctToBusd,
  _bctToBusd,
  _bctToEbct,
  _busdToEbct,
  _busdToBct,
} from "../utils/pool";
import { toWei } from "../utils/wei";

export const Web3Context = createContext({});

const FINAL_SCALES = { success: true, farmingScale: 1, upgradesScale: 12 };

export const Web3Provider = ({ children }) => {
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState(null);
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const [scales, setScales] = useState(null); // { farmingScale, upgradesScale }
  const [isUsingKingdomChain, setIsUsingKingdomChain] = useState(false);

  const [blockchainState, setBlockchainState] = useState(null); // { halvingsData, poolReserves, updatedAt}

  const [initialBlockNumber, setInitialBlockNumber] = useState(0);
  const [initialTimestamp, setInitialTimestamp] = useState(0);

  // To ber called once:
  async function setupProvider() {
    console.log("Web3 Provider Setup...");

    let _provider = await detectEthereumProvider({ silent: true });

    let _ethersProvider;
    if (Boolean(_provider)) {
      console.log("Metamask detected.");

      _ethersProvider = new ethers.BrowserProvider(_provider);
      setProvider(_ethersProvider);

      _provider.on("accountsChanged", _updateAccounts);
      _provider.on("chainChanged", _onChainChanged);
      _provider.on("changed", _onChainChanged);
      _provider.on("connect", onConnect);
      _provider.on("disconnect", onDisconnect);
      _provider.on("error", onError);

      await setupSigner(_ethersProvider);

      const { chainId } = await _ethersProvider.getNetwork();

      console.info(`Metamask Chain ID ${chainId}`);

      const isOnKChain = chainId.toString() === Config[ENV].chainId.toString();
      if (
        !window.location.pathname.includes("kingdom-bridge") &&
        !window.location.pathname.includes("exit-bridge")
      ) {
        if (isOnKChain) {
          setIsUsingKingdomChain(true);
          localStorage.setItem("walletConnected", "true");
        } else {
          if (!chainId || chainId.toString() === 0) {
            console.log("Unable to connect to any blockchain.");
          } else {
            setIsUsingKingdomChain(false);
            localStorage.removeItem("walletConnected");

            switchToKingdomChain();
            Messenger.warn(
              "Please make sure you're connected to the Kingdom Chain on Metamask. Don't know how? Find the menu Kingdom Chain => Add Kingdom Chain.",
              30000
            );
          }
        }
      } else {
        setIsUsingKingdomChain(isOnKChain);

        if (!isOnKChain) {
          localStorage.removeItem("walletConnected");
        } else {
          localStorage.setItem("walletConnected", "true");
        }
      }
    } else {
      console.log("Provider not detected. Using Default Ethers Provider.");
      _ethersProvider = ethers.getDefaultProvider();
      localStorage.removeItem("walletConnected");
    }

    return _ethersProvider;
  }

  // To be used after setupProvider:
  async function getProvider() {
    if (provider) return provider;

    try {
      const _provider = await detectEthereumProvider({ silent: true });
      const _ethersProvider = new ethers.BrowserProvider(_provider);
      return _ethersProvider;
    } catch (e) {
      try {
        return ethers.getDefaultProvider();
      } catch (e) {
        console.error("Critical Error getting provider:", e.stack);
        return null;
      }
    }
  }

  async function setupSigner(_ethersProvider = null) {
    const _provider = _ethersProvider ? _ethersProvider : await getProvider();

    let _signer;
    try {
      _signer = await _provider.getSigner();
      setSigner(_signer);

      const _initialBlockNumber = await _provider.getBlockNumber();
      setInitialBlockNumber(_initialBlockNumber);
      setInitialTimestamp(Date.now());

      return _signer;
    } catch (e) {
      console.log("Error setting up signer:", e.message);
      return null;
    }
  }

  async function getContract(contractName, readOnlyMode = false) {
    console.log(`Establishing connection with contract ${contractName}...`);

    let signerOrProvider;

    if (readOnlyMode) {
      const _provider = await getProvider();
      signerOrProvider = _provider;
    } else {
      if (signer) {
        signerOrProvider = signer;
      } else {
        const _provider = await getProvider();
        signerOrProvider = _provider;
        console.log(`Cannot find a valid signer; READ-ONLY access granted for ${contractName}}`);
      }
    }

    const contract = Contracts.keyToEthersContract(contractName, signerOrProvider);

    console.log(`Connection established with ${contractName}`);
    return contract;
  }

  async function syncBlockchainState() {
    // if we're at /kingdom-bridge, return:
    if (
      window.location.pathname.includes("kingdom-bridge") ||
      window.location.pathname.includes("exit-bridge")
    )
      return;

    try {
      console.log(`Syncing with the blockchain...`);

      const bctContract = await getContract("BCT", true);
      const farmBalance = await bctContract.balanceOf(Config[ENV].Farm.address);
      const _halvingsData = _processHalvingsData(farmBalance);

      const poolContract = await getContract("CommunityPool", true);
      const _poolReserves = await poolContract.getReserves();

      const koziPoolContract = await getContract("KoziPool", true);
      const _targetPrice = await koziPoolContract.getTargetPrice();

      const _result = {
        halvingsData: _halvingsData,
        poolReserves: _poolReserves,
        scales: FINAL_SCALES,
        updatedAt: new Date().getTime(),
        targetPrice: _targetPrice,
      };

      setBlockchainState(_result);

      console.log("Blockchain state synced:", _result);
    } catch (e) {
      console.warn("Error syncing first blockchain state (retrying):", e.message);

      if (!isRetrying) {
        Messenger.warn(
          "Please make sure that you're connected to the Kingdom Chain on Metamask and that you have registered on the Register page. Don't know how? Find the menu Kingdom Chain => Add Kingdom Chain.",
          30000
        );
      }

      setIsRetrying(true);

      // return a promise to retry:
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          await syncBlockchainState();
          resolve();
        }, 5000);
      });
    }
  }

  async function _updateAccounts(providedAccounts) {
    const _accounts =
      providedAccounts || (await window.ethereum.request({ method: "eth_accounts" }));

    if (_accounts.length === 0) {
      setAccounts(null);
      return;
    }

    setAccounts(_accounts);
    const _provider = await getProvider();
    if (_accounts.length > 0) {
      const _signer = _accounts[0];
      setSigner(_signer);
      console.log(`Metamask account switched to ${_signer} from Metamask ${_accounts[0]}`);
    } else {
      const _signer = await _provider.getSigner();
      setSigner(_signer);
      console.log(`Metamask account switched to ${_signer} from Metamask ${_accounts[0]}`);
    }
    // reload the website:
    window.location.reload();

    reconnectWallet();
  }

  async function _onChainChanged(chainId) {
    console.log(`Metamask chain changed to ${chainId}`);

    if (
      chainId.toString() !== Config[ENV].chainId.toString() &&
      !window.location.pathname.includes("kingdom-bridge") &&
      !window.location.pathname.includes("exit-bridge")
    ) {
      console.log("NOT connected to the Kingdom Chain");
      setIsUsingKingdomChain(false);
      localStorage.removeItem("walletConnected");
      await switchToKingdomChain();
    } else {
      if (chainId.toString() === Config[ENV].chainId.toString()) {
        console.log("2 Connected to the Kingdom Chain");
        setIsUsingKingdomChain(true);
        localStorage.setItem("walletConnected", "true");
      }
    }

    // reload the website:
    window.location.reload();
  }

  async function connectWallet() {
    if (accounts) return;

    try {
      const _provider = await setupProvider();
      await setupSigner(_provider);
      const _chainId = await getChainId();
      if (_chainId === ethers.toQuantity(Config[ENV].chainId)) {
        await syncBlockchainState();
      }

      setIsReady(true);
      console.log("Web3 Module is ready ✨");
    } catch (e) {
      setIsReady(false);
      console.error("Critical Error setting up WEB3:", e.stack);
    }

    // reload the website:
    window.location.reload();
  }

  const onConnect = async () => {
    console.log("🌍 Connected");
  };

  const onDisconnect = async () => {
    disconnectWallet();
    console.log("💢 Disconnected");
  };

  const onError = (error) => {
    console.log("Provider Error:", error);
  };

  const getAllowance = async (allowance) => {
    if (allowance.type === "erc20") {
      return getErc20Allowance(
        allowance.tokenContractName,
        allowance.addressToAllow,
        allowance.allowedContractName,
        allowance.allowedAssetDescription,
        allowance.successMessage
      );
    } else {
      return getErc721Allowance(
        allowance.tokenContractName,
        allowance.addressToAllow,
        allowance.allowedContractName,
        allowance.allowedAssetDescription,
        allowance.successMessage
      );
    }
  };

  async function getErc20Allowance(
    tokenContractName,
    addressToAllow,
    allowedContractName,
    allowedAssetDescription, // E.g. "MHT in your stash"
    successMessage // E.g. "You have migrated your stashes to Beast Kingdom!"
  ) {
    try {
      console.log(
        `> Getting Erc20 ${tokenContractName} allowance for: ${allowedAssetDescription} => to ${allowedContractName} (${addressToAllow})`
      );

      // Check if the user already has a sufficient allowance:
      const tokenContract = await getContract(tokenContractName);

      const allowance = new BigNumber(
        await tokenContract.allowance(signer.address, addressToAllow)
      );
      console.log(`> Current allowance: ${allowance.toString()}`);

      if (allowance.gte(BigNumber("999999000000000000000000"))) {
        // This is a success, we don't even need to ask for allowance
        console.log(`> User had already given enough allowance.`);
        return true;
      }

      console.log(`> Asking for user's allowance...`);
      Messenger.info(
        `Allow the ${allowedContractName} smart contract to access ${allowedAssetDescription}.`
      );

      // pass 999 million as string to approve the max amount
      const tx = await tokenContract.approve(addressToAllow, "999999999000000000000000000");
      Messenger.info("Transaction sent. Please wait for it to be mined.", 20000);
      // Wait for the transaction to be mined
      const receipt = await tx.wait();

      // if the tx failed, we need to check the receipt
      if (receipt.status !== 1) {
        console.log(`> Allowance tx failed.`);
        console.log(`> Receipt: ${JSON.stringify(receipt, null, 2)}`);

        Messenger.error(`Error. Failed do give allowance.`);
        return false;
      }

      Messenger.success(`Allowance completed. ${successMessage}.`);

      console.log(`> Allowance completed.`);

      return true;
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        Messenger.error("Transaction cancelled.");

        console.log(`> Allowance tx cancelled by user.`);
        return false;
      } else {
        console.error(error);
        Messenger.error(
          `Error getting an allowance. Please reload the page and try again or ask for help on Discord. Take a screenshot of this message: ${error.message}`,
          20000
        );
      }

      console.log(`> Allowance tx failed with Error.`);
      return false;
    }
  }

  async function getErc721Allowance(
    tokenContractName,
    addressToAllow,
    allowedContractName,
    allowedAssetDescription, // E.g. "NFTs in your Metamask"
    successMessage // E.g. "You have successfully migrated NFTs to Beast Kingdom!"
  ) {
    try {
      console.log(
        `> Getting Erc721 allowance for: ${allowedAssetDescription} => to ${allowedContractName} (${addressToAllow})`
      );

      // Check if the user already has a sufficient allowance:
      const tokenContract = await getContract(tokenContractName);
      const allowance = await tokenContract.isApprovedForAll(signer.address, addressToAllow);
      console.log(`> Is Approved For All: ${allowance}`);
      if (allowance) {
        // This is a success, we don't even need to ask for allowance
        console.log(`> User had already given allowance.`);
        return true;
      }

      console.log(`> Asking for user's allowance...`);

      Messenger.info(
        `Allow the ${allowedContractName} contract to access ${allowedAssetDescription}.`
      );

      const tx = await tokenContract.setApprovalForAll(addressToAllow, true);
      Messenger.info("Transaction sent. Please wait for it to be mined.", 20000);
      // Wait for the transaction to be mined
      const receipt = await tx.wait();

      // if the tx failed, we need to check the receipt
      if (receipt.status !== 1) {
        console.log(`> Allowance tx failed.`);
        console.log(`> Receipt: ${JSON.stringify(receipt, null, 2)}`);

        Messenger.error(
          `Error. Failed do give allowance. Please try again or ask for help on Discord. Error: E22.`,
          20000
        );

        return false;
      }

      Messenger.success(`Allowance completed. ${successMessage}.`);

      console.log(`> Allowance completed.`);

      return true;
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        Messenger.error("Transaction cancelled.");

        console.log(`> Allowance tx cancelled by user.`);
        return false;
      } else {
        console.error(error);

        Messenger.error(
          `Error. Please reload the page and try again or ask for help on Discord. Take a screenshot of this message: ${error.message}`,
          20000
        );
      }

      console.log(`> Allowance tx failed with Error.`);
      return false;
    }
  }

  async function execFunction({
    contractName,
    functionName,
    functionParams = [],
    allowance = null, // { type, tokenContractName, addressToAllow, allowedContractName, minAmount, allowedAssetDescription, successMessage }
    successMessage = null, // E.g. "You have successfully migrated your stashes to Beast Kingdom!"
    errorList = null,
    navigateOnSuccess = null,
    koziAmount = 0,
    minedCallback = null,
    errorCallback = null,
  }) {
    let functionParamsString = functionParams.join(",");
    try {
      functionParamsString = JSON.stringify(functionParams);
    } catch (e) {}
    if (BigNumber(koziAmount).gt(1)) {
      console.warn(
        `Executing PAYABLE SOL function ${contractName}.${functionName}(${functionParamsString}) with ${koziAmount} KOZI`
      );
    } else {
      console.log(
        `Executing SOL function ${contractName}.${functionName}(${functionParamsString})`
      );
    }

    if (!isReady) {
      console.error("|>>> Critical Error: execFunction called before isReady");
      if (errorCallback) errorCallback("Critical Error: execFunction called before isReady");
      return null;
    }

    const contract = await getContract(contractName);

    if (allowance?.tokenContractName) {
      allowance.type = allowance?.type || "erc20";

      const allowanceSuccess = await getAllowance(allowance);
      if (!allowanceSuccess) {
        if (errorCallback) errorCallback("Error getting allowance");
        return;
      }
    }

    let e;
    let tx;
    let receipt;
    try {
      if (functionParams.length > 0) {
        if (koziAmount > 0) {
          tx = await contract[functionName](...functionParams, { value: koziAmount });
        } else {
          tx = await contract[functionName](...functionParams);
        }
      } else {
        if (koziAmount > 0) {
          tx = await contract[functionName]({ value: koziAmount });
        } else {
          tx = await contract[functionName]();
        }
      }

      Messenger.info("Transaction sent to the blockchain. Please wait for it to be mined.", 20000);

      // Wait for the transaction to be mined
      receipt = await tx.wait();

      console.log(`|>>> Function ${contractName}.${functionName} successfully executed.`);

      if (successMessage) {
        Messenger.success(successMessage, 15000);
      }
    } catch (error) {
      e = error;
      if (e.message.indexOf("transaction underpriced") !== -1) {
        Messenger.error("Blockhain fee too low. Please try again with a higher fee.");
        if (errorCallback)
          errorCallback("Blockhain fee too low. Please try again with a higher fee.");
        return false;
      }

      if (errorList) {
        errorList.push(
          {
            reason: "onQ",
            message: "This beast is on a quest. Please finish it first.",
          },
          {
            reason: "require(false)",
            message: `Invalid transaction.`,
          },
          {
            reason: "KPayments: Not Enough Kozi",
            message: `Not enough KOZI to execute this function.`,
          }
        );

        for (let i = 0; i < errorList.length; i++) {
          const element = errorList[i];

          let lowerCasedReason;
          try {
            lowerCasedReason = error.reason.toLowerCase();
          } catch (e) {
            lowerCasedReason = "";
          }

          if (
            element?.reason &&
            (error.reason === element?.reason ||
              lowerCasedReason === `execution reverted: ${element?.reason}`)
          ) {
            Messenger.error("Error: " + element.message);
            if (errorCallback) errorCallback(element.message);
            return element.message;
          } else if (element?.code && error.code === element?.code) {
            Messenger.error("Error: " + element.message);
            if (errorCallback) errorCallback(element.message);
            return element.message;
          }
        }
      }

      if (error.code === "ACTION_REJECTED") {
        Messenger.error("Transaction cancelled.");
        if (errorCallback) errorCallback("Transaction cancelled.");
        console.log(`> Function ${functionName} execution cancelled by user.`);
        return "cancelled by user";
      } else {
        console.log(`> Function ${functionName} failed with Error:`);
        console.error(error);
        if (errorCallback) errorCallback(error.message);
        Messenger.error(
          `Error. Please reload the page and try again or ask for help on Discord. Take a screenshot of this message: ${error.message}`,
          20000,
          0,
          Messenger.defaultPosition,
          true
        );
        return error.reason || error.message || "unknown error";
      }
    }

    if (minedCallback) {
      minedCallback(receipt);
      return true;
    }

    if (navigateOnSuccess && !e) {
      console.log("WEB3Context :: Navigate on Success");
      //window.location.href = navigateOnSuccess;
      // let's remove https://beastkingdom.io from the url:
      let navigateOnSuccessNoDomain = navigateOnSuccess.replace("https://beastkingdom.io", "");
      navigateOnSuccessNoDomain = navigateOnSuccess.replace("https://www.beastkingdom.io", "");

      // let's also remove http://localhost:3000
      const navigateOnSuccessNoLocalhost = navigateOnSuccessNoDomain.replace(
        "http://localhost:3000",
        ""
      );

      const navigateNoStagingIssues = navigateOnSuccessNoLocalhost.replace(
        "http://18.221.130.161",
        ""
      );

      navigate(navigateNoStagingIssues);

      // TODO: refresh the player data
      setSigner(null);
      setTimeout(setupSigner, 100);
      return tx.hash;
    }

    if (!navigateOnSuccess && !e) {
      return tx.hash;
    }
  }

  async function consult({ contractName, functionName, functionParams = [] }) {
    if (!isReady) {
      console.error("|>>> Critical Error: consult called before isReady");
      return null;
    }

    console.log(`Consulting SOL function ${contractName}.${functionName}(${[...functionParams]})`);

    try {
      const contract = await getContract(contractName, true); // try using a read-only provider first (faster)
      let result;
      if (functionParams.length > 0) {
        result = await contract[functionName](...functionParams);
      } else {
        result = await contract[functionName]();
      }

      return result;
    } catch (e) {
      try {
        const contract = await getContract(contractName);
        let result;
        if (functionParams.length > 0) {
          result = await contract[functionName](...functionParams);
        } else {
          result = await contract[functionName]();
        }

        return result;
      } catch (e) {
        console.error("|>>> Critical Error in consult:", e);
        return `Error: ${e.reason || e.message}`;
      }
    }
  }

  async function watchBct() {
    if (typeof window !== "undefined") {
      await window.ethereum
        .request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: Config[ENV].BCT.address, // The address of the token.
              symbol: "BCT", // A ticker symbol or shorthand, up to 5 characters.
              decimals: 18, // The number of decimals in the token.
              image: "https://mammal-general-assets.s3.amazonaws.com/metamask-icon2.png", // A string URL of the token logo.
            },
          },
        })
        .catch((error) => {
          console.log("Error watching BCT:", error);
        });
    }
  }

  async function watchUsdc() {
    if (typeof window !== "undefined") {
      await window.ethereum
        .request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: Config[ENV].Stabletoken.address, // The address of the token.
              symbol: "USDC", // A ticker symbol or shorthand, up to 5 characters.
              decimals: 18, // The number of decimals in the token.
              image: "https://mammal-general-assets.s3.amazonaws.com/resource-USDC.png", // A string URL of the token logo.
            },
          },
        })
        .catch((error) => {
          console.log("Error watching BCT:", error);
        });
    }
  }

  function isAddress(address) {
    try {
      return ethers.isAddress(address);
    } catch (e) {
      return false;
    }
  }

  // Halvings:
  function _processHalvingsData(farmBalance) {
    let halvingsHad = 0;
    let halvingsLeft = 0;
    let currentScale = 0;
    let nextHalvingThreshold = BigNumber(0);
    let totalAmountToBurn = BigNumber(0);
    let leftToHalve = BigNumber(0);

    for (let i = 0; i < HALVING_THRESHOLDS.length; i++) {
      if (BigNumber(farmBalance).gt(HALVING_THRESHOLDS[i].threshold)) {
        halvingsHad = HALVING_THRESHOLDS[i].count - 1;
        halvingsLeft = 9 - halvingsHad;
        currentScale = HALVING_THRESHOLDS[i].previousScale;
        nextHalvingThreshold = HALVING_THRESHOLDS[i].threshold;
        totalAmountToBurn = HALVING_THRESHOLDS[i - 1].threshold.minus(
          HALVING_THRESHOLDS[i].threshold
        );
        leftToHalve = BigNumber(farmBalance).minus(nextHalvingThreshold);
        break;
      }
    }

    return {
      halvingsHad,
      halvingsLeft,
      currentScale,
      currentFarmBalance: BigNumber(farmBalance),
      nextHalvingThreshold,
      totalAmountToBurn,
      leftToHalve,
    };
  }

  // Conversions between BCT / USDC:
  const refreshPoolReserves = async () => {
    if (!isReady) {
      console.error("|>>> Critical Error: refreshPoolReserves called before isReady");
      return null;
    }

    try {
      const contract = await getContract("CommunityPool", true);
      const currentReserves = await contract.getReserves();
      return currentReserves;
    } catch (e) {
      console.warn(`Error fetching pool reserves: ${e.message}`);
    }
  };

  const bctToBusd = async (amountInBct) => {
    if (!isReady) {
      console.error("|>>> Critical Error: bctToBusd called before isReady");
      return BigNumber(0);
    }
    if (!blockchainState?.poolReserves[0] || !blockchainState?.poolReserves[1]) {
      console.error("|>>> Critical Error: bctToBusd called before poolReserves are ready");
      return BigNumber(0);
    }

    const result = _bctToBusd(
      amountInBct,
      blockchainState.poolReserves[0],
      blockchainState.poolReserves[1]
    );

    return result;
  };

  const bctToEbct = (amountInBct) => {
    return _bctToEbct(amountInBct);
  };

  const busdToBct = async (amountInBusd) => {
    if (!isReady) {
      console.error("|>>> Critical Error: busdToBct called before isReady");
      return BigNumber(0);
    }
    if (!blockchainState?.poolReserves[0] || !blockchainState?.poolReserves[1]) {
      console.error("|>>> Critical Error: busdToBct called before poolReserves are ready");
      return BigNumber(0);
    }

    const result = _busdToBct(
      amountInBusd,
      blockchainState.poolReserves[0],
      blockchainState.poolReserves[1]
    );

    return result;
  };

  const busdToEbct = async (amountInBusd) => {
    if (!isReady) {
      console.error("|>>> Critical Error: busdToEbct called before isReady");
      return BigNumber(0);
    }

    const result = _busdToEbct(amountInBusd);

    return result;
  };

  const ebctToBct = (amountInEbct) => {
    if (!isReady) {
      console.error("|>>> Critical Error: ebctToBct called before isReady");
      return BigNumber(0);
    }
    if (blockchainState?.poolReserves[0] || blockchainState?.poolReserves[1]) {
      console.error("|>>> Critical Error: ebctToBct called before poolReserves are ready");
      return BigNumber(0);
    }

    const result = _ebctToBct(
      amountInEbct,
      blockchainState.poolReserves[0],
      blockchainState.poolReserves[1]
    );

    return result;
  };

  const ebctToBusd = async (amountInEbct) => {
    if (!isReady) {
      console.error("|>>> Critical Error: ebctToBusd called before isReady");
      return BigNumber(0);
    }
    if (blockchainState?.poolReserves[0] || blockchainState?.poolReserves[1]) {
      console.error("|>>> Critical Error: ebctToBusd called before poolReserves are ready");
      return BigNumber(0);
    }

    const result = _ebctToBusd(
      amountInEbct,
      blockchainState.poolReserves[0],
      blockchainState.poolReserves[1]
    );

    return result;
  };

  const getScales = async () => {
    return FINAL_SCALES;
  };

  const getUpgradesScale = async () => {
    const _scales = await getScales();
    return _scales.upgradesScale;
  };

  const getFarmingScale = async () => {
    const _scales = await getScales();
    return _scales.farmingScale;
  };

  const parseLogs = async (contractAddress, logs) => {
    const parsedLogs = logs.map((log) => {
      const contract = getContract(contractAddress, true);
      const parsedLog = contract.interface.parseLog(log);
      return parsedLog;
    });

    return parsedLogs;
  };

  useEffect(() => {
    // Check if the user was connected previously and reconnect
    const reconnectOnLoad = async () => {
      const wasConnected = localStorage.getItem("walletConnected") === "true";
      if (wasConnected) {
        await reconnectWallet();
      }
    };

    reconnectOnLoad();
  }, []);

  async function reconnectWallet() {
    // this should be called when the suer refreshes the page
    // or when the user changes the network on Metamask
    console.log("Reconnecting wallet...");

    try {
      const _provider = await setupProvider();
      await setupSigner(_provider);
      await syncBlockchainState();

      setIsReady(true);

      if (
        !window.location.pathname.includes("kingdom-bridge") &&
        !window.location.pathname.includes("exit-bridge")
      ) {
        await switchToKingdomChain();
        localStorage.setItem("walletConnected", "true");
      }
      console.log("Web3 Module is ready ✨");
    } catch (e) {
      setIsReady(false);
      console.error("Critical Error setting up WEB3:", e.stack);
    }
  }

  const disconnectWallet = () => {
    // Logic to disconnect the wallet
    localStorage.removeItem("walletConnected");
  };

  async function addKingdomChainToMetamask() {
    try {
      const _provider = new ethers.BrowserProvider(window.ethereum);
      await _provider.send("wallet_addEthereumChain", [
        {
          chainId: ethers.toQuantity(Config[ENV].chainId),
          chainName: "Kingdom Chain",
          rpcUrls: ["https://kingdomchain.observer/rpc"],
          iconUrls: [
            "https://mammal-general-assets.s3.amazonaws.com/kingdomchain256.png",
            "https://mammal-general-assets.s3.amazonaws.com/kingdomchain256.png",
          ],
          nativeCurrency: {
            name: "Kozi",
            symbol: "KOZI",
            decimals: 18,
          },
          blockExplorerUrls: ["https://beastkingdom.io/travelsong/"],
        },
      ]);
    } catch (error) {
      Messenger.error("Log in to your Metamask");
      console.log(error);
    }
  }

  async function switchToBnbChain() {
    console.log("Connecting to BNB Chain...");
    const _provider = new ethers.BrowserProvider(window.ethereum);

    try {
      // send a request to the wallet to switch the network and select the BNB Chain
      await _provider.send("wallet_switchEthereumChain", [{ chainId: ethers.toQuantity(56) }]);
      const currentChainId = await getChainId();
      if (currentChainId !== ethers.toQuantity(56)) {
        Messenger.info("Let's switch to BNB Chain. Check your Metamask.", 10000);
      }
    } catch (error) {
      if (error.code === 4001 || error.reason === "rejected") {
        console.log("the user doesn't want to change the network!");
        Messenger.error("Chain switch cancelled by user.", 10000);
      } else if (error.code === 4902) {
        console.log("this network is not in the user's wallet");
        Messenger.error("Please add the BNB Chain to Metamask first.", 10000);
      } else if (error.code === -32002) {
        console.log("Switch chain request already pending");
        Messenger.error(`Check your Metamask`, 5000);
      } else {
        /*
        console.log(`Error ${error.code}: ${error.message}`);
        Messenger.error(
          `Unknown error (E23). Plase take a screenshot of this message and open a suport ticket on Discord: ${error.message}`,
          30000
        );
        */
      }

      return false;
    }

    const _signer = await _provider.getSigner();
    return { provider: _provider, signer: _signer };
  }

  async function switchToKingdomChain() {
    // let's make suire we're not already connected to it:
    const currentChainId = await getChainId();
    if (currentChainId === ethers.toQuantity(Config[ENV].chainId)) {
      console.log("Already connected to the Kingdom Chain");
      return null;
    }

    // let's see if we're connected to the kingdom chain
    const _provider = await getProvider();

    try {
      // send a request to the wallet to switch the network and select the BNB Chain
      await _provider.send("wallet_switchEthereumChain", [
        { chainId: ethers.toQuantity(Config[ENV].chainId) },
      ]);
      const currentChainId = await getChainId();
      if (currentChainId !== ethers.toQuantity(Config[ENV].chainId) && currentChainId !== null) {
        Messenger.info(
          `Let's switch to the Kingdom Chain. Check your Metamask. (${currentChainId})`,
          10000
        );
      }
    } catch (error) {
      if (error.code === 4001) {
        console.log("the user doesn't want to change the network!");
        Messenger.error("Chain switch cancelled by user.", 10000);
      } else if (error.code === 4902) {
        console.log("this network is not in the user's wallet");
        Messenger.error(
          "Please add the Kingdom Chain to Metamask first. Don't know how? Find the menu Kingdom Chain => Add Kingdom Chain.",
          10000
        );
      } else if (error.message.includes("already pending")) {
        console.log("Switch chain request already pending");
        Messenger.error(`Check your Metamask`, 5000);
      } else if (
        error.message.includes("Try adding the chain using wallet_addEthereumChain first.")
      ) {
        console.log("Kingdom Chain not found on Metamask");
        Messenger.error(`Please add Kingdom Chain to Metamask to navigate.`, 5000);
      } else {
        //console.log(`[E22] Unknown Error: ${error.code}: ${error.message}`);
        /*
        Messenger.error(
          `Unknown error (E22). Plase take a screenshot of this message and open a suport ticket on Discord: ${error.message}`,
          30000
        );
        */
        // Just wait a bit
      }

      return null;
    }

    const _signer = await _provider.getSigner();
    return { provider: _provider, signer: _signer };
  }

  async function getChainId(_provider) {
    if (!_provider && !provider) return null;
    let _chainId;
    if (_provider) {
      const { chainId } = await _provider.getNetwork();
      _chainId = chainId;
    } else {
      const { chainId } = await provider.getNetwork();
      _chainId = chainId;
    }

    return _chainId;
  }

  function stableToTargetKozi(amountInStable, discountPct = 0) {
    if (!blockchainState) {
      console.error("blockchainState not ready");
      TimerManager.start(
        "baseReload",
        () => {
          window.location.reload();
        },
        5000
      );

      return BigNumber(0);
    } else {
      TimerManager.stop("baseReload");
    }

    // we already have the target price in the blockchainState
    const targetPrice = blockchainState.targetPrice;
    const amountInKozi = BigNumber(amountInStable).div(targetPrice);

    if (discountPct === 0) {
      discountPct = GLOBAL_KOZI_DISCOUNT_PCT;
    }

    if (discountPct > 0) {
      const discount = amountInKozi.times(discountPct).div(100);
      return amountInKozi.minus(discount);
    }

    return amountInKozi;
  }

  return (
    <Web3Context.Provider
      value={{
        signer,
        provider,
        initialBlockNumber,
        initialTimestamp,
        setupSigner,
        watchBct,
        watchUsdc,
        getErc20Allowance,
        getErc721Allowance,
        execFunction,
        consult,
        IS_PAUSED,
        PAUSED_MESSAGE,
        PAUSED_TITLE,
        isAddress,
        isReady,
        blockchainState,
        bctToBusd,
        bctToEbct,
        busdToBct,
        busdToEbct,
        ebctToBct,
        ebctToBusd,
        getScales,
        getUpgradesScale,
        getFarmingScale,
        connectWallet,
        isUsingKingdomChain,
        parseLogs,
        switchToBnbChain,
        switchToKingdomChain,
        getChainId,
        addKingdomChainToMetamask,
        stableToTargetKozi,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export function useWeb3Context() {
  const context = useContext(Web3Context);

  return context;
}
