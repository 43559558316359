export const promos = [
  {
    id: 201,
    name: "Unpaid Mechanic",
    rewardedFor: "First ever community-made how-to-video on Kingdom Chain",
    frameId: 1,
    originalOwner: "Unpaid Mechanic",
  },
  {
    id: 211,
    name: "LongTerm Stacker",
    rewardedFor: "Second ever community-made how-to-video on Kingdom Chain",
    frameId: 1,
    originalOwner: "Unpaid Mechanic",
  },
  {
    id: 14600,
    name: "Mighty Alpha Kong",
    rewardedFor: "Kingdom Chain Alpha Tester",
    frameId: 2,
    originalOwner: "Zoen Cottini",
  },
  {
    id: 14601,
    name: "Mighty Alpha Kong",
    rewardedFor: "Kingdom Chain Alpha Tester",
    frameId: 2,
    originalOwner: "Sharkymate",
  },
  {
    id: 14602,
    name: "Mighty Alpha Kong",
    rewardedFor: "Kingdom Chain Alpha Tester",
    frameId: 2,
    originalOwner: "Jonhing",
  },
  {
    id: 14603,
    name: "Mighty Alpha Kong",
    rewardedFor: "Kingdom Chain Alpha Tester",
    frameId: 2,
    originalOwner: "Meinrath 2 por engano! (0x8105d0dD4e15F62b6d859a56ee357DCA0D5BE9f6)",
  },
  {
    id: 14604,
    name: "Mighty Alpha Kong",
    rewardedFor: "Kingdom Chain Alpha Tester",
    frameId: 2,
    originalOwner: "Cartola",
  },
  {
    id: 14605,
    name: "Mighty Alpha Kong",
    rewardedFor: "Kingdom Chain Alpha Tester",
    frameId: 2,
    originalOwner: "Myk",
  },
  {
    id: 14606,
    name: "Mighty Alpha Kong",
    rewardedFor: "Kingdom Chain Alpha Tester",
    frameId: 2,
    originalOwner: "Meinrath",
  },
  {
    id: 17975,
    name: "Moolight",
    rewardedFor: "Third video on Kingdom Chain",
    frameId: 1,
    originalOwner: "Unpaid Mechanic",
  },
  {
    id: 14772,
    name: "IG the Linksmith",
    rewardedFor: "Video Tutorial",
    frameId: 1,
    originalOwner: "IG",
  },

  {
    id: 18828,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18823,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18818,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18815,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18829,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18824,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18819,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18817,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18816,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18830,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18825,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18820,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18831,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18826,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18821,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18832,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 18827,
    name: "Cacodemon",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 50,
    name: "Hydra",
    rewardedFor: "Prosperity Wars 2024",
    frameId: 5,
    originalOwner: "?",
  },
  {
    id: 10002,
    name: "Apocalypse Now",
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 216,
    name: "Mutant Spirit Sindel",
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 11345,
    name: "Lord Shadow",
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 641,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 1588,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 437,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 3994,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 7484,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 6563,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 1085,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 3924,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 5214,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 2642,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 1666,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 4026,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 5150,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 12619,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 16320,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 16267,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 12073,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 13087,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 13616,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 10667,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 15089,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 13981,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 14449,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 9565,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 17617,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 17927,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 11405,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 18118,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 19086,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 8270,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 10504,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 14200,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 19662,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 17706,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 16315,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 18401,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 200,
    rewardedFor: "Gravedance",
    frameId: 6,
  },

  {
    id: 1403,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 2781,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 3204,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 4526,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 5094,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 6355,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 7775,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 8641,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 9901,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 10084,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 11229,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 12615,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 13463,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 14518,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 15193,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 16005,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 17242,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 18834,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 19499,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
  {
    id: 20700,
    rewardedFor: "Gravedance",
    frameId: 6,
  },
];

export function isPromoNft(nftId) {
  return promos.some((promo) => promo.id === nftId);
}

export function getPromoInfo(nftId) {
  return promos.find((promo) => promo.id === nftId);
}
