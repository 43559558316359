import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 218px;
  width: 198px;
  background: ${(props) => props.background};
  margin-bottom: 3px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  width: 100%;
  height: 100%;
  border-radius: 4px;
  outline: 4px solid black;
`;

export const TopImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  background: ${(props) => props.background};
  outline: 1px solid black;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    opacity: 1;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  position: relative;
  top: 16px;

  border-radius: 50%;
  //outline: 3px solid white;

  background: ${(props) => props.background};

  img {
    width: 50px;
    object-fit: fill;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 6px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background};
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  font-size: 17px;
  color: #fff;
  border: 1 px solid red;
  position: relative;
  top: 10px;
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
  background: ${(props) => props.background};
`;

export const QuantityContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 20px;
  color: #fff;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #fff;
  margin: 4px 4px 4px 4px;
  width: 90%;
  line-height: 1.1;

  position: relative;
  top: 10px;

  background: ${(props) => props.background};
`;

export const SellerPositioner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 32px;
`;

export const SellerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 24px;
  border-radius: 0px 0px 4px 4px;
  outline: 2px solid black;
  position: relative;
  top: 12px;
`;

export const Seller = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #ddd;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 4px 4px;

  cursor: ${(props) => (props.disable ? "default" : "pointer")};

  ${(props) =>
    !props.disable &&
    css`
      &:hover {
        color: #fff;
        background: ${(props) => props.background};
      }

      &:active {
        filter: brightness(0.8);
      }
    `}
`;

export const MoreLikeThisContainer = styled.div`
  display: flex;
  align-items: right;
  justify-content: flex-end;
  text-align: right;
  font-size: 12px;
  color: #ddd;
  width: 50px;
  height: 140px;
  position: absolute;
  transform: translateY(38px) translateX(75px);
`;

export const MoreLikeThisButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  margin: 0px 4px 0px 0px;
  border-radius: 4px;

  height: 20px;
  width: 20px;

  &:hover {
    background: ${(props) => props.background};
  }

  &:active {
    background: ${(props) => props.background};
    filter: brightness(0.9);
  }

  img {
    width: 60%;
  }
`;

export const CancelContainer = styled.div`
  display: flex;
  align-items: right;
  justify-content: flex-end;
  text-align: right;
  font-size: 12px;
  color: #ddd;
  width: 50px;
  height: 140px;
  position: absolute;
  transform: translateY(38px) translateX(75px);
`;

export const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  margin: 0px 4px 0px 0px;
  border-radius: 4px;

  height: 20px;
  width: 20px;

  &:hover {
    background: ${(props) => props.background};
  }

  &:active {
    background: ${(props) => props.background};
    filter: brightness(0.9);
  }

  img {
    filter: invert(1);
    width: 70%;
  }
`;
