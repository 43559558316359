import React, { useState } from "react";

import ClickableBox from "../clickableBox";
import {
  Container,
  TitleWrapper,
  TitleWrapperOverlayCenter,
  TitleWrapperOverlayClickable,
} from "./styles";

const MasteryGroup = ({
  title = "Masteries",
  groupWidth = "300px",
  startCollapsed = false,
  isCollapsable = true,
  buttons = [],
}) => {
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed);

  const handleTitleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Container groupWidth={groupWidth} isCollapsed={isCollapsed}>
      <TitleWrapper groupWidth={groupWidth} isCollapsed={isCollapsed}>
        <p>{title}</p>
      </TitleWrapper>
      <TitleWrapperOverlayClickable
        onClick={isCollapsable ? handleTitleClick : null}
        isCollapsed={isCollapsed}
      ></TitleWrapperOverlayClickable>
      <TitleWrapperOverlayCenter buttonCount={buttons.length}></TitleWrapperOverlayCenter>
      {!isCollapsed &&
        buttons.map((button, index) => (
          <ClickableBox
            key={`${button.title}-${button.resource}-${button.maxQuantity}-${index}`}
            title={button.title}
            resource={button.resource}
            currentQuantity={button.currentQuantity}
            maxQuantity={button.maxQuantity}
            tooltip={button.tooltip}
            onClick={button.onClick}
            isActive={button.isActive}
          />
        ))}
    </Container>
  );
};

export default MasteryGroup;
