export const NOBLE_TITLES = [
  "King",
  "Grand Vizier",
  "Duke",
  "Marquess",
  "Count",
  "Viscount",
  "Baron",
  "High Lord",
  "Lord",
  "Knight Commander",
  "High Knight",
  "High Knight",
  "High Knight",
  "High Knight",
  "High Knight",
  "Knight",
  "Knight",
  "Knight",
  "Knight",
  "Knight",
  "Knight",
  "Knight",
  "Knight",
  "Knight",
  "Knight",
];
