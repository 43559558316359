import styled from "styled-components";

const Text = styled.p`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  font-size: ${(props) => (props.scale ? `${12.5 * props.scale}px` : `12.5px`)};
  letter-spacing: -0.06em;
  background: -webkit-linear-gradient(#fff, #fff);
  color: #fff !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
`;

const RankNumber = ({ children, scale }) => {
  return <Text scale={scale}>{children}</Text>;
};

export default RankNumber;
