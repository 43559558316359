import styled, { keyframes, css } from "styled-components";

const colorCycle = keyframes`
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const shine = keyframes`
  0% {
    filter: brightness(1);
  }  
  50% {
    filter: brightness(3.2);
  }
  100% {
    filter: brightness(1);
  }
`;

export const InfluencerOverlay = styled.img`
  position: absolute;
  transform: ${(props) =>
    props.scale < 1
      ? `translate(-${10 * props.scale}px, -${14 * props.scale}px);`
      : `translate(-7px, -11px);`};
  width: ${(props) => (props.scale < 1 ? `${220 * props.scale}px` : `214px`)};
  height: ${(props) => (props.scale < 1 ? `${275 * props.scale}px` : `271px`)};
  pointer-events: none;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1));
  z-index: 100;

  ${(props) =>
    props.cycleColors &&
    css`
      animation: ${colorCycle} 10s linear infinite;
    `}

  ${(props) =>
    !props.lockSize &&
    `
  @media (max-width: 450px) {
    margin-top: ${String(props.scale ? `${2 * props.scale}px` : `2px`)};
    width: ${String(props.scale ? `${164 * props.scale}px` : `164px`)};
    height: ${String(props.scale ? `${204 * props.scale}px` : `204px`)};
  }`}
`;
