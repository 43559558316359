import styled, { keyframes } from "styled-components";

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: auto;

  flex-wrap: wrap;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 10px;
`;

export const NobleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: ${(props) =>
    props.position === 1
      ? "#000000"
      : props.position <= 4
      ? "#000002"
      : props.position <= 10
      ? "#000004"
      : props.position <= 15
      ? "#000006"
      : "#000008"};
  border-radius: 10px;
  margin: 2px;
  width: 335px;
`;

export const NobleTitle = styled.div`
  font-size: ${(props) => (props.pos === 1 ? "18px" : "14px")};
  color: ${(props) => (props.name === "" ? "#666" : "#CC0")};
  margin-bottom: 6px;
  font-family: "Metropolis", sans-serif;
`;

export const NobleNameContainer = styled.div`
  display: flex;
`;

export const NobleName = styled.div`
  font-size: 14px;
  color: white;
  padding: 4px 12px 4px 12px;
  cursor: default;
`;

export const FantasyButton = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isAdvanced ? "#ff0" : "#fff")};
  cursor: pointer;
  background-color: #000;
  outline: ${(props) => (props.isAdvanced ? "1px solid yellow" : "1px solid white")};
  border-radius: 4px;
  position: relative;
  right: 6px;
  margin-top: -10px;
  margin-bottom: 10px;

  padding: 4px 8px;
`;
