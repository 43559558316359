import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  margin-bottom: 100px;
`;

export const BlockDetails = styled.div`
  width: 100%;
  min-width: 390px;
  max-width: 800px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 28px;
`;

export const AdvancedButton = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isAdvanced ? "#ff0" : "#fff")};
  cursor: pointer;
  background-color: #000;
  outline: 1px solid white;
  border-radius: 4px;
  position: relative;
  top: 2px;

  margin-left: auto;
  padding: 4px 8px;
`;

export const DetailItem = styled.div`
  margin: 10px 0;
  padding: 4px 4px 4px 0;
  display: flex;
  border-bottom: 1px solid #e1e1e1;

  // if this is the last item, remove the border
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 5px;
  }

  &:first-child {
    padding-top: 0;
    margin-top: 8px;
  }

  font-size: 12px;
`;

export const NotFound = styled.div`
  width: 100%;
  max-width: 600px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 14px;
  padding: 20px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 1.4;
`;
