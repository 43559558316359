import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/header/Header.jsx";
import ItemValue from "../components/travel-song-screen/item-value/ItemValue.jsx";

import ContractsConfig from "../utils/Contracts.js";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";
import { useWeb3Context } from "../contexts/Web3Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 400px;
`;

const DetailItem = styled.div`
  margin: 10px 0 20px;
  padding: 4px 4px 8px 0;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: center;

  // if this is the last item, remove the border
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 5px;
  }
`;

const SmartContracts = () => {
  const { watchBct, watchUsdc } = useWeb3Context();
  const navigate = useNavigate();
  const searchCallback = (address) => () => {
    navigate(`/travelsong/contract/${address}`);
  };

  const searchTokenCallback = (address) => () => {
    navigate(`/travelsong/token/${address}`);
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">📑</h1>
                    <h1 className="heading text-center">Smart Contracts</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      Click them to view on Travel Song
                    </h5>
                    <Container>
                      <SubContainer>
                        <h3>Tokens:</h3>
                        <DetailItem>
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Stabletoken")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchTokenCallback(
                              ContractsConfig.keyToAddress("Stabletoken")
                            )}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("BCT")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchTokenCallback(ContractsConfig.keyToAddress("BCT"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("BkNft")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("BkNft"))}
                          />
                        </DetailItem>

                        <h3>Kingdom Chain:</h3>
                        <DetailItem>
                          <ItemValue
                            value={ContractsConfig.keyToAddress("KoziPool")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("KoziPool"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("KingdomBridge")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("KingdomBridge"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("KingdomNobles")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("KingdomNobles"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("KingdomFunds")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("KingdomFunds"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("KingdomFlow")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("KingdomFlow"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("KoziPayments")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("KoziPayments"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("DelayedRandomResult")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(
                              ContractsConfig.keyToAddress("DelayedRandomResult")
                            )}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("EntropyGenerator")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(
                              ContractsConfig.keyToAddress("EntropyGenerator")
                            )}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("FurAndFortune")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("FurAndFortune"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("CosmicFund")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("CosmicFund"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("BkCommonwealthFund")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(
                              ContractsConfig.keyToAddress("BkCommonwealthFund")
                            )}
                          />
                        </DetailItem>

                        <h3>Beast Kingdom:</h3>
                        <DetailItem>
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Farm")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("Farm"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("QuestManager")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("QuestManager"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Stasher")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("Stasher"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("CommunityPool")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("CommunityPool"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Marketplace")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("Marketplace"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Upgrades")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("Upgrades"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("HighUpgrades")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("HighUpgrades"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("PlayerBonus")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("PlayerBonus"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("DnaManager")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("DnaManager"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("DnaMaster")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("DnaMaster"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("TraitReroller")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("TraitReroller"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("BoosterCracker")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("BoosterCracker"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Forge")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("Forge"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Cosmetics")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("Cosmetics"))}
                          />

                          <ItemValue
                            value={ContractsConfig.keyToAddress("BkMigrator")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("BkMigrator"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("BkBatch")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("BkBatch"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Fetch")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("Fetch"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("Gravedance")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("Gravedance"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("QuestMath")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("QuestMath"))}
                          />
                          <ItemValue
                            value={ContractsConfig.keyToAddress("BkCypherpunks")}
                            forceContainerMarginTop={"10px"}
                            onClick={searchCallback(ContractsConfig.keyToAddress("BkCypherpunks"))}
                          />
                        </DetailItem>
                      </SubContainer>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12" style={{ marginTop: "-40px" }}>
                  <h5
                    className="sub-title help-center mg-bt-32 "
                    style={{ marginTop: "32px", display: "flex", flexDirection: "column" }}
                  >
                    <Link to="#" style={{ textDecorationLine: "underline" }} onClick={watchUsdc}>
                      Add Kingdom-Peg USDC to Metamask
                    </Link>
                    <Link
                      to="#"
                      style={{ textDecorationLine: "underline", marginTop: "10px" }}
                      onClick={watchBct}
                    >
                      Add BCT to Metamask
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default SmartContracts;
