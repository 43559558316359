import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
import routes from "./pages/index";
import { ToastContainer } from "react-toastify";

import { Web3Provider } from "./contexts/Web3Context";
import { PlayerProvider } from "./contexts/PlayerContext";
import { OtherPlayerProvider } from "./contexts/OtherPlayerContext";
import { KingdomQuestsProvider } from "./contexts/KingdomQuestsContext";
import { RegisterProvider } from "./contexts/RegisterContext";
import { BctPoolProvider } from "./contexts/BctPoolContext";
import { KoziPoolProvider } from "./contexts/KoziPoolContext";
import { UpgradesProvider } from "./contexts/UpgradesContext";
import { BoosterCrackerProvider } from "./contexts/BoosterCrackerContext";
import { MarketplaceProvider } from "./contexts/MarketplaceContext";

function App() {
  return (
    <Web3Provider>
      <PlayerProvider>
        <OtherPlayerProvider>
          <KingdomQuestsProvider>
            <UpgradesProvider>
              <BoosterCrackerProvider>
                <BctPoolProvider>
                  <KoziPoolProvider>
                    <RegisterProvider>
                      <MarketplaceProvider>
                        <ToastContainer
                          theme="dark"
                          position="bottom-right"
                          newestOnTop={false}
                          draggable
                          pauseOnHover
                        />
                        <Routes>
                          {routes.map((data, index) => (
                            <Route
                              onUpdate={() => window.scrollTo(0, 0)}
                              exact={true}
                              path={data.path}
                              element={data.component}
                              key={index}
                            />
                          ))}
                        </Routes>
                      </MarketplaceProvider>
                    </RegisterProvider>
                  </KoziPoolProvider>
                </BctPoolProvider>
              </BoosterCrackerProvider>
            </UpgradesProvider>
          </KingdomQuestsProvider>
        </OtherPlayerProvider>
      </PlayerProvider>
    </Web3Provider>
  );
}

export default App;
