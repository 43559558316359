import React, { useState } from "react";

import ClickableBox from "../../clickable-box";
import {
  Container,
  TitleWrapper,
  TitleWrapperOverlayCenter,
  TitleWrapperOverlayClickable,
} from "./styles";

const UpgradeGroup = ({
  title = "Resource Looting Skills",
  groupWidth = "300px",
  startCollapsed = false,
  isCollapsable = true,
  buttons = [
    {
      title: "Basic Loot Skill",
      resource: 0,
      currentQuantity: 5,
      maxQuantity: 5,
      tooltip: "",
      onClick: null,
      isActive: false,
    },
    {
      title: "Advanced Loot Skill",
      resource: 5,
      currentQuantity: 5,
      maxQuantity: 5,
      tooltip: null,
      onClick: null,
      isActive: false,
    },
    {
      title: "Golden Nuts Skill",
      resource: 10,
      currentQuantity: 5,
      maxQuantity: 5,
      tooltip: null,
      onClick: null,
      isActive: false,
    },
    {
      title: "Evolution Items Skill",
      resource: 1118,
      currentQuantity: 5,
      maxQuantity: 5,
      tooltip: null,
      onClick: null,
      isActive: false,
    },
  ],
}) => {
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed);

  const handleTitleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Container groupWidth={groupWidth} isCollapsed={isCollapsed}>
      <TitleWrapper groupWidth={groupWidth} isCollapsed={isCollapsed}>
        <p>{title}</p>
      </TitleWrapper>
      <TitleWrapperOverlayClickable
        onClick={isCollapsable ? handleTitleClick : null}
        isCollapsed={isCollapsed}
      ></TitleWrapperOverlayClickable>
      <TitleWrapperOverlayCenter buttonCount={buttons.length}></TitleWrapperOverlayCenter>
      {!isCollapsed &&
        buttons.map((button, index) => (
          <ClickableBox
            key={`${button.title}-${button.resource}-${button.maxQuantity}-${index}`}
            title={button.title}
            resource={button.resource}
            currentQuantity={button.currentQuantity}
            maxQuantity={button.maxQuantity}
            tooltip={button.tooltip}
            onClick={button.onClick}
            isActive={button.isActive}
          />
        ))}
    </Container>
  );
};

export default UpgradeGroup;
