import styled, { keyframes, css } from "styled-components";

const scaleUp = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1.5);
    opacity: 0.5;
    filter: blur(0.5px);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const OuterShell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  max-width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  flex-wrap: wrap;
`;

export const TicketsButton = styled.div`
  font-size: 12px;
  color: #fff;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.isOn ? "#fa3" : "#113")};
  outline: ${(props) => (props.isOn ? "1px solid yellow" : "1px solid white")};
  border-radius: 4px;
  padding: 5px 3px;
  margin-left: 4px;
  user-select: none;
  min-width: 36px;
  text-align: center;

  ${(props) =>
    !props.disabled
      ? `
  &:hover {
    opacity: 1;
    outline: ${(props) => (props.isOn ? "1px solid white" : "1px solid yellow")};
  }

  &:active {
    background-color: #fff;
  }`
      : ""}
`;

export const BigSpinButton = styled.div`
  font-size: 2.5rem;
  color: #fff;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  background: ${(props) =>
    props.disabled ? "#555" : "linear-gradient(0deg, rgba(38, 74, 40, 1), rgba(108, 200, 104, 1))"};
  outline: 3px solid white;
  border-radius: 4px;
  padding: 15px 3px;
  margin: 14px;
  user-select: none;
  font-weight: bold;
  width: 90%;
  max-width: 396px;
  text-align: center;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333,
    0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333;

  opacity: ${(props) => (props.isInvisible ? "0" : "1")};

  ${(props) =>
    !props.disabled
      ? css`
          :hover {
            opacity: ${(props) => (props.disabled ? "1" : "0.9")};
          }

          :active {
            background: #fff;
          }
        `
      : ""}
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-bottom: 14px;
  margin-top: -10px;
`;

export const CardFX = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px 5px #000;

  outline: ${(props) => (props.hasFinalResult ? "10px solid #c0a;" : "")};
  outline-offset: 3px;
  margin-top: ${(props) => (props.hasFinalResult ? "20px" : "0px")};

  transition: all 3s;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 406px;

  opacity: ${(props) => (props.hasFinalResult ? "0" : "1")};

  transition: all 1s;
`;

export const InputIdField = styled.input`
  width: 206px !important;
  max-width: 206px !important;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #555555;
  padding: 0 10px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.3) !important;
`;

export const InvisibleContainer = styled.div`
  opacity: ${(props) => (props.isInvisible ? "0" : "1")};
  user-select: none;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ddd;
  border-radius: 4px;
  width: 194px;
  height: 38px;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 12px;
  outline: 1px solid #555555;
  margin: 4px;
  position: relative;
  bottom: 2px;
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  &:active {
    background: #0f2034;
    opacity: 1;
  }

  @media (max-width: 450px) {
    margin: ${(props) => (props.isFirst ? "4px 4px 4px 0" : "4px 0 4px 4px")};
  }
`;

export const WingsContainer = styled.div`
  position: absolute;
  width: 320px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: -10;
`;

export const Wings = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${(props) => props.src}) no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  z-index: 1;
  transform: scale(1.5);
  opacity: 0;

  ${(props) =>
    !props.activate
      ? ""
      : css`
          animation: ${scaleUp} 3s forwards;
        `}
`;
