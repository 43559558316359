import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Messenger from "../../utils/Messenger";
import BigNumber from "bignumber.js";
import { zeroAddress } from "../../utils/address";

import { useWeb3Context } from "../../contexts/Web3Context";
import { usePlayerContext } from "../../contexts/PlayerContext";

import { fromWei, toWei } from "../../utils/wei";
import TimerManager from "../../utils/TimerManager";
import Contracts from "../../utils/Contracts";
import { ethers } from "ethers";
import { shortHash } from "../../utils/tx";
import Card from "../card/Card";

import thrillerBgmAudio from "../../assets/audio/thriller-cut-3.mp3";
import wolfFXAudio from "../../assets/audio/wolf.mp3";
import wings from "../../assets/images/wings_small.png";

import {
  OuterShell,
  BigSpinButton,
  CardContainer,
  InputContainer,
  InputIdField,
  InvisibleContainer,
  PaginationContainer,
  PaginationButton,
  CardFX,
  WingsContainer,
  Wings,
} from "./styles";

import Item from "./Item";

import { COST } from "./config";

const DEFAULT_STATE = {
  nft: null,
  optimizations: [0, 0, 0],
  tripledItems: [0, 0, 0],
  modificationChance: [1, 1, 1],
  spinUid: null,
  spinStep: 0,
  finalInnerResults: [],
};

const SPIN_STEPS = {
  0: {
    name: "SPIN",
    description: "Start the Ritual",
    button: "💀 Start the Ritual",
  },
  1: {
    name: "SPIN",
    description: "Start the Ritual",
    button: "💀 Start the Ritual",
  },
  2: {
    name: "Shuffling",
    description: "Awaiting 15 seconds before unlocking the button to select categories",
    button: "😵 Murmuring forbidden words...",
  },
  3: {
    name: "Revive",
    description: "Complete the ritual",
    button: "RISE FROM YOUR GRAVE!",
  },
  4: {
    name: "Reveal Prizes",
    description: "Reveal your prizes",
    button: "Reviving the dead...",
  },
  5: {
    name: "Ritual completed",
    description: "Claim your prizes",
    button: "😇 Claim the Revived Beast",
  },
};

const TICKET_COST_IN_STABLE = 6; // becomes $3 after a 50% discount for paying in Kozi

const GravedanceScreen = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_UP });
  const location = useLocation();
  const navigate = useNavigate();
  const { stableToTargetKozi, consult, execFunction } = useWeb3Context();
  const { player, fetchBeasts, clearLastFechedBeasts } = usePlayerContext();

  const [state, setState] = useState(DEFAULT_STATE);
  const [balances, setBalances] = useState(null);
  const [inputId, setInputId] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [forceBlockButtons, setForceBlockButtons] = useState(false);

  const thrillerBgm = new Audio(thrillerBgmAudio);
  const wolfFX = new Audio(wolfFXAudio);

  useEffect(() => {
    fetchBalances();
    thrillerBgm.load();
    wolfFX.load();
    thrillerBgm.volume = 0.75;

    return () => {
      TimerManager.stopAll();
      stopThriller();
      stopWolf();
    };
  }, [player]);

  useEffect(() => {}, [state]);

  const playThriller = () => {
    thrillerBgm.play();
  };

  const playWolf = () => {
    wolfFX.play();
  };

  const stopThriller = () => {
    thrillerBgm.pause();
    thrillerBgm.currentTime = 0;
  };

  const stopWolf = () => {
    wolfFX.pause();
    wolfFX.currentTime = 0;
  };

  const cycleTickets = (itemConfig) => {
    const _state = { ...state };
    _state.optimizations[itemConfig.itemIndex] = itemConfig.tickets;
    setState(_state);
  };

  const handleTripleChances = (itemConfig) => {
    const _state = { ...state };
    _state.tripledItems[itemConfig.itemIndex] = itemConfig.tripleChances ? 1 : 0;
    setState(_state);
  };

  const totalTickets = () => {
    return state.optimizations.reduce((acc, val) => acc + val, 0);
  };

  const _stableToTargetKozi = (amount, decimals) => {
    // we'll try to get the result in the target token
    // if `amount` is not zero AND the result is zero, we'll display a question mark.
    let result = stableToTargetKozi(amount).toFixed(decimals, BigNumber.ROUND_CEIL);
    if (
      (amount !== BigNumber(0) && result === "0.0000") ||
      result === "NaN" ||
      result === "undefined"
    ) {
      return "?";
    } else {
      return result;
    }
  };

  const currentCostInKozi = (decimals = 4) => {
    if (state.nft === null) return "?";
    if (state.nft.type_ === 0) return "?";

    let _price = COST[state.nft.type_][state.nft.rarity];
    if (_price === 0) return "?";

    if (state.tripledItems[0] === 1) _price = _price * 1.5;
    if (state.tripledItems[1] === 1) _price = _price * 1.5;
    if (state.tripledItems[2] === 1) _price = _price * 1.5;

    if (state.nft.attributes[6] > 0) {
      _price = _price * 2 ** state.nft.attributes[6];
    }

    return _stableToTargetKozi(toWei(_price), decimals);
  };

  const handleBigButtonClick = () => {
    if (state.spinStep === 0) {
      handleSpin();
    } else if (state.spinStep === 3) {
      handleFinalResult();
    } else if (state.spinStep === 5) {
      //window.location.reload();
      window.location.assign("/beasts");
    }
  };

  const handleBigKoziButtonClick = () => {
    if (state.spinStep === 0) {
      handleSpin(true);
    }
  };

  const unblockButtons = () => {
    setForceBlockButtons(false);
  };

  const handleSpin = async (koziOnly = false) => {
    // we'll call the blockchain, and the receipt will be handled by the context
    // @SOL: function beginRevival(uint256 nftId, uint256[] luckyTickets, bool[] tripleChances, bool useKoziOnly)
    setForceBlockButtons(true);

    const success = await execFunction({
      contractName: "Gravedance",
      functionName: "beginRevival",
      functionParams: [state.nft.id, state.optimizations, state.tripledItems, koziOnly],
      successMessage: "The ritual has begun!",
      minedCallback: onSpinTxMined,
      errorCallback: unblockButtons,
      koziAmount: koziOnly
        ? costInKoziOnlyBN().times(1e18).toString()
        : BigNumber(currentCostInKozi(4)).times(1e18).toString(),
      errorList: [
        {
          reason: "KingdomNobles: not enough loyalty",
          message: "Not enough Lucky Tickets",
        },
        {
          reason: "DRR: blockhash not available",
          message: "You're too fast! Please try again in 10 seconds.",
        },
        {
          reason: "this beast has zero traits",
          message:
            "This beast has zero traits! I don't have the power to revive it. Please chose another beast.",
        },
      ],
    });

    if (!success) {
      setForceBlockButtons(false);
      Messenger.error("Failed to start the Gravedance game");
    }
  };

  const parseLogs = (logs) => {
    const parsedLogs = logs.map((log) => {
      const abi = Contracts.addressToAbi(ethers.getAddress(log.address));

      if (!abi)
        return {
          address: log.address,
          emitter: log.address,
          name: shortHash(log.topics[0], 10, 0),
          data: log.data,
          args: log.topics,
          logIndex: log.logIndex,
          fragment: {
            inputs: log?.topics?.length
              ? new Array(log.topics.length).fill({ type: "bytes32" })
              : [],
          },
        };

      const contract = new ethers.Contract(log.address, abi);
      const parsedLog = contract.interface.parseLog(log);
      return { ...parsedLog, emitter: log.address, logIndex: log.logIndex };
    });

    // now remove from the list all logs that don't have a name
    const filteredLogs = parsedLogs.filter((log) => log.name);

    return filteredLogs;
  };

  const onSpinTxMined = (receipt) => {
    setForceBlockButtons(false);

    clearLastFechedBeasts();

    // find the event "Spin" and get the uid
    const events = parseLogs(receipt?.logs);
    const spinEvent = events.find((event) => event.name === "BeginRevival");
    if (!spinEvent) {
      Messenger.error("Failed to get the Spin event from the receipt. Did the transaction fail?");
      return;
    }
    const _uid = spinEvent.args[0];

    console.log("Spin Event:", spinEvent);
    playThriller();

    const newState = { ...state, spinUid: _uid, spinStep: 2 };
    setState(newState);

    TimerManager.start(
      "shuffleTimer",
      () => {
        setState({ ...newState, spinStep: 3 });
      },
      16000
    );
  };

  const handleFinalResult = async () => {
    setForceBlockButtons(true);

    console.log("Called completeRevival, setting spinStep to 4/5");
    const newState = { ...state, spinStep: 4 };
    setState(newState);

    // we'll call the blockchain, and the receipt will be handled by the context
    const success = await execFunction({
      contractName: "Gravedance",
      functionName: "completeRevival",
      functionParams: [state.spinUid],
      successMessage: "The beast is reviving! Not long now!",
      minedCallback: onFinalResultsTxMined,
      errorCallback: unblockButtons,
      errorList: [
        {
          reason: "KingdomNobles: not enough loyalty",
          message: "Not enough Lucky Tickets",
        },
        {
          reason: "DRR: blockhash not available",
          message: "You're too fast! Please count to 10 and try again.",
        },
        {
          reason: "this beast has zero traits",
          message:
            "This beast has zero traits! I don't have the power to revive it. Please chose another beast.",
        },
      ],
    });

    if (!success) {
      const newState = { ...state, spinStep: 2 };
      setState(newState);
      setForceBlockButtons(false);
      return;
    }
  };

  const onFinalResultsTxMined = async (receipt) => {
    setForceBlockButtons(false);

    const events = parseLogs(receipt?.logs);
    const completeRevivalEvent = events.find((event) => event.name === "CompleteRevival");
    if (!completeRevivalEvent) {
      Messenger.error(
        "Failed to get the CompleteRevival event from the receipt. Please look for immediate help on Discord. You can still gain the prizes if you act fast!"
      );
      return;
    }

    setTimeout(delayedFetchBeast, 4000, completeRevivalEvent);
  };

  const delayedFetchBeast = async (completeRevivalEvent) => {
    console.log("CompleteRevival event:", completeRevivalEvent);
    setForceBlockButtons(false);

    const results = completeRevivalEvent.args[4].map((x) => parseInt(x));
    console.log("RESULTS:", results);

    const tempBeast = await fetchBeasts([inputId]);

    stopThriller();
    playWolf();

    const newState = { ...state, finalInnerResults: results, spinStep: 5 };
    newState.nft = tempBeast[0];

    console.log("Final State:", newState);

    setState(newState);
  };

  const bigButtonLabel = () => {
    const _costInKozi = currentCostInKozi();
    if (_costInKozi === "?") {
      return "First, find a dead beast!";
    } else {
      if (state.spinStep < 2) {
        return `REVIVE for 
        ${_costInKozi} 🧿 ${totalTickets() > 0 ? `and ${totalTickets()} 🎫` : ""}`;
      } else {
        return SPIN_STEPS[state.spinStep].button;
      }
    }
  };

  const bigButtonKoziOnlyLabel = () => {
    const _finalCostInKozi = costInKoziOnlyBN().toFixed(4);

    return `REVIVE for ${_finalCostInKozi} 🧿`;
  };

  const costInKoziOnlyBN = () => {
    const _costInKozi = currentCostInKozi();
    const _ticketCostInStable = TICKET_COST_IN_STABLE; // in USDC
    const _totalCostForTickets = totalTickets() * _ticketCostInStable;
    const _totalCostForTicketsInKozi = _stableToTargetKozi(toWei(_totalCostForTickets), 4);
    const _finalCostInKozi = BigNumber(_costInKozi).plus(_totalCostForTicketsInKozi);

    return _finalCostInKozi;
  };

  const fetchBalances = async () => {
    if (!player) {
      TimerManager.start("fetchBalancesTimer", fetchBalances, 1000);
      return;
    } else {
      TimerManager.stop("fetchBalancesTimer");
    }

    const loyalty = await consult({
      contractName: "KingdomNobles",
      functionName: "loyaltyOfMany",
      functionParams: [[player.address, player.address]],
    });
    const loyaltyOf = BigNumber(loyalty[0]).div(2000).toFixed(2, BigNumber.ROUND_DOWN);

    setBalances({
      tickets: loyaltyOf,
      kozi: BigNumber(player.koziBalance).div(1e18).toFixed(4, BigNumber.ROUND_DOWN),
    });
  };

  const isBigButtonDisabled = () => {
    if (!state?.nft) return true;
    if (state?.nft?.currentOwner !== zeroAddress && state?.finalInnerResults.length < 3)
      return true;
    if (forceBlockButtons) return true;

    return (
      state.spinStep === 2 ||
      state.spinStep === 4 ||
      state.spinStep === 6 ||
      currentCostInKozi() === "?"
    );
  };

  function handleIdChange(event) {
    loadId(event.target.value);
  }

  function handlePrevious() {
    if (state.spinStep > 1) return;

    if (parseInt(inputId) - 1 > 0) {
      loadId(parseInt(inputId) - 1);
      setInputId(parseInt(inputId) - 1);
    }
  }

  function handleNext() {
    if (state.spinStep > 1) return;

    if (!inputId) {
      loadId(1);
      setInputId(1);
    } else {
      loadId(parseInt(inputId) + 1);
      setInputId(parseInt(inputId) + 1);
    }
  }

  const loadId = async (_id) => {
    if (state.spinStep > 1) return;

    // Check if the beast is already loaded
    if ((isLoaded && inputId === _id) || state?.nft?.id === _id) return;

    setInputId(_id);

    TimerManager.start(
      "loadIdTimer",
      async () => {
        const tempBeast = await fetchBeasts([_id]);
        if (!tempBeast) {
          const _state = { ...state };
          _state.nft = null;
          setState(_state);
        } else {
          if (tempBeast[0] && tempBeast[0].type_ > 0) {
            const _state = { ...state };

            /*
            //! DEV:
            tempBeast[0].attributes = [14, 5, 99, 5, 50, 11];
            tempBeast[0].skills = [10, 10, 10, 10, 10, 10];
            tempBeast[0].baseFarm = "7000000000000000";
            tempBeast[0].farmPerBlock = "76300000000000000";
            tempBeast[0].rank = 99;
            tempBeast[0].agility = 5;
            tempBeast[0].type_ = 14;
            tempBeast[0].species = 14;
            tempBeast[0].rarity = 5;
            tempBeast[0].nuts = 50;
            tempBeast[0].evos = 11;
            tempBeast[0].traits = [51, 51, 51];
            tempBeast[0].name = "Obsidian Terror";
            tempBeast[0].imageUrl = "https://i.postimg.cc/TYyf0CQb/d18.png";
            */

            _state.nft = tempBeast[0];

            setState(_state);
          } else {
            const _state = { ...state };
            _state.nft = null;
            setState(_state);
          }
        }
      },
      1000
    );
  };

  const finalResult = (modIndex) => {
    if (state.spinStep < 4) return null;

    return state.finalInnerResults[modIndex];
  };

  return (
    <OuterShell>
      {balances && (
        <>
          <h5 className="sub-title help-center" style={{ marginTop: "-30px" }}>
            Balances
          </h5>
          <h5 className="sub-title help-center mg-bt-32">
            {balances.kozi} 🧿 ◾ {balances.tickets} 🎫
          </h5>

          <CardContainer>
            <WingsContainer>
              <Wings src={wings} activate={state.spinStep >= 5} />
            </WingsContainer>
            {state?.nft?.currentOwner === zeroAddress || state.spinStep >= 5 ? (
              <CardFX hasFinalResult={state.spinStep >= 5}>
                <Card nft={state.nft} lockSize={true} supressInteraction={true} />
              </CardFX>
            ) : state?.nft?.id && inputId ? (
              <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                Beast #{state?.nft?.id} is alive and kicking. Try another one.
              </h5>
            ) : (
              <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                Find a dead beast to proceed.
              </h5>
            )}
          </CardContainer>

          <InputContainer hasFinalResult={state.spinStep >= 5}>
            <PaginationButton onClick={handlePrevious} isFirst>{`${
              state?.nft?.id - 1 > 0 ? state?.nft?.id - 1 : 0
            }`}</PaginationButton>
            <InputIdField
              placeholder={inputId || "Beast blockchain ID"}
              style={{ textAlign: "center" }}
              type="number"
              value={inputId}
              onChange={handleIdChange}
            />
            <PaginationButton onClick={handleNext}>{`${
              parseInt(state?.nft?.id || 0) + 1
            }`}</PaginationButton>
          </InputContainer>

          <Item
            config={{
              name: "Vampire",
              traitId: 57,
              chance: state.modificationChance[0],
              totalTickets: state.optimizations[0],
              onCycleTickets: cycleTickets,
              onTripleChances: handleTripleChances,
              itemIndex: 0,
            }}
            isRolling={state.spinStep === 2 || state.spinStep === 3 || state.spinStep === 4}
            finalResult={finalResult(0)}
          />
          <Item
            config={{
              name: "Ghost",
              traitId: 56,
              chance: state.modificationChance[1],
              totalTickets: state.optimizations[1],
              onCycleTickets: cycleTickets,
              onTripleChances: handleTripleChances,
              itemIndex: 1,
            }}
            isRolling={state.spinStep === 2 || state.spinStep === 3 || state.spinStep === 4}
            finalResult={finalResult(1)}
          />
          <Item
            config={{
              name: "Undead",
              traitId: 55,
              chance: state.modificationChance[2],
              totalTickets: state.optimizations[2],
              onCycleTickets: cycleTickets,
              onTripleChances: handleTripleChances,
              itemIndex: 2,
            }}
            isRolling={state.spinStep === 2 || state.spinStep === 3 || state.spinStep === 4}
            finalResult={finalResult(2)}
          />
          <BigSpinButton
            onClick={isBigButtonDisabled() ? null : handleBigButtonClick}
            disabled={isBigButtonDisabled()}
          >
            {bigButtonLabel()}
          </BigSpinButton>
          <InvisibleContainer
            isInvisible={totalTickets() <= 0 || isBigButtonDisabled() || state.spinStep > 1}
          >
            <h5 className="sub-title help-center " style={{ color: "yellow" }}>
              OR
            </h5>
          </InvisibleContainer>

          <BigSpinButton
            onClick={
              totalTickets() <= 0 || isBigButtonDisabled() || state.spinStep > 1
                ? null
                : handleBigKoziButtonClick
            }
            disabled={totalTickets() <= 0 || isBigButtonDisabled() || state.spinStep > 1}
            isInvisible={totalTickets() <= 0 || isBigButtonDisabled() || state.spinStep > 1}
          >
            {bigButtonKoziOnlyLabel()}
          </BigSpinButton>
        </>
      )}
      {!balances && (
        <>
          <h5 className="sub-title help-center" style={{ marginBottom: "20px", fontSize: "20px" }}>
            Loading...
          </h5>
          <h5 className="sub-title help-center" style={{ marginBottom: "200px", fontSize: "16px" }}>
            If this takes more than 10 seconds, reload the page.
          </h5>
        </>
      )}
    </OuterShell>
  );
};

export default GravedanceScreen;
