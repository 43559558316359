import React, { useEffect, useState } from "react";

import CountUp from "react-countup";
import { resourceToImagePath } from "../../../../../config/resources/09-resources-images";

import {
  Container,
  ImageContainer,
  TextContainer,
  WholePart,
  DecimalPart,
  EbctContainer,
} from "./styles";

const BctContainerInProgress = ({ resource, quantity, background }) => {
  const [previousQuantity, setPreviousQuantity] = useState(quantity);

  const imgStyle = {
    height: "90%",
  };

  resource === "eBCT" ? (resource = 98) : (resource = resource);
  resource === "BCT" ? (resource = 99) : (resource = resource);

  useEffect(() => {
    if (quantity !== previousQuantity) {
      // Wait for the CountUp animation to complete
      setTimeout(() => {
        setPreviousQuantity(Math.max(quantity, previousQuantity));
      }, 10000);
    }
  }, [quantity, previousQuantity]);

  const numberOfDecimals = (value) => {
    if (value < 10) return 7;
    if (value < 100) return 6;
    if (value < 1000) return 5;
    if (value < 10000) return 3;
    if (value < 100000) return 2;
    return 1;
  };

  return (
    <Container background={background}>
      <ImageContainer background={background}>
        {Number(resource) === 98 || resource === "eBct" ? (
          <EbctContainer background={background}>⚡</EbctContainer>
        ) : (
          <img src={resourceToImagePath(Number(resource))} style={imgStyle} />
        )}
      </ImageContainer>
      <TextContainer>
        <CountUp
          key={`farming-${resource}`}
          start={previousQuantity}
          end={quantity}
          duration={10}
          decimals={numberOfDecimals(quantity)}
          useEasing={false}
        >
          {({ countUpRef }) => <WholePart ref={countUpRef} />}
        </CountUp>
      </TextContainer>
    </Container>
  );
};

export default BctContainerInProgress;
