import BigNumber from "bignumber.js";
export const HALVING_THRESHOLDS = [
  {
    count: 1,
    previousScale: 8,
    currentScale: 4,
    threshold: BigNumber(25000000).times(1e18),
  },
  {
    count: 2,
    previousScale: 4,
    currentScale: 2,
    threshold: BigNumber(17500000).times(1e18),
  },
  {
    count: 3,
    previousScale: 2,
    currentScale: 1,
    threshold: BigNumber(10000000).times(1e18),
  },
  {
    count: 4,
    previousScale: 1,
    currentScale: 0.5,
    threshold: BigNumber(5000000).times(1e18),
  },
  {
    count: 5,
    previousScale: 0.5,
    currentScale: 0.25,
    threshold: BigNumber(2500000).times(1e18),
  },
  {
    count: 6,
    previousScale: 0.25,
    currentScale: 0.12,
    threshold: BigNumber(1250000).times(1e18),
  },
  {
    count: 7,
    previousScale: 0.12,
    currentScale: 0.06,
    threshold: BigNumber(625000).times(1e18),
  },
  {
    count: 8,
    previousScale: 0.06,
    currentScale: 0.03,
    threshold: BigNumber(312500).times(1e18),
  },
  {
    count: 9,
    previousScale: 0.03,
    currentScale: 0.01,
    threshold: BigNumber(156250).times(1e18),
  },
  {
    count: 10,
    previousScale: 0.01,
    currentScale: 0.01,
    threshold: BigNumber(1).times(1e18),
  },
];
