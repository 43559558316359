import styled from "styled-components";

export const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  outline: 2px solid white;
  outline-offset: 2px;
  height: 100%;
  width: 298px;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin: auto;
    left: 0px;
    margin-top: 5px;
    width: 90%;
  }
`;

export const BalanceGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const PlayerNotFound = styled.div`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;

  // centralize it inside the container:
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // make it fill the container:
  width: 330px;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

export const Subtitle = styled.h1`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #ddd;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;
