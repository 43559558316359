import React, { useState } from "react";
import { getFinalUrl } from "../../../utils/imageUrlCheck";
import { toast } from "react-toastify";

import {
  GlobalContainer,
  Container,
  Title,
  Subtitle,
  InputContainer,
  Input,
  ButtonContainer,
  Button,
} from "./styles";

const CustomizationModal = ({
  beastCount = 5,
  onClose = null,
  onSave = null,
  beastNames = [],
  beastImages = [],
}) => {
  const [canSave, setCanSave] = useState(true);

  const handleClose = () => {
    if (canSave) {
      if (onClose) onClose();
    } else {
      toast.error("Please fix or delete the image url before closing this", {
        type: toast.TYPE.WARNING,
        autoClose: 5000,
      });
    }
  };

  const dummyArray = new Array(beastCount).fill(0);

  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  const handleNameChange = (value, index) => {
    const newBeastNames = [...beastNames];
    newBeastNames[index] = value;
    if (onSave) onSave(newBeastNames, beastImages);
  };

  const handleImageChange = async (value, index) => {
    if (!onSave) return;

    const finalUrl = await getFinalUrl(value);
    const newBeastImages = [...beastImages];
    newBeastImages[index] = value;
    if (onSave) onSave(beastNames, newBeastImages);

    if (!finalUrl.success) {
      toast.error(finalUrl.message, {
        type: toast.TYPE.WARNING,
        autoClose: 5000,
      });

      setCanSave(false);
    } else {
      setCanSave(true);
    }
  };

  return (
    <GlobalContainer onClick={handleClose}>
      <Container onClick={handleContainerClick}>
        <Title>Customize</Title>
        <Subtitle>All fields are optional</Subtitle>
        {dummyArray.map((_, index) => (
          <InputContainer key={index}>
            <Input
              value={beastNames[index] || ""}
              placeholder={"Beast name"}
              onChange={(e) => handleNameChange(e.target.value, index)}
            />
            <Input
              value={beastImages[index] || ""}
              placeholder="Image url ending in .png (or .gif for mythics)"
              onChange={(e) => handleImageChange(e.target.value, index)}
            />
          </InputContainer>
        ))}
        <ButtonContainer onClick={handleContainerClick}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Save</Button>
        </ButtonContainer>
      </Container>
    </GlobalContainer>
  );
};

export default CustomizationModal;
