import React, { useState } from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import Header from "../components/header/Header";
import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";
import { priceFromReserves } from "../utils/pool";

const DECIMAL_PLACES = 2;

const TradingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  padding: 20px;
  margin: 0px auto;
  border-radius: 8px;
  outline: 3px solid #fff;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

const TradingButton = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background: ${(props) =>
    props.sell
      ? "linear-gradient(145deg, #d9534f, #c9302c)"
      : "linear-gradient(145deg, #45a049, #4caf58)"};
  border: none;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  &:hover {
    background: ${(props) =>
      props.sell
        ? "linear-gradient(145deg, #c74343, #df6360)"
        : "linear-gradient(145deg, #3c8f40, #51c665)"};
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const TradingLabel = styled.label`
  color: #fff;
  margin-bottom: 6px;
  font-size: 14px;
`;

const TradingInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 6px;
  border: 1px solid #4caf58;
  background-color: #1c1b20;
  color: #fff;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  max-width: 90%;
  margin-top: 20px; // Reduced this value
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  padding: 20px;
  gap: 6px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BuyEbct = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const { blockchainState, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();
  const { buyEbct } = usePlayerContext();
  const [formattedBusdAmount, setFormattedBusdAmount] = useState("0");
  const [formattedBctAmount, setFormattedBctAmount] = useState("0");
  const [formattedDrascaAmount, setFormattedDrascaAmount] = useState("0");

  const isValidBlockchainState = blockchainState && blockchainState?.poolReserves?.length === 2;

  const price = isValidBlockchainState
    ? priceFromReserves(blockchainState.poolReserves[0], blockchainState.poolReserves[1]).div(4)
    : 0;

  const handleBusdAmountChange = (event) => {
    if (!blockchainState) return;

    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedBusdAmount(value);

    // Perform calculation to update BCT amount
    const bctAmount = BigNumber(value).div(price).times(1e18);
    setFormattedBctAmount(bctAmount.toFixed(DECIMAL_PLACES), BigNumber.ROUND_DOWN);

    if (value >= 10) {
      const drasca = BigNumber(value).div(10).times(4).toFixed(0, BigNumber.ROUND_DOWN);

      setFormattedDrascaAmount(drasca);
    } else {
      setFormattedDrascaAmount(0);
    }
  };

  const handleBctAmountChange = (event) => {
    if (!blockchainState) return;

    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedBctAmount(value);

    // Perform calculation to update USDC amount
    const busdAmount = BigNumber(value).times(price).div(1e18);
    setFormattedBusdAmount(busdAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP));

    const drasca = BigNumber(busdAmount).div(10).times(4).toFixed(0, BigNumber.ROUND_DOWN);

    setFormattedDrascaAmount(drasca);
  };

  const isBuyButtonDisabled = false;
  const handleBuyButtonClick = async () => {
    await buyEbct(BigNumber(formattedBusdAmount).times(1e18).toFixed(0, BigNumber.ROUND_DOWN));
  };

  return (
    <div>
      <style>
        {`
        .green {
          color: #2f4;
        }
        .red {
          color: red;
        }
        .fade-in {
          opacity: 0;
          transition: opacity 0.5s ease-in;
        }
        .fade-in.show {
          opacity: 1;
        }
      `}
      </style>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">⚡</h1>
                    <h1 className="heading text-center">eBCT</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      While BCT is an ERC20 Token, eBCT is an ethereal version of it. It is designed
                      to be used in-game and cannot be sold for USDC.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Purchasing eBCT offers a <strong>4x MEGA BONUS</strong> over BCT. This means
                      that you get 4x more eBCT than BCT for the same price.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 " style={{ color: "yellow" }}>
                      EXTRA BONUS: For every 10 USDC you spend on buying eBCT, you get 4 Dragon
                      Scales for free!
                    </h5>
                    <h5 className="sub-title help-center small" style={{ color: "#ccc" }}>
                      Please note: Minor decimal imprecisions may occur.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <BoxesWrapper>
            <TradingBox>
              <TradingLabel htmlFor="busdInputBuy">Send USDC</TradingLabel>
              <TradingInput
                type="text"
                id="busdInputBuy"
                value={formattedBusdAmount}
                onChange={handleBusdAmountChange}
                onFocus={(event) => event.target.select()}
              />
              <TradingLabel htmlFor="bctInputBuy">Get eBCT</TradingLabel>
              <TradingInput
                type="text"
                id="bctInputBuy"
                value={formattedBctAmount}
                onChange={handleBctAmountChange}
                onFocus={(event) => event.target.select()}
              />
              <p style={{ color: "yellow", fontSize: "12px", position: "relative", bottom: "5px" }}>
                {`Gain ${formattedDrascaAmount} Dragon Scales!`}
              </p>
              <TradingButton disabled={isBuyButtonDisabled} onClick={handleBuyButtonClick}>
                Buy eBCT
              </TradingButton>
            </TradingBox>
          </BoxesWrapper>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default BuyEbct;
