import React, { useEffect, useState } from "react";
import { hasEnoughBct, hasEnoughResources } from "../../../utils/purchase";
import Rpc from "../../../rpc/Rpc";

import {
  ButtonContainer,
  BuyButton,
  BuyWithKoziButton,
  PurchaseCostContainer,
} from "../buttons/PurchaseButtons";

import {
  Container,
  TitleContainer,
  Title,
  Description,
  InputWrapper,
  NumberInput,
  NumberButton,
  PlusPlusButton,
  MinusMinusButton,
  Number,
  Explanation,
} from "./styles";

import "../../../assets/styles/loader.css";

import BigNumber from "bignumber.js";
import ResourceContainerUpgrades from "../../upgrades/resource-container";
import { indexToBackground } from "../../../utils/constants";

import { useWeb3Context } from "../../../contexts/Web3Context";

const UpgradeBox = ({
  title,
  description,
  description2,
  eBctPrice,
  resourcePrices,
  resourceIndexes,
  updatePrice,
  currentLevel,
  previewLevel,
  maxLevel,
  busdPrice = 0,
  buyFunction = null,
  buyWithKoziFunction = null,
  player = null,
  isReady = false,
  isHeader = false,
  isForbidden = false,
  isBlocked = false,
}) => {
  const { stableToTargetKozi } = useWeb3Context();
  const [levels, setLevels] = useState(1);
  const [hasEnoughKozi, setHasEnoughKozi] = useState(false);

  const explanation = `The current level is ${currentLevel} and the max level is ${maxLevel}.`;
  const explanationLine2 = "What level would you like it to become?";

  const fetchHasEnoughKozi = (player, koziAmount) => {
    if (player) {
      const balance = Rpc.fetchKoziBalanceOf(player.address);
      const hasEnough = BigNumber(koziAmount).lte(balance);
      return hasEnough;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (player) {
      setHasEnoughKozi(fetchHasEnoughKozi(player, stableToTargetKozi(busdPrice)));
    }
  }, [player, busdPrice]);

  useEffect(() => {
    const initialLevel = Math.min(parseInt(previewLevel), parseInt(maxLevel));
    setLevels(initialLevel);
  }, [currentLevel, maxLevel]);

  const handleIncrease = () => {
    if (levels < maxLevel) {
      setLevels((prevLevels) => prevLevels + 1);
      updatePrice(levels + 1);
    }
  };

  const handleIncreasePlus = () => {
    if (levels + 10 < maxLevel) {
      setLevels((prevLevels) => prevLevels + 10);
      updatePrice(levels + 10);
    } else {
      setLevels(maxLevel);
      updatePrice(maxLevel);
    }
  };

  const handleDecrease = () => {
    if (levels > currentLevel) {
      setLevels((prevLevels) => prevLevels - 1);
      updatePrice(levels - 1);
    }
  };

  const handleDecreasePlus = () => {
    if (levels - 10 > currentLevel) {
      setLevels((prevLevels) => prevLevels - 10);
      updatePrice(levels - 10);
    } else {
      setLevels(currentLevel);
      updatePrice(currentLevel);
    }
  };

  function handleBuyFunction() {
    if (!buyFunction) {
      console.log(`Can't find the buy function`);
      return;
    }

    if (levels <= currentLevel && maxLevel !== 0) {
      console.log(`Levels are not greater than the current level`);
      return;
    }

    buyFunction(levels - currentLevel);
  }

  function handleBuyWithKoziFunction() {
    if (!buyWithKoziFunction) {
      console.log(`Can't find the pay with KOZI function`);
      return;
    }

    if (levels <= currentLevel && maxLevel !== 0) {
      console.log(`Levels are not greater than the current level`);
      return;
    }

    buyWithKoziFunction(levels - currentLevel);
  }

  return (
    <Container>
      <TitleContainer>
        <Title>
          {!isHeader
            ? maxLevel
              ? `${title}: ${currentLevel} / ${maxLevel}`
              : `${title}`
            : `${title}`}
        </Title>
      </TitleContainer>
      <Description style={isHeader ? { fontSize: "16px" } : {}}>{description}</Description>
      <Description style={isHeader ? { fontSize: "16px" } : {}}>{description2}</Description>
      {isReady && !isHeader && (
        <>
          <Explanation>{explanation}</Explanation>
          <Explanation>{explanationLine2}</Explanation>
          <InputWrapper>
            <MinusMinusButton onClick={handleDecreasePlus}>{"--"}</MinusMinusButton>
            <NumberButton onClick={handleDecrease}>-</NumberButton>
            <NumberInput>
              <Number>{levels}</Number>
            </NumberInput>
            <NumberButton onClick={handleIncrease}>+</NumberButton>
            <PlusPlusButton onClick={handleIncreasePlus}>{"++"}</PlusPlusButton>
          </InputWrapper>
          <Explanation>{description2}</Explanation>
        </>
      )}
      {eBctPrice !== null && !isHeader && (
        <ButtonContainer>
          <BuyButton
            onClick={handleBuyFunction}
            disabled={
              (levels <= currentLevel && maxLevel !== 0) ||
              !hasEnoughResources(player, resourcePrices, resourceIndexes) ||
              isForbidden ||
              isBlocked
            }
          >
            <p>Pay with resources</p>
            <PurchaseCostContainer>
              {resourceIndexes.map((resourceIndex, mapIndex) => (
                <ResourceContainerUpgrades
                  key={`aresource-${resourceIndex}`}
                  resource={resourceIndex}
                  quantity={resourcePrices[mapIndex] / 100}
                  background={indexToBackground[resourceIndex]}
                  hasEnough={hasEnoughResources(
                    player,
                    [resourcePrices[mapIndex]],
                    [resourceIndexes[mapIndex]]
                  )}
                />
              ))}
            </PurchaseCostContainer>
          </BuyButton>
          <BuyWithKoziButton
            disabled={
              (levels <= currentLevel && maxLevel !== 0) ||
              busdPrice === "0.00" ||
              busdPrice === "—" ||
              isForbidden ||
              isBlocked
            }
            onClick={handleBuyWithKoziFunction}
            hasEnough={hasEnoughKozi}
          >
            <h1>
              Pay with <span style={{ color: "yellow" }}>KOZI</span>
            </h1>
            {BigNumber(busdPrice).toFixed(2) !== "0.00" &&
              BigNumber(busdPrice).toString() !== "—" && (
                <p
                  style={{
                    fontSize: "12px",
                    color: "#ddc",
                    textDecoration: "line-through",
                    marginBottom: "4px",
                  }}
                >
                  {"- "}
                  {BigNumber(busdPrice).div(1e18).toFixed(2, BigNumber.ROUND_CEIL)} USDC{" -"}
                </p>
              )}
            <p>
              {busdPrice === "0.00" ||
              busdPrice === 0 ||
              busdPrice === "0.01" ||
              busdPrice === NaN ||
              busdPrice === "NaN"
                ? "—"
                : stableToTargetKozi(busdPrice).toFixed(4, BigNumber.ROUND_CEIL) + " 🧿"}
            </p>
          </BuyWithKoziButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default UpgradeBox;
