import { SERVICE_URL, GET_CHART_ENDPOINT } from "../../../../utils/constants";

import axios from "axios";

/*
The getChart endpoint expects one parameter, timeFrame, which is a string that can be one of the following:
"oneDay", "oneWeek", "oneMonth", "oneYear"

This is an example response from the service:
{
	"success": true,
	"result": {
		"data": [
			"18039341823257947",
			"18044298014181767",
			"18044329075124597",
		],
		"labels": [
			"2023-10-05T14:55:19.323Z",
			"2023-10-05T14:56:26.465Z",
			"2023-10-05T14:59:52.303Z",
		],
		"needsUpdate": false
	}
}
*/

class Service {
  constructor() {
    this.baseUrl = SERVICE_URL;
    //this.baseUrl = "http://localhost:3001";
    this.getChartUrl = `${this.baseUrl}${GET_CHART_ENDPOINT}`;
  }

  async getChart(timeFrame = "oneDay") {
    console.log("SERVICE::getChart | timeFrame: ", timeFrame);

    const result = await axios.post(this.getChartUrl, {
      timeFrame,
    });

    if (!result.data.success) throw new Error("Service Error on getChart: !result.success");

    return result.data.result;
  }
}

const service = new Service();
export default service;
