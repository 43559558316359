import traitList from "./01-traits.json";
import extendedTraitList from "./01-extendedTraits.json";

function nameToNumber(name, useExtended = false) {
  if (name === "Zero") return 0;
  if (name === "Random") return 999;

  const _traitList = useExtended ? extendedTraitList : traitList;
  const trait = _traitList.find((trait) => trait.name === name);

  if (trait) {
    return trait.id;
  } else {
    return 0;
  }
}

function numberToName(number, useExtended = false) {
  if (number === 0) return "Zero";
  if (number === 999) return "Random";
  if (number === 998) return "?";

  const _traitList = useExtended ? extendedTraitList : traitList;
  const trait = _traitList.find((trait) => trait.id === number);

  if (trait) {
    return trait.name;
  } else {
    return 0;
  }
}

function allTraits(useExtended = false) {
  const _traitList = useExtended ? extendedTraitList : traitList;
  return _traitList;
}

export { nameToNumber, numberToName, allTraits };
