import React, { useEffect, useState } from "react";

import { GlobalContainer, Container, ActionContainer } from "./styles";
import MasteryGroup from "./masteryGroup";
import { UpgradeGroupRowContainer } from "../upgrades/styles";
import MasteryBox from "./masteryBox";

import { useWeb3Context } from "../../contexts/Web3Context";
import { usePlayerContext } from "../../contexts/PlayerContext";
import { useUpgradesContext } from "../../contexts/UpgradesContext";

import { getPerksArray } from "./perks";
import BigNumber from "bignumber.js";

const MasteriesScreen = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_CEIL });

  const { isReady } = useWeb3Context();
  const { player } = usePlayerContext();
  const { buyMastery, currentlySelectedMasteryIndex, setCurrentlySelectedMasteryIndex } =
    useUpgradesContext();

  const [selectedPerk, setSelectedPerk] = useState({
    title: "Masteries",
    description:
      "Masteries are permanent global upgrades that will help you fight against our aggressive halvings.",
    description2: "Select a Mastery above to see its details.",
    isHeader: true,
  });
  const [bctMasteryPreviewLevel, setBctMasteryPreviewLevel] = useState(0);
  const [eBctMasteryPreviewLevel, setEBctMasteryPreviewLevel] = useState(0);
  const [blMasteryPreviewLevel, setBlMasteryPreviewLevel] = useState(0);
  const [alMasteryPreviewLevel, setAlMasteryPreviewLevel] = useState(0);
  const [nutsMasteryPreviewLevel, setNutsMasteryPreviewLevel] = useState(0);
  const [evosMasteryPreviewLevel, setEvosMasteryPreviewLevel] = useState(0);

  const [currentPriceInResources, setCurrentPriceInResources] = useState({
    bct: BigNumber(0),
    resourceCents: [0],
    resourceIndexes: [],
  });

  const [currentPriceInStabletokens, setCurrentPriceInStabletokens] = useState(0);

  useEffect(() => {
    if (!isReady) return;
    if (!player) return;

    setBctMasteryPreviewLevel(player.masteries[0]);
    setEBctMasteryPreviewLevel(player.masteries[1]);
    setBlMasteryPreviewLevel(player.masteries[2]);
    setAlMasteryPreviewLevel(player.masteries[3]);
    setNutsMasteryPreviewLevel(player.masteries[4]);
    setEvosMasteryPreviewLevel(player.masteries[5]);

    if (currentlySelectedMasteryIndex !== null) {
      selectPerkByIndex(currentlySelectedMasteryIndex);
    }
  }, [isReady, player, currentlySelectedMasteryIndex]);

  if (!player) return null;

  function updateCurrentPrices(forcePerk = null, forceLevel = null) {
    if (!isReady) return;

    const perk = forcePerk ? forcePerk : selectedPerk;
    const priceInRes = perk.getPriceInResourcesFunction(forceLevel);

    setCurrentPriceInResources(priceInRes);
    setCurrentPriceInStabletokens(getPriceInStabletokens(perk, forceLevel));
  }

  function getLevelPrice(perkId, targetLevel) {
    return getMasteryPrice(player, perkId, targetLevel);
  }

  function getMasteryPrice(player, perkId, targetLevel) {
    const currentLevel = parseInt(player.masteries[perkId]);
    let _targetLevel = Math.max(currentLevel, parseInt(targetLevel));

    let maxStep;
    if (perkId === 0) {
      maxStep = 5;
    } else if (perkId === 1 || perkId === 5) {
      maxStep = 4;
    } else {
      maxStep = 3;
    }

    let aiChips = 0;
    if (_targetLevel - currentLevel > 0) {
      for (let i = currentLevel; i < _targetLevel; i++) {
        if (i < maxStep) {
          aiChips += i + 1;
        } else {
          aiChips += maxStep;
        }
      }
    }

    const result = {
      bctPrice: 0,
      resourceCents: [(_targetLevel - currentLevel) * 100, aiChips * 100],
      resourceIndexes: [21, 25],
    };

    return result;
  }

  function getPriceInStabletokens(perk, targetLevel) {
    const currentLevel = parseInt(player.masteries[perk.id]);
    let _targetLevel = Math.max(currentLevel, parseInt(targetLevel));

    let result = BigNumber(1e18)
      .times(10)
      .times(BigNumber(_targetLevel).minus(BigNumber(currentLevel)));

    if (isNaN(result)) {
      result = "0.00";
    }

    console.log(
      `getPriceInStabletokens(perk: ${perk.id}, targetLevel: ${targetLevel}) | Result: ${result}`
    );

    return result;
  }

  const handleBuyMastery = async (levels) => {
    console.log(`Buying ${levels} levels of Mastery ${selectedPerk.id}`);
    await buyMastery(selectedPerk.id, levels);
  };

  const handleBuyMasteryWithKozi = async (levels) => {
    console.log(`Buying ${levels} levels of Mastery ${selectedPerk.id} with KOZI`);
    await buyMastery(selectedPerk.id, levels, true);
  };

  function selectPerkByIndex(index) {
    setSelectedPerk(perks[index]);
    updateCurrentPrices(perks[index]);
    setCurrentlySelectedMasteryIndex(index);
  }

  function onPerkSelect(index) {
    selectPerkByIndex(index);
  }

  function _getBctUpPrices(level) {
    return getLevelPrice(0, level || bctMasteryPreviewLevel);
  }

  function _getEbctUpPrices(level) {
    return getLevelPrice(1, level || eBctMasteryPreviewLevel);
  }

  function _getBlUpPrices(level) {
    return getLevelPrice(2, level || blMasteryPreviewLevel);
  }

  function _getAlUpPrices(level) {
    return getLevelPrice(3, level || alMasteryPreviewLevel);
  }

  function _getNutsUpPrices(level) {
    return getLevelPrice(4, level || nutsMasteryPreviewLevel);
  }

  function _getEvosUpPrices(level) {
    return getLevelPrice(5, level || evosMasteryPreviewLevel);
  }

  function _onBctPriceUpdate(newLevel) {
    updateCurrentPrices(perks[0], newLevel);
    setBctMasteryPreviewLevel(newLevel);
  }
  function _onEbctPriceUpdate(newLevel) {
    updateCurrentPrices(perks[1], newLevel);
    setEBctMasteryPreviewLevel(newLevel);
  }
  function _onBlPriceUpdate(newLevel) {
    updateCurrentPrices(perks[2], newLevel);
    setBlMasteryPreviewLevel(newLevel);
  }
  function _onAlPriceUpdate(newLevel) {
    updateCurrentPrices(perks[3], newLevel);
    setAlMasteryPreviewLevel(newLevel);
  }
  function _onNutsPriceUpdate(newLevel) {
    updateCurrentPrices(perks[4], newLevel);
    setNutsMasteryPreviewLevel(newLevel);
  }
  function _onEvosPriceUpdate(newLevel) {
    updateCurrentPrices(perks[5], newLevel);
    setEvosMasteryPreviewLevel(newLevel);
  }

  const perks = getPerksArray({
    prices: {
      bct: _getBctUpPrices,
      eBct: _getEbctUpPrices,
      bl: _getBlUpPrices,
      al: _getAlUpPrices,
      nuts: _getNutsUpPrices,
      evos: _getEvosUpPrices,
    },
    update: {
      bct: _onBctPriceUpdate,
      eBct: _onEbctPriceUpdate,
      bl: _onBlPriceUpdate,
      al: _onAlPriceUpdate,
      nuts: _onNutsPriceUpdate,
      evos: _onEvosPriceUpdate,
    },
    buyFunc: {
      resources: handleBuyMastery,
      kozi: handleBuyMasteryWithKozi,
    },
  });

  return (
    <GlobalContainer>
      <Container>
        <UpgradeGroupRowContainer>
          <MasteryGroup
            title="Masteries"
            buttons={[
              {
                title: "BCT",
                resource: 99,
                currentQuantity: player.masteries[0],
                maxQuantity: "99",
                onClick: () => onPerkSelect(0),
                isActive: selectedPerk.title === "BCT",
              },
              {
                title: "eBCT",
                resource: 98,
                currentQuantity: player.masteries[1],
                maxQuantity: "99",
                onClick: () => onPerkSelect(1),
                isActive: selectedPerk.title === "eBCT",
              },
              {
                title: "Basic Loot",
                resource: 220,
                currentQuantity: player.masteries[2],
                maxQuantity: "99",
                onClick: () => onPerkSelect(2),
                isActive: selectedPerk.title === "Basic Loot",
              },
              {
                title: "Advanced Loot",
                resource: 221,
                currentQuantity: player.masteries[3],
                maxQuantity: "99",
                onClick: () => onPerkSelect(3),
                isActive: selectedPerk.title === "Advanced Loot",
              },
              {
                title: "Golden Nuts",
                resource: 10,
                currentQuantity: player.masteries[4],
                maxQuantity: "99",
                onClick: () => onPerkSelect(4),
                isActive: selectedPerk.title === "Golden Nuts",
              },
              {
                title: "Evolution Items",
                resource: 1118,
                currentQuantity: player.masteries[5],
                maxQuantity: "99",
                onClick: () => onPerkSelect(5),
                isActive: selectedPerk.title === "Evolution Items",
              },
            ]}
          />
        </UpgradeGroupRowContainer>
      </Container>
      <ActionContainer>
        <MasteryBox
          key={selectedPerk.title + "al"}
          title={selectedPerk.title}
          description={selectedPerk.description}
          description2={selectedPerk.description2}
          eBctPrice={currentPriceInResources?.bct}
          resourcePrices={currentPriceInResources?.resourceCents}
          resourceIndexes={currentPriceInResources?.resourceIndexes}
          currentLevel={eval(selectedPerk.currentLevelReference)}
          previewLevel={eval(selectedPerk.previewLevelReference)}
          maxLevel={99}
          busdPrice={currentPriceInStabletokens}
          player={player}
          updatePrice={selectedPerk.updateFunction}
          buyFunction={selectedPerk.buyFunction}
          buyWithKoziFunction={handleBuyMasteryWithKozi}
          isReady={selectedPerk.isReady}
          isHeader={selectedPerk.isHeader}
          isForbidden={false}
          isBlocked={false}
          isLoading={false}
        />
      </ActionContainer>
    </GlobalContainer>
  );
};

export default MasteriesScreen;
