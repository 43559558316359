import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Messenger from "../../utils/Messenger";

import {
  Container,
  Title,
  Header,
  NftGrid,
  RightAlignedOptions,
  AdvancedOptions,
  AdvancedButton,
} from "./styles";

import SquadHeaderComponent from "../squad-header";
import SquadNftGridItem from "./SquadNftGridItem";

import { useUpgradesContext } from "../../contexts/UpgradesContext";
import { useWeb3Context } from "../../contexts/Web3Context";

const Squad = ({ squad, onExtraSlotClick }) => {
  const { execFunction } = useWeb3Context();
  const { getCollectionNames } = useUpgradesContext();

  const [synergies, setSynergies] = useState([]);
  const [collections, setCollections] = useState(null);
  const [collectionNames, setCollectionNames] = useState([]);
  const [isRemoving, setIsRemoving] = useState(false);
  const [toBeRemovedList, setToBeRemovedList] = useState([]);

  useEffect(() => {
    const traitsSynergy = squad.traits.filter(
      (item, index) => squad.traits.indexOf(item) !== index
    );
    setSynergies(traitsSynergy);
    const collectionsSynergy = squad.collections.filter(
      (item, index) => squad.collections.indexOf(item) !== index
    );
    setCollections(collectionsSynergy);

    // create a unique set of the squad's collections:
    const uniqueCollections = new Set(squad.collections);
    // convert the set to an array:
    const uniqueCollectionsArray = Array.from(uniqueCollections);

    // get the names of the collections (but that's async):
    getCollectionNames(uniqueCollectionsArray).then((names) => {
      setCollectionNames(names);
    });
  }, [squad]);

  const handleCancelQuest = async () => {
    if (squad.currentQuest === 0) {
      Messenger.warn("This squad is not on a quest.");
      return;
    }

    await execFunction({
      contractName: "QuestManager",
      functionName: "cancelQuest",
      functionParams: [squad.id],
      successMessage: "Quest Cancelled",
      navigateOnSuccess: "/kingdom-quests",
    });
  };

  const handleClearSquad = async () => {
    if (
      squad.currentQuest !== 0 &&
      squad.currentQuest !== null &&
      squad.currentQuest !== undefined
    ) {
      Messenger.warn("You must finish or cancel the current quest before clearing the squad.");
      return;
    }

    if (squad.nfts.length === 0) {
      Messenger.warn("This squad is already empty.");
      return;
    }

    await execFunction({
      contractName: "Farm",
      functionName: "clearSquad",
      functionParams: [squad.id],
      successMessage: `Squad #${squad.id} Cleared`,
      navigateOnSuccess: "/kingdom-quests",
    });
  };

  const toggleIsRemoving = () => {
    if (!isRemoving) {
      if (squad.nfts.length === 0) {
        Messenger.warn("This squad is already empty.");
        return;
      }

      if (
        squad.currentQuest !== 0 &&
        squad.currentQuest !== null &&
        squad.currentQuest !== undefined
      ) {
        Messenger.warn(
          "You must finish or cancel the current quest to remove a beast from the squad."
        );
        return;
      }
    }

    setIsRemoving(!isRemoving);
  };

  return (
    collections && (
      <Container>
        <Title>
          {`Squad ${squad.squadNumber}`}
          {collectionNames?.length > 0 ? `: ${collectionNames.join(", ")}` : ""}

          <RightAlignedOptions>
            <AdvancedOptions>
              <a
                data-tooltip-id="sp2"
                data-tooltip-content={`Remove a Beast from squad #${squad.id}`}
                data-tooltip-place="top"
              >
                <AdvancedButton
                  isOn={isRemoving}
                  onClick={toggleIsRemoving}
                  ignoreDisplayNone={true}
                >
                  ➖
                </AdvancedButton>
              </a>
              <Tooltip id="sp2" style={{ fontSize: "12px", fontWeight: 600 }} />
              <a
                data-tooltip-id="sp3"
                data-tooltip-content={`Clear Squad: remove all beasts from squad #${squad.id}`}
                data-tooltip-place="top"
              >
                <AdvancedButton onClick={handleClearSquad}>⭕</AdvancedButton>
              </a>
              <Tooltip id="sp3" style={{ fontSize: "12px", fontWeight: 600 }} />
              <a
                data-tooltip-id="sp4"
                data-tooltip-content={`Cancel Quest: you'll LOSE what you have farmed so far`}
                data-tooltip-place="top"
              >
                <AdvancedButton onClick={handleCancelQuest}>❌</AdvancedButton>
              </a>
              <Tooltip id="sp4" style={{ fontSize: "12px", fontWeight: 600 }} />
            </AdvancedOptions>
          </RightAlignedOptions>
        </Title>
        <Header>
          <SquadHeaderComponent squad={squad} />
        </Header>
        <NftGrid>
          <SquadNftGridItem
            squadId={squad.id || 0}
            nft={squad.nfts.at(0)}
            unlocked={!squad.nfts.at(0)}
            synergies={synergies}
            collections={collections}
            squadType={squad.type_}
            onExtraSlotClick={onExtraSlotClick}
            isRemovingFromSquad={isRemoving}
          />
          <SquadNftGridItem
            squadId={squad.id || 0}
            nft={squad.nfts.at(1)}
            unlocked={!squad.nfts.at(1)}
            synergies={synergies}
            collections={collections}
            squadType={squad.type_}
            onExtraSlotClick={onExtraSlotClick}
            isRemovingFromSquad={isRemoving}
          />
          <SquadNftGridItem
            squadId={squad.id || 0}
            nft={squad.nfts.at(2)}
            unlocked={!squad.nfts.at(2)}
            synergies={synergies}
            collections={collections}
            squadType={squad.type_}
            onExtraSlotClick={onExtraSlotClick}
            isRemovingFromSquad={isRemoving}
          />
          <SquadNftGridItem
            squadId={squad.id || 0}
            nft={squad.nfts.at(3)}
            unlocked={squad.size > 3}
            synergies={synergies}
            collections={collections}
            squadType={squad.type_}
            onExtraSlotClick={onExtraSlotClick}
            isRemovingFromSquad={isRemoving}
          />
          <SquadNftGridItem
            squadId={squad.id || 0}
            nft={squad.nfts.at(4)}
            unlocked={squad.size > 4}
            synergies={synergies}
            collections={collections}
            squadType={squad.type_}
            onExtraSlotClick={onExtraSlotClick}
            isRemovingFromSquad={isRemoving}
          />
          <SquadNftGridItem
            squadId={squad.id || 0}
            nft={squad.nfts.at(5)}
            unlocked={squad.size > 5}
            synergies={synergies}
            collections={collections}
            squadType={squad.type_}
            onExtraSlotClick={onExtraSlotClick}
            isRemovingFromSquad={isRemoving}
          />
        </NftGrid>
      </Container>
    )
  );
};

export default Squad;
