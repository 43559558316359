import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";

import TheForgeScreen from "../components/the-forge-screen/TheForgeScreen";
import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";

// import the Crafting Powder icon (it's resource 26)
import CraftingPowderIcon from "../assets/images/resources/resource-26.png";
import DrascaIcon from "../assets/images/resources/resource-21.png";
import ApeToolsIcon from "../assets/images/resources/resource-9.png";

const TheForge = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">➰</h1>
                    <h1 className="heading text-center">The Forge</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      Here you can melt resources down to Crafting Powder (
                      <img
                        src={CraftingPowderIcon}
                        alt="Crafting Powder"
                        style={{ width: "30px", position: "relative", bottom: "3px" }}
                      />
                      ), and can turn Crafting Powder into resources. The most advanced resources
                      also cost Dragon Scales (
                      <img
                        src={DrascaIcon}
                        alt="Dragon Scales"
                        style={{ width: "30px", position: "relative", bottom: "3px" }}
                      />
                      ) to craft.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Use Ape Tools (
                      <img
                        src={ApeToolsIcon}
                        alt="Dragon Scales"
                        style={{ width: "30px", position: "relative", bottom: "3px" }}
                      />
                      ) to melt resources with a bonus. For each Ape Tool you use when melting, you
                      will receive a 5% bonus on the Crafting Powder yield of resources. You can use
                      up to 10 Ape Tools per melt, reaching a maximum bonus of 50%.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      You can also use Ape Tools (
                      <img
                        src={ApeToolsIcon}
                        alt="Dragon Scales"
                        style={{ width: "30px", position: "relative", bottom: "3px" }}
                      />
                      ) to craft resources at a discount. For each Ape Tool you use when crafting,
                      you will receive a 5% discount on the Crafting Powder cost of the resources.
                      You can use up to 10 Ape Tools per craft, reaching a maximum discount of 50%.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-wrap justify-content-center">
                    <TheForgeScreen></TheForgeScreen>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default TheForge;
