import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import Header from "../components/header/Header";

import ProsperityWarsScreen from "../components/prosperity-wars-screen/ProsperityWarsScreen";

import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";

import { stasherLevelByBalance } from "../utils/constants";

import EbctIcon from "../assets/images/resources/resource-eBCT-sm.png";
import FeatherIcon from "../assets/images/resources/resource-22.png";
import HornIcon from "../assets/images/resources/resource-23.png";
import SkullIcon from "../assets/images/resources/resource-24.png";

const ProsperityWars = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();
  const { player } = usePlayerContext();
  const [stasherLevel, setStasherLevel] = useState(0);

  useEffect(() => {
    if (!player) return;

    setStasherLevel(
      stasherLevelByBalance(parseFloat(BigNumber(player.stashed).div(1e18).toString()))
    );
  }, [player]);

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🥳</h1>
                    <h1 className="heading text-center">Prosperity Wars</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      Where winning is only half the fun!
                    </h5>

                    <h5 className="sub-title help-center mg-bt-32">
                      Players compete to win the auctioned item. If 24 hours pass without a new bid,
                      the latest bidder wins the auction, taking home the auctioned item plus a
                      Winner Bonus.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Whenever someone bids on an item, the auction is extended by 24 hours and the
                      Winner Bonus increases by 10 eBCT (⚡), 0.20 Griffin Feathers (
                      <img
                        src={FeatherIcon}
                        alt="Griffin Feather"
                        style={{ width: "30px", position: "relative", bottom: "3px" }}
                      />
                      ), 0.10 Satyr Horns (
                      <img
                        src={HornIcon}
                        alt="Satyr Horns"
                        style={{ width: "30px", position: "relative", bottom: "3px" }}
                      />
                      ), and 0.05 Dinosaur Skulls (
                      <img
                        src={SkullIcon}
                        alt="Dinosaur Skulls"
                        style={{ width: "30px", position: "relative", bottom: "3px" }}
                      />
                      ).
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32" style={{ color: "yellow" }}>
                      If you bid on an item and someone outbids you, you instantly get your money
                      back plus a 2% instant profit.
                    </h5>

                    <h5 className="sub-title help-center">
                      <p className="miniP">
                        You play Prosperity Wars with USDC directly. When you place a bid, the money
                        is sent to the smart contract. If you are outbid, you instantly get your
                        money back plus 2%.
                      </p>
                      <p className="miniP">
                        Example: You bid 100 USDC on an item. Someone outbids you with 105 USDC. You
                        get 102 USDC back.
                      </p>
                      <p className="miniP">Please note: minor decimal imprecisions may occur.</p>
                      <p className="miniP">
                        Players can make Safe Bids or Power Bids. In both types of bids you always
                        pay 5% more than the latest bid, and you always get your money back plus 2%
                        if you are outbid; While Safe Bids will fail if the price increases faster
                        than the website can keep up with, Power Bids will go through even if it
                        means paying more than the last price you saw.
                      </p>
                      <p className="miniP">
                        Please note: when a Safe Bid fails, you may have to pay blockchain fees
                        nevertheless.
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ProsperityWarsScreen />
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default ProsperityWars;
