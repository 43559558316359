function nameToNumber(name) {
  switch (name) {
    case 'Common':
      return 1
    case 'Rare':
      return 2
    case 'Epic':
      return 3
    case 'Legendary':
      return 4
    case 'Mythic':
      return 5
    default:
      return 0
  }
}

function numberToName(number) {
  switch (number) {
    case 1:
      return 'Common'
    case 2:
      return 'Rare'
    case 3:
      return 'Epic'
    case 4:
      return 'Legendary'
    case 5:
      return 'Mythic'
    default:
      return 'Unknown'
  }
}

export { nameToNumber, numberToName }
