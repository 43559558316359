import BigNumber from "bignumber.js";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Rpc from "../rpc/Rpc";
import Debouncer from "../utils/Debouncer";
import Messenger from "../utils/Messenger";
import Contracts from "../utils/Contracts";

import Header from "../components/header/Header";

import TravelSongScreen from "../components/travel-song-screen/TravelSongScreen";
import AccountModel from "../models/AccountModel";
import TokenModel from "../models/TokenModel";
import ContractModel from "../models/ContractModel";
import { shortAccount } from "../utils/account";

import { useWeb3Context } from "../contexts/Web3Context";
import { travelSongTypeOf } from "../utils/travelSongTypes";

const DEBOUNCE_DELAY = 800;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const InputIdField = styled.input`
  border-radius: 4px;
  height: 44px;
  max-width: 600px;
  border: 1px solid #e5e5e5;
  padding: 0 10px;
  margin-bottom: 20px;
  font-size: 15px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  transition: all 0.3s ease-in-out;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  font-size: 20px;
  height: 60px;
`;

const TravelSong = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { consult, isReady, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();

  const { id } = useParams();

  const [inputId, setInputId] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshingTxns, setIsRefreshingTxns] = useState(false);
  const [currentResult, setCurrentResult] = useState({
    object: {},
    type_: "",
  });
  const [latestBlock, setLatestBlock] = useState({
    object: {},
    type_: "block",
  });
  const [latestTxns, setLatestTxns] = useState({
    object: {},
    type_: "transaction",
  });

  useEffect(() => {
    if(IS_PAUSED) return;

    if (id && !isFetching && !isLoaded) {
      setInputId(id);

      debounceFetchByType(travelSongTypeOf(id), id);
    } else if (!id && !isFetching && !isLoaded) {
      fetchLatestSummary();
    }
  }, [id, isFetching, isLoaded, isReady, location]);

  useEffect(() => {
    if(IS_PAUSED) return;

    if (id) {
      setInputId(id);
      debounceFetchByType(travelSongTypeOf(id), id);
    } else {
      setInputId("");
      setIsLoaded(false);
      setIsFetching(false);
      setIsRefreshingTxns(false);
      setCurrentResult({ object: {}, type_: "" });
    }
  }, [isReady, location]);

  function debounceFetchByType(type_, id, page) {
    setIsFetching(true);

    let fn;
    switch (type_) {
      case "token":
        fn = fetchToken;
        break;
      case "account":
        fn = fetchAccount;
        break;
      case "block":
        fn = fetchBlock;
        break;
      case "transaction":
        fn = fetchTx;
        break;
      default:
        break;
    }

    Debouncer.debounce("fetchFromRpc", fn, DEBOUNCE_DELAY, id, page);
  }

  async function fetchBlock(blockNumber) {
    const blockModel = await Rpc.fetchBlock(blockNumber);
    setCurrentResult({ object: blockModel, type_: "block" });

    setIsLoaded(true);
    setIsFetching(false);

    console.log("Block Fetched: ", blockModel);
  }

  async function fetchTx(hash) {
    const txnModel = await Rpc.fetchFullTx(hash);
    setCurrentResult({ object: txnModel, type_: "transaction" });

    setIsLoaded(true);
    setIsFetching(false);

    console.log("Tx Fetched: ", txnModel);
  }

  async function fetchAccount(address, page = 1) {
    if (!isReady) {
      Messenger.info(`Connect your Metamask to see the balances of ${shortAccount(address)}`);
    }

    let model;
    if (Contracts.isKnownContract(address)) {
      model = new ContractModel(address);
    } else {
      model = new AccountModel(address);
    }

    await model.fetchKoziBalance();
    await model.setTxList(page);

    const tokenBalances = await consultTokenBalancesOf(address);
    model.setTokenBalances(tokenBalances);

    setCurrentResult({ object: model, type_: "account" });

    setIsLoaded(true);
    setIsFetching(false);
    setIsRefreshingTxns(false);
    console.log("Account Fetched: ", model);
  }

  async function fetchToken(address, page = 1) {
    if (!isReady) {
      Messenger.info(`Connect your Metamask to see the balances of ${shortAccount(address)}`);
    }

    const tokenModel = await configTokenModel(address);
    await tokenModel.fetchKoziBalance();

    await tokenModel.setTxList(page, true);

    const tokenBalances = await consultTokenBalancesOf(address);
    tokenModel.setTokenBalances(tokenBalances);

    setCurrentResult({ object: tokenModel, type_: "token" });

    setIsLoaded(true);
    setIsFetching(false);
    console.log("ERC20 Token Fetched: ", tokenModel);
  }

  async function consultTokenBalancesOf(address) {
    const bctBalance = await consult({
      contractName: "BCT",
      functionName: "balanceOf",
      functionParams: [address],
    });

    const usdcBalance = await consult({
      contractName: "Stabletoken",
      functionName: "balanceOf",
      functionParams: [address],
    });

    const result = {
      BCT: BigNumber(bctBalance).toString(),
      USDC: BigNumber(usdcBalance).toString(),
    };

    return result;
  }

  async function configTokenModel(address) {
    // tokens in the Kingdom Chain may have a special function called basicData() that returns everything at once.
    // if the token has this function, we use it to get the data.
    // else, we get the data from the individual functions.
    const tokenModel = new TokenModel(address);

    // let's get a NativeERC20Token instance with the address
    const tokenContract = Contracts.getNativeERC20TokenContract(address);

    // let's get the data from the basicData() function assuming it exists:
    let tokenData = await tokenContract.basicData();
    const updatedTokenModel = tokenModel.setTokenData(tokenData);

    return updatedTokenModel;
  }

  const handleInputChange = async (e) => {
    // we'll remove comas and dots from the input
    const newIdWithoutComasOrDots = e.target.value.replace(/,/g, "").replace(/\./g, "");

    const newId = newIdWithoutComasOrDots.trim();
    setInputId(newId);

    if (!newId) {
      setIsFetching(false);
      setIsLoaded(false);
      navigate(`/travelsong/`);
      fetchLatestSummary();
      return;
    }

    const type_ = travelSongTypeOf(newId);
    navigateByType(type_, newId);
    debounceFetchByType(type_, newId);
  };

  const navigateByType = (type_, id) => {
    switch (type_) {
      case "token":
        console.log(`Nav Search (Token): ${id}...`);
        navigate(`/travelsong/token/${id}`);
        break;
      case "account":
        console.log(`Nav Search (Address): ${id}...`);
        navigate(`/travelsong/address/${id}`);
        break;
      case "block":
        console.log(`Nav Search (Block): ${id}...`);
        navigate(`/travelsong/block/${id}`);
        break;
      case "transaction":
        console.log(`Nav Search (Tx): ${id}...`);
        navigate(`/travelsong/tx/${id}`);
        break;
      default:
        break;
    }
  };

  const handleInputClick = (e) => {
    e.target.select();
  };

  // This is on click: "Search" button
  const handleSearchCallback = ({ value, isFrom, isTo, isTxHash }) => {
    if (!value) return;

    setInputId(value);
    const type_ = travelSongTypeOf(value);
    navigateByType(type_, value);
    debounceFetchByType(type_, value);
  };

  const fetchLatestSummary = async () => {
    const latestSummary = await Rpc.fetchLatestSummary();

    setLatestBlock({ object: latestSummary.block, type_: "block" });
    setLatestTxns({ object: latestSummary.txList, type_: "transaction" });

    console.log("Latest Block Fetched: ", latestSummary.block);
    console.log("Latest Txns Fetched: ", latestSummary.txList);
  };

  const changePageCallback = (newPage) => {
    console.log("changePageCallback to", newPage);

    if (currentResult.type_ === "account") {
      setIsRefreshingTxns(true);
      Debouncer.debounce("fetchFromRpc", fetchAccount, 100, id, newPage);
    } else if (currentResult.type_ === "token") {
      setIsRefreshingTxns(true);
      Debouncer.debounce("fetchFromRpc", fetchToken, 100, id, newPage);
    }
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🎼</h1>
                    <h1 className="heading text-center">Travel Song Explorer</h1>
                  </div>
                  <h5 className="sub-title help-center mg-bt-32">
                    A light blockchain explorer created specifically for the Kingdom Chain
                  </h5>
                  <InputContainer style={{ marginBottom: "-40px" }}>
                    <InputIdField
                      type="text"
                      value={inputId}
                      onChange={handleInputChange}
                      onClick={handleInputClick}
                      placeholder={inputId || "Search by hash, block number or address"}
                      style={{ textAlign: "center" }}
                    />
                  </InputContainer>
                </div>
              </div>
            </div>
          </section>
          {isLoaded && !isFetching ? (
            <TravelSongScreen
              isRefreshingTxns={isRefreshingTxns}
              isFetching={isFetching}
              toSearch={inputId}
              result={currentResult.object}
              resultType={currentResult.type_}
              searchCallback={handleSearchCallback}
              changePageCallback={changePageCallback}
            />
          ) : id ? (
            <Loading>Loading...</Loading>
          ) : latestBlock?.object?.number ? (
            <TravelSongScreen
              isRefreshingTxns={isRefreshingTxns}
              isFetching={isFetching}
              toSearch={latestBlock?.object?.number}
              result={latestBlock.object}
              resultType={latestBlock.type_}
              searchCallback={handleSearchCallback}
              isLatestBlock={true}
              txnList={latestTxns.object}
            />
          ) : (
            <Loading>Loading...</Loading>
          )}
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default TravelSong;
