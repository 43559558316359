import React, { useState, useEffect } from "react";

import { ModalOverlay, ModalContent } from "./styles";

const DisclaimerModal = () => {
  const [showModal, setShowModal] = useState(true);
  const [skipModal, setSkipModal] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [titleText, setTitleText] = useState("DISCLAIMER");
  const [modalText, setModalText] = useState(
    "Beast Kingdom was born from the ashes of a previous crypto game that can no longer be mentioned. After failing to deliver on its original vision, the companies behind that game dissolved, and the remaining assets were donated to the community for collective decision-making. Beast Kingdom is not run by any company; instead, the game is owned by the community. Voting power is allocated based on one's stake in the game, up to a point: the more you contribute, the more say you have in its future direction.    We have a community developer who doesn't receive a salary but is permitted to allocate 10% of the game's income toward infrastructure costs and an occasional dinner. The remaining 90% of the revenue is distributed back to the community as USDC deposits into our Community Pool. These rules are automated and enforced through publicly accessible smart contracts. We are not a company; we are a community. That means we can collectively decide on anything through voting—whether it's changing rules, adjusting values, adding new features, or removing existing ones. Voting takes place exclusively on Discord. While the prospects look promising, especially if our deflationary economic model performs as expected, there's always a risk of things going south. If you choose to join us, you become part of this community and accept the associated risks. There is no option for reimbursement, no company to hold liable, and no one to blame. We're all in this together. If you're uncomfortable with these terms, we kindly ask that you refrain from joining. However, if you're a pioneer like us and willing to take the risk, welcome to the Beast Kingdom community!"
  );
  const [modalText2, setModalText2] = useState(
    `By clicking "YES, I'm a pioneer!" you agree to the above terms and conditions.`
  );

  useEffect(() => {
    //localStorage.clear(); // this resets the skipModal pref
    const skipModalPref = localStorage.getItem("skipModal_2");
    if (skipModalPref === "true") {
      setShowModal(false);
    }
  }, []);

  const handleCloseModal = () => {
    if (skipModal) {
      localStorage.setItem("skipModal_2", "true");
    }
    setShowModal(false);
  };

  const handleNoClick = () => {
    setShowButtons(false);
    setTitleText("YOU SHALL NOT PASS!");
    setModalText(
      "Thank you for your interest, but please go away. This is not for you. If you need support, please reach out to the community on Discord."
    );
    setModalText2(
      "The migration from MH to Beast Kingdom lasted for 6 months and is now closed forever. There is no way to migrate those assets now, as the migration itself was managed by smart contracts on the blockchain."
    );
  };

  const handleAgreeClick = () => {
    handleCloseModal();
  };

  return (
    <>
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <h3>{titleText}</h3>
            <p>{modalText}</p>
            <p className="small-legal">{modalText2}</p>
            {showButtons && (
              <div className="button-container">
                <button className="red-button" onClick={handleNoClick}>
                  NO
                </button>
                <button className="green-button" onClick={handleAgreeClick}>
                  YES, I'm a pioneer!
                </button>
              </div>
            )}
            {showButtons && (
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="skipModalCheckbox"
                  onChange={(event) => {
                    event.stopPropagation(); // Prevent event propagation
                    setSkipModal(!skipModal);
                  }}
                />
                <label className="checkbox-label" htmlFor="skipModalCheckbox">
                  Do not show this again unless something changes
                </label>
              </div>
            )}
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default DisclaimerModal;
