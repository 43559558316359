import { toast } from "react-toastify";
import { IS_PAUSED } from "./environment.js";

class Messenger {
  constructor() {
    // Binding methods
    this._show = this._show.bind(this);
    this._callShow = this._callShow.bind(this);
    this.warn = this.warn.bind(this);
    this.error = this.error.bind(this);
    this.success = this.success.bind(this);
    this.info = this.info.bind(this);
    this.cancelDelay = this.cancelDelay.bind(this);
    this.dismissAll = this.dismissAll.bind(this);
    this.setDefaultAutoClose = this.setDefaultAutoClose.bind(this);
    this.setUseSingleToast = this.setUseSingleToast.bind(this);
    this.setDefaultPosition = this.setDefaultPosition.bind(this);
    this.setLastToastId = this.setLastToastId.bind(this);
    this.getLastToastId = this.getLastToastId.bind(this);
    this.noPlayerDelayedError = this.noPlayerDelayedError.bind(this);

    this.lastToastId = null;
    this.defaultAutoClose = 10000;
    this.useSingleToast = true;
    this.defaultPosition = toast.POSITION.BOTTOM_CENTER;
    this.timeout = null;
    this.POSITION = toast.POSITION;
  }

  _show(message, type, autoClose, position = this.defaultPosition, destroyOthers = false) {
    if (
      (this.useSingleToast && this.lastToastId && toast.isActive(this.lastToastId)) ||
      destroyOthers
    ) {
      toast.update(this.lastToastId, {
        render: message,
        type,
        position,
        closeOnClick: true,
        autoClose,
      });
    } else {
      this.lastToastId = toast(message, {
        type,
        position,
        closeOnClick: true,
        autoClose,
      });
    }
  }

  _callShow(message, type, autoClose, delay, position, destroyOthers) {
    this.cancelDelay();
    if (delay > 0) {
      this.timeout = setTimeout(
        this._show,
        delay,
        message,
        type,
        autoClose,
        position,
        destroyOthers
      );
    } else {
      this._show(message, type, autoClose, position, destroyOthers);
    }
  }

  warn(
    message,
    autoClose = this.defaultAutoClose,
    delay = 0,
    position = toast.POSITION.BOTTOM_CENTER
  ) {
    this._callShow(message, toast.TYPE.WARNING, autoClose, delay, position);
  }

  error(
    message,
    autoClose = this.defaultAutoClose,
    delay = 0,
    position = toast.POSITION.BOTTOM_CENTER,
    destroyOthers = false
  ) {
    this._callShow(message, toast.TYPE.ERROR, autoClose, delay, position, destroyOthers);
  }

  success(
    message,
    autoClose = this.defaultAutoClose,
    delay = 0,
    position = toast.POSITION.BOTTOM_CENTER,
    destroyOthers = false
  ) {
    this._callShow(message, toast.TYPE.SUCCESS, autoClose, delay, position, destroyOthers);
  }

  info(
    message,
    autoClose = this.defaultAutoClose,
    delay = 0,
    position = toast.POSITION.BOTTOM_CENTER,
    destroyOthers = false
  ) {
    this._callShow(message, toast.TYPE.INFO, autoClose, delay, position, destroyOthers);
  }

  cancelDelay() {
    clearTimeout(this.timeout);
  }

  dismissAll() {
    toast.dismiss();
  }

  setDefaultAutoClose(autoClose) {
    this.defaultAutoClose = autoClose;
  }

  setUseSingleToast(useSingleToast) {
    this.useSingleToast = useSingleToast;
  }

  setDefaultPosition(position) {
    this.defaultPosition = position;
  }

  setLastToastId(lastToastId) {
    this.lastToastId = lastToastId;
  }

  getLastToastId() {
    return this.lastToastId;
  }

  noPlayerDelayedError() {
    if (IS_PAUSED) return;

    this._callShow(
      "Please connect your Metamask to the Kingdom Chain and make sure you're registered (Play -> Register) to access this content.",
      toast.TYPE.WARNING,
      this.defaultAutoClose,
      10000
    );
  }
}

const messenger = new Messenger();
export default messenger;
