// launch date is October 6th, 2023
export const launchDate = new Date(Date.UTC(2023, 9, 6)); // Note: January is 0, February is 1, etc.

export function monthsSinceLaunch() {
  const currentYear = new Date().getUTCFullYear();
  const currentMonth = new Date().getUTCMonth();
  const currentDay = new Date().getUTCDate();

  // we will consider that "1 month" is exactly 30 days, and we'll return only the integer part of the result:
  const full30DaysPassed = Math.floor(
    (Date.UTC(currentYear, currentMonth, currentDay) -
      Date.UTC(launchDate.getUTCFullYear(), launchDate.getUTCMonth(), launchDate.getUTCDate())) /
      (1000 * 60 * 60 * 24 * 30)
  );

  return full30DaysPassed;
}

export function getSpread(userStasherLevel) {
  // This matrix represents the spread table
  const spreadMatrix = [
    [70, 66, 62, 58, 54, 50, 46, 42, 38, 34, 30],
    [60, 56, 52, 48, 44, 40, 36, 32, 28, 24, 20],
    [50, 46, 42, 38, 34, 30, 26, 22, 18, 14, 10],
    [40, 36, 32, 28, 24, 20, 16, 12, 8, 4, 0],
    [36, 32, 28, 24, 20, 16, 12, 8, 4, 0, 0],
    [32, 28, 24, 20, 16, 12, 8, 4, 0, 0, 0],
    [28, 24, 20, 16, 12, 8, 4, 0, 0, 0, 0],
    [24, 20, 16, 12, 8, 4, 0, 0, 0, 0, 0],
    [20, 16, 12, 8, 4, 0, 0, 0, 0, 0, 0],
    [16, 12, 8, 4, 0, 0, 0, 0, 0, 0, 0],
    [12, 8, 4, 0, 0, 0, 0, 0, 0, 0, 0],
    [8, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ];

  const months = monthsSinceLaunch();

  if (userStasherLevel > spreadMatrix[0].length) {
    console.error("Invalid Stasher level.");
    return null;
  }

  if (months >= spreadMatrix.length) {
    months = spreadMatrix.length - 1;
  }

  console.log(
    `userStasherLevel: ${userStasherLevel} | months: ${months} | spread: ${spreadMatrix[months][userStasherLevel]}`
  );

  return spreadMatrix[months][userStasherLevel];
}
