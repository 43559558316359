import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 4px;
  margin: 10px;

  animation: ${fadeIn} 0.5s ease-in-out;

  opacity: ${({ disable }) => (disable ? 0.1 : 1)};
`;

export const CardContainer = styled.div`
  margin-bottom: -3px;
  z-index: 2;
`;

export const ResourceNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: 3px solid black;
  border-radius: 4px 4px 4px 4px;
`;

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: 4px solid black;
  border-radius: 4px 4px 0px 0px;
  //background: black;
  background: ${(props) => (props.isPoolFuel ? "black" : "black")};
  margin-top: ${({ isNft, isMaxedOut, isPowerBid }) =>
    isNft ? (isMaxedOut ? (isPowerBid ? "17px" : "12px") : isPowerBid ? "10px" : "12px") : "9px"};
`;

export const PricesTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 198px;
  flex-direction: column;
  height: 21px;
  //background: linear-gradient(0deg, #000 40%, #111 100%);
  background: ${(props) =>
    props.isPoolFuel
      ? "linear-gradient(0deg, rgba(38, 4, 60,1), rgba(108, 61, 104,1))"
      : "linear-gradient(0deg, #000 40%, #111 100%)"};
  padding-bottom: ${(props) => (props.isPoolFuel ? "4px" : "6px")};
  font-size: ${(props) => (props.isPoolFuel ? "11px" : "10px")};
  color: #ccc;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  border-radius: ${(props) => (props.isPoolFuel ? "2px" : "2px 2px 0px 0px")};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MiddleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  flex-direction: column;
  margin-bottom: 1px;
  background: black;
  z-index: 2;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0px;
  outline: 1px solid black;
  background-color: black;
`;

export const NumberInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38%;
  height: 28px;
  background: linear-gradient(180deg, #222 0%, #111 100%);
  color: white;
  border-radius: 4px;
  user-select: none;
  margin-left: 1px;
  margin-right: 1px;
`;

export const NumberButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  outline: 1px solid black;
  //outline-offset: -1px;
  height: 28px;
  border: none;
  background: linear-gradient(180deg, #222 0%, #111 100%);
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};

  color: #fff;
  font-size: 16px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  user-select: none;
  border-radius: 4px;

  ${(props) =>
    !props.disable &&
    `
      &:hover {
        background: linear-gradient(180deg, #333 0%, #222 100%);
      }

      &:active {
        background: linear-gradient(180deg, #444 0%, #333 100%);
      }
  `}
`;

export const PlusPlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  outline: 1px solid black;
  //outline-offset: -1px;
  height: 28px;
  border: none;
  background: linear-gradient(180deg, #222 0%, #111 100%);
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
  color: #fff;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 16px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  user-select: none;
  border-radius: 4px;

  ${(props) =>
    !props.disable &&
    `
      &:hover {
        background: linear-gradient(180deg, #333 0%, #222 100%);
      }

      &:active {
        background: linear-gradient(180deg, #444 0%, #333 100%);
      }
  `}
`;

export const MinusMinusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  outline: 1px solid black;
  //outline-offset: -1px;
  height: 28px;
  border: none;
  background: linear-gradient(180deg, #222 0%, #111 100%);
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
  color: #fff;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 20px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  border-radius: 4px;
  user-select: none;

  ${(props) =>
    !props.disable &&
    `
      &:hover {
        background: linear-gradient(180deg, #333 0%, #222 100%);
      }

      &:active {
        background: linear-gradient(180deg, #444 0%, #333 100%);
      }
  `}
`;

export const Number = styled.span`
  font-size: 15px;
  font-weight: bold;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
`;

export const OriginalPriceContainer = styled.div`
  display: flex;
  justify-content: top;
  align-items: center;
  width: 190px;
  flex-direction: column;
  margin-bottom: 3px;
  height: 20px;
  background: linear-gradient(0deg, #111 0%, #222 100%);
  z-index: 2;
  outline: 1px solid black;
  border-radius: 4px 4px 0px 0px;
  padding: 4px 0px 4px 0px;
  font-size: 12px;
  color: #ccc;

  position: relative;
  top: ${({ isNft, isMaxedOut }) => (isNft ? (isMaxedOut ? "0px" : "0px") : "0px")};
`;

export const CreateOrderButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 198px;
  margin: 10px 4px 4px 4px;
  outline: 4px solid black;
  border-radius: 4px 4px 4px 4px;
`;

export const CreateOrderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
  color: ${(props) => (props.disable ? "gray" : "black")};
  font-size: 16px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  user-select: none;
  border-radius: 4px;
  background-color: ${(props) => (props.disable ? "#444" : "white")};
  transition: all 0.2s ease-in-out;

  ${(props) =>
    !props.disable &&
    `
  &:hover {
    background-color: black;
    color: white;
  }

  &:active {
    background-color: grey;
    color: white;
  }
  `}
`;

export const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #ddd;
  width: 200px;
  height: 50px;
  position: absolute;
  transform: translateY(-10px);
`;

export const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  margin: 0px 4px 0px 0px;
  border-radius: 4px;
  outline: 1px solid white;

  height: 20px;
  width: 20px;

  &:hover {
    background: 0xa5a;
    outline: 2px solid red;
  }

  &:active {
    background: ${(props) => props.background};
    filter: brightness(0.9);
  }

  img {
    filter: invert(1);
    width: 70%;
  }
`;

export const WinnerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 198px;
  background: ${(props) =>
    props.ebct > 0
      ? "linear-gradient(0deg, #101 10%, #000 100%)"
      : "linear-gradient(0deg, #111 10%, #000 100%)"};
  border-radius: 2px;
  outline: 4px solid black;
  margin-top: 10px;

  font-family: "Metropolis", sans-serif;
  font-size: 11px;
  color: #ccc;
`;

export const OutbidText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 198px;
  background: linear-gradient(0deg, #010 10%, #000 100%);
  border-radius: 0px 0px 2px 2px;
  outline: 4px solid black;
  margin-top: 6px;

  font-family: "Metropolis", sans-serif;
  font-size: 11px;
  color: #2f2;
`;

export const ClaimButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

export const ClaimButton = styled.div`
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  padding: 6px 5px;
  text-align: center;
  width: 198px;
  min-width: 180px;
  transition: all 0.2s ease-in-out;
  margin-top: -10px;

  &:hover {
    background-color: #060;
    color: #fff;
  }

  &:active {
    background-color: #0f0;
    color: #fff;
  }
`;
