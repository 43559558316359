import { ethers } from "ethers";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/header/Header.jsx";
import BigNumber from "bignumber.js";
import Messenger from "../utils/Messenger.js";

import Contracts from "../utils/Contracts.js";
import { quoteBuyKozi, quoteStableToKozi, priceFromReserves } from "../utils/koziPool.js";

import { useWeb3Context } from "../contexts/Web3Context.jsx";

import IntervalManager from "../utils/IntervalManager.js";

const DECIMAL_PLACES = 6;
BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_UP });

const TradingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  padding: 20px;
  margin: 4px;
  border-radius: 8px;
  outline: 3px solid #fff;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

const TradingButton = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background: ${(props) =>
    props.disabled
      ? "linear-gradient(145deg, #ccc, #bbb)"
      : "linear-gradient(145deg, #45a049, #4caf58)"};
  border: none;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background 0.3s;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  &:hover {
    background: ${(props) =>
      props.disabled
        ? "linear-gradient(145deg, #ccc, #bbb)"
        : "linear-gradient(145deg, #3c8f40, #51c665)"};
  }
`;

const TradingLabel = styled.label`
  color: #fff;
  margin-bottom: 6px;
  font-size: 14px;
`;

const TradingInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 6px;
  border: 1px solid #4caf58;
  background-color: #1c1b20;
  color: #fff;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  max-width: 90%;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  padding: 20px;
  gap: 6px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const DescriptionText = styled.p`
  font-size: 11px;
  color: #ccc;
  padding: 5px;
  margin-bottom: 12px;
  text-align: center;
  line-height: 1.5;
`;

const BalanceText = styled.p`
  font-size: 11px;
  color: #ff0;
  margin-bottom: 12px;
  margin-top: -8px;
  text-align: center;
  line-height: 1.5;
`;

const ExitBridge = () => {
  BigNumber.config({ DECIMAL_PLACES: 18 });
  const {
    isReady,
    IS_PAUSED,
    PAUSED_MESSAGE,
    PAUSED_TITLE,
    switchToBnbChain,
    switchToKingdomChain,
    getChainId,
    signer,
    execFunction,
  } = useWeb3Context();
  const [chainId, setChainId] = useState(0);
  const [formattedStabletokenAmount, setFormattedStabletokenAmount] = useState("0");
  const [formattedBnbUsdcAmount, setFormattedBnbUsdcAmount] = useState("0");
  const [formattedStableToStableAmount, setFormattedStableToStableAmount] = useState("0");
  const [bnbChainBalance, setBnbChainBalance] = useState(BigNumber("0"));
  const [currentBnbBlock, setCurrentBnbBlock] = useState(0);
  const [lastClaimBlock, setLastClaimBlock] = useState(0);

  useEffect(() => {
    cid();
    getBalanceOnBnbChain();
  }, [isReady]);

  const bnbChainProvider = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/", 56);

  const getBalanceOnBnbChain = async () => {
    try {
      const bnbBridgeAddress = Contracts.keyToAddress("BnbBridge");
      const bnbBridgeAbi = Contracts.keyToAbi("BnbBridge");
      const bnbProvider = bnbChainProvider;
      const bnbBridgeContractForBalances = new ethers.Contract(
        bnbBridgeAddress,
        bnbBridgeAbi,
        bnbProvider
      );

      const _balance = await bnbBridgeContractForBalances.balances(signer.getAddress());
      setBnbChainBalance(BigNumber(_balance));

      const _lastClaimBlock = await bnbBridgeContractForBalances.lastClaimReceivedBlock(
        signer.getAddress()
      );
      setLastClaimBlock(_lastClaimBlock);

      const _currentBnbBlock = await bnbProvider.getBlockNumber();
      setCurrentBnbBlock(_currentBnbBlock);
    } catch (e) {
      console.warn(`Unable to fetch BNB Chain balance: ${e}`);
    }
  };

  const handleStabletokenAmountChange = (event) => {
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedStabletokenAmount(value);

    // Perform calculation to update KOZI amount
    const calculatedBnbUsdc = BigNumber(value).times(1e18).minus(BigNumber(2).times(1e18));
    const bnbUsdc = calculatedBnbUsdc.div(1e18);

    if (bnbUsdc.isNaN() || bnbUsdc.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN") {
      setFormattedBnbUsdcAmount("0");
      return;
    }

    setFormattedBnbUsdcAmount(bnbUsdc.toFixed(DECIMAL_PLACES), BigNumber.ROUND_DOWN);
  };

  const handleBnbUsdcAmountChange = (event) => {
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedBnbUsdcAmount(value);

    // Perform calculation to update USDC amount
    const calculatedStabletokenAmount = BigNumber(value).times(1e18).plus(BigNumber(2).times(1e18));
    const stabletokenAmount = calculatedStabletokenAmount.div(1e18);

    if (
      stabletokenAmount.isNaN() ||
      stabletokenAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN"
    ) {
      setFormattedStabletokenAmount("0");
      return;
    }

    if (stabletokenAmount.lt(0)) {
      setFormattedStabletokenAmount("Infinity");
    } else {
      setFormattedStabletokenAmount(stabletokenAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP));
    }
  };

  const handleSendUsdcClick = async () => {
    if (!chainId || chainId === 56) return;

    const finalAmount = BigNumber(formattedStabletokenAmount)
      .times(1e18)
      .toFixed(0, BigNumber.ROUND_DOWN);

    if (formattedStabletokenAmount === "0" || BigNumber(formattedStabletokenAmount).lt(0)) {
      Messenger.error("Please enter a valid amount of USDC to bridge.");
      return;
    }

    if (BigNumber(formattedStabletokenAmount).lt(5)) {
      Messenger.error("The minimum amount of USDC to bridge is $5.");
      return;
    }

    const allowance = {
      tokenContractName: "Stabletoken",
      addressToAllow: Contracts.keyToAddress("KingdomBridge"),
      allowedContractName: "KingdomBridge",
      allowedAssetDescription: "USDC in your wallet",
      successMessage: "Allowance given to the Kingdom Bridge",
    };

    const success = await execFunction({
      contractName: "KingdomBridge",
      functionName: "depositStabletokens",
      functionParams: [finalAmount],
      successMessage: "Bridging process initiated. Check your Discord for updates.",
      errorList: [
        {
          reason: "ERC20: transfer amount exceeds balance",
          message: `Insufficient funds`,
        },
      ],
      allowance,
    });

    if (!success) {
      Messenger.error(
        "Failed to initiate bridging process. Please reload the page and try again, or seek help on Discord."
      );
    }
  };

  const handleWithdrawClick = async () => {
    if (!chainId || chainId === 39916801) return;

    const bnbBridgeAbi = Contracts.keyToAbi("BnbBridge");
    const bnbBridgeAddress = Contracts.keyToAddress("BnbBridge");
    const bnbBridge = new ethers.Contract(bnbBridgeAddress, bnbBridgeAbi, signer);

    let bridgeTx;
    try {
      bridgeTx = await bnbBridge.claim();
    } catch (e) {
      console.error(e);
      if (e.message.indexOf("User denied transaction signature") !== -1) {
        Messenger.error(`Transaction cancelled by user.`);
      } else if (e.message.indexOf("too soon") !== -1) {
        Messenger.error(
          `Your withdrawal is not yet claimable. Please wait until ${nextWithdrawal()}.`
        );
      } else if (e.message.indexOf("no balance") !== -1) {
        Messenger.error(
          `You don't have any pending withdrawals. Send some USDC to the bridge first.`
        );
      } else {
        Messenger.error(`Failed to bridge USDC: ${e.message}`);
      }
      return;
    }

    Messenger.info("Transaction sent to the blockchain. Please wait for it to be mined.", 20000);
    // Wait for the transaction to be mined
    await bridgeTx.wait();

    console.log(`Bridging process initiated.`);
    Messenger.success(
      "Withdrawal complete! Your USDC is already in your wallet. Please reload the website to refresh your blockchain data.",
      15000
    );
  };

  async function connectToBnbChain() {
    const { provider, signer } = await switchToBnbChain();

    if (provider && signer) {
      return { provider, signer };
    }
  }

  async function connectToKingdomChain() {
    try {
      const { provider, signer } = await switchToKingdomChain();

      if (provider && signer) {
        return { provider, signer };
      }
    } catch (e) {}
  }

  const cid = async () => {
    const chainId = await getChainId();
    setChainId(parseInt(chainId));
    return parseInt(chainId);
  };

  const nextWithdrawal = () => {
    // Each block in BNB chain takes 3 seconds; Withdrawals can be made 3 days after the last claim block
    // Let's find out how long it will take to reach the next withdrawal date
    // First in blocks, then in a human-readable format

    console.log(`Current BNB block: ${currentBnbBlock} | Last claim block: ${lastClaimBlock}`);

    const blocksToWait =
      86400 - (BigNumber(currentBnbBlock).toNumber() - BigNumber(lastClaimBlock).toNumber()); // 3 days minus the blocks passed since the last claim
    const secondsToWait = blocksToWait * 3;

    const date = new Date();
    date.setSeconds(date.getSeconds() + secondsToWait);

    // now let's add one minute to the date:
    date.setMinutes(date.getMinutes() + 1);

    // now replace the month with the month name:
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();

    // add a leading zero if the minutes are less than 10:
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    const formattedDate = `${month} ${day}, ${year} at ${hours}:${minutes}`;

    return formattedDate;
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">👜</h1>
                    <h1 className="heading text-center">Exit Bridge</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      Securely move your assets from the Kingdom Chain to BNB Chain
                    </h5>
                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      You can bridge from $5 to $10,000 USDC to BNB Chain in each transaction.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      The process is simple and secure. Just enter the amount of USDC you want to
                      bridge, and click the "Send to BNB Chain" button.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      First, you bridge USDC. Then, you withdraw USDC on BNB Chain.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      When bridging USDC to BNB Chain, your Metamask is connected to the Kingdom
                      Chain. Conversely, when withdrawing USDC on BNB Chain, your Metamask is
                      connected to the BNB Chain.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      The Exit Bridge has a flat fee of $2 per use.
                    </h5>
                    <h5 className="sub-title help-center ">
                      This fee is deducted from the amount of USDC you send and is used to cover the
                      costs of the bridge.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            {chainId === 39916801 && (
              <h5
                className="sub-title help-center small"
                style={{ marginTop: "-40px", color: "yellow", fontWeight: "bold" }}
              >
                Bridge some USDC or{" "}
                <Link
                  to="#"
                  className="btn-selector nolink"
                  style={{ textDecorationLine: "underline" }}
                  onClick={connectToBnbChain}
                >
                  switch to BNB Chain
                </Link>{" "}
                to withdraw USDC there.
              </h5>
            )}
            {chainId === 56 && (
              <h5
                className="sub-title help-center small"
                style={{ marginTop: "-40px", color: "yellow", fontWeight: "bold" }}
              >
                Withdraw USDC on BNB Chain or{" "}
                <Link
                  to="#"
                  className="btn-selector nolink"
                  style={{ textDecorationLine: "underline" }}
                  onClick={connectToKingdomChain}
                >
                  switch to the Kingdom Chain
                </Link>{" "}
                to bridge USDC.
              </h5>
            )}
            {chainId !== 39916801 && chainId !== 56 && (
              <h5 className="sub-title help-center" style={{ color: "yellow", marginTop: "-40px" }}>
                Connect your wallet to proceed.
              </h5>
            )}
            <div className="themesflat-container">
              <div className="row">
                <BoxesWrapper>
                  <TradingBox>
                    <TradingLabel htmlFor="stabletokenInputBuy">
                      Send USDC from Kingdom Chain
                    </TradingLabel>
                    <TradingInput
                      type="text"
                      id="stabletokenInputBuy"
                      value={formattedStabletokenAmount}
                      onChange={handleStabletokenAmountChange}
                      onFocus={(event) => event.target.select()}
                    />
                    <TradingLabel htmlFor="koziInputBuy">Get USDC on BNB Chain</TradingLabel>
                    <TradingInput
                      type="text"
                      id="koziInputBuy"
                      value={formattedBnbUsdcAmount}
                      onChange={handleBnbUsdcAmountChange}
                      onFocus={(event) => event.target.select()}
                    />
                    <TradingButton
                      onClick={handleSendUsdcClick}
                      disabled={
                        !chainId || chainId === 56 || formattedStabletokenAmount === "Infinity"
                      }
                    >
                      Send to BNB Chain
                    </TradingButton>
                  </TradingBox>
                  <TradingBox>
                    <TradingLabel htmlFor="stabletokenInputBuy">
                      Withdraw USDC on BNB Chain
                    </TradingLabel>
                    <DescriptionText>
                      For security reasons, all exit claims are subject to a 3-day grace period.
                      This means you will be able to withdraw your USDC on BNB Chain 72 hours after
                      your exit claim is processed.
                    </DescriptionText>
                    {bnbChainBalance.gt(0) ? (
                      <BalanceText>
                        You currently have {bnbChainBalance.div(1e18).toFixed(2)} USDC on the
                        Bridge. Your withdrawal will be claimable on {nextWithdrawal()}.
                      </BalanceText>
                    ) : (
                      <BalanceText>
                        You have no pending withdrawal claims at the moment. To withdraw USDC on BNB
                        Chain, you should first send some USDC.
                      </BalanceText>
                    )}

                    <TradingButton
                      onClick={handleWithdrawClick}
                      disabled={!chainId || chainId === 39916801}
                    >
                      Withdraw on BNB Chain
                    </TradingButton>
                  </TradingBox>
                </BoxesWrapper>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default ExitBridge;
