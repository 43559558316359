import styled from "styled-components";
import { ProgressBar } from "react-bootstrap";

const ProgressContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94%;
  height: ${(props) => (props.scale ? `${3 * props.scale}px` : `3px`)};
  top: 3.5px;

  @media (max-width: 450px) {
    top: 3px;
  }
`;

const CustomProgressBar = styled(ProgressBar)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background-color: transparent;

  .progress-bar {
    background: linear-gradient(-90deg, #02bb60, #028632);
    border-radius: 6px;
    height: 95%;
    transition: width 3s ease-in-out;
  }
`;

const CardProgressBar = ({ progress, scale }) => {
  return (
    progress > 0 && (
      <ProgressContainer scale={scale}>
        <CustomProgressBar now={progress} scale={scale} />
      </ProgressContainer>
    )
  );
};

export default CardProgressBar;
