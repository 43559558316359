export function currentBlock(initialBlockNumber, initialTimestamp) {
  const currentTimestamp = Date.now(); // in ms

  // each block takes 10 seconds to mine
  const currentBlockNumber = Math.floor(
    initialBlockNumber + (currentTimestamp - initialTimestamp) / 10000
  );

  return currentBlockNumber;
}

export function elapsedBlocksSinceBlock(targetBlockNumber, initialBlockNumber, initialTimestamp) {
  const currentBlockNumber = currentBlock(initialBlockNumber, initialTimestamp);
  return currentBlockNumber - targetBlockNumber;
}

export function elapsedSecondsSinceBlock(targetBlockNumber, initialBlockNumber, initialTimestamp) {
  const elapsedBlocks = elapsedBlocksSinceBlock(
    targetBlockNumber,
    initialBlockNumber,
    initialTimestamp
  );
  return blocksToSeconds(elapsedBlocks);
}

// only works for blocks that have already been mined
export function blockToTimestamp(targetBlockNumber, initialBlockNumber, initialTimestamp) {
  const elapsedSeconds = elapsedSecondsSinceBlock(
    targetBlockNumber,
    initialBlockNumber,
    initialTimestamp
  );
  return initialTimestamp - elapsedSeconds * 1000;
}

////////////////////////
// Conversions
export function blocksToSeconds(blocks) {
  return blocks * 10;
}

export function blocksToMinutes(blocks) {
  return blocks / 6;
}

export function blocksToHours(blocks) {
  return blocks / 360;
}

export function blocksToDays(blocks) {
  return blocks / 8640;
}

export function secondsToBlocks(seconds) {
  return seconds / 10;
}

export function minutesToBlocks(minutes) {
  return minutes * 6;
}

export function hoursToBlocks(hours) {
  return hours * 360;
}

export function daysToBlocks(days) {
  return days * 8640;
}
