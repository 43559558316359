import bnb from "./bnb";
import hom from "./hom";
import prod from "./prod";

const config = {
  bnb,
  hom,
  prod,
};

export default config;
