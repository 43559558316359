import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import SuperCheese from "../../assets/images/squads-card/resource-00.png";
import Fish from "../../assets/images/squads-card/resource-01.png";
import Grass from "../../assets/images/squads-card/resource-02.png";
import Root from "../../assets/images/squads-card/resource-03.png";
import Banana from "../../assets/images/squads-card/resource-04.png";
import ExoticCheese from "../../assets/images/squads-card/resource-05.png";
import Milk from "../../assets/images/squads-card/resource-06.png";
import Mushrooms from "../../assets/images/squads-card/resource-07.png";
import Mango from "../../assets/images/squads-card/resource-08.png";
import Tool from "../../assets/images/squads-card/resource-09.png";
import Nuts from "../../assets/images/squads-card/resource-10.png";
import Therapy from "../../assets/images/squads-card/resource-11.png";
import MouseBox from "../../assets/images/squads-card/resource-12.png";
import CatBox from "../../assets/images/squads-card/resource-13.png";
import MouseDna from "../../assets/images/squads-card/resource-14.png";
import CatDna from "../../assets/images/squads-card/resource-15.png";
import CowDna from "../../assets/images/squads-card/resource-16.png";
import ElephantDna from "../../assets/images/squads-card/resource-17.png";
import ApeDna from "../../assets/images/squads-card/resource-18.png";
import CowBox from "../../assets/images/squads-card/resource-19.png";
import ElephantBox from "../../assets/images/squads-card/resource-20.png";

import SkillNumber from "./SkillNumber";

export const ImagesContainer = styled.div`
  position: relative;
  width: 10%;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.skillsLength > 2
      ? `top: ${props.scale ? (props.scale <= 0.5 ? "18%" : "17%") : "17%"};`
      : `top: ${props.scale ? (props.scale <= 0.5 ? "51%" : "50%") : "50%"};`}

  ${(props) =>
    props.skillsLength > 2
      ? `left: ${
          props.scale ? (props.scale <= 0.5 ? "0px" : `${4 * props.scale * props.scale}px`) : "4px"
        };`
      : `left: ${
          props.scale ? (props.scale <= 0.5 ? "0px" : `${4 * props.scale * props.scale}px`) : "4px"
        };`}	

  @media (max-width: 450px) {
    ${(props) =>
      !props.lockSize
        ? props.skillsLength > 2
          ? `
      top: ${props.scale ? `${5.5 * props.scale}px` : "21.5px"};
    `
          : `top: ${props.scale ? `${21.5 * props.scale}px` : "21.5px"};`
        : null}
  }

  .skillOne {
    position: relative;
    bottom: ${(props) =>
      props.scale ? (props.species > 10 ? `${4 * props.scale}px` : `${3 * props.scale}px`) : "3px"};
    left: ${(props) => (props.scale ? `${0.5 * props.scale}px` : "0.5px")};
    margin-bottom: ${(props) => (props.scale ? `${0.5 * props.scale}px` : "0.5px")};

    @media (max-width: 450px) {
      ${(props) =>
        !props.lockSize
          ? props.skillsLength > 2
            ? `
        padding-top: ${props.scale ? `${3 * props.scale}px` : "3px"};
        margin-bottom: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
        left: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
      `
            : `
      padding-top: ${props.scale ? `${3 * props.scale}px` : "3px"};
      margin-bottom: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
      left: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
    `
          : null}
    }
  }

  .skillTwo {
    position: relative;
    left: ${(props) => (props.scale ? `${1 * props.scale}px` : "1px")};

    ${(props) =>
      props.skillsLength > 2
        ? `top: ${props.scale ? (props.scale <= 0.5 ? "-1px" : "-1px") : "-1px"};`
        : `top: ${props.scale ? (props.scale <= 0.5 ? "0" : "0") : "0"};`}

    @media (max-width: 450px) {
      ${(props) =>
        !props.lockSize
          ? props.skillsLength > 2
            ? `
        left: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
        top: -0.5px;
      `
            : `
      left: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
      padding-top: 0;
    `
          : null}
    }
  }

  .skillThree {
    position: relative;
    bottom: ${(props) => (props.scale ? `${-1.5 * props.scale}px` : "-1.5px")};
    left: ${(props) => (props.scale ? `${0.5 * props.scale}px` : "0.5px")};
    margin-bottom: ${(props) => (props.scale ? `${0.5 * props.scale}px` : "0.5px")};

    @media (max-width: 450px) {
      ${(props) =>
        !props.lockSize
          ? props.skillsLength > 2
            ? `
        padding-top: ${props.scale ? `${0 * props.scale}px` : "0px"};
        margin-bottom: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
        left: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
      `
            : `
      padding-top: ${props.scale ? `${3 * props.scale}px` : "3px"};
      margin-bottom: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
      left: ${props.scale ? `${-0.5 * props.scale}px` : "-0.5px"};
    `
          : null}
    }
  }
`;

export const SkillNumbersContainer = styled.div`
  position: relative;
  width: 10%;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.skillsLength > 2
      ? `top: ${
          props.scale
            ? props.scale <= 0.5
              ? `${10 * props.scale}px`
              : `${10 * props.scale}px`
            : "10%"
        };`
      : `top: ${
          props.scale
            ? props.scale <= 0.5
              ? `${32 * props.scale}px`
              : `${31 * props.scale}px`
            : "31%"
        };`}

  left: ${(props) =>
    props.scale
      ? props.scale <= 0.5
        ? `${10 * props.scale * props.scale - 1}px`
        : `${6 * props.scale * props.scale}px`
      : "6px"};

  height: fit-content;
  text-align: center;

  @media (max-width: 450px) {
    ${(props) =>
      !props.lockSize &&
      `
      top: ${
        props.skillsLength > 2
          ? props.scale
            ? props.scale <= 0.5
              ? `${7 * props.scale}px`
              : `${7 * props.scale}px`
            : "7px"
          : props.scale
          ? props.scale <= 0.5
            ? `${25 * props.scale}px`
            : `${24 * props.scale}px`
          : "24px"
      };
      left: ${
        props.scale
          ? props.scale <= 0.5
            ? `${4 * props.scale * props.scale}px`
            : `${5.5 * props.scale * props.scale}px`
          : "5.5px"
      };
    `}
  }
`;

const getSkillImages = (type) => {
  switch (type) {
    case 1:
      return [SuperCheese, ExoticCheese];
    case 2:
      return [Fish, Milk];
    case 3:
      return [Grass, Mushrooms];
    case 4:
      return [Root, Mango];
    case 5:
      return [Banana, Tool];
    case 6:
      return [ExoticCheese, Nuts, Milk];
    case 7:
      return [Milk, Therapy, Mushrooms];
    case 8:
      return [Mushrooms, MouseBox, Mango];
    case 9:
      return [Mango, CatBox, Tool];
    case 10:
      return [Tool, MouseDna, Nuts];
    case 11:
      return [Nuts, CatDna, Therapy];
    case 12:
      return [Therapy, CowDna, MouseBox];
    case 13:
      return [MouseBox, ElephantDna, CatBox];
    case 14:
      return [CatBox, ApeDna, MouseDna];
    case 15:
      return [MouseDna, CowBox, CatDna];
    default:
      return ["Unknown", "Unknown"];
  }
};

const CardSkillsLeft = ({ nft, scale, lockSize }) => {
  const skills = getSkillImages(nft?.type_);
  if (!skills || !nft.skills || !nft.rarity) return null;

  return (
    <>
      <ImagesContainer
        scale={scale}
        lockSize={lockSize}
        skillsLength={skills?.length}
        species={nft.species}
      >
        <img className="skillOne" src={skills[0]} />
        <img className="skillTwo" src={skills?.length > 2 ? skills[2] : skills[1]} />
        {skills?.length > 2 && <img className="skillThree" src={skills[1]} />}
      </ImagesContainer>
      <SkillNumbersContainer scale={scale} lockSize={lockSize} skillsLength={skills?.length}>
        <SkillNumber
          scale={scale}
          lockSize={lockSize}
          smallfont={nft?.rarity * nft?.skills[0] > 99}
        >
          {nft?.rarity * nft?.skills[0]}
        </SkillNumber>
        <SkillNumber
          scale={scale}
          lockSize={lockSize}
          isSecondSkillNumber={true}
          smallfont={
            skills?.length > 2
              ? nft?.rarity * nft?.skills[4] > 99
              : nft?.rarity * nft?.skills[1] > 99
          }
        >
          {skills?.length > 2 ? nft?.rarity * nft?.skills[4] : nft?.rarity * nft?.skills[1]}
        </SkillNumber>
        {skills?.length > 2 && (
          <SkillNumber
            scale={scale}
            lockSize={lockSize}
            isThirdSkillNumber={true}
            smallfont={nft?.rarity * nft?.skills[1] > 99}
          >
            {nft?.rarity * nft?.skills[1]}
          </SkillNumber>
        )}
      </SkillNumbersContainer>
    </>
  );
};

export default CardSkillsLeft;
