import { zeroAddress } from "../../../utils/address";

export const DUMMY_RESOURCE_LIST = [
  {
    id: 100000000001,
    createdAt: 0,
    assetId: 0, // SC
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000002,
    createdAt: 0,
    assetId: 1, // Fish
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000003,
    createdAt: 0,
    assetId: 2, // Grass
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000004,
    createdAt: 0,
    assetId: 3, // Roots
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000005,
    createdAt: 0,
    assetId: 4, // Bananas
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000006,
    createdAt: 0,
    assetId: 5, // Exotic Cheese
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000007,
    createdAt: 0,
    assetId: 6, // Cat Milk
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000008,
    createdAt: 0,
    assetId: 7, // Mushrooms
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000009,
    createdAt: 0,
    assetId: 8, // Mango
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000010,
    createdAt: 0,
    assetId: 9, // Tools
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },

  {
    id: 100000000011,
    createdAt: 0,
    assetId: 11, // Gene Therapy
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000012,
    createdAt: 0,
    assetId: 12, // Mouse Box
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000013,
    createdAt: 0,
    assetId: 13, // Cat Box
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000014,
    createdAt: 0,
    assetId: 19, // CowBox
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000015,
    createdAt: 0,
    assetId: 20, // EleBox
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000016,
    createdAt: 0,
    assetId: 14, // Mouse Dna
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000017,
    createdAt: 0,
    assetId: 15, // CatDna
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000018,
    createdAt: 0,
    assetId: 16, // CowDna
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000019,
    createdAt: 0,
    assetId: 17, // ElephantDna
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000020,
    createdAt: 0,
    assetId: 18, // ApeDna
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000021,
    createdAt: 0,
    assetId: 10, // Nuts
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000022,
    createdAt: 0,
    assetId: 21, // Dragon Scales
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000023,
    createdAt: 0,
    assetId: 22, // Griffin Feathers
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000024,
    createdAt: 0,
    assetId: 23, // Satyr Horns
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000025,
    createdAt: 0,
    assetId: 24, // Dinosaur Skulls
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
  {
    id: 100000000026,
    createdAt: 0,
    assetId: 25, // Ai Chips
    quantity: 0,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
    userBalance: 0,
  },
];

export const DUMMY_BEAST_ORDER = [
  {
    id: 200000000001,
    createdAt: 0,
    assetId: 39916801,
    quantity: 1,
    seller: "You",
    privateFor: zeroAddress,
    priceInWei: "0",
    isNft: true,
    allowEbct: true,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
    isPreview: true,
  },
];
