import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: auto;
  border-radius: 10px;
  flex-wrap: wrap;
  margin-top: 40px;
`;

export const MintContainer = styled.div`
  display: flex;
  justify-content: center;
  //max-width: 1000px;
  flex-wrap: wrap;

  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const BalanceGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const PlayerNotFound = styled.div`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;

  // centralize it inside the container:
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // make it fill the container:
  width: 330px;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

export const Subtitle = styled.h1`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #ddd;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;
