import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  margin: 2px;
  border-radius: 6px;
  background: ${(props) =>
    props.isSuccess
      ? "linear-gradient(0deg, #040, #0e0, #040)"
      : "linear-gradient(0deg, #04040f, #14141f, #04040f)"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 400px;
  opacity: ${(props) =>
    props.isSuccess ? "1" : props.isRolling ? "0.4" : props.hasFinalResult ? "0.2" : "1"};

  outline: ${(props) =>
    props.isSuccess ? "3px solid #0a0" : props.isRolling ? "1px solid #f00" : "1px solid #ccc"};

  outline-offset: ${(props) => (props.isSuccess ? "-2px" : "0")};

  transition: all 0.3s;
`;

export const TraitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  width: ${(props) => (props.isSuccess ? "60px" : props.isRolling ? "36px" : "60px")};

  transition: width 0.3s;
`;

export const Image = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  position: relative;
  top: 2px;
`;

export const Name = styled.div`
  font-size: 20px;
  color: ${(props) => (props.isSuccess ? "#14141f" : "#fff")};
  padding: 10px;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  width: 100px;
  text-align: center;
`;

export const Chance = styled.div`
  font-size: ${(props) => (props.isRolling ? "14px" : "24px")};
  color: ${(props) =>
    props.isSuccess
      ? "#14141f"
      : props.chance === 1
      ? "#fa0"
      : props.chance < 22
      ? "#Fd0"
      : props.chance < 64
      ? "#0ff"
      : "#0f1"};
  font-family: "Metropolis", sans-serif;
  font-weight: bold;
  padding: 10px;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333;
  width: 56px;

  margin-right: ${(props) => (props.hasFinalResult ? "20px" : "0")};
`;

export const ResultNumber = styled.div`
  font-size: ${(props) => (props.is100 ? "14px" : "16px")};
  color: ${(props) => (props.isSuccess ? "#060" : "#f00")};
  padding: 10px;
  font-family: "Metropolis", sans-serif;
  font-weight: bold;
  text-shadow: -0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333;

  width: 40px;
  text-align: center;

  background: ${(props) => (props.isSuccess ? "#eee" : "#bbb")};
  border-radius: 4px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const TicketsButton = styled.div`
  font-size: 12px;
  color: #fff;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.isOn ? "#0a3" : "#113")};
  outline: ${(props) => (props.isOn ? "1px solid green" : "1px solid white")};
  border-radius: 4px;
  padding: 5px;
  margin: ${(props) => (props.isFirst ? "5px 5px 2px 5px" : "2px 5px 5px 5px")};
  user-select: none;
  min-width: 40px;
  text-align: center;
  font-weight: bold;

  ${(props) =>
    !props.disabled
      ? `
  &:hover {
    opacity: 1;
    outline: ${(props) => (props.isOn ? "1px solid white" : "1px solid yellow")};
  }

  &:active {
    background-color: #fff;
  }`
      : ""}
`;
