import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: ${(props) => props.background};
  cursor: default;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  background: ${(props) => props.background};

  img {
    width: 100%;
    object-fit: contain;
    left: 1px;
    position: relative;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  top: 1px;
  left: 6px;
  position: relative;
  cursor: default;
`;

export const WholePart = styled.span`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  font-size: 15px;
`;

export const DecimalPart = styled.span`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  font-size: 11px;
  color: #ccc;
  opacity: 0.9;
  top: 1.5px;
  left: 1px;
  position: relative;
`;
