const bnb = {
  name: "BNB MAINNET",
  chainId: 56,
  validators: ["0x5bd09121BA4c26a37460890f63Bf9388e0Aa9359"],
  operator: "0x0054F092B885903B666cBbef09600c7C831EaDa2",
  treasury: "0x357AA742b178AD0a06900C99Ee80aDf634B73406",
  Forge: {
    address: "0xD714cAb8b401e317076373f59424700f6aF28AD8",
  },
  HighUpgrades: {
    address: "0xAe012e945CB1034053C1d78070048bF4e54FbB9F",
  },
  Vault: {
    address: "0x16BfdB5311283289e27Dcad8BF311ecF9E8FE12F",
  },
  Marketplace: {
    address: "0xe069ec8EEE4D6cCe59330535Fc42C9d508D3911d",
  },
  BoosterCracker: {
    address: "0xe518e8922F29486f943e5Ba7F840afe32142d4F2",
  },
  TraitReroller: {
    address: "0xF42b0c5e24cBF9f858bE715851a6Ad580d8812FA",
  },
  QuestManager: {
    address: "0x4d5fa3Cf1a3E31c9c4D96576AF780b4686EF2902",
  },
  Fetch: {
    address: "0xF6FAf9c6D0913655c6BB0AAF386CBE1216c9655B",
  },
  DnaManager: {
    address: "0x676E7c3eBe091D80fc7f27EF42169A3FDF374519",
  },
  Farm: {
    address: "0x28663781FbD1eB6AC805B96c4cBA91C4978f874b",
  },
  QuestRewardsCalculator: {
    address: "0xDc9e705d3774388723e5f7B786b72890abdcCf10",
  },
  Upgrades: {
    address: "0x03dDFf7A5Fc6F3648f4e213ebA28862f0F39edC3",
  },
  PlayerBonus: {
    address: "0xC2a8B4BC6E6d9c56343A02e54aFF4BD004022F5e",
  },
  CommunityPool: {
    address: "0x84CacCDac3416DF22224f8A0f4fB601fdA263Bb6",
  },
  Stasher: {
    address: "0xa63476588Bd9372bE466F97C6D5EcbF629a73caA",
  },
  BkNft: {
    address: "0x2f1f39EFACFce12BA05CAa43bA6c826b42ed697f",
  },
  Stabletoken: {
    name: "USD Coin",
    symbol: "USDC",
    decimals: 18,
    address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  },
  BCT: {
    name: "Beast Community Token",
    symbol: "BCT",
    decimals: 18,
    address: "0x6c93500E8BEf8C806cF5228A352F8C0Af439370f",
  },
};

export default bnb;
