import { shortHash } from "../utils/tx";
import Contracts from "../utils/Contracts.js";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
/*
This is a block (inside `result`) as returned by the RPC endpoint:
{
	"jsonrpc": "2.0",
	"id": 1,
	"result": {
		"number": "0x4b34",
		"hash": "0x568304e6449662909283f446ddb6e8130c3aeae4a4b465b0bb6399fd209e9776",
		"mixHash": "0x63746963616c2062797a616e74696e65206661756c7420746f6c6572616e6365",
		"parentHash": "0x98b7114186553db039f25ae16dd2ae9e79d676f2a61146dae16cfe97a4067b50",
		"nonce": "0x0000000000000000",
		"sha3Uncles": "0x1dcc4de8dec75d7aab85b567b6ccd41ad312451b948a7413f0a142fd40d49347",
		"logsBloom": "0x00000000000000000000000000000000000000000000000100000000000000000000000000000000000000000040000000000000000000000000000000000000000000000000000000000008000000000080000000000000000000000000000000000000000000000000001000200000000000000000000000000010000000000000000000000000040000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000002001000000000200000000000000000000000000000000000000000000000000000000000000008000000000000000000000000000000000000000000",
		"transactionsRoot": "0x5ebc4f9c1fa88f69856d6f9c69aa902cc6cc34df262d56cc7b27ac3cfa042651",
		"stateRoot": "0xe617b4d116a17efe67f248cd4d0c67c7e47c88e047b19ea2b6070697107cab12",
		"receiptsRoot": "0x4d64b487ce417c22fcec0873487ee1cbe370066aac22705880c3382f1de89301",
		"miner": "0xde2316f1d78acd7d6fc502905886accec13b69f2",
		"difficulty": "0x1",
		"totalDifficulty": "0x4b35",
		"extraData": "0xf90144a00000000000000000000000000000000000000000000000000000000000000000f8549434074f5d25d5c5e9f3b1486eb134646744dfc15e9474b0dce94bd15e82ece67dd3da1e7992f190a8b894a6bc43b7281c1aa814eaf5b5c352311d227a2d3794de2316f1d78acd7d6fc502905886accec13b69f2c080f8c9b841e561b3e9d97ab8112e3f60265252db55f89c2c80aa0e2b568702239e98651115057f95169027c3b9f6377e6795b36cbd3a845b96c9e56dd003faba70534a1f3601b841f6e025f8ef0f7465d3219afbd706a1ae3f3193a3cea6085fda648c992c0ca69e7283957cff9fab5c88dad0c459740bc5af7b1db9c92c62e436b8a959b850292600b84119a2da13f94fa3ae787d369d9e6aa366d60bb2393066d0030a9d48d3e49e9b5d1b97abe8b191c999afe6c3b7283d8c057f99bde890b5f44fb6e62b33b494121a00",
		"baseFeePerGas": "0x0",
		"size": "0x489",
		"gasLimit": "0x8583b00",
		"gasUsed": "0x27d74",
		"timestamp": "0x659be06a",
		"uncles": [],
		"transactions": [
			{
				"accessList": [],
				"blockHash": "0x568304e6449662909283f446ddb6e8130c3aeae4a4b465b0bb6399fd209e9776",
				"blockNumber": "0x4b34",
				"chainId": "0x2611501",
				"from": "0x6f165b30ee4bfc9565e977ae252e4110624ab147",
				"gas": "0x2995f",
				"gasPrice": "0x3b9aca00",
				"maxPriorityFeePerGas": "0x3b9aca00",
				"maxFeePerGas": "0x3b9aca00",
				"hash": "0xc7ce3b7f6be20dae4f45391ca07faec99b2099c7355ddfc379ec647d224327f3",
				"input": "0x74c4dfc2000000000000000000000000000000000000000000000000000000000000008000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
				"nonce": "0x25",
				"to": "0x67840c5d545eb16108b2e38e4741698be162c72e",
				"transactionIndex": "0x0",
				"type": "0x2",
				"value": "0x0",
				"yParity": "0x1",
				"v": "0x1",
				"r": "0x4d7b52dd66adc8153a9409235fca9767db4be39dcb22a14c39b8ffbf2e935827",
				"s": "0x15723457284d36a77c96b29612f92262f79a6bcc1b72fca58c037687b7ebade6"
			}
		]
	}
}
*/

class BlockModel {
  constructor(blockData) {
    if (blockData) {
      this.setBlockData(blockData);
    }
  }

  setBlockData(blockData) {
    this.number = blockData?.number;
    this.hash = blockData?.hash;
    this.mixHash = blockData?.mixHash;
    this.parentHash = blockData?.parentHash;
    this.nonce = blockData?.nonce;
    this.sha3Uncles = blockData?.sha3Uncles;
    this.logsBloom = blockData?.logsBloom;
    this.transactionsRoot = blockData?.transactionsRoot;
    this.stateRoot = blockData?.stateRoot;
    this.receiptsRoot = blockData?.receiptsRoot;
    this.miner = blockData?.miner;
    this.difficulty = blockData?.difficulty;
    this.totalDifficulty = blockData?.totalDifficulty;
    this.extraData = blockData?.extraData;
    this.baseFeePerGas = blockData?.baseFeePerGas;
    this.size = blockData?.size;
    this.gasLimit = blockData?.gasLimit;
    this.gasUsed = blockData?.gasUsed;
    this.timestamp = blockData?.timestamp;
    this.uncles = blockData?.uncles;
    this.transactions = blockData?.transactions;
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }

  shortHash(initialDigits = 5, finalDigits = 4) {
    return shortHash(this.hash, initialDigits, finalDigits);
  }

  transactionCount() {
    return this.transactions.length;
  }

  txSummary() {
    // we're interested in an array that looks like this:
    /*
    [
      {
        hash: "0x...",
        from: "0x...",
        to: "0x...",
      },
    ]
    */

    return this.transactions.map((tx) => {
      return {
        hash: tx.hash,
        from: tx.from,
        to: tx.to || "Contract Deployment",
        action: this.parseAction(tx),
      };
    });
  }

  parseAction(txReceipt) {
    if (!txReceipt.to) return "Contract Deployment";

    // action is the name of the called function
    const abi = Contracts.addressToAbi(ethers.getAddress(txReceipt.to));

    if (!abi) {
      if (BigNumber(txReceipt.value).toFixed() !== "0") return "KOZI Transfer";

      if (txReceipt.input.startsWith("0x12257c90")) {
        return "Kingdom Reward";
      }

      return "Unknown";
    }

    const contract = new ethers.Contract(txReceipt.to, abi);
    const parsedFunction = contract.interface.parseTransaction({ data: txReceipt.input });

    const action = parsedFunction.name.replace(/([A-Z])/g, " $1").trim();
    const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1);
    return capitalizedAction;
  }
}

export default BlockModel;
