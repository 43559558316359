import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Messenger from "../utils/Messenger";
import Header from "../components/header/Header";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";

import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useUpgradesContext } from "../contexts/UpgradesContext";

import DepositButton from "../components/deposit-button/DepositButton";
import { stasherLevelByBalance } from "../utils/constants";
import { bonusToBackground } from "../utils/constants";
import { indexToBackground } from "../utils/constants";

import BalanceContainer from "../components/balance-container";

// let's create the subtitles and their description componenents
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const WhiteHeading = styled.h5`
  color: #ddd;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 20px;
  max-width: 600px;
`;

const WhiteDescription = styled.p`
  color: #ddd;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  max-width: 600px;
  text-align: center;
  line-height: 1.3;
`;

const BalanceGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const BalanceGroup = styled.div`
  display: flex;
  align-items: stretch;
  width: 350px;
  height: auto;
  overflow: hidden;
  padding: 10px;
  gap: 2px;

  outline: 3px solid #000;
  border-radius: 6px;

  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.7) 20%,
      rgba(0, 0, 0, 0.7) 80%,
      rgba(0, 0, 0, 0.87)
    ),
    linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),
    linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),
    linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;

  margin-right: 20px;
  margin-left: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));

  @media (max-width: 450px) {
    width: 280px;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

const Subtitle = styled.h1`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #ddd;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const BALANCE_ORDER = [
  0, 5, 1, 6, 2, 7, 3, 8, 4, 9, 11, 14, 12, 15, 13, 16, 19, 17, 20, 18, 10, 21, 22, 23, 24, 25,
];

const initialBonusValuesList = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
];

// transform the list into an object
const initialBonusValues = initialBonusValuesList.reduce((acc, curr, index) => {
  acc[index] = curr;
  return acc;
}, {});

const Deposit = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE, consult } = useWeb3Context();
  const { player } = usePlayerContext();
  const { depositBct } = useUpgradesContext();

  const [inputValue, setInputValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentBonus, setCurrentBonus] = useState(0);
  const [ebctBonus, setEbctBonus] = useState(0);
  const [resourceBonus, setResourceBonus] = useState(initialBonusValues);

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }
  }, [player]);

  const exec = async (event) => {
    event.preventDefault(); // prevent the default form submission
    setIsLoading(true);
    if (
      inputValue === "" ||
      inputValue.indexOf(".") !== -1 ||
      inputValue.indexOf("-") !== -1 ||
      inputValue.indexOf("+") !== -1 ||
      inputValue.indexOf("e") !== -1
    ) {
      console.log(`The number ${inputValue} is not valid.`);

      toast.error(`The number ${inputValue} is not valid.`, {
        type: toast.TYPE.WARNING,
        autoClose: 5000,
      });

      setIsLoading(false);
      return;
    }

    await depositBct(BigNumber(inputValue).times(1e18).toString());
    setIsLoading(false);
  };

  const fetchCurrentBonus = async () => {
    // let use the consult function to get the current bonus
    // we need to find the mentor level of the player's Mentor;
    // the mentor is in player.info.mentor; the mentor's mentor level is in player.info.mentorLevel of the mentor!
    if (!player) return;

    const baseBonus = 50;

    const stasherBonus =
      stasherLevelByBalance(parseFloat(BigNumber(player.stashed).div(1e18).toString())) * 25;

    const currentBonus = baseBonus + stasherBonus;

    setCurrentBonus(currentBonus);
  };

  useEffect(() => {
    fetchCurrentBonus();
  }, [player]);

  // When the user changes the amount informed in the input, we need to update the bonus values
  const handleBctAmountChange = (event) => {
    const re = /^[0-9\b]+$/; // RegEx pattern for numbers and backspace
    if (event.target.value === "" || re.test(event.target.value)) {
      setInputValue(event.target.value);

      const bctToDeposit = event.target.value;

      const { ebct, resources } = getFullBonus(bctToDeposit);

      setEbctBonus(ebct);
      setResourceBonus(resources);
    }
  };

  const getFullBonus = (bctToDeposit) => {
    const _ebct = BigNumber(bctToDeposit * ((100 + currentBonus) / 100)).times(1e18);
    const _resources = initialBonusValuesList;

    // for each 100 units of BCT deposited, we give 25 cents of resources
    const _resourceBonus = BigNumber(bctToDeposit)
      .times(1e18)
      .times(25)
      .div(10000)
      .div(1e18)
      .dp(2, BigNumber.ROUND_DOWN);

    for (let i = 0; i < 16; i++) {
      if (parseFloat(_resourceBonus) > 0) _resources[i] = _resourceBonus;
      else _resources[i] = 0;
    }

    // for each 1000 units of BCT deposited, we give 1 Cow Box, 4 Griffin Feathers, 2 Satyr Horns, 1 Dinosaur Skull
    const oneUnitPer1k = BigNumber(bctToDeposit)
      .times(1e18)
      .div(100000)
      .times(100)
      .div(1e18)
      .dp(2, BigNumber.ROUND_DOWN);

    if (bctToDeposit >= 1000) {
      _resources[19] = oneUnitPer1k; // Cow Box
      _resources[22] = oneUnitPer1k.times(4); // Griffin Feathers
      _resources[23] = oneUnitPer1k.times(2); // Satyr Horns
      _resources[24] = oneUnitPer1k; // Dinosaur Skull
    } else {
      _resources[19] = 0; // Cow Box
      _resources[22] = 0; // Griffin Feathers
      _resources[23] = 0; // Satyr Horns
      _resources[24] = 0; // Dinosaur Skull
    }

    // for each 5000 units of BCT deposited, we give 1 Elephant Box, 3 AI Chips
    const oneUnitPer5k = BigNumber(bctToDeposit)
      .times(1e18)
      .div(500000)
      .times(100)
      .div(1e18)
      .dp(2, BigNumber.ROUND_DOWN);

    if (bctToDeposit >= 5000) {
      _resources[20] = oneUnitPer5k; // Elephant Box
      _resources[25] = oneUnitPer5k.times(5); // AI Chips
    } else {
      _resources[20] = 0; // Elephant Box
      _resources[25] = 0; // AI Chips
    }

    return { ebct: _ebct, resources: _resources };
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12" style={{ marginBottom: "-20px" }}>
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🎁</h1>
                    <h1 className="heading text-center">Deposit</h1>
                  </div>
                  <div>
                    <TextContainer>
                      <WhiteHeading>BCT Conversion & Basic Bonus:</WhiteHeading>
                      <WhiteDescription>
                        🔹 When you transfer BCT from your Metamask into the game, that BCT is
                        consumed and converted into eBCT in your game account.
                      </WhiteDescription>
                      <WhiteDescription>
                        🔹 You receive a base bonus of 50% for each deposit.
                      </WhiteDescription>
                      <WhiteHeading>Stasher Level Bonus:</WhiteHeading>
                      <WhiteDescription>
                        🔹 Depending on your Stasher Level, you can earn an additional bonus of up
                        to 250% (25% per level).
                      </WhiteDescription>

                      <WhiteHeading>Maximum eBCT Bonus:</WhiteHeading>
                      <WhiteDescription>
                        🔹 Combining all bonuses, you can earn a maximum of 300% bonus in eBCT. As
                        of now,{" "}
                        <span style={{ color: "yellow" }}>
                          your current bonus stands at {currentBonus}%.
                        </span>
                      </WhiteDescription>

                      <WhiteHeading>Resource Bonus:</WhiteHeading>
                      <WhiteDescription>
                        🔹 For every 100 BCT you deposit into the game, you are awarded 0.25 of EACH
                        of the following resources: Super Cheese, Shiny Fish, Groovy Grass, Happy
                        Roots, Bang Bananas, Exotic Cheese, Cat Milk, Magic Mushrooms, Power
                        Mangoes, Ape Tools, Golden Nuts, Gene Therapy, Mouse Box, Cat Box, Mouse
                        DNA, and Cat DNA.
                      </WhiteDescription>

                      <WhiteHeading>Special Bonuses for 1000+ deposits:</WhiteHeading>
                      <WhiteDescription>
                        🔹 For every 1000 BCT, you receive 1 Cow Box.
                      </WhiteDescription>
                      <WhiteDescription>
                        🔹 For every 1000 BCT, you receive 4 Griffin Feathers.
                      </WhiteDescription>
                      <WhiteDescription>
                        🔹 For every 1000 BCT, you receive 2 Satyr Horns.
                      </WhiteDescription>
                      <WhiteDescription>
                        🔹 For every 1000 BCT, you receive 1 Dinosaur Skull.
                      </WhiteDescription>

                      <WhiteHeading>Special Bonuses for 5000+ deposits:</WhiteHeading>
                      <WhiteDescription>
                        🔹 For every 5000 BCT, you receive 1 Elephant Box.
                      </WhiteDescription>
                      <WhiteDescription>
                        🔹 For every 5000 BCT, you receive 5 Ai Chips.
                      </WhiteDescription>

                      <WhiteHeading>Cumulative Bonuses Example:</WhiteHeading>
                      <WhiteDescription>
                        🔹 If you were to deposit 5000 BCT, you would get up to 20000 eBCT, plus
                        12.5 of each resource, plus 5 Cow Boxes, plus 1 Elephant Box, plus 20
                        Griffin Feathers, plus 10 Satyr Horns, plus 5 Dinosaur Skulls, plus 5 AI
                        Chips.
                      </WhiteDescription>
                    </TextContainer>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container" style={{ marginBottom: "200px" }}>
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    marginTop: "-20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h5 className="sub-title help-center">
                    You currently hold{" "}
                    {BigNumber(player?.metamaskBalances[0])
                      .div(1e18)
                      .dp(2, BigNumber.ROUND_DOWN)
                      .toString()}{" "}
                    BCT in your Metamask.
                  </h5>
                  <h5 className="sub-title help-center mg-bt-32 ">
                    How much would you like to deposit?
                  </h5>
                  <form
                    action="#"
                    className="help-center-form for-small-button"
                    style={{ marginBottom: "30px" }}
                  >
                    <input
                      type="number"
                      min="0"
                      step="1"
                      value={inputValue}
                      pattern="^[0-9]"
                      id="centralizedInput"
                      onChange={handleBctAmountChange}
                      onFocus={(event) => event.target.select()}
                    />
                    <DepositButton
                      onClick={(event) => exec(event)}
                      className="blue"
                      disabled={isLoading}
                      isLoading={isLoading}
                    />
                  </form>

                  <BalanceGroupContainer>
                    <Title>You will receive</Title>
                    <BalanceGroup>
                      <BalanceContainer
                        key={`ingame-99`}
                        resource={99}
                        quantity={0}
                        background={bonusToBackground["BCT"]}
                        duration={1}
                      />
                      <BalanceContainer
                        key={`ingame-98`}
                        resource={98}
                        quantity={BigNumber(ebctBonus).div(1e18).toFixed(2)}
                        background={bonusToBackground["eBCT"]}
                        duration={1}
                      />
                      {BALANCE_ORDER.map((index) => (
                        <BalanceContainer
                          key={`ingame-${index}`}
                          resource={index}
                          quantity={resourceBonus[index]}
                          background={indexToBackground[index]}
                          duration={1}
                        />
                      ))}
                    </BalanceGroup>
                  </BalanceGroupContainer>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Deposit;
