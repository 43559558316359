/**
 * This is the model for a Squad.
 * To get the correct data, you need to call the FARM contract and get the data from there.
 * The function is farm.getSquad(id) and it returns a SquadData struct, that you pass to the constructor of this class.
 * Note that the Squad DOES NOT know its own global id. You need to pass it to the constructor.
 */
import BigNumber from "bignumber.js";
import { isMaxedOut } from "../config/resources/08-squad-cards";

class SquadModel {
  constructor(squadData, id) {
    this.setData(squadData, id);
  }

  setData(squadData, id) {
    this.id = id;
    this.owner = squadData.owner; // the blockchain address of the owner of this squad
    this.type_ = new BigNumber(squadData.type_).toNumber(); // from 1 to 5: Mouse, Cat, Cow, Elephant, Ape (for pure squads), or 6 for mixed squad.
    this.size = new BigNumber(squadData.size).toNumber(); // from 3 to 6: the number of NFTs slots available in this squad
    this.baseFarmPerBlock = new BigNumber(squadData.baseFarmPerBlock).toString(); // starts at 200, can go very high. Not rendered anywhere by now.
    this.synergyBonus = new BigNumber(squadData.synergyBonus).toNumber(); // from 0 to 1440: the synergy bonus of this squad
    this.squadBonus = new BigNumber(squadData.squadBonus).toNumber(); // from 0 to infinity: the % in bonus farm of MHT and eMHT of this squad.
    this.squadTrait = new BigNumber(squadData.squadTrait).toNumber(); // from 0 to 50: the trait of this squad (squads now can have 1 trait). 0 means no trait.
    this.farmPerBlock = new BigNumber(squadData.farmPerBlock).toString(); // starts at 200, can go very high. Rendered on the header of each squad. It's in GWEI of MHT (1 MHT / 1 billion).
    this.currentQuest = new BigNumber(squadData.currentQuest).toNumber(); // from 0 to infinity: the ID of the current quest of this squad. 0 means no quest.
    this.questStartedAt = new BigNumber(squadData.questStartedAt).toNumber(); // from 0 to infinity: the BLOCK of the start of the current quest.
    this.questEndsAt = new BigNumber(squadData.questEndsAt).toNumber(); // from 0 to infinity: the BLOCK of the end of the current quest. If the current block is below this number, the squad in on a quest. Else, it's idle.
    this.nftIds = squadData.nftIds.map((id) => new BigNumber(id).toNumber()); // a list containing integers from 0 to infinity. Each integer represents the ID of an NFT in this squad. The list can have from 0 to 6 elements.
    this.traits = squadData.traits.map((trait) => new BigNumber(trait).toNumber()); // a list containing integers from 0 to 50. Each integer represents the ID of a trait in this squad. The list can have from 0 to 18 elements (some will be repeated).
    this.collections = squadData.collections.map((collection) =>
      new BigNumber(collection).toNumber()
    ); // a list containing integers from 0 to infinity. Each integer represents the ID of a collection in this squad. The list can have from 0 to 6 elements (some will be repeated).^
    this.squadNumber = 0; // from 1 to 20, the squad within this player's squad roaster
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }

  calculateSpecials = () => {
    // for each beast here, let's check if it's maxed out
    // if it is, then we add 1 to the count
    let beastsOnFire = 0;
    let dragons = 0;
    this.nfts.forEach((beast) => {
      if (beast.type_ === 6) {
        dragons++;
      }
      if (isMaxedOut(beast)) {
        beastsOnFire++;
      }
    });

    this.beastsOnFire = beastsOnFire;
    this.dragons = dragons;
  };
}

export default SquadModel;
