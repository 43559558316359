import React from "react";
import { Line } from "react-chartjs-2";
import BigNumber from "bignumber.js";

function LineChart({ chartData, chartSubtitle }) {
  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>KOZI Price</h2>
      <Line
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: chartSubtitle,
              color: "white",
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = " " + BigNumber(context.parsed.y).toFixed(8);
                  return label;
                },
              },
              usePointStyle: true,
            },
          },
          scales: {
            y: {
              position: "right", // `axis` is determined by the position as `'y'`
              ticks: {
                color: "#8f8",
              },
            },
            x: {
              ticks: {
                color: "#ddd",
              },
            },
          },
          tension: 0.3,
        }}
      />
    </div>
  );
}
export default LineChart;
