import { SERVICE_URL, GET_OUTSTANDING_CLAIM_ENDPOINT } from "../../utils/constants";
import axios from "axios";
import ClaimModel from "../../models/ClaimModel";

const USE_MOCK = false;
const MOCK_CLAIM = {
  digest: "0x5c61cc99149cb87010e90b44758cb69f4baa27df273496f96ab34e7532787400",
  claimData: {
    playerAddress: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    eBct: "650000000000000000000",
    resources: [58], // up to 26 integers representing the 26 resources, in cents
    nonce: 1, // has to be the NEXT nonce for the player
  },
  signature: {
    signer: "0x5bd09121BA4c26a37460890f63Bf9388e0Aa9359",
    _v: 27,
    _r: "0xeea090720f56d0049d6cefdb7165871a709821f0fa76850840684bb20db88570",
    _s: "0x4490ca6356eab12c4f2b71a2985ac64f81f25c5ba025c8e55af5049d34557726",
  },
};
const MOCK_RESPONSE = {
  data: {
    success: true,
    data: MOCK_CLAIM,
  },
};

class Service {
  constructor() {
    this.baseUrl = SERVICE_URL;
    this.getOutstandingClaimUrl = `${this.baseUrl}${GET_OUTSTANDING_CLAIM_ENDPOINT}`;
  }

  async getOutstandingClaim(address) {
    console.log("SERVICE::getOutstandingClaim | address: ", address);

    try {
      let result;
      if (USE_MOCK) {
        result = MOCK_RESPONSE;
      } else {
        result = await axios.post(this.getOutstandingClaimUrl, { address });
      }

      if (!result.data.success)
        throw new Error("Service error on getOutstandingClaim: !result.success");

      if (result.data.data) {
        return new ClaimModel(result.data.data);
      } else {
        return null;
      }
    } catch (e) {
      console.log("SERVICE::getOutstandingClaim | e: ", e);
      return null;
    }
  }
}

const service = new Service();
export default service;
