import React from "react";
import { bonusToBackground } from "../../../../utils/constants";

import { QuestRewardsContainer, QuestBonusWrapper, ResourcesRewardContainer } from "./styles";
import ResourceContainer from "../../resource-container";

const QuestBonus = ({ quest }) => {
  // we want to keep the order of the resources:
  // BCT, eBCT, evo, BL, AL, nuts
  // evo is actually a class of resources, so we will fill the evos last
  // they will take the other's place if they are not present, starting fom BCT
  const resources = [
    { resource: 99, quantity: quest.bctPercentage },
    { resource: 98, quantity: quest.etherealPercentage },
    { resource: "BL", quantity: quest.resourceMultipliers[0] || 0 },
    { resource: "AL", quantity: quest.resourceMultipliers[1] || 0 },
    { resource: 10, quantity: quest.resourceMultipliers[2] || 0 },
  ];

  let evos = quest.resourceMultipliers.slice(3).map((multiplier, index) => ({
    resource: index + 11,
    quantity: multiplier,
  }));

  // sort them by quantity (descending)
  evos.sort((a, b) => b.quantity - a.quantity);

  // filter only the ones with quantity > 0
  evos = evos.filter((evo) => evo.quantity > 0);

  // if there is at least 1 evo greater than zero, inject the first evo in the third slot of the array
  if (evos.length > 0) {
    resources.splice(2, 0, evos[0]);
  } else {
    // if there are no evos, inject a generic evo in the third slot of the array
    resources.splice(2, 0, { resource: 1118, quantity: 0 });
  }

  // now, if we have more than 1 evo greater than zero, from the second evo on it will take the slots of
  // the other resources, but only if they are not present, starting from BCT
  if (evos.length > 1) {
    for (let i = 1; i < evos.length; i++) {
      if (evos[i].quantity > 0) {
        for (let j = 0; j < resources.length; j++) {
          if (resources[j].quantity === 0) {
            resources[j] = evos[i];
            break;
          }
        }
      }
    }
  }

  return (
    <QuestRewardsContainer numberOfResources={6}>
      <ResourcesRewardContainer>
        {resources.map((item, index) => {
          return (
            <QuestBonusWrapper key={index}>
              <ResourceContainer
                resource={item.resource}
                quantity={
                  item.resource === 19 || item.resource === 20 ? item.quantity / 50 : item.quantity
                }
                alreadyInPct={item.resource === 99 || item.resource === 98}
                useBalancesTooltips={true}
                background={
                  item.quantity > 0 ? bonusToBackground[item.resource] : bonusToBackground["none"]
                }
              />
            </QuestBonusWrapper>
          );
        })}
      </ResourcesRewardContainer>
    </QuestRewardsContainer>
  );
};

export default QuestBonus;
