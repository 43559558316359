import styled, { css } from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 6px;
  margin-top: 8px;
  justify-content: center;

  @media (max-width: 450px) {
    gap: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;

export const BuyButton = styled.button`
  width: 50%;
  padding: 8px;
  background: linear-gradient(0deg, rgba(37, 58, 75, 1), rgba(70, 115, 150, 1));
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}

  &:hover {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  &:active {
    outline: none;
    border: none;
    background: linear-gradient(180deg, rgba(45, 45, 45, 1), rgba(56, 56, 56, 1));
    border: ${(props) => (props.disabled ? "none" : "1px solid rgba(255,255,255,0.1)")};
    color: #fff;
  }

  &:focus {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  @media (max-width: 450px) {
    width: 62%;
    height: 100px;
  }
`;

export const BuyWithBusdButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 50%;
  padding: 8px;
  background: linear-gradient(0deg, rgba(48, 74, 50, 1), rgba(108, 161, 104, 1));
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}

  &:hover {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  &:active {
    outline: none;
    border: none;
    background: linear-gradient(180deg, rgba(45, 45, 45, 1), rgba(56, 56, 56, 1));
    border: ${(props) => (props.disabled ? "none" : "1px solid rgba(255,255,255,0.1)")};
    color: #fff;
  }

  &:focus {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  p {
    font-family: "Metropolis", sans-serif !important;
    font-weight: 600;
    letter-spacing: -0.02em;
    font-size: 20px;
    position: relative;
    top: 2px;
    color: ${(props) => (props.hasEnough ? "#fff" : "#111")};
  }

  @media (max-width: 450px) {
    padding: 3px !important;
    width: 180px;
    margin: 0px !important;

    font-size: 12px;
    p {
      display: flex;
      font-size: 16px;
    }
  }
`;

export const PurchaseCostContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;
  top: 4px;
  margin-bottom: 4px;

  @media (max-width: 518px) {
    width: 150px;
  }
`;

export const BuyWithKoziButton = styled.button`
  width: 50%;
  padding: 8px;
  background: linear-gradient(180deg, #8085c2, #471069);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px;

  h1 {
    margin-bottom: 6px;
    font-family: "Metropolis", sans-serif;
    font-size: 13px;
    line-height: 1.2;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}

  &:hover {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  &:active {
    outline: none;
    border: none;
    background: linear-gradient(180deg, rgba(45, 45, 45, 1), rgba(56, 56, 56, 1));
    border: ${(props) => (props.disabled ? "none" : "1px solid rgba(255,255,255,0.1)")};
    color: #fff;
  }

  &:focus {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  p {
    font-family: "Metropolis", sans-serif !important;
    font-weight: 600;
    letter-spacing: -0.02em;
    font-size: 15px;
    line-height: 1.2;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: ${(props) => (!props.hasEnough ? "#fff" : "#fff")};
  }
`;