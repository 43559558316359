import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 690px;

  border-radius: 6px;

  margin-bottom: 20px;
  padding: 10px 18px 20px 18px;

  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 52, 72, 1));
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  font-size: 20px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  text-align: center;
  background: #0f2034;
  min-height: 40px;
  padding: 5px;
  height: auto;
  cursor: default;

  margin-bottom: 5px;
  margin-top: 10px;

  @media (max-width: 685px) {
    width: 440px;
  }

  @media (max-width: 450px) {
    font-size: 16px;
    max-width: 350px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  @media (max-width: 685px) {
    :has(.farmed) {
      flex-direction: column;
    }
  }
`;

export const NftGrid = styled.div`
  width: 100%;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 1px;

  grid-template-rows: 250px;

  @media (max-width: 685px) {
    grid-template-columns: repeat(2, 1fr);
    width: 450px;
    grid-template-rows: 245px;
  }

  @media (max-width: 450px) {
    width: 350px;
    grid-gap: 38px;
    grid-template-rows: 180px;
  }
`;

export const RightAlignedOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  max-width: 640px;

  @media (max-width: 685px) {
    width: 420px;
  }

  @media (max-width: 450px) {
    width: 330px;
  }
`;

export const AdvancedOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100px;
  height: 20px;
`;

export const AdvancedButton = styled.div`
  font-size: 10px;
  color: ${(props) => (props.isOn ? "#ff0" : "#fff")};
  cursor: pointer;
  background-color: ${(props) => (props.isOn ? "#ff0" : "#113")};
  outline: ${(props) => (props.isOn ? "1px solid yellow" : "1px solid white")};
  border-radius: 4px;
  padding: 5px 3px;
  margin-left: 4px;
  user-select: none;

  @media (max-width: 685px) {
    display: ${(props) => (props.ignoreDisplayNone ? "flex" : "none")};
  }

  opacity: 0.5;

  :hover {
    opacity: 1;
  }

  :active {
    background-color: #fff;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #0f2034;
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-right: 10px;
`;

