import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import CountUp from "react-countup";
import { resourceToImagePath } from "../../../../../config/resources/09-resources-images";
import { Container, ImageContainer, TextContainer, WholePart, DecimalPart } from "./styles";

import { indexToTooltip } from "../../../../../utils/constants";

const ResourceContainerInProgress = ({ resource, quantity, background }) => {
  const [previousQuantity, setPreviousQuantity] = useState(quantity);

  const imgStyle = {
    height: "90%",
  };

  useEffect(() => {
    if (quantity !== previousQuantity) {
      // Wait for the CountUp animation to complete
      setTimeout(() => {
        setPreviousQuantity(Math.max(quantity, previousQuantity));
      }, 10000);
    }
  }, [quantity, previousQuantity]);

  const numberOfInsignificantDecimals = (value) => {
    if (value < 10) return 4;
    if (value < 100) return 3;
    if (value < 1000) return 2;
    if (value < 10000) return 1;
    return 0;
  };

  const wholePart = Math.floor(quantity * 100) / 100;
  const insigDecimals = numberOfInsignificantDecimals(wholePart);
  const decimalPart = Math.ceil((quantity % 1).toFixed(6).substring(4, 4 + insigDecimals));
  const previousDecimalPart = Math.ceil(
    (previousQuantity % 1).toFixed(6).substring(4, 4 + insigDecimals)
  );

  return (
    <>
      <a
        data-tooltip-id={resource}
        data-tooltip-content={indexToTooltip[resource]}
        data-tooltip-place="top"
      >
        <Container background={background}>
          <ImageContainer background={background}>
            <img src={resourceToImagePath(Number(resource))} style={imgStyle} />
          </ImageContainer>
          <TextContainer>
            <CountUp
              key={wholePart}
              start={Math.floor(previousQuantity * 100) / 100}
              end={wholePart}
              duration={10}
              decimals={2}
              useEasing={false}
            >
              {({ countUpRef }) => <WholePart ref={countUpRef} />}
            </CountUp>
            <CountUp
              key={resource + "-decimal"}
              start={Number(previousDecimalPart)}
              end={Number(decimalPart)}
              formattingFn={(value) => value.toString().padStart(insigDecimals, "0")}
              duration={10}
              useEasing={false}
            >
              {({ countUpRef }) => <DecimalPart ref={countUpRef} />}
            </CountUp>
          </TextContainer>
        </Container>
      </a>
      <Tooltip id={resource} style={{ fontSize: "12px", zIndex: 2, fontWeight: 600 }} />
    </>
  );
};

export default ResourceContainerInProgress;
