import { ENV } from "../utils/environment";
import config from "../config/config";
import { ethers } from "ethers";
import { shortHash } from "../utils/tx";
import BigNumber from "bignumber.js";
import { koziToStable } from "../config/resources/15-kozi";
import Contracts from "../utils/Contracts";
import { parseAge } from "../utils/age.js";

const fromWei = ethers.formatEther;

class TransactionModel {
  constructor(txData, receiptData) {
    if (txData) {
      this.setTxData(txData);
    }

    if (receiptData) {
      this.setReceiptData(receiptData);
    }
  }

  setTxData(txData) {
    this.hash = txData?.hash;
    this.blockHash = txData?.blockHash;
    this.blockNumber = txData?.blockNumber;
    this.chainId = txData?.chainId;
    this.from = txData?.from;
    this.to = txData?.to;
    this.nonce = txData?.nonce;
    this.input = txData?.input;
    this.value = txData?.value;
    this.gas = txData?.gas;
    this.gasPrice = txData?.gasPrice;
    this.maxPriorityFeePerGas = txData?.maxPriorityFeePerGas;
    this.maxFeePerGas = txData?.maxFeePerGas;
    this.transactionIndex = txData?.transactionIndex;
    this.type = txData?.type;
    this.yParity = txData?.yParity;
    this.v = txData?.v;
    this.r = txData?.r;
    this.s = txData?.s;
  }

  // Overwrite the txData with the receiptData, and more
  setReceiptData(receiptData) {
    this.blockHash = receiptData?.blockHash;
    this.blockNumber = receiptData?.blockNumber;
    this.contractAddress = receiptData?.contractAddress;
    this.cumulativeGasUsed = receiptData?.cumulativeGasUsed;
    this.from = ethers.getAddress(receiptData?.from);
    this.gasUsed = receiptData?.gasUsed;
    this.effectiveGasPrice = receiptData?.effectiveGasPrice;
    this.logs = receiptData?.logs;
    this.logsBloom = receiptData?.logsBloom;
    this.status = receiptData?.status;
    this.transactionHash = receiptData?.transactionHash;
    this.transactionIndex = receiptData?.transactionIndex;
    this.type = receiptData?.type;

    try {
      this.to = ethers.getAddress(receiptData?.to);
    } catch (e) {
      this.to = "";
    }

    // Parse all logs into events:
    this.events = receiptData?.logs ? this.parseLogs(receiptData?.logs) : [];

    if (this.events.length > 0) {
      this.parsedEvents = this.events.map((event) => {
        return {
          name: event.name,
          args: event.args,
          emitter: event.emitter,
          logIndex: event.logIndex,
          // now we'll reconstruct a key-value object with the inputs and the values
          fragments: event?.fragment?.inputs?.reduce((acc, input, index) => {
            const value = (acc[input.name] = event.args[index]);
            return acc;
          }, {}),
        };
      });
    }
  }

  parseLogs(logs) {
    const parsedLogs = logs.map((log) => {
      console.log("Parsing logs:", log);
      const abi = Contracts.addressToAbi(ethers.getAddress(log.address));

      if (!abi)
        return {
          address: log.address,
          emitter: log.address,
          name: shortHash(log.topics[0], 10, 0),
          data: log.data,
          args: log.topics,
          logIndex: log.logIndex,
          fragment: {
            inputs: log?.topics?.length
              ? new Array(log.topics.length).fill({ type: "bytes32" })
              : [],
          },
        };

      const contract = new ethers.Contract(log.address, abi);
      const parsedLog = contract.interface.parseLog(log);
      return { ...parsedLog, emitter: log.address, logIndex: log.logIndex };
    });

    // now remove from the list all logs that don't have a name
    const filteredLogs = parsedLogs.filter((log) => log.name);

    return filteredLogs;
  }

  fee() {
    const kozi = BigNumber(this.gasUsed).times(this.effectiveGasPrice).toString();
    return {
      kozi: kozi.toString(),
      stabletoken: koziToStable(kozi.toString()),
    };
  }

  feeString() {
    const fee = this.fee();
    const inUsdc = BigNumber(fromWei(fee.stabletoken)).dp(4, BigNumber.ROUND_UP);
    return `$${inUsdc} ( ${fromWei(fee.kozi)} KOZI )`;
  }

  parseStatus() {
    return this.status === "0x1" ? "Success ✅" : "Fail ❌";
  }

  parseAction() {
    if (!this.to) return "Contract Deployment";

    // action is the name of the called function
    const abi = Contracts.addressToAbi(ethers.getAddress(this.to));

    if (!abi) {
      if (BigNumber(this.value).toFixed() !== "0") return "KOZI Transfer";

      if (this.input.startsWith("0x12257c90")) {
        return "Kingdom Reward";
      }

      return "Unknown";
    }

    const contract = new ethers.Contract(this.to, abi);
    const parsedFunction = contract.interface.parseTransaction({ data: this.input });

    const action = parsedFunction.name.replace(/([A-Z])/g, " $1").trim();
    const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1);
    return capitalizedAction;
  }

  setAge(currentBlockHeight) {
    this.age = parseAge(this.blockNumber, currentBlockHeight);
  }

  parseInput() {
    if (!this.to) return this.contractAddress;

    const abi = Contracts.addressToAbi(ethers.getAddress(this.to));

    if (!abi) return this.input;

    const contract = new ethers.Contract(this.to, abi);
    const parsedFunction = contract.interface.parseTransaction({ data: this.input });

    const input = parsedFunction.name + "(" + parsedFunction.args.join(", ") + ")";
    return input;
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }

  shortHash(initialDigits = 5, finalDigits = 4) {
    return shortHash(this.hash, initialDigits, finalDigits);
  }
}

export default TransactionModel;
