import React, { useState } from "react";
import Header from "../components/header/Header";
import { Link } from "react-router-dom";

import { useWeb3Context } from "../contexts/Web3Context";

import SpreadModal from "../components/BctPool/spreadModal";
import stashImg from "../assets/images/stasher-benefits-tiny.png";

import StashScreen from "../components/stash-screen/StashScreen";

const Stash = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalToggle = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <SpreadModal isOpen={isModalOpen} closeModal={handleModalToggle} tableSrc={stashImg} />
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">💰</h1>
                    <h1 className="heading text-center">Stash</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      Discover stashing in Beast Kingdom: a unique take on traditional staking. To
                      maximize your stashed BCT's value, active game participation is crucial.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Stashers enjoy exclusive perks, from voting power to enhanced farming rates.
                      Every BCT stashed amplifies your gaming potential. For a full benefits list,{" "}
                      <Link
                        to="#"
                        style={{ textDecorationLine: "underline" }}
                        onClick={handleModalToggle}
                      >
                        click here.
                      </Link>
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      When you stash BCT, you instantly receive 40% of that amount in eBCT (⚡) as a
                      bonus.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Remember, stashing locks your BCT for 180 days. After this, you can withdraw
                      2,000 BCT monthly, but that can reduce your Stasher Level, thus reducing your
                      benefits. Stashing more BCT? The 180-day lock resets for everything you have
                      stashed.
                    </h5>
                    <h5 className="sub-title help-center">
                      Don't have enough BCT to stash? No worries! Purchase some from our{" "}
                      <Link to="/bct-pool" style={{ textDecorationLine: "underline" }}>
                        Community Pool
                      </Link>{" "}
                      to join the action. Buying from the Community Pool not only elevates your
                      gameplay but also directly contributes to sustaining and enriching our
                      collective Beast Kingdom experience.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <StashScreen />
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Stash;
