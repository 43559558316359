import React, { useEffect, useState } from "react";
import {
  ProgressBarContainer,
  QuestDetailWrapper,
  QuestBonusWrapper,
  RewardsContainer,
  RewardsOuterContainer,
  FarmPerDayLabel,
} from "./styles";
import ResourceContainerInProgress from "./resource-container-in-progress";
import BctContainerInProgress from "./bct-container-in-progress";
import { bonusToBackground, indexToBackground } from "../../../../utils/constants";
import { Tooltip } from "react-tooltip";
import { indexToTooltip } from "../../../../utils/constants";

const QuestProgress = ({ rewards, questMultipliers }) => {
  const [resources, setResources] = useState([[]]);

  useEffect(() => {
    if (!rewards) return null;
    if (!rewards.resources) return null;
    if (!rewards.resources.length) return null;
    if (!resources.length) return null;

    // questMultipliers[0] is Basic Loot;
    // questMultipliers[1] is Advanced Loot;
    // questMultipliers[2] is Nuts;
    // questMultipliers[3] is Evo 1, and it goes up to index 10

    // rewards.resources is an array of objects, each with a key that is the resource index and a value that is the amount farmed in a day
    // let's transform that array into an object that has the resource index as the key and the amount farmed in a day as the value, without rendering:
    const rewardResourcesAsObject = {};

    rewards.resources.forEach((resource, index) => {
      const resourceIndex = parseInt(Object.keys(resource)[0]);
      const farmedInADay = resource[resourceIndex];
      rewardResourcesAsObject[resourceIndex] = farmedInADay;
    });

    let resourceComponents = [];
    for (let i = 0; i < 21; i++) {
      const element = rewardResourcesAsObject[i];
      const resourceIndex = i;
      const farmedInADay = parseFloat(rewardResourcesAsObject[resourceIndex]);
      const multiplierIndex = resourceIndex < 5 ? 0 : resourceIndex < 10 ? 1 : resourceIndex - 8;
      const maxFarmInADay = parseFloat(questMultipliers[multiplierIndex]) || 0;

      if (maxFarmInADay === 0) {
        // this quest doesn't even have this resource in it. Let's not show it.
        if (farmedInADay === 0) {
          /*
          console.log(
            `Resource explicitly not in quest: ${resourceIndex} | MaxFarmInADay: ${maxFarmInADay} | FarmInADay: ${farmedInADay}`
          );
          */
        } else {
          resourceComponents.push(
            <QuestBonusWrapper key={i}>
              <ResourceContainerInProgress
                resource={resourceIndex}
                quantity={farmedInADay}
                background={indexToBackground[resourceIndex]}
              />
            </QuestBonusWrapper>
          );
        }
      } else if (farmedInADay === 0) {
        //console.log(`Resource not being farmed: ${resourceIndex}`);
        // this quest has this resource, but it's not being farmed. Let's show it in red.
        if (resourceIndex > 9) {
          resourceComponents.push(
            <QuestBonusWrapper key={i}>
              <ResourceContainerInProgress
                resource={resourceIndex}
                quantity={farmedInADay}
                background={"#B00"}
              />
            </QuestBonusWrapper>
          );
        } else {
          //resourceComponents.push(null);
        }
      } else {
        resourceComponents.push(
          <QuestBonusWrapper key={i}>
            <ResourceContainerInProgress
              resource={resourceIndex}
              quantity={farmedInADay}
              background={indexToBackground[resourceIndex]}
            />
          </QuestBonusWrapper>
        );
      }
    }

    /*
    let resourceComponents = rewards.resources
      .map((resource, index) => {
        const resourceIndex = parseInt(Object.keys(resource)[0]);
        const farmedInADay = rewardResourcesAsObject[resourceIndex];

        const multiplierIndex = resourceIndex < 5 ? 0 : resourceIndex < 10 ? 1 : resourceIndex - 8;
        const maxFarmInADay = questMultipliers[multiplierIndex] || 0;

        if (maxFarmInADay === 0) {
          // this quest doesn't even have this resource in it. Let's not show it.
          return null;
        } else if (farmedInADay === 0) {
          // this quest has this resource, but it's not being farmed. Let's show it in red.
          if (resourceIndex > 9) {
            return (
              <QuestBonusWrapper key={index}>
                <ResourceContainerInProgress
                  resource={resourceIndex}
                  quantity={farmedInADay}
                  background={"#B00"}
                />
              </QuestBonusWrapper>
            );
          } else {
            return null;
          }
        } else {
          return (
            <QuestBonusWrapper key={index}>
              <ResourceContainerInProgress
                resource={resourceIndex}
                quantity={farmedInADay}
                background={indexToBackground[resourceIndex]}
              />
            </QuestBonusWrapper>
          );
        }
      })
      .filter(Boolean);
      */

    setResources(resourceComponents);
  }, [rewards]);

  return (
    rewards && (
      <>
        <ProgressBarContainer>
          <QuestDetailWrapper>
            <FarmPerDayLabel>
              Your squad will farm roughly this much every day here:
            </FarmPerDayLabel>
          </QuestDetailWrapper>
        </ProgressBarContainer>
        <RewardsOuterContainer>
          <RewardsContainer>
            {Object.keys(rewards.bonuses).map((key, index) => {
              if (rewards.bonuses[key] > 0) {
                return (
                  <QuestBonusWrapper key={key}>
                    <a
                      data-tooltip-id={`progress-${key}`}
                      data-tooltip-content={indexToTooltip[key]}
                      data-tooltip-place="top"
                    >
                      <BctContainerInProgress
                        resource={key}
                        quantity={rewards.bonuses[key]}
                        background={bonusToBackground[key]}
                      />
                    </a>
                    <Tooltip id={`progress-${key}`} style={{ fontSize: "12px", fontWeight: 600 }} />
                  </QuestBonusWrapper>
                );
              } else {
                return null;
              }
            })}
            {resources.length > 0 ? resources : null}
          </RewardsContainer>
        </RewardsOuterContainer>
      </>
    )
  );
};

export default QuestProgress;
