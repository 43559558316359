import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Container, DropdownItem } from "./styles";

const SortSelectDropdown = ({
  selectCallback = null,
  optionList = null,
  defaultText = "Default Text",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedName, setSelectedName] = useState(defaultText);

  const onSelect = (prop) => {
    setSelected(prop);
    setSelectedName(`${prop.name}`);
    setIsOpen(false);

    if (selectCallback) {
      selectCallback(prop.id);
    }
  };

  const dropDownForceStyle = {
    minWidth: "280px",
  };

  return (
    <Container itemCount={optionList.length}>
      <div className="dropdown" style={dropDownForceStyle}>
        <Link
          to="#"
          className="btn-selector nolink"
          onClick={() => setIsOpen(!isOpen)}
          style={{ padding: "5px" }}
        >
          {selectedName}
        </Link>
        {isOpen && (
          <ul style={{ overflowY: "auto" }}>
            {optionList.map((prop, index) => (
              <li key={index}>
                <DropdownItem onClick={() => onSelect(prop)}>{prop.name}</DropdownItem>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Container>
  );
};

export default SortSelectDropdown;
