const imageUrlMap = {
  "bg-collection-active2": "https://i.postimg.cc/0y0DxYRQ/bg-collection-active2.png",
  "bg-collection-active2-5skills":
    "https://i.postimg.cc/q7mK6p7H/bg-collection-active2-5skills.png",
  "bg-collection-active2-6skills":
    "https://i.postimg.cc/V6dCj2Sr/bg-collection-active2-6skills.png",
  "bg-collection-active4": "https://i.postimg.cc/tC0xxS9J/bg-collection-active4.png",
  "bg-collection-active4-5skills":
    "https://i.postimg.cc/Z54vfWBh/bg-collection-active4-5skills.png",
  "bg-collection-active4-6skills":
    "https://i.postimg.cc/pddhH3JG/bg-collection-active4-6skills.png",
  "bg-collection-inactive2": "https://i.postimg.cc/KvngrPYN/bg-collection-inactive2.png",
  "bg-collection-inactive2-5skills":
    "https://i.postimg.cc/wj2yn9LR/bg-collection-inactive2-5skills.png",
  "bg-collection-inactive2-6skills":
    "https://i.postimg.cc/g0Bwk4MY/bg-collection-inactive2-6skills.png",
  "bg-collection-inactive4": "https://i.postimg.cc/QN7BhQc3/bg-collection-inactive4.png",
  "bg-collection-inactive4-5skills":
    "https://i.postimg.cc/dtKh1h9T/bg-collection-inactive4-5skills.png",
  "bg-collection-inactive4-6skills":
    "https://i.postimg.cc/wTc7s9W4/bg-collection-inactive4-6skills.png",
  bg2: "https://i.postimg.cc/gJT0mJ2r/bg2.png",
  "bg2-5skills": "https://i.postimg.cc/zfpB2pmC/bg2-5skills.png",
  "bg2-6skills": "https://i.postimg.cc/sXX2kSyt/bg2-6skills.png",
  bg4: "https://i.postimg.cc/3Jp2fMt2/bg4.png",
  "bg4-5skills": "https://i.postimg.cc/6Ts54mRX/bg4-5skills.png",
  "bg4-6skills": "https://i.postimg.cc/7LP7L5wN/bg4-6skills.png",
};

export const getCustomBorder = (nft, hasSynergyCollection) => {
  if (!nft) return [false, false, ""];

  const suffix = nft.type_ >= 6 && nft.type_ <= 10 ? "-5skills" : nft.type_ >= 11 ? "-6skills" : "";
  const versionNumber = isMaxedOut(nft) ? 4 : 2;
  let baseName = "bg";

  if (nft.collection) {
    baseName = hasSynergyCollection ? "bg-collection-active" : "bg-collection-inactive";
  }

  const imageName = `${baseName}${versionNumber}${suffix}`;
  const imageUrl = imageUrlMap[imageName] || ""; // Default to an empty string if the image is not found

  return [true, true, imageUrl];
};

export const getFireOverlayImage = () => {
  return `/assets/images/squad-card/fire-anim-transparent.png`;
};

export const isMaxedOut = (nft) => {
  try {
    if (nft.rank < 99) return false;
    if (nft.agility < 5) return false;
    if (nft.nuts < 50) return false;
    if (nft.evos < 11) return false;
    if (nft.rarity < 4) return false;
    if (nft.traits.length < 3) return false;
    if (nft.rarity < 4) return false;

    const extraSkillLevels = nft.type_ < 6 ? 0 : nft.type_ < 11 ? 2 : 5;
    if (nft.skills[0] < 5 + extraSkillLevels) return false;
    if (nft.skills[1] < 5 + extraSkillLevels) return false;
    if (nft.skills[2] < 5 + extraSkillLevels) return false;
    if (nft.skills[3] < 5 + extraSkillLevels) return false;
    if (nft.type_ > 5 && nft.skills[4] < 5 + extraSkillLevels) return false;
    if (nft.type_ > 10 && nft.skills[5] < 5 + extraSkillLevels) return false;
  } catch (e) {
    return false;
  }

  return true;
};
