import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: auto;
  border-radius: 6px;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 180px;
`;

export const InternalContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 52, 72, 1));

  border-radius: 6px;

  max-width: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 25px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 25px;
`;

export const ActionBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CollectionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  width: 685px;
  z-index: 1;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;
