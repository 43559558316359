import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;

  animation: ${fadeIn} 1s ease-in-out;

  border-radius: 4px;

  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1));

  @media (max-width: 685px) {
    width: 440px;
  }

  @media (max-width: 450px) {
    width: 350px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  overflow: hidden;
  margin-left: 4px;

  @media (max-width: 685px) {
    flex-direction: column;
    height: auto;
    margin-left: 2px;
  }
`;

export const TopLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 646px;

  @media (max-width: 685px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const NoNftsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  overflow: hidden;
  margin-bottom: 10px;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  line-height: 1.3;
  background: #0f2034;

  font-size: 1.4rem;

  @media (max-width: 685px) {
    height: auto;
    width: 440px;
  }

  @media (max-width: 450px) {
    height: auto;
    width: 350px;
  }
`;

export const Wrapper = styled.div``;
