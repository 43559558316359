import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GlobalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));
  width: 350px;
  max-width: 90%;
  outline: 3px solid white;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`;

export const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin: -15px 15px 15px 15px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 15px 5px 15px;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 6px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  outline: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 15px 15px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 6px;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 45%;

  &:hover {
    background: #000;
    color: #fff;
  }
`;
