import React, { useEffect, useState } from "react";
import Messenger from "../../utils/Messenger";
import BigNumber from "bignumber.js";

import {
  Container,
  Title,
  YellowHeading,
  GreenHeading,
  ButtonContainer,
  Button,
} from "./styles.js";

import { usePlayerContext } from "../../contexts/PlayerContext";
import { useWeb3Context } from "../../contexts/Web3Context";

import { currentBlock, blocksToHours } from "../../utils/block";

const WithdrawScreen = () => {
  const { isReady } = useWeb3Context();
  const { player, withdraw } = usePlayerContext();
  const [didWithdraw, setDidWithdraw] = useState(false);
  const [amount, setAmount] = useState(0);
  const [bctToClaim, setBctToClaim] = useState(0);
  const [canWithdraw, setCanWithdraw] = useState(false);
  const [remainingHours, setRemainingHours] = useState(0);

  const _currentBlockNumber = () => {
    if (!player?.connection?.initialBlockNumber) return;

    const _block = currentBlock(
      player.connection.initialBlockNumber,
      player.connection.initialTimestamp
    );

    return _block;
  };

  // if player.lastWithdrawalBlock is null
  // or if currentBlock - player.lastWithdrawalBlock is greater than 432000,
  // then the player can withdraw
  // else we should tell the user how many blocks are left until they can withdraw
  // But we want to display that in days and hours, instead of blocks
  // So we need to convert blocks to hours
  const elapsedHours = () => {
    if (!player?.info?.lastWithdrawalBlock) return 0;

    const _hours = blocksToHours(_currentBlockNumber(), player.info.lastWithdrawalBlock);

    return _hours;
  };

  const _remainingHours = () => {
    if (!player?.info?.lastWithdrawalBlock) return 0;

    const _elapsed = elapsedHours();
    if (!_elapsed) return 0;

    if (_elapsed >= 360) return 0;

    const _hours = 360 - _elapsed; // 15 days * 24 hours = 360 hours

    console.log("remaining hours: ", _hours);

    return BigNumber(_hours).toFixed(0);
  };

  const doWithdraw = async () => {
    if (!isReady) return;
    if (!player) return;
    if (didWithdraw) return;

    const _didWithdraw = await withdraw(amount);
    if (_didWithdraw) {
      setDidWithdraw(true);
      player.info.lastWithdrawalBlock = _currentBlockNumber();
    }
  };

  useEffect(() => {
    // Step 2: Recompute _bctToClaim whenever the player object changes
    const computeBctToClaim = () => {
      const _bct = player?.info?.bctToClaim;
      if (!_bct) return;

      if (didWithdraw) return 0;

      // Now let's convert it to a normal number through BigNumber
      const computedBctToClaim = new BigNumber(_bct)
        .dividedBy(10 ** 18)
        .dp(2, BigNumber.ROUND_HALF_DOWN);

      const _ebctToClaim = parseFloat(computedBctToClaim);
      console.log("computedBctToClaim: ", _ebctToClaim);

      return _ebctToClaim;
    };

    const computeCanWithdraw = () => {
      const _bct = player?.info?.bctToClaim;
      if (!_bct) return false;

      if (_bct === "0") return false;

      const _remaining = _remainingHours();
      if (!_remaining || _remaining <= 0) return true;
      else return false;
    };

    setBctToClaim(computeBctToClaim());
    setCanWithdraw(computeCanWithdraw());
    setRemainingHours(_remainingHours());
  }, [player, didWithdraw, remainingHours]);

  return (
    player && (
      <Container>
        {canWithdraw && (
          <>
            <GreenHeading>You have {bctToClaim} BCT to withdraw</GreenHeading>
            <Title>
              Withdrawing it will yield you{" "}
              {BigNumber(bctToClaim).times(85).div(100).dp(2, BigNumber.ROUND_HALF_DOWN).toString()}{" "}
              BCT and the remainder returns to the Farm.
            </Title>
            <ButtonContainer onClick={doWithdraw}>
              <Button>Withdraw</Button>
            </ButtonContainer>
          </>
        )}
        {!canWithdraw && remainingHours > 0 && (
          <YellowHeading>{`You can withdraw again in ${remainingHours} hours`}</YellowHeading>
        )}
        {!canWithdraw && bctToClaim === 0 && (
          <YellowHeading>{`You don't have BCT to withdraw at the moment.`}</YellowHeading>
        )}
      </Container>
    )
  );
};

export default WithdrawScreen;
