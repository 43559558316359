import styled, { keyframes } from "styled-components";
import { usePlayerContext } from "../../contexts/PlayerContext";
import Squad from "../squad-v2/Squad";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49vw, 2fr));
  width: 100%;
  justify-items: center;
  animation: ${fadeIn} 0.5s ease-in-out;

  @media (max-width: 1340px) {
    grid-template-columns: repeat(auto-fill, minmax(100vw, 1fr));
    width: 100%;
  }

  @media (max-width: 685px) {
    grid-template-columns: repeat(auto-fill, minmax(100vw, 1fr));
    width: 100%;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fill, minmax(100vw, 1fr));
    width: 100%;
  }
`;

const SquadsGrid = ({ onExtraSlotClick }) => {
  const { player } = usePlayerContext();

  return (
    player.squads.length &&
    onExtraSlotClick && (
      <Grid>
        {player.squads.map((squad, index) => (
          <div key={index}>
            <Squad squad={squad} onExtraSlotClick={onExtraSlotClick} />
          </div>
        ))}
      </Grid>
    )
  );
};

export default SquadsGrid;
