import { ethers } from "ethers";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/header/Header.jsx";
import BigNumber from "bignumber.js";
import Messenger from "../utils/Messenger.js";
import { useWeb3Context } from "../contexts/Web3Context.jsx";

const Focus = () => {
  BigNumber.config({ DECIMAL_PLACES: 18 });
  const { isReady, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">👽</h1>
                    <h1 className="heading text-center">Focus</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      The longer you maintain focus, the more you earn
                    </h5>

                    <h5 className="sub-title help-center mg-bt-32">
                      Focus is how long-time holders are rewarded. You initiate the ritual by
                      refraining from selling or transferring BCT for 30 days. After reaching this
                      threshold, your Focus Level will increase by 1 every day, starting from 0 and
                      up to a maximum of 500.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      If you sell or transfer BCT, your Focus will reset to 0 and the 30-day initial
                      timer will start again.
                    </h5>
                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      Focus provides a huge bonus to your{" "}
                      <span style={{ fontWeight: "bold" }}>global</span> farming of BCT and eBCT:
                      <p style={{ marginTop: "10px" }}>💠 +1% for each 1000 BCT in your Metamask</p>
                      <p className="small" style={{ color: "#bbb" }}>
                        The total bonus cannot exceed your current Focus Level.
                      </p>
                      <p
                        className="small"
                        style={{ fontWeight: "bold", color: "#a33", marginTop: "30px" }}
                      >
                        This feature is not yet implemented. Stay tuned for updates.
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container" style={{ top: "-40px" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h2 className="heading text-center">⏳</h2>
                    <h2 className="heading text-center">Coming Soon</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Focus;
