import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px 0px 0px 40px;

  ul {
    height: ${(props) => (props.itemCount < 6 ? `${props.itemCount * 27}px` : `188px`)};
    background-color: #06063b;
  }

  .dropdown li {
    padding: 1px 8px;
    /*font-family: "Metropolis", sans-serif;*/
  }

  .dropdown li:hover {
    background-color: #06065b;
  }

  a {
    padding: 5px !important;
  }

  @media (max-width: 585px) {
    padding: 0;
  }
`;

export const TraitItem = styled.div`
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  height: 24px !important;
  padding: 0px !important;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TraitImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  position: relative;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  top: 1px;
`;
