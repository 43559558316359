import { zeroAddress } from "../../../utils/address";

export const FAKE_DUTCH_LIST = [
  {
    id: 501,
    createdAt: 34409564,
    assetId: 10974, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "6000000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: false, // that 1 ether is in BCT
    isStabletokenOnly: false,
    isDutchAuction: true,
  },
  {
    id: 502,
    createdAt: 34409864,
    assetId: 10975, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "150000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: true,
  },
  {
    id: 505,
    createdAt: 34410064,
    assetId: 7245, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "250000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: true,
  },
  {
    id: 503,
    createdAt: 34411764,
    assetId: 10976, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "100000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: true,
  },
  {
    id: 506,
    createdAt: 34410764,
    assetId: 7, // Mushrooms
    quantity: 50,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "200000000000000000000",
    allowEbct: false,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: true,
  },
  {
    id: 504,
    createdAt: 31232530,
    assetId: 10977, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "100000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: true,
  },
];

export const FAKE_PAGINATED_LIST = [];
for (let i = 0; i < 256; i++) {
  FAKE_PAGINATED_LIST.push({
    id: i + 1,
    createdAt: 1000,
    assetId: 10974, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "6000000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: false, // that 1 ether is in BCT
    isStabletokenOnly: false,
    isDutchAuction: false,
  });
}

export const FAKE_NFT_LIST = [
  {
    id: 1,
    createdAt: 1000,
    assetId: 10974, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "6000000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: false, // that 1 ether is in BCT
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 2,
    createdAt: 1000,
    assetId: 10975, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "150000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 103,
    createdAt: 1000,
    assetId: 10976, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "100000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 104,
    createdAt: 1000,
    assetId: 10977, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "100000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 105,
    createdAt: 1000,
    assetId: 7245, // NFT
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "250000000000000000000",
    allowEbct: true,
    isNft: true,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
];

export const FAKE_RESOURCE_LIST = [
  {
    id: 3,
    createdAt: 1000,
    assetId: 0, // SC
    quantity: 25,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "20000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 4,
    createdAt: 1000,
    assetId: 1, // Fish
    quantity: 50,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "40000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 5,
    createdAt: 1000,
    assetId: 2, // Grass
    quantity: 75,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "60000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 6,
    createdAt: 1000,
    assetId: 3, // Roots
    quantity: 75,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "80000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 7,
    createdAt: 1000,
    assetId: 4, // Bananas
    quantity: 999,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "100000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 8,
    createdAt: 1000,
    assetId: 5, // Exotic Cheese
    quantity: 200,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "120000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 9,
    createdAt: 1000,
    assetId: 6, // Cat Milk
    quantity: 12,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "200000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 10,
    createdAt: 1000,
    assetId: 7, // Mushrooms
    quantity: 50,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "200000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 11,
    createdAt: 1000,
    assetId: 8, // Mango
    quantity: 10,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "2250000000000000000000", // 3 ether each
    allowEbct: true, // can't pay with eBCT
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 12,
    createdAt: 1000,
    assetId: 9, // Tools
    quantity: 10,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "5000000000000000000000", // 3 ether each
    allowEbct: true, // can't pay with eBCT
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },

  {
    id: 14,
    createdAt: 1000,
    assetId: 11, // Gene Therapy
    quantity: 20,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "60000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 15,
    createdAt: 1000,
    assetId: 12, // Mouse Box
    quantity: 100,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "60000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 16,
    createdAt: 1000,
    assetId: 13, // Cat Box
    quantity: 100,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "120000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 17,
    createdAt: 1000,
    assetId: 19, // CowBox
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "52000000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 18,
    createdAt: 1000,
    assetId: 20, // EleBox
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "150000000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 19,
    createdAt: 1000,
    assetId: 14, // Mouse Dna
    quantity: 99,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "200000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 20,
    createdAt: 1000,
    assetId: 15, // CatDna
    quantity: 100,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "3000000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 21,
    createdAt: 1000,
    assetId: 16, // CowDna
    quantity: 100,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "4000000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 22,
    createdAt: 1000,
    assetId: 17, // ElephantDna
    quantity: 2,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "8000000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 23,
    createdAt: 1000,
    assetId: 18, // ApeDna
    quantity: 1,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "15000000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 13,
    createdAt: 1000,
    assetId: 10, // Nuts
    quantity: 20,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "250000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: false,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 24,
    createdAt: 1000,
    assetId: 21, // Dragon Scales
    quantity: 3,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "2000000000000000000",
    allowEbct: false,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 25,
    createdAt: 1000,
    assetId: 22, // Griffin Feathers
    quantity: 5,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "3000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: true,
    isDutchAuction: false,
  },
  {
    id: 26,
    createdAt: 1000,
    assetId: 23, // Satyr Horns
    quantity: 8,
    seller: "0x6F165B30ee4bFc9565E977Ae252E4110624ab147",
    privateFor: zeroAddress,
    priceInWei: "4000000000000000000",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
  {
    id: 0,
    createdAt: 1000,
    assetId: 24, // Dinosaur Skulls
    quantity: 0,
    seller: zeroAddress,
    privateFor: zeroAddress,
    priceInWei: "0",
    allowEbct: true,
    isNft: false,
    isPriceInBusd: true,
    isStabletokenOnly: false,
    isDutchAuction: false,
  },
];
