import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header.jsx";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

import UnderworldLegacyScreen from "../components/underworld-legacy-screen/UnderworldLegacyScreen.jsx";

import { useWeb3Context } from "../contexts/Web3Context.jsx";
import BigNumber from "bignumber.js";

const UnderworldLegacy = () => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const { isReady, consult } = useWeb3Context();
  const [jackpot, setJackpot] = useState("?");
  const [collapsed, setCollapsed] = useState(false);

  const getJackpot = async () => {
    const totalPot = await consult({
      contractName: "KingdomFunds",
      functionName: "balanceOf",
      functionParams: [3], // fund #3 is the Fur & Fortune jackpot
    });
    const _jackpot = BigNumber(totalPot)
      .times(25)
      .div(100)
      .div(1e18)
      .toFixed(2, BigNumber.ROUND_DOWN);

    setJackpot(_jackpot);
  };

  useEffect(() => {
    if (isReady) {
      getJackpot();
    }
  }, [isReady]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Header />

      {!IS_PAUSED ? (
        <div>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">⚰️</h1>
                    <h1 className="heading text-center">Underworld Legacy</h1>
                  </div>
                  {!collapsed ? (
                    <div>
                      <h5 className="sub-title help-center mg-bt-32 ">
                        Exile a beast from the game to inherit its Collection.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Find a dead beast or kill one of your own.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        Lorem ipsum Dolor Sit Amet. Lorem ipsum Dolor Sit Amet. Lorem ipsum Dolor.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Spend Kozi (🧿) to execute the ritual.
                      </h5>
                      <h5 className="sub-title help-center small mg-bt-32">
                        Lorem ipsum Dolor Sit Amet. Lorem ipsum Dolor Sit Amet. Lorem ipsum Dolor.
                      </h5>

                      <h5 className="sub-title help-center " style={{ color: "yellow" }}>
                        Don't faint while playing!
                      </h5>
                      <h5 className="sub-title help-center small">
                        You will execute exactly 2 transactions each time you play Underworld
                        Legacy. The whole game takes approximately 40 seconds to unfold. Once
                        started, you must complete the game within 30 minutes, or you'll
                        automatically forfeit your rewards.
                      </h5>
                    </div>
                  ) : (
                    <h5 className="sub-title help-center small" style={{ marginBottom: "-20px" }}>
                      <Link
                        to="#"
                        onClick={toggleCollapse}
                        style={{ fontSize: "12px", textDecoration: "underline" }}
                      >
                        Rules of the Game
                      </Link>
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </section>

          <UnderworldLegacyScreen jackpot={jackpot} />
        </div>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default UnderworldLegacy;
