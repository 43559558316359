import React, { createContext, useContext } from "react";
import { toast } from "react-toastify";

import { useWeb3Context } from "./Web3Context";
import { usePlayerContext } from "./PlayerContext";

export const RegisterContext = createContext({});

export const useRegisterContext = () => {
  return useContext(RegisterContext);
};

export const RegisterProvider = ({ children }) => {
  // Get account and provider from the Web3Context
  const { isReady, signer, execFunction } = useWeb3Context();
  const { isRegistered } = usePlayerContext();

  const registerPlayer = async () => {
    if (!isReady) return;
    if (!signer) return;

    if (isRegistered) {
      toast.error(`Address already registered. Go ahead and play!`, {
        type: toast.TYPE.WARNING,
        autoClose: 5000,
      });
      return;
    }

    await execFunction({
      contractName: "Farm",
      functionName: "registerPlayer",
      functionParams: [signer.address, true],
      successMessage: "You have successfully registered your address!",
      errorList: [
        {
          reason: "1",
          message: "Address already registered. Go ahead and play!",
        },
      ],
      navigateOnSuccess: "/balances",
    });
  };

  return (
    <RegisterContext.Provider
      value={{
        registerPlayer,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};
