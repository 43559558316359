import styled, { css } from "styled-components";

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: center;
  outline: 2px solid white;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: auto;
  padding: 10px;
`;

export const CompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: auto;
  padding: 10px;

  background-color: rgba(0, 85, 0, 1);
`;

export const CompleteTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const CompleteDescription = styled.p`
  text-align: center;
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const ExternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: auto;
  padding: 10px;

  background-color: "transparent";
`;

export const ExternalTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ExternalDescription = styled.p`
  text-align: center;
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
  line-height: 1.4;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-top: 0;
  font-family: "Metropolis", sans-serif;
`;

export const ExtraHelpButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;

  top: 8px;
  right: 8px;

  border: 1px solid white;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #007;
  }
`;

export const ExtraHelpButtonText = styled.div`
  position: relative;
  top: 1px;
  font-family: "Metropolis", sans-serif;
  font-size: 16px;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 13px;
  line-height: 1.2;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const TraitPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .dropdown btn-selector nolink {
    color: #fff;
  }
`;

export const ForbiddenRarity = styled.h3`
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  font-family: "Metropolis", sans-serif;
  color: #ff0;
`;

export const NameInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
`;

export const NameInput = styled.input`
  width: 100% !important;
  height: 34px !important;
  margin-top: 4px;
  margin-bottom: 7px;
  border-radius: 4px !important;
  background-color: #06063b !important;

  font-size: 1.5rem !important;
  color: #eee !important;
  outline: none;
  text-align: center;

  &::placeholder {
    font-size: 1.5rem !important;
    position: relative;
    top: 0px;
  }
`;

export const SpeciesPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .dropdown btn-selector nolink {
    color: #fff;
  }

  ${(props) =>
    props.hasSecondaryTraitPicker &&
    css`
      position: relative;
      top: 2px;
      margin-top: -10px;
      margin-bottom: 4px;
      height: 80px;
      justify-content: space-between;
    `}
`;

export const RarityPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .dropdown btn-selector nolink {
    color: #fff;
  }

  ${(props) =>
    props.hasSecondaryTraitPicker &&
    css`
      position: relative;
      top: 2px;
      margin-top: -10px;
      margin-bottom: 4px;
      height: 80px;
      justify-content: space-between;
    `}
`;

export const CustomizeButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
  height: 34px;
  margin-top: 6px;
  border-radius: 4px;
  background-color: #06063b;
  cursor: pointer;

  &:hover {
    background-color: #007;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #06063b !important;
      cursor: not-allowed !important;
    `}
`;

export const CustomizeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
`;
