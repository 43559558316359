import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 198px;
  margin-top: 10px;
  background: ${(props) =>
    props.ebct > 0
      ? props.isWinner
        ? "linear-gradient(0deg, #010 20%, #000 100%)"
        : "linear-gradient(0deg, #101 20%, #000 100%)"
      : "linear-gradient(0deg, #111 20%, #000 100%)"};
`;

export const BorderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  border-radius: 4px 4px 4px 4px;
  outline: 4px solid black;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  font-size: 16px;
  color: ${(props) =>
    props.ebct > 0 ? (props.isWinner ? "#0d0" : props.isTimeOver ? "#bbb" : "#dd0") : "#bbb"};
  margin-top: 4px;
  margin-bottom: 3px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #fff;

  margin-top: 6px;
`;
