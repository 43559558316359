export const promos = [
  {
    id: 304,
    name: "God-Emperors",
    rewardedFor: "1st Prosperity Wars in 2024",
    originalOwner: "?",
  },
];

export function isPromoCollection(collectionId) {
  return promos.some((promo) => promo.id === collectionId);
}

export function getPromoInfo(collectionId) {
  return promos.find((promo) => promo.id === collectionId);
}
