import styled, { keyframes } from "styled-components";
import { ProgressBar } from "react-bootstrap";

const shimmerAnimation = keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 22px;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const CustomProgressBar = styled(ProgressBar)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background-color: #2f2c33;

  .progress-bar {
    background: linear-gradient(-90deg, #89e160, #026622);
    border-radius: 6px;
    height: 95%;
    transition: width 3s ease-in-out;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0)
      );
      background-position: -100% 0;
      background-size: 10% 100%;
      animation: ${shimmerAnimation} 25s linear infinite;
    }
  }
`;

export const DurationText = styled.div`
  position: absolute;
  bottom: 5px;
  font-size: 14px;
  color: #ffffff;
  /*text-shadow: -1px 0 #888, 0 1px #888, 1px 0 #888, 0 -1px #888;*/
  font-weight: 600;
  z-index: 1;
  cursor: default;

  left: 50%;
  transform: translateX(-50%);
`;
