import React from "react";
import Header from "../components/header/Header";
import { Link } from "react-router-dom";

import { useWeb3Context } from "../contexts/Web3Context";

const AddKingdomChain = () => {
  const { addKingdomChainToMetamask, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🦊</h1>
                    <h1 className="heading text-center">Add Kingdom Chain to Metamask</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center">
                      Let's connect to our very own community blockchain, the{" "}
                      <span style={{ fontWeight: "bold" }}> Kingdom Chain</span>.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      <Link
                        to="#"
                        style={{ textDecorationLine: "underline" }}
                        onClick={addKingdomChainToMetamask}
                      >
                        CLICK HERE
                      </Link>{" "}
                      to add Kingdom chain to Metamask.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      If that doesn't work, follow the steps below:
                    </h5>

                    <h5 className="sub-title help-center">
                      1. Click on the Metamask icon on your browser.
                    </h5>
                    <h5 className="sub-title help-center">
                      2. Click on the Networks dropdown menu.
                    </h5>
                    <h5 className="sub-title help-center">
                      3. Click on the 'Add Network' option at the bottom of the dropdown menu.
                    </h5>
                    <h5 className="sub-title help-center">
                      4. Click on the "Add a network manually" option.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      5. Enter the following information in the form:
                    </h5>
                    <h5 className="sub-title help-center">
                      <span style={{ fontWeight: "bold" }}>Network Name:</span> Kingdom Chain
                    </h5>
                    <h5 className="sub-title help-center">
                      <span style={{ fontWeight: "bold" }}>New RPC URL:</span>{" "}
                      https://kingdomchain.observer/rpc
                    </h5>
                    <h5 className="sub-title help-center">
                      <span style={{ fontWeight: "bold" }}>Chain ID:</span> 39916801
                    </h5>
                    <h5 className="sub-title help-center">
                      <span style={{ fontWeight: "bold" }}>Currency Symbol:</span> KOZI
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      <span style={{ fontWeight: "bold" }}>Block Explorer URL:</span>{" "}
                      https://beastkingdom.io/travelsong
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      6. Click on the "Save" button to save the Kingdom Chain network to your
                      Metamask. You're done! Now simply refresh the page and make sure you're
                      connected to the Kingdom Chain network in Metamask.
                    </h5>
                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      Next:{" "}
                      <Link to="/kingdom-bridge" style={{ textDecorationLine: "underline" }}>
                        Get some Kozi
                      </Link>{" "}
                      then{" "}
                      <Link to="/register" style={{ textDecorationLine: "underline" }}>
                        Register
                      </Link>{" "}
                      to play!
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
                  <h5 className="sub-title help-center mg-bt-32 "></h5>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default AddKingdomChain;
