import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  padding: 5px 3px 5px 0px;
  width: 100%;

  animation: ${fadeIn} 1s ease-in-out;

  border-radius: 8px;

  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1));

  @media (max-width: 685px) {
    width: 440px;
  }

  @media (max-width: 450px) {
    width: 290px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  overflow: hidden;
  margin-left: 4px;

  @media (max-width: 685px) {
    flex-direction: column;
    height: auto;
    margin-left: 2px;
  }
`;

export const TopLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 646px;

  @media (max-width: 685px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div``;

export const CostTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background: linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1));
  border-radius: 0px 0px 4px 4px;
  position: relative;
  top: -10px;
`;

export const CostText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  color: yellow;
`;
