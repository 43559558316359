import React, { createContext, useContext } from "react";
import { useWeb3Context } from "./Web3Context";

export const BoosterCrackerContext = createContext({});

export const useBoosterCrackerContext = () => {
  return useContext(BoosterCrackerContext);
};

export const BoosterCrackerProvider = ({ children }) => {
  const { isReady, execFunction } = useWeb3Context();

  const unboxBooster = async (type_, howMany) => {
    if (!isReady) return;

    await execFunction({
      contractName: "BoosterCracker",
      functionName: "unboxBooster",
      functionParams: [type_, howMany],
      successMessage: "You have successfully minted a beast!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: "/beasts",
    });
  };

  return (
    <BoosterCrackerContext.Provider
      value={{
        unboxBooster,
      }}
    >
      {children}
    </BoosterCrackerContext.Provider>
  );
};
