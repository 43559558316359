import styled from "styled-components";
import SkillNumber from "./SkillNumber";

import SuperCheese from "../../assets/images/squads-card/resource-00.png";
import Fish from "../../assets/images/squads-card/resource-01.png";
import Grass from "../../assets/images/squads-card/resource-02.png";
import Root from "../../assets/images/squads-card/resource-03.png";
import Banana from "../../assets/images/squads-card/resource-04.png";
import ExoticCheese from "../../assets/images/squads-card/resource-05.png";
import Milk from "../../assets/images/squads-card/resource-06.png";
import Mushrooms from "../../assets/images/squads-card/resource-07.png";
import Mango from "../../assets/images/squads-card/resource-08.png";
import Tool from "../../assets/images/squads-card/resource-09.png";
import Nuts from "../../assets/images/squads-card/resource-10.png";
import Therapy from "../../assets/images/squads-card/resource-11.png";
import MouseBox from "../../assets/images/squads-card/resource-12.png";
import CatBox from "../../assets/images/squads-card/resource-13.png";
import MouseDna from "../../assets/images/squads-card/resource-14.png";
import CatDna from "../../assets/images/squads-card/resource-15.png";
import CowDna from "../../assets/images/squads-card/resource-16.png";
import ElephantDna from "../../assets/images/squads-card/resource-17.png";
import ApeDna from "../../assets/images/squads-card/resource-18.png";
import CowBox from "../../assets/images/squads-card/resource-19.png";
import ElephantBox from "../../assets/images/squads-card/resource-20.png";

const getSixthSkillImage = (type) => {
  switch (type) {
    case 11:
      return [CowDna];
    case 12:
      return [ElephantDna];
    case 13:
      return [ApeDna];
    case 14:
      return [CowBox];
    case 15:
      return [ElephantBox];
    default:
      return ["Unknown"];
  }
};

const SkillNumbersContainer = styled.div`
  position: relative;
  width: 10%;
  display: flex;
  flex-direction: column;
  top: ${(props) => (props.scale ? (props.scale <= 0.5 ? `51%` : `49%`) : `50%`)};
  left: ${(props) => (props.scale ? (props.scale <= 0.5 ? `68%` : `67%`) : `67%`)};
  height: fit-content;
  text-align: center;

  @media (max-width: 450px) {
    top: ${(props) =>
      props.scale
        ? props.lockSize
          ? `${30 * props.scale}px`
          : props.scale <= 0.5
          ? `${25 * props.scale}px`
          : `${23 * props.scale}px`
        : `24px`};
    left: ${(props) =>
      props.scale ? (props.lockSize ? `67.5%` : props.scale <= 0.5 ? `70.5%` : `68.5%`) : `68.5%`};
  }
`;

const SkillNumbersContainerFor3 = styled.div`
  position: relative;
  width: 10%;
  display: flex;
  flex-direction: column;
  top: ${(props) => (props.scale ? (props.scale <= 0.5 ? `15%` : `15%`) : `15%`)};
  left: ${(props) => (props.scale ? (props.scale <= 0.5 ? `68%` : `67%`) : `67%`)};
  height: fit-content;
  text-align: center;

  @media (max-width: 450px) {
    top: ${(props) => (props.scale ? (props.scale <= 0.5 ? `14%` : `14%`) : `14%`)};
    left: ${(props) => (props.scale ? (props.scale <= 0.5 ? `68%` : `68%`) : `68%`)};
    width: 20px;
  }
`;

const ImageContainer = styled.img`
  position: absolute;
  top: ${(props) => (props.scale ? (props.scale <= 0.5 ? `-3px;` : `-3px;`) : `-3px;`)};
  right: ${(props) => (props.scale ? (props.scale <= 0.7 ? `12px;` : `20px;`) : `20px;`)};
  width: 28px;
  height: ${(props) => (props.scale ? (props.scale <= 0.7 ? `13px;` : `20px;`) : `20px;`)};
  display: flex;
  justify-content: center;

  @media (max-width: 450px) {
    width: 17px;
    height: 17px;
  }
`;

const CardSkillsLeft = ({ nft, scale, lockSize }) => {
  if (!nft || !nft.skills || !nft.rarity) return null;

  if (nft.species <= 10) {
    return (
      <SkillNumbersContainer scale={scale} lockSize={lockSize}>
        <SkillNumber scale={scale} lockSize={lockSize}>
          {nft.rarity * nft.skills[2]}
        </SkillNumber>
        <SkillNumber scale={scale} lockSize={lockSize} isSecondSkillNumber={true}>
          {nft.rarity * nft.skills[3]}
        </SkillNumber>
      </SkillNumbersContainer>
    );
  } else {
    return (
      <>
        <SkillNumbersContainerFor3 scale={scale} lockSize={lockSize}>
          <ImageContainer scale={scale} lockSize={lockSize} src={getSixthSkillImage(nft.species)} />
          <SkillNumber scale={scale} lockSize={lockSize}>
            {nft.rarity * nft.skills[5]}
          </SkillNumber>
          <SkillNumber scale={scale} lockSize={lockSize}>
            {nft.rarity * nft.skills[2]}
          </SkillNumber>
          <SkillNumber scale={scale} lockSize={lockSize} isSecondSkillNumber={true}>
            {nft.rarity * nft.skills[3]}
          </SkillNumber>
        </SkillNumbersContainerFor3>
      </>
    );
  }
};

export default CardSkillsLeft;
