import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import {
  Container,
  ListContainer,
  CollectionNamesContainer,
  CollectionName,
  CollectionLength,
  TitleContainer,
  Title,
  Subtitle,
} from "./styles";

import { existingCount, maxSize, isComplete, isFull } from "../../../utils/collections";
import { isPromoCollection } from "../../card/specialCollections";

const CollectionList = ({ collectionList = [], onSelect = null, playerNftIds = [] }) => {
  const [selectedCollectionId, setSelectedCollectionId] = useState(0);

  useEffect(() => {});

  function handleSelectCollection(collectionId) {
    setSelectedCollectionId(collectionId);
    if (onSelect) onSelect(collectionId);
  }

  return (
    <Container>
      <TitleContainer>
        <Title>Collection List</Title>
        <Subtitle>{`View, expand, or create new collections`}</Subtitle>
      </TitleContainer>
      <ListContainer>
        <CollectionNamesContainer
          key={"new"}
          onClick={() => handleSelectCollection(0)}
          isSelected={selectedCollectionId === 0}
        >
          <CollectionName>{"Create New Collection"}</CollectionName>
          <CollectionLength></CollectionLength>
        </CollectionNamesContainer>
        {collectionList.map((collection, index) => (
          <CollectionNamesContainer
            key={index + collection.id}
            onClick={() => handleSelectCollection(collection.id)}
            isSelected={collection.id === selectedCollectionId}
            isExternal={collection.isExternal}
            isComplete={isComplete(collection, playerNftIds)}
            isTheLastOne={index === collectionList.length - 1}
            isFull={isFull(collection)}
            isPromo={isPromoCollection(collection.id)}
          >
            <CollectionName key={"name" + index + collection.id}>
              {collection.name.replace("Collection", "").replace("collection", "")}
            </CollectionName>
            <a
              data-tooltip-id={"col" + index + collection.id}
              data-tooltip-content={`Blockchain ID: ${collection.id}`}
            >
              <CollectionLength key={"length" + index + collection.id}>
                {existingCount(collection, playerNftIds)} / {maxSize(collection)}
              </CollectionLength>
            </a>
            <Tooltip
              id={"col" + index + collection.id}
              style={{ fontSize: "12px", zIndex: 9999999999, fontWeight: 600 }}
            />
          </CollectionNamesContainer>
        ))}
      </ListContainer>
    </Container>
  );
};

export default CollectionList;
