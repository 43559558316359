import React, { useState, useEffect } from "react";

import { Tooltip } from "react-tooltip";
import { indexToTooltip, indexToVerticalBackground } from "../../../utils/constants.js";
import { resourceToImagePath } from "../../../config/resources/09-resources-images.js";

import { Container, ContainerBg, ImageContainer, TextContainer } from "./styles.js";

const ClickableBox = ({
  title = "loading...",
  resource = 0,
  currentQuantity = 5,
  maxQuantity = 5,
  tooltip = null,
  onClick = null,
  isActive = false,
}) => {
  resource = Number(resource);
  currentQuantity = Number(currentQuantity);
  maxQuantity = Number(maxQuantity);
  const tooltipId = "tooltip-" + resource;
  const tooltipText = tooltip || "";
  const isInfinite = maxQuantity === 0;

  const background = indexToVerticalBackground[resource];
  const image = `../${resourceToImagePath(resource)}`;

  const isAtMax = currentQuantity === maxQuantity && !isInfinite;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <a data-tooltip-id={tooltipId} data-tooltip-content={tooltipText}>
        <Container onClick={handleClick} isActive={isActive} isAtMax={isAtMax}>
          <ContainerBg isActive={isActive} background={background} isAtMax={isAtMax} />
          <ImageContainer isActive={isActive} isAtMax={isAtMax}>
            <img src={image} alt={title} />
          </ImageContainer>
          <TextContainer isInfinite={isInfinite} isAtMax={isAtMax}>
            <h6>{isInfinite ? "∞" : `${currentQuantity}/${maxQuantity}`}</h6>
          </TextContainer>
        </Container>
      </a>
      <Tooltip id={tooltipId} style={{ fontSize: "11px", zIndex: 99999 }} />
    </>
  );
};

export default ClickableBox;
