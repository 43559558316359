import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Messenger from "../../utils/Messenger";
import BigNumber from "bignumber.js";
import { useWeb3Context } from "../../contexts/Web3Context";
import { fromWei, toWei } from "../../utils/wei";
import TimerManager from "../../utils/TimerManager";
import Contracts from "../../utils/Contracts";
import { ethers } from "ethers";
import { shortHash } from "../../utils/tx";
import Debouncer from "../../utils/Debouncer";

import Card from "../card/Card";

import { CONSTANTS } from "./config";
import {
  OuterShell,
  Wheel,
  SacrificeBox,
  CardCradle,
  StatsBox,
  StatLabel,
  Lucks,
  AdvancedOptions,
  ClearButton,
  DoubleButton,
  TicketsButton,
  SmallDoubleButton,
  BigSpinButton,
} from "./styles";

import Item from "./Item";

const COST = {
  double: 10,
  luck: 3,
};

const DEFAULT_STATE = {
  mouseNft: null,
  catNft: null,
  cowNft: null,
  elephantNft: null,
  apeNft: null,
  totalLuck: 0,
  spentLuck: 0,
  optimizedItemIndexes: [],
  disabledItemIndexes: [],
  deadItemIndexes: [],
  doublePrizes: false,
  prestigeOn: false,
  totalTickets: 1,
  spinUid: null,
  spinStep: 0,
  selectedCategories: [],
  finalInnerResults: [],
};

/*
  SpinStep 1: after clicking the Spin button, before approving it on Metamask (old: 1)
  SpinStep 2: after approving the Spin on Metamask, before selecting categories: 
              awaiting 15 seconds before unlocking the button to select categories (button locked) (NEW)
  SpinStep 3: ready to select categories (button unlocked) (old: 2)
  SpinStep 4: after clicking the select categories button AND approving it on Metamask
              awaiting 15 seconds before unlocking the button to get results (button locked) (NEW)
  SpinStep 5: ready to get results (button unlocked) (old: 3)
  SpinStep 6: after clicking the get results button AND approving it on Metamask (button locked) (old: 4)
  SpinStep 7: display results on a ritual modal (the OK button reloads the website) (NEW)
  */
const SPIN_STEPS = {
  0: {
    name: "SPIN",
    description: "Spin the wheel to win prizes",
    button: "spin",
  },
  1: {
    name: "SPIN",
    description: "Spin the wheel to win prizes",
    button: "spin",
  },
  2: {
    name: "Shuffling",
    description: "Awaiting 15 seconds before unlocking the button to select categories",
    button: "😵 Spinning...",
  },
  3: {
    name: "Select Categories",
    description: "Find out which categories you won in",
    button: "🧐 Find the Winning Categories",
  },
  4: {
    name: "Shuffling",
    description: "Awaiting 15 seconds before unlocking the button to get results",
    button: "😵 Searching for Winning Categories...",
  },
  5: {
    name: "Get Results",
    description: "Find out what you won",
    button: "🙈 Show me the Prizes!",
  },
  6: {
    name: "Reveal Prizes",
    description: "Reveal your prizes",
    button: "🤓 Revealing Prizes...",
  },
  7: {
    name: "Claim Prizes",
    description: "Claim your prizes",
    button: "🤩 Claim Prizes and Refresh Balances",
  },
};

const UnderworldLegacyScreen = ({ jackpot = null }) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const location = useLocation();
  const navigate = useNavigate();
  const { stableToTargetKozi, execFunction } = useWeb3Context();

  const [state, setState] = useState(DEFAULT_STATE);

  // find the index of the "USDC" item inside the prizes array
  const usdcIndex = CONSTANTS.prizes.findIndex((prize) => prize.name === "USDC");
  CONSTANTS.prizes[usdcIndex].highItems[4] = jackpot;

  useEffect(() => {
    if (location?.state?.selectedNfts?.length > 0) {
      onNftsAdded(location.state.selectedNfts);
    }
  }, [location?.state?.selectedNfts]);

  const toggleDoublePrizes = () => {
    setState({ ...state, doublePrizes: !state.doublePrizes });
  };

  const togglePrestige = () => {
    if (state.prestigeOn) {
      clearAll();
    } else {
      setState({
        ...state,
        mouseNft: null,
        catNft: null,
        cowNft: null,
        elephantNft: null,
        apeNft: null,
        totalLuck: 25,
        spentLuck: 0,
        optimizedItemIndexes: [],
        disabledItemIndexes: [],
        deadItemIndexes: [],
        koziSpentOnReplacingBeasts: 0,
        prestigeOn: true,
      });
    }
  };

  const clearAll = () => {
    setState(DEFAULT_STATE);
    navigate(location.pathname, { state: { selectedNfts: [] } });
  };

  const getLuckEmojis = (totalLuck, availableLuck, spentLuck) => {
    let emojis = "";
    for (let i = 0; i < spentLuck; i++) {
      emojis += "🔹";
    }

    for (let i = 0; i < availableLuck; i++) {
      emojis += "🍀";
    }

    if (totalLuck === 0) emojis = "Just your glorious lucky self! 💪";

    return emojis;
  };

  const onNftsAdded = (nfts) => {
    // first, we check if we already have an nft with the species of the new nft
    const oldState = { ...state };
    const newState = { ...state };
    let totalLuck = 0;
    for (let i = 0; i < nfts.length; i++) {
      const nft = nfts[i];
      let species = nft.species;
      switch (species) {
        case 1: // mouse
          newState.mouseNft = { ...nft };
          break;
        case 2: // cat
          newState.catNft = nft;
          break;
        case 3: // cow
          newState.cowNft = nft;
          break;
        case 4: // elephant
          newState.elephantNft = nft;
          break;
        case 5: // ape
          newState.apeNft = nft;
          break;
      }

      totalLuck += nft.rarity;
    }

    newState.totalLuck = totalLuck;

    setState(newState);

    console.log("New State:", newState);

    if (JSON.stringify(oldState) === JSON.stringify(newState)) return;

    Debouncer.debounce(
      "underworldLegacyScreen",
      () => {
        navigate(location.pathname, { state: { selectedNfts: nfts } });
      },
      500
    );
  };

  const getAvailableLuck = () => {
    return state.totalLuck - state.spentLuck;
  };

  const cycleTickets = () => {
    if (state.totalTickets === 6) {
      setState({ ...state, totalTickets: 1 });
    } else {
      setState({ ...state, totalTickets: state.totalTickets + 1 });
    }
  };

  const handleAddLuck = (itemConfig, itemIndex) => {
    //console.log(`handleAddLuck ${itemConfig.name} ${itemIndex}`);
    if (state.optimizedItemIndexes.includes(itemIndex)) {
      // let's remove the item:
      let newOptimizedItemIndexes = state.optimizedItemIndexes.filter(
        (index) => index !== itemIndex
      );
      setState({
        ...state,
        spentLuck: state.spentLuck - 1,
        optimizedItemIndexes: newOptimizedItemIndexes,
      });
    } else {
      if (state.spentLuck >= state.totalLuck) {
        Messenger.warn("Not enough Luck. Sacrifice beasts to gain more Luck.");
        return;
      }

      setState({
        ...state,
        spentLuck: state.spentLuck + 1,
        optimizedItemIndexes: [...state.optimizedItemIndexes, itemIndex],
      });
    }
  };

  const handleDisableCategory = (itemConfig, itemIndex) => {
    //console.log(`handleDisableCategory ${itemConfig.name} ${itemIndex}`);
    if (state.disabledItemIndexes.includes(itemIndex)) {
      // let's remove the item:
      let newDisabledItemIndexes = state.disabledItemIndexes.filter((index) => index !== itemIndex);
      setState({
        ...state,
        spentLuck: state.spentLuck - 2,
        disabledItemIndexes: newDisabledItemIndexes,
      });
    } else {
      if (state.spentLuck >= state.totalLuck - 1) {
        Messenger.warn("Not enough Luck. Sacrifice beasts to gain more Luck.");
        return;
      }

      setState({
        ...state,
        spentLuck: state.spentLuck + 2,
        disabledItemIndexes: [...state.disabledItemIndexes, itemIndex],
      });
    }
  };

  const _stableToTargetKozi = (amount, decimals) => {
    // we'll try to get the result in the target token
    // if `amount` is not zero AND the result is zero, we'll display a question mark.
    let result = stableToTargetKozi(amount).toFixed(decimals, BigNumber.ROUND_CEIL);
    if (
      (amount !== BigNumber(0) && result === "0.0000") ||
      result === "NaN" ||
      result === "undefined"
    ) {
      return "?";
    } else {
      return result;
    }
  };

  const currentCostInKozi = (decimals = 4) => {
    // we'll add up all the rarities of the nfts
    let luckBalance = 0;
    if (state.prestigeOn) {
      luckBalance = 25;
    } else {
      if (state.mouseNft)
        luckBalance += state.mouseNft.isActuallyAResource ? 0 : state.mouseNft.rarity;

      if (state.catNft) luckBalance += state.catNft.isActuallyAResource ? 0 : state.catNft.rarity;
      if (state.cowNft) luckBalance += state.cowNft.isActuallyAResource ? 0 : state.cowNft.rarity;
      if (state.elephantNft)
        luckBalance += state.elephantNft.isActuallyAResource ? 0 : state.elephantNft.rarity;

      if (state.apeNft) luckBalance += state.apeNft.isActuallyAResource ? 0 : state.apeNft.rarity;
    }

    let cost = 0;
    if (state.doublePrizes) cost += COST.double;
    if (state.totalLuck > luckBalance) cost += COST.luck * (state.totalLuck - luckBalance);

    return _stableToTargetKozi(toWei(cost), decimals);
  };

  const handleBigButtonClick = () => {
    if (state.spinStep === 0) {
      handleSpin();
    } else if (state.spinStep === 3) {
      handleSelectCategories();
    } else if (state.spinStep === 5) {
      handleFinalResult();
    } else if (state.spinStep === 7) {
      // reload the website on the balances screen:
      window.location.assign("/balances");
    }
  };

  const handleSpin = async () => {
    if (state.spentLuck < state.totalLuck) {
      Messenger.warn("You must allocate all your Luck (🍀) before spinning.");
      return;
    }

    /*
    function spin(
      uint256 tickets,
      uint256[] memory validCategories,
      uint256[] memory optimizedCategories,
      uint256[] memory sacNftIds,
      bool doubleAllPrizes,
      bool useTrophy
    )
    */
    // activeCategories is the opposite of disabledCategories
    const activeCategories = [];
    for (let i = 0; i < CONSTANTS.prizes.length; i++) {
      if (!state.disabledItemIndexes.includes(i)) {
        activeCategories.push(i);
      }
    }

    // sacNftIds is the list of nft ids that are being sacrificed
    // we'll get the ids of the nfts that are not resources
    const sacNftIds = [];
    if (state.mouseNft && !state.mouseNft?.isActuallyAResource) sacNftIds.push(state.mouseNft.id);
    if (state.catNft && !state.catNft?.isActuallyAResource) sacNftIds.push(state.catNft.id);
    if (state.cowNft && !state.cowNft?.isActuallyAResource) sacNftIds.push(state.cowNft.id);
    if (state.elephantNft && !state.elephantNft?.isActuallyAResource)
      sacNftIds.push(state.elephantNft.id);
    if (state.apeNft && !state.apeNft?.isActuallyAResource) sacNftIds.push(state.apeNft.id);

    // let's construct the optimized array with 25 items with value 0
    const optimized = new Array(25).fill(0);
    for (let i = 0; i < state.optimizedItemIndexes.length; i++) {
      optimized[state.optimizedItemIndexes[i]] = 1;
    }

    const success = await execFunction({
      contractName: "FurAndFortune",
      functionName: "spin",
      functionParams: [
        state.totalTickets,
        activeCategories,
        optimized,
        sacNftIds,
        state.doublePrizes,
        state.prestigeOn,
      ],
      koziAmount: BigNumber(currentCostInKozi()).times(1e18).toString(),
      successMessage: "Fur & Fortune game started!",
      minedCallback: onSpinTxMined,
      errorList: [
        {
          reason: "KingdomNobles: not enough loyalty",
          message: "Not enough Lucky Tickets",
        },
        {
          reason: "Insufficient Prestige Trophies",
          message: "Not enough Prestige Trophies",
        },
        {
          reason: "DRR: blockhash not available",
          message: "You're too fast! Please try again in 10 seconds.",
        },
      ],
    });

    if (!success) {
      Messenger.error("Failed to start the Fur & Fortune game");
    }
  };

  const parseLogs = (logs) => {
    const parsedLogs = logs.map((log) => {
      const abi = Contracts.addressToAbi(ethers.getAddress(log.address));

      if (!abi)
        return {
          address: log.address,
          emitter: log.address,
          name: shortHash(log.topics[0], 10, 0),
          data: log.data,
          args: log.topics,
          logIndex: log.logIndex,
          fragment: {
            inputs: log?.topics?.length
              ? new Array(log.topics.length).fill({ type: "bytes32" })
              : [],
          },
        };

      const contract = new ethers.Contract(log.address, abi);
      const parsedLog = contract.interface.parseLog(log);
      return { ...parsedLog, emitter: log.address, logIndex: log.logIndex };
    });

    // now remove from the list all logs that don't have a name
    const filteredLogs = parsedLogs.filter((log) => log.name);

    return filteredLogs;
  };

  const onSpinTxMined = (receipt) => {
    // find the event "Spin" and get the uid
    const events = parseLogs(receipt?.logs);
    const spinEvent = events.find((event) => event.name === "Spin");
    if (!spinEvent) {
      Messenger.error("Failed to get the Spin event from the receipt. Did the transaction fail?");
      return;
    }
    const _uid = spinEvent.args[0];

    console.log("Spin Event:", spinEvent);

    const newState = { ...state, spinUid: _uid, spinStep: 2 };
    setState(newState);

    TimerManager.start(
      "shuffleCategoriesTimer",
      () => {
        setState({ ...newState, spinStep: 3 });
      },
      10000
    );
  };

  const handleSelectCategories = async () => {
    // we'll call the blockchain, and the receipt will be handled by the context
    const success = await execFunction({
      contractName: "FurAndFortune",
      functionName: "selectCategories",
      functionParams: [state.spinUid],
      successMessage: "Yes, keep going!",
      minedCallback: onCategoriesSelectedTxMined,
      errorList: [
        {
          reason: "DRR: blockhash not available",
          message: "You're too fast! Please try again in 10 seconds.",
        },
      ],
    });

    if (!success) {
      Messenger.error(
        "Failed to execute step 2: please look for immediate help on Discord. You can still gain the prizes if you act fast!"
      );
    }
  };

  const onCategoriesSelectedTxMined = (receipt) => {
    const events = parseLogs(receipt?.logs);
    const categoriesSelectionEvent = events.find((event) => event.name === "CategorySelection");
    if (!categoriesSelectionEvent) {
      Messenger.error(
        "Failed to get the CategorySelection event from the receipt. Please look for immediate help on Discord. You can still gain the prizes if you act fast!"
      );
      return;
    }
    const categoriesSelected = categoriesSelectionEvent.args[2].map((index) => parseInt(index));
    console.log("CategorySelection event:", categoriesSelectionEvent);

    const deadItemIndexes = [];
    for (let i = 0; i < CONSTANTS.prizes.length; i++) {
      if (!categoriesSelected.includes(i)) {
        deadItemIndexes.push(i);
      }
    }

    const newState = {
      ...state,
      deadItemIndexes,
      selectedCategories: categoriesSelected,
      spinStep: 4,
    };
    setState(newState);

    TimerManager.start(
      "shufflePrizesTimer",
      () => {
        setState({ ...newState, spinStep: 5 });
      },
      10000
    );
  };

  const handleFinalResult = async () => {
    // we'll call the blockchain, and the receipt will be handled by the context
    const success = await execFunction({
      contractName: "FurAndFortune",
      functionName: "finalResults",
      functionParams: [state.spinUid],
      successMessage: "The final results are in!",
      minedCallback: onFinalResultsTxMined,
    });

    if (!success) {
      Messenger.error(
        "Failed to execute the final step: please look for immediate help on Discord. You can still gain the prizes if you act fast!"
      );
    }
  };

  const onFinalResultsTxMined = (receipt) => {
    const events = parseLogs(receipt?.logs);
    const kingdomRewardEvent = events.find((event) => event.name === "KingdomReward");
    if (!kingdomRewardEvent) {
      Messenger.error(
        "Failed to get the KingdomReward event from the receipt. Please look for immediate help on Discord. You can still gain the prizes if you act fast!"
      );
      return;
    }

    console.log("KingdomReward event:", kingdomRewardEvent);

    const results = kingdomRewardEvent.args[3].map((index) => parseInt(index));
    const newState = { ...state, finalResults: results, spinStep: 6 };
    setState(newState);

    TimerManager.start(
      "shuffleCategoriesTimer",
      () => {
        setState({ ...newState, spinStep: 7 });
      },
      5000
    );
  };

  const innerResultOfCategory = (categoryIndex) => {
    if (!state.finalResults) return null;
    if (!state.selectedCategories.includes(categoryIndex)) return null;

    // get the index of categoryIndex within selectedCategories
    const index = state.selectedCategories.indexOf(categoryIndex);
    return state.finalResults[index];
  };

  const bigButtonLabel = () => {
    if (state.spinStep < 2) {
      return `SPIN for ${state.totalTickets} 🎫
      ${currentCostInKozi() > 0 ? ` and ${currentCostInKozi()} 🧿` : ""}
      ${state.prestigeOn ? ` and 1 🏆` : ""}`;
    } else {
      return SPIN_STEPS[state.spinStep].button;
    }
  };

  return (
    <OuterShell>
      {!state.spinUid && (
        <SacrificeBox disabled={state.prestigeOn}>
          <CardCradle disabled={state.prestigeOn}>
            <Card
              nft={state.mouseNft}
              scale={0.5}
              reasonToSelect="sacrifice-mouse"
              extraOutline={true}
              extraOutlineColor="#ff0000"
              clickToSelectNftFromInventory={true}
              targetSpecies={1}
            />
          </CardCradle>
          <CardCradle disabled={state.prestigeOn}>
            <Card
              nft={state.catNft}
              scale={0.5}
              reasonToSelect="sacrifice-cat"
              extraOutline={true}
              extraOutlineColor="#ff0000"
              clickToSelectNftFromInventory={true}
            />
          </CardCradle>
          <CardCradle disabled={state.prestigeOn}>
            <Card
              nft={state.cowNft}
              scale={0.5}
              reasonToSelect="sacrifice-cow"
              extraOutline={true}
              extraOutlineColor="#ff0000"
              clickToSelectNftFromInventory={true}
            />
          </CardCradle>
          <CardCradle disabled={state.prestigeOn}>
            <Card
              nft={state.elephantNft}
              scale={0.5}
              reasonToSelect="sacrifice-elephant"
              extraOutline={true}
              extraOutlineColor="#ff0000"
              clickToSelectNftFromInventory={true}
            />
          </CardCradle>
          <CardCradle disabled={state.prestigeOn}>
            <Card
              nft={state.apeNft}
              scale={0.5}
              reasonToSelect="sacrifice-ape"
              extraOutline={true}
              extraOutlineColor="#ff0000"
              clickToSelectNftFromInventory={true}
            />
          </CardCradle>
        </SacrificeBox>
      )}
      <StatsBox>
        <StatLabel style={getAvailableLuck() > 0 ? { color: "yellow", fontWeight: "bold" } : {}}>
          Luck:
        </StatLabel>
        <Lucks>
          {getLuckEmojis(state.totalLuck, state.totalLuck - state.spentLuck, state.spentLuck)}
        </Lucks>

        <AdvancedOptions>
          <a
            data-tooltip-id="sp2"
            data-tooltip-content={`Spend KOZI to double all prizes`}
            data-tooltip-place="top"
          >
            <SmallDoubleButton
              onClick={state.spinUid ? null : toggleDoublePrizes}
              isOn={state.doublePrizes}
              disabled={state.spinUid}
            >
              2x
            </SmallDoubleButton>
            <DoubleButton
              onClick={state.spinUid ? null : toggleDoublePrizes}
              isOn={state.doublePrizes}
              disabled={state.spinUid}
            >
              {state.doublePrizes
                ? "All Prizes Doubled ✅"
                : `Double Prizes for ${_stableToTargetKozi(toWei("10"), 4)} 🧿`}
            </DoubleButton>
          </a>
          <Tooltip id="sp2" style={{ fontSize: "12px", fontWeight: 600 }} />

          <a
            data-tooltip-id="sp5"
            data-tooltip-content={`Spend Lucky Tickets to win in more categories`}
            data-tooltip-place="top"
          >
            <TicketsButton onClick={state.spinUid ? null : cycleTickets} disabled={state.spinUid}>
              {state.totalTickets} 🎫
            </TicketsButton>
          </a>
          <Tooltip id="sp5" style={{ fontSize: "12px", fontWeight: 600 }} />

          <a
            data-tooltip-id="sp4"
            data-tooltip-content={`Spend 1 Prestige Trophy to gain 25🍀`}
            data-tooltip-place="top"
          >
            <ClearButton
              onClick={state.spinUid ? null : togglePrestige}
              isOn={state.prestigeOn}
              disabled={state.spinUid}
            >
              🏆
            </ClearButton>
          </a>
          <Tooltip id="sp4" style={{ fontSize: "12px", fontWeight: 600 }} />

          <a data-tooltip-id="sp3" data-tooltip-content={`Clear All`} data-tooltip-place="top">
            <ClearButton onClick={state.spinUid ? null : clearAll} disabled={state.spinUid}>
              ⭕
            </ClearButton>
          </a>
          <Tooltip id="sp3" style={{ fontSize: "12px", fontWeight: 600 }} />
        </AdvancedOptions>
      </StatsBox>
      <Wheel>
        {CONSTANTS.prizes.map((prize, index) => (
          <Item
            key={index + "prizeList"}
            config={prize}
            isOptimized={state?.optimizedItemIndexes?.includes(index)}
            isDisabled={state?.disabledItemIndexes?.includes(index)}
            isDead={state?.deadItemIndexes?.includes(index)}
            isDoubling={state.doublePrizes}
            onAddLuck={() => handleAddLuck(prize, index)}
            onDisableCategory={() => handleDisableCategory(prize, index)}
            spinStep={state.spinStep}
            forceValue={state.finalResults ? innerResultOfCategory(index) : null}
            selectedCategories={state.selectedCategories}
          />
        ))}
      </Wheel>
      <BigSpinButton
        onClick={handleBigButtonClick}
        disabled={state.spinStep === 2 || state.spinStep === 4 || state.spinStep === 6}
      >
        {bigButtonLabel()}
      </BigSpinButton>
    </OuterShell>
  );
};

export default UnderworldLegacyScreen;
