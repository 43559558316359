import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Container, TraitItem, TraitImage } from "./styles";
import { getSvgTraitById } from "../../../config/resources/07-traits-to-svg";
import { allTraits } from "../../../config/resources/01-traits.js";

const ORIGINAL_TRAIT_LIST = allTraits(true).sort((a, b) => a.name.localeCompare(b.name));
// we'll remove the special traits UNDEAD, GHOST, and VAMPIRE (55, 56, 57) from the extended list:
const ORIGINAL_EXTENDED_TRAIT_LIST = allTraits(true)
  .sort((a, b) => a.name.localeCompare(b.name))
  .filter((trait) => trait.id < 55);
let TRAIT_LIST = [...ORIGINAL_TRAIT_LIST];

const TraitSelectDropdown = ({
  traitSelectCallback = null,
  injectTraitZero = false,
  traitPickerForceTraits = [],
  useExtendedTraitList = false,
  isExtraction = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [selectedTraitName, setSelectedTraitName] = useState("Pick a Trait");

  const setup = () => {
    const originalList = useExtendedTraitList ? ORIGINAL_EXTENDED_TRAIT_LIST : ORIGINAL_TRAIT_LIST;

    if (traitPickerForceTraits.length > 0) {
      // transform TRAIT_LIST to only include the traits we want

      console.log("traitPickerForceTraits", traitPickerForceTraits);
      if (isExtraction) {
        console.log("IS EXTRACTION");
        // must use the absolutely full list for extraction
        TRAIT_LIST = ORIGINAL_TRAIT_LIST.filter((trait) =>
          traitPickerForceTraits.includes(trait.id)
        );

        console.log(TRAIT_LIST);
      } else {
        TRAIT_LIST = ORIGINAL_EXTENDED_TRAIT_LIST.filter((trait) =>
          traitPickerForceTraits.includes(trait.id)
        );
      }
    } else {
      if (TRAIT_LIST.length < originalList.length) {
        TRAIT_LIST = originalList;
      }

      if (injectTraitZero) {
        if (TRAIT_LIST[0].id !== 0) {
          // Inject trait zero
          TRAIT_LIST = [
            {
              id: 0,
              name: "Don't Reroll",
            },
            ...TRAIT_LIST,
          ];
        }
      } else {
        // see if we need to remove trait zero
        if (TRAIT_LIST[0].id === 0) TRAIT_LIST = TRAIT_LIST.slice(1);
      }

      if (!useExtendedTraitList) {
        // remove the special traits (above 50)
        TRAIT_LIST = TRAIT_LIST.filter((trait) => trait.id <= 50);
      }
    }
  };
  setup();

  const onTraitSelect = (trait) => {
    setSelectedTrait(trait?.id !== null ? trait.id : selectedTrait);
    setSelectedTraitName(trait.name || selectedTraitName);
    setIsOpen(false);

    if (traitSelectCallback) {
      traitSelectCallback(trait?.id !== null ? trait.id : selectedTrait);
    }
  };

  

  return (
    <Container itemCount={TRAIT_LIST.length}>
      <div className="dropdown">
        <Link
          to="#"
          className="btn-selector nolink"
          onClick={() => setIsOpen(!isOpen)}
          style={{ padding: "5px" }}
        >
          {selectedTrait !== null && <TraitImage src={getSvgTraitById(selectedTrait)} />}{" "}
          {selectedTraitName}
        </Link>
        {isOpen && (
          <ul style={{ overflowY: "auto" }}>
            {TRAIT_LIST.map((trait, index) => (
              <li key={index}>
                <TraitItem onClick={() => onTraitSelect(trait)}>
                  <TraitImage src={getSvgTraitById(trait.id)} /> {trait.name}
                </TraitItem>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Container>
  );
};

export default TraitSelectDropdown;
