import React, { useEffect, useState } from "react";
import { usePlayerContext } from "../../../contexts/PlayerContext";

import OrderCard from "../order-card/OrderCard";

import {
  Container,
  Grid,
  NoOrdersMessageContainer,
  LoadingMessageContainer,
  BigLoadingMessageContainer,
  PaginationContainer,
  PaginationButton,
} from "./styles.js";

const ORDERS_PER_PAGE = 30;

const MarketplaceGrid = ({
  orders = null,
  forceBeastId = null,
  moreFromThisSellerCallback = null,
  moreLikeThisCallback = null,
  isMerchant = false,
  isMyOrders = false,
}) => {
  const { fetchBeasts } = usePlayerContext();
  const itemsPerPage = ORDERS_PER_PAGE;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(orders.length / itemsPerPage));
  const [enrichedOrders, setEnrichedOrders] = useState([]);

  const [isEnriching, setIsEnriching] = useState(false);

  const ordersToShow = enrichedOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    setTotalPages(Math.ceil(orders.length / itemsPerPage));

    const enrichOrders = async () => {
      setIsEnriching(true);
      if (orders) {
        // for each order, if it's an NFT order, fetch the nft from the blockchain
        // but we'll do it for all orders at once, to save time
        if (forceBeastId && orders.length === 1 && !isMyOrders) {
          orders[0].assetId = parseInt(forceBeastId);
        }

        const nftIds = orders.filter((order) => order.isNft).map((order) => order.assetId);

        let _enrichedOrders = [];
        const beasts = await fetchBeasts(nftIds).catch((err) => {
          console.error("MarketplaceGrid :: Error fetching NFTs from the blockchain:", err);
          // reload the website: this is a hack to fix a bug where the NFTs are not fetched
          // when the user is not logged in
          window.location.reload();
        });
        console.log("MarketplaceGrid :: Received", beasts.length, "NFTs from the blockchain.");

        // now we have the NFTs, we can enrich the orders
        orders.forEach((order) => {
          let enriched;
          if (order.isNft) {
            enriched = { ...order };
            enriched.nft = beasts.find((beast) => beast.id === order.assetId);
            _enrichedOrders.push(enriched);
          } else {
            /*
            enriched = { ...order };
            enriched.nft = {
              id: order.id + String(order.assetId),
              isActuallyAResource: true,
              resourceIndex: order.assetId,
              resourceQuantity: order.quantity,
            };
            */
            _enrichedOrders.push(order);
          }
        });

        setEnrichedOrders(_enrichedOrders);
        console.log("MarketplaceGrid :: Orders:", _enrichedOrders);
      }

      setIsEnriching(false);
    };

    enrichOrders();
  }, [orders]);

  if (!orders) {
    console.log("MarketplaceGrid: awaiting for orders data...");
  }

  const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
    let buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <PaginationButton
          key={i}
          onClick={() => setCurrentPage(i)}
          disabled={currentPage === i}
          isSelected={currentPage === i}
          style={i === 1 ? { marginLeft: "0px" } : null}
        >
          {i}
        </PaginationButton>
      );
    }

    return totalPages > 1 && <PaginationContainer>{buttons.slice(0, 21)}</PaginationContainer>;
  };

  return (
    orders && (
      <Container>
        {ordersToShow.length > 0 && (
          <>
            <>
              <Grid orderCount={ordersToShow.length}>
                {ordersToShow.map((order, index) => (
                  <OrderCard
                    orderData={order}
                    key={String(order.id) + String(order.assetId) + String(index)}
                    moreFromThisSellerCallback={moreFromThisSellerCallback}
                    moreLikeThisCallback={moreLikeThisCallback}
                    isMerchant={isMerchant}
                  />
                ))}
              </Grid>
            </>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            )}
          </>
        )}
        {orders && ordersToShow.length === 0 && !isEnriching && (
          <NoOrdersMessageContainer>
            There are no orders with those filters.
          </NoOrdersMessageContainer>
        )}
        {(!orders || isEnriching) && (
          <LoadingMessageContainer>
            <BigLoadingMessageContainer>Loading...</BigLoadingMessageContainer>
          </LoadingMessageContainer>
        )}
      </Container>
    )
  );
};

export default MarketplaceGrid;
