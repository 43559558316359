import React, { createContext, useContext, useCallback, useState, useEffect } from "react";

import { useWeb3Context } from "./Web3Context";

import PlayerModel from "../models/PlayerModel";

export const OtherPlayerContext = createContext({});

export const useOtherPlayerContext = () => {
  return useContext(OtherPlayerContext);
};

export const OtherPlayerProvider = ({ children }) => {
  const { isReady, initialBlockNumber, initialTimestamp, consult } = useWeb3Context();

  const [otherPlayer, setOtherPlayer] = useState(null);

  const fetchOtherPlayerData = useCallback(
    async (address) => {
      if (!address || address === "0x") return;

      const actualPlayerAddress = address;

      const everything = await consult({
        contractName: "Fetch",
        functionName: "getEverything",
        functionParams: [actualPlayerAddress],
      });

      const playerTemp = new PlayerModel(
        actualPlayerAddress,
        everything,
        initialBlockNumber,
        initialTimestamp
      );
      setOtherPlayer(playerTemp);

      console.log("Other Player fetched", playerTemp);
    },
    [isReady]
  );

  return (
    <OtherPlayerContext.Provider
      value={{
        otherPlayer,
        fetchOtherPlayerData,
      }}
    >
      {children}
    </OtherPlayerContext.Provider>
  );
};
