/**
 * This is the model for an NFT.
 * To get the correct data, you need to call the NFT contract and get the data from there.
 * The function is nft.getNft(id) and it returns a NftData struct, that you pass to the constructor of this class.
 */
import BigNumber from "bignumber.js";

class NftModel {
  constructor(nftData) {
    try {
      this.setData(nftData);
    } catch (e) {
      console.error("Error in NftModel constructor", e);
    }
  }

  setData(nftData) {
    this.id = new BigNumber(nftData.id).toNumber(); // an integer from 0 to infinity. This is the global ID of this NFT. @notice: NFT 0 DOES EXIST AND IS VALID.
    this.name = String(nftData.name); // When the name is en ampty string (""), we'll render the rarity + type instead: "Common Cat", "Legendary Mouse", etc.
    this.imageUrl = nftData.imageUrl; // When the image URL is an empty string (""), we'll render the image of the rarity + type instead: we have one image for each rarity-type.
    this.currentOwner = nftData.currentOwner; // the blockchain address of the ORIGINAL owner (not necessarily the current owner)
    if (nftData.attributes.length === 0) {
      this.type_ = 0;
      this.collection = 0;

      return;
    }

    this.type_ = new BigNumber(nftData.attributes[0]).toNumber(); // from 1 to 5: Mouse, Cat, Cow, Elephant, Ape
    this.species = new BigNumber(nftData.attributes[0]).toNumber(); // from 1 to 5: Mouse, Cat, Cow, Elephant, Ape
    this.rarity = new BigNumber(nftData.attributes[1]).toNumber(); // from 1 to 5: Common, Rare, Epic, Legendary, Mythic
    this.rank = new BigNumber(nftData.attributes[2]).toNumber(); // from 0 to 99
    this.agility = new BigNumber(nftData.attributes[3]).toNumber(); // from 0 to 5
    this.nuts = new BigNumber(nftData.attributes[4]).toNumber(); // from 0 to 50
    this.evos = new BigNumber(nftData.attributes[5]).toNumber(); // from 0 to 11
    this.attributes = nftData.attributes.map((attribute) => new BigNumber(attribute).toNumber()); // a list containing 6 integers. The first 5 are the ones above. The last one is the number of times this NFT has been evolved.
    this.attributes[6] =
      this.attributes.length > 6 ? new BigNumber(nftData?.attributes[6]).toNumber() : 0; // from 0 to 5. This is the level of the NFT. 0 means the NFT is level 1, 5 means the NFT is level 6.
    this.baseFarm = new BigNumber(nftData.baseFarm).toString(); // starts at 200, can go very high. Rendered on the left side of the card.
    this.farmPerBlock = new BigNumber(nftData.farmPerBlock).toString(); // starts at 200, can go very high. Rendered on the right side of the card.
    this.squadId = new BigNumber(nftData.squadId).toNumber(); // from 1 to infinity. This is the global ID of this squad. 0 means the NFT is not in a squad.
    this.traits = nftData.traits.map((trait) => new BigNumber(trait).toNumber()); // A list containig integers from 1 to 50. Each integer represents a trait. The list can have from 0 to 3 elements.
    this.skills = nftData.skills.map((skill) => new BigNumber(skill).toNumber()); // a list containing integers from 0 to 5. Each integer represents the level of a skill. The list always has exactly 4 elements, as there are exactly 4 skills for every NFT.
    this.collection = new BigNumber(nftData.collection).toNumber(); // an integer from 0 to infinity. This is the global ID of the collection this NFT belongs to. 0 means the NFT does not belong to any collection.
    this.squadNumber = 0; // from 1 to 20. This is the position of this squad in the player.
    this.questId = 0;
    this.isActuallyAResource = false;
    this.resourceIndex = 0;
    this.resourceQuantity = 0;
    this.isDead = nftData.isDead;
    if (this.skills.length === 4) {
      this.skills.push(0, 0);
    }
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }
}

export default NftModel;
