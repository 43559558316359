import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Messenger from "../utils/Messenger";
import styled from "styled-components";
import Header from "../components/header/Header";
import InventoryGrid from "../components/inventory/InventoryGridKozika";
import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useOtherPlayerContext } from "../contexts/OtherPlayerContext";
import ProgressLabel from "react-progress-label";
import { fadeInFx } from "../utils/fadeIn";

const SHOW_LOADER = false;

const LoaderMasterContainer = styled.div`
  animation: ${fadeInFx} 0.5s ease-in-out;

  p {
    font-family: "Metropolis", sans-serif;
    font-size: 14px;
    line-height: 1.4;
  }
`;

const LoaderContainer = styled.div`
  margin-top: 40px;
  animation: ${fadeInFx} 0.5s ease-in-out;
`;

const InventoryNfts = () => {
  const location = useLocation();
  const { isAddress, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();
  const { player, loading } = usePlayerContext();
  const { fetchOtherPlayerData } = useOtherPlayerContext();

  const [inputAddress, setInputAddress] = useState("");
  const [useOtherPlayer, setUseOtherPlayer] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isTakingLonger, setIsTakingLonger] = useState(false);

  const handleAddressChange = (event) => {
    let address = event.target.value;
    address = address.trim();
    setInputAddress(address);

    if (!address || address === "") {
      setUseOtherPlayer(false);
    } else {
      if (isAddress(address)) {
        setUseOtherPlayer(true);
        fetchOtherPlayerData(address);
      } else {
        setUseOtherPlayer(false);
      }
    }
  };

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }

    return () => {
      Messenger.cancelDelay();
    };
  }, [player]);

  const isPickingForSquad = () => {
    return location.state?.hasOwnProperty("squadId");
  };

  const isPickingForSomething = () => {
    return location.state?.hasOwnProperty("forbiddenNftIds");
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🐮</h1>
                    <h1 className="heading text-center">Beasts</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center">
                      {isPickingForSquad() ? (
                        <>
                          <p>This is a list of all Beasts you own that can enter your squad.</p>
                          <p>Select one of them to add to your squad.</p>
                        </>
                      ) : isPickingForSomething() ? (
                        <>
                          <p>
                            This is a list of all Beasts you own that can be used for this action.
                          </p>
                          <p>Select one of them to proceed.</p>
                        </>
                      ) : (
                        <>
                          This is a list of all Beast Kingdom NFTs that you own.
                          <p>Select one of them to upgrade it!</p>
                          <p className="miniP">
                            You can also take a peek into other players' inventories. Just enter
                            their address in the search bar below.
                          </p>
                        </>
                      )}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {!isPickingForSquad() && (
            <input
              type="text"
              value={inputAddress}
              onChange={handleAddressChange}
              placeholder={
                player ? player.address : "Enter a player's address or connect your Metamask"
              }
              style={{ textAlign: "center" }}
            />
          )}
          <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
            <InventoryGrid isOtherPlayer={useOtherPlayer} />
          </div>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default InventoryNfts;
