import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const OuterShell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 760px;
`;

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 760px;
  height: auto;
  background-color: "transparent";
  border-radius: 10px;
  flex-wrap: wrap;
  margin-top: -20px;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const BalanceGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 350px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const BalanceGroup = styled.div`
  display: flex;
  align-items: stretch;
  width: 350px;
  height: auto;
  overflow: hidden;
  padding: 10px 10px 44px 10px;
  gap: 2px;

  outline: 3px solid #000;
  border-radius: 6px;

  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.7) 20%,
      rgba(0, 0, 0, 0.7) 80%,
      rgba(0, 0, 0, 0.87)
    ),
    linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),
    linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),
    linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;

  margin-right: 20px;
  margin-left: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));

  animation: ${fadeIn} 1s ease-in-out;
`;

export const PlayerNotFound = styled.div`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;

  // centralize it inside the container:
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // make it fill the container:
  width: 330px;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

export const PageSubtitle = styled.h1`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #dd0;
  margin-top: -150px;
  margin-bottom: 100px;
  width: 100%;
  height: 40px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Subtitle = styled.h1`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #dd0;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Subtitle2 = styled.h1`
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #dd0;
  margin-bottom: 10px;
  margin-top: -20px;
  width: 100%;
  height: 40px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94%;
  border-radius: 4px;
  outline: 2px solid white;
  outline-offset: -1px;
  margin-top: -35px;
  margin-bottom: 14px;
`;

export const NumberInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 24px;
  background-color: #fff;
  color: #000;
`;

export const NumberButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 22px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  user-select: none;
`;

export const PlusPlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 22px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  border-radius: 0px 4px 4px 0;
  user-select: none;
`;

export const MinusMinusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 25px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  border-radius: 4px 0 0 4px;
  user-select: none;
`;

export const Number = styled.span`
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 1px;
  left: 5px;
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101.6%;
  height: 40px;
  background-color: #080;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: "Metropolis", sans-serif;
  font-weight: 500;
  letter-spacing: -0.06em;
  margin-top: 10px;

  &:hover {
    background-color: #070;
  }

  // create disabled state:
  ${(props) =>
    props.disabled &&
    `
    background-color: #333;
    cursor: default;

    &:hover {
      background-color: #333;
    }
  `}
`;
