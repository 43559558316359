import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

const initialDate = new Date("2024-03-01T00:00:00Z");

const LateMigration = () => {
  const [migrationFee, setMigrationFee] = useState(0.1);

  useEffect(() => {
    const currentDate = new Date();
    const differenceInDays = Math.floor(
      (currentDate.getTime() - initialDate.getTime()) / (1000 * 3600 * 24)
    );
    const _migrationFee = () => {
      if (differenceInDays < 0) return 0.1;
      return 0.1 * 1.1 ** differenceInDays;
    };

    const formattedFee = BigNumber(_migrationFee()).toFixed(5, BigNumber.ROUND_UP);

    setMigrationFee(formattedFee);
  }, []);

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">😴</h1>
                    <h1 className="heading text-center">Late Migration</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      If you're a BNB Chain player of Beast Kingdom and have{" "}
                      <Link to="/add-kingdom-chain" style={{ textDecorationLine: "underline" }}>
                        connected
                      </Link>{" "}
                      to the Kingdom Chain but can't see your balances or NFTs on our website, it
                      means you've missed the initial migration period.{" "}
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      While verified players on Discord enjoyed a free and automatic migration in
                      February,{" "}
                      <span style={{ textDecoration: "line-through" }}>
                        you can still join us by paying a fee to cover the migration costs.
                      </span>
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      The migration fee started at 0.1 Kozi on March 1st and increases by 10% every
                      day. The current migration fee{" "}
                      <span style={{ textDecoration: "line-through" }}>is</span>{" "}
                      <span style={{ color: "yellow" }}>would be</span> {migrationFee} Kozi.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      The final deadline for migration{" "}
                      <span style={{ textDecoration: "line-through" }}>is</span>{" "}
                      <span style={{ color: "yellow" }}>was</span> April 30th, 2024. After that, the
                      blockchain <span style={{ textDecoration: "line-through" }}>will begin</span>{" "}
                      <span style={{ color: "yellow" }}>began</span> to automatically reject all
                      migration transactions. All assets left behind{" "}
                      <span style={{ textDecoration: "line-through" }}>will be</span>{" "}
                      <span style={{ color: "yellow" }}>were</span> lost, including BCT.
                    </h5>
                    <h5 className="sub-title help-center" style={{ fontWeight: "bold" }}>
                      Q: What if I missed both the initial deadline and the April 30th extension for
                      migrating my assets?
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      A: We understand how disappointing this situation can be. Unfortunately, after
                      April 30th, there are no further options available for migrating your assets
                      to the Kingdom Chain. The migration process is governed strictly by blockchain
                      protocols, which means that after the deadline, the system no longer supports
                      the transfer of assets, even through manual requests. This limitation is not
                      about our willingness to help but is dictated by the technical constraints of
                      the blockchain. We truly appreciate your understanding.
                    </h5>
                    <h5 className="sub-title help-center" style={{ fontWeight: "bold" }}>
                      Q: Why was there a time limit in the first place?
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      A: Because all the money belongs to the community, and maintaining the servers
                      capable of migrating assets from one chain to another is expensive. The time
                      limit was set to ensure that the migration process would be completed in a
                      timely manner, allowing us to focus on the development of new features and
                      improvements for the community, instead of spending the community's resources
                      on maintaining the old system.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
                  <h5 className="sub-title help-center mg-bt-32 "></h5>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default LateMigration;
