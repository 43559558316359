import React, { useEffect, useState } from "react";
import TraitSelectDropdown from "../trait-select-dropdown/TraitSelectDropdown";
import SquadSelectDropdown from "../squad-select-dropdown/SquadSelectDropdown";
import { hasEnoughBct, hasEnoughBusd, hasEnoughResources } from "../../../utils/purchase";
import { getFinalUrl } from "../../../utils/imageUrlCheck";
import { toast } from "react-toastify";

import {
  ButtonContainer,
  BuyButton,
  BuyWithBusdButton,
  BuyWithKoziButton,
  PurchaseCostContainer,
} from "../buttons/PurchaseButtons";

import {
  Container,
  TitleContainer,
  Title,
  Description,
  InputWrapper,
  NumberInput,
  NumberButton,
  PlusPlusButton,
  MinusMinusButton,
  Number,
  Explanation,
  TraitPickerContainer,
  SquadPickerContainer,
  ForbiddenRarity,
  ImageUrlInputContainer,
  ImageUrlInput,
  NameInputContainer,
  NameInput,
  ExtraHelpButton,
  ExtraHelpButtonText,
} from "./styles";

import "../../../assets/styles/loader.css";

import BigNumber from "bignumber.js";
import ResourceContainerUpgrades from "../resource-container";
import { indexToBackground } from "../../../utils/constants";
import { bonusToBackground } from "../../../utils/constants";

import { useWeb3Context } from "../../../contexts/Web3Context";

const UpgradeBox = ({
  title,
  description,
  description2,
  eBctPrice,
  resourcePrices,
  resourceIndexes,
  updatePrice,
  currentLevel,
  previewLevel,
  maxLevel,
  busdPrice = 0,
  buyFunction = null,
  buyWithBusdFunction = null,
  player = null,
  isReady = false,
  isHeader = false,
  hasTraitPicker = false,
  hasSecondaryTraitPicker = false,
  hasTertiaryTraitPicker = false,
  traitSelectCallback = null,
  secondaryTraitSelectCallback = null,
  tertiaryTraitSelectCallback = null,
  useExtendedTraitList = false,
  traitPickerForceTraits = [],
  injectTraitZero = false,
  hasSquadPicker = true,
  squadSelectCallback = null,
  squadPickerForceSquads = [],
  sacrificeSlots = 0,
  hasInputUrl = false,
  inputUrlDefaultValue = "",
  inputUrlCallback = null,
  inputNameDefaultValue = "",
  inputNameCallback = null,
  isForbidden = false,
  isBlocked = false,
  isLoading = false,
  onExtraHelpButtonClick = null,
  omitLevelPicker = false,
  isOverMaxType = false,
  mustButDoesntHave3Traits = false,
  buyWithKoziFunction = null,
  isExtraction = false,
}) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_CEIL });
  const { stableToTargetKozi } = useWeb3Context();
  const [levels, setLevels] = useState(1);
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [secondarySelectedTrait, setSecondarySelectedTrait] = useState(null);
  const [tertiarySelectedTrait, setTertiarySelectedTrait] = useState(null);
  const [currentInputTextValue, setCurrentInputTextValue] = useState("");
  const [currentNameInputValue, setCurrentNameInputValue] = useState(inputNameDefaultValue);
  const [selectedSquadId, setSelectedSquadId] = useState(0);

  const explanation = `The current level is ${currentLevel} and the max level is ${maxLevel}.`;
  const explanationLine2 = "What level would you like it to become?";

  busdPrice = BigNumber(busdPrice).div(1e18).toFixed(2);

  useEffect(() => {
    const initialLevel = Math.min(previewLevel, maxLevel);
    setLevels(initialLevel);
    setCurrentInputTextValue(inputUrlDefaultValue);
    setCurrentNameInputValue(inputNameDefaultValue);
  }, [currentLevel, maxLevel]);

  const handleIncrease = () => {
    if (levels < maxLevel) {
      setLevels((prevLevels) => prevLevels + 1);
      updatePrice(levels + 1);
    }
  };

  const handleIncreasePlus = () => {
    if (levels + 10 < maxLevel) {
      setLevels((prevLevels) => prevLevels + 10);
      updatePrice(levels + 10);
    } else {
      if (currentLevel >= maxLevel) return;

      setLevels(maxLevel);
      updatePrice(maxLevel);
    }
  };

  const handleDecrease = () => {
    if (levels > currentLevel) {
      setLevels((prevLevels) => prevLevels - 1);
      updatePrice(levels - 1);
    }
  };

  const handleDecreasePlus = () => {
    if (levels - 10 > currentLevel) {
      setLevels((prevLevels) => prevLevels - 10);
      updatePrice(levels - 10);
    } else {
      if (currentLevel >= maxLevel) return;

      setLevels(currentLevel);
      updatePrice(currentLevel);
    }
  };

  function handleBuyFunction() {
    if (!buyFunction) {
      console.log(`Can't find the buy function`);
      return;
    }

    if (levels <= currentLevel && maxLevel !== 0) {
      console.log(`Levels are not greater than the current level`);
      return;
    }

    if (hasSquadPicker) {
      buyFunction(levels - currentLevel, selectedTrait, selectedSquadId);
      return;
    }

    if (inputUrlCallback && inputNameCallback && hasTraitPicker && !hasSecondaryTraitPicker) {
      buyFunction(
        levels - currentLevel,
        selectedTrait,
        currentNameInputValue,
        currentInputTextValue
      );
    } else if (hasTraitPicker) {
      if (hasTertiaryTraitPicker) {
        buyFunction(
          levels - currentLevel,
          selectedTrait,
          secondarySelectedTrait,
          tertiarySelectedTrait
        );
      } else if (hasSecondaryTraitPicker) {
        buyFunction(levels - currentLevel, selectedTrait, secondarySelectedTrait);
      } else {
        buyFunction(levels - currentLevel, selectedTrait);
      }
    } else {
      buyFunction(levels - currentLevel);
    }
  }

  function handleBuyWithBusdFunction() {
    if (!buyWithBusdFunction) {
      console.log(`Can't find the pay with USDC function`);
      return;
    }

    if (levels <= currentLevel && maxLevel !== 0) {
      console.log(`Levels are not greater than the current level`);
      return;
    }

    if (hasSquadPicker) {
      buyWithBusdFunction(levels - currentLevel, selectedTrait, selectedSquadId);
      return;
    }

    buyWithBusdFunction(levels - currentLevel);
  }

  function handleBuyWithKoziFunction() {
    if (!buyWithKoziFunction) {
      console.log(`Can't find the pay with KOZI function`);
      return;
    }

    if (levels <= currentLevel && maxLevel !== 0) {
      console.log(`Levels are not greater than the current level`);
      return;
    }

    if (hasSquadPicker) {
      buyWithKoziFunction(levels - currentLevel, selectedTrait, selectedSquadId);
      return;
    }

    buyWithKoziFunction(levels - currentLevel);
  }

  function onTraitSelectCallback(traitId) {
    setSelectedTrait(traitId);
    traitSelectCallback([traitId, secondarySelectedTrait, tertiarySelectedTrait]);
  }

  function onSecondaryTraitSelectCallback(traitId) {
    setSecondarySelectedTrait(traitId);
    secondaryTraitSelectCallback([selectedTrait, traitId, tertiarySelectedTrait]);
  }

  function onTertiaryTraitSelectCallback(traitId) {
    setTertiarySelectedTrait(traitId);
    tertiaryTraitSelectCallback([selectedTrait, secondarySelectedTrait, traitId]);
  }

  function onSquadSelectCallback(squadId) {
    setSelectedSquadId(squadId);
    squadSelectCallback(squadId);
  }

  function onImgUrlFieldChange(url) {
    setCurrentInputTextValue(url);
    handleFetchImgUrl(url);
  }

  async function handleFetchImgUrl(url) {
    if (!inputUrlCallback) return;

    const finalUrl = await getFinalUrl(url);
    inputUrlCallback(finalUrl.url);

    if (!finalUrl.success) {
      toast.error(finalUrl.message, {
        type: toast.TYPE.WARNING,
        autoClose: 5000,
      });
    }
  }

  function handleNameChange(newName) {
    setCurrentNameInputValue(newName);
    inputNameCallback(newName);
  }

  function handleExtraHelpButtonClick() {
    if (onExtraHelpButtonClick) {
      onExtraHelpButtonClick();
    }
  }

  return (
    <Container>
      <TitleContainer>
        <Title>{maxLevel ? `${title}: ${currentLevel} / ${maxLevel}` : `${title}`}</Title>
        {onExtraHelpButtonClick && (
          <ExtraHelpButton onClick={handleExtraHelpButtonClick}>
            <ExtraHelpButtonText>?</ExtraHelpButtonText>
          </ExtraHelpButton>
        )}
      </TitleContainer>
      <Description style={isHeader ? { fontSize: "16px" } : {}}>{description}</Description>
      {isReady && !isHeader && (
        <>
          {!hasTraitPicker && sacrificeSlots === 0 && !hasInputUrl && !omitLevelPicker && (
            <>
              <Explanation>{explanation}</Explanation>
              <Explanation>{explanationLine2}</Explanation>
              <InputWrapper>
                <MinusMinusButton onClick={handleDecreasePlus}>{"--"}</MinusMinusButton>
                <NumberButton onClick={handleDecrease}>-</NumberButton>
                <NumberInput>
                  <Number>{levels}</Number>
                </NumberInput>
                <NumberButton onClick={handleIncrease}>+</NumberButton>
                <PlusPlusButton onClick={handleIncreasePlus}>{"++"}</PlusPlusButton>
              </InputWrapper>
            </>
          )}
          {!hasTraitPicker && sacrificeSlots === 0 && !hasInputUrl && omitLevelPicker && (
            <Explanation>{description2}</Explanation>
          )}
          {hasTraitPicker && (
            <TraitPickerContainer
              hasSecondaryTraitPicker={hasSecondaryTraitPicker}
              hasTertiaryTraitPicker={hasTertiaryTraitPicker}
            >
              {!hasSecondaryTraitPicker && <Explanation>{description2}</Explanation>}
              <TraitSelectDropdown
                traitSelectCallback={onTraitSelectCallback}
                injectTraitZero={injectTraitZero}
                traitPickerForceTraits={traitPickerForceTraits}
                useExtendedTraitList={useExtendedTraitList}
                isExtraction={isExtraction}
              />
              {hasSecondaryTraitPicker && (
                <TraitSelectDropdown
                  traitSelectCallback={onSecondaryTraitSelectCallback}
                  injectTraitZero={injectTraitZero}
                  useExtendedTraitList={useExtendedTraitList}
                  isExtraction={false}
                />
              )}
              {hasTertiaryTraitPicker && (
                <TraitSelectDropdown
                  traitSelectCallback={onTertiaryTraitSelectCallback}
                  injectTraitZero={injectTraitZero}
                  useExtendedTraitList={useExtendedTraitList}
                  isExtraction={false}
                />
              )}
            </TraitPickerContainer>
          )}
          {hasSquadPicker && (
            <SquadPickerContainer>
              <SquadSelectDropdown
                squadSelectCallback={onSquadSelectCallback}
                squadList={squadPickerForceSquads}
              />
            </SquadPickerContainer>
          )}
          {hasInputUrl && (
            <>
              <NameInputContainer>
                <NameInput
                  type="text"
                  placeholder={currentNameInputValue}
                  onChange={(e) => handleNameChange(e.target.value)}
                ></NameInput>
              </NameInputContainer>
              <ImageUrlInputContainer>
                <ImageUrlInput
                  type="text"
                  placeholder={inputUrlDefaultValue}
                  onChange={(e) => onImgUrlFieldChange(e.target.value)}
                  onFocus={(event) => event.target.select()}
                />
              </ImageUrlInputContainer>
            </>
          )}

          {sacrificeSlots > 0 && (
            <Description style={{ marginBottom: "0px", marginTop: "-6px" }}>
              {description2}
            </Description>
          )}
        </>
      )}
      {!isReady && !isHeader && (
        <>
          <Explanation style={{ fontSize: "16px", color: "#cccc00" }}>COMING SOON</Explanation>
          <Explanation style={{ fontSize: "14px", color: "#cccc00", lineHeight: "1.2em" }}>
            <em>
              For now, this feature is available via the blockchain only. It should be published
              soon-ish, but if you can't wait, please learn how to operate on the blockchain by
              reading the BscScan channel in our Discord server.
            </em>
          </Explanation>
        </>
      )}
      {eBctPrice && !isForbidden && !isOverMaxType && !mustButDoesntHave3Traits && (
        <ButtonContainer style={hasSecondaryTraitPicker ? { marginTop: "0px" } : {}}>
          <BuyButton
            onClick={handleBuyFunction}
            disabled={
              (levels <= currentLevel && maxLevel !== 0) ||
              (hasTraitPicker && selectedTrait === null) ||
              (hasSecondaryTraitPicker &&
                (selectedTrait === null || secondarySelectedTrait === null)) ||
              !hasEnoughBct(player, eBctPrice) ||
              !hasEnoughResources(player, resourcePrices, resourceIndexes) ||
              isForbidden ||
              isBlocked
            }
          >
            <p>Pay with resources</p>
            <PurchaseCostContainer>
              <ResourceContainerUpgrades
                resource={98}
                quantity={eBctPrice.div(1e18).toNumber()}
                background={bonusToBackground["eBCT"]}
                hasEnough={hasEnoughBct(player, eBctPrice)}
              />
              {resourceIndexes.map((resourceIndex, mapIndex) => (
                <ResourceContainerUpgrades
                  key={`resource-${resourceIndex}`}
                  resource={resourceIndex}
                  quantity={resourcePrices[mapIndex] / 100}
                  background={indexToBackground[resourceIndex]}
                  hasEnough={hasEnoughResources(
                    player,
                    [resourcePrices[mapIndex]],
                    [resourceIndexes[mapIndex]]
                  )}
                />
              ))}
            </PurchaseCostContainer>
          </BuyButton>

          <BuyWithKoziButton
            disabled={
              busdPrice === 0 ||
              busdPrice === "0.00" ||
              busdPrice === "0.01" ||
              busdPrice === "—" ||
              (levels <= currentLevel && maxLevel !== 0) ||
              (hasTraitPicker && selectedTrait === null) ||
              (hasSecondaryTraitPicker &&
                (selectedTrait === null || secondarySelectedTrait === null)) ||
              isForbidden ||
              isBlocked
            }
            onClick={handleBuyWithKoziFunction}
            hasEnough={player.hasEnoughKozi(stableToTargetKozi(BigNumber(busdPrice).times(1e18)))}
          >
            <h1>
              Pay with <span style={{ color: "yellow" }}>KOZI</span>
            </h1>
            {busdPrice !== "0.00" && busdPrice !== "—" && (
              <p
                style={{
                  fontSize: "12px",
                  color: "#ddc",
                  textDecoration: "line-through",
                  marginBottom: "4px",
                }}
              >
                {"- "}
                {busdPrice} USDC{" -"}
              </p>
            )}
            <p>
              {busdPrice === "0.00" ||
              busdPrice === "0.01" ||
              busdPrice === NaN ||
              busdPrice === "NaN"
                ? "—"
                : stableToTargetKozi(BigNumber(busdPrice).times(1e18)).toFixed(
                    4,
                    BigNumber.ROUND_CEIL
                  ) + " 🧿"}
            </p>
          </BuyWithKoziButton>
        </ButtonContainer>
      )}
      {isForbidden && (
        <ForbiddenRarity>This doesn't work with beasts of this rarity level.</ForbiddenRarity>
      )}
      {isOverMaxType && (
        <ForbiddenRarity>This doesn't work with beasts of this species.</ForbiddenRarity>
      )}
      {mustButDoesntHave3Traits && (
        <ForbiddenRarity>This beast must have 3 traits to be upgraded.</ForbiddenRarity>
      )}
    </Container>
  );
};

export default UpgradeBox;
