import BigNumber from "bignumber.js";

class SquadMultipliersModel {
  constructor(squadData, globalBonus, fortuneLevel, squadBonus, masteries) {
    this.BCT = 100 + squadBonus;
    this.eBCT = 100 + squadBonus;
    this.BL = 100;
    this.AL = 100;
    this.Nuts = 100;
    this.Evo = 100;

    this.setData(squadData, globalBonus, fortuneLevel, masteries);
  }

  setData(squadData, globalBonus, fortuneLevel, masteries) {
    this.BCT =
      squadData.squadBonus +
      this.getBctMultiplier(
        squadData.type_,
        squadData.raritySum,
        globalBonus,
        BigNumber(masteries[0]).toNumber()
      );

    this.eBCT =
      squadData.squadBonus +
      this.getEbctMultiplier(
        squadData.type_,
        squadData.nftIds.length,
        globalBonus,
        BigNumber(masteries[1]).toNumber()
      );

    this.BL = this.getBLMultiplier(
      squadData.type_,
      squadData.synergyBonus,
      squadData.traits.length,
      globalBonus,
      BigNumber(masteries[2]).toNumber()
    );

    this.AL = this.getALMultiplier(
      squadData.type_,
      squadData.synergyBonus,
      squadData.traits.length,
      globalBonus,
      BigNumber(masteries[3]).toNumber()
    );

    this.Nuts = this.getNutsMultiplier(
      squadData.collections.length,
      globalBonus,
      BigNumber(masteries[4]).toNumber()
    );

    this.Evo = this.getEvoMultiplier(fortuneLevel, globalBonus, BigNumber(masteries[5]).toNumber());
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }

  getBctMultiplier(squadType, raritySum, globalBonus, mastery) {
    let multiplier = 100 + globalBonus + mastery;

    // Mandatory Pure squads farm 20% more BCT
    if (squadType !== 6) {
      multiplier += 20;
    }

    // The higher the sum of the NFTs' rarities, the more BCT they farm (up to 30%) [max: 6 nfts * 5 rarities = 30]
    multiplier += raritySum;

    return multiplier;
  }

  getEbctMultiplier(squadType, numberOfNtfs, globalBonus, mastery) {
    let multiplier = 100 + globalBonus + mastery;

    // Mandatory Pure squads farm 30% more eBCT
    if (squadType !== 6) {
      multiplier += 30;
    }

    // The more NFTs there are in the squad, the more eBCT they farm (up to 60%)
    if (numberOfNtfs > 1) {
      multiplier += numberOfNtfs * 10;
    }

    return multiplier;
  }

  getBLMultiplier(squadType, synergyBonus, traits, globalBonus, mastery) {
    let multiplier = 100 + globalBonus + mastery;

    // Mandatory Pure squads farm 100% more Loot
    if (squadType !== 6) {
      multiplier += 100;
    }

    // The higher the synergy bonus, the more Loot they farm [max synergy bonus: 1440] (up to 144%)
    multiplier += synergyBonus / 10;

    // The higher the number of traits, the more Loot they farm [max traits: 18] (up to 90%)
    multiplier += traits * 5;

    return multiplier;
  }

  getALMultiplier(squadType, synergyBonus, traits, globalBonus, mastery) {
    let multiplier = 100 + globalBonus + mastery;

    // Mandatory Pure squads farm 100% more Loot
    if (squadType !== 6) {
      multiplier += 100;
    }

    // The higher the synergy bonus, the more Loot they farm [max synergy bonus: 1440] (up to 144%)
    multiplier += synergyBonus / 10;

    // The higher the number of traits, the more Loot they farm [max traits: 18] (up to 90%)
    multiplier += traits * 5;

    return multiplier;
  }

  getNutsMultiplier(collections, globalBonus, mastery) {
    let multiplier = 100 + globalBonus + mastery;

    // The more collection NFTs in the squad, the more nuts (up to +600%) [max collections: 6]
    // They don't have to belong to the same collection
    multiplier += collections * 20;

    return multiplier;
  }

  getEvoMultiplier(fortuneLevel, globalBonus, mastery) {
    let multiplier = 100 + globalBonus + mastery + fortuneLevel;

    return multiplier;
  }
}

export default SquadMultipliersModel;
