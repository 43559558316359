import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import ReactFlipCard from "reactjs-flip-card";
import { resourceToImagePath } from "../../../config/resources/09-resources-images";
import { format } from "../../../utils/math";
import IntervalManager from "../../../utils/IntervalManager";
import Debouncer from "../../../utils/Debouncer";
import { getSvgTraitById } from "../../../config/resources/07-traits-to-svg";

import {
  Container,
  TraitContainer,
  Image,
  Name,
  ResultNumber,
  Chance,
  ButtonsContainer,
  TicketsButton,
} from "./styles";
import BigNumber from "bignumber.js";

const Item = ({ config, isRolling = false, finalResult = null }) => {
  const [tickets, setTickets] = useState(config.totalTickets);
  const [tripleChances, setTripleChances] = useState(false);
  const [currentRandomValue, setCurrentRandomValue] = useState(0);

  useEffect(() => {
    if (isRolling) {
      randomResult();
    }
  }, [isRolling]);

  const handleCycleTickets = async () => {
    const _config = { ...config };
    let _tickets = tickets;
    if (tickets >= 3) {
      _tickets = 0;
      _config.tickets = _tickets;
    } else {
      _tickets = tickets + 1;
      _config.tickets = _tickets;
    }
    setTickets(_tickets);

    if (config.onCycleTickets) {
      config.onCycleTickets(_config);
    }
  };

  const handleTripleChances = async () => {
    const _config = { ...config };
    _config.tripleChances = !tripleChances;
    setTripleChances(!tripleChances);
    config.onTripleChances(_config);
  };

  const getChance = () => {
    let result = config.chance + tickets * 10;
    if (tripleChances) {
      result = result * 3;
    }
    return result;
  };

  const getIsSuccess = () => {
    if (!isRolling && !finalResult) return false;

    if (isRolling) {
      return currentRandomValue <= getChance();
    } else {
      return finalResult <= getChance();
    }
  };

  const randomResult = () => {
    // let's set a random value between 1 and 100, inclusive
    const rand = Math.floor(Math.random() * 100) + 1;
    setCurrentRandomValue(rand);

    if (isRolling) {
      //start(intervalName, intervalFunction, intervalTime)
      IntervalManager.start(`Item-${config.traitId}`, randomResult, 100);
    } else {
      IntervalManager.stop(`Item-${config.traitId}`);
    }
  };

  const numericResult = () => {
    if (!isRolling && !finalResult) return 0;

    if (isRolling) {
      return currentRandomValue;
    } else {
      return finalResult;
    }
  };

  const is100 = () => {
    return numericResult() === 100;
  };

  return (
    <Container isRolling={isRolling} isSuccess={getIsSuccess()} hasFinalResult={finalResult}>
      <TraitContainer>
        <Image src={getSvgTraitById(config.traitId)} />
      </TraitContainer>

      <Name isSuccess={getIsSuccess()}>{config.name}</Name>

      {(isRolling || finalResult) && (
        <ResultNumber isSuccess={getIsSuccess()} is100={is100()}>
          {numericResult()}
        </ResultNumber>
      )}

      <Chance
        chance={getChance()}
        isRolling={isRolling}
        isSuccess={getIsSuccess()}
        hasFinalResult={finalResult}
      >
        {getChance()}%
      </Chance>
      {!isRolling && !finalResult && (
        <ButtonsContainer>
          <TicketsButton
            onClick={config.spinUid ? null : handleCycleTickets}
            disabled={config.spinUid}
            isFirst={true}
          >
            {config.totalTickets} 🎫
          </TicketsButton>
          <TicketsButton
            onClick={config.spinUid ? null : handleTripleChances}
            disabled={config.spinUid}
            isOn={tripleChances}
          >
            {tripleChances ? "x3" : "x1"}
          </TicketsButton>
        </ButtonsContainer>
      )}
    </Container>
  );
};

export default Item;
