import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  max-width: 685px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 100px;

  @media (max-width: 450px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const UpgradeGroupRowContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 450px) {
    justify-content: center;
  }
`;

export const UpgradeGroupRowRightContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 450px) {
    justify-content: center;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.sacrificeSlots > 0 ? `space-around` : `center`)};
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-radius: 6px;
  position: relative;
  left: ${(props) => (props.sacrificeSlots > 0 ? `3px` : `0`)};

  @media (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 450px) {
    top: 20px;
    left: 0;
  }
`;

export const SacrificeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 15px;
  height: 80%;

  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-row-gap: 16px;
    margin-left: -6px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  left: 15px;
  outline: 2px solid white;
  outline-offset: 2px;
  height: 100%;
  width: 298px;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  @media (max-width: 767px) {
    margin: auto;
    left: 0px;
    margin-top: 5px;
    width: 90%;
  }

  @media (max-width: 450px) {
    width: 82%;
  }
`;
