/**
 * This is the model for a Quest.
 * To get the correct data, you need to call the FARM contract and get the data from there.
 * The function is farm.getQuest(id) and it returns a QuestData struct, that you pass to the constructor of this class.
 */

import BigNumber from "bignumber.js";

class QuestModel {
  constructor(questData) {
    this.setData(questData);
  }

  setData(questData) {
    this.id = new BigNumber(questData.id).toNumber(); // a global ID. Goes from 1 to infinity.
    this.minSynergyMultiplier = new BigNumber(questData.minSynergyMultiplier).toNumber(); // from 0 to 1440. The minimum synergy multiplier required to complete this quest.
    this.bctPercentage = new BigNumber(questData.bctPercentage).toNumber(); // from 0 to infinity. The percentage of bct that will be rewarded for completing this quest.
    this.etherealPercentage = new BigNumber(questData.etherealPercentage).toNumber(); // from 0 to infinity. The percentage of ebct that will be rewarded for completing this quest.
    this.resourceMultipliers = questData.resourceMultipliers.map((resource) =>
      new BigNumber(resource).toNumber()
    ); 
    this.traits = questData.traits.map((trait) => new BigNumber(trait).toNumber()); // a list of integers that represent traits needed to enter this quest. Each element goes from 1 to 50. The list can have from 0 to 3 elements.
    this.collections = questData.collections.map((collection) =>
      new BigNumber(collection).toNumber()
    ); // a list of integers that represent collections needed to enter this quest. Each element goes from 1 to infinity. The list can have from 0 to 3 elements.
    this.anyCollection = questData.anyCollection; // a boolean that tells us whether this quest requires at least 1 collection NFT to enter.
    this.costInResources = questData?.costInResources
      ? questData.costInResources.map((resource) => new BigNumber(resource).toNumber())
      : []; // a list containing 21 integers. Each goes from 0 to infinity. The first 5 are for the 5 basic resources of each species of NFT, the next 5 are for the advanced resources of each species; the rest if for the Golden Nuts and the 8 Evolution Items.

    this.requiredDreamBeasts = questData?.requiredDreamBeasts
      ? questData?.requiredDreamBeasts.length > 0
        ? questData.requiredDreamBeasts.map((beast) => new BigNumber(beast).toNumber())
        : [0]
      : [0]; // a list of integers that represent dream beasts needed to enter this quest. Each element goes from 1 to 6, the number of beasts of that species. The list can have from 0 to 10 elements (the dream species).

    this.requiredMaxedOutNfts = questData?.requiredMaxedOutNfts
      ? new BigNumber(questData.requiredMaxedOutNfts).toNumber()
      : 0; // from 1 to 6. The number of maxed out NFTs needed to enter this quest.
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }
}

export default QuestModel;
