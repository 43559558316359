import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import DarkMode from "./DarkMode";
import logodark from "../../assets/images/logo/logo_dark.png";
import logodark2x from "../../assets/images/logo/logo_dark@2x.png";
import { ConnectWalletButton } from "../connect-wallet-button";
import "./menu.css";

import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../../utils/environment.js";
import { useWeb3Context } from "../../contexts/Web3Context";

const Header = () => {
  const { pathname } = useLocation();
  const { isUsingKingdomChain } = useWeb3Context();

  const [usingKingdomChain, setUsingKingdomChain] = useState(false);

  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);

    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    if (
      !window.location.pathname.includes("kingdom-bridge") &&
      !window.location.pathname.includes("exit-bridge")
    ) {
      setUsingKingdomChain(isUsingKingdomChain);
    } else {
      setUsingKingdomChain(true);
    }
  }, [isUsingKingdomChain]);

  const isSticky = (e) => {
    try {
      const header = document.querySelector(".js-header");
      const scrollTop = window.scrollY;
      scrollTop >= 300 ? header.classList.add("is-fixed") : header.classList.remove("is-fixed");
      scrollTop >= 400 ? header.classList.add("is-small") : header.classList.remove("is-small");
    } catch (e) {}
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index, disabled) => {
    if (!disabled) {
      setActiveIndex(index);
    }
  };

  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className={usingKingdomChain ? "network right" : "network wrong"}>
        {usingKingdomChain
          ? "Connected!"
          : IS_PAUSED
          ? PAUSED_TITLE
          : "Please connect to the Kingdom Chain to navigate."}
      </div>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        className="logo-dark"
                        id="logo_header"
                        src={logodark}
                        srcSet={`${logodark2x}`}
                        alt="nft-gaming"
                      />
                    </Link>
                  </div>
                </div>
                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}>
                  <span></span>
                </div>
                <nav id="main-nav" className="main-nav" ref={menuLeft}>
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, index) => (
                      <li
                        key={index}
                        onClick={() => handleOnClick(index, data.disabled)} // Pass disabled property to handleOnClick function
                        className={`menu-item ${data.namesub ? "menu-item-has-children" : ""} ${
                          activeIndex === index ? "active" : ""
                        } ${data.disabled ? "disabled" : ""}`} // Add disabled class to gray out and make inactive
                      >
                        <Link to={data.links}>{data.name}</Link>
                        {data.namesub && (
                          <ul className={`sub-menu`}>
                            {data.namesub.map((submenu) => (
                              <li
                                key={submenu.id}
                                className={
                                  pathname === submenu.links
                                    ? "menu-item current-item"
                                    : `menu-item ${submenu.disabled ? "disabled" : ""}`
                                }
                              >
                                <Link to={submenu.links}>{submenu.sub}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
                {!IS_PAUSED && (
                  <div className="flat-search-btn flex">
                    <div className="sc-btn-top mg-r-25" id="site-header">
                      {/*  <Link to="/wallet-connect" className="sc-button header-slider style style-1 wallet fl-button pri-1"><span>Wallet connect
                                        </span></Link> */}
                      <ConnectWalletButton />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DarkMode />
    </header>
  );
};

export default Header;
