import resourceList from "./04-resources-full-list.json";

function nameToIndex(name) {
  const resourceIndex = resourceList.findIndex((resource) => resource === name);

  if (resourceIndex !== -1) {
    return resourceIndex;
  } else {
    return 0;
  }
}

function indexToName(index) {
  if (index === 999) {
    return "KOZI";
  }

  const resource = resourceList[index];

  if (resource) {
    return resource;
  } else {
    return 0;
  }
}

export { nameToIndex, indexToName };
