import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.groupWidth ? props.groupWidth : "300px")};
  margin-bottom: ${(props) => (props.isCollapsed ? "60px" : "20px")};

  @media (max-width: 767px) {
    margin-bottom: ${(props) => (props.isCollapsed ? "36px" : "20px")};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isCollapsed ? "85%" : props.groupWidth ? props.groupWidth : "300px")};
  height: ${(props) => (props.isCollapsed ? "30px" : "66px")};
  top: 0;
  border: 2px solid white;
  position: absolute;
  border-radius: ${(props) => (props.isCollapsed ? "6px" : "0px")};
  z-index: -1;

  p {
    font-family: "Metropolis", sans-serif;
    font-weight: 800;
    font-size: 18px;
    position: relative;
    bottom: ${(props) => (props.isCollapsed ? "0px" : "15px")};
  }
`;

export const TitleWrapperOverlayCenter = styled.div`
  width: 180px;

  width: ${(props) => (props.buttonCount === 4 || props.buttonCount === 3 ? "180px" : "280px")};

  z-index: -1;
  height: 30px;
  position: absolute;
  top: 50px;
  background: #14141f;
`;

export const TitleWrapperOverlayClickable = styled.div`
  width: 280px;
  z-index: -1;
  height: 30px;
  position: absolute;
  top: 0px;
  background: transparent;
  z-index: 10;
`;
