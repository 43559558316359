import Home01 from "./Home01";
import Register from "./Register";
import KingdomQuests from "./KingdomQuests";
import TheForge from "./TheForge";
import Deposit from "./Deposit";
import Stash from "./Stash";
import Withdraw from "./Withdraw";
import InventoryNfts from "./InventoryNfts";
import Balances from "./Balances";
import BctPool from "./BctPool";
import BuyEbct from "./BuyEbct";
import Marketplace from "./Marketplace";
import Discord from "./Discord";
import UpgradeNft from "./UpgradeNft";
import Halvings from "./Halvings";
import Mint from "./Mint";
import Collections from "./Collections";
import Beast from "./Beast";
import LinkAddress from "./LinkAddress";
import Claim from "./Claim";
import Masteries from "./Masteries";
import ProsperityWars from "./ProsperityWars";
import TravelSong from "./TravelSong";
import AddKingdomChain from "./AddKingdomChain";
import KingdomNobles from "./KingdomNobles";
import KoziPool from "./KoziPool";
import Whitepaper from "./Whitepaper";
import KingdomBridge from "./KingdomBridge";
import LateMigration from "./LateMigration";
import SmartContracts from "./SmartContracts";
import QuickStart from "./QuickStart";
import ExitBridge from "./ExitBridge";
import Focus from "./Focus";
import AllKingdomQuests from "./AllKingdomQuests";
import FurAndFortune from "./FurAndFortune";
import TrophiesAndTickets from "./TrophiesAndTickets";
import Gravedance from "./Gravedance";
import UnderworldLegacy from "./UnderworldLegacy";

const routes = [
  { path: "/", component: <Home01 /> },
  { path: "/register", component: <Register /> },
  { path: "/forge", component: <TheForge /> },
  { path: "/mint", component: <Mint /> },
  { path: "/collections", component: <Collections /> },
  { path: "/deposit", component: <Deposit /> },
  { path: "/stash", component: <Stash /> },
  { path: "/withdraw", component: <Withdraw /> },
  { path: "/kingdom-quests", component: <KingdomQuests /> },
  { path: "/beasts", component: <InventoryNfts /> },
  { path: "/balances", component: <Balances /> },
  { path: "/bct-pool", component: <BctPool /> },
  { path: "/buy-ebct", component: <BuyEbct /> },
  { path: "/marketplace", component: <Marketplace /> },
  { path: "/marketplace/:code", component: <Marketplace /> },
  { path: "/marketplace/:code/:id", component: <Marketplace /> },
  { path: "/halvings", component: <Halvings /> },
  { path: "/discord", component: <Discord /> },
  { path: "/late-migration", component: <LateMigration /> },

  { path: "/upgrade-nft/:id", component: <UpgradeNft /> },
  { path: "/beast/:id", component: <Beast /> },
  { path: "/link/:discordId/:address/", component: <LinkAddress /> },
  { path: "/claim", component: <Claim /> },

  { path: "/masteries", component: <Masteries /> },
  { path: "/prosperity-wars", component: <ProsperityWars /> },

  { path: "/travelsong", component: <TravelSong /> },
  { path: "/travelsong/:type/:id", component: <TravelSong /> },

  { path: "/add-kingdom-chain", component: <AddKingdomChain /> },
  { path: "/kingdom-nobles", component: <KingdomNobles /> },

  { path: "/kozi-pool", component: <KoziPool /> },
  { path: "/whitepaper", component: <Whitepaper /> },
  { path: "/kingdom-bridge", component: <KingdomBridge /> },
  { path: "/smart-contracts", component: <SmartContracts /> },
  { path: "/quick-start", component: <QuickStart /> },
  { path: "/exit-bridge", component: <ExitBridge /> },
  { path: "/focus", component: <Focus /> },
  { path: "/all-kingdom-quests", component: <AllKingdomQuests /> },
  { path: "/fur-and-fortune", component: <FurAndFortune /> },
  { path: "/trophies-and-tickets", component: <TrophiesAndTickets /> },
  { path: "/gravedance", component: <Gravedance /> },
  { path: "/underworld-legacy", component: <UnderworldLegacy /> },
];

export default routes;
