import React, { useState, useEffect } from "react";

import {
  ModalOverlay,
  ModalContent,
  Title,
  Description,
  ButtonContainer,
  GotItButton,
} from "./styles";

const UpgradesModal = ({
  title,
  description1 = "",
  description2 = "",
  description3 = "",
  link1 = null,
  link2 = null,
  link3 = null,
  isOpen = false,
  onClose = null,
}) => {
  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleCloseModal = (e) => {
    e.stopPropagation();

    if (onClose) {
      onClose();
    } else {
      setShowModal(false);
    }
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation(); // Stop the event from propagating up to ModalOverlay
  };

  const getDescription = (number) => {
    const link = eval(`link${number}`);
    const description = eval(`description${number}`);
    const highlight = number === 3;

    if (link) {
      return (
        <Description highlight={highlight}>
          {description.split("%link%")[0]}
          <a href={link} target="_blank">
            {link}
          </a>
          {description.split("%link%")[1]}
        </Description>
      );
    } else {
      if (description) {
        return <Description highlight={highlight}>{description}</Description>;
      } else {
        return null;
      }
    }
  };

  return (
    <>
      {showModal && (
        <ModalOverlay onClick={handleCloseModal}>
          <ModalContent onClick={handleModalContentClick}>
            <Title>{title}</Title>
            {description1 && getDescription(1)}
            {description2 && getDescription(2)}
            {description3 && getDescription(3)}
            <ButtonContainer>
              <GotItButton onClick={(e) => handleCloseModal(e)}>Got it!</GotItButton>
            </ButtonContainer>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default UpgradesModal;
