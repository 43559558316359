import React from "react";
import Header from "../components/header/Header";

import CollectionsScreen from "../components/collections-screen/CollectionsScreen";

import { useWeb3Context } from "../contexts/Web3Context";

const Collections = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🎎</h1>
                    <h1 className="heading text-center">Collections</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      High-level Stashers can mint their own Collections of ultra-powerful beasts.
                      Mouse Collections are available to Stashers level 9 and 10, while all the
                      others are available to Stashers level 10 only.
                    </h5>

                    <h5 className="sub-title help-center mg-bt-32">
                      Creating a collection mints 3 beasts for you. Expanding a collection that you
                      already own mints 5 beasts.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      When you create or expand a Collection, all minted beasts will share a trait
                      of your choice between them.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Having at least 1 collection beast in a squad unlocks high-level quests for
                      that squad. On top of that, Collections themselves work like an extra trait:
                      collection beasts synergize with other beasts just for belonging to the same
                      collection. That allows you to reach higher synergies, which in turn unlocks
                      even more high-level quests.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <CollectionsScreen />
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Collections;
