import styled from "styled-components";

const colors = {
  base: {
    action: "#ddd",
    zeroAddress: "#ddd",
    txHash: "#505",
    address: "#033",
    value: "#030",
    block: "#330",
  },
  hover: {
    action: "#ddd",
    zeroAddress: "#ddd",
    txHash: "#606",
    address: "#044",
    value: "#040",
    block: "#440",
  },
};

export const Container = styled.div`
  display: flex;
  margin-top: ${(props) => (props.forceContainerMarginTop ? props.forceContainerMarginTop : "0")};
`;

export const Text = styled.div`
  /*outline: 1px solid #fff;*/
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;

  position: relative;
  bottom: 4px;
  left: -1px;

  line-break: anywhere;
`;

export const Badge = styled.div`
  background-color: ${(props) =>
    props.isAction
      ? colors.base.action
      : props.isZeroAddress
      ? colors.base.zeroAddress
      : props.isTxHash
      ? colors.base.txHash
      : props.isAddress
      ? colors.base.address
      : props.isBlockNumber
      ? colors.base.block
      : colors.base.value};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  margin-left: 2px;
  position: relative;
  bottom: 4px;
  left: 3px;
  font-size: 12px;
  color: ${(props) => (props.isAction ? "#000" : props.isZeroAddress ? "#000" : "#fff")};

  cursor: ${(props) => (props.isAction ? "default" : props.isZeroAddress ? "default" : "pointer")};

  &:hover {
    background-color: ${(props) =>
      props.isAction
        ? colors.hover.action
        : props.isZeroAddress
        ? colors.hover.zeroAddress
        : props.isTxHash
        ? colors.hover.txHash
        : props.isAddress
        ? colors.hover.address
        : props.isBlockNumber
        ? colors.hover.block
        : colors.hover.value};
    color: ${(props) => (props.isAction ? "#000" : props.isZeroAddress ? "#000" : "#fff")};
  }
`;
