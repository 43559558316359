import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
`;

export const YellowHeading = styled.h5`
  color: #f9d71c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const GreenHeading = styled.h5`
  color: #00ff00;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`;

export const Button = styled.div`
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px 20px;
  text-align: center;
  width: 10%;
  min-width: 180px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &:active {
    background-color: #000;
    color: #fff;
  }
`;
