import React from "react";
import Header from "../components/header/Header";
import { Link } from "react-router-dom";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

const QuickStart = () => {
  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🎓</h1>
                    <h1 className="heading text-center">Quick Start</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      Fly over the basics and get started with the Kingdom in a few simple steps:
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 " style={{ color: "yellow" }}>
                      1.{" "}
                      <Link
                        to="/add-kingdom-chain"
                        className="btn-selector nolink"
                        style={{ textDecorationLine: "underline" }}
                      >
                        Connect
                      </Link>{" "}
                      to the Kingdom Chain.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      2.{" "}
                      <Link
                        to="/discord"
                        className="btn-selector nolink"
                        style={{ textDecorationLine: "underline" }}
                      >
                        Verify
                      </Link>{" "}
                      your blockchain address on Discord.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 small">
                      This step is optional, but highly recommended to enjoy the full experience of
                      the Kingdom.
                    </h5>

                    <h5 className="sub-title help-center mg-bt-32 " style={{ color: "yellow" }}>
                      3.{" "}
                      <Link
                        to="/kingdom-bridge"
                        className="btn-selector nolink"
                        style={{ textDecorationLine: "underline" }}
                      >
                        Get at least 0.1 Kozi
                      </Link>{" "}
                      to be able to use the blockchain.
                    </h5>

                    <h5 className="sub-title help-center mg-bt-32 " style={{ color: "yellow" }}>
                      4.{" "}
                      <Link
                        to="/register"
                        className="btn-selector nolink"
                        style={{ textDecorationLine: "underline" }}
                      >
                        Register
                      </Link>{" "}
                      to play Beast Kingdom.
                    </h5>

                    <h5 className="sub-title help-center mg-bt-32 " style={{ color: "yellow" }}>
                      5.{" "}
                      <Link
                        to="/marketplace/buy-beasts"
                        className="btn-selector nolink"
                        style={{ textDecorationLine: "underline" }}
                      >
                        Buy entry-level beasts
                      </Link>{" "}
                      in our P2P Marketplace.
                    </h5>

                    <h5 className="sub-title help-center mg-bt-32 " style={{ color: "yellow" }}>
                      6.{" "}
                      <Link
                        to="/marketplace/buy-beasts"
                        className="btn-selector nolink"
                        style={{ textDecorationLine: "underline" }}
                      >
                        Add beasts to a squad
                      </Link>{" "}
                      and pick a quest to start farming.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      That's it! Now, check out the tips below to get the most out of this.
                    </h5>

                    <h2 className="sub-title help-center mg-bt-16">Tips</h2>
                    <h3 className="sub-title help-center mg-bt-16">How to Farm:</h3>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 First, place beasts in a squad. That squad remains idle until you choose a
                      quest for it.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Once a beast is in a squad, a header will appear for that squad with a
                      question mark. Clicking on it will show you a list of quests and their
                      respective rewards.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 After 24 hours on a quest, you can conclude it and collect what you've
                      farmed. You can then choose the same or a different quest to re-enter.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      💠 If desired, you can leave your squad farming indefinitely. However, you
                      only collect what you've farmed when you choose to conclude the quest.
                    </h5>

                    <h3 className="sub-title help-center mg-bt-16">Transaction Costs:</h3>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Beast Kingdom is a full on-chain game. This means that everything you do is
                      a blockchain transaction and there are fees involved. Fees are paid in Kozi,
                      our "Gas" token. Most transactions on the Kingdom Chain cost less than $0.01.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Some players let their squads farm for extended periods, like two or three
                      weeks, before collecting. Others, with more funds at the moment, prefer to go
                      faster to utilize what they've farmed and upgrade their beasts.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      💠 This cycle of farming and evolution is what takes a player to the top.
                      Everyone will have their pace based on strategies and the time dedicated to
                      the game.
                    </h5>

                    <h3 className="sub-title help-center mg-bt-16">Early Game Strategies:</h3>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Focus on synergies: before anything else, it's essential to focus on
                      synergies. You get synergy by having more than one instance of the same trait
                      in a squad, such as many FANCY beasts.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Increase the number of beasts that fit in your squads. You can reach
                      configurations so powerful that grant you a whopping 1440% farming bonus. It's
                      massive.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Having repeated traits in one beast is great, as it sinergizes with itself!
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Having more beasts in one squad significantly boosts your gameplay.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      💠 Remember, the maximum for each trait is 6; having 7 of the same won't
                      increase your bonus.
                    </h5>

                    <h3 className="sub-title help-center mg-bt-16">Squad Quests:</h3>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 The higher your synergy, the more quests become available for that squad.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 The initial quests might seem basic, but there are some excellent quests
                      down the line.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 The best way to strategize is by checking the upgrades screen to see the
                      cost of each upgrade you want. Then, try to find quests that offer those
                      rewards and target them.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      💠 The{" "}
                      <Link
                        to="/all-kingdom-quests"
                        className="btn-selector nolink"
                        style={{ textDecorationLine: "underline" }}
                      >
                        All Kingdom Quests
                      </Link>{" "}
                      page features a huge list of all the existing quests, even those you might not
                      see right now.
                    </h5>

                    <h3 className="sub-title help-center mg-bt-16">Pro Tips:</h3>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Evolve your beasts and squads as quickly as possible for more significant
                      game gains. Growth is exponential, so keeping resources idle is detrimental.
                      Farm to your maximum potential at every moment (unless you have a master plan,
                      of course).
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Sell the surplus in the marketplace and use eBCT to purchase what you need,
                      accelerating your growth.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Use the Forge to convert resources you don't need. You can even convert
                      them directly to eBCT.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Upgrading a beast that isn't in a squad incurs lower blockchain fees
                      compared to upgrading one that is in a squad. If you're planning to upgrade
                      multiple attributes of a beast, it may be cost-effective to remove it from the
                      squad beforehand.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 If you wish to farm Golden Nuts and Evolution Items (such as Gene Therapy,
                      Beast Boxes and DNA), ensure that your beasts are trained to do so. Even if
                      you enter a quest that has one of those resources, your squad won't farm any
                      if your beasts aren't skilled in collecting them. Visit the upgrades page to
                      learn how to enhance your beasts' abilities in farming these items.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Buying Kozi through the Kozi Pool is much cheaper than converting USDC to
                      Kozi directly at the Kingdom Bridge. At the Kozi Pool, you'll pay Kingdom
                      Chain Fees (in Kozi), while at the Kingdom Bridge, you'll pay BNB Chain Fees
                      (in BNB).
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Every hour, the Kingdom does its best to drive Kozi's price toward the
                      Target Price. If the current price is too high for you, keep an eye out for
                      the price to drop—because it will.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Check the Kingdom Nobles page often to stay ahead of the competition.
                      There, you can see the top players in the Kingdom and how much Kozi they have
                      accumulated. Nobles gain frequent USDC rewards and are eligible for special
                      events.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-16">
                      💠 Purchasing Kozi through the Kozi Pool comes with an added layer of security
                      against sudden price fluctuations. Should the price of Kozi vary by more than
                      1% from the time of your purchase request to the transaction confirmation, the
                      system automatically cancels the transaction. Note, this safeguard is
                      exclusive to the Kozi Pool and does not apply to transactions made via the
                      Kingdom Bridge.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
                  <h5 className="sub-title help-center mg-bt-32 "></h5>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default QuickStart;
