import React from "react";
import { Tooltip } from "react-tooltip";

import {
  Container,
  OnQuestContainer,
  ActionButton,
  StartButtonContainer,
  CollectContainer,
} from "./styles";
import StartQuestIcon from "../../../../assets/images/svg/play-icon.svg";

import { realQuestId } from "../../../../utils/quests";

import { useKingdomQuestsContext } from "../../../../contexts/KingdomQuestsContext";

const QuestAction = ({ squad, quest, currentBlockNumber }) => {
  const { startQuest, finishQuest } = useKingdomQuestsContext();

  const endsAt = squad.questStartedAt + 8640;

  return (
    <Container>
      <ActionButton disabled={squad.questStartedAt > 0 && endsAt > currentBlockNumber}>
        {squad.questStartedAt === 0 && (
          <>
            <a
              data-tooltip-id="ac1"
              data-tooltip-content={`Embark on Quest ${realQuestId(quest.id)}`}
              data-tooltip-place="top"
            >
              <StartButtonContainer onClick={() => startQuest(squad.id, quest.id)}>
                <img src={StartQuestIcon} />
              </StartButtonContainer>
            </a>
            <Tooltip id="ac1" style={{ fontSize: "12px", fontWeight: 600 }} />
          </>
        )}

        {squad.questStartedAt > 0 && endsAt > currentBlockNumber && (
          <>
            <a
              data-tooltip-id="ac2"
              data-tooltip-content={`The squad must complete at least 24 hours of Quest ${realQuestId(
                quest.id
              )}`}
              data-tooltip-place="top"
            >
              <OnQuestContainer>
                <img src="assets/images/quest/pickaxe.png" />
              </OnQuestContainer>
            </a>
            <Tooltip id="ac2" style={{ fontSize: "12px", fontWeight: 600 }} />
          </>
        )}

        {squad.questStartedAt > 0 && currentBlockNumber > endsAt && (
          <>
            <a
              data-tooltip-id="ac3"
              data-tooltip-content={`Finish Quest ${realQuestId(
                quest.id
              )} and collect what this squad farmed`}
              data-tooltip-place="top"
            >
              <CollectContainer onClick={() => finishQuest(squad.id)}>
                <img src="assets/images/quest/present.png" alt="Collect" />
              </CollectContainer>
            </a>
            <Tooltip id="ac3" style={{ fontSize: "12px", fontWeight: 600 }} />
          </>
        )}
      </ActionButton>
    </Container>
  );
};

export default QuestAction;
