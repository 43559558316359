import styled, { css } from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 8px;
  justify-content: center;
`;

export const BuyButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  background: linear-gradient(0deg, rgba(37, 58, 75, 1), rgba(70, 115, 150, 1));
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;

  p {
    margin-bottom: 6px;
    width: 140px;
    font-family: "Metropolis", sans-serif;
    font-size: 13px;
    line-height: 1.2;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}

  &:hover {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  &:active {
    outline: none;
    border: none;
    background: linear-gradient(180deg, rgba(45, 45, 45, 1), rgba(56, 56, 56, 1));
    border: ${(props) => (props.disabled ? "none" : "1px solid rgba(255,255,255,0.1)")};
    color: #fff;
  }

  &:focus {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }
`;

export const BuyWithKoziButton = styled.button`
  width: 50%;
  padding: 8px;
  background: linear-gradient(180deg, #8085c2, #471069);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 200px;

  h1 {
    margin-bottom: 6px;
    font-family: "Metropolis", sans-serif;
    font-size: 13px;
    line-height: 1.2;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}

  &:hover {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  &:active {
    outline: none;
    border: none;
    background: linear-gradient(180deg, rgba(45, 45, 45, 1), rgba(56, 56, 56, 1));
    border: ${(props) => (props.disabled ? "none" : "1px solid rgba(255,255,255,0.1)")};
    color: #fff;
  }

  &:focus {
    outline: ${(props) => (props.disabled ? "none" : "3px solid rgba(255,255,255,0.1)")};
    border: none;
    color: #fff;
  }

  p {
    font-family: "Metropolis", sans-serif !important;
    font-weight: 600;
    letter-spacing: -0.02em;
    font-size: 15px;
    line-height: 1.2;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: ${(props) => (!props.hasEnough ? "#fff" : "#fff")};
  }
`;

export const PurchaseCostContainer = styled.div`
  display: flex;
  width: 200px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const PurchaseCostContainer2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;
  top: 4px;
  margin-bottom: 4px;
`;
