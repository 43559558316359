import { ENV } from "../utils/environment";
import config from "../config/config";

import React, { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useWeb3Context } from "./Web3Context";
import { usePlayerContext } from "./PlayerContext";
import BigNumber from "bignumber.js";

export const UpgradesContext = createContext({});

export const useUpgradesContext = () => {
  return useContext(UpgradesContext);
};

export const UpgradesProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isReady, execFunction, consult, stableToTargetKozi } = useWeb3Context();
  const { fetchPlayerData } = usePlayerContext();
  const [currentlySelectedPerkIndex, setCurrentlySelectedPerkIndex] = useState(null);
  const [currentlySelectedMasteryIndex, setCurrentlySelectedMasteryIndex] = useState(null);

  const getAllowanceObject = (tokenContractName, addressToAllow, allowedContractName) => {
    return {
      tokenContractName,
      addressToAllow,
      allowedContractName,
      allowedAssetDescription: `${tokenContractName} in your wallet`,
      successMessage: `Allowance given to ${allowedContractName}`,
    };
  };

  const buyRankUp = async (nftId, howMany) => {
    if (!isReady) return;

    await execFunction({
      contractName: "Upgrades",
      functionName: "buyRankUp",
      functionParams: [nftId, howMany],
      successMessage: "You have successfully ranked up!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const buyAgilityUp = async (nftId, howMany, withBusd, busdValue = 0) => {
    if (!isReady) return;

    const execObj = {
      contractName: "Upgrades",
      functionName: "buyAgilityUp",
      functionParams: [nftId, howMany, withBusd],
      successMessage: "You have successfully upgraded this beast's agility!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    };

    if (withBusd) {
      execObj.koziAmount = stableToTargetKozi(busdValue).times(1e18).toFixed(0);
    }

    await execFunction(execObj);
  };

  const buySkillUp = async (nftId, skillIndex, howMany, withBusd, busdValue = 0) => {
    if (!isReady) return;

    console.log(
      `UpgradesContext::buySkillUp::nftId: ${nftId}, skillIndex: ${skillIndex}, howMany: ${howMany}, withBusd: ${withBusd}, busdValue: ${busdValue}`
    );

    const execObj = {
      contractName: "Upgrades",
      functionName: "buySkillLevel",
      functionParams: [nftId, skillIndex, howMany, withBusd],
      successMessage: "You have successfully upgraded this beast's skill!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    };

    if (withBusd) {
      execObj.koziAmount = stableToTargetKozi(busdValue).times(1e18).toFixed(0);
    }

    await execFunction(execObj);
  };

  //SOL: function improveBaseFarmWithNuts(uint256 nftId, uint256 howManyNuts)
  const buyBaseFarmUp = async (nftId, howManyNuts) => {
    if (!isReady) return;

    await execFunction({
      contractName: "DnaManager",
      functionName: "improveBaseFarmWithNuts",
      functionParams: [nftId, howManyNuts],
      successMessage: "You have successfully improved this beast's base farm!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const buyExtraSlot = async (squadId, withBusd, busdValue = 0) => {
    if (!isReady) return;

    const execObj = {
      contractName: "Upgrades",
      functionName: "buyExtraSlot",
      functionParams: [squadId, withBusd],
      successMessage: "You have successfully acquired an extra beast slot for your squad!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    };

    if (withBusd) {
      execObj.koziAmount = stableToTargetKozi(busdValue).times(1e18).toFixed(0);
    }

    await execFunction(execObj);
  };

  const buyExtraSquad = async (withBusd, busdValue = 0) => {
    if (!isReady) return;

    const execObj = {
      contractName: "Upgrades",
      functionName: "buyExtraSquad",
      functionParams: [withBusd],
      successMessage: "You have successfully acquired an extra squad!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    };

    if (withBusd) {
      execObj.koziAmount = stableToTargetKozi(busdValue).times(1e18).toFixed(0);
    }

    await execFunction(execObj);
  };

  const buyTraitReroll = async (nftId, chosenTrait) => {
    if (!isReady) return;

    await execFunction({
      contractName: "TraitReroller",
      functionName: "traitReroll",
      functionParams: [nftId, chosenTrait],
      successMessage:
        "You have successfully rerolled this beast's traits! Please reload the page if it doesn't update automatically.",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const buyReassemble = async (nftId, chosenTrait1, chosenTrait2) => {
    if (!isReady) return;

    await execFunction({
      contractName: "TraitReroller",
      functionName: "reassemble",
      functionParams: [nftId, chosenTrait1, chosenTrait2],
      successMessage: "You have successfully reassembled this beast!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const buyAddTrait = async (nftId, chosenTrait) => {
    if (!isReady) return;

    await execFunction({
      contractName: "DnaManager",
      functionName: "addExtraTrait",
      functionParams: [nftId, chosenTrait],
      successMessage: "You have successfully added a new trait to this beast!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const buyTransmute = async (nftId, chosenTrait1, chosenTrait2, chosenTrait3) => {
    if (!isReady) return;

    await execFunction({
      contractName: "TraitReroller",
      functionName: "transmute",
      functionParams: [nftId, chosenTrait1, chosenTrait2, chosenTrait3],
      successMessage: "You have successfully transmuted this beast!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const buyIntegration = async (nftId, sacNftsIds, transform = false) => {
    if (!isReady) return;

    const usePowerMangoes = sacNftsIds[0] === 999999008 || sacNftsIds[1] === 999999008;
    if (usePowerMangoes) {
      // let's remove the power mangoes from the list of nfts to integrate
      sacNftsIds = sacNftsIds.filter((nftId) => nftId !== 999999008);
    }

    await execFunction({
      contractName: "DnaManager",
      functionName: "integrate",
      functionParams: [[nftId, ...sacNftsIds], usePowerMangoes, transform],
      successMessage: "You have successfully integrated these beasts!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });

    // also add other forbidden rules (must be of the same type and rarity, and can't be on quest)
    const requiredNftFeatures = {
      type_: location.state.requiredNftFeatures.type_,
      rarity: location.state.requiredNftFeatures.rarity + 1,
      notOnQuest: true,
    };

    if (location.state?.hasOwnProperty("selectedNfts")) {
      navigate(window.location.pathname, {
        state: {
          ...location.state,
          selectedNfts: [],
          forbiddenNftIds: [location.state.forbiddenNftIds[0]],
          requiredNftFeatures,
        },
        replace: true, // replacing the current entry in the history stack.
      });

      await fetchPlayerData();
      window.location.reload();
    }
  };

  const buyEvolution = async (nftId, chosenTrait, newName, newImageUrl) => {
    if (!isReady) return;

    await execFunction({
      contractName: "DnaManager",
      functionName: "evolve",
      functionParams: [nftId, chosenTrait, newName, newImageUrl],
      successMessage: "You have successfully evolved this beast!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const buyExtraction = async (nftId, chosenTrait, chosenSquadId, withBusd, busdValue = 0) => {
    if (!isReady) return;

    const execObj = {
      contractName: "Upgrades",
      functionName: "extractTrait",
      functionParams: [nftId, chosenTrait, chosenSquadId, withBusd],
      successMessage: "You have successfully extracted a trait!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: "/kingdom-quests",
    };

    if (withBusd) {
      execObj.koziAmount = stableToTargetKozi(busdValue).times(1e18).toFixed(0);
    }

    await execFunction(execObj);
  };

  const buyDeification = async (nftId) => {
    if (!isReady) return;

    await execFunction({
      contractName: "HighUpgrades",
      functionName: "deify",
      functionParams: [nftId],
      successMessage: "You have successfully deified this beast!",
      errorList: [
        {
          reason: "4",
          message:
            "Forbidden: Dragons require Stasher Level 9. All other Dream Beasts require Stasher Level 10.",
        },
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  // @SOL:  function buyMastery(uint256 masteryIndex, uint256 levels, bool useKozi)
  const buyMastery = async (masteryIndex, levels, useKozi = false) => {
    if (!isReady) return;

    const func = {
      contractName: "HighUpgrades",
      functionName: "buyMastery",
      functionParams: [masteryIndex, levels, useKozi],
      successMessage: "You have successfully increased you Mastery!",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
        {
          reason: "Missing Kozi",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: "/masteries",
    };

    if (useKozi) {
      const busdValue = BigNumber(1e18).times(levels).times(10).toFixed(0);
      func.koziAmount = stableToTargetKozi(busdValue).times(1e18).toFixed(0);
    }

    await execFunction(func);
  };

  const depositBct = async (amount) => {
    if (!isReady) return;

    await execFunction({
      contractName: "Upgrades",
      functionName: "deposit",
      functionParams: [amount],
      allowance: getAllowanceObject("BCT", config[ENV].Upgrades.address, "Upgrades"),
      successMessage: "You have successfully deposited BCT!",
      errorList: [
        {
          reason: "1",
          message: "The minimum amount for deposits is 11 BCT.",
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const getCollectionNames = async (collectionIds) => {
    if (collectionIds.length === 0) return [];
    if (collectionIds.length === 1 && collectionIds[0] === 0) return [];

    const collectionNames = await consult({
      contractName: "DnaManager",
      functionName: "getCollectionNames",
      functionParams: [collectionIds],
    });

    return collectionNames;
  };

  const createCollection = async (
    collectionName,
    beastNames,
    type_,
    rarity,
    trait,
    beastImages,
    withBusd,
    koziValue = 0
  ) => {
    if (!isReady) return;

    const execObj = {
      contractName: "DnaManager",
      functionName: "createCollection",
      functionParams: [collectionName, beastNames, type_, rarity, trait, beastImages, withBusd],
      successMessage: "You have successfully minted a Collection!",
      errorList: [
        {
          reason: "Transaction reverted: function call to a non-contract account",
          message: "Only in Season 2! Please wait for the next season to start.",
        },
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    };

    if (withBusd) {
      execObj.koziAmount = BigNumber(koziValue).toFixed(0);
    }

    await execFunction(execObj);
  };

  const expandCollection = async (
    collectionId,
    beastNames,
    type_,
    rarity,
    trait,
    beastImages,
    withBusd,
    koziValue = 0
  ) => {
    if (!isReady) return;

    const execObj = {
      contractName: "DnaManager",
      functionName: "expandCollection",
      functionParams: [collectionId, beastNames, type_, rarity, trait, beastImages, withBusd],
      successMessage: "You have successfully expanded a Collection!",
      errorList: [
        {
          reason: "Transaction reverted: function call to a non-contract account",
          message: "Only in Season 2! Please wait for the next season to start.",
        },
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    };

    if (withBusd) {
      execObj.koziAmount = BigNumber(koziValue).toFixed(0);
    }

    await execFunction(execObj);
  };

  return (
    <UpgradesContext.Provider
      value={{
        buyRankUp,
        buyAgilityUp,
        buySkillUp,
        buyBaseFarmUp,
        buyExtraSlot,
        buyExtraSquad,
        buyTraitReroll,
        buyReassemble,
        buyAddTrait,
        buyIntegration,
        buyEvolution,
        buyExtraction,
        depositBct,
        currentlySelectedPerkIndex,
        setCurrentlySelectedPerkIndex,
        currentlySelectedMasteryIndex,
        setCurrentlySelectedMasteryIndex,
        getCollectionNames,
        createCollection,
        expandCollection,
        buyDeification,
        buyTransmute,
        buyMastery,
      }}
    >
      {children}
    </UpgradesContext.Provider>
  );
};
