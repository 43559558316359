export const Data = [
  {
    price: "16290908573353606",
    createdAt: "2023-08-04T19:04:50.211Z",
  },
  {
    price: "16504692858808718",
    createdAt: "2023-08-31T18:44:32.431Z",
  },
];
