import styled, { keyframes, css } from "styled-components";
import SynergyOverlay from "../../assets/images/squads-card/SynergyOverlay.png";

const colorCycle = keyframes`
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
`;

const shine = keyframes`
  0% {
    filter: brightness(1);
  }  
  50% {
    filter: brightness(3.2);
  }
  100% {
    filter: brightness(1);
  }
`;

const colorize = keyframes`
  0% {
    filter: invert(10%) sepia(9%) saturate(5000%) hue-rotate(280deg) brightness(104%) contrast(104%);
  }
  20% {
    filter: invert(0) sepia(0) saturate(0) hue-rotate(120deg) brightness(1) contrast(1);
  }
  80% {
    filter: invert(0) sepia(0) saturate(0) hue-rotate(120deg) brightness(1) contrast(1);
  }
  100% {
    filter: invert(10%) sepia(9%) saturate(5000%) hue-rotate(280deg) brightness(104%) contrast(104%);
  }
`;

export const GridItem = styled.div`
  background-color: ${(props) => props.backgroundColor};
  outline: ${(props) => (props.extraOutline ? `2px solid ${props.extraOutlineColor}` : "none")};
  outline-offset: 2px;
  border: ${(props) => (props.borderImage ? "none" : `3px solid ${props.borderColor}`)};
  border-image: ${(props) =>
    props.hasCustomBorder ? `url('${props.borderImage}') 0 fill` : "none"};
  border-image-outset: 3px;
  width: ${(props) => (props.scale ? `${200 * props.scale}px` : `200px`)};
  height: ${(props) => (props.scale ? `${250 * props.scale}px` : `250px`)};
  border-radius: ${(props) => (props.scale ? `${6 * props.scale}px` : `6px`)};
  cursor: ${(props) => (props.isClickable ? "pointer" : "auto")};

  // only add the @media block if && !props.lockSize:

  ${(props) =>
    !props.lockSize &&
    `
    @media (max-width: 450px) {
      width: ${props.scale ? `${150 * props.scale}px` : `150px`};
      height: ${props.scale ? `${187 * props.scale}px` : `187px`};
    }
  `}
`;

export const ClickableOverlay = styled.div`
  position: absolute;
  width: ${(props) => (props.scale ? `${200 * props.scale}px` : `200px`)};
  height: ${(props) => (props.scale ? `${250 * props.scale}px` : `250px`)};

  z-index: 10;
  background: transparent;
  cursor: pointer;

  ${(props) =>
    !props.lockSize &&
    `
    @media (max-width: 450px) {
      width: ${String(props.scale ? `${150 * props.scale}px` : `150px`)};
      height: ${String(props.scale ? `${187 * props.scale}px` : `187px`)};
    }
  `}
`;

export const HoverRemoveNft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.9);
  display: none;

  .div-text {
    position: absolute;
    bottom: 10%;
    width: 100%;
  }

  .div-img {
    padding-top: 25%;
    width: 100%;
    text-align: center;
  }

  .nft-remove-text {
    font-size: 20px;
    font-weight: 600;
    color: #5b597f;
    text-align: center;
  }

  .nft-remove-image {
    height: 100px;
    rotate: 45deg;
  }
`;

export const ImageEmptyModel = styled.div`
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.background !== "#000000" ? 1 : 0.5)};
  background: ${(props) => (props.background ? props.background : "#000000")};
  cursor: ${(props) => (props.isClickable || props.background !== "#000000" ? "pointer" : "auto")};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid white;
  border-radius: 4px;

  .nft-dummy {
    font-size: ${(props) => (props.scale ? `${22 * props.scale}px` : `22px`)};
    color: #ffffff;
    width: 100%;
    bottom: ${(props) => (props.scale ? `${10 * props.scale}px` : `10px`)};
  }

  span {
    top: ${(props) => (props.scale ? `${50 * props.scale}px` : `50px`)};
  }

  img {
    width: ${(props) => (props.scale ? `${100 * props.scale}px` : `100px`)};
    padding-top: ${(props) => (props.scale ? `${14 * props.scale}px` : `14px`)};
  }

  p {
    font-family: "Metropolis", sans-serif;
    font-size: ${(props) => (props.scale ? `${24 * props.scale}px` : `24px`)};
    line-height: 1.2em;
    font-weight: 800;
    margin-top: ${(props) => (props.scale ? `${14 * props.scale}px` : `14px`)};
  }

  @media (max-width: 450px) {
    .nft-dummy {
      font-size: ${(props) => (props.scale ? `${18 * props.scale}px` : `18px`)};
    }

    img {
      width: ${(props) => (props.scale ? `${80 * props.scale}px` : `80px`)};
      padding-top: ${(props) => (props.scale ? `${10 * props.scale}px` : `10px`)};
    }

    p {
      font-size: ${(props) => (props.scale ? `${24 * props.scale}px` : `24px`)};
      margin-top: ${(props) => (props.scale ? `${8 * props.scale}px` : `10px`)};
    }

    span {
      top: ${(props) => (props.scale ? `${40 * props.scale}px` : `40px`)};
    }
`;

export const NftId = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  position: relative;
  font-size: ${(props) => (props.scale ? `${10 * props.scale}px` : `10px`)};
  font-weight: bold;
  color: ${(props) => (props.nftId === "NEW" ? `#ff0` : `#fff`)};
  height: 20%;
  text-align: center;
  right: 1px;

  ${(props) =>
    !props.lockSize &&
    `
  @media (max-width: 450px) {
    font-size: ${String(props.scale ? `${8 * props.scale}px` : `8px`)};
  }
  `}
`;

export const QuestTag = styled.div`
  font-family: "Metropolis", sans-serif;
  letter-spacing: -0.06em;
  position: absolute;
  z-index: 3;
  font-size: ${(props) => (props.scale ? `${9 * props.scale}px` : `9px`)};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  text-align: center;
  left: 50%;
  border-radius: 10px;
  border: ${(props) => (props.color ? `1px solid ${props.color}` : `1px solid #ffffff`)};
  background-color: rgba(0, 0, 0, 0.7);
  transform: translate(-50%);
  width: ${(props) => (props.scale ? `${64 * props.scale}px` : `64px`)};
  height: ${(props) => (props.scale ? `${13 * props.scale}px` : `13px`)};
  bottom: ${(props) => (props.scale ? `${-58 * props.scale}px` : `-58px`)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    !props.lockSize &&
    `
    @media (max-width: 450px) {
      font-size: ${String(props.scale ? `${8 * props.scale}px` : `8px`)};
      bottom: ${String(props.scale ? `${-44 * props.scale}px` : `-44px`)};
    }
    `}
`;

export const SquadTag = styled.div`
  font-family: "Metropolis", sans-serif;
  letter-spacing: -0.06em;
  position: absolute;
  z-index: 3;
  font-size: ${(props) => (props.scale ? `${9 * props.scale}px` : `9px`)};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  text-align: center;
  left: 50%;
  border-radius: 10px;
  border: ${(props) => (props.color ? `1px solid ${props.color}` : `1px solid #ffffff`)};
  background-color: rgba(0, 0, 0, 0.7);
  transform: translate(-50%);
  width: ${(props) => (props.scale ? `${64 * props.scale}px` : `64px`)};
  height: ${(props) => (props.scale ? `${13 * props.scale}px` : `13px`)};
  bottom: ${(props) =>
    props.scale
      ? props.isOnQuest
        ? `${-42 * props.scale}px`
        : `${-58 * props.scale}px`
      : `-58px`};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    !props.lockSize &&
    `
    @media (max-width: 450px) {
      font-size: ${String(props.scale ? `${8 * props.scale}px` : `8px`)};
      bottom: ${String(
        props.scale
          ? props.isOnQuest
            ? `${-30 * props.scale}px`
            : `${-44 * props.scale}px`
          : `-44px`
      )};
    }
    `}
`;

export const NftName = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  font-size: ${(props) => (props.scale ? `${12 * props.scale}px` : `12px`)};
  color: ${(props) =>
    props.nftId === "NEW" ? `#ff0` : props.isPromo ? `#0ff` : props.isAwakened ? `#f7f` : `#fff`};
  height: 40%;
  text-align: center;
  top: ${(props) => (props.scale ? `${3 * props.scale + 1}px` : "3px")};

  ${(props) =>
    !props.lockSize &&
    `
    @media (max-width: 450px) {
      font-size: ${String(props.scale ? `${9.5 * props.scale}px` : `9.5px`)};
      top: ${String(props.scale ? `${3.5 * props.scale}px` : `3.5px`)};
    }
    `}
`;

export const NutsAndEvoContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 18%;
  top: ${(props) =>
    props.scale
      ? props.scale <= 0.5
        ? `${31 * props.scale - 0.5}px`
        : `${31 * props.scale}px`
      : `31px`};

  font-size: 13.5px;
  color: #ffffff;

  ${(props) =>
    !props.lockSize &&
    `
    @media (max-width: 450px) {
      top: ${String(props.scale ? `${23 * props.scale}px` : `23px`)}
    }
    `}
`;

export const FarmContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.04em;
  position: relative;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: "spread-around";

  top: ${(props) =>
    props.scale
      ? props.scale <= 0.5
        ? `${2 * props.scale + 1}px`
        : `${2 * props.scale}px`
      : `2px`};

  ${(props) =>
    !props.lockSize &&
    `
        @media (max-width: 450px) {
          top: ${String(
            props.scale
              ? props.scale <= 0.5
                ? `${1 * props.scale + 1}px`
                : `${1 * props.scale}px`
              : `1px`
          )};
        }
        `}
`;

export const BaseFarm = styled.div`
  position: relative;
  font-size: ${(props) => (props.scale ? `${14 * props.scale}px` : `14px`)};
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  width: ${(props) => (props.scale ? `${87 * props.scale}px` : `87px`)};
  top: ${(props) => (props.scale ? `${5 * props.scale}px` : `5px`)};
  left: ${(props) => (props.scale ? `${4 * props.scale}px` : `4px`)};

  ${(props) =>
    !props.lockSize &&
    `
  @media (max-width: 450px) {
    font-size: ${String(props.scale ? `${11 * props.scale}px` : `11px`)};
    left: ${String(props.scale ? `${-7 * props.scale}px` : `-7px`)};
  }`}
`;

export const FarmPerBlock = styled.div`
  position: relative;
  font-size: ${(props) => (props.scale ? `${14 * props.scale}px` : `14px`)};
  font-weight: bold;
  color: ${({ isDifferent, isMaxedOut }) =>
    isMaxedOut ? "#f9f" : isDifferent ? "#00ff00" : "#ffffff"};
  text-align: center;
  width: ${(props) => (props.scale ? `${87 * props.scale}px` : `87px`)};
  top: ${(props) => (props.scale ? `${5 * props.scale}px` : `5px`)};
  right: ${(props) => (props.scale ? `${-20 * props.scale}px` : `-20px`)};

  text-shadow: ${(props) =>
    props.isDifferent
      ? "-0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333, 0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333"
      : "none"};

  ${(props) =>
    !props.lockSize &&
    `
  @media (max-width: 450px) {
    font-size: ${String(props.scale ? `${11 * props.scale}px` : `11px`)};
    left: ${String(props.scale ? `${-16 * props.scale}px` : `-16px`)};
  }`}
`;

const shimmerAnimation = keyframes`
  0% {
  }
  50% {
    opacity: 0.3;
  }
  100% {
  }
`;

export const CollectionOverlay = styled.div`
  position: absolute;
  z-index: 3;
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  text-align: center;
  left: 49.5%;
  border-radius: 10px;
  background-color: "transparent";
  transform: translate(-50%);
  width: ${(props) => (props.scale ? `${14 * props.scale}px` : `14px`)};
  height: ${(props) => (props.scale ? `${14 * props.scale}px` : `14px`)};
  top: ${(props) => (props.scale ? `${5 * props.scale}px` : `5px`)};
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${shimmerAnimation} 1.5s linear infinite;

  ${(props) =>
    !props.lockSize &&
    `
  @media (max-width: 450px) {
    width: ${String(props.scale ? `${10 * props.scale}px` : `10px`)};
    height: ${String(props.scale ? `${10 * props.scale}px` : `10px`)};
  }
  `}
`;

export const CollectionGlow = styled.div`
  position: relative;
  display: flex;
  background: "#ffe176";
  box-shadow: "0px 0px 3px 1px #ffe176";
  width: ${(props) => (props.scale ? `${12 * props.scale}px` : `12px`)};
  height: ${(props) => (props.scale ? `${16 * props.scale}px` : `16px`)};
  border-radius: 50%;

  ${(props) =>
    !props.lockSize &&
    `
  @media (max-width: 450px) {
    width: ${String(props.scale ? `${9 * props.scale}px` : `9px`)};
    height: ${String(props.scale ? `${12 * props.scale}px` : `12px`)};
  }
  `}

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.3;
    box-shadow: inset 0px 0px 3px 2px #ffe176;
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    background: url(${SynergyOverlay}) no-repeat center;
    background-size: contain;
    top: -3px;
    left: -3px;
    opacity: 0.2;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    z-index: 3;
    animation: ${shimmerAnimation} 1.5s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  img {
    position: relative;
    z-index: 1;
  }
`;

export const Star = styled.img`
  position: relative;
  zindex: 99;
  top: ${(props) =>
    props.scale
      ? props.scale <= 0.5
        ? `${-2 * props.scale + 1.5}px`
        : `${-2 * props.scale}px`
      : `-2px`};
  right: ${(props) => (props.scale ? `${1 * props.scale}px` : `1px`)};
`;

export const BottomDataContainer = styled.div`
  position: relative;
  height: 50%;
`;

export const DeadStamp = styled.img`
  position: absolute;
  zindex: 99;
  width: ${(props) => (props.scale ? `${110 * props.scale}px` : `110px`)};
  top: ${(props) => (props.scale ? `${44 * props.scale}px` : `44px`)};
  left: ${(props) => (props.scale ? `${44 * props.scale}px` : `44px`)};

  // shadow:
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1));
`;

export const glow = keyframes`
    from {
      filter: brightness(0) saturate(100%) invert(91%) sepia(21%) saturate(5559%) hue-rotate(358deg)
    brightness(104%) contrast(106%) opacity(40%) blur(1px);
    }
    to {
      filter: brightness(0) saturate(100%) invert(80%) sepia(35%) saturate(5559%) hue-rotate(350deg) brightness(105%) contrast(103%) opacity(40%) blur(1px);
    }
  }
`;

export const FireOverlay = styled.img`
  position: absolute;
  zindex: 99;
  width: ${(props) => (props.scale ? `${204 * props.scale}px` : `204px`)};
  height: ${(props) => (props.scale ? `${254 * props.scale}px` : `254px`)};
  transform: translate(-2px, -2px);
  animation: ${glow} 1s infinite alternate;
  pointer-events: none;

  ${(props) =>
    !props.lockSize &&
    `
  @media (max-width: 450px) {
    width: ${String(props.scale ? `${150 * props.scale}px` : `150px`)};
    height: ${String(props.scale ? `${187 * props.scale}px` : `187px`)};
  }`}
`;

export const RevivedOverlayContainer = styled.div`
  position: absolute;
  zindex: 99;
  width: ${(props) => (props.scale ? `${18 * props.scale}px` : `18px`)};
  height: ${(props) => (props.scale ? `${18 * props.scale}px` : `18px`)};
  transform: ${(props) =>
    props.scale
      ? `translate(${90 * props.scale}px, ${-11 * props.scale}px)`
      : `translate(90px, -11px)`};

  display: flex;

  ${(props) =>
    !props.lockSize &&
    css`
      @media (max-width: 450px) {
        width: ${String(props.scale ? `${18 * props.scale}px` : `18px`)};
        height: ${String(props.scale ? `${18 * props.scale}px` : `18px`)};
        transform: translate(${64 * props.scale}px, -8px);
        display: flex;
      }
    `}

  animation: ${colorize} 10s linear infinite;

  background-color: black;
  border-radius: 50%;

  z-index: 101;
`;
