import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Container, DropdownItem } from "./styles";

const RaritySelectDropdown = ({ raritySelectCallback = null, rarityList = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRarity, setSelectedRarity] = useState(null);
  const [selectedRarityName, setSelectedRarityName] = useState("Pick a Rarity");

  const onRaritySelect = (rarity) => {
    setSelectedRarity(rarity);
    setSelectedRarityName(`${rarity.name}`);
    setIsOpen(false);

    if (raritySelectCallback) {
      raritySelectCallback(rarity.id);
    }
  };

  const dropDownForceStyle = {
    minWidth: "280px",
  };

  return (
    <Container itemCount={rarityList.length}>
      <div className="dropdown" style={dropDownForceStyle}>
        <Link
          to="#"
          className="btn-selector nolink"
          onClick={() => setIsOpen(!isOpen)}
          style={{ padding: "5px" }}
        >
          {selectedRarityName}
        </Link>
        {isOpen && (
          <ul style={{ overflowY: "auto" }}>
            {rarityList.map((rarity, index) => (
              <li key={index}>
                <DropdownItem onClick={() => onRaritySelect(rarity)}>{rarity.name}</DropdownItem>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Container>
  );
};

export default RaritySelectDropdown;
