import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const CategoryButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 720px;
  max-width: 90%;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

export const CategoryButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 4px;

  outline: ${(props) => (props.selected ? "3px solid white" : "none")};
  opacity: ${(props) => (props.selected ? "1" : "0.7")};

  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  user-select: none;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  height: 100px;
  width: 100px;
  margin: 0px 10px 10px 10px;

  img {
    width: 50%;
    height: 50%;
    filter: invert(1);
    margin-bottom: 4px;
  }

  &:hover {
    outline: 3px solid white;
    opacity: 1;
  }

  &:active {
    background-color: #3c3c3c;
  }
`;

export const CategoryMiniDescription = styled.div`
  font-size: 12px;
  position: relative;
  top: -8px;
`;

export const PreferredCurrencyButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  max-width: 720px;
  margin-bottom: 10px;
`;

export const PreferredCurrencyTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 4px;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const PreferredCurrencyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 10px;

  outline: ${(props) => (props.selected ? "3px solid white" : "none")};
  opacity: ${(props) => (props.selected ? "1" : "0.7")};
  //background: ${(props) => (props.selected ? props.selectedBg : "none")};
  background: ${(props) => props.selectedBg};

  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  user-select: none;
  transition: all 0.2s ease-in-out;
  height: 80px;
  width: 80px;

  img {
    width: 50%;
    height: 50%;
    filter: ${(props) => (props.selectedBg ? "none" : "invert(1)")};
    margin-bottom: 4px;
  }

  &:hover {
    outline: 3px solid white;
    opacity: 1;
  }

  &:active {
    background-color: #3c3c3c;
  }
`;

export const CategoryDescriptionContainer = styled.div`
  p {
    margin-bottom: 20px;
  }

  .small {
    line-height: 1.2;
    margin-bottom: 10px;
  }
`;

export const OuterLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 600px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

export const LoadingLabel = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-left: 10px;
  color: #ddd;
`;

export const BeastFiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const BeastFiltersTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 4px;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const BeastFiltersDropdownsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 585px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

export const MerchantContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const MerchantTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 4px;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const EbctContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  font-size: 25px;
  background: ${(props) => props.background};
`;
