import BigNumber from "bignumber.js";

export const hasEnoughBct = (player, bctAmount) => {
  if (player) {
    const totalInGameBct = BigNumber(player.ingameBalances[0]).plus(player.ingameBalances[1]);
    const hasEnough = BigNumber(bctAmount).lte(totalInGameBct);

    return hasEnough;
  } else {
    return true;
  }
};

export const hasEnoughResources = (player, resourcesAmounts = [], resourceIndexes = []) => {
  if (!player) return false;
  if (!player?.ingameBalances) return false;
  if (!player?.ingameBalances[2]) return false;

  for (let i = 0; i < resourceIndexes.length; i++) {
    const resourceIndex = resourceIndexes[i];
    const balance = player.ingameBalances[2][resourceIndex];
    const cost = resourcesAmounts[i];

    if (parseFloat(cost) === 0) {
      continue;
    }

    const hasEnoughHere = parseFloat(cost) <= parseFloat(balance);

    if (!hasEnoughHere) {
      return false;
    }
  }

  return true;
};

export const hasEnoughBusd = (player, busdAmount) => {
  if (player) {
    const hasEnough = BigNumber(busdAmount).lte(player.metamaskBalances[1]);
    return hasEnough;
  } else {
    return true;
  }
};
