import React, { useEffect, useState } from "react";
import Messenger from "../../../utils/Messenger";
import BigNumber from "bignumber.js";
import Card from "../../card/Card";
import ResourceContainer from "../resource-container";
import ResourceNft from "../resource-nft/ResourceNft";
import { shortAccount } from "../../../utils/account";
import { currentBlock } from "../../../utils/block";
import {
  Container,
  CardContainer,
  PricesContainer,
  ResourceNameContainer,
  PricesTitle,
  CancelContainer,
  CancelButton,
  WinnerText,
  OutbidText,
  ClaimButtonContainer,
  ClaimButton,
} from "./styles.js";
import { indexToDescription } from "../../../utils/constants";
import BinIcon from "../../../assets/images/icon/trash-bin.png";
import { indexToVerticalBackground } from "../../../utils/constants";
import { isMaxedOut } from "../../../config/resources/08-squad-cards";

import { indexToName } from "../../../config/resources/04-resources-full-list";

import { usePlayerContext } from "../../../contexts/PlayerContext";
import { useMarketplaceContext } from "../../../contexts/MarketplaceContext";

import OrderProgressBar from "../order-progress-bar";
import BonusBlock from "../bonus-block/BonusBlock";

const DECIMALS = 2;

const OrderCard = ({ orderData = null, showCancelButton = false }) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_CEIL });

  const { player } = usePlayerContext();
  const { bidOnAuction, executeOrder, cancelOrder, mustHaveEnough } = useMarketplaceContext();

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }
  }, [player]);

  const getDescription = (assetId) => {
    return indexToDescription[assetId];
  };

  const isItMaxedOut = isMaxedOut(orderData?.nft);

  const handleBid = async (isPowerBid = false) => {
    console.log(`User action: ${isPowerBid ? "POWER " : ""}bid on Spiral Auction #${orderData.id}`);

    // first, let's make sure the current player is not the seller of the order
    if (player.address === orderData.seller) {
      Messenger.warn("You can't bid on your own auction.");
      return;
    }

    // now, let's guarantee the player has enough balance using the hasEnough function
    // if the player doesn't have enough, the function will show a warning message and return false
    // if the player has enough, the function will return true
    const hasEnough = mustHaveEnough(BigNumber(price()).times(1e18).toString(), "USDC");
    if (!hasEnough) return;

    // now we can bid on the order
    await bidOnAuction(orderData.id, BigNumber(price()).times(1e18).toString(), isPowerBid);
  };

  const handleClaim = async () => {
    console.log(`User action: claim Spiral Auction #${orderData.id}`);

    // first, let's make sure the current player is the privateFor
    if (player.address !== orderData.privateFor) {
      Messenger.warn("You are not the winner of this auction.");
      return;
    }

    // now we can execute the order
    await executeOrder(orderData.id, orderData.quantity, true, false);
  };

  const handleCancelOrder = async () => {
    if (!player) return;
    if (orderData.seller !== player.address) return;

    // now we can cancel the order
    await cancelOrder(orderData.id);
  };

  const currentlyWinning = () => {
    if (orderData.privateFor === orderData.seller) {
      return "nobody";
    } else {
      return shortAccount(orderData.privateFor);
    }
  };

  const hasOneOrMoreBids = () => {
    return orderData.privateFor !== orderData.seller;
  };

  const bids = () => {
    return orderData?.bids || 0;
  };

  const ebctBonus = () => {
    return 10 * 2 * bids();
  };

  const featherBonus = () => {
    return Math.floor(0.2 * 2 * bids() * 100) / 100;
  };

  const hornBonus = () => {
    return Math.floor(0.1 * 2 * bids() * 100) / 100;
  };

  const skullBonus = () => {
    return Math.floor(0.05 * 2 * bids() * 100) / 100;
  };

  const price = () => {
    return BigNumber(orderData.priceInWei)
      .times(105)
      .div(100)
      .div(1e18)
      .toFixed(DECIMALS, BigNumber.ROUND_CEIL);
  };

  const cashback = () => {
    return BigNumber(price()).times(102).div(100).toFixed(DECIMALS, BigNumber.ROUND_DOWN);
  };

  const shouldShowClaimButton = () => {
    const _isTimeOver = isTimeOver();
    const _isWinner = isWinner();
    const _hasValidBids = hasValidBids();

    return _isTimeOver && _isWinner && _hasValidBids;
  };

  const isTimeOver = () => {
    const _currentBlock = currentBlock(
      player?.connection?.initialBlockNumber,
      player?.connection?.initialTimestamp
    );

    const isOver =
      !isNaN(_currentBlock) && _currentBlock > 0 && _currentBlock > orderData.createdAt + 8640;

    return isOver;
  };

  const isWinner = () => {
    return orderData.privateFor === player?.address && orderData.seller !== player?.address;
  };

  const hasValidBids = () => {
    return orderData.bids > 0;
  };

  return (
    orderData.assetId !== undefined && (
      <Container>
        <OrderProgressBar startsAt={orderData.createdAt} />
        {orderData.isNft ? (
          <CardContainer>
            {showCancelButton && (
              <CancelContainer>
                <CancelButton disable={false} onClick={handleCancelOrder}>
                  <img src={BinIcon} alt="cancel" />
                </CancelButton>
              </CancelContainer>
            )}
            <Card
              nft={orderData.nft}
              squadType={6}
              scale={isItMaxedOut ? 0.98 : 1}
              reasonToSelect={"none"}
              clickToSell={false}
              clickToSelectNftFromInventory={false}
              supressInteraction={true}
              lockSize={true}
            />
          </CardContainer>
        ) : (
          <ResourceNameContainer>
            <ResourceNft
              resource={orderData.assetId}
              name={indexToName(orderData.assetId)}
              quantity={orderData.quantity}
              background={indexToVerticalBackground[orderData.assetId]}
              description={getDescription(orderData.assetId)}
              seller={orderData.seller}
              userBalance={orderData.userBalance}
              isPreview={false}
              disable={false}
              cancelOrderCallback={handleCancelOrder}
              showCancelButton={showCancelButton}
            />
          </ResourceNameContainer>
        )}

        {shouldShowClaimButton() ? (
          <>
            <PricesContainer
              isNft={orderData.isNft}
              isMaxedOut={isItMaxedOut}
              isPoolFuel={orderData.isPoolFuel}
            >
              <PricesTitle isPoolFuel={orderData.isPoolFuel}>
                Congratulations, YOU won the auction!
              </PricesTitle>
              <ClaimButtonContainer>
                <ClaimButton onClick={handleClaim}>🥳 CLAIM NOW 🥳</ClaimButton>
              </ClaimButtonContainer>
            </PricesContainer>
            <OutbidText>{`The item and the bonus are yours`}</OutbidText>
          </>
        ) : isTimeOver() ? (
          <>
            <PricesContainer
              isNft={orderData.isNft}
              isMaxedOut={isItMaxedOut}
              isPoolFuel={orderData.isPoolFuel}
            >
              <PricesTitle isPoolFuel={orderData.isPoolFuel}>
                This auction is closed now
              </PricesTitle>
              <ResourceContainer
                resource={102}
                quantity={price()}
                background={indexToVerticalBackground[103]}
                hasEnough={true}
                useRecurrentCountUp={false}
                countUpSeconds={0}
                isLastOne={true}
                isPreview={false}
                bonus={false}
                previewDivider={1}
                isBasePrice={true}
                isAccepted={false}
                useAnimatedCounter={false}
                isPoolFuel={orderData.isPoolFuel}
              />
            </PricesContainer>
            <OutbidText>{`Winner: ${shortAccount(orderData.privateFor)}`}</OutbidText>
          </>
        ) : (
          <>
            <PricesContainer
              isNft={orderData.isNft}
              isMaxedOut={isItMaxedOut}
              isPoolFuel={orderData.isPoolFuel}
            >
              <PricesTitle isPoolFuel={orderData.isPoolFuel}>
                Safe Bid: don't pay more than
              </PricesTitle>
              <ResourceContainer
                resource={102}
                quantity={price()}
                background={indexToVerticalBackground[103]}
                hasEnough={true}
                useRecurrentCountUp={false}
                countUpSeconds={0}
                isLastOne={true}
                isPreview={false}
                bonus={false}
                previewDivider={1}
                isBasePrice={true}
                isAccepted={true}
                buyCallback={() => handleBid(false)}
                useAnimatedCounter={false}
                isPoolFuel={orderData.isPoolFuel}
              />
            </PricesContainer>
            <OutbidText>{`Get $${cashback()} back if outbid`}</OutbidText>

            <PricesContainer
              isNft={orderData.isNft}
              isMaxedOut={isItMaxedOut}
              isPoolFuel={orderData.isPoolFuel}
              isPowerBid={true}
            >
              <PricesTitle isPoolFuel={orderData.isPoolFuel}>
                Power Bid: cover simultaneous bids
              </PricesTitle>
              <ResourceContainer
                resource={102}
                quantity={price()}
                background={indexToVerticalBackground[103]}
                hasEnough={true}
                isPowerBid={true}
                useRecurrentCountUp={false}
                countUpSeconds={0}
                isLastOne={true}
                isPreview={false}
                bonus={false}
                previewDivider={1}
                isBasePrice={true}
                isAccepted={true}
                buyCallback={() => handleBid(true)}
                useAnimatedCounter={false}
                isPoolFuel={orderData.isPoolFuel}
              />
            </PricesContainer>
            <OutbidText>{`Get 102% back if outbid`}</OutbidText>

            <WinnerText
              style={
                currentlyWinning() === "nobody"
                  ? { color: "#bbb" }
                  : { color: "yellow", background: "linear-gradient(0deg, #101 10%, #000 100%)" }
              }
            >
              Currently Winning: {currentlyWinning()}
            </WinnerText>
          </>
        )}
        <BonusBlock
          ebct={ebctBonus()}
          feathers={featherBonus()}
          horns={hornBonus()}
          skulls={skullBonus()}
          isWinner={shouldShowClaimButton()}
          isTimeOver={isTimeOver()}
        />
      </Container>
    )
  );
};

export default OrderCard;
