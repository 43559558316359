import React from "react";
import Header from "../components/header/Header";
import { Link } from "react-router-dom";
import styled from "styled-components";

import KoziTargetPrice from "../assets/images/koziTargetPrice.jpg";

const GlobalContainer = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-top: 60px;
  margin-bottom: 200px;
`;

const Title = styled.div`
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
`;
const SubTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
  text-align: center;
`;

const Category = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 4rem;
`;

const Paragraph = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  text-indent: 3rem;
`;

const BulletPoint = styled.div`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: bold;
  margin-top: 2rem;
`;

const UnindentedParagraph = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1rem;
  line-height: 1.5;
`;

const IndentedParagraph = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  margin-left: 2.6rem;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;

  p {
    font-size: 1.3rem;
    color: #ccc;
  }
`;

const Whitepaper = () => {
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">📃</h1>
                <h1 className="heading text-center">Whitepaper</h1>
              </div>
              <h5 className="sub-title help-center">
                Our whitepaper outlines the blueprint of our digital ecosystem, detailing the
                technology that powers it and the economic principles that guide it.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <GlobalContainer>
        <Title>Kingdom Chain</Title>
        <SubTitle>Enhancing the Beast Kingdom Experience</SubTitle>
        <Category>I. Introduction</Category>
        <Paragraph>
          Kingdom Chain is an innovative blockchain initiative tailor-made for the Beast Kingdom
          community. Our goal is simple: to enhance our Web3 gaming experience by significantly
          reducing costs and introducing new, exciting DeFi features to our games.
        </Paragraph>
        <Paragraph>
          Here, we're not just building a blockchain; we're crafting a specialized tool to enrich
          our tight-knit community. Kingdom Chain is our shared space for growth and exploration,
          designed with the needs and aspirations of our community at its core.
        </Paragraph>
        <UnindentedParagraph style={{ marginBottom: "-1rem" }}>
          Technical Highlights:
        </UnindentedParagraph>
        <BulletPoint>💠 Ethereum Protocol</BulletPoint>
        <IndentedParagraph>
          Kingdom Chain leverages the Ethereum Virtual Machine (EVM), ensuring full compatibility
          with Ethereum and other prominent EVM-based chains like Polygon, Avalanche, and Celo.
        </IndentedParagraph>
        <BulletPoint>💠 Consensus Mechanism</BulletPoint>
        <IndentedParagraph>
          Kingdom Chain uses the QBFT consensus protocol, offering fast, reliable, and
          energy-efficient transaction validation.
        </IndentedParagraph>
        <BulletPoint>💠 Block Time</BulletPoint>
        <IndentedParagraph>
          Kingdom Chain features a 10-second block time, striking an optimal balance between swift
          transaction processing and manageable block size.
        </IndentedParagraph>
        <BulletPoint>💠 Transaction Fees</BulletPoint>
        <IndentedParagraph>
          Aiming for affordability, most transactions on Kingdom Chain cost less than $0.01,
          ensuring economic accessibility for all players.
        </IndentedParagraph>
        <BulletPoint>💠 Block Explorer</BulletPoint>
        <IndentedParagraph>
          Our unique blockchain explorer, Travel Song, provides a perfect balance between offering
          an abundance of information about the chain state and maintaining low infrastructure
          costs.
        </IndentedParagraph>
        <BulletPoint>💠 Not an Investment</BulletPoint>
        <IndentedParagraph>
          Kingdom Chain is a community-focused blockchain initiative, created for the enjoyment and
          exploration of blockchain technology, specifically within the context of Beast Kingdom and
          its community. It is not designed or intended as an investment vehicle. While financial
          profits may occur, they are neither guaranteed nor actively pursued as the primary goal of
          this project. We strongly advise those seeking solely financial returns to explore
          alternative opportunities. Kingdom Chain is about passion for innovation and a shared
          journey in the evolving world of blockchain, not financial speculation.
        </IndentedParagraph>

        <Category>II. Kozi: Kingdom Chain's Gas Token</Category>
        <Paragraph>
          As with any EVM Chain, Kingdom Chain has its own Gas token, called "Kozi”. In the EVM
          world, "Gas” is how we measure processing needs, and every transaction requires a small
          quantity of Gas to be executed.
        </Paragraph>
        <UnindentedParagraph style={{ marginBottom: "-1rem" }}>
          Here are the 10 automated rules that govern the flow of Kozi in the Kingdom:
        </UnindentedParagraph>
        <BulletPoint>
          💠 1. Upon deployment of the Kingdom Chain, 101 (one hundred and one) Kozi will be
          pre-mined and transferred to the "Kingdom Wallet".
        </BulletPoint>
        <IndentedParagraph>
          The Kingdom Wallet will use them to deploy the initial smart contracts (~0.5 Kozi), fill
          the Kozi Pool with 100 Kozi, and perform administrative tasks on the Kingdom Chain.
        </IndentedParagraph>

        <BulletPoint>
          💠 2. Whenever a block is mined in the Kingdom, 0.01 Kozi are generated and allocated to
          the Kingdom Wallet.
        </BulletPoint>
        <IndentedParagraph>
          With blocks mined approximately every 10 seconds, the Kingdom Wallet can accumulate up to
          3.6 Kozi per hour as mining rewards. Additionally, all blockchain fees are also directed
          to the Kingdom Wallet.
        </IndentedParagraph>

        <BulletPoint>
          💠 3. The Kingdom aims to maintain a minimum balance of 1 Kozi at all times.
        </BulletPoint>
        <IndentedParagraph>
          After spending Kozi for initial deployments, the Kingdom Wallet will steadily accumulate
          Kozi every ~10 seconds until reaching a balance of 1 Kozi again. This reserve, known as
          the "Administrative Floor", will support administrative tasks on the Kingdom Chain.
        </IndentedParagraph>

        <BulletPoint>
          💠 4. Kozi can be bought and sold through a MicroDex called "Kozi Pool”.
        </BulletPoint>
        <IndentedParagraph>
          The MicroDex is accessible both through the Beast Kingdom website and via direct
          interaction with the Kingdom Chain RPC.
        </IndentedParagraph>

        <BulletPoint>💠 5. The initial price of Kozi is $10.</BulletPoint>
        <IndentedParagraph>
          This is based on the initial funds deposited into our Kozi Pool: 100 Kozi and $990. As
          individuals buy and sell Kozi, the price will naturally fluctuate according to market
          forces.
        </IndentedParagraph>
        <IndentedParagraph>
          The minimum blockchain fee on the Kingdom Chain is set at 0.000000001 Kozi per Gas for all
          transactions (1 Gwei per Gas). This fee will be adjusted downward yearly to ensure
          transactions remain affordable for all members.
        </IndentedParagraph>

        <BulletPoint>💠 6. Kozi has a moving "Target Price".</BulletPoint>
        <IndentedParagraph>
          The initial Target Price of Kozi is set at $10 and increases by 0.1% daily.
        </IndentedParagraph>
        <IndentedParagraph>
          When the price falls below the Target Price, all Kozi sold to the Kozi Pool are
          immediately burned. This action makes the token temporarily scarcer, thereby accelerating
          its price recovery.
        </IndentedParagraph>
        <IndentedParagraph>
          There is a significant distinction between the "price" and the "Target Price". The price
          represents the market value at any given moment, essentially the highest amount someone is
          willing to pay for one KOZI. In contrast, the 'Target Price' is an objective we aim to
          achieve, which should loosely reflect network usage. It's important to understand that the
          Target Price is not the current price; rather, it serves as a benchmark for where we want
          the price to be. For the actual price to increase, there needs to be substantial network
          usage.
        </IndentedParagraph>

        <ImageContainer>
          <img
            src={KoziTargetPrice}
            alt="Kozi Target Price"
            style={{ width: "60%", textAlign: "center", margin: "0 auto" }}
          />
          <p>Table of the Target Price progression: this is not the same as the actual price.</p>
        </ImageContainer>

        <BulletPoint>
          💠 7. The Kingdom aims to keep the price close to the Target Price at all times.
        </BulletPoint>
        <IndentedParagraph>
          Every hour, the Kingdom evaluates whether Kozi's current price is above or below the
          Target Price and takes appropriate action.
        </IndentedParagraph>
        <IndentedParagraph>
          Target Price checks occur within a 30-minute window around each hour, either 15 minutes
          before or after. For instance, a check might happen from 8:45 to 9:15, then from 9:45 to
          10:15, and so on, at random intervals within these times. No one knows exactly when the
          next check will happen.
        </IndentedParagraph>
        <IndentedParagraph>
          Below Target Price Action: If Kozi's price falls below the Target Price, the Kingdom
          Wallet will burn any excess Kozi beyond the Administrative Floor. This action helps
          maintain scarcity and supports price recovery.
        </IndentedParagraph>
        <IndentedParagraph>
          Above Target Price Action: If Kozi's price is higher than the Target Price, the Kingdom
          Wallet will sell any excess over the Administrative Floor to the Kozi Pool, up to 3.6 Kozi
          hourly.
        </IndentedParagraph>
        <IndentedParagraph>
          Importantly, the Kingdom will only sell Kozi when its price is above the Target Price. The
          quantity sold is carefully calculated to prevent these sales from pushing the price below
          the Target Price. This strategic approach is aimed at ensuring a steady alignment with the
          Target Price.
        </IndentedParagraph>

        <BulletPoint>💠 8. The Kozi Pool has a dynamic spread.</BulletPoint>
        <IndentedParagraph>
          The spread of the Kozi Pool adjusts dynamically based on Kozi's current price in relation
          to its Target Price, with the goal of maintaining market stability and fair pricing.
        </IndentedParagraph>
        <IndentedParagraph>
          When Kozi's price is above the Target Price, the spread adjusts to half the percentage
          difference between the current and Target Price, capped at 99%. As an example, if Kozi's
          Target Price is $10 and its current price is $10.50, which is 5% above the Target, selling
          1 Kozi would incur a 2.5% spread. This means for a sale of 1 Kozi at $10.50, the seller
          would receive $10.25, and $0.25 would remain in the Kozi Pool.
        </IndentedParagraph>
        <IndentedParagraph>
          Conversely, when Kozi's price falls below the Target Price, the spread increases to twice
          the percentage difference between the current and Target Price, also capped at 99%. For
          example, if selling Kozi would result in the price dropping to 3% below the Target Price,
          the spread applied to that sale would be 6%. This spread remains in the Kozi Pool.
        </IndentedParagraph>

        <BulletPoint>
          💠 9. Each time the Kingdom sells Kozi to the Kozi Pool, a fixed spread of 50% is applied
          to the transaction, and this amount remains within the pool.
        </BulletPoint>
        <IndentedParagraph>
          This policy is deliberately chosen to gradually enhance the liquidity of the Kozi Pool,
          thereby contributing to greater price stability over time.
        </IndentedParagraph>

        <BulletPoint>💠 10. There are 25 Kingdom Nobles.</BulletPoint>
        <IndentedParagraph>
          Whenever the Kingdom sells Kozi to the Kozi Pool, 12.5% of the USDC received are sent
          directly to the top 25 Kozi holders that are not the Kingdom itself or smart contracts:
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 16th to 25th place, known as <span style={{ fontWeight: "bold" }}>"Knights"</span>,
          gain 0.05% each.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 11th to 15th place, known as <span style={{ fontWeight: "bold" }}>"High Knights"</span>
          , gain 0.1% each.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 10th place, known as <span style={{ fontWeight: "bold" }}>"Knight Commander"</span>,
          gains 0.2%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 9th place, known as <span style={{ fontWeight: "bold" }}>"Lord"</span>, gains 0.3%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 8th place, known as <span style={{ fontWeight: "bold" }}>"High Lord"</span>, gains
          0.4%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 7th place, known as <span style={{ fontWeight: "bold" }}>"Baron"</span>, gains 0.5%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 6th place, known as <span style={{ fontWeight: "bold" }}>"Viscount"</span>, gains 0.6%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 5th place, known as <span style={{ fontWeight: "bold" }}>"Count"</span>, gains 0.7%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 4th place, known as <span style={{ fontWeight: "bold" }}>"Marquess"</span>, gains 1%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 3rd place, known as <span style={{ fontWeight: "bold" }}>"Duke"</span>, gains 1.5%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 2nd place, known as <span style={{ fontWeight: "bold" }}>"Grand Vizier"</span>, gains
          2.5%.
        </IndentedParagraph>
        <IndentedParagraph>
          ◻️ 1st place, known as <span style={{ fontWeight: "bold" }}>"The King"</span>, gains 3.8%.
        </IndentedParagraph>
        <IndentedParagraph>On top of that:</IndentedParagraph>
        <IndentedParagraph>
          ◼ 4.5% is directed to support initiatives on Crystal Ball, our upcoming mini-games and
          Predictive Markets system.
        </IndentedParagraph>
        <IndentedParagraph>
          ◼ 4% is contributed to the Cosmic Fund, designated for our next game, "Cosmic: Humanity
          Reborn".
        </IndentedParagraph>
        <IndentedParagraph>
          ◼ 4% is allocated to the Commonwealth Fund of Beast Kingdom during Kingdom Chain's first
          year, and to the Cosmic Fund after that.
        </IndentedParagraph>
        <IndentedParagraph>
          ◼ The remainder is utilized to cover expenses related to development, support, and
          infrastructure of the ecosystem.
        </IndentedParagraph>
        <UnindentedParagraph>
          The 10 rules that comprise Kozi's policy will govern this process for the first 6 years.
          After this period, we will hold a community vote to decide whether to continue with the
          current policy or adopt a new approach.
        </UnindentedParagraph>
        <UnindentedParagraph>
          With these rules, Kozi's price is expected to ebb and flow like ocean waves for 6 years.
          As interest in purchasing Kozi grows, its price will initially surge, followed by a
          gradual decline as we adjust towards the Target Price, and then rise again. This wave-like
          pattern is expected to repeat, with each cycle smoothing out the fluctuations, leading to
          increased liquidity and more stable pricing.
        </UnindentedParagraph>

        <Category>III. Kingdom Chain Applications</Category>
        <Paragraph>
          Kingdom Chain is a general-use blockchain, developed with the flexibility to support a
          wide range of applications. Our focus is on practical, community-driven uses that enhance
          the gaming experience and expand blockchain functionality.
        </Paragraph>
        <UnindentedParagraph style={{ marginBottom: "-1rem" }}>
          Current Applications:
        </UnindentedParagraph>
        <BulletPoint>💠 Beast Kingdom</BulletPoint>
        <IndentedParagraph>
          The game will utilize our blockchain for lower transaction costs, directly benefiting our
          player community.
        </IndentedParagraph>

        <BulletPoint>💠 USDC Bridge</BulletPoint>
        <IndentedParagraph>
          It enables the transfer of USDC between BNB Chain and Kingdom Chain. Designed for seamless
          transactions, the bridge provides a secure and efficient way for users to move their
          assets between these platforms.
        </IndentedParagraph>
        <UnindentedParagraph style={{ marginBottom: "-1rem" }}>
          Upcoming Projects:
        </UnindentedParagraph>
        <BulletPoint>💠 DeFi mini-games and Predictive Markets System</BulletPoint>
        <IndentedParagraph>
          ‘Crystal Ball' is a system for making predictions on real-world events and for
          participating in exciting DeFi mini-games.
        </IndentedParagraph>
        <BulletPoint>💠 Hybrid Game (Web2-Web3 Integration)</BulletPoint>
        <IndentedParagraph>
          Our next game will merge Web2 and Web3 elements, aiming to attract both traditional gamers
          and blockchain enthusiasts. This is about expanding our reach and trying something new
        </IndentedParagraph>

        <BulletPoint>💠 Extended Nobility Benefits</BulletPoint>
        <IndentedParagraph>
          The benefits for the 25 Nobles of Kingdom Chain will be expanded. Depending on their
          titles, Nobles might receive special access or advantages across all Kingdom Chain
          applications. This feature is intended to reward engagement and loyalty, enhancing the
          experience for our most dedicated community members.
        </IndentedParagraph>

        <UnindentedParagraph>
          We're starting with a centralized approach for stability. However, our goal is to
          gradually decentralize block processing, allowing more community involvement in the
          network's operation. Additionally, in the future we'll open up Kingdom Chain for
          third-party developers. We see the value in diverse applications and want to provide a
          platform where various projects can coexist and enhance the overall ecosystem.
        </UnindentedParagraph>

        <Category>IV. Conclusion</Category>
        <Paragraph>
          Kingdom Chain culminates our efforts to create a blockchain environment tailored for Beast
          Kingdom, prioritizing practicality and community engagement. Built on the Ethereum Virtual
          Machine and utilizing the QBFT protocol, our blockchain achieves a balance of technical
          efficiency and accessibility. With low transaction fees and the introduction of Kozi, we
          aim to keep our ecosystem economically viable for all players.
        </Paragraph>
        <Paragraph>
          Our journey begins with Beast Kingdom, but it doesn't end there. Future expansions include
          diverse applications like predictive markets, DeFi mini-games, and a hybrid gaming model.
          These steps are part of our broader strategy to bring blockchain's benefits to a wider
          range of uses within our community.
        </Paragraph>
        <Paragraph>
          As we look forward, the plan is to transition towards greater decentralization and open
          the platform to external developers, fostering a more inclusive and participatory
          environment. Kingdom Chain is about practicality, innovation, and a shared journey in
          blockchain gaming. It's a tool, evolving with and for our community.
        </Paragraph>

        <Paragraph>
          We move into this future with a commitment to transparency, community collaboration, and
          continuous adaptation to the needs of our users. Together, we will navigate the evolving
          landscape of blockchain gaming, focusing on genuine community engagement as we develop and
          refine the Kingdom Chain.
        </Paragraph>
      </GlobalContainer>
    </div>
  );
};

export default Whitepaper;
