import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Messenger from "../../utils/Messenger";
import BigNumber from "bignumber.js";

import {
  Container,
  UpgradeGroupRowContainer,
  UpgradeGroupRowRightContainer,
  CardContainer,
  ActionContainer,
  SacrificeContainer,
} from "./styles";
import Card from "../card/Card";
import UpgradeBox from "./upgradeBox";
import UpgradeGroup from "./upgradeGroup";
import UpgradesModal from "./upgradesModal";

import { calculateFarmPerBlock } from "../../utils/nft";
import { usePlayerContext } from "../../contexts/PlayerContext";
import { useUpgradesContext } from "../../contexts/UpgradesContext";
import { useWeb3Context } from "../../contexts/Web3Context";

import { numberToName as getRarity } from "../../config/resources/05-rarity";
import { numberToName as getType } from "../../config/resources/06-type_";

import {
  getRankUpPrices,
  getAgilityUpPrices,
  getSkillLevelPrices,
  getNutsUpPrices,
  getPerksArray,
  getTraitRerollPrices,
  getReassemblePrices,
  getAddTraitPrices,
  getIntegrationPrices,
  getTransformationPrices,
  getEvolutionPrices,
  getExtractionPrices,
  getDeificationPrices,
  getTransmutePrices,
  getDeifiedNftPropsFrom,
  getDissolutionPrices,
} from "../../config/resources/12-upgrades";

const UpgradesBlock = ({ nft }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { player } = usePlayerContext();
  const {
    buyRankUp,
    buyAgilityUp,
    buySkillUp,
    buyBaseFarmUp,
    buyTraitReroll,
    buyReassemble,
    buyTransmute,
    buyAddTrait,
    buyIntegration,
    buyEvolution,
    buyExtraction,
    buyDeification,
    currentlySelectedPerkIndex,
    setCurrentlySelectedPerkIndex,
    buyDissolution,
  } = useUpgradesContext();
  const { isReady, bctToBusd, blockchainState } = useWeb3Context();
  const [tempNft, setTempNft] = useState(null);
  const [currentLootSkillButtons, setCurrentLootSkillButtons] = useState([]);

  const [tempName, setTempName] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState("");
  const [rankUpLevels, setRankUpLevels] = useState(0);
  const [agilityLevels, setAgilityLevels] = useState(0);
  const [skill1Levels, setSkill1Levels] = useState(0);
  const [skill2Levels, setSkill2Levels] = useState(0);
  const [skill3Levels, setSkill3Levels] = useState(0);
  const [skill4Levels, setSkill4Levels] = useState(0);
  const [skill5Levels, setSkill5Levels] = useState(0);
  const [skill6Levels, setSkill6Levels] = useState(0);
  const [nutsLevels, setNutsLevels] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedTrait, setSelectedTrait] = useState(null);
  const [specialTraitsCount, setSpecialTraitsCount] = useState(0);

  const [sacrificeSelectedNfts, setSacrificeSelectedNfts] = useState([]);

  const [isExtraHelpModalOpen, setIsExtraHelpModalOpen] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    // cleanup the listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); // the empty array means this effect runs on mount and unmount

  useEffect(() => {
    setSacrificeSelectedNfts(location?.state?.selectedNfts || []);
  }, [location?.state?.selectedNfts]);

  // For perks that are not ready to be bought (DO NOT REMOVE EVEN IF IT IS GRAYED OUT - blood magic)
  const nonReadyLevels = 0;

  const [currentPriceInResources, setCurrentPriceInResources] = useState({
    bct: BigNumber(0),
    resourceCents: [0],
    resourceIndexes: [],
  });
  const [currentPriceInBusd, setCurrentPriceInBusd] = useState(0);

  useEffect(() => {
    if (!nft) return;

    if (!tempNft) {
      resetCardPreview();
    }

    setCurrentLootSkillButtons(getUpgradeResourceButtons(nft));

    if (currentlySelectedPerkIndex) {
      setSelectedPerk(perks[currentlySelectedPerkIndex]);
      updateCurrentPrices(perks[currentlySelectedPerkIndex], null, specialTraitsCount);
    }

    if (currentlySelectedPerkIndex === 9) {
      const forbiddenNftIds = location.state?.forbiddenNftIds || [];
      forbiddenNftIds.push(nft?.id);

      // also add other forbidden rules (must be of the same type and rarity, and can't be on quest)
      const requiredNftFeatures = {
        type_: nft?.type_,
        rarity: nft?.rarity,
        notOnQuest: true,
      };

      const newState = {
        ...location.state,
        forbiddenNftIds,
        requiredNftFeatures,
      };

      if (
        location.state?.requiredNftFeatures?.type_ !== requiredNftFeatures.type_ ||
        location.state?.requiredNftFeatures?.rarity !== requiredNftFeatures.rarity
      ) {
        navigate(`/upgrade-nft/${nft?.id}`, {
          state: newState,
        });
      }
    }
  }, [
    nft,
    tempNft?.type_,
    tempNft?.rank,
    tempNft?.agility,
    tempNft?.skills[0],
    tempNft?.skills[1],
    tempNft?.skills[2],
    tempNft?.skills[3],
    tempNft?.skills[4],
    tempNft?.skills[5],
    tempNft?.nuts,
    location,
  ]);

  function updateCurrentPrices(forcePerk = null, forceLevel = null, forceSpecialTraits = null) {
    if (!isReady) return;
    BigNumber.config({ DECIMAL_PLACES: 18 });

    const perk = forcePerk ? forcePerk : selectedPerk;
    const priceInRes = perk.getPriceInResourcesFunction(forceLevel, forceSpecialTraits);
    setCurrentPriceInResources(priceInRes);

    const fetchBusdPrice = async () => {
      if (!perk.buyWithBusdFunction) {
        setCurrentPriceInBusd(0);
      } else {
        let busdPrice = await bctToBusd(priceInRes.bctPrice);
        if (busdPrice.toString() === "1") {
          busdPrice = 0;
        }
        console.log("busdPrice", BigNumber(busdPrice).toFixed(0));
        setCurrentPriceInBusd(busdPrice.toFixed(0));
      }
    };

    fetchBusdPrice();
  }

  function updateTempNft(propertyName, propertyValue) {
    setTempNft((prevTempNft) => {
      const newNft = { ...prevTempNft, [propertyName]: propertyValue };
      newNft.farmPerBlock = calculateFarmPerBlock(newNft);
      return newNft;
    });
  }

  function updateTempNftSkill(skillIndex, propertyValue) {
    const newSkills = [...tempNft.skills];
    newSkills[skillIndex] = propertyValue;
    const newNft = { ...tempNft, skills: newSkills };
    setTempNft(newNft);
  }

  function updateTempNftNuts(newNuts) {
    const newNft = { ...tempNft, ["nuts"]: nft.nuts + newNuts };
    newNft.baseFarm = BigNumber(nft.baseFarm).plus(BigNumber(nft.baseFarm).times(newNuts).div(100));
    newNft.farmPerBlock = calculateFarmPerBlock(newNft);
    setTempNft(newNft);
  }

  const onRankLevelUpdate = (newLevel) => {
    updateTempNft("rank", newLevel);
    setRankUpLevels(newLevel);
    updateCurrentPrices(perks[0], newLevel);
  };

  const onAgilityLevelUpdate = (newLevel) => {
    updateTempNft("agility", newLevel);
    setAgilityLevels(newLevel);
    updateCurrentPrices(perks[1], newLevel);
  };

  const onBaseFarmLevelUpdate = (newLevel) => {
    updateTempNftNuts(newLevel - nft.nuts);
    setNutsLevels(newLevel);
    updateCurrentPrices(perks[2], newLevel);
  };

  const onSkill1LevelUpdate = (newLevel) => {
    updateTempNftSkill(0, newLevel);
    setSkill1Levels(newLevel);
    updateCurrentPrices(perks[3], newLevel);
  };

  const onSkill2LevelUpdate = (newLevel) => {
    updateTempNftSkill(1, newLevel);
    setSkill2Levels(newLevel);
    updateCurrentPrices(perks[4], newLevel);
  };

  const onSkill3LevelUpdate = (newLevel) => {
    updateTempNftSkill(2, newLevel);
    setSkill3Levels(newLevel);
    updateCurrentPrices(perks[5], newLevel);
  };

  const onSkill4LevelUpdate = (newLevel) => {
    updateTempNftSkill(3, newLevel);
    setSkill4Levels(newLevel);
    updateCurrentPrices(perks[6], newLevel);
  };

  const onSkill5LevelUpdate = (newLevel) => {
    updateTempNftSkill(4, newLevel);
    setSkill5Levels(newLevel);
    updateCurrentPrices(perks[14], newLevel);
  };

  const onSkill6LevelUpdate = (newLevel) => {
    updateTempNftSkill(5, newLevel);
    setSkill6Levels(newLevel);
    updateCurrentPrices(perks[15], newLevel);
  };

  function handleBuyRankUpWithResources(levels) {
    buyRankUp(nft.id, levels);
  }

  function handleBuyAgilityUpWithResources(levels) {
    handleBuyAgilityUp(levels, false);
  }

  async function handleBuyAgilityUp(levels, withBusd) {
    const _busdPrice = await bctToBusd(currentPriceInResources.bctPrice);
    buyAgilityUp(nft.id, levels, withBusd, _busdPrice.toFixed(0));
  }

  function handleBuySkill1UpWithResources(levels) {
    handleBuySkillUp(levels, 0, false);
  }

  function handleBuySkill2UpWithResources(levels) {
    handleBuySkillUp(levels, 1, false);
  }

  function handleBuySkill3UpWithResources(levels) {
    handleBuySkillUp(levels, 2, false);
  }

  function handleBuySkill4UpWithResources(levels) {
    handleBuySkillUp(levels, 3, false);
  }

  function handleBuySkill5UpWithResources(levels) {
    handleBuySkillUp(levels, 4, false);
  }

  function handleBuySkill6UpWithResources(levels) {
    handleBuySkillUp(levels, 5, false);
  }

  function handleBuyAgilityUpWithBusd(levels) {
    handleBuyAgilityUp(levels, true);
  }

  function handleBuySkill1UpWithBusd(levels) {
    handleBuySkillUp(levels, 0, true);
  }

  function handleBuySkill2UpWithBusd(levels) {
    handleBuySkillUp(levels, 1, true);
  }

  function handleBuySkill3UpWithBusd(levels) {
    handleBuySkillUp(levels, 2, true);
  }

  function handleBuySkill4UpWithBusd(levels) {
    handleBuySkillUp(levels, 3, true);
  }

  function handleBuySkill5UpWithBusd(levels) {
    handleBuySkillUp(levels, 4, true);
  }

  function handleBuySkill6UpWithBusd(levels) {
    handleBuySkillUp(levels, 5, true);
  }

  // TODFO: está pegando atrasado o preço em USDC; e está passando com ponto
  async function handleBuySkillUp(levels, skillIndex, withBusd) {
    const _busdPrice = await bctToBusd(currentPriceInResources.bctPrice);
    buySkillUp(nft.id, skillIndex, levels, withBusd, _busdPrice.toFixed(0));
  }

  function handleBuyNutsUp(levels) {
    buyBaseFarmUp(nft.id, levels);
  }

  function handleBuyTraitReroll(levels, traitId) {
    if (traitId === 0) {
      console.log(`No trait selected`);
      return;
    }

    if (nft.squadId !== 0) {
      // check if the squad is onQuest
      const squad = player.squads.find((squad) => squad.id === nft.squadId);
      if (squad.quest) {
        Messenger.warn(`This beast is on a quest, please finish the quest first.`);
        return;
      }
    }

    buyTraitReroll(nft.id, traitId);
  }

  function handleBuyReassemble(levels, traitId, secondaryTraitId) {
    if (traitId === 0) {
      console.log(`No trait A selected`);
      return;
    }

    if (secondaryTraitId === 0) {
      console.log(`No trait B selected`);
      return;
    }

    if (nft.squadId !== 0) {
      // check if the squad is onQuest
      const squad = player.squads.find((squad) => squad.id === nft.squadId);
      if (squad.quest) {
        Messenger.warn(`This beast is on a quest, please finish the quest first.`);
        return;
      }
    }

    buyReassemble(nft.id, traitId, secondaryTraitId);
  }

  function handleBuyTransmute(levels, traitId, secondaryTraitId, tertiaryTraitId) {
    if (traitId === 0) {
      console.log(`No trait A selected`);
      return;
    }

    if (secondaryTraitId === 0) {
      console.log(`No trait B selected`);
      return;
    }

    if (tertiaryTraitId === 0) {
      console.log(`No trait C selected`);
      return;
    }

    if (nft.squadId !== 0) {
      // check if the squad is onQuest
      const squad = player.squads.find((squad) => squad.id === nft.squadId);
      if (squad.quest) {
        Messenger.warn(`This beast is on a quest, please finish the quest first.`);
        return;
      }
    }

    buyTransmute(nft.id, traitId, secondaryTraitId, tertiaryTraitId);
  }

  function handleBuyAddTrait(levels, traitId) {
    if (traitId === 0) {
      console.log(`No trait selected`);
      return;
    }

    if (nft.squadId !== 0) {
      // check if the squad is onQuest
      const squad = player.squads.find((squad) => squad.id === nft.squadId);
      if (squad.quest) {
        Messenger.warn(`This beast is on a quest, please finish the quest first.`);
        return;
      }
    }

    buyAddTrait(nft.id, traitId);
  }

  function handleBuyIntegration() {
    // we'll integrate the nft with the two sacrificeSelectedNfts nfts

    if (nft.squadId !== 0) {
      // check if the squad is onQuest
      const squad = player.squads.find((squad) => squad.id === nft.squadId);
      if (squad.quest) {
        Messenger.warn(`Beast #${nft.id} is on a quest, please finish the quest first.`);
        return;
      }
    }

    // the sacrificeSelectedNfts nfts cannot be inside any squad (but nft can)
    for (let i = 0; i < sacrificeSelectedNfts.length; i++) {
      const sacNft = sacrificeSelectedNfts[i];
      if (sacNft.squadId !== 0 && sacNft.id !== 999999008) {
        Messenger.warn(`Beast #${sacNft.id} is in a squad, please remove it first.`);
        return;
      }
    }

    buyIntegration(
      nft.id,
      sacrificeSelectedNfts.map((nft) => nft.id)
    );
  }

  function handleBuyTransformation() {
    // we'll integrate the nft with the two sacrificeSelectedNfts nfts

    if (nft.squadId !== 0) {
      // check if the squad is onQuest
      const squad = player.squads.find((squad) => squad.id === nft.squadId);
      if (squad.quest) {
        Messenger.warn(`Beast #${nft.id} is on a quest, please finish the quest first.`);
        return;
      }
    }

    // the sacrificeSelectedNfts nfts cannot be inside any squad (but nft can)
    for (let i = 0; i < sacrificeSelectedNfts.length; i++) {
      const sacNft = sacrificeSelectedNfts[i];
      if (sacNft.squadId !== 0 && sacNft.id !== 999999008) {
        Messenger.warn(`Beast #${sacNft.id} is in a squad, please remove it first.`);
        return;
      }
    }

    buyIntegration(
      nft.id,
      sacrificeSelectedNfts.map((nft) => nft.id),
      true
    );
  }

  function handleBuyEvolution(levels, traitId, name, imageUrl) {
    if (!traitId || traitId === 999) {
      traitId = 0;
    }

    if (nft.squadId !== 0) {
      // check if the squad is onQuest
      const squad = player.squads.find((squad) => squad.id === nft.squadId);
      if (squad.quest) {
        Messenger.warn(`This beast is on a quest, please finish the quest first.`);
        return;
      }
    }

    const finalName = name === `${getRarity(nft.rarity)} ${getType(nft.type_)}` ? "" : name;
    const finalImageUrl = imageUrl.indexOf("Paste the URL") !== -1 ? "" : imageUrl;

    console.log(
      `Upgrades :: Calling buyEvolution with params: traitId=${traitId}, nftId=${nft.id}, nftName=${finalName}, nftImageUrl=${finalImageUrl}`
    );

    buyEvolution(nft.id, traitId, finalName, finalImageUrl);
  }

  function handleBuyExtraction(levels, traitId, squadId) {
    if (nft.squadId !== 0) {
      Messenger.warn(`This beast is in a squad. Please remove it from the squad first.`);
      return;
    }

    const squad = player.squads.find((squad) => squad.id === squadId);
    if (squad.quest) {
      Messenger.warn(`This squad is on a quest, please finish the quest first.`);
      return;
    }

    buyExtraction(nft.id, traitId, squadId, false);
  }

  function handleBuyDeification() {
    if (nft.squadId !== 0) {
      Messenger.warn(`Beast #${nft.id} is in a squad. Please remove it from the squad first.`);
      return;
    }

    buyDeification(nft.id);
  }

  function handleBuyDissolution() {
    // TODO! Check all nfts ready to be sac'd
    if (nft.squadId !== 0) {
      Messenger.warn(`Beast #${nft.id} is in a squad. Please remove it from the squad first.`);
      return;
    }

    const nftIds = [1]; // TODO!
    buyDissolution([nftIds]);
  }

  function handleBuyExtractionWithBusd(levels, traitId, squadId) {
    if (nft.squadId !== 0) {
      Messenger.warn(`This beast is in a squad. Please remove it from the squad first.`);
      return;
    }
    const squad = player.squads.find((squad) => squad.id === squadId);
    if (squad?.quest) {
      Messenger.warn(`This squad is on a quest, please finish the quest first.`);
      return;
    }
    console.log("Squad NOT on a quest:", squad);

    buyExtraction(nft.id, traitId, squadId, true, currentPriceInBusd);
  }

  function onTraitSelect(selectedTraitList) {
    // check the trait list for special traits (ie. id > 50)
    // the more there are, the greater the price in Dragon Scales
    // if there is one, the price is 9 Dragon Scales
    // for two it costs 27 Dragons Scales, and for three it costs 81 Dragon Scales
    let _specialTraitsCount = 0;
    for (let i = 0; i < selectedTraitList.length; i++) {
      const trait = selectedTraitList[i];
      if (trait > 50) {
        _specialTraitsCount++;
      }
    }
    setSpecialTraitsCount(_specialTraitsCount);
    updateCurrentPrices(null, null, _specialTraitsCount);
  }

  function onSquadSelect(squadId) {
    // do nothing for now
  }

  function _getRankUpPrices(forceLevel = null) {
    if (!nft) {
      return {
        bct: BigNumber(0),
        resourceCents: [0],
        resourceIndexes: [],
      };
    }

    return getRankUpPrices(
      nft,
      forceLevel ? forceLevel - nft.rank : rankUpLevels - nft.rank,
      blockchainState?.scales?.upgradesScale
    );
  }

  function _getAgilityUpPrices(forceLevel = null) {
    return getAgilityUpPrices(
      nft,
      forceLevel ? forceLevel - nft.agility : agilityLevels - nft.agility,
      blockchainState?.scales?.upgradesScale
    );
  }

  function getNutsLevelPrices(forceLevel = null) {
    return getNutsUpPrices(
      nft,
      forceLevel ? forceLevel - nft.nuts : nutsLevels - nft.nuts,
      blockchainState?.scales?.upgradesScale
    );
  }

  function getSkill1LevelPrices(forceLevel = null) {
    return getSkillLevelPrices(
      nft,
      1,
      forceLevel ? forceLevel - nft.skills[0] : skill1Levels - nft.skills[0],
      blockchainState?.scales?.upgradesScale
    );
  }

  function getSkill2LevelPrices(forceLevel = null) {
    return getSkillLevelPrices(
      nft,
      2,
      forceLevel ? forceLevel - nft.skills[1] : skill2Levels - nft.skills[1],
      blockchainState?.scales?.upgradesScale
    );
  }

  function getSkill3LevelPrices(forceLevel = null) {
    return getSkillLevelPrices(
      nft,
      3,
      forceLevel ? forceLevel - nft.skills[2] : skill3Levels - nft.skills[2],
      blockchainState?.scales?.upgradesScale
    );
  }

  function getSkill4LevelPrices(forceLevel = null) {
    return getSkillLevelPrices(
      nft,
      4,
      forceLevel ? forceLevel - nft.skills[3] : skill4Levels - nft.skills[3],
      blockchainState?.scales?.upgradesScale
    );
  }

  function getSkill5LevelPrices(forceLevel = null) {
    return getSkillLevelPrices(
      nft,
      5,
      forceLevel ? forceLevel - nft.skills[4] : skill5Levels - nft.skills[4],
      blockchainState?.scales?.upgradesScale
    );
  }

  function getSkill6LevelPrices(forceLevel = null) {
    return getSkillLevelPrices(
      nft,
      6,
      forceLevel ? forceLevel - nft.skills[5] : skill6Levels - nft.skills[5],
      blockchainState?.scales?.upgradesScale
    );
  }

  function getTraitRerollUpPrices() {
    return getTraitRerollPrices(nft, blockchainState?.scales?.upgradesScale);
  }

  function getAddTraitUpPrices() {
    return getAddTraitPrices(nft, blockchainState?.scales?.upgradesScale);
  }

  function getReassembleUpPrices() {
    return getReassemblePrices(nft, blockchainState?.scales?.upgradesScale);
  }

  function getIntegrationUpPrices() {
    return getIntegrationPrices(nft, blockchainState?.scales?.upgradesScale);
  }

  function getTransformationUpPrices() {
    return getTransformationPrices(nft, blockchainState?.scales?.upgradesScale);
  }

  function getEvolutionUpPrices() {
    return getEvolutionPrices(nft, blockchainState?.scales?.upgradesScale);
  }

  function getExtractionUpPrices() {
    return getExtractionPrices(nft, blockchainState?.scales?.upgradesScale);
  }

  function getDeificationUpPrices() {
    return getDeificationPrices(nft, blockchainState?.scales?.upgradesScale);
  }

  function getTransmuteUpPrices(ignored = null, forceSpecialTraitCount = 0) {
    return getTransmutePrices(nft, forceSpecialTraitCount, blockchainState?.scales?.upgradesScale);
  }

  function getDissolutionUpPrices() {
    const howMany = 1; // TODO!
    return getDissolutionPrices(howMany, blockchainState?.scales?.upgradesScale);
  }

  function selectPerkByIndex(index) {
    setSelectedPerk(perks[index]);
    updateCurrentPrices(perks[index]);
    setCurrentlySelectedPerkIndex(index);
    resetCardPreview();
    setCurrentLootSkillButtons(getUpgradeResourceButtons(nft, perks[index]));
  }

  function resetCardPreview() {
    setTempName(nft.name);
    setTempImageUrl(nft.imageUrl);
    setRankUpLevels(nft.rank);
    setAgilityLevels(nft.agility);
    setSkill1Levels(nft.skills[0]);
    setSkill2Levels(nft.skills[1]);
    setSkill3Levels(nft.skills[2]);
    setSkill4Levels(nft.skills[3]);
    setSkill5Levels(nft.skills[4]);
    setSkill6Levels(nft.skills[5]);
    setNutsLevels(nft.nuts);
    setTempNft({ ...nft });
  }

  function onRankSelect() {
    selectPerkByIndex(0);
  }

  function onAgilitySelect() {
    selectPerkByIndex(1);
  }

  function onBaseFarmBoostSelect() {
    selectPerkByIndex(2);
  }

  function onSkill1Select() {
    selectPerkByIndex(3);
  }

  function onSkill2Select() {
    selectPerkByIndex(4);
  }

  function onSkill3Select() {
    selectPerkByIndex(5);
  }

  function onSkill4Select() {
    selectPerkByIndex(6);
  }

  function onSkill5Select() {
    selectPerkByIndex(14);
  }

  function onSkill6Select() {
    selectPerkByIndex(15);
  }

  function onEvolveSelect() {
    selectPerkByIndex(7);

    const isFirstEvo = nft.evos === 0;
    const canUpgrade = nft.evos < 11;

    setTempNft({ ...nft, evos: canUpgrade ? nft.evos + 1 : nft.evos });

    if (canUpgrade) {
      updateTempNft(
        "baseFarm",
        isFirstEvo ? BigNumber(nft.baseFarm).times(1.25) : BigNumber(nft.baseFarm).times(1.02)
      );
    }
  }

  function onIntegrateSelect() {
    selectPerkByIndex(8);
  }

  function onTransformSelect() {
    selectPerkByIndex(9);
  }

  function onRerollSelect() {
    selectPerkByIndex(10);
    setSelectedTrait(0);
  }

  function onReassembleSelect() {
    selectPerkByIndex(13);
    setSelectedTrait(0);
  }

  function onAddSelect() {
    selectPerkByIndex(11);
    setSelectedTrait(0);
  }

  function onExtractSelect() {
    selectPerkByIndex(12);
    setSelectedTrait(0);
  }

  function onDeificationSelect() {
    selectPerkByIndex(16);
    setSelectedTrait(0);

    const newProps = getDeifiedNftPropsFrom(tempNft);

    if (newProps.type_ === 0) {
      // we can't do this, so we'll just render the current nft
      setTempNft({
        ...tempNft,
      });
    } else {
      setTempNft({
        ...tempNft,
        ...newProps,
      });
    }
  }

  function onTransmuteSelect() {
    selectPerkByIndex(17);
    setSelectedTrait(0);
  }

  function onDissolutionSelect() {
    selectPerkByIndex(18);
    setSelectedTrait(0);
  }

  const perks = getPerksArray({
    prices: {
      rank: _getRankUpPrices,
      agility: _getAgilityUpPrices,
      baseFarm: getNutsLevelPrices,
      skill1: getSkill1LevelPrices,
      skill2: getSkill2LevelPrices,
      skill3: getSkill3LevelPrices,
      skill4: getSkill4LevelPrices,
      skill5: getSkill5LevelPrices,
      skill6: getSkill6LevelPrices,
      reroll: getTraitRerollUpPrices,
      addTrait: getAddTraitUpPrices,
      reassemble: getReassembleUpPrices,
      integration: getIntegrationUpPrices,
      transformation: getTransformationUpPrices,
      evolution: getEvolutionUpPrices,
      extraction: getExtractionUpPrices,
      deification: getDeificationUpPrices,
      transmute: getTransmuteUpPrices,
      dissolution: getDissolutionUpPrices,
    },
    update: {
      rank: onRankLevelUpdate,
      agility: onAgilityLevelUpdate,
      baseFarm: onBaseFarmLevelUpdate,
      skill1: onSkill1LevelUpdate,
      skill2: onSkill2LevelUpdate,
      skill3: onSkill3LevelUpdate,
      skill4: onSkill4LevelUpdate,
      skill5: onSkill5LevelUpdate,
      skill6: onSkill6LevelUpdate,
    },
    buyFunc: {
      resources: {
        rank: handleBuyRankUpWithResources,
        agility: handleBuyAgilityUpWithResources,
        baseFarm: handleBuyNutsUp,
        skill1: handleBuySkill1UpWithResources,
        skill2: handleBuySkill2UpWithResources,
        skill3: handleBuySkill3UpWithResources,
        skill4: handleBuySkill4UpWithResources,
        skill5: handleBuySkill5UpWithResources,
        skill6: handleBuySkill6UpWithResources,
        reroll: handleBuyTraitReroll,
        addTrait: handleBuyAddTrait,
        reassemble: handleBuyReassemble,
        transmute: handleBuyTransmute,
        integration: handleBuyIntegration,
        transformation: handleBuyTransformation,
        evolution: handleBuyEvolution,
        extraction: handleBuyExtraction,
        deification: handleBuyDeification,
        dissolution: handleBuyDissolution,
      },
      busd: {
        rank: null,
        agility: handleBuyAgilityUpWithBusd,
        baseFarm: null,
        skill1: handleBuySkill1UpWithBusd,
        skill2: handleBuySkill2UpWithBusd,
        skill3: handleBuySkill3UpWithBusd,
        skill4: handleBuySkill4UpWithBusd,
        skill5: handleBuySkill5UpWithBusd,
        skill6: handleBuySkill6UpWithBusd,
        reroll: null,
        addTrait: null,
        reassemble: null,
        integration: null,
        transformation: null,
        evolution: null,
        extraction: handleBuyExtractionWithBusd,
      },
    },
  });
  const [selectedPerk, setSelectedPerk] = useState({
    title: "Upgrades",
    description:
      "Upgrades are the core of Beast Kingdom. They allow you to improve your farming experience in several ways. Every upgrade costs something different, and that's one of the reasons why you pick specific quests for your squads: to get what you need to make your beasts stronger. Choose one of the upgrades above to see more details. ",
    isHeader: true,
    hasTraitPicker: false,
    description2: null,
    forbiddenRarities: [],
  });

  async function onNewImageUrlSet(newImageUrl) {
    if (!tempNft) return;

    let tempNewImgUrl = newImageUrl.trim();
    if (tempNewImgUrl === "" || !tempNewImgUrl) {
      tempNewImgUrl = nft.imageUrl;
    }

    setIsLoading(true);

    setTempImageUrl(tempNewImgUrl);

    setTempNft({
      ...tempNft,
      imageUrl: tempNewImgUrl,
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }

  function getDefaultNftName() {
    if (!tempNft) return "";

    if (!tempNft.name || tempNft.name.trim() === '""') {
      const name = `${getRarity(tempNft.rarity)} ${getType(tempNft.type_)}`;
      return name;
    }

    return tempNft.name;
  }

  function getCurrentImageUrl() {
    if (!tempNft) return "";

    if (!tempNft.imageUrl || tempNft.imageUrl.trim() === '""') {
      return 'Paste the URL of your image ending in ".png"';
    }

    return tempNft.imageUrl;
  }

  function onNewNameSet(newName) {
    setTempName(newName);

    setTempNft({
      ...tempNft,
      name: newName,
    });
  }

  function onExtraHelpButtonClick() {
    setIsExtraHelpModalOpen(true);
  }

  function onExtraHelpModalClose() {
    if (isExtraHelpModalOpen) {
      setIsExtraHelpModalOpen(false);
    }
  }

  if (!nft) return null;

  function getUpgradeResourceButtons(_nft, forcePerk = null) {
    const buttons = [
      {
        title: "Basic Loot Skill",
        resource: _nft.type_ - 1,
        currentQuantity: _nft.skills[0],
        maxQuantity: "5",
        onClick: onSkill1Select,
        isActive: forcePerk
          ? forcePerk.title === "Basic Loot Skill"
          : selectedPerk.title === "Basic Loot Skill",
      },
      {
        title: "Advanced Loot Skill",
        resource: _nft.type_ + 4,
        currentQuantity: _nft.skills[1],
        maxQuantity: "5",
        onClick: onSkill2Select,
        isActive: forcePerk
          ? forcePerk.title === "Advanced Loot Skill"
          : selectedPerk.title === "Advanced Loot Skill",
      },
      {
        title: "Golden Nuts Skill",
        resource: 10,
        currentQuantity: _nft.skills[2],
        maxQuantity: "5",
        onClick: onSkill3Select,
        isActive: forcePerk
          ? forcePerk.title === "Golden Nuts Skill"
          : selectedPerk.title === "Golden Nuts Skill",
      },
      {
        title: "Evolution Items Skill",
        resource: 1118,
        currentQuantity: _nft.skills[3],
        maxQuantity: "5",
        onClick: onSkill4Select,
        isActive: forcePerk
          ? forcePerk.title === "Evolution Items Skill"
          : selectedPerk.title === "Evolution Items Skill",
      },
    ];

    if (_nft.type_ >= 6) {
      buttons.splice(1, 0, {
        title: "Mythic Basic Loot",
        resource: _nft.type_,
        currentQuantity: _nft.skills[4],
        maxQuantity: "5",
        onClick: onSkill5Select,
        isActive: forcePerk
          ? forcePerk.title === "Mythic Basic Loot"
          : selectedPerk.title === "Mythic Basic Loot",
      });

      // now, we also have to change all maxQuantity proporties to 7
      buttons.forEach((button) => {
        button.maxQuantity = "7";
      });
    }

    // if the type of the current nft is >= 11 && <=15, then we can add the "Titanic Advanced Loot" button in the fourth position of the array
    if (_nft.type_ >= 11) {
      buttons.splice(3, 0, {
        title: "Titanic Advanced Loot",
        resource: _nft.type_ + 5,
        currentQuantity: _nft.skills[5],
        maxQuantity: "5",
        onClick: onSkill6Select,
        isActive: forcePerk
          ? forcePerk.title === "Titanic Advanced Loot"
          : selectedPerk.title === "Titanic Advanced Loot",
      });

      // now, we also have to change all maxQuantity proporties to 10
      buttons.forEach((button) => {
        button.maxQuantity = "10";
      });
    }

    return buttons;
  }

  return (
    currentLootSkillButtons &&
    selectedPerk &&
    tempNft && (
      <>
        <UpgradesModal
          title={selectedPerk?.extraHelpContent?.title || ""}
          description1={selectedPerk?.extraHelpContent?.description1 || ""}
          description2={selectedPerk?.extraHelpContent?.description2 || ""}
          description3={selectedPerk?.extraHelpContent?.description3 || ""}
          link1={selectedPerk?.extraHelpContent?.link1 || null}
          link2={selectedPerk?.extraHelpContent?.link2 || null}
          link3={selectedPerk?.extraHelpContent?.link3 || null}
          isOpen={isExtraHelpModalOpen}
          onClose={onExtraHelpModalClose}
        ></UpgradesModal>
        <Container>
          <div className="row">
            <div className="col-md-6">
              <UpgradeGroupRowContainer>
                <UpgradeGroup
                  title="BCT/eBCT Farming Power"
                  startCollapsed={dimensions.width < 768}
                  isCollapsable={dimensions.width < 768}
                  buttons={[
                    {
                      title: "Rank",
                      resource: 201,
                      currentQuantity: nft.rank,
                      maxQuantity: "99",
                      onClick: onRankSelect,
                      isActive: selectedPerk.title === "Rank",
                    },
                    {
                      title: "Agility",
                      resource: 202,
                      currentQuantity: nft.agility,
                      maxQuantity: "5",
                      onClick: onAgilitySelect,
                      isActive: selectedPerk.title === "Agility",
                    },
                    {
                      title: "Base Farm Boost",
                      resource: 203,
                      currentQuantity: nft.nuts,
                      maxQuantity: "50",
                      onClick: onBaseFarmBoostSelect,
                      isActive: selectedPerk.title === "Base Farm Boost",
                    },
                    {
                      title: "Evolution",
                      resource: 204,
                      currentQuantity: nft.evos,
                      maxQuantity: 11,
                      onClick: onEvolveSelect,
                      isActive: selectedPerk.title === "Evolution",
                    },
                  ]}
                />
              </UpgradeGroupRowContainer>
            </div>
            <div className="col-md-6">
              <UpgradeGroupRowContainer>
                <UpgradeGroup
                  title="Experiments"
                  startCollapsed={dimensions.width < 768}
                  isCollapsable={dimensions.width < 768}
                  buttons={[
                    {
                      title: "Integrate",
                      resource: 205,
                      currentQuantity: 0,
                      maxQuantity: 0,
                      onClick: onIntegrateSelect,
                      isActive: selectedPerk.title === "Integrate",
                    },
                    {
                      title: "Transform",
                      resource: 206,
                      currentQuantity: 0,
                      maxQuantity: 0,
                      onClick: onTransformSelect,
                      isActive: selectedPerk.title === "Transform",
                    },
                    {
                      title: "Deify",
                      resource: 215,
                      currentQuantity: 0,
                      maxQuantity: 0,
                      onClick: onDeificationSelect,
                      isActive: selectedPerk.title === "Deify",
                    },
                    {
                      title: "Extract",
                      resource: 216,
                      currentQuantity: 0,
                      maxQuantity: 0,
                      onClick: onExtractSelect,
                      isActive: selectedPerk.title === "Extract",
                    },
                    /*
                    {
                      title: "Dissolve",
                      resource: 216,
                      currentQuantity: 0,
                      maxQuantity: 0,
                      onClick: onDissolutionSelect,
                      isActive: selectedPerk.title === "Dissolve",
                    },
                    */
                  ]}
                />
              </UpgradeGroupRowContainer>
            </div>
            <div className="col-md-6">
              <UpgradeGroupRowRightContainer>
                <UpgradeGroup
                  key={`lootSkills-${nft.id}`}
                  title="Resource Looting Skills"
                  startCollapsed={dimensions.width < 768}
                  isCollapsable={dimensions.width < 768}
                  buttons={currentLootSkillButtons}
                  groupWidth={nft.type_ >= 6 && nft.type_ <= 10 ? "370px" : "300px"}
                />
              </UpgradeGroupRowRightContainer>
            </div>
            <div className="col-md-6">
              <UpgradeGroupRowRightContainer>
                <UpgradeGroup
                  title="Bestial Traits"
                  startCollapsed={dimensions.width < 768}
                  isCollapsable={dimensions.width < 768}
                  buttons={[
                    {
                      title: "Trait Reroll",
                      resource: 207,
                      currentQuantity: 0,
                      maxQuantity: 0,
                      onClick: onRerollSelect,
                      isActive: selectedPerk.title === "Trait Reroll",
                    },
                    {
                      title: "Reassemble",
                      resource: 214,
                      currentQuantity: 0,
                      maxQuantity: 0,
                      onClick: onReassembleSelect,
                      isActive: selectedPerk.title === "Reassemble",
                    },
                    {
                      title: "Add Trait",
                      resource: 208,
                      currentQuantity: nft.traits.length,
                      maxQuantity: 3,
                      onClick: onAddSelect,
                      isActive: selectedPerk.title === "Add Trait",
                    },
                    {
                      title: "Transmute",
                      resource: 217,
                      currentQuantity: 0,
                      maxQuantity: 0,
                      onClick: onTransmuteSelect,
                      isActive: selectedPerk.title === "Transmute",
                    },
                  ]}
                />
              </UpgradeGroupRowRightContainer>
            </div>
            <div className="col-md-6">
              <ActionContainer>
                {tempNft && (
                  <UpgradeBox
                    key={selectedPerk.title}
                    title={selectedPerk.title}
                    description={selectedPerk.description}
                    description2={selectedPerk.description2}
                    eBctPrice={currentPriceInResources.bctPrice}
                    resourcePrices={currentPriceInResources.resourceCents}
                    resourceIndexes={currentPriceInResources.resourceIndexes}
                    currentLevel={eval(selectedPerk.currentLevelReference)}
                    previewLevel={eval(selectedPerk.previewLevelReference)}
                    maxLevel={
                      nft.type_ >= 6
                        ? nft.type_ >= 11
                          ? selectedPerk.titanicMaxLevel
                          : selectedPerk.mythicMaxLevel
                        : selectedPerk.maxLevel
                    }
                    busdPrice={currentPriceInBusd}
                    player={player}
                    updatePrice={selectedPerk.updateFunction}
                    buyFunction={selectedPerk.buyFunction}
                    buyWithBusdFunction={selectedPerk.buyWithBusdFunction}
                    buyWithKoziFunction={selectedPerk.buyWithBusdFunction}
                    isReady={selectedPerk.isReady}
                    isHeader={selectedPerk.isHeader}
                    hasTraitPicker={selectedPerk.hasTraitPicker}
                    hasSecondaryTraitPicker={selectedPerk.hasSecondaryTraitPicker}
                    hasTertiaryTraitPicker={selectedPerk.hasTertiaryTraitPicker}
                    traitPickerForceTraits={
                      selectedPerk?.useNftTraitsInTraitPicker ? nft.traits : []
                    }
                    traitSelectCallback={onTraitSelect}
                    secondaryTraitSelectCallback={onTraitSelect}
                    tertiaryTraitSelectCallback={onTraitSelect}
                    injectTraitZero={selectedPerk?.injectTraitZero}
                    hasSquadPicker={selectedPerk?.hasSquadPicker}
                    squadSelectCallback={onSquadSelect}
                    squadPickerForceSquads={player.squads}
                    sacrificeSlots={selectedPerk.sacrificeSlots}
                    isForbidden={selectedPerk.forbiddenRarities.includes(nft.rarity)}
                    isBlocked={
                      selectedPerk.sacrificeSlots > 0 && sacrificeSelectedNfts.length !== 2
                    }
                    hasInputUrl={selectedPerk.hasInputText}
                    inputUrlDefaultValue={getCurrentImageUrl()}
                    inputUrlCallback={onNewImageUrlSet}
                    inputNameDefaultValue={getDefaultNftName()}
                    inputNameCallback={onNewNameSet}
                    isLoading={isLoading}
                    onExtraHelpButtonClick={
                      selectedPerk?.extraHelpContent ? onExtraHelpButtonClick : null
                    }
                    omitLevelPicker={selectedPerk.omitLevelPicker}
                    isOverMaxType={selectedPerk.maxType < nft.type_}
                    mustButDoesntHave3Traits={selectedPerk.mustHave3Traits && nft.traits.length < 3}
                    useExtendedTraitList={selectedPerk.useExtendedTraitList}
                    hasProportionPicker={selectedPerk.hasProportionPicker}
                    isExtraction={selectedPerk.title === "Extract"}
                  />
                )}
              </ActionContainer>
            </div>
            <div className="col-md-6" style={{ display: "flex", justifyContent: "center" }}>
              <CardContainer sacrificeSlots={selectedPerk.sacrificeSlots}>
                {tempNft && (
                  <>
                    <Card
                      nft={tempNft}
                      scale={1}
                      extraOutline={true}
                      clickToResetFreeSelection={true}
                      lockSize={true}
                    />
                    {selectedPerk.sacrificeSlots > 0 && (
                      <SacrificeContainer>
                        <Card
                          nft={sacrificeSelectedNfts.length > 0 ? sacrificeSelectedNfts[0] : null}
                          scale={0.47}
                          extraOutline={true}
                          extraOutlineColor="#ff0000"
                          clickToSelectNftFromInventory={true}
                          reasonToSelect="sacrifice"
                          lockSize={true}
                        />
                        <Card
                          nft={sacrificeSelectedNfts.length > 1 ? sacrificeSelectedNfts[1] : null}
                          scale={0.47}
                          extraOutline={true}
                          extraOutlineColor="#ff0000"
                          clickToSelectNftFromInventory={true}
                          reasonToSelect="sacrifice"
                          lockSize={true}
                        />
                      </SacrificeContainer>
                    )}
                  </>
                )}
              </CardContainer>
            </div>
          </div>
        </Container>
      </>
    )
  );
};

export default UpgradesBlock;
