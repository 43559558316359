import styled from "styled-components";

export const Container = styled.div`
  width: 600px;
  padding: 20px;
  border-radius: 8px;
  outline: 3px solid #fff;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const TimeframeButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TimeframeButton = styled.div`
  width: 23%;
  height: 100%;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  color: ${(props) => (props.isNegative ? "#f88" : "#8f8")};
  font-weight: bold;
  text-align: center;
  font-size: 12px;

  &:hover {
    background: linear-gradient(0deg, rgba(16, 59, 83, 1), rgba(15, 22, 42, 1));
  }

  ${(props) =>
    props.selected &&
    `
    background: linear-gradient(0deg, rgba(16, 59, 83, 1), rgba(15, 22, 42, 1));
    outline: 2px solid #fff;
  `}
`;
