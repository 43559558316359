import { MktplaceDragonBonus } from "../../utils/constants";

export const CATEGORIES = [
  /*
  {
    id: 1,
    name: "Prosperity Wars",
    code: "dutch-auctions",
    useBeastFilters: false,
    useTwoPathBits: false,
    forceId: false,
    description: (
      <>
        <p>
          In these auctions, the price drops gradually over exactly 3 hours, with reductions
          reaching up to 75%. As the auction progresses, the price keeps falling in real-time, and
          the item goes to the first person who places a bid.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          The final price is determined when the transaction is mined by the blockchain. If the
          price continues to decrease as you initiate the purchase, you will typically pay slightly
          less than the last price you saw before confirming the transaction.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          {`Bonus: For every ${
            MktplaceDragonBonus / 100
          } USDC you spend on Spiral Auctions, both you and the seller will receive
          0.01 Dragon Scales!`}
        </p>
      </>
    ),
  },
  */
  {
    id: 2,
    name: "Buy Beasts",
    code: "buy-beasts",
    useBeastFilters: true,
    useTwoPathBits: false,
    forceId: false,
    description: (
      <>
        <p>
          Search the Marketplace for Beasts that are for sale. You can filter by species, rarity,
          trait, and price. Once you find a Beast you like, you can buy it instantly.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          {`Bonus: For every ${
            MktplaceDragonBonus / 100
          } USDC you spend on buying Beasts, both you and the seller will receive
          0.01 Dragon Scales!`}
        </p>
      </>
    ),
  },
  {
    id: 3,
    name: "Buy Resources",
    code: "buy-resources",
    useBeastFilters: false,
    useTwoPathBits: false,
    forceId: false,
    description: (
      <>
        <p>
          These are the most competitive prices for resources on the market. You have the
          flexibility to decide the quantity of resources you want to purchase from each order.
        </p>
        <p>
          When a sell order is fully satisfied, the order with the next most favorable price takes
          its position.
        </p>
        <p>
          To view a comprehensive list of sellers and their respective prices, click the button
          located at the top-right corner of each resource.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          {`Bonus: For every ${
            MktplaceDragonBonus / 100
          } USDC you spend on buying resources, both you and the seller will
          receive 0.01 Dragon Scales!`}
        </p>
      </>
    ),
  },
  {
    id: 4,
    name: "Sell Beasts",
    code: "sell-beasts",
    useBeastFilters: false,
    useTwoPathBits: true,
    forceId: true,
    description: (
      <>
        <p>
          To create a sell order for Beasts, begin by selecting the specific Beast you wish to sell.
        </p>
        <p>
          Next, define the price either in eBCT, BCT or USDC. The price you manually define becomes
          locked, and the system will automatically update the other prices whenever the price of
          BCT fluctuates.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          After specifying the Beast and determining the price, you can create your sell order. When
          a sell order is created, the Beast leaves your inventory until the order is fulfilled. If
          necessary, you can cancel your sell order at any time, and the Beast will be returned to
          you.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          The price in eBCT is always 30% greater than the price in BCT. Prices are rounded from 18
          to 2 decimals for display purposes.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          Beasts that are in squads cannot be sold. Remove them from any squad before trying to sell
          them.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          After editing a price, press ENTER to confirm the change. If you don't, the button for
          creating the order will not become active.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          {`Bonus: For every ${
            MktplaceDragonBonus / 100
          } USDC a buyer spends on your Beasts, you and the buyer will each receive
          0.01 Dragon Scales!`}
        </p>
        <p className={"small"} style={{ color: "yellow" }}>
          {`Placing a Pool Fuel order means you will get zero Dollars out of it. You and the buyer will earn 50 times more Dragon Scales than usual, and the money goes to the community's Commonwealth Fund.`}
        </p>
      </>
    ),
  },
  {
    id: 5,
    name: "Sell Resources",
    code: "sell-resources",
    useBeastFilters: false,
    useTwoPathBits: false,
    forceId: false,
    description: (
      <>
        <p>
          To create a resource sell order, begin by specifying the quantity of resources you want to
          sell. The +/- buttons increase or decrease the quantity by 1, and the buttons with ++/--
          increase or decrease the quantity by 10. Other players will be able to buy any quantity of
          resources from your sell order.
        </p>
        <p>
          Next, define the UNITARY price either in eBCT, BCT or USDC. The price you manually define
          becomes locked, and the system will automatically update the other prices whenever the
          price of BCT fluctuates.
        </p>
        <p>
          Finally, click the checkbox icons to toggle whether you want to accept payments in eBCT
          and BCT.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          After determining quantity and price, you'll be able to create your sell order. When a
          sell order is created, the resources leave your inventory and balance until the order is
          fulfilled. Should you need to, you can cancel your sell orders at any point and reclaim
          any remaining assets.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          The price in eBCT is always 30% greater than the price in BCT. Prices are rounded from 18
          to 2 decimals for display purposes.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          After editing a price, press ENTER to confirm the change. If you don't, the button for
          creating the order will not become active.
        </p>
        <p className={"small"} style={{ color: "#bbb" }}>
          {`Bonus: For every ${
            MktplaceDragonBonus / 100
          } USDC a buyer spends on your resources, you and the buyer will each
          receive 0.01 Dragon Scales!`}
        </p>
        <p className={"small"} style={{ color: "yellow" }}>
          {`Placing a Pool Fuel order means you will get zero Dollars out of it. You and the buyer will earn 50 times more Dragon Scales than usual, and the money goes to the community's Commonwealth Fund.`}
        </p>
      </>
    ),
  },
  {
    id: 6,
    name: "My Active Orders",
    code: "my-orders",
    useBeastFilters: false,
    useTwoPathBits: false,
    forceId: false,
    description: (
      <>
        <p>
          This is a list of all your currently open sell orders. You can cancel any of these orders
          at any time by clicking the trash bin icon on top of each order.
        </p>
      </>
    ),
  },
  {
    id: 7,
    name: "Orders of",
    code: "orders-of",
    useBeastFilters: false,
    useTwoPathBits: true,
    forceId: true,
    description: (
      <>
        <p>This is a list of all currently open sell orders created by this single seller.</p>
      </>
    ),
  },
  {
    id: 8,
    name: "More Like This",
    code: "more-like-this",
    useBeastFilters: false,
    useTwoPathBits: true,
    forceId: true,
    description: (
      <>
        <p>
          More Like This: Here are the most competitive prices for this Resource and filters on the
          market.
        </p>
      </>
    ),
  },
  {
    id: 9,
    name: "Specific Order",
    code: "order",
    useBeastFilters: false,
    useTwoPathBits: true,
    forceId: true,
    description: "",
  },
];
