import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
`;

export const ModalContent = styled.div`
  padding: 20px;
  border-radius: 8px;
  outline: 3px solid #fff;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;
  cursor: default;
  max-width: 600px;
  max-height: 90%;
  overflow: auto;

  @media (max-width: 650px) {
    max-width: 90%;
  }

  h3 {
    text-align: center;
    margin-bottom: 20px;
    cursor: default;
  }

  p {
    font-family: "Metropolis-SemiBold", sans-serif;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    cursor: default;
  }

  button {
    margin: 0 10px;
    padding: 10px 20px;
    border-radius: 4px;
    font-family: "Metropolis", sans-serif;
    font-weight: 600;
  }
`;

export const Title = styled.h3`
  font-size: 28px;
  margin-top: 0;
  cursor: pointer;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 1.2;
  margin-top: 4px;
  margin-bottom: 16px;
  font-family: "Metropolis", sans-serif !important;
  font-weight: 600;
  letter-spacing: -0.02em;

  color: ${(props) => (props.highlight ? "#ffff88" : "white")};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const GotItButton = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  font-family: "Metropolis", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.02em;
  padding: 10px 20px;
  border: 2px solid #000;
  border-radius: 4px;
  width: 100%;
`;
