import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 28px;
  max-height: 36px;
  border-radius: 10px;
  overflow: hidden;
  background: ${(props) => props.background};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 44px;
  background: ${(props) => props.background};
`;

export const Image = styled.img`
  width: 100%;
  object-fit: contain;

  ${(props) =>
    props.disabled &&
    css`
      filter: brightness(0) saturate(100%) invert(50%) sepia(0%) saturate(6211%) hue-rotate(22deg)
        brightness(107%) contrast(85%) !important;
    `}
`;

export const TextContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 100%;

  // if quantity is 0, the text should be gray
  ${(props) =>
    (props.quantity === 0 || props.quantity === "0.00" || props.quantity === "0") &&
    css`
      color: #bbb;
    `}

  h6 {
    font-size: 20px;
  }

  input {
    outline: none;
    border: none;
    box-shadow: none;

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
`;

export const Input = styled.input`
  font-size: 20px;
  font-family: "Metropolis", sans-serif;
  color: #fff !important;
  text-align: center;
  outline: none;
  border: none;
  box-shadow: none;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  // if we have a props.disabled = true, the input should be gray (#888), else, fi we have a props.tooHigh = true, the input should be red
  ${(props) =>
    props.disabled
      ? css`
          color: #888 !important;
        `
      : props.tooHigh
      ? css`
          color: #ff0000 !important;
        `
      : css`
          color: #fff !important;
        `}
`;

export const GradientContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: default;
  }
`;

export const EbctContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 44px;
  font-size: 18px;
  background: ${(props) => props.background};

  @media (max-width: 450px) {
    width: 28px;
    height: 38px;
  }
`;
