import { ENV } from "../utils/environment";
import config from "../config/config";

import React, { createContext, useContext } from "react";
import BigNumber from "bignumber.js";
import Messenger from "../utils/Messenger";

import { useWeb3Context } from "./Web3Context";
import { usePlayerContext } from "./PlayerContext";

export const MarketplaceContext = createContext({});

export const useMarketplaceContext = () => {
  return useContext(MarketplaceContext);
};

export const MarketplaceProvider = ({ children }) => {
  const { isReady, execFunction } = useWeb3Context();
  const { player } = usePlayerContext();

  /*
  @SOL:
  function createOrder(
    Asset memory asset,
    Price memory price,
    Players memory players,
    bool isDutchAuction,
    bool isPoolFuel
  )
  Example:
  { isNft: false, id: 0, quantity: 100 },
  { isNominalInStabletoken: false, isStabletokenOnly: false, amount: toWei("1"), allowEbct: false, secondaryReceiverPct: 0 },
  { seller: alice.address, secondaryReceiver: zeroAddress, privateFor: zeroAddress },
  false,
  false
  */
  const createOrder = async (asset, price, players, isDutchAuction, isPoolFuel) => {
    if (!isReady) return;
    if (!player) return;

    let url;
    if (window.location.href.includes("/sell-beasts/")) {
      let parts = window.location.href.split("/");
      parts.pop();
      url = parts.join("/");
    } else {
      url = window.location.href;
    }

    const allowance = asset.isNft
      ? {
          tokenContractName: "BkNft",
          addressToAllow: config[ENV].Marketplace.address,
          allowedContractName: "Marketplace",
          allowedAssetDescription: "Beasts in your wallet",
          successMessage: "Allowance given to Marketplace",
          type: "ERC721",
        }
      : null;

    const success = await execFunction({
      contractName: "Marketplace",
      functionName: "createOrder",
      functionParams: [asset, price, players, isDutchAuction, isPoolFuel],
      allowance,
      successMessage: `Sell order successfully created! Please allow up to 2 minutes for it to be displayed on the website. If you've linked your blockchain address with your Discord account, you'll receive real-time updates on your order's status via Discord. Haven't linked them yet? Head over to the menu and navigate to Community => Discord to set it up.`,
      errorList: [
        {
          reason: "a",
          message: `Press ENTER in each price field that you're editing before submitting the order.`,
        },
        {
          reason: "ERC721: transfer of token that is not own",
          message: `You don't currently own this NFT. If you've recently listed it for sale, please refresh the page.`,
        },
        {
          reason: "g",
          message: `Dragon Scales cannot be used to fuel the pool, but nice try! 😀`,
        },
      ],
      navigateOnSuccess: url,
    });

    if (success) {
      return true;
    } else {
      return false;
    }
  };

  const executeOrder = async (orderId, quantity, payWithBusd, payWithEbct) => {
    if (!isReady) return;
    if (!player) return;

    let allowance;
    if (payWithBusd) {
      allowance = {
        tokenContractName: "Stabletoken",
        addressToAllow: config[ENV].Marketplace.address,
        allowedContractName: "Marketplace",
        allowedAssetDescription: "USDC in your wallet",
        successMessage: "Allowance given to Marketplace",
      };
    } else if (!payWithBusd && !payWithEbct) {
      allowance = {
        tokenContractName: "BCT",
        addressToAllow: config[ENV].Marketplace.address,
        allowedContractName: "Marketplace",
        allowedAssetDescription: "BCT in your wallet",
        successMessage: "Allowance given to Marketplace",
      };
    } else {
      allowance = null;
    }

    await execFunction({
      contractName: "Marketplace",
      functionName: "executeOrder",
      functionParams: [orderId, quantity, payWithBusd, payWithEbct],
      allowance,
      successMessage: `You have successfully executed order ${orderId}!`,
      errorList: [
        {
          reason: "3",
          message: `You can't buy your own order.`,
        },
        {
          reason: "Panic due to OVERFLOW(17)",
          message: "Insufficient funds",
        },
        {
          reason: "1",
          message: `Bah, someone got there just before you did! This order has already been executed. Please reload the page to refresh all orders.`,
        },
        {
          reason: "8",
          message: `This order cannot be bought using that currency`,
        },
        {
          reason: "9",
          message: `This order cannot be bought using that currency`,
        },
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  const cancelOrder = async (orderId) => {
    if (!isReady) return;
    if (!player) return;

    await execFunction({
      contractName: "Marketplace",
      functionName: "cancelOrder",
      functionParams: [orderId],
      successMessage: `Order ${orderId} successfully cancelled`,
      navigateOnSuccess: window.location.href,
      errorList: [
        {
          reason: "12",
          message: `You can't cancel a Dutch Auction before it reaches minimum price.`,
        },
      ],
    });
  };

  const hasEnoughEbct = (amount) => {
    if (!player) return false;
    if (!player?.info?.etherealBct) return false;

    return BigNumber(player.info.etherealBct).gte(BigNumber(amount));
  };

  const hasEnoughBct = (amount) => {
    // the balance of BCT is the sum of the ingame BCT and the metamask BCT
    if (!player) return false;
    //if (!player?.info?.bctToClaim) return false;
    const bctToClaim = player?.info?.bctToClaim ? BigNumber(player.info.bctToClaim) : BigNumber(0);
    const metamaskBct = player?.metamaskBalances
      ? BigNumber(player.metamaskBalances[0].toString())
      : BigNumber(0);

    return bctToClaim.plus(metamaskBct).gte(BigNumber(amount));
  };

  const hasEnoughBusd = (amount) => {
    if (!player) return false;
    if (!player?.metamaskBalances) return false;

    if (player.metamaskBalances.length < 2) return false;

    return BigNumber(player.metamaskBalances[1].toString()).gte(BigNumber(amount));
  };

  const mustHaveEnough = (amount, currency) => {
    console.log("mustHaveEnough:", amount, currency);
    let hasEnough = false;
    switch (currency) {
      case "eBCT":
        hasEnough = hasEnoughEbct(amount);
        break;
      case "BCT":
        hasEnough = hasEnoughBct(amount);
        break;
      case "USDC" || "BUSD":
        hasEnough = hasEnoughBusd(amount);
        break;
      default:
        break;
    }

    if (!hasEnough) {
      Messenger.warn(`You don't have enough ${currency} to execute this order.`);
    }

    return hasEnough;
  };

  const bidOnAuction = async (orderId, bid, isPowerBid) => {
    if (!isReady) return;
    if (!player) return;

    const allowance = {
      tokenContractName: "Stabletoken",
      addressToAllow: config[ENV].Marketplace.address,
      allowedContractName: "Marketplace",
      allowedAssetDescription: "USDC in your wallet",
      successMessage: "Allowance given to Marketplace",
    };

    await execFunction({
      contractName: "Marketplace",
      functionName: "bidOnAuction",
      functionParams: [orderId, bid, isPowerBid],
      allowance,
      successMessage: `You made a ${
        isPowerBid ? "Power Bid" : `$${BigNumber(bid).div(1e18).toFixed(2)} bid`
      } on Auction #${orderId}. Check your Discord for more details.`,
      errorList: [
        {
          reason: "20",
          message: `You can't buy your own order.`,
        },
        {
          reason: "Panic due to OVERFLOW(17)",
          message: "Insufficient funds",
        },
        {
          reason: "21",
          message: `This auction is closed now. Only the winner can claim it.`,
        },
        {
          reason: "22",
          message: `Reverted: this shouldn't happen and is being looked into. Please use the Power Bid button instead.`,
        },
        {
          reason: "23",
          message: `You are already winning this auction.`,
        },

        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: window.location.href,
    });
  };

  return (
    <MarketplaceContext.Provider
      value={{
        createOrder,
        executeOrder,
        cancelOrder,
        mustHaveEnough,
        bidOnAuction,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  );
};
