class IntervalManager {
  constructor() {
    // Binding all methods
    Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach((method) => {
      this[method] = this[method].bind(this);
    });

    this.intervals = {};
  }

  _start(intervalName, intervalFunction, intervalTime) {
    this._stop(intervalName);
    this.intervals[intervalName] = setInterval(intervalFunction, intervalTime);
  }

  _stop(intervalName) {
    if (this.intervals[intervalName]) {
      clearInterval(this.intervals[intervalName]);
      this.intervals[intervalName] = null;
    }
  }

  start(intervalName, intervalFunction, intervalTime) {
    this._start(intervalName, intervalFunction, intervalTime);
  }

  stop(intervalName) {
    this._stop(intervalName);
  }

  stopAll() {
    Object.keys(this.intervals).forEach((intervalName) => {
      this._stop(intervalName);
    });
  }
}

const intervalManager = new IntervalManager();
export default intervalManager;
