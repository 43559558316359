import React, { useState, useEffect } from "react";

import {
  Container,
  BonusContainer,
  ResourcesRewardContainer,
  ResourceBonusWrapper,
} from "./styles";

import ResourceContainer from "../resource-container";
import { bonusToBackground, indexToBackground } from "../../../utils/constants";

const SquadBonus = ({ squad }) => {
  return (
    <Container>
      {squad && squad.multipliers && (
        <BonusContainer numberOfResources={6}>
          <ResourcesRewardContainer>
            <ResourceBonusWrapper key={"BCT"}>
              <ResourceContainer
                resource={99}
                quantity={squad.multipliers["BCT"]}
                background={bonusToBackground["BCT"]}
                alreadyInPct={true}
              />
            </ResourceBonusWrapper>
            <ResourceBonusWrapper key={"eBCT"}>
              <ResourceContainer
                resource={98}
                quantity={squad.multipliers["eBCT"]}
                background={bonusToBackground["eBCT"]}
                alreadyInPct={true}
              />
            </ResourceBonusWrapper>
            <ResourceBonusWrapper key={"evolutionItems"}>
              <ResourceContainer
                resource={1118}
                quantity={squad.multipliers["Evo"]}
                background={indexToBackground[1118]}
                alreadyInPct={true}
              />
            </ResourceBonusWrapper>
            <ResourceBonusWrapper key={"basicLoot"}>
              <ResourceContainer
                resource={"BL"}
                quantity={squad.multipliers["BL"]}
                background={bonusToBackground["BasicLoot"]}
                alreadyInPct={true}
              />
            </ResourceBonusWrapper>
            <ResourceBonusWrapper key={"advancedLoot"}>
              <ResourceContainer
                resource={"AL"}
                quantity={squad.multipliers["AL"]}
                background={bonusToBackground["Loot"]}
                alreadyInPct={true}
              />
            </ResourceBonusWrapper>
            <ResourceBonusWrapper key={"goldenNuts"} style={{ paddingRight: "0px" }}>
              <ResourceContainer
                resource={10}
                quantity={squad.multipliers["Nuts"]}
                background={indexToBackground[10]}
                alreadyInPct={true}
              />
            </ResourceBonusWrapper>
          </ResourcesRewardContainer>
        </BonusContainer>
      )}
    </Container>
  );
};

export default SquadBonus;
