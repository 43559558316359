import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Container, DropdownItem } from "./styles";

const SpeciesSelectDropdown = ({ speciesSelectCallback = null, speciesList = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState(null);
  const [selectedSpeciesName, setSelectedSpeciesName] = useState("Pick a Species");

  const onSpeciesSelect = (species) => {
    setSelectedSpecies(species);
    setSelectedSpeciesName(`${species.name}`);
    setIsOpen(false);

    if (speciesSelectCallback) {
      speciesSelectCallback(species.id);
    }
  };

  const dropDownForceStyle = {
    minWidth: "280px",
  };

  return (
    <Container itemCount={speciesList.length}>
      <div className="dropdown" style={dropDownForceStyle}>
        <Link
          to="#"
          className="btn-selector nolink"
          onClick={() => setIsOpen(!isOpen)}
          style={{ padding: "5px" }}
        >
          {selectedSpeciesName}
        </Link>
        {isOpen && (
          <ul style={{ overflowY: "auto" }}>
            {speciesList.map((species, index) => (
              <li key={index}>
                <DropdownItem onClick={() => onSpeciesSelect(species)}>{species.name}</DropdownItem>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Container>
  );
};

export default SpeciesSelectDropdown;
