import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  outline: 3px solid white;
  background: linear-gradient(0deg, rgba(15, 42, 62, 1), rgba(15, 52, 72, 1));

  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  width: 90%;
  max-width: 850px;
  max-height: 80%;
  overflow: auto;

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    font-family: "Metropolis-SemiBold", sans-serif;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .small-legal {
    margin-top: 20px;
    font-size: 12px;
    line-height: 1.4;
    color: yellow;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  button {
    margin: 0 10px;
    padding: 10px 20px;
    border-radius: 4px;
    font-family: "Metropolis", sans-serif;
    font-weight: 600;
    cursor: pointer;
  }

  .red-button {
    background-color: red;
    color: white;
    border: none;

    &:hover {
      background-color: #a00;
    }
  }

  .green-button {
    background-color: green;
    color: white;
    border: none;

    &:hover { 
      background-color: #060;
    }
  }

  .checkbox-container {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  input[type="checkbox"] {
    margin-right: 8px;
  }

  .checkbox-label {
    font-size: 12px;
  }

  @media (max-width: 450px) {
    p {
      font-size: 14px;
      line-height: 1.5;
    }
  }
`;
