class Debouncer {
  constructor() {
    this.bouncing = {};
  }

  debounce(bounceName, callbackFunction, timeInMs, ...args) {
    this.cancel(bounceName);
    // Wrap the callbackFunction in an arrow function to maintain its context
    this.bouncing[bounceName] = setTimeout(() => callbackFunction(...args), timeInMs);
  }

  cancel(bounceName) {
    if (this.bouncing[bounceName]) {
      clearTimeout(this.bouncing[bounceName]);
      this.bouncing[bounceName] = null;
    }
  }
}
const debouncer = new Debouncer();
export default debouncer;
