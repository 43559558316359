// now join them all:
const HOT_LIST = [
  322, 324, 383, 385, 386, 392, 457, 459, 461, 462, 464, 465, 466, 467, 469, 470, 471, 472, 473,
  474, 475, 476, 477, 478, 488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499, 500, 501,
  502, 503, 504, 505, 508, 521, 600, 737, 739, 740, 856, 912, 913, 914, 919, 920, 923, 924, 925,
  926, 927, 1086, 1091, 1102, 1190, 1193, 1194, 1195, 1196, 1197, 1203, 1204, 1205, 1437, 1440,
  1441, 1442, 1571, 1597, 1600, 1626, 1628, 1629, 1632, 1643, 1864, 1871, 1882, 1918, 2056, 2060,
  2062, 2188, 2930, 2937, 2954, 2965, 2970, 3082, 3088, 3094, 3098, 3103, 3474, 3481, 3921, 3923,
  3929, 3931, 3955, 4061, 4560, 4570, 5497, 5807, 5815, 5865, 6014, 6016, 6034, 6039, 7205, 7210,
  7244, 7245, 7246, 7257, 7272, 7430, 7431, 7432, 7476, 7477, 7478, 7484, 7485, 7486, 7487, 7538,
  7539, 7540, 7541, 7804, 7805, 7806, 7807, 7808, 8084, 8120, 8259, 8260, 8262, 8263, 8265, 8266,
  8267, 8268, 8269, 8270, 8326, 8329, 8330, 8331, 8332, 8333, 8344, 8345, 8346, 8347, 8348, 8434,
  8436, 8633, 8634, 8635, 8637, 8638, 8639, 8782, 8783, 8784, 8791, 8792, 8793, 8819, 8820, 8821,
  8822, 8823, 8836, 8837, 8838, 8839, 8840, 9237, 9239, 9294, 9296, 9307, 9322, 9323, 9324, 10001,
  10003, 10004, 10005, 10020, 10021, 10022, 10064, 10065, 10066, 10067, 10068, 10090, 10091, 10093,
  10122, 10123, 10124, 10160, 10161, 10162, 10163, 10164, 10184, 10279, 10436, 10497, 10499, 10510,
  10539, 10540, 10541, 10542, 10543, 10571, 10573, 10586, 10587, 10588, 10594, 10595, 10596, 10642,
  10643, 10644, 10681, 10682, 10687, 10688, 10689, 10690, 10691, 10692, 10693, 10694, 10696, 10720,
  10722, 10747, 10748, 10750, 10781, 10973, 10974, 10975, 10976, 10977, 10978, 10979, 10980, 11072,
  11073, 11074, 11342, 11345, 11347, 11398, 11399, 11400, 11410, 11411, 11412, 11417, 11419, 11420,
  11428, 11429, 11432, 11434, 11435, 11437, 11438, 11441, 11442, 11443, 11444, 11445, 11446, 11447,
  11448, 11449, 11450, 11451, 11452, 11453, 11454, 11455, 11456, 11457, 11458, 11459, 11460, 11461,
  11462, 11463, 11464, 11465, 11466, 11467, 11476, 11477, 11478, 11479, 11480, 11481, 11482, 11483,
  11484, 11485, 11486, 11487, 11488, 11489, 11490, 11491, 11492, 11493, 11494, 11495, 11496, 11497,
  11498, 11499, 11500, 11501, 11502, 11503, 11504, 11505, 11506, 11507, 11508, 11509, 11510, 11511,
  11512, 11513, 11514, 11516, 11569, 11780, 11788, 11790, 11850, 11937, 12023, 12116, 12124, 12201,
  12202, 12203, 12211, 12212, 12213, 12214, 12217, 12218, 12219, 12221, 12222, 12223, 12242, 12243,
  12244, 12245, 12246, 12357, 12388, 12391, 12392, 12527, 12528, 12529, 12532, 12533, 12534, 12675,
  12677, 12963, 13000, 13079, 13080, 13081, 13082, 13083, 13084, 13085, 13249, 13252, 13254, 13255,
  13258, 13264, 13265, 13266, 13507, 13900, 13901, 13902, 13903, 13904, 14146, 14233, 14248, 14249,
  14250, 14252, 14253, 14254, 14255, 14256, 14336, 14467, 14468, 14469, 14476, 14479, 14483, 14519,
  14521, 14522, 14523, 14524, 14525, 14544, 14545, 14546, 14547, 14548, 14555, 14556, 14557, 14561,
  14591, 14592, 14593, 14594, 14595, 14611, 14745, 14746, 14747, 14748, 14749, 14858, 14859, 14860,
  15033, 15034, 15035, 15036, 15037, 15145, 15146, 15147, 15148, 15149, 15150, 15151, 15152, 15153,
  15154, 15155, 15156, 15157, 15158, 15159, 15160, 15161, 15162, 15222, 15223, 15224, 15225, 15226,
  15227, 15228, 15229, 15230, 15231, 15232, 15233, 15234, 15265, 15266, 15267, 15274, 15388, 15389,
  15413, 15414, 15415, 15416, 15417, 15418, 15419, 15420, 15421, 15422, 15423, 15424, 15425, 15426,
  15427, 15428, 15429, 15430, 15431, 15432, 15433, 15434, 15435, 15436, 15437, 15438, 15439, 15440,
  15441, 15442, 15443, 15623, 15624, 15625, 15626, 15627, 15628, 15629, 15630, 15631, 15632, 23912,
  23913, 23914, 26834, 26837, 26838,
];

export function getHotIds(count) {
  // return `count` number of ids from the HOT_LIST at random
  // if count is greater than the length of the HOT_LIST, return the entire HOT_LIST
  // if count is 0, return an empty array

  if (count === 0) {
    return [];
  }

  if (count >= HOT_LIST.length) {
    return HOT_LIST;
  }

  const ids = [];
  while (ids.length < count) {
    const id = HOT_LIST[Math.floor(Math.random() * HOT_LIST.length)];
    if (!ids.includes(id)) {
      ids.push(id);
    }
  }

  return ids;
}
