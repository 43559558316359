import { useEffect, useState } from "react";

import Card from "../../card/Card";
import { Container, GridItem, TitleContainer, Title, Subtitle, GridKozika } from "./styles";

import { baseFarmPerSpeciesAndRarity } from "../../../utils/nft";
import { isEqual } from "../../../utils/address";
import { isPromoCollection } from "../../card/specialCollections";

const CardView = ({
  nftList = [],
  playerNftIds = [],
  title = "New Collection",
  species = 1,
  rarity = 4,
  trait = null,
  currentPlayerAddress = null,
  isComplete = false,
  customizedBeastNames = [],
  customizedBeastImages = [],
  injectPreview = false,
  isPromoCollection = false,
}) => {
  const [nftListState, setNftListState] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  useEffect(() => {
    if (!currentPlayerAddress) return;

    let updatedNftList = [...nftList];
    if (injectPreview) {
      if (nftList?.length === 0) {
        // this is a new collection; let's create 3 NFTs for it according to species and rarity
        const baseFarm = baseFarmPerSpeciesAndRarity(species, rarity, true);

        for (let i = 0; i < 3; i++) {
          updatedNftList.push({
            id: "NEW",
            type_: species,
            species: species,
            name: customizedBeastNames[i] || "",
            imageUrl: customizedBeastImages[i] || "",
            rarity,
            traits: trait ? [trait, 999] : [999, 999],
            skills: [1, 1, 0, 0, 0, 0],
            rank: 0,
            agility: 0,
            baseFarm,
            farmPerBlock: baseFarm,
            collection: 99,
            evos: 0,
            nuts: 0,
          });
        }
      } else if (
        isEqual(currentPlayerAddress, getCollectionMinter() || "") &&
        nftList?.length !== 0 &&
        nftList?.length < 18 &&
        !isComplete
      ) {
        // we own this collection and it's not complete, so we can expand it with 5 more nfts
        // let's inject 5 new NFTs in the first positions:
        const baseFarm = baseFarmPerSpeciesAndRarity(species, rarity, true);

        for (let i = 4; i >= 0; i--) {
          updatedNftList.unshift({
            id: "NEW",
            type_: species,
            species: species,
            name: customizedBeastNames[i] || "",
            imageUrl: customizedBeastImages[i] || "",
            rarity,
            traits: trait ? [trait, 999] : [999, 999],
            skills: [1, 1, 0, 0, 0, 0],
            rank: 0,
            agility: 0,
            baseFarm,
            farmPerBlock: baseFarm,
            collection: 99,
            evos: 0,
            nuts: 0,
          });
        }
      }
    }

    // now, let's complete 18 with empty items
    if (updatedNftList?.length < 18) {
      const emptyItems = 18 - updatedNftList.length;

      for (let i = 0; i < emptyItems; i++) {
        updatedNftList.push(null);
      }
    }

    setNftListState(updatedNftList);
  }, [species, rarity, trait, customizedBeastNames, customizedBeastImages]);

  function shouldHighlight(nft) {
    if (!nft) return false;
    if (!playerNftIds) return false;

    return playerNftIds.includes(nft.id);
  }

  function getCollectionMinter() {
    if (!nftList[0]) {
      return null;
    }

    // if there is a list and there is an owner for nft[0], then return it
    if (nftList[0].currentOwner) {
      return nftList[0].currentOwner;
    }

    // if there is a list of exactly 3 items and there is no owner for nft[0], then return null
    if (nftListState.length === 3 && nftListState[0].id === "NEW") {
      return null;
    }

    return currentPlayerAddress;
  }

  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        {getCollectionMinter() ? (
          isPromoCollection ? (
            <Subtitle>{"Promotional Collection"}</Subtitle>
          ) : (
            <Subtitle>{`Collection Minter: ${getCollectionMinter()}`}</Subtitle>
          )
        ) : (
          <Subtitle>{`This is your new collection`}</Subtitle>
        )}
      </TitleContainer>
      <GridKozika>
        {nftListState.map((nft, index) => (
          <GridItem key={"g" + nft?.id + index || "empty" + index + nftListState[0]?.id}>
            <Card
              key={"c" + index + nft?.id + title || "empty" + index + nftListState[0]?.id + title}
              nft={nft || null}
              squadType={6}
              squadSynergies={[]}
              scale={0.6}
              reasonToSelect="none"
              extraOutline={shouldHighlight(nft) || nft?.id === "NEW"}
              extraOutlineColor={nft?.id === "NEW" ? "yellow" : "white"}
            />
          </GridItem>
        ))}
      </GridKozika>
    </Container>
  );
};

export default CardView;
