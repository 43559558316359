import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { Tooltip } from "react-tooltip";
import {
  Container,
  FarmAndSynergyContainer,
  SquadNumberWrapper,
  GradientContainer,
  CollectionAndTraitContainer,
  GradientContainerSmall,
  TraitImage,
  TraitGlow,
} from "./styles";

import { format } from "../../../utils/math";
import { numberToName } from "../../../config/resources/01-traits";
import { getSvgTraitById } from "../../../config/resources/07-traits-to-svg";
import { isMaxedOut } from "../../../config/resources/08-squad-cards";

import { isPromoCollection } from "../../card/specialCollections";
import PromoCollection from "../../../assets/images/squads-card/collection-promo-symbol.png";

const SquadInfo = ({ squad }) => {
  const [specials, setSpecials] = useState({
    beastsOnFire: 0,
    dragons: 0,
  });

  const realFarmPerBlock = new BigNumber(squad.farmPerBlock).div(1e9).toFixed(0);
  const formatted = format(realFarmPerBlock, 3);

  const calculateSpecials = () => {
    // for each beast here, let's check if it's maxed out
    // if it is, then we add 1 to the count
    let beastsOnFire = 0;
    let dragons = 0;
    squad.nfts.forEach((beast) => {
      if (beast.type_ === 6) {
        dragons++;
      }
      if (isMaxedOut(beast)) {
        beastsOnFire++;
      }
    });

    setSpecials({
      beastsOnFire,
      dragons,
    });
  };

  useEffect(() => {
    calculateSpecials();
  }, [squad]);

  const hasPromoCollection = () => {
    return squad.collections.some((collection) => isPromoCollection(collection));
  };

  return (
    <Container>
      <FarmAndSynergyContainer>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp1"
            data-tooltip-content={`This squad farms ${realFarmPerBlock} Gwei of BCT/eBCT every 10 seconds`}
            data-tooltip-place="top"
          >
            <GradientContainer isFarmPerBlock={true}>
              <h4>{formatted}</h4>
            </GradientContainer>
          </a>
          <Tooltip id="tp1" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp2"
            data-tooltip-content="Synergy Bonus: unlocks quests and affects how much this squad farms"
            data-tooltip-place="top"
          >
            <GradientContainer isSynergy={true}>
              <h4>{squad.synergyBonus}%</h4>
            </GradientContainer>
          </a>
          <Tooltip id="tp2" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
      </FarmAndSynergyContainer>
      <CollectionAndTraitContainer>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp3"
            data-tooltip-content={
              squad.collections.length > 0
                ? hasPromoCollection()
                  ? `This squad has Promotional Collection NFTs`
                  : `This squad has Collection NFTs`
                : "Collection NFTs: none"
            }
            data-tooltip-place="top"
          >
            <GradientContainerSmall>
              {squad.collections.length > 0 ? (
                squad.collections.includes(304) ? (
                  <h4>
                    <img src={PromoCollection} style={{ width: "24px" }} />
                  </h4>
                ) : (
                  <h4>🎎</h4>
                )
              ) : (
                <h4>—</h4>
              )}
            </GradientContainerSmall>
          </a>
          <Tooltip id="tp3" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp4"
            data-tooltip-content={
              squad.squadTrait > 0
                ? `Squad Trait: ${numberToName(squad.squadTrait, true)}`
                : "Squad Trait: none"
            }
            data-tooltip-place="top"
          >
            <GradientContainerSmall>
              {squad.squadTrait > 0 ? (
                <TraitGlow isGlowing={squad.traits.includes(squad.squadTrait)} scale={1}>
                  <TraitImage src={getSvgTraitById(squad.squadTrait)} scale={1} />
                </TraitGlow>
              ) : (
                <h4>—</h4>
              )}
            </GradientContainerSmall>
          </a>
          <Tooltip id="tp4" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp5"
            data-tooltip-content={
              specials.beastsOnFire > 0
                ? `Beasts On Fire: ${specials.beastsOnFire}`
                : "Beasts On Fire: none"
            }
            data-tooltip-place="top"
          >
            <GradientContainerSmall>
              {specials.beastsOnFire > 0 ? <h4>{specials.beastsOnFire}</h4> : <h4>—</h4>}
            </GradientContainerSmall>
          </a>
          <Tooltip id="tp5" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp6"
            data-tooltip-content={
              specials.dragons > 0 ? `Dragons: ${specials.dragons}` : "Dragons: none"
            }
            data-tooltip-place="top"
          >
            <GradientContainerSmall>
              {specials.dragons > 0 ? <h4>{specials.dragons}</h4> : <h4>—</h4>}
            </GradientContainerSmall>
          </a>
          <Tooltip id="tp6" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
      </CollectionAndTraitContainer>
    </Container>
  );
};

export default SquadInfo;
