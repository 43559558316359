import React, { useState, useEffect } from "react";
import IntervalManager from "../../../utils/IntervalManager";

import {
  OuterContainer,
  InnerContainer,
  TopLineContainer,
  Wrapper,
} from "../../squad-header/styles";

import QuestAction from "./quest-action";
import QuestInfo from "./quest-info";
import QuestBonus from "./quest-bonus";
import QuestProgress from "./quest-progress";

import {
  idToQuest,
  calculateQuestRewardsV2,
  rewardsPerSecond,
} from "../../../config/resources/02-quests";
import { currentBlock } from "../../../utils/block";

import { usePlayerContext } from "../../../contexts/PlayerContext";
import { useWeb3Context } from "../../../contexts/Web3Context";

import BigNumber from "bignumber.js";

const DURATION = 1;

const Quest = ({ squad, questId, hideProgress, onForceOpenQuestSelection }) => {
  const { player } = usePlayerContext();
  const { blockchainState } = useWeb3Context();
  const [isLoading, setIsLoading] = useState(true);
  const [quest, setQuest] = useState(null);
  const [currentBlockNumber, setCurrentBlockNumber] = useState(0);
  const [startsAt, setStartsAt] = useState(0);
  const [endsAt, setEndsAt] = useState(0);

  const [rewards, setRewards] = useState({
    bonuses: {
      BCT: 0,
      eBCT: 0,
    },
    resources: [],
  });

  useEffect(() => {
    if (questId === 0 || !questId) return;
    const quest = idToQuest(questId);
    setQuest(quest);

    setIsLoading(false);
  }, [questId, squad]);

  useEffect(() => {
    if (!squad) return;

    if (squad.questStartedAt && squad.currentQuest > 0) {
      // This squad is ON A QUEST! We can fetch all values from the squad itself
      setStartsAt(squad.questStartedAt);
      setEndsAt(squad.questStartedAt + 8640);
    } else {
      // we're choosing a quest, let's hammer down the values for 1 day
      // and tell the quest it started 1 day ago:
      setStartsAt(Date.now() / 1000 - 86400);
      setEndsAt(Date.now() / 1000);
    }

    setIsLoading(false);
  }, [squad]);

  useEffect(() => {
    updateRewards();

    IntervalManager.start(`questCounter-${questId}-${squad?.id}`, updateRewards, 10000);

    return () => IntervalManager.stop(`questCounter-${questId}-${squad?.id}`);
  }, [quest, squad, currentBlockNumber]);

  function updateRewards() {
    if (!quest || !squad || squad.nfts.length < 1 || squad.multipliers.length < 3) return;

    setCurrentBlockNumber(
      currentBlock(player.connection.initialBlockNumber, player.connection.initialTimestamp)
    );

    const questRewards = calculateQuestRewardsV2(
      quest.id,
      squad,
      currentBlockNumber,
      blockchainState?.scales?.farmingScale
    );
    const castedResources = questRewards.resources.map((resource, index) => {
      return {
        [index]: resource.toString() === "NaN" ? 0 : resource,
      };
    });

    const sortedResources = castedResources.sort((a, b) => {
      const aKey = Object.keys(a)[0];
      const bKey = Object.keys(b)[0];
      return b[bKey] - a[aKey];
    });

    const bctReward = new BigNumber(questRewards.bct).div(1e18).toFixed(8);
    const ebctReward = new BigNumber(questRewards.eBct).div(1e18).toFixed(8);

    setRewards((prevRewards) => ({
      bonuses: {
        BCT: bctReward,
        eBCT: ebctReward,
      },
      resources: sortedResources,
    }));
  }

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    quest && squad && currentBlockNumber && rewards && startsAt && endsAt && (
      <Wrapper>
        <OuterContainer>
          <TopLineContainer>
            <QuestAction
              squad={squad}
              quest={quest}
              currentBlockNumber={currentBlockNumber}
              onForceOpenQuestSelection={onForceOpenQuestSelection}
            />
            <InnerContainer>
              <QuestInfo quest={quest}></QuestInfo>
              <QuestBonus quest={quest}></QuestBonus>
            </InnerContainer>
          </TopLineContainer>
        </OuterContainer>
        {(startsAt > 0 || hideProgress) && (
          <QuestProgress
            rewards={rewards}
            duration={DURATION}
            startsAt={startsAt}
            endsAt={endsAt}
            currentBlockNumber={currentBlockNumber}
            hideProgress={hideProgress}
          />
        )}
      </Wrapper>
    )
  );
};

export default Quest;
