import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 24px;
  font-family: "Metropolis", sans-serif;
`;

export const Subtitle = styled.div`
  font-size: 12px;
  font-family: "Metropolis", sans-serif;
  margin-top: 10px;
  color: #aaa;

  @media (max-width: 450px) {
    font-size: 9px;
  }
`;

export const GridItem = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  margin-top: 15.5px;
`;

export const GridKozika = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 4fr));
  width: 100%;
  justify-items: center;

  @media (max-width: 685px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 3fr));
    width: 450px;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fill, minmax(90px, 2fr));
    width: 310px;
  }
`;
