export function getPerksArray({ prices, update, buyFunc }) {
  return [
    {
      id: 0,
      title: "BCT",
      description: "Increase the BCT farming rate of all your squads.",
      maxLevel: 99,
      isReady: true,
      currentLevelReference: "parseInt(player?.masteries[0])",
      previewLevelReference: "bctMasteryPreviewLevel",
      getPriceInResourcesFunction: prices.bct,
      updateFunction: update.bct,
      buyFunction: buyFunc.resources,
      buyWithKoziFunction: buyFunc.kozi,
    },
    {
      id: 1,
      title: "eBCT",
      description: "Increase the eBCT farming rate of all your squads.",
      maxLevel: 99,
      isReady: true,
      currentLevelReference: "parseInt(player?.masteries[1])",
      previewLevelReference: "eBctMasteryPreviewLevel",
      getPriceInResourcesFunction: prices.eBct,
      updateFunction: update.eBct,
      buyFunction: buyFunc.resources,
      buyWithKoziFunction: buyFunc.kozi,
    },
    {
      id: 2,
      title: "Basic Loot",
      description: "Increase the Basic Loot farming rate of all your squads.",
      maxLevel: 99,
      isReady: true,
      currentLevelReference: "parseInt(player?.masteries[2])",
      previewLevelReference: "blMasteryPreviewLevel",
      getPriceInResourcesFunction: prices.bl,
      updateFunction: update.bl,
      buyFunction: buyFunc.resources,
      buyWithKoziFunction: buyFunc.kozi,
    },
    {
      id: 3,
      title: "Advanced Loot",
      description: "Increase the Advanced Loot farming rate of all your squads.",
      maxLevel: 99,
      isReady: true,
      currentLevelReference: "parseInt(player?.masteries[3])",
      previewLevelReference: "alMasteryPreviewLevel",
      getPriceInResourcesFunction: prices.al,
      updateFunction: update.al,
      buyFunction: buyFunc.resources,
      buyWithKoziFunction: buyFunc.kozi,
    },
    {
      id: 4,
      title: "Golden Nuts",
      description: "Increase the Golden Nuts farming rate of all your squads.",
      maxLevel: 99,
      isReady: true,
      currentLevelReference: "parseInt(player?.masteries[4])",
      previewLevelReference: "nutsMasteryPreviewLevel",
      getPriceInResourcesFunction: prices.nuts,
      updateFunction: update.nuts,
      buyFunction: buyFunc.resources,
      buyWithKoziFunction: buyFunc.kozi,
    },
    {
      id: 5,
      title: "Evolution Items",
      description: "Increase the Evolution Items farming rate of all your squads.",
      maxLevel: 99,
      isReady: true,
      currentLevelReference: "parseInt(player?.masteries[5])",
      previewLevelReference: "evosMasteryPreviewLevel",
      getPriceInResourcesFunction: prices.evos,
      updateFunction: update.evos,
      buyFunction: buyFunc.resources,
      buyWithKoziFunction: buyFunc.kozi,
    },
  ];
}
