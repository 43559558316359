import styled from "styled-components";

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 690px;

  @media (max-width: 1365px) {
    width: 90%;
    flex-direction: column;
  }
`;

export const MobileTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  font-size: 20px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  text-align: center;
  background: #0f2034;
  min-height: 40px;
  padding: 5px;
  height: auto;

  margin-bottom: 5px;
  margin-top: 10px;

  @media (max-width: 685px) {
    width: 440px;
  }

  @media (max-width: 450px) {
    font-size: 16px;
    max-width: 350px;
  }
`;

export const MobileButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  @media (max-width: 685px) {
    flex-direction: column;
  }
`;

export const TitleStyle = styled.h2`
  font-size: 5.5em;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;

  @media (max-width: 450px) {
    margin-top: -40px;
    font-size: 3.5em;
  }
`;

export const SubtitleStyle = styled.h2`
  font-size: 4.8em;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: 0;

  color: #14141f;
  background: linear-gradient(-45deg, #e250e5, #4b50e6, #e250e5, #4b50e6);
  background-size: 100% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  -webkit-text-fill-color: #14141f;

  @media (max-width: 450px) {
    font-size: 2.8em;
  }
`;

export const ThirdTitleStyle = styled.h2`
  font-size: 4.8em;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;

  @media (max-width: 450px) {
    font-size: 3.2em;
  }
`;

export const SubHeadingStyle = styled.p`
  font-size: 2em;
  line-height: 30px;
  color: #ebebeb;
  text-transform: capitalize;
  margin-top: 29px;
  margin-bottom: 44px;

  @media (max-width: 450px) {
    margin-top: 9px;
    font-size: 1.4em;
  }
`;
