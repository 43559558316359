import React, { useEffect, useState } from "react";
import {
  ProgressBarContainer,
  QuestDetailWrapper,
  QuestBonusWrapper,
  RewardsContainer,
  RewardsOuterContainer,
} from "./styles";
import ResourceContainerInProgress from "./resource-container-in-progress";
import BctContainerInProgress from "./bct-container-in-progress";
import QuestProgressBar from "../quest-progress/quest-progress-bar";
import { bonusToBackground, indexToBackground } from "../../../../utils/constants";
import { Tooltip } from "react-tooltip";
import { indexToTooltip } from "../../../../utils/constants";

const QuestProgress = ({ rewards, endsAt, startsAt, currentBlockNumber, hideProgress }) => {
  const [animatedBonuses, setAnimatedBonuses] = useState({});
  const [resources, setResources] = useState([[]]);

  useEffect(() => {
    if (!rewards) return;

    const updatedBonuses = Object.keys(rewards.bonuses).reduce((acc, key) => {
      const value = rewards.bonuses[key];
      if (value > 0) {
        acc[key] = {
          start: animatedBonuses[key]?.end || 0,
          end: value,
          duration: 10,
        };
      }
      return acc;
    }, {});

    setAnimatedBonuses((prevAnimatedBonuses) => ({
      ...prevAnimatedBonuses,
      ...updatedBonuses,
    }));

    const interval = setInterval(() => {
      setAnimatedBonuses((prevAnimatedBonuses) => {
        const updatedAnimatedBonuses = { ...prevAnimatedBonuses };

        Object.keys(updatedBonuses).forEach((key) => {
          const animatedBonus = updatedAnimatedBonuses[key];
          if (animatedBonus) {
            animatedBonus.start = animatedBonus.end;
            animatedBonus.end = rewards.bonuses[key];
          }
        });

        return updatedAnimatedBonuses;
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [rewards]);

  useEffect(() => {
    if (!rewards) return null;
    if (!rewards.resources) return null;
    if (!rewards.resources.length) return null;
    if (!resources.length) return null;

    let resourceComponents = rewards.resources
      .map((resource, index) => {
        const quantity = resource[Object.keys(resource)[0]];
        if (quantity === 0) return null;
        return (
          <QuestBonusWrapper key={index}>
            <ResourceContainerInProgress
              resource={Object.keys(resource)[0]}
              quantity={quantity}
              background={indexToBackground[Object.keys(resource)[0]]}
            />
          </QuestBonusWrapper>
        );
      })
      .filter(Boolean);

    setResources(resourceComponents);
  }, [rewards]);

  return (
    rewards && (
      <>
        <ProgressBarContainer>
          <QuestDetailWrapper>
            {!hideProgress && (
              <QuestProgressBar
                duration={1}
                startsAt={startsAt}
                endsAt={endsAt}
                currentBlockNumber={currentBlockNumber}
              />
            )}
          </QuestDetailWrapper>
        </ProgressBarContainer>
        <RewardsOuterContainer>
          <RewardsContainer>
            {Object.keys(rewards.bonuses).map((key, index) => {
              if (rewards.bonuses[key] > 0) {
                return (
                  <QuestBonusWrapper key={key}>
                    <a
                      data-tooltip-id={`progress-${key}`}
                      data-tooltip-content={indexToTooltip[key]}
                      data-tooltip-place="top"
                    >
                      <BctContainerInProgress
                        resource={key}
                        quantity={rewards.bonuses[key]}
                        background={bonusToBackground[key]}
                      />
                    </a>
                    <Tooltip id={`progress-${key}`} style={{ fontSize: "12px", fontWeight: 600 }} />
                  </QuestBonusWrapper>
                );
              } else {
                return null;
              }
            })}
            {resources.length > 0 ? resources : null}
          </RewardsContainer>
        </RewardsOuterContainer>
      </>
    )
  );
};

export default QuestProgress;
