import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /*font-family: "Metropolis", sans-serif;*/
  /*letter-spacing: -0.06em;*/
  margin-bottom: 6px !important;
  padding: 0px 40px 0px 0px;

  ul {
    height: ${(props) =>
      props.itemCount ? (props.itemCount < 10 ? `${26.5 * props.itemCount}px` : "265px") : "53px"};
    background-color: #06063b;
  }

  .dropdown li {
    padding: 1px 8px;
    /*font-family: "Metropolis", sans-serif;*/
  }

  .dropdown li:hover {
    background-color: #06065b;
  }

  a {
    padding: 5px !important;
    text-align: center;
  }

  @media (max-width: 585px) {
    padding: 0;
  }
`;

export const DropdownItem = styled.div`
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  height: 24px !important;
  padding: 0px !important;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
