import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  margin-bottom: 100px;
`;

export const AccountDetails = styled.div`
  width: 100%;
  min-width: 390px;
  max-width: 800px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 28px;
`;

export const TopRightButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  position: relative;
  top: 2px;
`;

export const AdvancedButton = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isAdvanced ? "#ff0" : "#fff")};
  cursor: pointer;
  background-color: #000;
  outline: 1px solid white;
  border-radius: 4px;

  padding: 4px 8px;
`;

export const RobotButton = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isContractView ? "#ff0" : "#fff")};
  cursor: pointer;
  background-color: #000;
  outline: ${(props) => (props.isContractView ? "1px solid yellow" : "1px solid white")};
  border-radius: 4px;
  position: relative;
  right: 6px;

  padding: 4px 8px;
`;

export const DetailItem = styled.div`
  margin: 10px 0;
  padding: 4px 4px 4px 0;
  display: flex;
  border-bottom: 1px solid #e1e1e1;

  // if this is the last item, remove the border
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 5px;
  }

  &:first-child {
    padding-top: 0;
    margin-top: 8px;
  }

  font-size: 12px;
`;

export const NotFound = styled.div`
  width: 100%;
  max-width: 600px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 14px;
  padding: 20px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 1.4;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -20px;
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: ${(props) => (props.disabled ? "#2f4054" : "#0f2034")};
  margin: 20px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-size: 16px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  ${(props) =>
    props.isSelected &&
    `
    background: #0f2034;
    opacity: 0.8;
    outline: 1px solid white;
  `}

  ${(props) =>
    !props.disabled &&
    `
    &:hover {
      background: #0f2034;
      opacity: 0.8;
      outline: 1px solid white;
    }
  `}
`;

export const PaginationText = styled.div`
  font-size: 22px;
`;

export const QueryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 46px;
  height: 20px;
  background: rgba(0, 30, 40);
  cursor: pointer;
  font-size: 12px;
  color: #fff;

  position: relative;
  bottom: 3px;
  left: 2px;
  margin-left: auto;

  &:hover {
    background: rgba(0, 50, 60);
  }

  &:active {
    background: rgba(0, 70, 80);
  }
`;

export const ExecuteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 56px;
  height: 20px;
  background: rgba(0, 30, 40);
  cursor: pointer;
  font-size: 12px;
  color: #fff;

  position: relative;
  bottom: 3px;
  left: 2px;
  margin-left: auto;

  &:hover {
    background: rgba(0, 50, 60);
  }

  &:active {
    background: rgba(0, 70, 80);
  }
`;
