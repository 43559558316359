const hom = {
  name: "KINGDOM CHAIN TESTNET",
  chainId: 39916800,
  validators: [""],
  operator: "",
  treasury: "",
  Cosmetics: {
    address: "",
  },
  Forge: {
    address: "",
  },
  HighUpgrades: {
    address: "",
  },
  Vault: {
    address: "",
  },
  BoosterCracker: {
    address: "",
  },
  TraitReroller: {
    address: "",
  },
  QuestManager: {
    address: "",
  },
  Fetch: {
    address: "",
  },
  DnaManager: {
    address: "",
  },
  Marketplace: {
    address: "",
  },
  Farm: {
    address: "",
  },
  QuestRewardsCalculator: {
    address: "",
  },
  Upgrades: {
    address: "",
  },
  PlayerBonus: {
    address: "",
  },
  CommunityPool: {
    address: "",
  },
  Stasher: {
    address: "",
  },
  BkNft: {
    address: "",
  },
  Stabletoken: {
    name: "Kingdom-Peg USD Coin",
    symbol: "USDC",
    decimals: 18,
    address: "",
  },
  BCT: {
    name: "Beast Community Token",
    symbol: "BCT",
    decimals: 18,
    address: "",
  },
  EasyBkCharLib: {
    address: "",
  },
  RResult: {
    address: "",
    core: "",
  },
  Blocklist: {
    address: "",
  },
};

export default hom;
