import React, { useEffect, useState } from "react";
import Messenger from "../../utils/Messenger";
import BigNumber from "bignumber.js";

import { usePlayerContext } from "../../contexts/PlayerContext";
import { useUpgradesContext } from "../../contexts/UpgradesContext";
import { useWeb3Context } from "../../contexts/Web3Context";

import { stasherLevelByBalance } from "../../utils/constants";

import {
  GlobalContainer,
  InternalContainer,
  LeftContainer,
  RightContainer,
  CollectionListContainer,
  ActionBoxContainer,
  CardViewContainer,
} from "./styles";

import ActionBox from "./action-box/ActionBox";
import CardView from "./card-view/CardView";
import CollectionList from "./collection-list/CollectionList";
import CustomizationModal from "./customization-modal/CustomizationModal";

import { getCollectionPrices } from "../../config/resources/12-upgrades";
import { isComplete } from "../../utils/collections";
import { isPromoCollection } from "../card/specialCollections";

const DEFAULT_PRICE = {
  bctPrice: BigNumber(0),
  resourceCents: [],
  resourceIndexes: [],
};

const CollectionsScreen = () => {
  const { player, loading, isRegistered, fetchCollections } = usePlayerContext();
  const { createCollection, expandCollection } = useUpgradesContext();
  const { blockchainState, bctToBusd, stableToTargetKozi } = useWeb3Context();

  const [collectionList, setCollectionList] = useState([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState();
  const [selectedTrait, setSelectedTrait] = useState();
  const [selectedSpecies, setSelectedSpecies] = useState();
  const [selectedRarity, setSelectedRarity] = useState();
  const [selectedName, setSelectedName] = useState();
  const [beastNames, setBeastNames] = useState([]);
  const [beastImages, setBeastImages] = useState([]);
  const [customizationNonce, setCustomizationNonce] = useState(0);

  const [costInResources, setCostInResources] = useState(DEFAULT_PRICE);
  const [costInBusd, setCostInBusd] = useState(BigNumber(0));

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }

    if (!player.ingameBalances || !player.ingameBalances[2] || !isRegistered) return null;

    if (!loading) {
      // fetch the collections and save them in the context
      const fetch = async () => {
        const collectionsResult = await fetchCollections();
        setCollectionList(collectionsResult);
      };

      fetch();

      return;
    }
  }, [
    player,
    loading,
    isRegistered,
    selectedSpecies,
    selectedRarity,
    selectedTrait,
    customizationNonce,
  ]);

  useEffect(() => {
    // increment the nonce to force a re-render of the CardView
    setCustomizationNonce((prevNonce) => prevNonce + 1);
  }, [beastNames, beastImages]);

  const buyFunction = () => {
    if (!isStasher(selectedSpecies)) return;

    if (selectedCollectionId) {
      handleExpandCollection(false);
    } else {
      handleCreateCollection(false);
    }
  };

  const buyWithBusdFunction = () => {
    if (!isStasher(selectedSpecies)) return;

    if (selectedCollectionId) {
      handleExpandCollection(true, stableToTargetKozi(BigNumber(costInBusd).times(1e18)));
    } else {
      handleCreateCollection(true, stableToTargetKozi(BigNumber(costInBusd).times(1e18)));
    }
  };

  function isStasher(species) {
    // Only Stshers Lvl 9+ can create or expand mouse (species 1) collections
    const playerStasherLevel = stasherLevelByBalance(
      parseFloat(BigNumber(player.stashed).div(1e18).toString())
    );
    console.log(`isStasher(${species}): playerStasherLevel = ${playerStasherLevel}`);
    if (species === 1) {
      if (playerStasherLevel < 9) {
        Messenger.error(
          "You need to be a Stasher Level 9 or above to create or expand Mouse Collections."
        );
        return;
      }
    } else {
      // Only Stashers Lvl 10 can do it
      if (playerStasherLevel < 10) {
        Messenger.error(
          "You need to be a Stasher Level 10 to create or expand Non-Mouse Collections."
        );
        return;
      }
    }

    return true;
  }

  const handleCreateCollection = async (withBusd, sendKozi) => {
    // if beastNames has less than 3 items, complete it with empty strings:
    const _beastNames = cleanup(beastNames, 3);

    // do the same with beastImages:
    const _beastImages = cleanup(beastImages, 3);

    await createCollection(
      selectedName,
      _beastNames,
      selectedSpecies,
      selectedRarity,
      selectedTrait,
      _beastImages,
      withBusd,
      sendKozi
    );

    // desselect the current collection and select none:
    setSelectedCollectionId(null);
  };

  const handleExpandCollection = async (withBusd, sendKozi) => {
    // if beastNames has less than 5 items, complete it with empty strings:
    const _beastNames = cleanup(beastNames, 5);

    // do the same with beastImages:
    const _beastImages = cleanup(beastImages, 5);

    await expandCollection(
      selectedCollectionId,
      _beastNames,
      selectedSpecies,
      selectedRarity,
      selectedTrait,
      _beastImages,
      withBusd,
      sendKozi
    );

    // desselect the current collection and select none:
    setSelectedCollectionId(null);
  };

  const cleanup = (list, fill = 3) => {
    // if list doesn't exist, return an array of empty strings will `fill` length
    if (!list) {
      return Array(fill).fill("");
    }

    // replace any null values with empty strings:
    const _list = list.map((item) => (item === null || !item ? "" : item));

    while (_list.length < fill) {
      _list.push("");
    }

    return _list;
  };

  const handleSelectCollection = (collectionId) => {
    resetAllSelections();

    setSelectedCollectionId(collectionId);

    setCostInResources(DEFAULT_PRICE);
    setCostInBusd(BigNumber(0));
  };

  const handleTraitSelected = (trait) => {
    setSelectedTrait(trait);
  };

  const handleSpeciesSelected = (species) => {
    setSelectedSpecies(species);

    if (selectedRarity) {
      _setCostInResources(species, selectedRarity);
    }
  };

  const handleRaritySelected = (rarity) => {
    setSelectedRarity(rarity);

    if (selectedSpecies) {
      _setCostInResources(selectedSpecies, rarity);
    }
  };

  const handleCustomizationDone = (_beastNames, _beastImages) => {
    setBeastNames(_beastNames);
    setBeastImages(_beastImages);
  };

  const handleNameSelected = (name) => {
    setSelectedName(name);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const resetAllSelections = () => {
    setSelectedTrait();
    setSelectedSpecies();
    setSelectedRarity();
  };

  function getCollectionName() {
    const name = collectionList.find((col) => col.id === selectedCollectionId)?.name;
    if (name) return name;

    return selectedName || "New Collection";
  }

  function isExternal() {
    // it will be an External if the collection is external
    const col = collectionList.find((col) => col.id === selectedCollectionId);
    return col?.isExternal;
  }

  function isExpansion() {
    // it will be an Expansion if the collection is not external and has more than 0 nfts
    const col = collectionList.find((col) => col.id === selectedCollectionId);
    return col?.nftIds.length > 0 && !col?.isExternal;
  }

  function _setCostInResources(species, rarity) {
    const cost = getCollectionPrices(species, rarity, blockchainState?.scales?.upgradesScale);

    if (cost) {
      setCostInResources(cost);
      _setCostInBusd(cost, species, rarity);
    } else {
      setCostInResources(DEFAULT_PRICE);
      setCostInBusd(BigNumber(0));
    }
  }

  async function _setCostInBusd(costInResources, species, rarity) {
    const costInBct = costInResources.bctPrice.multipliedBy(2);

    let costInBusd = await bctToBusd(costInBct);

    const tempRarity = rarity || selectedRarity;
    const tempSpecies = species || selectedSpecies;

    // if it's mythic rarity, add 40 busd
    if (tempRarity === 5) {
      costInBusd = costInBusd.plus(BigNumber(40).times(1e18));
    } else if (tempSpecies > 2) {
      costInBusd = costInBusd.plus(BigNumber(20).times(1e18));
    }

    setCostInBusd(costInBusd);
  }

  return (
    <GlobalContainer>
      <InternalContainer>
        <LeftContainer>
          <CollectionListContainer>
            <CollectionList
              collectionList={collectionList}
              onSelect={handleSelectCollection}
              playerNftIds={player?.nftIds || []}
            />
          </CollectionListContainer>
          <ActionBoxContainer>
            {player && player.ingameBalances && player.ingameBalances[2] && isRegistered && (
              <ActionBox
                title={`Create Collection`}
                description={`Choose the name of your new collection. Then, choose the species, the rarity, and the shared trait of this group of beasts. Finally, upload the images of your beasts and set their names. Customizing names and images is optional.`}
                eBctPrice={costInResources.bctPrice}
                resourcePrices={costInResources.resourceCents}
                resourceIndexes={costInResources.resourceIndexes}
                busdPrice={costInBusd || 0}
                player={player}
                buyFunction={buyFunction}
                buyWithBusdFunction={buyWithBusdFunction}
                traitSelectCallback={handleTraitSelected}
                speciesSelectCallback={handleSpeciesSelected}
                raritySelectCallback={handleRaritySelected}
                customizationModalOpenCallback={handleShowModal}
                customizationDoneCallback={handleCustomizationDone}
                inputNameDefaultValue={selectedName}
                inputNameCallback={handleNameSelected}
                isForbidden={false}
                isBlocked={
                  (!selectedName && !isExpansion()) ||
                  !selectedSpecies ||
                  !selectedRarity ||
                  !selectedTrait
                }
                isLoading={false}
                onExtraHelpButtonClick={null}
                isComplete={isComplete(
                  collectionList.find((col) => col.id === selectedCollectionId),
                  player?.nftIds || []
                )}
                isExternal={isExternal()}
                isExpansion={isExpansion()}
                isFull={
                  collectionList.find((col) => col.id === selectedCollectionId)?.nfts?.length >= 18
                }
                forceName={isExpansion() ? getCollectionName() : null}
              />
            )}
          </ActionBoxContainer>
        </LeftContainer>
        <RightContainer>
          <CardViewContainer>
            <CardView
              key={selectedCollectionId}
              title={getCollectionName()}
              nftList={collectionList.find((col) => col.id === selectedCollectionId)?.nfts}
              reasonToSelect="none"
              playerNftIds={player?.nftIds || []}
              species={selectedSpecies}
              rarity={selectedRarity}
              trait={selectedTrait}
              currentPlayerAddress={player?.address}
              isComplete={isComplete(
                collectionList.find((col) => col.id === selectedCollectionId),
                player?.nftIds || []
              )}
              customizedBeastNames={beastNames}
              customizedBeastImages={beastImages}
              injectPreview={selectedSpecies}
              isPromoCollection={isPromoCollection(selectedCollectionId)}
            />
          </CardViewContainer>
        </RightContainer>
      </InternalContainer>
      {showModal && (
        <CustomizationModal
          onClose={() => setShowModal(false)}
          beastCount={isExpansion() ? 5 : 3}
          onSave={handleCustomizationDone}
          beastNames={beastNames}
          beastImages={beastImages}
        />
      )}
    </GlobalContainer>
  );
};

export default CollectionsScreen;
