import React from "react";
import BigNumber from "bignumber.js";

import { Container, TextContainer, Text, Image, ButtonContainer, RightContainer } from "./styles";

import { BuyButton, PurchaseCostContainer } from "../../upgrades/buttons/PurchaseButtons";
import ResourceContainerUpgrades from "../../upgrades/resource-container";

import { getDefaultImage } from "../../card/CardImage.jsx";
import { nameToNumber } from "../../../config/resources/06-type_.js";
import { bonusToBackground } from "../../../utils/constants";
import { indexToBackground } from "../../../utils/constants";

import { hasEnoughBct, hasEnoughResources } from "../../../utils/purchase";

import { numberToName } from "../../../config/resources/05-rarity.js";

const MintButton = ({
  type,
  rarity,
  player,
  eBctPrice,
  resourcePrices,
  resourceIndexes,
  disabled = true,
  onClick = null,
}) => {
  return (
    <Container>
      <Image src={getDefaultImage(`${nameToNumber(type)}-${rarity}`)} />
      <RightContainer>
        <TextContainer>
          <Text>{numberToName(rarity)}</Text>
        </TextContainer>
        <ButtonContainer>
          <BuyButton disabled={disabled} onClick={onClick}>
            <p>Pay with resources</p>
            <PurchaseCostContainer>
              <ResourceContainerUpgrades
                resource={98}
                quantity={eBctPrice.div(1e18).toNumber()}
                background={bonusToBackground["eBCT"]}
                hasEnough={hasEnoughBct(player, eBctPrice)}
              />
              {resourceIndexes.map((resourceIndex, mapIndex) => (
                <ResourceContainerUpgrades
                  key={`resource-${resourceIndex}`}
                  resource={resourceIndex}
                  quantity={resourcePrices[mapIndex] / 100}
                  background={indexToBackground[resourceIndex]}
                  hasEnough={hasEnoughResources(
                    player,
                    [resourcePrices[mapIndex]],
                    [resourceIndexes[mapIndex]]
                  )}
                />
              ))}
            </PurchaseCostContainer>
          </BuyButton>
        </ButtonContainer>
      </RightContainer>
    </Container>
  );
};

export default MintButton;
