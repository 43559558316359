import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Container, DropdownItem } from "./styles";

const SquadSelectDropdown = ({ squadSelectCallback = null, squadList = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSquad, setSelectedSquad] = useState(null);
  const [selectedSquadName, setSelectedSquadName] = useState("Pick a Squad");

  const onSquadSelect = (squad) => {
    setSelectedSquad(squad);
    setSelectedSquadName(`Squad ${squad.squadNumber}`);
    setIsOpen(false);

    if (squadSelectCallback) {
      squadSelectCallback(squad.id);
    }
  };

  const dropDownForceStyle = {
    minWidth: "280px",
  };

  return (
    <Container itemCount={squadList.length}>
      <div className="dropdown" style={dropDownForceStyle}>
        <Link
          to="#"
          className="btn-selector nolink"
          onClick={() => setIsOpen(!isOpen)}
          style={{ padding: "5px" }}
        >
          {selectedSquadName} {selectedSquad ? `(#${selectedSquad.id})` : null}
        </Link>
        {isOpen && (
          <ul style={{ overflowY: "auto" }}>
            {squadList.map((squad, index) => (
              <li key={index}>
                <DropdownItem onClick={() => onSquadSelect(squad)}>
                  Squad {squad.squadNumber} (#{squad.id})
                </DropdownItem>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Container>
  );
};

export default SquadSelectDropdown;
