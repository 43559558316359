export function parseAge(blockNumber, currentBlockHeight) {
  if (!blockNumber || !currentBlockHeight) return "Unknown";

  const ageInBlocks = currentBlockHeight - blockNumber;
  const secondsSince = ageInBlocks * 10;
  const formattedAge = formatAge(secondsSince);

  return formattedAge;
}

export function formatAge(seconds) {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor(((seconds % 86400) % 3600) / 60);

  let age = "";
  if (days > 0) {
    age += `${days} day${days > 1 ? "s" : ""}, `;
  }
  if (hours > 0 && days <= 10) {
    age += `${hours} hour${hours > 1 ? "s" : ""}, `;
  }
  if (minutes > 0 && days <= 10) {
    age += `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  if (age === "") {
    return "Just now";
  } else {
    return age + " ago";
  }
}
