import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GlobalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(16, 49, 73, 1), rgba(16, 59, 83, 1));
  width: 750px;
  max-width: 96%;
  outline: 3px solid white;

  max-height: calc(70vh);
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin: 15px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.2;
  width: 80%;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  font-size: 20px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  text-align: center;
  background: #0f2034;
  min-height: 40px;
  padding: 5px;
  height: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
`;

export const Separator = styled.img`
  width: 200px;
  height: 10px;
  margin-top: 10px;
  margin-bottom: -10px;
  filter: invert(7%) sepia(6%) saturate(7406%) hue-rotate(202deg) brightness(90%) contrast(99%);
`;

export const Envelope = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: #0f2034;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    `
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  `}

  &:hover {
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  }
`;
