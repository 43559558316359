import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width 186px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 345px;
  height: 160px;
  margin-top: 20px;
  border-radius: 6px;
  background: ${(props) =>
    props.disabled
      ? "linear-gradient(0deg, rgba(65, 65, 65, 1), rgba(95, 95, 95, 1));"
      : "linear-gradient(0deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1));"};
  color: ${(props) => (props.disabled ? "#777" : "#fff")};

  // only hover if not disabled:
  ${(props) =>
    !props.disabled &&
    `
      &:hover {
        background: linear-gradient(0deg, rgba(15, 12, 42, 1), rgba(15, 32, 52, 1));
        outline: 2px solid #fff;
      }`}

  &:active {
    background: linear-gradient(0deg, rgba(5, 5, 10, 1), rgba(15, 32, 52, 1));
    outline: 2px solid #fff;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width 140px;
  font-size: 20px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8px;  
`;

export const Image = styled.img`
  height: 100%;
  border-radius: 6px 0px 0px 6px;
`;
