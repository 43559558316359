import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 300px;
  padding: 10px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-top: 0;
  font-family: "Metropolis", sans-serif;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 13px;
  line-height: 1.2;
  margin-top: 6px;
  margin-bottom: 16px;
`;

export const Explanation = styled.div`
  color: #fff;
  font-size: 13px;
  margin-bottom: 6px;
  text-align: center;
  line-height: 1.2;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 278px;
  border-radius: 4px;
  outline: 2px solid white;
  outline-offset: -1px;
  margin-top: 10px;
`;

export const NumberInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 24px;
  background-color: #fff;
  color: #000;
`;

export const NumberButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 22px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  user-select: none;
`;

export const PlusPlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 22px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  border-radius: 0px 4px 4px 0;
  user-select: none;
`;

export const MinusMinusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  outline: 2px solid white;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 25px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
  border-radius: 4px 0 0 4px;
  user-select: none;
`;

export const Number = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

export const ForbiddenRarity = styled.h3`
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  font-family: "Metropolis", sans-serif;
  color: #ff0;
`;
