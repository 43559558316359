import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Header from "../components/header/Header.jsx";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

import SeparatorImg from "../assets/images/icon/separator.png";
import { getAllUniqueQuests } from "../config/resources/02-quests.js";
import Quest from "../components/all-quests/quest/index.jsx";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
`;

const Envelope = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: #0f2034;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    `
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  `}

  &:hover {
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  }
`;

const AllKingdomQuests = () => {
  const allQuests = getAllUniqueQuests();

  allQuests[4].traits = [998, 998];
  allQuests[5].traits = [998, 998, 998];
  allQuests[6].traits = [998, 998];
  allQuests[7].traits = [998, 998, 998];
  allQuests[8].traits = [998, 998, 998];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [totalPages, setTotalPages] = useState(Math.ceil(allQuests.length / itemsPerPage));

  const questsToShow = allQuests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
    let buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <PaginationButton
          key={i}
          onClick={() => setCurrentPage(i)}
          disabled={currentPage === i}
          isSelected={currentPage === i}
          style={i === 1 ? { marginLeft: "0px" } : null}
        >
          {i}
        </PaginationButton>
      );
    }

    return totalPages > 1 && <PaginationContainer>{buttons.slice(0, 10)}</PaginationContainer>;
  };

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🐾</h1>
                    <h1 className="heading text-center">All Kingdom Quests</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32">
                      There are 168 unique quests to choose from. Your squads farm a defined amount
                      of each resource based on their beasts. On top of that, each quest has a
                      modifier to the natural farming power of your squad. The best quests, the ones
                      that multiply your farming power the most, require high squad synergy and
                      special beasts in your squads.
                    </h5>
                    <h5 className="sub-title help-center">
                      As an example, if your squad farms 1 BCT a day, and you choose a quest with a
                      50% modifier in BCT, you will farm 0.5 BCT a day instead. The same applies to
                      all other resources.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <Container>
                    {questsToShow.map((quest, index) => (
                      <Envelope key={quest.id}>
                        <Wrapper>
                          <Quest questId={quest.id} />
                        </Wrapper>
                      </Envelope>
                    ))}
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      setCurrentPage={setCurrentPage}
                    />
                  </Container>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default AllKingdomQuests;
