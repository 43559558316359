import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header.jsx";
import { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } from "../utils/environment.js";

const TrophiesAndTickets = () => {
  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🏆</h1>
                    <h1 className="heading text-center">Trophies & Tickets</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      On top of the natural USDC distribution, Kozi holders gain extra rewards from
                      the Kingdom Nobles game.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      The closer you are to the King, the more Lucky Tickets (🎫) you earn.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Whenever the Kingdom adjusts Kozi's price, each Noble earns (26 - X) / 2000
                      Lucky Tickets, where X is their position in the Kingdom at that time.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      Spend Kozi to earn Prestige Trophies (🏆).
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Whenever a player spends 0.1 or more Kozi on our website, they are awarded 10%
                      of that amount in Prestige Trophies. For example, spending 1 Kozi earns 0.1
                      Prestige Trophies.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      These Royal Charms are forever yours to wield.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Lucky Tickets and Prestige Trophies are bound to you. They cannot be
                      transferred or sold, and do not expire. Unleashing their potential can unlock
                      unparalleled riches and rewards throughout the Kingdom.
                    </h5>

                    <h5 className="sub-title help-center" style={{ color: "yellow" }}>
                      Use Tickets and Trophies in the{" "}
                      <Link to="/fur-and-fortune" style={{ textDecorationLine: "underline" }}>
                        Fur & Fortune
                      </Link>{" "}
                      game.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32">
                      Players can use Lucky Tickets and Prestige Trophies to win substantial prizes,
                      including game items and USDC. Additionally, they will be featured in future
                      games, providing more avenues to garner a variety of rewards.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="tf-help-center tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10"></h2>
                  <h5 className="sub-title help-center mg-bt-32 "></h5>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default TrophiesAndTickets;
