import React, { createContext, useContext, useCallback } from "react";
import { BigNumber } from "bignumber.js";

import config from "../config/config";
import { useWeb3Context } from "./Web3Context";
import { ENV } from "../utils/environment";

export const KoziPoolContext = createContext({});

export const useKoziPoolContext = () => {
  return useContext(KoziPoolContext);
};

export const KoziPoolProvider = ({ children }) => {
  const { isReady, execFunction, consult } = useWeb3Context();

  const getAllowanceObject = (tokenContractName, addressToAllow, allowedContractName) => {
    return {
      tokenContractName,
      addressToAllow,
      allowedContractName,
      allowedAssetDescription: `${tokenContractName} in your wallet`,
      successMessage: `Allowance given to ${allowedContractName}`,
    };
  };

  const buyKozi = async (howMany) => {
    if (!isReady) return;

    await execFunction({
      contractName: "KoziPool",
      functionName: "buyKozi",
      functionParams: [howMany],
      allowance: getAllowanceObject("Stabletoken", config[ENV].KoziPool.address, "Kozi Pool"),
      successMessage: "You have successfully bought KOZI. It's already in your wallet!",
      errorList: [
        {
          reason: "cooldown",
          message: "Cooldown: There is a 10-minute cooldown between operations.",
        },
      ],
    });
  };

  const buyKoziAtPrice = async (howMany, price) => {
    if (!isReady) return;

    await execFunction({
      contractName: "KoziPool",
      functionName: "buyKoziAtPrice",
      functionParams: [howMany, price, 10], // 10 here equals 1%
      allowance: getAllowanceObject("Stabletoken", config[ENV].KoziPool.address, "Kozi Pool"),
      successMessage: "You have successfully bought KOZI. It's already in your wallet!",
      errorList: [
        {
          reason: "cooldown",
          message: "Cooldown: There is a 10-minute cooldown between operations.",
        },
      ],
    });
  };

  const sellKozi = async (howMany) => {
    if (!isReady) return;

    await execFunction({
      contractName: "KoziPool",
      functionName: "sellKozi",
      functionParams: [],
      successMessage:
        "You have successfully sold KOZI. The corresponding USDC is already in your wallet!",
      errorList: [
        {
          reason: "cooldown",
          message: "Cooldown: There is a 10-minute cooldown between operations.",
        },
      ],
      koziAmount: howMany,
    });
  };

  const sellKoziAtPrice = async (howMany, price) => {
    if (!isReady) return;

    await execFunction({
      contractName: "KoziPool",
      functionName: "sellKoziAtPrice",
      functionParams: [price, 10], // 10 here equals 1%
      successMessage:
        "You have successfully sold KOZI. The corresponding USDC is already in your wallet!",
      errorList: [
        {
          reason: "cooldown",
          message: "Cooldown: There is a 10-minute cooldown between operations.",
        },
      ],
      koziAmount: howMany,
    });
  };

  const getPrice = async () => {
    if (!isReady) return;

    const result = await consult({
      contractName: "KoziPool",
      functionName: "getPrice",
    });

    return BigNumber(result).div(1e18).toFixed(5);
  };

  const quoteBuyKozi = async (howMany) => {
    if (!isReady) return;

    const result = await consult({
      contractName: "KoziPool",
      functionName: "quoteBuyKozi",
      functionParams: [howMany],
    });

    return BigNumber(result).div(1e18).toFixed(2);
  };

  return (
    <KoziPoolContext.Provider
      value={{
        quoteBuyKozi,
        buyKozi,
        sellKozi,
        getPrice,
        buyKoziAtPrice,
        sellKoziAtPrice,
      }}
    >
      {children}
    </KoziPoolContext.Provider>
  );
};
