import React, { useState } from "react";
import BigNumber from "bignumber.js";
import { capitalize } from "../../../utils/capitalize";
import { format } from "../../../utils/math";

import {
  Container,
  TransactionDetails,
  TitleBox,
  Title,
  AdvancedButton,
  DetailItem,
  NotFound,
} from "./styles";

import ItemValue from "../item-value/ItemValue";

const toGwei = (wei) => {
  return new BigNumber(wei).dividedBy(new BigNumber(10).pow(9)).toFixed(0);
};

const Transaction = ({ hash = "", txn = null, searchCallback = null }) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const [isAdvanced, setIsAdvanced] = useState(false);

  BigNumber.config({ DECIMAL_PLACES: 18 });

  const toggleAdvanced = () => {
    setIsAdvanced(!isAdvanced);
  };

  const isTraitNumber = (name) => {
    if (name === "Trait") return true;
    if (name === "ChosenTrait") return true;
    if (name === "OldTrait1") return true;
    if (name === "OldTrait2") return true;
    if (name === "OldTrait3") return true;
    if (name === "NewTrait1") return true;
    if (name === "NewTrait2") return true;
    if (name === "NewTrait3") return true;
    return false;
  };

  return (
    <Container>
      {txn?.hash ? (
        <TransactionDetails>
          <TitleBox>
            <Title>Transaction Details</Title>
            {window.innerWidth > 580 && (
              <AdvancedButton onClick={toggleAdvanced} isAdvanced={isAdvanced}>
                {isAdvanced ? "Advanced" : "Standard"}
              </AdvancedButton>
            )}
          </TitleBox>

          <DetailItem>
            <strong>Action:</strong>{" "}
            <ItemValue onClick={searchCallback} isAction={true} value={txn.parseAction()} />
          </DetailItem>

          <DetailItem>
            <strong>From:</strong> <ItemValue onClick={searchCallback} value={txn.from} />
          </DetailItem>

          {txn.to && (
            <DetailItem>
              <strong>To:</strong> <ItemValue onClick={searchCallback} value={txn.to} />
            </DetailItem>
          )}

          {txn.parseAction() === "KOZI Transfer" && (
            <DetailItem>
              <strong>Amount:</strong>{" "}
              <ItemValue value={BigNumber(txn.value).dividedBy(1e18).toString()} />
            </DetailItem>
          )}

          {txn.contractAddress && (
            <DetailItem>
              <strong>Deployed Contract:</strong>{" "}
              <ItemValue onClick={searchCallback} value={txn.contractAddress} />
            </DetailItem>
          )}

          <DetailItem>
            <strong>Gas Fee:</strong> <ItemValue onClick={searchCallback} value={txn.feeString()} />
          </DetailItem>

          <DetailItem>
            <strong>Block:</strong>{" "}
            {
              <ItemValue
                onClick={searchCallback}
                value={parseInt(txn.blockNumber)}
                isBlockNumber={true}
              />
            }
          </DetailItem>

          <DetailItem>
            <strong>Status:</strong>{" "}
            <ItemValue onClick={searchCallback} value={txn.parseStatus()} />
          </DetailItem>

          {isAdvanced && (
            <>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Tx Hash:</strong> <ItemValue onClick={searchCallback} value={txn.hash} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Block Hash:</strong>{" "}
                <ItemValue onClick={searchCallback} value={txn.blockHash} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Input:</strong>{" "}
                <ItemValue onClick={searchCallback} value={txn.parseInput()} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Gas Price:</strong>{" "}
                <ItemValue onClick={searchCallback} value={toGwei(txn.gasPrice) + " Gwei"} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Gas Used:</strong>{" "}
                <ItemValue onClick={searchCallback} value={parseInt(txn.gasUsed)} />
              </DetailItem>
              <DetailItem style={{ color: "yellow" }}>
                <strong>Position in block:</strong>{" "}
                <ItemValue onClick={searchCallback} value={parseInt(txn.transactionIndex + 1)} />
              </DetailItem>

              <DetailItem style={{ color: "yellow" }}>
                <strong>Nonce:</strong>{" "}
                <ItemValue onClick={searchCallback} value={parseInt(txn.nonce)} />
              </DetailItem>

              <DetailItem style={{ color: "yellow" }}>
                <strong>Value:</strong> <ItemValue value={txn.value} />
              </DetailItem>
            </>
          )}

          {txn.events.map(
            (event, index) =>
              event.fragment && (
                <div
                  key={`${event.logIndex} ${index}`}
                  style={{
                    marginTop: "20px",
                    outline: "1px solid white",
                    padding: "4px 10px 1px 10px",
                    borderRadius: "4px",
                    backgroundColor: "#000010",
                  }}
                >
                  <DetailItem>
                    <strong>Event:</strong>{" "}
                    <ItemValue onClick={searchCallback} isAction={true} value={event.name} />
                  </DetailItem>
                  <DetailItem>
                    <strong>Emitted By:</strong>{" "}
                    <ItemValue onClick={searchCallback} value={event.emitter} />
                  </DetailItem>
                  {event?.fragment?.inputs?.length &&
                    event.fragment.inputs.map((input, index) => (
                      <DetailItem key={`Topic ${index}`}>
                        <strong>{capitalize(input?.name || `Topic ${index}`)}:</strong>{" "}
                        <ItemValue
                          value={String(event.args[index])}
                          isFrom={capitalize(input?.name) === "From"}
                          isTo={capitalize(input?.name) === "To"}
                          isResourceArray={capitalize(input?.name) === "Resources"}
                          isTraitNumber={isTraitNumber(capitalize(input?.name))}
                          onClick={searchCallback}
                          isPrice={
                            input?.name === "priceBefore" ||
                            input?.name === "priceAfter" ||
                            input?.name === "price" ||
                            input?.name === "newPrice" ||
                            input?.name === "targetPrice"
                          }
                        />
                      </DetailItem>
                    ))}
                </div>
              )
          )}
        </TransactionDetails>
      ) : (
        hash && (
          <NotFound>
            Transaction not found. If you have just sent it to the Kingdom Chain, please wait 60
            seconds and try again.
          </NotFound>
        )
      )}
    </Container>
  );
};

export default Transaction;
