import { Container, ImageContainer, TextContainer, EbctContainer } from "./styles";
import { resourceToImagePath } from "../../../config/resources/09-resources-images";

const ResourceContainerUpgrades = ({ resource, quantity, background, hasEnough = true }) => {
  const resourcePath = `../${resourceToImagePath(Number(resource))}`;
  const smallFont = quantity > 9999;
  if (quantity % 0 !== 0 && quantity > 100) {
    quantity = Math.ceil(parseFloat(quantity));
  }

  if (isNaN(quantity) || quantity < 0) {
    // reload the page:
    window.location.reload();
  }

  return (
    <Container background={background} missing={!hasEnough}>
      {resource === 98 || resource === "eBct" ? (
        <EbctContainer background={background}>⚡</EbctContainer>
      ) : (
        <ImageContainer background={background} missing={!hasEnough}>
          <img src={resourcePath} alt="resource" />
        </ImageContainer>
      )}

      <TextContainer smallFont={smallFont} missing={!hasEnough}>
        <h6>{quantity}</h6>
      </TextContainer>
    </Container>
  );
};

export default ResourceContainerUpgrades;
