export async function getFinalUrl(url) {
  if (!url) {
    return { success: true, message: "No URL provided", url: "" };
  }

  // trim:
  url = url.trim();

  if (url === "" || url === '""') {
    return { success: true, message: "URL is empty", url: "" };
  }

  // if it doesn't start with "https://" reject it:
  if (!url.startsWith("https://")) {
    return {
      success: false,
      message: "Invalid Image URL: it must be in a secure server",
      url: "",
    };
  }

  // if it doesn't end with ".png" or ".gif" reject it:
  if (!url.endsWith(".png") && !url.endsWith(".gif") && !url.endsWith(".jpg")) {
    return {
      success: false,
      message: "Invalid Image URL: the link must end in .png (or .gif for mythics)",
      url: "",
    };
  }

  // get the header of the image
  try {
    const header = await fetch(url, {
      method: "HEAD",
    });

    if (header.status !== 200) {
      return {
        success: false,
        message: "Invalid Image URL: it must be a valid image",
        url: "",
      };
    }

    // now make sure the image is a PNG or a GIF (check the content-type)
    const contentType = header.headers.get("content-type");
    if (
      contentType !== "image/png" &&
      contentType !== "image/gif" &&
      contentType !== "image/jpg" &&
      contentType !== "image/jpeg"
    ) {
      return {
        success: false,
        message: "Invalid Image URL: it must be a PNG (or GIF for mythics)",
        url: "",
      };
    }

    // Make sure the image doesn't have more than 4Mb:
    const contentLength = header.headers.get("content-length");
    if (contentLength > 4000000) {
      return {
        success: false,
        message: "Invalid Image: it must be have less than 4Mb",
        url: "",
      };
    }
  } catch (e) {
    return {
      success: false,
      message: "Invalid or unreachable image URL",
      url: "",
    };
  }

  return { success: true, message: "Valid URL", url: url };
}
