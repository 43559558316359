export const CONSTANTS = {
  sacrificeValue: [1, 3, 5], // both mouse and cat are worth this much for common / rare / epic
  removePriceInTickets: 0.4, // price in lucky tickets
  removePriceInStableKozi: 0.67, // price in stable-kozi
  fullKoziInfusion: 1, // how much it costs to double all rewards
  oneLineKoziInfusion: 0.1, // how much it costs to double one line
  prizes: [
    {
      name: "Dragon Scales",
      resource: "21",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "20", "40"],
      background: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
    },
    {
      name: "Mouse Boxes",
      resource: "12",
      lowItemValue: "1",
      highItems: ["3", "6", "9", "27", "81"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "eBCT",
      resource: 98,
      formatWithKs: true,
      lowItemValue: "100",
      highItems: ["1000", "1500", "2000", "2500", "5000"],
      background: "linear-gradient(0deg, rgba(37, 58, 75,1), rgba(80, 125, 160,1))",
    },
    {
      name: "Mouse Dna",
      resource: "14",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "25", "50"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "Exotic Cheese",
      resource: "5",
      lowItemValue: "2",
      highItems: ["12", "24", "36", "48", "96"],
      background: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
    },
    {
      name: "Cat Boxes",
      resource: "13",
      lowItemValue: "1",
      highItems: ["3", "6", "9", "27", "81"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "Griffin Feathers",
      resource: "22",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "20", "40"],
      background: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
    },
    {
      name: "Crafting Powder",
      resource: "26",
      formatWithKs: true,
      lowItemValue: "3000",
      highItems: ["10000", "20000", "30000", "50000", "100000"],
      background: "linear-gradient(0deg, rgba(250, 20, 60,1),rgba(200, 80, 50,1))",
    },
    {
      name: "Cat Milk",
      resource: 6,
      lowItemValue: "2",
      highItems: ["12", "24", "36", "48", "96"],
      background: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
    },
    {
      name: "Cat Dna",
      resource: "15",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "25", "50"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "Satyr Horns",
      resource: "23",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "20", "40"],
      background: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
    },
    {
      name: "Cow Boxes",
      resource: "19",
      lowItemValue: "0.25",
      highItems: ["1", "2", "3", "9", "27"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "USDC",
      resource: 102,
      lowItemValue: "0.50",
      prefix: "$",
      highItems: ["1.00", "1.50", "2.00", "2.50", "jackpot"],
      background: "linear-gradient(0deg, rgba(20, 50, 0,1), rgba(0, 200, 0,1))",
    },
    {
      name: "Cow Dna",
      resource: "16",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "25", "50"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "Magic Mushrooms",
      resource: 7,
      lowItemValue: "5",
      highItems: ["25", "35", "50", "75", "150"],
      background: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
    },
    {
      name: "Elephant Boxes",
      resource: "20",
      lowItemValue: "0.1",
      highItems: ["0.5", "1", "2", "3", "9"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "Dinosaur Skulls",
      resource: "24",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "20", "40"],
      background: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
    },
    {
      name: "Lucky Tickets",
      resource: "🎫",
      lowItemValue: "0.5",
      highItems: ["1", "1.5", "2", "2.5", "5"],
      background: "linear-gradient(0deg, rgba(250, 20, 60,1),rgba(200, 80, 50,1))",
    },
    {
      name: "Power Mangoes",
      resource: "8",
      lowItemValue: "1",
      highItems: ["3", "6", "9", "15", "30"],
      background: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
    },
    {
      name: "Elephant Dna",
      resource: "17",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "25", "50"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "Ai Chips",
      resource: "25",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "20", "40"],
      background: "linear-gradient(0deg, rgba(60, 20, 60,1), rgba(100, 40, 100,1))",
    },
    {
      name: "Gene Therapy",
      resource: "11",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "25", "50"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "BCT",
      resource: 99,
      lowItemValue: "5",
      highItems: ["20", "35", "50", "100", "250"],
      background: "linear-gradient(0deg, rgba(48, 74, 50,1), rgba(108, 161, 104,1))",
    },
    {
      name: "Ape Dna",
      resource: "18",
      lowItemValue: "1",
      highItems: ["3", "5", "10", "25", "50"],
      background: "linear-gradient(0deg, rgba(0, 0, 80,1), rgba(0, 40, 100,1))",
    },
    {
      name: "Ape Tools",
      resource: "9",
      lowItemValue: "1",
      highItems: ["5", "10", "15", "30", "60"],
      background: "linear-gradient(0deg, rgba(157, 92, 16,1), rgba(188, 154, 19,1))",
    },
  ],
};
