import { useEffect, useState, useRef } from "react";
import BigNumber from "bignumber.js";

import styled, { keyframes } from "styled-components";

import { usePlayerContext } from "../../contexts/PlayerContext";
import { useOtherPlayerContext } from "../../contexts/OtherPlayerContext";

import Card from "../card/Card";

import { useLocation } from "react-router-dom";
import { fromWei } from "../../utils/wei";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const GridItem = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  margin-bottom: 20px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  position: relative;
  flex-wrap: wrap;
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: #0f2034;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    `
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  `}

  &:hover {
    background: #0f2034;
    opacity: 0.8;
    outline: 2px solid white;
  }
`;

export const GridKozika = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 4fr));
  width: 95%;
  justify-items: center;

  @media (max-width: 685px) {
    grid-template-columns: repeat(auto-fill, minmax(190px, 4fr));
    width: 100%;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    width: 320px;
    @media (max-width: 450px) {
      grid-row-gap: 6px;
    }
  }
`;

export const NoNftsMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const LoadingMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`;

export const BigLoadingMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const SmallLoadingMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
  text-align: center;
`;

const NFTS_PER_PAGE = 27;

const InventoryGridKozika = ({ isOtherPlayer = false }) => {
  BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const { player } = usePlayerContext();
  const { otherPlayer } = useOtherPlayerContext();

  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(items.length / NFTS_PER_PAGE));

  const itemsToShow = items.slice((currentPage - 1) * NFTS_PER_PAGE, currentPage * NFTS_PER_PAGE);

  const [noNftsMessage, setNoNftsMessage] = useState("This account has no NFTs");
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [traitSinergies, setTraitSinergies] = useState([]);
  const itemsRef = useRef([]);

  const location = useLocation();

  const canEnterSquad = (nft, squadId) => {
    if (!currentPlayer) return false;

    // find the squad that corresponds to the squadId we received
    const squad = currentPlayer.squads.find((squad) => squad.id === squadId);
    return squad?.type_ === nft?.type_ || squad?.type_ === 6 || nft?.type_ >= 6;
  };

  const squadType = (squadId) => {
    if (!currentPlayer) return 6;

    // find the squad that corresponds to the squadId we received
    const squad = currentPlayer.squads.find((squad) => squad.id === squadId);
    const type_ = squad?.type_;
    return type_;
  };

  useEffect(() => {
    const _currentPlayer = isOtherPlayer ? otherPlayer : player;
    setCurrentPlayer(_currentPlayer);
    setTraitSinergies([]);

    if (!_currentPlayer?.nfts) return;

    if (_currentPlayer) {
      console.log("Inventory :: Active Player is", _currentPlayer.address);

      if (location.state?.hasOwnProperty("squadId")) {
        console.log(">>>>>>>>>>>>>>>>>> SHOWING NFTS FOR SQUAD", location.state.squadId);

        const nftList = _currentPlayer.nfts.filter(
          (nft) => nft.squadId === 0 && canEnterSquad(nft, location.state.squadId)
        );

        // get the squad:
        const squad = _currentPlayer.squads.find((squad) => squad.id === location.state.squadId);

        if (!squad) return;

        //get the synergies of any card available:
        setTraitSinergies(squad.traits);

        console.log(">>>>>>>>>>>>>>>>>> NFT LIST", nftList);

        if (JSON.stringify(itemsRef.current) !== JSON.stringify(nftList)) {
          itemsRef.current = nftList;
          setItems(nftList);
        }
      } else if (location.state?.hasOwnProperty("forbiddenNftIds")) {
        console.log(
          ">>>>>>>>>>>>>>>>>> SHOWING NFTS FORBIDDEN NFTS",
          location.state.forbiddenNftIds
        );
        console.log("CARD STATE", location.state);
        const tempList = [..._currentPlayer.nfts];
        if (location.state?.injectPowerMangoes === true) {
          if (parseInt(_currentPlayer.ingameBalances[2][8]) >= 300) {
            // inject power mangoes in the first array slot and push everything one slot further
            tempList.unshift({
              id: 999999008, // this is a fake id representing power mangoes
              isActuallyAResource: true,
              resourceIndex: 8,
              resourceQuantity: 3,
              questId: 0,
              type_: location.state?.requiredNftFeatures?.type_,
              rarity: location.state?.requiredNftFeatures?.rarity,
            });
          }
        }
        let ignoreExlusion = false;
        if (location.state?.injectStablekozi && location.state?.injectStablekozi !== 0) {
          tempList.unshift({
            id: 999999009, // this is a fake id representing kozi
            isActuallyAResource: true,
            resourceIndex: 999,
            resourceQuantity: BigNumber(location.state.injectStablekozi)
              .div(1e18)
              .toFixed(4, BigNumber.ROUND_CEIL),
            questId: 0,
            type_: location.state?.requiredNftFeatures?.type_,
            species: location.state?.requiredNftFeatures?.type_,
            rarity: 5,
          });

          ignoreExlusion = true;
        }

        let nftList = tempList;
        if (!ignoreExlusion) {
          nftList = tempList.filter((nft) => !location.state.forbiddenNftIds.includes(nft.id));
        }

        if (location.state?.hasOwnProperty("requiredNftFeatures")) {
          console.log(">>>>>>>>>>>>>>>>>> SHOWING NFTS WITH REQUIRED FEATURES");

          // filter nfts that have the same type as the requiredNftFeatures.type_ property
          nftList = nftList.filter((nft) =>
            location.state.requiredNftFeatures.type_
              ? nft.type_ === location.state.requiredNftFeatures.type_
              : true
          );

          // filter nfts that have the same rarity as the requiredNftFeatures.rarity property
          nftList = nftList.filter((nft) =>
            location.state.requiredNftFeatures.rarity
              ? nft.rarity === location.state.requiredNftFeatures.rarity
              : true
          );

          // now filter nfts that are on quests
          nftList = nftList.filter((nft) => nft.questId === 0);

          if (location.state?.hasOwnProperty("pickToSell")) {
            // filter nfts that are in squads
            nftList = nftList.filter((nft) => nft.squadId === 0);
          }
        }

        setItems(nftList);

        if (nftList.length === 0) {
          setNoNftsMessage(
            "You have no other NFTs of the same species and rarity that are not on quests"
          );
        }
      } else {
        // Show ALL NFTs
        console.log(">>>>>>>>>>>>>>>>>>>>> SHOWING ALL NFTS");
        setItems(_currentPlayer.nfts);
      }
    }
  }, [currentPlayer, location.state, player, otherPlayer, isOtherPlayer]);

  useEffect(() => {
    setTotalPages(Math.ceil(items?.length / NFTS_PER_PAGE));
  }, [items]);

  if (!currentPlayer) {
    console.log("Inventory: awaiting for player data...");
  }

  const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
    let buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <PaginationButton
          key={i}
          onClick={() => setCurrentPage(i)}
          disabled={currentPage === i}
          isSelected={currentPage === i}
          style={i === 1 ? { marginLeft: "0px" } : null}
        >
          {i}
        </PaginationButton>
      );
    }

    return totalPages > 1 && <PaginationContainer>{buttons.slice(0, 21)}</PaginationContainer>;
  };

  return (
    currentPlayer &&
    traitSinergies && (
      <Container>
        {itemsToShow?.length > 0 && (
          <>
            <>
              <GridKozika>
                {itemsToShow.map((nft, index) => (
                  <GridItem key={currentPlayer.address + nft.id}>
                    <Card
                      nft={nft}
                      squadType={squadType(nft.squadId)}
                      key={currentPlayer.address + index}
                      squadSynergies={traitSinergies}
                      lockSize={true}
                    />
                  </GridItem>
                ))}
              </GridKozika>
            </>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            )}
          </>
        )}
        {currentPlayer && itemsToShow?.length === 0 && (
          <NoNftsMessageContainer>{noNftsMessage}</NoNftsMessageContainer>
        )}
        {!currentPlayer && (
          <LoadingMessageContainer>
            <BigLoadingMessageContainer>Loading your NFTs...</BigLoadingMessageContainer>
            <SmallLoadingMessageContainer>
              (if takes more than 30 seconds, reload the page)
            </SmallLoadingMessageContainer>
          </LoadingMessageContainer>
        )}
      </Container>
    )
  );
};

export default InventoryGridKozika;
