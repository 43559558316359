import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: "center";
  width: 60px;
  height: 60px;
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  background: linear-gradient(0deg, rgba(15, 52, 72, 1), rgba(15, 32, 52, 1));

  &:hover {
    outline: ${(props) => (props.disabled ? "none" : "2px solid rgba(255,255,255,1)")};
    background: ${(props) =>
      props.disabled
        ? "linear-gradient(0deg, rgba(15, 52, 72, 1), rgba(15, 32, 52, 1))"
        : "linear-gradient(180deg, rgba(15, 32, 52, 1), rgba(15, 32, 52, 1))"};
  }

  &:active {
    background: linear-gradient(180deg, rgba(15, 32, 52, 1), rgba(15, 32, 92, 1));
  }

  &:focus {
    outline: none;
  }
`;

export const IdleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 58px;
  h2,
  h6 {
    color: rgba(255, 242, 0, 0.8);
    font-size: 44px;
  }
  cursor: pointer;

  @media (max-width: 450px) {
    height: 51px;
  }
`;

export const ClickableOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  background: transparent;
  height: 60px;
  width: 60px;
  left: 150px;
`;

export const OnQuestContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 58px;
  cursor: default;
  h2,
  h6 {
    color: rgba(255, 242, 0, 0.8);
  }

  @media (max-width: 450px) {
    height: 51px;
  }
`;
