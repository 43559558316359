import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /*font-family: "Metropolis", sans-serif;*/
  /*letter-spacing: -0.06em;*/
  margin-bottom: 6px !important;

  ul {
    height: 110px;
    background-color: #06063b;
  }

  .dropdown li {
    padding: 1px 8px;
    /*font-family: "Metropolis", sans-serif;*/
  }

  .dropdown li:hover {
    background-color: #06065b;
  }

  a {
    padding: 5px !important;
  }

  @media (max-width: 450px) {
    .dropdown {
      width: 80% !important;
      min-width: 242px !important;
    }

    .dropdown a {
      width: 80% !important;
      min-width: 242px !important;
    }
  }
`;

export const DropdownItem = styled.div`
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  height: 24px !important;
  padding: 0px !important;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
