import React, { useEffect, useState, useCallback } from "react";
import Messenger from "../../utils/Messenger.js";
import { Container, OuterLoadingContainer, LoadingContainer, LoadingLabel } from "./styles.js";

import PWGrid from "./pw-grid/PWGrid.jsx";
import Service from "./service/index.js";

import { usePlayerContext } from "../../contexts/PlayerContext.jsx";
import { useWeb3Context } from "../../contexts/Web3Context.jsx";

import IntervalManager from "../../utils/IntervalManager.js";

const ProsperityWarsScreen = () => {
  const { isReady } = useWeb3Context();
  const { player } = usePlayerContext();

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrders = async () => {
    if (!isReady) return;

    setIsLoading(true);

    let _orders = [];

    try {
      _orders = await Service.getDutchAuctions();
      setOrders(_orders);

      IntervalManager.start("refresh-prosperity-wars", refreshOrdersPrices, 10000);
    } catch (e) {
      // maybe this player is not even registered yet
      console.error("error fetching orders", e);
    }

    setIsLoading(false);
  };

  // Initial Effect for setting up with URL parameters
  useEffect(() => {
    if (!isReady || !player) {
      return;
    }

    Messenger.cancelDelay();
  }, [player, isReady]);

  // Separate Effect for regular refreshing
  useEffect(() => {
    fetchOrders();

    return () => {
      IntervalManager.stop("refresh-prosperity-wars");
    };
  }, [isReady, player]);

  const refreshOrdersPrices = async () => {
    let _orders;
    try {
      _orders = await Service.getDutchAuctions();
    } catch (e) {
      // maybe this player is not even registered yet
      console.error("error fetching orders", e);
    }

    const areEqual = (a, b) => {
      let equal = true;

      if (a?.length !== b?.length) return false;

      for (let i = 0; i < a?.length || 0; i++) {
        if (a[i].priceInWei !== b[i].priceInWei) {
          equal = false;
          break;
        }

        if (a[i].createdAt !== b[i].createdAt) {
          equal = false;
          break;
        }

        if (a[i].bids !== b[i].bids) {
          equal = false;
          break;
        }
      }

      return equal;
    };

    if (!areEqual(_orders, orders)) {
      setOrders(_orders);
    } else {
      console.log("No changes");
    }
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <OuterLoadingContainer>
            <LoadingContainer>
              <LoadingLabel>Loading...</LoadingLabel>
            </LoadingContainer>
          </OuterLoadingContainer>
        ) : (
          <PWGrid orders={orders} isMerchant={player && player?.isMerchant} isMyOrders={false} />
        )}
      </Container>
    </>
  );
};

export default ProsperityWarsScreen;
