export function existingCount(collection, playerNftIds) {
  if (!collection) return 0;
  if (!playerNftIds) return 0;

  const existingMembers = collection.nfts.filter((nft) => nft?.id > 0);

  // create a list of NFT ids that
  const myMembers = existingMembers.filter((nft) => playerNftIds.includes(nft.id));
  return myMembers.length;
}

export function maxSize(collection) {
  // it's 18 minus the number of dead nfts in the collection
  const deadNfts = collection.nfts.filter((nft) => nft?.isDead);

  return 18 - deadNfts.length;
}

export function isComplete(collection, playerNftIds) {
  if (!collection) return false;
  if (!collection.nfts) return false;
  if (!playerNftIds) return false;

  const members = collection.nfts.filter((nft) => nft?.id > 0);
  const existingMembers = members.filter((nft) => playerNftIds.includes(nft.id));

  const deadNfts = collection.nfts.filter((nft) => nft?.isDead);
  const maxSize = 18 - deadNfts.length;

  return existingMembers.length === maxSize && maxSize > 0;
}

export function isFull(collection) {
  if (!collection) return false;
  if (!collection.nfts) return false;
  return collection.nfts.length >= 18;
}
