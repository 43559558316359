import React, { useEffect } from "react";
import Messenger from "../../utils/Messenger";

import { usePlayerContext } from "../../contexts/PlayerContext";

import BeastMinter from "./beast-minter/BeastMinter";

import { GlobalContainer, MintContainer } from "./styles";

import { useBoosterCrackerContext } from "../../contexts/BoosterCrackerContext";

const MintScreen = () => {
  const { player, loading, isRegistered } = usePlayerContext();
  const { unboxBooster } = useBoosterCrackerContext();

  useEffect(() => {
    if (!player) {
      Messenger.noPlayerDelayedError();
      return;
    } else {
      Messenger.cancelDelay();
    }

    if (!isRegistered && !loading) {
      console.log("Mint :: Player not found (yet?)");
      return;
    }
  }, [player, loading, isRegistered]);

  if (!player || !player.ingameBalances || !player.ingameBalances[2]) return null;

  const mouseBoxesbalance = Math.floor(player.ingameBalances[2][12] / 100);
  const catBoxesbalance = Math.floor(player.ingameBalances[2][13] / 100);
  const cowBoxesbalance = Math.floor(player.ingameBalances[2][19] / 100);
  const elephantBoxesbalance = Math.floor(player.ingameBalances[2][20] / 100);

  const handleMint = async (type, amount) => {
    console.log(`Minting ${amount} ${type}`);
    await unboxBooster(type, amount);
  };

  return (
    <GlobalContainer>
      <MintContainer>
        <BeastMinter
          type="Mouse"
          balance={mouseBoxesbalance}
          onMint={(amount) => handleMint(1, amount)}
        />
        <BeastMinter
          type="Cat"
          balance={catBoxesbalance}
          onMint={(amount) => handleMint(2, amount)}
        />
        <BeastMinter
          type="Cow"
          balance={cowBoxesbalance}
          onMint={(amount) => handleMint(3, amount)}
        />
        <BeastMinter
          type="Elephant"
          balance={elephantBoxesbalance}
          onMint={(amount) => handleMint(4, amount)}
        />
      </MintContainer>
    </GlobalContainer>
  );
};

export default MintScreen;
