import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 198px;
  height: ${(props) => (props.hasBonus ? "36px" : "30px")};
  min-height: 20px;
  overflow: hidden;
  background: ${(props) => (props.disable ? props.background : props.background)};
  cursor: ${(props) => (props.disable && !props.isPreview ? "not-allowed" : "pointer")};
  border-radius: 4px;
  margin-bottom: ${(props) => (props.isLastOne ? "0px" : "3px")};

  outline-offset: -1px;
  transition: all 0.1s ease-out;

  filter: ${(props) => (props.isRejected || props.disable ? "brightness(0.4)" : "brightness(1)")};

  // only add hover effect if not disabled
  ${(props) =>
    !props.disable &&
    css`
      &:hover {
        filter: brightness(1.1);
        outline: ${(props) =>
          props.missing ? "1px solid #f22" : props.disable ? "none" : "2px solid #fff"};
      }

      &:active {
        filter: brightness(1.4);
      }
    `}
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 100%;
  background: ${(props) => props.background};
  outline: 1px solid black;
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
  background: ${(props) => props.background};
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 100%;
  background: ${(props) => props.background};
  outline: 1px solid black;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${(props) => props.background};
  font-size: 14px;

  img {
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;

export const TextContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 100%;
  position: relative;
  top: 1px;

  h6 {
    font-size: ${(props) => (props.smallFont ? "15px" : "15px")};
    color: ${(props) => (props.disable ? "#777" : "#fff")};

    text-shadow: ${(props) =>
      props.missing
        ? "-0.5px -0.5px 0 #333, 0 -0.5px 0 #333, 0.5px -0.5px 0 #333, 0.5px 0 0 #333, 0.5px 0.5px 0 #333, 0 0.5px 0 #333, -0.5px 0.5px 0 #333, -0.5px 0 0 #333"
        : "none"};
  }

  p {
    font-size: 10px;
  }

  @media (max-width: 685px) {
    h6 {
      font-size: 14px;
    }
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;

export const Input = styled.input`
  width: 90%;
  height: 88%;
  background: ${(props) => props.background};
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  font-size: 15px;
  color: black;
  text-align: center;
  border-radius: 4px;
`;

export const BonusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: -6px;
`;

export const EbctContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  min-height: 20px;
  height: 26px;
  font-size: 14px;
  background: ${(props) => props.background};
`;