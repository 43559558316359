/**
 * This is the model for an NFT.
 * To get the correct data, you need to call the NFT contract and get the data from there.
 * The function is nft.getNft(id) and it returns a orderData struct, that you pass to the constructor of this class.
 */
import BigNumber from "bignumber.js";

class OrderModel {
  constructor(orderData, isPreview = false) {
    this.setData(orderData);
    this.isPreview = isPreview;
  }

  setData(orderData) {
    this.id = new BigNumber(orderData.orderId).toNumber(); // an integer from 0 to infinity. This is the global ID of this order. @notice: Order 0 is INVALID.
    this.createdAt = orderData.createdAtBlock
      ? parseInt(orderData.createdAtBlock)
      : parseInt(orderData.createdAt); // an integer from 0 to infinity. This is the blocknumber when the order was created.
    this.assetId = new BigNumber(orderData.assetId).toNumber(); // an integer from 0 to infinity. This is the global ID of this asset. @notice: Asset ID 0 is VALID - it's Super Cheese.
    this.quantity = orderData.assetQuantity
      ? new BigNumber(orderData.assetQuantity).toNumber()
      : new BigNumber(orderData.quantity).toNumber(); // an integer from 0 to infinity. This is the quantity of the asset that is being sold. @notice: Quantity 0 is INVALID.
    this.seller = orderData.seller; // the blockchain address of the seller
    this.privateFor = orderData.privateFor; // the blockchain address of the buyer; if it's the zero address, it means the order is public
    this.priceInWei = new BigNumber(orderData.priceInWei).toString(); // the price of the asset in WEI
    this.allowEbct = orderData.allowEbct;
    this.isNft = orderData.isNft; // true if the asset is an NFT, false if it's a resource
    this.isPriceInBusd = orderData.isPriceInBusd; // true if the price is in USDC, false if it's in BCT
    this.isStabletokenOnly = orderData.isBusdOnly; // true if the price is in USDC and the buyer can only pay in USDC, false if the price is in BCT and the buyer can pay in USDC only
    this.isDutchAuction = orderData.isDutchAuction; // true if the order is a Dutch auction, false if it's a fixed price auction
    this.pricePaidInWei = new BigNumber("0").toString(); // the price paid by the buyer in WEI (starts as 0)
    this.paidInBusd = false; // true if the buyer paid in USDC, false if the buyer paid in BCT or eBCT
    this.paidInEbct = false; // true if the buyer paid in eBCT, false if the buyer paid in BCT or USDC
    this.txHash = orderData.txHash; // the transaction hash of the order
    this.nft = null; // the actual NFT object (to be fetched later), if the asset is an NFT

    // normalized values:
    this.normalizedPriceInBct = orderData.normalizedPriceInBct; // the price of the asset in BCT || null
    this.normalizedPriceInBusd = orderData.normalizedPriceInBusd; // the price of the asset in USDC || null

    this.isPoolFuel = orderData.isPoolFuel; // true if the asset is a pool fuel, false if it's not

    this.bids = orderData.bids || 0;
  }

  set(propertyName, value) {
    this[propertyName] = value;
  }
}

export default OrderModel;
