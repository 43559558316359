import BigNumber from "bignumber.js";

export const koziPrice = 10; // $10 is the target price for 1 kozi

export function koziToStable(kozi) {
  return BigNumber(kozi).times(BigNumber(koziPrice)).toString();
}

export function stableToKozi(stable) {
  return BigNumber(stable).div(BigNumber(koziPrice)).toString();
}
