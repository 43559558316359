import { resourceToImagePath } from "../../../config/resources/09-resources-images";
import { Tooltip } from "react-tooltip";
import CountUp from "react-countup";
import { indexToTooltip, bonusToBackground } from "../../../utils/constants.js";
import { useState } from "react";

import {
  Container,
  ImageContainer,
  TextContainer,
  GradientContainer,
  Input,
  Image,
  EbctContainer,
} from "./styles";

const ResourceContainer = ({
  resource,
  quantity,
  background,
  isEditable = false,
  disabled = false,
  tooHigh = false,
  onChange = null,
  duration = 3,
}) => {
  const [inputValue, setInputValue] = useState(quantity);

  const tooltipText = indexToTooltip[resource] || "";
  const tooltipId = `tooltip-${resource}-${isEditable}`;

  // if quantity == 0, the background should be the gray one (not the gradient)
  if (!isEditable && (quantity === 0 || quantity === "0.00")) {
    background = bonusToBackground["none"];
  }

  if (
    isEditable &&
    (inputValue === 0 || inputValue === "0.00" || inputValue === "0" || inputValue === "")
  ) {
    background = bonusToBackground["none"];
  }

  const handleInputChange = (e) => {
    // prevent default:
    e.preventDefault();

    const value = e.target.value;

    // Prevent negative numbers
    if (parseFloat(value) < 0) return;

    // Allow only characters 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 (don't allow dots, don't allow 'e's)
    if (value.match(/[^0-9]/g)) return;

    setInputValue(value);

    if (onChange) onChange(value);
  };

  return (
    <GradientContainer>
      <a data-tooltip-id={tooltipId} data-tooltip-content={tooltipText}>
        <Container background={background}>
          {resource !== 100 && resource !== 98 && resource !== "eBct" && (
            <ImageContainer background={background}>
              <Image src={resourceToImagePath(Number(resource))} disabled={disabled} />
            </ImageContainer>
          )}
          {(resource === 98 || resource === "eBct") && (
            <EbctContainer background={background}>⚡</EbctContainer>
          )}
          {resource !== 100 && (
            <TextContainer quantity={quantity}>
              <h6>
                {isEditable ? (
                  <Input
                    type="number"
                    min="0"
                    step="0.01"
                    defaultValue={quantity}
                    onChange={handleInputChange}
                    onFocus={(event) => event.target.select()}
                    disabled={disabled}
                    tooHigh={tooHigh}
                  />
                ) : (
                  <CountUp start={0} end={quantity} duration={duration} decimals={2} />
                )}
              </h6>
            </TextContainer>
          )}
        </Container>
      </a>
      {!isEditable && (
        <Tooltip id={tooltipId} style={{ fontSize: "12px", zIndex: 99999, fontWeight: 600 }} />
      )}
    </GradientContainer>
  );
};

export default ResourceContainer;
