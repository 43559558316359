export function getQuestProgressInDays(startedAt, currentBlock) {
  const inSeconds = getQuestElapsedSeconds(startedAt, currentBlock);
  const inDays = inSeconds / 86400;
  return inDays;
}
export function getQuestElapsedSeconds(startedAt, currentBlock) {
  return getQuestElapsedBlocks(startedAt, currentBlock) * 10;
}
export function getQuestElapsedBlocks(startedAt, currentBlock) {
  const elapsedBlocks = currentBlock - startedAt;
  return elapsedBlocks;
}

export function getQuestElapsedTime(startedAt, currentBlock) {
  const inSeconds = getQuestElapsedSeconds(startedAt, currentBlock);
  // format time as dd:hh:mm:ss, but only show days if there are any
  const days = Math.floor(inSeconds / 86400);
  const hours = `0${Math.floor((inSeconds % 86400) / 3600)}`.slice(-2);
  const minutes = `0${Math.floor((inSeconds % 3600) / 60)}`.slice(-2);
  const seconds = `0${Math.floor(inSeconds % 60)}`.slice(-2);

  const time = `${
    days ? `${days} day${days > 1 ? "s" : ""} + ` : ""
  }${hours}:${minutes}:${seconds}`;
  return time;
}

export function getQuestProgressBarPercentage(startedAt, currentBlock) {
  // 100% is going to be 1 day (8640 blocks); anything higher than that will be considered 100%
  const elapsedBlocks = getQuestElapsedBlocks(startedAt, currentBlock);
  const percentage = Math.min((elapsedBlocks / 8640) * 100, 100);
  return percentage;
}

export function realQuestId(questId) {
  const realId =
    questId < 1000
      ? questId
      : questId < 10000
      ? Math.floor(questId / 1000)
      : Math.floor(questId / 10000);

  return realId;
}
