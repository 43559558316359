import styled, { css } from "styled-components";
import { resourceToImagePath } from "../../config/resources/09-resources-images";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 72px;
  height: 100%;
  min-height: 28px;
  border-radius: 6px;
  overflow: hidden;
  background: ${(props) => props.background};

  @media (max-width: 450px) {
    height: 26px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  background: ${(props) => props.background};

  img {
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;

const TextContainer = styled.div`
  font-family: "Metropolis", sans-serif;
  font-weight: 800;
  letter-spacing: -0.06em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  height: 100%;
  position: relative;
  left: 2px;
  top: 1px;

  h6 {
    font-size: ${(props) => (props.smallFont ? "13px" : "16px")};
    color: ${(props) => (props.missing ? "#111" : "#ffffff")};
  }

  @media (max-width: 685px) {
    h6 {
      font-size: 12px;
    }
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;

const EbctContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  background: ${(props) => props.background};
  font-size: 14px;
`;

const ResourceContainerModal = ({ resource, quantity, background, hasEnough = true }) => {
  const resourcePath = `../${resourceToImagePath(Number(resource))}`;
  const smallFont = quantity > 999;

  return (
    <Container background={background}>
      {resource === 98 || resource === "eBct" ? (
        <EbctContainer background={background}>⚡</EbctContainer>
      ) : (
        <ImageContainer background={background} missing={!hasEnough}>
          <img src={resourcePath} alt="resource" />
        </ImageContainer>
      )}

      <TextContainer smallFont={smallFont} missing={!hasEnough}>
        <h6>{quantity}</h6>
      </TextContainer>
    </Container>
  );
};

export default ResourceContainerModal;
