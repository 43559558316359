import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import {
  Container,
  LeftContainer,
  RightContainer,
  MiddleContainer,
  ImageContainer,
  TextContainer,
  BonusContainer,
  Input,
} from "./styles";
import { resourceToImagePath } from "../../../config/resources/09-resources-images";
import { bonusToBackground, MktplaceDragonBonus } from "../../../utils/constants";
import CountUp from "react-countup";

const ResourceContainer = ({
  resource,
  quantity,
  background,
  hasEnough = true,
  isLastOne = false,
  countUpSeconds = 3,
  isPreview = false,
  onEdit = null,
  bonus = false,
  forceEditValue = 0,
  previewDivider = 1,
  isBasePrice,
  isAccepted,
  onToggleAccepted = null,
  onIsEditing = null,
  useAnimatedCounter = false,
  buyCallback = null,
  isPoolFuel = false,
  isPowerBid = false,
}) => {
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(forceEditValue > 0 ? forceEditValue : quantity);

  const [previousQuantity, setPreviousQuantity] = useState(0);
  const [actualBonus, setActualBonus] = useState(BigNumber(0));
  const resourcePath = `../../${resourceToImagePath(Number(resource))}`;
  const smallFont = quantity > 9999;

  // if quantity == 0 || "0.00" we want to render a gray background
  const zeroQuantity = (quantity === 0 || quantity === "0.00") && !isPreview;
  if (zeroQuantity) {
    background = bonusToBackground["none"];
  }

  const resourceToName = {
    98: "eBCT",
    99: "BCT",
    102: "USDC",
    103: "USDC",
    FUEL: "FUEL",
  };

  useEffect(() => {
    if (quantity.toString() !== previousQuantity.toString()) {
      setTimeout(() => {
        setPreviousQuantity(quantity);

        setActualBonus(BigNumber(0));
      }, countUpSeconds * 1000);
    }
  }, [quantity, previousQuantity]);

  const handleClick = () => {
    if (buyCallback) buyCallback(resourceToName[resource]);
  };

  // Handle changes to the edit value
  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleEditEnd();
    }
  };

  const handleEditEnd = () => {
    setEditing(false);

    let _value = editValue;
    if (_value === "" || _value === "0" || parseFloat(_value) < 0.01) {
      _value = 0.01;
      setEditValue(_value);
    }

    if (onEdit) onEdit(_value, resource);
    if (onIsEditing) onIsEditing(false, resource);
  };

  const accepted = "✅";
  const rejected = "❌";
  const locked = "🔒";

  const handleToggleAccepted = () => {
    if (onToggleAccepted && resource !== 102 && isPreview) onToggleAccepted(resource);
  };

  const isRejected = isPreview && !isAccepted;

  return (
    <Container
      background={background}
      missing={!hasEnough}
      isLastOne={isLastOne}
      disable={(zeroQuantity && !isPreview) || !isAccepted}
      onClick={isPreview || ((zeroQuantity || !isAccepted) && !isPreview) ? null : handleClick}
      hasBonus={bonus}
      isBasePrice={isBasePrice}
      isRejected={isRejected}
      isPreview={isPreview}
      isPowerBid={isPowerBid}
    >
      <LeftContainer>
        <ImageContainer background={background}>
          {quantity === "Pool Fuel" ? <h6>🐉</h6> : <img src={resourcePath} alt="resource" />}
        </ImageContainer>
      </LeftContainer>
      <MiddleContainer>
        <TextContainer
          smallFont={smallFont}
          missing={!hasEnough}
          disable={(zeroQuantity && !isPreview) || !isAccepted}
          onClick={!isPreview ? null : handleClick}
        >
          {editing ? (
            <Input value={editValue} onChange={handleEditChange} onKeyDown={handleKeyDown} />
          ) : previousQuantity !== 39916801 ? (
            isPowerBid ? (
              <h6>
                {`${parseFloat(BigNumber(quantity).toFixed(2)).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })} or more`}
              </h6>
            ) : (
              <h6>
                {`${parseFloat(BigNumber(quantity).toFixed(2)).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}`}
              </h6>
            )
          ) : (
            <p>Syncing price...</p>
          )}
        </TextContainer>
        {actualBonus > 0 && <BonusContainer>{`Gain ${actualBonus} Dragon Scales!`}</BonusContainer>}
      </MiddleContainer>
      <RightContainer>
        <ImageContainer
          background={background}
          // if this is not a preview, and the quantity is 0, or the resource is not accepted, disable the button
          onClick={(zeroQuantity || !isAccepted) && !isPreview ? null : handleToggleAccepted}
        >
          {isPreview ? (
            isBasePrice && isAccepted ? (
              locked
            ) : isAccepted ? (
              accepted
            ) : (
              rejected
            )
          ) : (
            <img src={resourcePath} />
          )}
        </ImageContainer>
      </RightContainer>
    </Container>
  );
};

export default ResourceContainer;
