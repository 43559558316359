import React from "react";
import { Tooltip } from "react-tooltip";
import {
  Container,
  FarmAndSynergyContainer,
  SquadNumberWrapper,
  GradientContainer,
  CollectionAndTraitContainer,
  GradientContainerSmall,
} from "../../../squad-header/squad-info/styles";

import { TraitImage } from "./styles";

import { numberToName } from "../../../../config/resources/01-traits";
import { getSvgTraitById } from "../../../../config/resources/07-traits-to-svg";
import { realQuestId } from "../../../../utils/quests";

import PromoCollection from "../../../../assets/images/squads-card/collection-promo-symbol.png";

const QuestInfo = ({ quest }) => {
  return (
    <Container>
      <FarmAndSynergyContainer>
        <SquadNumberWrapper>
          <GradientContainer>
            <h4>{`Quest ${realQuestId(quest.id)}`}</h4>
          </GradientContainer>
        </SquadNumberWrapper>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp2"
            data-tooltip-content="Minimum Synergy Bonus required to embark on this Quest"
            data-tooltip-place="top"
          >
            <GradientContainer>
              <h4>{quest.minSynergyMultiplier}%</h4>
            </GradientContainer>
          </a>
          <Tooltip id="tp2" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
      </FarmAndSynergyContainer>
      <CollectionAndTraitContainer>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp3"
            data-tooltip-content={
              quest.anyCollection
                ? `This Quest requires 1 Collection NFT in the squad`
                : quest.collections.length > 0
                ? "This Quest requires 1 Promotional Collection NFT in the squad"
                : "This Quest doesn't require Collection NFTs in the squad"
            }
            data-tooltip-place="top"
          >
            <GradientContainerSmall>
              {quest.anyCollection ? (
                <h4>🎎</h4>
              ) : quest.collections.length > 0 ? (
                <h4>
                  <img src={PromoCollection} style={{ width: "24px" }} />
                </h4>
              ) : (
                <h4>—</h4>
              )}
            </GradientContainerSmall>
          </a>
          <Tooltip id="tp3" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp4"
            data-tooltip-content={
              quest.traits.length > 0
                ? `Required Traits: ${quest.traits
                    .map((trait) => numberToName(trait, true))
                    .join(", ")}`
                : "This Quest doesn't require specific Traits in the squad"
            }
            data-tooltip-place="top"
          >
            <GradientContainerSmall>
              {quest.traits.length > 0 ? (
                quest.traits.map((trait) => {
                  if (trait === 0) return null;
                  return <TraitImage key={trait} src={getSvgTraitById(trait)} scale={0.8} />;
                })
              ) : (
                <h4>—</h4>
              )}
            </GradientContainerSmall>
          </a>
          <Tooltip id="tp4" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp5"
            data-tooltip-content={
              quest.requiredMaxedOutNfts > 0
                ? `Beasts On Fire: ${quest.requiredMaxedOutNfts}`
                : "Beasts On Fire: none"
            }
            data-tooltip-place="top"
          >
            <GradientContainerSmall>
              {quest.requiredMaxedOutNfts > 0 ? <h4>{quest.requiredMaxedOutNfts}</h4> : <h4>—</h4>}
            </GradientContainerSmall>
          </a>
          <Tooltip id="tp5" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
        <SquadNumberWrapper>
          <a
            data-tooltip-id="tp6"
            data-tooltip-content={
              quest.requiredDreamBeasts[0] > 0
                ? `Dragons: ${quest.requiredDreamBeasts[0]}`
                : "Dragons: none"
            }
            data-tooltip-place="top"
          >
            <GradientContainerSmall>
              {quest.requiredDreamBeasts[0] > 0 ? (
                <h4>{quest.requiredDreamBeasts[0]}</h4>
              ) : (
                <h4>—</h4>
              )}
            </GradientContainerSmall>
          </a>
          <Tooltip id="tp6" style={{ fontSize: "12px", fontWeight: 600 }} />
        </SquadNumberWrapper>
      </CollectionAndTraitContainer>
    </Container>
  );
};

export default QuestInfo;
