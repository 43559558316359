import React from "react";
import Header from "../components/header/Header";

import BalancesScreen from "../components/balances-screen/BalancesScreen";

import { useWeb3Context } from "../contexts/Web3Context";

const Balances = () => {
  const { IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE } = useWeb3Context();

  return (
    <div>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">💎</h1>
                    <h1 className="heading text-center">Balances</h1>
                  </div>
                  <div>
                    <h5 className="sub-title help-center">
                      <p>Hover your mouse over each resource to see examples of what it does.</p>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="tf-help-center tf-section">
            <div className="themesflat-container" style={{ top: "-40px" }}>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-wrap justify-content-center">
                    <BalancesScreen></BalancesScreen>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default Balances;
