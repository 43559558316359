import BigNumber from "bignumber.js";

const MAGIC_NUMBER = BigNumber(6);

export function baseFarmPerSpeciesAndRarity(species, rarity, isCollection = false) {
  let typeFactor = species === 1 ? 1 : 2 ** species - 1;
  let rarityFactor = rarity === 1 ? 1 : 2 ** rarity - 1;

  let farm = BigNumber(200 * typeFactor * rarityFactor).times(1e9);
  if (isCollection) {
    farm = farm.times(1.25);
  }

  if (species >= 6) {
    farm = BigNumber(100000)
      .times(1e9)
      .plus(
        BigNumber(25000)
          .times(1e9)
          .times(species - 5)
      );
  }

  return farm;
}

export function calculateFarmPerBlock(tempNft) {
  let { baseFarm, rank, agility } = tempNft;

  baseFarm = BigNumber(baseFarm);
  rank = BigNumber(rank);
  agility = BigNumber(agility);

  const farmBonus = baseFarm.times(rank).div(10).div(MAGIC_NUMBER.minus(agility));
  return baseFarm.plus(farmBonus);
}

export function getOneOfEachSpecies(ownerAddress) {
  const nfts = [];
  for (let i = 1; i < 11; i++) {
    const type_ = i;
    for (let j = 5; j < 6; j++) {
      const rarity = j;

      let typeFactor = type_ === 1 ? 1 : 2 ** type_ - 1;
      let rarityFactor = rarity === 1 ? 1 : 2 ** rarity - 1;

      let farm = BigNumber(200 * typeFactor * rarityFactor).times(1e9);
      if (type_ >= 6) {
        farm = BigNumber(100000)
          .times(1e9)
          .plus(
            BigNumber(25000)
              .times(1e9)
              .times(type_ - 5)
          );
      }

      nfts.push({
        id: 100001 + nfts.length,
        name: "",
        imageUrl: "",
        owner: ownerAddress,
        type_: i,
        rarity: j,
        rank: 0,
        agility: 0,
        baseFarm: farm,
        farmPerBlock: farm,
        squadId: 0,
        traits: [1, 2, 3],
        skills: [1, 1, 0, 0, 0, 0],
        collection: 0,
        nuts: 0,
        evos: 0,
        squadNumber: 0,
        questId: 0,
        isActuallyAResource: false,
        resourceIndex: 0,
        resourceQuantity: 0,
      });
    }
  }
  return nfts;
}
