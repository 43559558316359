import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import Header from "../components/header/Header";
import CountUp from "react-countup";
import { useWeb3Context } from "../contexts/Web3Context";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useKoziPoolContext } from "../contexts/KoziPoolContext";
import {
  quoteBuyKozi,
  quoteStableToKozi,
  priceFromReserves,
  quoteSellKozi,
} from "../utils/koziPool";
import DexChart from "../components/kozi-pool/chart/dexChart";

const DECIMAL_PLACES = 6;
const ORIGINAL_PRICE = 10;
BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_UP });

const DexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 90%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const TradingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  padding: 20px;
  margin: 4px;
  border-radius: 8px;
  outline: 3px solid #fff;
  background: linear-gradient(0deg, rgba(15, 22, 42, 1), rgba(16, 59, 83, 1));

  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

const TradingButton = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background: ${(props) =>
    props.sell
      ? "linear-gradient(145deg, #d9534f, #c9302c)"
      : "linear-gradient(145deg, #45a049, #4caf58)"};
  border: none;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;
  letter-spacing: -0.04em;

  &:hover {
    background: ${(props) =>
      props.sell
        ? "linear-gradient(145deg, #c74343, #df6360)"
        : "linear-gradient(145deg, #3c8f40, #51c665)"};
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const TradingLabel = styled.label`
  color: #fff;
  margin-bottom: 6px;
  font-size: 14px;
`;

const TradingInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 6px;
  border: 1px solid #4caf58;
  background-color: #1c1b20;
  color: #fff;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  max-width: 90%;
  margin-top: 20px; // Reduced this value
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  padding: 20px;
  gap: 6px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const KoziPool = () => {
  BigNumber.config({ DECIMAL_PLACES: 18 });
  const { isReady, IS_PAUSED, PAUSED_MESSAGE, PAUSED_TITLE, consult } = useWeb3Context();
  const { player } = usePlayerContext();
  const { buyKozi, sellKozi, buyKoziAtPrice, sellKoziAtPrice } = useKoziPoolContext();
  const [showCountUp, setShowCountUp] = useState(false);
  const [formattedStabletokenAmount, setFormattedStabletokenAmount] = useState("0");
  const [formattedKoziAmount, setFormattedKoziAmount] = useState("0");
  const [isModalOpen, setModalOpen] = useState(false);
  const [formattedSellStabletokenAmount, setFormattedSellStabletokenAmount] = useState("0");
  const [formattedSellKoziAmount, setFormattedSellKoziAmount] = useState("0");

  const [poolState, setPoolState] = useState({
    price: 0,
    reserves: [0, 0],
    targetPrice: 0,
  });

  const updatePoolState = async () => {
    if (!isReady) return;

    const _poolState = await consult({
      contractName: "KoziPool",
      functionName: "getPricesAndReserves",
      functionParams: [],
    });

    const _state = {
      price: BigNumber(_poolState[0]).toString(),
      targetPrice: BigNumber(_poolState[1]).toString(),
      reserves: [BigNumber(_poolState[2]).toString(), BigNumber(_poolState[3]).toString()],
    };
    setPoolState(_state);
    console.log("Kozi Pool State: ", _state);
  };

  useEffect(() => {
    updatePoolState();
  }, [isReady]);

  const priceChange = (price) => {
    return Math.round((parseFloat(price) / ORIGINAL_PRICE - 1) * 10000) / 100;
  };
  const priceChangeClass = priceChange(poolState.price) < 0 ? "red" : "";

  const handleStabletokenAmountChange = (event) => {
    setShowCountUp(false);
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedStabletokenAmount(value);

    // Perform calculation to update KOZI amount
    const calculatedKoziAmount = quoteStableToKozi(
      BigNumber(value).times(1e18),
      poolState.reserves[0],
      poolState.reserves[1]
    );
    const koziAmount = calculatedKoziAmount.div(1e18);

    if (koziAmount.isNaN() || koziAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN") {
      setFormattedKoziAmount("0");
      return;
    }

    setFormattedKoziAmount(koziAmount.toFixed(DECIMAL_PLACES), BigNumber.ROUND_DOWN);
  };

  const handleKoziAmountChange = (event) => {
    setShowCountUp(false);
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedKoziAmount(value);

    // Perform calculation to update USDC amount
    const calculatedStabletokenAmount = quoteBuyKozi(
      BigNumber(value).times(1e18),
      poolState.reserves[0],
      poolState.reserves[1]
    );
    const stabletokenAmount = calculatedStabletokenAmount.div(1e18);

    if (
      stabletokenAmount.isNaN() ||
      stabletokenAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN"
    ) {
      setFormattedStabletokenAmount("0");
      return;
    }

    setFormattedStabletokenAmount(stabletokenAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP));
  };

  const handleSellKoziAmountChange = (event) => {
    if (!player) return;

    setShowCountUp(false);
    const value = event.target.value;
    // this value is always 1e18 times smaller than the actual value
    setFormattedSellKoziAmount(value);

    // Perform calculation to update USDC amount
    const calculatedStabletokenAmount = quoteSellKozi(
      BigNumber(value).times(1e18),
      poolState.reserves[0],
      poolState.reserves[1],
      poolState.targetPrice
    );
    const stabletokenAmount = calculatedStabletokenAmount.div(1e18);

    if (
      stabletokenAmount.isNaN() ||
      stabletokenAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_UP) === "NaN"
    ) {
      setFormattedStabletokenAmount("0");
      return;
    }

    setFormattedSellStabletokenAmount(
      stabletokenAmount.toFixed(DECIMAL_PLACES, BigNumber.ROUND_DOWN)
    );
  };

  const isBuyButtonDisabled = false;
  const handleBuyButtonClick = async () => {
    await buyKoziAtPrice(
      BigNumber(formattedKoziAmount).times(1e18).toFixed(0, BigNumber.ROUND_DOWN),
      priceFromReserves(poolState.reserves[0], poolState.reserves[1]).toFixed(0)
    );
  };

  const isSellButtonDisabled = false;
  const handleSellButtonClick = async () => {
    await sellKoziAtPrice(
      BigNumber(formattedSellKoziAmount).times(1e18).toFixed(0, BigNumber.ROUND_DOWN),
      priceFromReserves(poolState.reserves[0], poolState.reserves[1]).toFixed(0)
    );
  };

  return (
    <div>
      <style>
        {`
        .green {
          color: #2f4;
        }
        .red {
          color: red;
        }
        .fade-in {
          opacity: 0;
          transition: opacity 0.5s ease-in;
        }
        .fade-in.show {
          opacity: 1;
        }
      `}
      </style>
      <Header />
      {!IS_PAUSED ? (
        <>
          <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">🧿</h1>
                    <h1 className="heading text-center">KOZI</h1>
                    <h2
                      className={`price text-center ${
                        poolState.price ? "green" : ""
                      } ${priceChangeClass} fade-in ${poolState?.price ? "show" : ""}`}
                    >
                      {!poolState?.price ? (
                        "Loading..."
                      ) : (
                        <>
                          $
                          {showCountUp ? (
                            <CountUp
                              start={0}
                              end={BigNumber(poolState.price).div(1e18) || 0}
                              duration={3}
                              decimals={5}
                            />
                          ) : (
                            BigNumber(poolState.price).div(1e18).toFixed(5, BigNumber.ROUND_DOWN)
                          )}
                          {" ("}
                          {priceChange(BigNumber(poolState.price).div(1e18)) > 0 ? "+" : ""}
                          <CountUp
                            start={0}
                            end={priceChange(BigNumber(poolState.price).div(1e18)) || 0}
                            duration={3}
                            decimals={2}
                          />
                          %)
                        </>
                      )}
                    </h2>
                  </div>
                  <div>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      Kozi is used across the Kingdom Chain to pay for services, goods, and
                      blockchain fees. The counter above displays the percentage change from the
                      initial price: ${ORIGINAL_PRICE} on March 1st, 2024.{" "}
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32 ">
                      Here, you can buy Kozi using USDC that you have on the Kingdom Chain. If you
                      have zero Kozi in your wallet right now, use the{" "}
                      <Link to="/kingdom-bridge" style={{ textDecorationLine: "underline" }}>
                        Kingdom Bridge
                      </Link>{" "}
                      first.
                    </h5>
                    <h5 className="sub-title help-center mg-bt-32"></h5>
                    <h5 className="sub-title help-center">
                      The Kozi Pool's fees change based on Kozi's Target Price. Please read the{" "}
                      <Link to="/whitepaper" style={{ textDecorationLine: "underline" }}>
                        Kingdom Chain Whitepaper
                      </Link>{" "}
                      to understand how the Target Price works before you trade.{" "}
                      <span style={{ color: "yellow" }}>
                        {`The current Target Price is ${BigNumber(poolState.targetPrice)
                          .div(1e18)
                          .toFixed(5)}`}
                      </span>
                      .
                    </h5>
                    <h5 className="sub-title help-center small" style={{ color: "#ccc" }}>
                      Please note: Minor decimal imprecisions may occur.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <DexWrapper>
            <DexChart />
          </DexWrapper>
          <BoxesWrapper>
            <TradingBox>
              <TradingLabel htmlFor="koziInputSell">Send KOZI</TradingLabel>
              <TradingInput
                type="text"
                id="koziInputSell"
                value={formattedSellKoziAmount}
                onChange={handleSellKoziAmountChange}
                onFocus={(event) => event.target.select()}
              />
              <TradingLabel htmlFor="stabletokenInputBuy">Get USDC</TradingLabel>
              <TradingInput
                type="text"
                id="stabletokenInputSell"
                value={formattedSellStabletokenAmount}
                //onChange={handleSellStabletokenAmountChange}
                //onFocus={(event) => event.target.select()}
                readOnly
              />
              <TradingButton sell disabled={isSellButtonDisabled} onClick={handleSellButtonClick}>
                Sell KOZI
              </TradingButton>
            </TradingBox>
            <TradingBox>
              <TradingLabel htmlFor="stabletokenInputBuy">Send USDC</TradingLabel>
              <TradingInput
                type="text"
                id="stabletokenInputBuy"
                value={formattedStabletokenAmount}
                onChange={handleStabletokenAmountChange}
                onFocus={(event) => event.target.select()}
              />
              <TradingLabel htmlFor="koziInputBuy">Get KOZI</TradingLabel>
              <TradingInput
                type="text"
                id="koziInputBuy"
                value={formattedKoziAmount}
                onChange={handleKoziAmountChange}
                onFocus={(event) => event.target.select()}
              />
              <TradingButton disabled={isBuyButtonDisabled} onClick={handleBuyButtonClick}>
                Buy KOZI
              </TradingButton>
            </TradingBox>
          </BoxesWrapper>
        </>
      ) : (
        <>
          <h2
            className="tf-title-heading ct style-2 fs-30 mg-bt-10"
            style={{ paddingTop: "200px" }}
          >
            {PAUSED_TITLE}
          </h2>
          <h5 className="sub-title help-center mg-bt-32 ">{PAUSED_MESSAGE}</h5>
        </>
      )}
    </div>
  );
};

export default KoziPool;
