import React, { useState, useEffect } from "react";

import {
  OuterContainer,
  InnerContainer,
  TopLineContainer,
  Wrapper,
} from "../../squad-header/styles";

import { CostTextContainer, CostText } from "./styles";

import QuestAction from "./quest-action";
import QuestInfo from "./quest-info";
import QuestBonus from "./quest-bonus";
import QuestProgress from "./quest-progress";

import { idToQuest, calculateQuestRewardsV2 } from "../../../config/resources/02-quests";
import { currentBlock } from "../../../utils/block";

import { usePlayerContext } from "../../../contexts/PlayerContext";
import { useWeb3Context } from "../../../contexts/Web3Context";

import BigNumber from "bignumber.js";

const Quest = ({ squad, questId }) => {
  const { player } = usePlayerContext();
  const { blockchainState } = useWeb3Context();

  const currentBlockNumber = currentBlock(
    player.connection.initialBlockNumber,
    player.connection.initialTimestamp
  );
  const startsAt = currentBlockNumber - 8640;
  const quest = idToQuest(questId);

  const tempSquad = { ...squad, questStartedAt: startsAt };

  const questRewards = calculateQuestRewardsV2(
    quest.id,
    tempSquad,
    currentBlockNumber,
    blockchainState?.scales?.farmingScale
  );

  const castedResources = questRewards.resources.map((resource, index) => {
    return {
      [index]: resource.toString() === "NaN" ? 0 : resource,
    };
  });

  const sortedResources = castedResources.sort((a, b) => {
    const aKey = Object.keys(a)[0];
    const bKey = Object.keys(b)[0];
    return b[bKey] - a[aKey];
  });

  const bctReward = new BigNumber(questRewards.bct).div(1e18).toFixed(8);
  const ebctReward = new BigNumber(questRewards.eBct).div(1e18).toFixed(8);

  const rewards = {
    bonuses: {
      BCT: bctReward,
      eBCT: ebctReward,
    },
    resources: sortedResources,
  };

  return (
    quest &&
    squad &&
    rewards && (
      <Wrapper>
        <OuterContainer>
          <TopLineContainer>
            <QuestAction squad={squad} quest={quest} currentBlockNumber={currentBlockNumber} />
            <InnerContainer>
              <QuestInfo quest={quest}></QuestInfo>
              <QuestBonus quest={quest}></QuestBonus>
            </InnerContainer>
          </TopLineContainer>
        </OuterContainer>
        {quest.costInResources.length > 0 && (
          <CostTextContainer>
            <CostText>
              Cost to enter this quest: {quest.costInResources[21] / 100} Dragon Scale
              {quest.costInResources[21] > 100 ? "s" : ""}
            </CostText>
          </CostTextContainer>
        )}
        <QuestProgress rewards={rewards} questMultipliers={quest.resourceMultipliers} />
      </Wrapper>
    )
  );
};

export default Quest;
